export const checkDuplicate = (currentNote, verificationNote, checkTitle) => {
  if (currentNote.id === verificationNote.id) return false;
  const sameTitle = currentNote.title === verificationNote.title;
  const sameNote = currentNote.notes === verificationNote.notes;

  let isDuplicate = false;
  if (checkTitle) {
    isDuplicate = sameTitle && sameNote;
  } else {
    isDuplicate = sameNote;
  }

  return isDuplicate;
};

export const generateImportDuplicateNoteRule = (currentNote, notes) => [
  {
    type: 'global',
    global: () => !Object.keys(notes).find(key => checkDuplicate(currentNote, notes[key], true)),
  },
  {
    type: 'custom',
    custom: () => !Object.keys(notes).find(key => checkDuplicate(currentNote, notes[key])),
  },
];
