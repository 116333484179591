import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Tooltip from 'pwm-components/components/Tooltip';

const Upsell: React.FC = () => (
  <Tooltip visible tooltipAlign="bottomRight">
    <Box p="m" width="400px">
      <Paragraph mb="l" textAlign="left">
        <FormattedMessage id="dashboard.securityStatus.upsellMsg1" />
      </Paragraph>
      <Paragraph textAlign="left">
        <FormattedMessage id="dashboard.securityStatus.upsellMsg2" />
      </Paragraph>
    </Box>
  </Tooltip>
);

export default Upsell;
