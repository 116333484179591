import React, { PropsWithChildren } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import * as Sentry from '@sentry/browser';
import Box from 'pwm-components/components/Box';
import LoadingIcon from 'pwm-components/components/Loading';
import Error from './Error';

const LOADING_TIMEOUT = 20;

type Props = PropsWithChildren<WrappedComponentProps & {
  onRetry?: () => void;
  onTimeout?: () => void;
  timeout?: number | null;
}>;

type State = {
  mountingTime: number;
  timedOut: boolean;
}

class Loading extends React.Component<PropsWithChildren<Props>, State> {
  private timer: any;

  public static defaultProps = {
    timeout: LOADING_TIMEOUT,
    onRetry: () => {},
    onTimeout: () => {},
  };

  constructor(props: PropsWithChildren<Props>) {
    super(props);
    this.state = {
      timedOut: false,
      mountingTime: Date.now(),
    };
    this.onClick = this.onClick.bind(this);
    this.setLoadingTimeOut = this.setLoadingTimeOut.bind(this);

    if (props.timeout! >= 1000) {
      // TODO: add env configurable logging to remove logs from production builds
      // eslint-disable-next-line no-console
      console.warn(`Loading: specified timeout is too long. Unit is seconds. Did you mean to give it ${Math.floor(props.timeout! / 1000)} seconds?`);
    }
  }

  public componentDidMount(): void {
    Sentry.addBreadcrumb({ message: 'Rendered Loading component' });
    this.setState({ mountingTime: Date.now() }, () => this.setLoadingTimeOut());
  }

  public componentDidUpdate(prevProps: Props): void {
    const { timeout } = this.props;
    if (prevProps.timeout !== timeout) {
      clearTimeout(this.timer);
      this.setLoadingTimeOut();
    }
  }

  public componentWillUnmount(): void {
    clearTimeout(this.timer);
  }

  public onClick(): void {
    const { onRetry } = this.props;

    this.setState({ timedOut: false }, () => {
      this.setLoadingTimeOut();
    });

    onRetry!();
  }

  public setLoadingTimeOut(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }

    const { timeout: timeoutInSec, onTimeout } = this.props;
    const { mountingTime } = this.state;

    if (timeoutInSec === null) {
      return;
    }

    const timeout = Math.max(0, timeoutInSec! * 1000 - (Date.now() - mountingTime));

    this.timer = setTimeout(() => {
      this.timer = null;
      this.setState({ timedOut: true });
      onTimeout!();
    }, timeout);
  }

  public render(): JSX.Element {
    const { onRetry, intl, children } = this.props;
    const { timedOut } = this.state;

    if (onRetry && timedOut) {
      return (
        <Error
          message={intl.formatMessage({ id: 'dashboard.error.message' })}
          buttonLabel={intl.formatMessage({ id: 'dashboard.error.tryAgain' })}
          context={children}
          onClick={this.onClick}
        />
      );
    }

    return (
      <Box
        position="absolute"
        zIndex={1000000000}
        top="0"
        bottom="0"
        left="0"
        right="0"
        display="flex"
        height="100%"
        width="100%"
        bg="background"
      >
        <LoadingIcon />
        {
          children && (
            <Box position="absolute" top="calc(50% + 20px)" width="100%" textAlign="center">
              {children}
            </Box>
          )
        }
      </Box>
    );
  }
}

export default injectIntl(Loading);
