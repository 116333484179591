import React from 'react';
import { bool, element, node } from 'prop-types';
import Button from 'pwm-components/components/Button';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import chromeLogo from '../../img/icon-chrome-large.png';
import chromeLogo2x from '../../img/icon-chrome-large@2x.png';
import firefoxLogo from '../../img/icon-firefox-large.png';
import firefoxLogo2x from '../../img/icon-firefox-large@2x.png';
import operaLogo from '../../img/icon-opera-large.png';
import operaLogo2x from '../../img/icon-opera-large@2x.png';
import safariLogo from '../../img/icon-safari-large.png';
import safariLogo2x from '../../img/icon-safari-large@2x.png';

import config from '../../config';
import { getExtensionBrowserName } from '../../lib/UserAgent';

const browserLogos = [
  {
    logo: operaLogo,
    logo2x: operaLogo2x,
    alt: 'Opera logo',
    link: config.links.opera,
  },
  {
    logo: chromeLogo,
    logo2x: chromeLogo2x,
    alt: 'Chrome logo',
    link: config.links.chrome,
  },
  {
    logo: firefoxLogo,
    logo2x: firefoxLogo2x,
    alt: 'Firefox logo',
    link: config.links.firefox,
  },
  {
    logo: safariLogo,
    logo2x: safariLogo2x,
    alt: 'Safari logo',
    link: config.links.safari,
  },
];

const ExtensionStatus = ({
  extensionConnected,
  installedMessage,
  notInstalledMessage,
  notSupportedMessage,
  ctaMessage,
}) => {
  const browser = getExtensionBrowserName();
  if (!config.links[browser]) {
    return (
      <>
        <Box mb="xl">
          {notSupportedMessage}
        </Box>

        {browserLogos.map(({
          alt, logo, logo2x, link,
        }) => (
          <Box mr="l" display="inline-block" key={alt}>
            <a href={`${link}?subSource=get-started`} target="_blank" rel="noopener noreferrer">
              <img
                src={logo}
                srcSet={`${logo} 1x, ${logo2x} 2x`}
                alt={alt}
              />
            </a>
          </Box>
        ))}
      </>
    );
  }

  return (
    extensionConnected
      ? (
        <Paragraph>
          {installedMessage}
        </Paragraph>
      )
      : (
        <>
          <Box mb="l">
            <Paragraph>
              {notInstalledMessage}
            </Paragraph>
          </Box>

          <Box>
            <a
              href={config.links[browser]}
              target="_blank"
              rel="noreferrer noopener"
            >
              <Button>
                {ctaMessage}
              </Button>
            </a>
          </Box>
        </>
      )
  );
};

ExtensionStatus.propTypes = {
  extensionConnected: bool.isRequired,
  notSupportedMessage: node.isRequired,
  notInstalledMessage: element.isRequired,
  installedMessage: element.isRequired,
  ctaMessage: element.isRequired,
};

export default ExtensionStatus;
