//
//     Symantec copyright header start
//
// Copyright © 2016, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2016, Symantec Corporation, All rights reserved.
//
// Change History :
// Wed Feb 20 2019 Rahuraman Adding to Git.
//

import nodeParser from './VTNodeParser';
import vaultManager from './VTVaultManager';

const { oxygen, session: o2Session } = SymO2;

const dsv2Messages = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const {
  NodeList
} = dsv2Messages;

const {
  isSuccessCode
} = SymBfw.utils;


class NodeSync {
  /**
    * @async
    * @function uploadNodes
    * @desc Wrapper around the datastore put nodes call.
    * @param {Array} nodes The array of nodes you want to upload.
    * @param {Object} headers The additional headers you want to send to Oxygen.
    * @summary uploads updated/added item to oxygen server
   * @return {Promise} a resolved object with following properties:
   * - status: 200/500/etc
   * - nodeList: datastore node list
   * - error: if any
   * - responseHeaders: relevant response headers from O2
    */
  async uploadNodes(nodes, headers) {
    return new Promise((resolve) => {
      const nodeList = new NodeList();
      nodeList.setNodes(nodes);
      const entityID = o2Session.getUserID();
      oxygen.putNodes(entityID, nodeList, (status, _nodeList, error, responseHeaders) => {
        // if we get a success code then parse the node list.
        if (isSuccessCode(status)) {
          nodeParser.parseNodeList(_nodeList);
          vaultManager.postSyncSetup();
        }
        // regardless of success code call the callback with all the information.
        resolve({
          status, nodeList, error, responseHeaders
        });
      }, headers);
    });
  }
}
const nodeSync = new NodeSync();
export default nodeSync;
