import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { UserShape } from '../../user';

class LoginProtectContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allowMounting: props.user && !!props.user.key,
    };
  }

  UNSAFE_componentWillMount() {
    const { user } = this.props;

    if (!user.key) {
      // TODO: here the requested URL should be stored for later redirect
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user.key && !this.state.allowMounting) {
      this.setState({
        allowMounting: true,
      });
    } else if (!nextProps.user.key) {
      this.setState({
        allowMounting: false,
      });
    }
  }

  render() {
    if (this.state.allowMounting) {
      return this.props.children;
    }

    return null;
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

LoginProtectContainer.propTypes = {
  children: PropTypes.element,
  user: UserShape.isRequired,
};

LoginProtectContainer.defaultProps = {
  children: <div />,
};

export default connect(mapStateToProps)(LoginProtectContainer);
