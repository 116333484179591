import {
  createStore as reduxCreateStore,
  combineReducers,
  applyMiddleware,
  compose,
  Store as ReduxStore,
} from 'redux';

import { ThunkAction as ThunkActionOriginal } from 'redux-thunk';

import AccountsReducer from '@avira-pwm/redux/accounts';
import NotesReducer from '@avira-pwm/redux/notes';
import FilesReducer from '@avira-pwm/redux/files';
import CreditCardsReducer from '@avira-pwm/redux/credit-cards';
import UserPreferencesReducer from '@avira-pwm/redux/userPreferences';

import { intlReducer } from 'react-intl-redux';

import AuthReducer from '../authentication/AuthReducer';
import OEReducer from '../oe/OEReducer';
import OEDataReducer from '../oe/OEDataReducer';

import trackingReducer from '../tracking/TrackingReducer';
import UserDataReducer from '../user/UserDataReducer';
import UserReducer from '../user/UserReducer';

import MyDataReducer from '../myData/MyDataReducer';

import AccountPreferencesReducer from '../accounts/AccountPreferencesReducer';
import AccountDetailsReducer from '../accounts/AccountDetailsReducer';
import AccountModifiedAtReducer from '../accounts/AccountModifiedAtReducer';

import NotePreferencesReducer from '../notes/NotePreferencesReducer';
import NoteDetailsReducer from '../notes/NoteDetailsReducer';
import WalletPreferencesReducer from '../wallet/WalletPreferencesReducer';
import { SidebarReducer } from '../sidebar';
import { PreferencesReducer } from '../preferences';
import { ConnectedDevicesReducer } from '../connectedDevices';
import { ManageBackupsReducer, BackupDataReducer } from '../manageBackups';
import ImporterReducer from '../settings/ImporterReducer';
import DashboardReducer from '../dashboard/DashboardReducer';
import SpotlightReducer from '../spotlight/SpotlightReducer';

import AviraBreachesReducer from '../securityStatus/AviraBreachesReducer';
import HIBPReducer from '../securityStatus/HIBPReducer';
import AUCReducer from '../securityStatus/AUCReducer';
import PasswordsBreachesReducer from '../securityStatus/PasswordsBreachesReducer';
import SecurityStatusPreferenceReducer from '../securityStatus/SecurityStatusPreferenceReducer';
import DeletedUnknownBreachesReducer from '../securityStatus/DeletedUnknownBreachesReducer';
import MobileReducer from '../mobile';

import { ABTestReducer } from '../abTest';

// eslint-disable-next-line import/no-cycle
import { ThunkMiddleware, ThunkExtraArgument } from './thunk';
import nlokDataReducer from '../nlok/NLOKDataReducer';
import config from '../config';

const rootReducer = combineReducers({
  abTest: ABTestReducer,
  accountDetails: AccountDetailsReducer,
  accountPreferences: AccountPreferencesReducer,
  accounts: AccountsReducer,
  accountsModifiedAt: AccountModifiedAtReducer,
  auc: AUCReducer,
  auth: AuthReducer,
  aviraBreaches: AviraBreachesReducer,
  backupData: BackupDataReducer,
  backupDatasets: ManageBackupsReducer,
  creditCards: CreditCardsReducer,
  dashboard: DashboardReducer,
  deletedUnknownBreaches: DeletedUnknownBreachesReducer,
  devices: ConnectedDevicesReducer,
  files: FilesReducer,
  hibp: HIBPReducer,
  importer: ImporterReducer,
  intl: intlReducer,
  myData: MyDataReducer,
  mobile: MobileReducer,
  noteDetails: NoteDetailsReducer,
  notePreferences: NotePreferencesReducer,
  notes: NotesReducer,
  oe: OEReducer,
  oeData: OEDataReducer,
  nlokData: nlokDataReducer,
  passwordsBreaches: PasswordsBreachesReducer,
  preferences: PreferencesReducer,
  securityStatusPreferences: SecurityStatusPreferenceReducer,
  sidebar: SidebarReducer,
  tracking: trackingReducer,
  user: UserReducer,
  userData: UserDataReducer,
  userPreferences: UserPreferencesReducer,
  walletPreferences: WalletPreferencesReducer,
  spotlight: SpotlightReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export type ThunkAction<R> = ThunkActionOriginal<R, RootState, ThunkExtraArgument, any>;

export type Store = ReduxStore<RootState>;

type Config = {
  initialState: Partial<RootState>;
  thunkMiddleware: ThunkMiddleware;
};

export default function createStore({
  initialState,
  thunkMiddleware,
}: Config): Store {
  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development' || config.devTools === true) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  const store = reduxCreateStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware)),
  );

  return store;
}
