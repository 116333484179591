import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import getScrollbarSize from 'dom-helpers/util/scrollbarSize';

const Row = styled('div')`
    display: flex;
    flex-direction: row;
    /* font-size: $txt-pica; */

    &:nth-child(even) {
      /* background-color: ${({ theme }) => theme.semanticColors.foreground}; */
    }
`;

class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      shadowTop: false,
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    this.rowsContainer.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    this.rowsContainer.removeEventListener('scroll', this.onScroll);
  }

  onScroll() {
    this.setState({ shadowTop: (this.rowsContainer.scrollTop > 0) });
    this.props.onScroll();
  }

  render() {
    const {
      data, columns, rowHeight, headerHeight, maxHeight,
    } = this.props;
    let scrollerPadding = false;

    let height = (data.length * rowHeight) + (1 * headerHeight);
    if (maxHeight && maxHeight < height) {
      height = maxHeight;
      scrollerPadding = true;
    }

    const headerCols = [];
    columns.forEach((col) => {
      let colStyle = {};
      if (col.flexGrow) {
        colStyle = { flexGrow: col.flexGrow };
      }
      headerCols.push((
        <div
          className={`c-list__headercell ${(col.className || '')}`}
          key={col.key}
          style={colStyle}
        >
          <FormattedMessage id={col.headerLabelId} />
        </div>
      ));
    });

    const rows = data.map((rowData, index) => (
      <Row
        className="c-list__row"
        style={{
          height: rowHeight,
        }}
        key={rowData.id || index}
      >
        {
          columns.map((col) => {
            let colStyle = {};
            if (col.flexGrow) {
              colStyle = { flexGrow: col.flexGrow };
            }

            return (
              <div
                className={`c-list__cell ${(col.className || '')}`}
                key={col.key}
                style={colStyle}
              >
                <span>
                  {
                    col.renderer
                      ? col.renderer(rowData[col.key], rowData)
                      : rowData[col.key]
                  }
                </span>
              </div>
            );
          })
        }
      </Row>
    ));

    return (
      <div
        className="c-list"
        style={{
          height,
        }}
      >
        <div
          className="c-list__header c-list__shadowTop"
          style={{
            height: headerHeight,
            paddingRight: scrollerPadding ? getScrollbarSize() : 0,
          }}
        >
          {headerCols}
        </div>
        {
          this.state.shadowTop
          && (
          <div
            className="c-list__shadow-top"
            style={{
              top: headerHeight,
              right: getScrollbarSize(),
            }}
          />
          )
        }
        <div
          className="u-pos-r"
          ref={(rowsContainer) => { this.rowsContainer = rowsContainer; }}
          style={{
            height: (height - headerHeight),
            overflow: 'auto',
          }}
        >
          {rows}
        </div>
      </div>
    );
  }
}

List.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  maxHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  onScroll: PropTypes.func,
};

List.defaultProps = {
  maxHeight: null,
  rowHeight: 75,
  headerHeight: 50,
  onScroll: () => { },
};

export default List;
