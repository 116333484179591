const AuthErrorConfig = {
  'error.login.noEmail': {
    email: true,
    password: false,
    hint: false,
  },
  'error.login.invalidEmail': {
    email: true,
    password: false,
    hint: false,
  },
  'error.login.noPassword': {
    email: false,
    password: true,
    hint: false,
  },
  'error.login.invalidOtp': {
    email: false,
    password: true,
    hint: false,
  },
  'error.login.invalidPassword': {
    email: false,
    password: true,
    hint: false,
  },
  'error.login.disabledAccount': {
    email: false,
    password: false,
    hint: false,
  },
  'error.login.retriesExceeded': {
    email: true,
    password: false,
    hint: false,
  },
  'error.otp.retriesExceeded': {
    email: true,
    password: true,
    hint: false,
  },
  'error.register.passwordIsNotStrongEnough': {
    email: false,
    password: true,
    hint: false,
  },
  'error.login.invalidCredentials': {
    email: true,
    password: false,
    hint: false,
  },
  'error.login.requiredCaptcha': {
    email: false,
    password: false,
    hint: false,
  },
  'error.register.emailExists': {
    email: true,
    password: false,
    hint: true,
  },
  'error.serverError': {
    email: true,
    password: false,
    hint: false,
  },
  'error.unknown': {
    email: true,
    password: false,
    hint: false,
  },
  'masterPassword.error.notIdentical': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.empty': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.invalid': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.create.empty': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.create.invalid': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.verify.notIdentical': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.verify.wrong': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.unlock.wrong': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.verify.empty': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.verify.invalid': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.unlock.empty': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.unlock.invalid': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.provide.empty': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.provide.invalid': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.breached': {
    email: false,
    password: true,
    hint: false,
  },
  'masterPassword.error.noConsent': {
    email: false,
    password: true,
    hint: false,
  },
  'error.outdated.extension.version': {
    email: false,
    password: true,
    hint: false,
  },
};

export default AuthErrorConfig;
