import React from 'react';
import PropTypes from 'prop-types';
import chrome from '../img/chrome.png';
import chrome2x from '../img/chrome@2x.png';
import edge from '../img/ic-edge-logo.png';
import edge2x from '../img/ic-edge-logo@2x.png';
import firefox from '../img/firefox.png';
import firefox2x from '../img/firefox@2x.png';
import opera from '../img/opera.png';
import opera2x from '../img/opera@2x.png';
import safari from '../img/safari.png';
import safari2x from '../img/safari@2x.png';

const logoMap = {
  chrome,
  chrome2x,
  edge,
  edge2x,
  firefox,
  firefox2x,
  opera,
  opera2x,
  safari,
  safari2x,
};

const BrowserLogo = ({ browser, storeLink }) => (
  <a
    href={storeLink}
    target="_blank"
    rel="noreferrer noopener"
  >
    <img
      src={logoMap[browser]}
      srcSet={`${logoMap[browser]} 1x, ${logoMap[`${browser}2x`]} 2x`}
      alt={browser}
    />
  </a>
);

BrowserLogo.propTypes = {
  browser: PropTypes.string.isRequired,
  storeLink: PropTypes.string.isRequired,
};

export default BrowserLogo;
