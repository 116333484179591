import styled from 'pwm-components/styled';
import Text from 'pwm-components/components/Text';

export default styled(Text.withComponent('ul'))`
  list-style: decimal;

  & > li {
    margin-top: ${({ theme }) => theme.space.s};
    margin-left: ${({ theme }) => theme.space.m};
    padding-left: ${({ theme }) => theme.space.xs};
    margin-bottom: ${({ theme }) => theme.space.xxs};
  }
`;
