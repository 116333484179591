import { createSelector } from 'reselect';
import { accountTagCountsSelector, noteTagCountsSelector } from '../lib/MyDataSelectors';

export const getSuggestedUsernames = createSelector(
  state => state.accounts,
  state => state.oe.email,
  (accounts, defaultUsername) => {
    const suggestedUserNames = {};
    if (defaultUsername) suggestedUserNames[defaultUsername] = 0;

    Object.keys(accounts).forEach((accountId) => {
      // build map of usernames/emails to number of times they are used
      const account = accounts[accountId];
      if (account.email) {
        suggestedUserNames[account.email] = (suggestedUserNames[account.email] || 0) + 1;
      }
      if (account.username) {
        suggestedUserNames[account.username] = (suggestedUserNames[account.username] || 0) + 1;
      }
    });
    return Object.keys(suggestedUserNames)
      .sort((a, b) => suggestedUserNames[b] - suggestedUserNames[a]);
  },
);

// hibp accounts check fails with usernames in the format a / b
export const getUsernamesToSkip = createSelector(
  getSuggestedUsernames,
  usernames => usernames.reduce((hash, username) => {
    if (username.match(/\w+\s*\/\s*\w+/)) hash[username] = true;
    return hash;
  }, {}),
);

export const getSuggestedTags = createSelector(
  accountTagCountsSelector,
  noteTagCountsSelector,
  (accountTagCounts, noteTagCounts) => Object.keys({ ...accountTagCounts, ...noteTagCounts }),
);
