const nativeMsgCode = {
  noError: 0,

  // browser specific errors
  readCredentialsDbError: 1,
  communicationERror: 2,

  // native messaging app communication errors
  noNativeMessagingAppFound: 100,
  noNativeMessagingPermissions: 101,
  noNativeMessagingCompatibleExt: 102,

  // browser specific error codes
  DBError: 1,
  Oops: 2,
  NotSupported: 3,
};

export default nativeMsgCode;
