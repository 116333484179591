import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'pwm-components/styled';

import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';

import mobile from '../../../img/icon-mobile.png';
import mobile2x from '../../../img/icon-mobile@2x.png';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

import autofill from '../../../img/icon-autofill.png';
import autofill2x from '../../../img/icon-autofill@2x.png';

import passwordGen from '../../../img/icon-password-generator.png';
import passwordGen2x from '../../../img/icon-password-generator@2x.png';

import fingerprint from '../../../img/icon-fingerprint-lock.png';
import fingerprint2x from '../../../img/icon-fingerprint-lock@2x.png';

import qrcode from '../../../img/icon-qr-code.png';
import qrcode2x from '../../../img/icon-qr-code@2x.png';

const WhyUseMobilePWMContainer = styled('ul')`
  & > li {
    margin-bottom: ${({ theme }) => theme.space.l};
  }
  & > li:last-child {
    margin-bottom: 0;
  }
`;

export default () => (
  <WhyUseMobilePWMContainer>
    <li>
      <Box
        display="flex"
        alignItems="flex-start"
      >
        <Box mr="l">
          <img
            src={mobile}
            srcSet={`${mobile} 1x, ${mobile2x} 2x`}
            alt="Mobile devices icon"
          />
        </Box>
        <Box mt="s">
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.useAviraPWMOnMobile.reason1"
              defaultMessage="Carry all your passwords and secure notes with you, wherever you go"
            />
          </Paragraph>
          <Box display="flex" alignItems="center" mt="l">
            <img
              src={hint}
              srcSet={`${hint} 1x, ${hint2x} 2x`}
              alt="Hint icon"
              style={{ marginRight: 12 }}
            />
            <Text>
              <FormattedMessage
                id="dashboard.getStarted.useAviraPWMOnMobile.reason1.hint"
                defaultMessage="Your passwords are synchronized across all your devices"
              />
            </Text>
          </Box>
        </Box>
      </Box>
    </li>
    <li>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr="l">
          <img
            src={passwordGen}
            srcSet={`${passwordGen} 1x, ${passwordGen2x} 2x`}
            alt="Password Generator icon"
          />
        </Box>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.useAviraPWMOnMobile.reason2"

            defaultMessage="Generate a new password and safely store it in password manager any time"
          />
        </Paragraph>
      </Box>
    </li>
    <li>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr="l">
          <img
            src={autofill}
            srcSet={`${autofill} 1x, ${autofill2x} 2x`}
            alt="Autofill icon"
          />
        </Box>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.useAviraPWMOnMobile.reason3"
            defaultMessage="Easily autofill your passwords into websites and apps on mobile"
          />
        </Paragraph>
      </Box>
    </li>
    <li>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr="l">
          <img
            src={fingerprint}
            srcSet={`${fingerprint} 1x, ${fingerprint2x} 2x`}
            alt="Fingerprint/Lock icon"
          />
        </Box>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.useAviraPWMOnMobile.reason4"

            defaultMessage="Conveniently unlock your mobile app with your fingerprint or face (iOS face id)"
          />
        </Paragraph>
      </Box>
    </li>
    <li>
      <Box
        display="flex"
        alignItems="center"
      >
        <Box mr={26}>
          <img
            src={qrcode}
            srcSet={`${qrcode} 1x, ${qrcode2x} 2x`}
            alt="Authenticator icon"
          />
        </Box>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.useAviraPWMOnMobile.reason5"

            defaultMessage="Increase your security by setting up and managing Two-Factor Authentication access for your accounts"
          />
        </Paragraph>
      </Box>
    </li>
  </WhyUseMobilePWMContainer>
);
