import React from 'react';
import PropTypes from 'prop-types';

type Props = {
  scrollYTo: number;
  className: string;
  background: string;
};

class ScrollAreaContent extends React.Component<Props> {
  private scrollTimeout: number | null = null;

  public static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    className: PropTypes.string,
    scrollYTo: PropTypes.number,
    background: PropTypes.string,
  };

  public static contextTypes = {
    scrollArea: PropTypes.object,
  };

  public static defaultProps = {
    scrollYTo: 0,
    className: '',
    background: 'inherit',
  };

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.scrollYTo = this.scrollYTo.bind(this);
  }

  public componentDidMount(): void {
    const { scrollYTo } = this.props;
    this.scrollYTo(scrollYTo);
  }

  public componentWillUnmount(): void {
    if (this.scrollTimeout) {
      clearTimeout(this.scrollTimeout);
    }
  }

  private scrollYTo(position: number): void {
    // using setTimeout so that the scrollYTo is moved to end of event loop
    // to run the function once the DOM is rendered.
    this.scrollTimeout = window.setTimeout(() => {
      this.context.scrollArea.scrollYTo(position);
      this.scrollTimeout = null;
    }, 0);
  }

  public render(): React.ReactNode {
    const { background, className, children } = this.props;

    let style: any;

    if (background) {
      style = {
        background,
      };
    }
    return (
      <div
        id="a-scrollable-content-area"
        className={`u-pos-r ${className}`}
        style={style}
      >
        {children}
      </div>
    );
  }
}

export default ScrollAreaContent;
