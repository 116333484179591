import React from 'react';
import PropTypes from 'prop-types';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import BackButton from './BackButton';

const PageTitle = ({ children, onBackClick, renderAfter }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    alignItems="flex-start"
    mb="xl"
  >
    <Box display="flex">
      {
        onBackClick
        && <BackButton onClick={onBackClick} />
      }

      <Text size="h1">
        {children}
      </Text>
    </Box>

    {
      renderAfter()
    }
  </Box>
);

PageTitle.propTypes = {
  children: PropTypes.node.isRequired,
  onBackClick: PropTypes.func,
  renderAfter: PropTypes.func,
};

PageTitle.defaultProps = {
  onBackClick: null,
  renderAfter: () => null,
};

export default PageTitle;
