import * as Sentry from '@sentry/browser';
import tld from 'tldjs';
import { SyncUtils } from '@avira-pwm/sync';
import ServerDate from '@avira-pwm/helpers/ServerDate';
import {
  getAll,
  clear,
} from '@avira-pwm/redux/accounts';

import { getDomain, getSubdomain } from '@avira-pwm/helpers/url';
import { TrackingActions, MixpanelEvents } from '../tracking';

import {
  ACCOUNTS_PREFERENCES_SCROLLPOSITION,
  UPDATE_ACCOUNT_DETAILS,
  ACCOUNTS_DATA,
} from './AccountActionTypes';
import DataValidator from '../lib/DataValidator';
import AccountValidatorRules, {
  generateDuplicateAccountRule,
  isDomainInSubdomains,
} from '../lib/AccountValidatorRules';
import { UPDATE_PASSWORD_SORT_BY } from '../preferences/PreferencesActionTypes';
import { getUpdatedSecurityStatusIgnoredWarnings } from '../securityStatus/SecurityStatusActions';
import { deleteUnknownBreach } from '../securityStatus/DeletedUnknownBreachesActions';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { getRelevantUserKey } from '../user/selectors';
import { hasUsernameForbiddenHIBPChars } from '../securityStatus/helpers';
import config from '../config';
import * as SpotlightAPI from '../lib/SpotlightAPI';
import { handleSyncError } from '../lib/ErrorHelper';

const { trackEvent } = TrackingActions;

const {
  MP_CREDENTIALS_USED,
  MP_CREDENTIALS_ADD,
  MP_CREDENTIALS_EDIT,
  MP_CREDENTIALS_EDITED,
  MP_CREDENTIALS_SAVED,
  MP_CREDENTIALS_DELETED,
  MP_GENERATE_PASSWORD,
  MP_CREDENTIALS_SAVED_ERROR,
  MP_CREDENTIALS_INVALID_USERNAME,
  MP_CREDENTIALS_ASSOCIATED_URL_ADD,
  MP_CREDENTIALS_ASSOCIATED_URL_REMOVE,
  MP_CREDENTIALS_ASSOCIATED_URL_SHOW,
  MP_CREDENTIALS_ASSOCIATED_URL_NAVIGATE,
  MP_CREDENTIALS_DOTMENU_OPEN,
  MP_CREDENTIALS_LIST_SORTED,
} = MixpanelEvents;

export const updateLastUsedAt = id => async (dispatch, getState, { syncInstance }) => {
  const now = new ServerDate().toISOString();
  const updatedMetadata = {
    lastUsedAt: now,
  };
  const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
  try {
    await sync.updateMetadata('Account', id, updatedMetadata);
  } catch (e) {
    handleSyncError(e, 'triggerImport');
  }
};

const FILTER_TRANSLATOR = {
  is_favorite: 'favorite',
  all: 'all',
};

export const setPasswordSortBy = (sortType, filter) => (
  async (dispatch) => {
    dispatch(trackEvent(MP_CREDENTIALS_LIST_SORTED, {
      sortedBy: sortType,
      filterBy: FILTER_TRANSLATOR[filter] ?? 'custom',
    }));
    dispatch({ type: UPDATE_PASSWORD_SORT_BY, value: sortType });
  }
);

export const syncGetAccounts = () => async (dispatch, getState, { syncInstance: sync }) => {
  try {
    const key = getRelevantUserKey(getState());
    await dispatch(getAll({ sync, key }));
    const accounts = sync.getAll('Account');
    dispatch({ type: ACCOUNTS_DATA, accounts });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const clearAccounts = () => clear();

export const updateAccountDetails = accId => async (dispatch, getState, { syncInstance }) => {
  const { accounts } = getState();
  const account = accounts[accId];
  const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
  const accountHistory = await SyncUtils.getAll(sync, 'AccountHistory')
    .filter(history => (history.aid === accId));
  dispatch({ type: UPDATE_ACCOUNT_DETAILS, value: { account, accountHistory } });
};

export const updateAccount = (id, accountChangedProps, passwordAutoGenerated) => async (
  dispatch, getState, { syncInstance },
) => {
  const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
  const { accounts } = getState();

  // check if securityStatusWarnings need to be updated
  const ignoredWarnings = getUpdatedSecurityStatusIgnoredWarnings(
    { ...accounts[id], ...accountChangedProps },
  );

  try {
    await SyncUtils.update(
      sync,
      'Account',
      id,
      {
        ...ignoredWarnings,
        ...accountChangedProps,
        ...(passwordAutoGenerated == null ? {} : { passwordAutoGenerated }),
      },
    );
  } catch (e) {
    handleSyncError(e, 'updateAccount');
  }
};

const getTrackingProperties = account => ({
  generatedPassword: account.passwordAutoGenerated,
  noteLength: (account.notes || '').length,
  domain: account.domain,
});

const trimDomainToSubdomain = (account, accountChangedProps) => {
  // Extracts subdomain and adds it as an associated url
  const domain = getDomain(account.domain);
  const subdomain = getSubdomain(account.domain);
  if (domain && subdomain && domain !== subdomain) {
    if (!account.subdomain) {
      account.subdomain = [];
    }

    const subdomainEntry = account.subdomain.find(
      entry => (entry.subdomain === subdomain),
    );

    if (subdomainEntry) {
      subdomainEntry.visible = true;
    } else {
      account.subdomain.push({
        subdomain: subdomain.trim(),
        visible: true,
        lastUsedAt: new ServerDate().toISOString(),
      });
    }

    const trimmedAccount = { ...account, domain };
    const trimmedChangedProps = {
      ...accountChangedProps,
      domain,
      subdomain: account.subdomain,
    };

    return { trimmedAccount, trimmedChangedProps };
  }

  return { trimmedAccount: account, trimmedChangedProps: accountChangedProps };
};

export const syncUpdateAccount = (id, accountChangedProps, {
  labelAutoFilled,
  passwordAutoGenerated,
  ignoreDuplicateAccount,
  ignoreDomainNotInSubdomain,
}) => async (dispatch, getState) => {
  const { accounts } = getState();

  let rules = AccountValidatorRules;

  const account = accounts[id];

  const accountToValidate = {
    ...account,
    ...accountChangedProps,
    labelAutoFilled,
    passwordAutoGenerated,
  };

  if (!ignoreDuplicateAccount) {
    rules = {
      ...rules,
      duplicateAccount: generateDuplicateAccountRule(accountToValidate, accounts),
    };
  }

  if (!ignoreDomainNotInSubdomain) {
    rules = {
      ...rules,
      domainNotInSubdomain: isDomainInSubdomains({
        domain: accountChangedProps.domain,
        subdomain: accountToValidate.subdomain,
      }),
    };
  }

  const errors = DataValidator(accountToValidate, rules);

  if (errors) {
    dispatch(trackEvent(MP_CREDENTIALS_SAVED_ERROR, { errors }));
    throw errors;
  }

  const {
    trimmedAccount,
    trimmedChangedProps,
  } = trimDomainToSubdomain(accountToValidate, accountChangedProps);

  try {
    dispatch(trackEvent(MP_CREDENTIALS_EDITED, getTrackingProperties(trimmedAccount)));
    dispatch(updateAccount(id, trimmedChangedProps, passwordAutoGenerated));
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const triggerAccountCreation = (id, accountProps, {
  labelAutoFilled,
  passwordAutoGenerated,
  ignoreDuplicateAccount,
}) => async (dispatch, getState, { syncInstance }) => {
  const { accounts } = getState();
  let rules = AccountValidatorRules;
  const accountToValidate = {
    id,
    ...accountProps,
    labelAutoFilled,
    passwordAutoGenerated,
  };

  if (!ignoreDuplicateAccount) {
    rules = {
      ...rules,
      duplicateAccount: generateDuplicateAccountRule(accountToValidate, accounts),
    };
  }

  const errors = DataValidator(accountToValidate, rules);

  if (errors) {
    dispatch(trackEvent(MP_CREDENTIALS_SAVED_ERROR, { errors }));
    throw errors;
  }

  const {
    trimmedAccount,
    trimmedChangedProps,
  } = trimDomainToSubdomain(accountToValidate, accountProps);

  dispatch(trackEvent(MP_CREDENTIALS_SAVED, {
    ...getTrackingProperties(trimmedAccount),
    nameHasTLD: !!(
      trimmedAccount.label
      && trimmedAccount.label.length > 0
      && tld.tldExists(trimmedAccount.label)
    ),
  }));

  const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));

  try {
    await SyncUtils.create(
      sync,
      'Account',
      id,
      {
        ...trimmedChangedProps,
        ...(passwordAutoGenerated == null ? {} : { passwordAutoGenerated }),
      },
    );
  } catch (e) {
    handleSyncError(e, 'triggerAccountCreation');
  }
};

export const trackCredentialsUsed = (context, action, account) => (dispatch) => {
  const trackingObj = {
    action,
    context,
    domain: account.domain,
    generatedPassword: !!account.passwordAutoGenerated,
  };

  dispatch(trackEvent(MP_CREDENTIALS_USED, trackingObj));
};

export const trackCredentialsDotMenuOpen = context => (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_DOTMENU_OPEN, {
    context,
  }));
};

// eslint-disable-next-line max-params
export const autoLoginAccount = (accountId, fallbackUrl, context, subdomain = {}) => (
  dispatch, getState, { dashboardMessenger },
) => {
  if (dashboardMessenger.isConnected()) {
    dashboardMessenger.send('dashboard:extension:loginAccount', {
      id: accountId,
      subdomain,
    });
  } else if (config.spotlight && SpotlightAPI.openURL.isAvailable()) {
    SpotlightAPI.openURL(fallbackUrl);
  } else {
    window.open(fallbackUrl);
  }

  dispatch(updateLastUsedAt(accountId));

  const { accounts } = getState();

  dispatch(trackCredentialsUsed(context, 'autologin', accounts[accountId]));
};

export const toggleAccountFavorite = account => (dispatch) => {
  dispatch(updateAccount(account.id, { is_favorite: !account.is_favorite }));
};

export const syncDeleteAccount = accountId => async (dispatch, getState, { syncInstance }) => {
  const { accounts } = getState();
  const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
  const properties = getTrackingProperties(accounts[accountId]);
  try {
    await SyncUtils.deleteData(sync, 'Account', accountId);
  } catch (e) {
    handleSyncError(e, 'syncDeleteAccount');
  }
  dispatch(trackEvent(MP_CREDENTIALS_DELETED, properties));
};

export const setScrollPosition = scrollPosition => (
  { type: ACCOUNTS_PREFERENCES_SCROLLPOSITION, value: scrollPosition }
);

export const trackCredentialsAssociatedUrlAdd = trackingObj => async (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_ASSOCIATED_URL_ADD, trackingObj));
};

export const trackCredentialsAssociatedUrlRemove = trackingObj => async (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_ASSOCIATED_URL_REMOVE, trackingObj));
};

export const trackCredentialsAssociatedUrlShow = trackingObj => async (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_ASSOCIATED_URL_SHOW, trackingObj));
};

export const trackCredentialsAssociatedUrlNavigate = trackingObj => async (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_ASSOCIATED_URL_NAVIGATE, trackingObj));
};

export const trackCredentialAdd = () => (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_ADD));
};

export const trackCredentialEdit = () => (dispatch) => {
  dispatch(trackEvent(MP_CREDENTIALS_EDIT));
};

export const trackPasswordGenerated = trackingObj => async (dispatch) => {
  dispatch(trackEvent(MP_GENERATE_PASSWORD, trackingObj));
};

export const onAccountCopy = (context, action, account) => (dispatch) => {
  dispatch(updateLastUsedAt(account.id));
  dispatch(trackCredentialsUsed(context, action, account));
};

export const updateAccounts = accountsToUpdate => async (dispatch, getState, { syncInstance }) => {
  const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
  try {
    await SyncUtils.updateBatch(sync, 'Account', accountsToUpdate);
  } catch (e) {
    handleSyncError(e, 'updateAccounts');
  }
};

export const onAccountDelete = id => async (dispatch, getState) => {
  const { accounts, hibp: { usernames: hibpUsernames } } = getState();
  const { domain, username, email } = accounts[id];

  await dispatch(syncDeleteAccount(id));

  if (hibpUsernames[username] && hibpUsernames[username].breaches
    && hibpUsernames[username].breaches.find(breach => getDomain(breach.Domain) === domain)) {
    dispatch(deleteUnknownBreach({ breachKey: `${domain}_${username}` }));
  }

  if (hibpUsernames[email] && hibpUsernames[email].breaches
    && hibpUsernames[email].breaches.find(breach => getDomain(breach.Domain) === domain)) {
    dispatch(deleteUnknownBreach({ breachKey: `${domain}_${email}` }));
  }
};

export const trackForbiddenCharsInUsernames = () => (dispatch, getState) => {
  const { accounts } = getState();

  for (const account of Object.values(accounts)) {
    if (
      (account.username && hasUsernameForbiddenHIBPChars(account.username))
      || (account.email && hasUsernameForbiddenHIBPChars(account.email))
    ) {
      dispatch(trackEvent(MP_CREDENTIALS_INVALID_USERNAME, {
        domain: account.domain,
      }));
    }
  }
};

export const updateModifiedAt = (
  (id, newDate) => async (dispatch, getState, { syncInstance }) => {
    const updatedMetadata = {
      modifiedAt: newDate,
    };
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    try {
      await sync.updateMetadata('Account', id, updatedMetadata);
    } catch (e) {
      handleSyncError(e, 'account:updateModifiedAt');
    }
  }
);
