import React, { useState, ChangeEvent, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import RadioGroup from 'pwm-components/components/RadioGroup';
import { useHistory } from 'react-router-dom';
import SpotlightPwmIcon from './SpotlightPwmIcon';
import * as SpotlightAPI from '../../lib/SpotlightAPI';

const RadioItemWrapper: React.FC = ({ children }) => (
  <Box m="4px" p="20px" bgOverride="limedspruce">
    <strong>
      {children}
    </strong>
  </Box>
);

const SpotlightOnboardingAccountVerify: React.FC = () => {
  const history = useHistory();
  const [hasAccount, setHasAccount] = useState<boolean | null>(null);

  const onBackClick = useCallback((): void => {
    history.push('/spotlight-onboarding');
  }, [history]);

  const onNextClick = useCallback((): void => {
    if (hasAccount == null) {
      return;
    }
    if (hasAccount) {
      SpotlightAPI.showRegisterPage();
      SpotlightAPI.onUserChanged(() => {
        history.push('/spotlight-login');
        SpotlightAPI.setSpotlightUserActivated('', true);
      });
    } else {
      SpotlightAPI.showBrowserExtensions('');
      SpotlightAPI.setSpotlightUserActivated('', true);
    }
  }, [hasAccount, history]);

  const onValueChange = useCallback((event: ChangeEvent<HTMLInputElement>): void => {
    event.persist();
    if (event.target.value === 'No') {
      setHasAccount(false);
    } else {
      setHasAccount(true);
    }
  }, []);

  return (
    <Box bgOverride="outerspace" display="flex" height="100%" flexDirection="column" alignItems="center" justifyContent="center">
      <Box textAlign="center">
        <Box position="relative" mb="xxxl" mx="a" width="150px">
          <SpotlightPwmIcon type="user" />
        </Box>
        <Paragraph mb="m" size="h2">
          <FormattedMessage
            id="spotlightOnboardingAccountVerify.headline"
            defaultMessage="Already have an Avira account?"
          />
        </Paragraph>
      </Box>
      <Box width="60%" mb="90px" mt="40px">
        <RadioGroup
          name="hasAccount"
          options={[
            {
              label: (
                <Text>
                  <FormattedMessage
                    id="spotlightOnboardingAccountVerify.yes"
                    defaultMessage="Yes, I have"
                  />
                </Text>
              ),
              value: 'Yes',
            },
            {
              label: (
                <Text>
                  <FormattedMessage
                    id="spotlightOnboardingAccountVerify.no"
                    defaultMessage="No, I don't have (or I don't know)"
                  />
                </Text>
              ),
              value: 'No',
            },
          ]}
          optionWrapperComponent={RadioItemWrapper}
          onChange={onValueChange}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box mr="m">
          <Button
            fullWidth
            onClick={onNextClick}
            disabled={hasAccount === null}
          >
            <FormattedMessage
              id="spotlightOnboardingConainer.next"
              defaultMessage="Next"
            />
          </Button>
        </Box>
        <Box>
          <Button
            variant="secondary"
            onClick={onBackClick}
          >
            <FormattedMessage
              id="spotlightOnboardingConainer.back"
              defaultMessage="Back"
            />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SpotlightOnboardingAccountVerify;
