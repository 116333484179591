import debug from 'debug';

const PWM = {
  debug,
  enableDebug: (namespace: string) => debug.enable(`dashboard:${namespace != null ? `${namespace}:*` : '*'}`),
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore we don't have types for PWM
window.PWM = PWM;
