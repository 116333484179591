import React from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import PWMLogo from './PWMLogo';

const HeaderBox = styled(Box)`
  padding: ${({ theme }) => theme.space.s};
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.lightgainsboro}`};
  z-index: 20;
`;

export default () => (
  <HeaderBox>
    <PWMLogo />
  </HeaderBox>
);
