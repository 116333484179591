import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import TextButton from 'pwm-components/components/TextButton';
import Arrow from 'pwm-components/components/Arrow';
import UserProfileImage from 'pwm-components/components/UserProfileImage';
import { withABTest } from 'pwm-components/objects/ABTest';

import DropdownMenu from '../../componentLib/DropdownMenu';
import { withSearchParams } from '../../app/components/SearchParamsContext';
import intlShape from '../../lib/intlShape';


const LOGOUT_CONTEXT = 'headerMenu';
const LOGOUT_CAUSE = 'logout';

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdownMenu: false,
    };
    this.onDropdownMenuClick = this.onDropdownMenuClick.bind(this);
    this.onLockClick = this.onLockClick.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.openEditAccount = this.openEditAccount.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  onLockClick(e) {
    const { onLockClick } = this.props;
    e.stopPropagation();
    onLockClick();
  }

  onLogoutClick(e) {
    const { onLogoutClick } = this.props;
    e.stopPropagation();
    onLogoutClick(LOGOUT_CONTEXT, LOGOUT_CAUSE);
  }

  onDropdownMenuClick(e) {
    e.stopPropagation();
    const { showDropdownMenu } = this.state;
    this.setState({ showDropdownMenu: !showDropdownMenu });
  }

  openEditAccount(e) {
    e.stopPropagation();
    const { openEditAccount } = this.props;
    openEditAccount('header');
  }

  openCancelRenewal(e) {
    e.stopPropagation();
    const { openCancelRenewal } = this.props;
    openCancelRenewal('header');
  }

  openRenewalSettings(e) {
    e.stopPropagation();
    const { openRenewalSettings } = this.props;
    openRenewalSettings('header');
  }

  openContractCancelationRefund(e) {
    e.stopPropagation();
    const { openContractCancelationRefund } = this.props;
    openContractCancelationRefund('header');
  }

  handleClickOutside() {
    const { showDropdownMenu } = this.state;
    if (showDropdownMenu) {
      this.setState({ showDropdownMenu: false });
    }
  }

  render() {
    const {
      profileImage, email, intl, abTest, searchParams, isPro,
    } = this.props;
    const { showDropdownMenu } = this.state;

    const menuItemsListProUsers = [
      {
        labelId: 'dashboard.account.menu.howToCancelRenewal',
        menuItemAttrs: {
          id: 'a-account.menu.howToCancelRenewal',
          onClick: e => this.openCancelRenewal(e),
        },
      },
      {
        labelId: 'dashboard.account.menu.manageRenewalSettings',
        menuItemAttrs: {
          id: 'a-account.menu.manageRenewalSettings',
          onClick: e => this.openRenewalSettings(e),
        },
      },
      {
        labelId: 'dashboard.account.menu.contractCancelationRefund',
        menuItemAttrs: {
          id: 'a-account.menu.contractCancelationRefund',
          onClick: e => this.openContractCancelationRefund(e),
        },
      },
    ];

    let menuItemsList = [
      {
        labelId: 'dashboard.account.menu.accessMyAccount',
        menuItemAttrs: {
          id: 'a-account.menu.accessMyAccount',
          onClick: e => this.openEditAccount(e),
        },
      },
    ];

    if (isPro) {
      menuItemsList = menuItemsList.concat(menuItemsListProUsers);
    }

    menuItemsList = menuItemsList.concat([
      {
        labelId: 'dashboard.account.menu.helpAndSupport',
        menuItemAttrs: {
          id: 'a-account.menu.helpAndSupport',
          href: intl.formatMessage({ id: 'dashboard.account.menu.helpAndSupportLink' }),
          target: '_blank',
          rel: 'noreferrer noopener',
        },
      },
      {
        labelId: 'dashboard.account.menu.feedback',
        menuItemAttrs: {
          id: 'a-account.menu.feedback',
          onClick: () => {
            searchParams.setParam('feedback', 'ongoing');
          },
        },
      },
      {
        labelId: 'dashboard.account.menu.lock',
        menuItemAttrs: {
          id: 'a-account.menu.lock',
          onClick: this.onLockClick,
        },
      },
    ]);

    if (abTest.abLogoutButton === 'with') {
      menuItemsList.push(
        {
          labelId: 'dashboard.account.menu.logout',
          menuItemAttrs: {
            id: 'a-account.menu.logout',
            onClick: this.onLogoutClick,
          },
        },
      );
    }
    return (
      <div className="u-pos-r c-header__user u-mr-xs">
        <div className="o-media o-media--middle o-media--wrap o-media--s">
          <div className="o-media__img">
            <UserProfileImage alt={email} src={profileImage || null} round />
          </div>
          <div className="o-media__body">
            <TextButton
              onClick={this.onDropdownMenuClick}
              id="a-user-profile-menu-open-action"
            >
              <FormattedMessage
                id="dashboard.account.menu.myaccount"
                defaultMessage="My Account"
              />
              <Arrow
                direction="down"
                display="inline-block"
                ml="xxs"
                style={{ verticalAlign: 'middle' }}
              />
            </TextButton>
          </div>
        </div>
        <DropdownMenu
          showDropdownMenu={showDropdownMenu}
          menuItems={menuItemsList}
          id="a-user-profile-menu"
        />
      </div>
    );
  }
}

UserProfile.propTypes = {
  onLogoutClick: PropTypes.func.isRequired,
  onLockClick: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  isPro: PropTypes.bool.isRequired,
  profileImage: PropTypes.any,
  abTest: PropTypes.object,
  openEditAccount: PropTypes.func.isRequired,
  openCancelRenewal: PropTypes.func.isRequired,
  openRenewalSettings: PropTypes.func.isRequired,
  openContractCancelationRefund: PropTypes.func.isRequired,
  searchParams: PropTypes.shape({
    setParam: PropTypes.func.isRequired,
  }).isRequired,
};

UserProfile.defaultProps = {
  profileImage: null,
  abTest: null,
};

export default injectIntl(
  withSearchParams(
    withABTest(
      UserProfile,
    ),
  ),
);
