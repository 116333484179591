export const SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION = 'SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION';
export const SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST = 'SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST';
export const SPOTLIGHT_SET_SPOTLIGHT_CONNECTED = 'SPOTLIGHT_SET_SPOTLIGHT_CONNECTED';
export const SPOTLIGHT_SET_PERMISSION_REQUESTED = 'SPOTLIGHT_SET_PERMISSION_REQUESTED';
export const SPOTLIGHT_SET_SPOTLIGHT_USER_DATA = 'SPOTLIGHT_SET_SPOTLIGHT_USER_DATA';

interface SpotlightSetNativeMessagingPermission {
  type: typeof SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION;
  hasPermission: boolean;
}

interface SpotlightSetManualPermissionRequest {
  type: typeof SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST;
  manualRequest: boolean;
}

interface SpotlightSetSpotlightConnected {
  type: typeof SPOTLIGHT_SET_SPOTLIGHT_CONNECTED;
  spotlightConnected: boolean;
}

interface SpotlightPermissionRequested {
  type: typeof SPOTLIGHT_SET_PERMISSION_REQUESTED;
  permissionRequested: boolean;
}

interface SpotlightSetSpotlightUserData {
  type: typeof SPOTLIGHT_SET_SPOTLIGHT_USER_DATA;
  spotlightUserData: {
    email: null | string;
    id: null | string;
  };
}

export type SpotlightActionTypes =
  SpotlightSetNativeMessagingPermission
  | SpotlightSetManualPermissionRequest
  | SpotlightSetSpotlightConnected
  | SpotlightPermissionRequested
  | SpotlightSetSpotlightUserData;
