import { AnyAction } from 'redux';
import thunk, { ThunkMiddleware as OGThunkMiddleware, ThunkAction as OGThunkAction } from 'redux-thunk';

import BreachedWebsiteService from '@avira-pwm/services/BreachedWebsiteService';
import LicenseService from '@avira-pwm/services/LicenseService';
import OEService from '@avira-pwm/services/OEService';
import EventTranslator from '@avira-pwm/aarrr/EventTranslator';

import Sync from '@avira-pwm/sync/Sync';

import NotificationServer from '@avira-pwm/notification';
import OERequest from 'oe-oauth/dist/lib/oe-request';
import DashboardMessenger from '@avira-pwm/messenger/dashboard';

import AUC from 'auc-2-lib';

import CognitoAdapter from '@avira-pwm/sync/adapters/CognitoAdapter';
import ExtensionAdapter from '@avira-pwm/sync/adapters/ExtensionAdapter';
import LocalStorageAdapter from '@avira-pwm/sync/adapters/LocalStorageAdapter';
import IndexedDBAdapter from '@avira-pwm/sync/adapters/IndexedDBAdapter';
import AsyncStorageAdapter from '@avira-pwm/sync/adapters/AsyncStorageAdapter';
import NDSAdapter from '@avira-pwm/sync/adapters/NDSAdapter';

import S3FileService from '@avira-pwm/services/S3FileService';
import { RootState } from './store';
import SpotlightAdapter from '../lib/SpotlightAdapter';
import { SpotlightApi } from '../spotlight-api-types';

export type ThunkExtraArgument = {
  licenseService: LicenseService;
  s3FileService: S3FileService;
  oeService: OEService;
  breachedWebsiteService: BreachedWebsiteService;
  oeRequest: OERequest;
  aucLib: AUC;
  eventTranslator: EventTranslator;
  syncInstance: Sync;
  notificationServer: NotificationServer;
  syncAdapters: {
    cloudSync: CognitoAdapter;
    extension: ExtensionAdapter;
    localStorage: LocalStorageAdapter;
    indexedDB: IndexedDBAdapter;
    asyncStorage: AsyncStorageAdapter;
    spotlight: SpotlightAdapter<SpotlightApi.Sync & SpotlightApi.Messaging>;
    nds: NDSAdapter;
  };
  models: string[];
  dashboardMessenger: DashboardMessenger;
}

export type ThunkMiddleware = OGThunkMiddleware<RootState, AnyAction, ThunkExtraArgument>;
export type ThunkAction<R> = (
  OGThunkAction<R, RootState, ThunkExtraArgument, AnyAction>
);

export default function createThunk(extraArgument: ThunkExtraArgument): ThunkMiddleware {
  return thunk.withExtraArgument(extraArgument);
}
