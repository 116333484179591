export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_AUTH_STORE = 'USER_AUTH_STORE';
export const USER_AUTH_CLEAR = 'USER_AUTH_CLEAR';

// KEY RELATED ACTIONS
export const STORE_KEY = 'STORE_KEY';
export const CLEAR_KEY = 'CLEAR_KEY';

// USERDATA & SYNC RELATED ACTIONS
export const SET_USER_DATA = 'SET_USER_DATA';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const SET_SYNC_STORE = 'SET_SYNC_STORE';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';

export const UPDATE_ALL_USER = 'UPDATE_ALL_USER';

export const ADD_MILESTONE = 'ADD_MILESTONE';
export const ADD_UNREGISTERED_MILESTONE = 'ADD_UNREGISTERED_MILESTONE';
export const CLEAR_UNREGISTERED_MILESTONES = 'CLEAR_UNREGISTERED_MILESTONES';

export const SET_LOCK_REASON = 'SET_LOCK_REASON';

export const CLEAR_UNREGISTERED_KEYS = 'CLEAR_UNREGISTERED_KEYS';
