import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'pwm-components/styled';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';

import iconClick from '../../../img/icon-click.png';
import iconClick2x from '../../../img/icon-click@2x.png';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

import iconExtension from '../../../img/extension-icon.png';
import iconExtension2x from '../../../img/extension-icon@2x.png';

import autofillLoginForms from '../../../img/animations/autofillLoginForms.gif';
import autofillLoginForms2x from '../../../img/animations/autofillLoginForms2x.gif';

import List from '../List';
import TwoColumnView from '../TwoColumnView';
import GifContainer from '../GifContainer';

const ExtensionIcon = styled('img')`
  display: inline;
  position: absolute;
  bottom: ${({ theme }) => theme.space.xxs};
`;

export default () => (
  <TwoColumnView
    leftSection={(
      <>
        <Box mb="m">
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.autofillForms.message1"
              defaultMessage="You can autofill your user name and password into login forms."
            />
          </Paragraph>
          <Box position="relative">
            <FormattedMessage
              id="dashboard.getStarted.autofillForms.message2"
              defaultMessage="Login forms are marked with the Avira Password Manager icon"
            />
            <ExtensionIcon
              src={iconExtension}
              srcSet={`${iconExtension} 1x, ${iconExtension2x} 2x`}
              alt="Avira Password Manager Extension icon"
            />
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          mb="m"
        >
          <Box mr="l">
            <img
              src={iconClick}
              srcSet={`${iconClick} 1x, ${iconClick2x} 2x`}
              alt="Click icon"
            />
          </Box>
          <List>
            <li>
              <Text>
                <FormattedMessage
                  id="dashboard.getStarted.autofillForms.step1"
                  defaultMessage="Navigate to a site with a login form"
                />
              </Text>
            </li>
            <li>
              <Text>
                <FormattedMessage
                  id="dashboard.getStarted.autofillForms.step2"
                  defaultMessage="Click the Avira Password Manger icon in the form field"
                />
              </Text>
            </li>
            <li>
              <Text>
                <FormattedMessage
                  id="dashboard.getStarted.autofillForms.step3"
                  defaultMessage="Select the account to autofill"
                />
              </Text>
            </li>
          </List>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <Box mr="m">
            <img
              src={hint}
              srcSet={`${hint} 1x, ${hint2x} 2x`}
              alt="Hint"
            />
          </Box>
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.autofillForms.hint"
              defaultMessage="Avira Password Manager also helps you to fill the registration forms. On registration forms you will find a different icon in the password field which will open the password generator."
            />
          </Paragraph>
        </Box>
      </>
    )}

    rightSection={(
      <GifContainer
        gifSrc={autofillLoginForms}
        gifSrc2x={autofillLoginForms2x}
        gifAlt="Autofill login forms animation"
      />
    )}
  />
);
