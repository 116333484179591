import React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loading from './Loading';
import { RootState } from '../store';

const LoadingOverlay = (): JSX.Element => {
  const {
    loadingOverlay,
    loadingOverlayMessage,
    loadingOverlayTimeout,
  } = useSelector<RootState, RootState['dashboard']>(({ dashboard }) => dashboard);

  if (!loadingOverlay) {
    return <></>;
  }

  return (
    <Loading
      onRetry={() => window.location.reload()}
      timeout={loadingOverlayTimeout}
      onTimeout={() => {}}
    >
      {
        loadingOverlayMessage
          ? <FormattedMessage id={loadingOverlayMessage} />
          : null
      }
    </Loading>
  );
};

export default LoadingOverlay;
