import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';


const BenefitList = styled('ul')`
  margin-bottom: ${({ theme }) => theme.space.m};
  margin-left: 14px;
  list-style: disc;

  & > li {
    padding-left: ${({ theme }) => theme.space.xs};
  }
`;

const WhereIsMyDataStoredUnregistered = () => (
  <Box>
    <Paragraph mb="xs">
      <FormattedMessage
        id="dashboard.getStarted.whereIsMyDataStored.unregisteredDetail"

        defaultMessage="Your data is stored locally on your device. In case you decide to register, your data will be additionally stored in a highly secured online vault. Your sensitive data will be encrypted with your Master Password which only you know."
      />
    </Paragraph>
    <Paragraph mb="xs">
      <FormattedMessage
        id="dashboard.getStarted.whereIsMyDataStored.benefits"
        defaultMessage="Storing the data in the cloud has two key benefits:"
      />
    </Paragraph>
    <BenefitList>
      <li>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.whereIsMyDataStored.benefit1"
            defaultMessage="Your data is regularly backed up to keep it safe."
          />
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.whereIsMyDataStored.benefit2"
            defaultMessage="You can always access it from multiple devices."
          />
        </Paragraph>
      </li>
    </BenefitList>
    <Paragraph>
      <FormattedHTMLMessage
        id="dashboard.getStarted.whereIsMyDataStored.moreBenefits"

        defaultMessage="Find more benefits for a registration <a href='/why-register?dashboardContext=get-started' target='_blank'>here</a>."
      />
    </Paragraph>
  </Box>
);

export default WhereIsMyDataStoredUnregistered;
