import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { WrappedComponentProps, injectIntl } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Loading from 'pwm-components/components/Loading';

import GetNativePersiossionRequest from './GetNativePermissionRequest';
import GetNativePermissionTryAgain from './GetNativePermissionTryAgain';

import {
  extensionCheckNativePermissions,
  extensionRequestNativePermissions,
  trackNativeMessagingPermissionGranted,
} from '../SpotlightActions';

import { State as SpotlightState } from '../SpotlightReducer';
import { getBrowser } from '../../lib/UserAgent';
import MessengerContext from '../../componentLib/MessengerContext';

type State = {
  spotlight: SpotlightState;
  render: JSX.Element;
}

type Props = WrappedComponentProps & {}

// eslint-disable-next-line max-statements
const GetNativePermission: React.FC<Props> = ({ intl }: Props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboardMessenger = useContext(MessengerContext);
  const [loading, setLoading] = useState(false);
  const [requestMessage, setRequestMessage] = useState(true);
  const [possibleReload, setPossibleReload] = useState(true);
  const hasPermission = useSelector(
    (state: State) => state.spotlight.extensionHasNativeMessagingPermission,
  );
  const manualRequest = useSelector(
    (state: State) => state.spotlight.extensionManualPermissionRequest,
  );
  const permissionRequested = useSelector(
    (state: State) => state.spotlight.extensionPermissionRequested,
  );

  const currentBrowser = getBrowser();
  const browser = currentBrowser ? intl.formatMessage({ id: `dashboard.spotlight.grantPermission.${currentBrowser.toLowerCase()}`, defaultMessage: currentBrowser }) : '';

  // checks for native permission
  useEffect(() => {
    dispatch(extensionCheckNativePermissions());
  }, [dispatch]);

  // gets native messaging permissions
  useEffect(() => {
    if (hasPermission) {
      dispatch(trackNativeMessagingPermissionGranted());
      setLoading(true);
      setRequestMessage(false);
      history.push('/connect-spotlight');
    } else {
      dispatch(extensionRequestNativePermissions());
    }
  }, [dispatch, history, hasPermission]);

  // extension reloading
  useEffect(() => {
    if (!possibleReload) {
      history.push('/connect-spotlight');
    }
  }, [possibleReload, history]);

  // subscribes events on dashboardMessenger
  useEffect(() => {
    if (dashboardMessenger!.isConnected()) {
      dashboardMessenger!.on('dashboard:extension:reloadingExtension', () => {
        setPossibleReload(false);
      });
    }
  }, [dashboardMessenger]);

  function requestPermissions(): void {
    dispatch(extensionRequestNativePermissions());
  }

  function closePermissionPage(): void {
    history.push('/login');
  }

  if (loading) {
    return (
      <Box height="100%" position="relative">
        <Loading />
      </Box>
    );
  }

  if (!permissionRequested && requestMessage) {
    return (
      <GetNativePersiossionRequest
        browser={browser}
        manualRequest={manualRequest}
        onRequestClick={requestPermissions}
      />
    );
  }

  if (permissionRequested && !hasPermission) {
    return (
      <GetNativePermissionTryAgain
        browser={browser}
        onRequestClick={requestPermissions}
        onCloseClick={closePermissionPage}
      />
    );
  }

  return (
    <Redirect to={{ pathname: '/page-not-found' }} />
  );
};

export default injectIntl(GetNativePermission);
