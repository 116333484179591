import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import { getTimeToHack, passwordRatingtextColorHelper } from './passwordStrengthHelper';

type Props = WrappedComponentProps & {
  score: number;
  time: number;
}

const DisplayTimeText = styled(Text)`
  text-transform: uppercase;
`;

const TimeToHack: React.SFC<Props> = ({ score, time, intl }: Props) => {
  const { displayTime, message } = getTimeToHack(time);

  return (
    <Box minHeight="54px">
      <Paragraph mb="xxs" size="h5" variant="normal">
        <FormattedMessage id="dashboard.account.details.yearsToHack.details" />
      </Paragraph>
      <DisplayTimeText size={displayTime ? 'h2' : 'large'} variant={passwordRatingtextColorHelper(score)}>
        <strong>
          {displayTime || intl.formatMessage({ id: message })}
        </strong>
      </DisplayTimeText>
      {displayTime && (
        <Text ml="xxs">
          <FormattedMessage id={message} />
        </Text>
      )}
    </Box>
  );
};

TimeToHack.defaultProps = {
  score: 0,
};

export default injectIntl(TimeToHack);
