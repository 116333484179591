import React, {
  useState, useEffect, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import union from 'lodash/union';
import difference from 'lodash/difference';
import { Primitive } from 'utility-types';
import { Type } from '@avira-pwm/sync/ModelSpecifics/File';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';

import * as fileHelpers from '@avira-pwm/sync/helpers/file';

import { ModelMerge } from '@avira-pwm/sync';
import { usePrevious } from '../../lib/hooks';
import { getFileTypeByExtension } from '../helpers/FileTypeHelper';
import { MainSuffixes } from '../helpers/FileNameHelper';
import { isStatusResolvable, FileStatusEnum } from '../helpers/FileStatusHelper';
import { useFileManager } from '../FileManagerContext';
import { getFileConfig } from '../selectors';
import { RootState } from '../../app/store';

type FileData = { [key: string]: Uint8Array | null };

export type Props = {
  files: { [key: string]: Flattened<Type> };
  suffix?: string;
  children: (
    files: FileData,
  ) => React.ReactElement | null;
};

function defaultifyAndSortFileMap<
  O extends { [key: string]: Flattened<Type> },
  V extends Primitive
>(
  obj: O,
  defaultValue: V,
): { [K in keyof O]: V } {
  return Object.keys(obj)
    .sort((ka, kb) => (obj[ka].createdAt < obj[kb].createdAt ? 1 : -1))
    .reduce((o, k) => (Object.assign(o, { [k]: defaultValue })), {} as any);
}

const FilesContext: React.FC<Props> = ({
  files: fileList,
  suffix,
  children,
}) => {
  const {
    // TODO: use file database
    fileManager: { fileStatuses, fileThumbnails },
    fileManagerActions: { setFileStatus },
  } = useFileManager();

  const [files, setFiles] = useState<FileData>({});
  const [fileQueue, setFileQueue] = useState(Object.keys(fileList));

  const previousFiles = usePrevious(fileList);
  const previousFileStatuses = usePrevious(fileStatuses);
  const fileKey = useSelector(({ user }: RootState) => user.key2!);

  const {
    credentials,
    region,
    bucket,
    folder,
    helpers,
  } = useSelector(getFileConfig);

  const updateFileQueue = useCallback((
    newFileQueue: Array<string>, deletedFileQueue: Array<string> = [],
  ): void => {
    setFileQueue(union(fileQueue, newFileQueue));
    setFiles(f => ({
      ...newFileQueue.reduce((obj, k) => (Object.assign(obj, { [k]: f[k] || null })), {}),
      ...defaultifyAndSortFileMap(fileList, null),
      ...omit(f, deletedFileQueue),
    }));
  }, [fileList, fileQueue]);

  useEffect(() => {
    if (fileList === previousFiles) {
      return;
    }

    const currentFileIdList = Object.keys(fileList);
    const previousFileIdList = Object.keys(previousFiles);
    const newFiles = difference(currentFileIdList, previousFileIdList);
    const deletedFiles = difference(previousFileIdList, currentFileIdList);

    if (newFiles.length === 0 && deletedFiles.length === 0) {
      return;
    }

    updateFileQueue(newFiles, deletedFiles);
  }, [previousFiles, fileList, updateFileQueue]);

  useEffect(() => {
    if (suffix !== 'thumb' || !fileThumbnails) {
      return;
    }

    const currentFileIdList = Object.keys(fileList);
    const thumbnailList = currentFileIdList.reduce((obj, id) => {
      if (files[id]) {
        return obj;
      }

      return {
        ...obj,
        [id]: fileThumbnails[id],
      };
    }, {});

    setFiles(f => ({
      ...f,
      ...thumbnailList,
    }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suffix, fileList, fileThumbnails]);

  useEffect(() => {
    if (!fileStatuses || !previousFileStatuses) {
      return;
    }

    if (isEqual(previousFileStatuses, fileStatuses)) {
      return;
    }

    const newlyUploadedFiles = Object.keys(fileList).filter(
      id => fileStatuses[id] === FileStatusEnum.Uploaded
      && previousFileStatuses[id] === FileStatusEnum.Uploading,
    );

    if (newlyUploadedFiles.length === 0) {
      return;
    }

    updateFileQueue(newlyUploadedFiles);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileStatuses]);

  useEffect(() => {
    function fetchFiles(): void {
      // eslint-disable-next-line complexity, max-statements
      const fetchPromises = fileQueue.map(async (id) => {
        if (!fileList[id]) {
          return;
        } // file entity does not exist

        if (files[id]) {
          return;
        } // file already downloaded and decrypted

        const fileStatus = (fileStatuses || {})[id];
        if (fileStatus && !isStatusResolvable(fileStatus)) {
          return;
        } // file has unresolvable errors

        if (fileStatus === FileStatusEnum.Uploading) {
          return;
        } // file is being uploaded

        const flattenedFile = fileList[id];
        const file = ModelMerge.create('File', id, flattenedFile);
        const fileName = helpers.getFileName(file, suffix);
        const fileType = getFileTypeByExtension(fileList[id].type);
        const mainSuffix = MainSuffixes[fileType];
        const mainFileName = helpers.getFileName(file, mainSuffix);

        const mainFileExists = await fileHelpers.fileExists({
          credentials,
          fileName: mainFileName,
          region,
          bucket,
          folder,
        });

        if (!mainFileExists) {
          setFileStatus(id, FileStatusEnum.NotFound);
          return;
        } // main file is missing

        if (fileName !== mainFileName) {
          const fileExists = await fileHelpers.fileExists({
            credentials,
            fileName,
            region,
            bucket,
            folder,
          });

          if (!fileExists) {
            setFileStatus(id, FileStatusEnum.Downloaded);
            return;
          } // no thumbnail available, but main file exists
        }

        setFileStatus(id, FileStatusEnum.Downloading);
        const encryptedFileData = await fileHelpers.downloadFile({
          credentials,
          fileName,
          region,
          bucket,
          folder,
        });

        const encryptor = helpers.getEncryptor(file, fileKey);

        const fileData = encryptedFileData ? encryptor.decryptFile(encryptedFileData) : null;

        if (fileData) {
          setFileStatus(id, FileStatusEnum.Downloaded);
        } else {
          setFileStatus(id, FileStatusEnum.DownloadFailed);
        }

        setFiles(f => ({ ...f, [id]: fileData }));
      });

      Promise.all(fetchPromises).then(() => setFileQueue([]));
    }

    if (fileQueue.length === 0) {
      return;
    }

    if (!fileKey || fileKey.length === 0) {
      fileQueue.map(id => setFileStatus(id, FileStatusEnum.DecryptFailed));
      return;
    }

    fetchFiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suffix, fileKey, fileQueue]);

  return children({
    ...files,
  });
};

export default FilesContext;
