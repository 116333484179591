import { sha256, generateKey } from '@avira-pwm/crypto-tools';
import memoize from 'lodash/memoize';
import zxcvbn from 'zxcvbn';

const MIN_PASSWORD_SCORE = 3;
const examplePassword = 'Mountain-Lawn-Yellow-Zebra';

export const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
export const validateEmail = email => emailRegex.test(email);

const validatePasswordStrengthFn = (password) => {
  if (!password) {
    return null;
  }
  const passwordValidated = zxcvbn(password.substring(0, 64));
  const pwdSuggestions = passwordValidated.feedback.suggestions;
  const feedback = pwdSuggestions.map(
    item => `msg_zxcvbn_${item.toLowerCase().replace(/[,.!;:?'"`]/g, '').replace(/ /g, '_')}`,
  );
  return {
    feedback,
    score: passwordValidated.score,
    timeToCrack: passwordValidated.crack_times_seconds.online_no_throttling_10_per_second,
  };
};

let validatePasswordStrengthExport = validatePasswordStrengthFn;

try {
  const salt = generateKey();
  validatePasswordStrengthExport = memoize(validatePasswordStrengthFn, password => (sha256(`${salt}${password}`)));
} catch (e) { /**/ }
export const validatePasswordStrength = validatePasswordStrengthExport;

export const isPasswordStrong = (password) => {
  const passwordStrength = validatePasswordStrength(password);
  return !!passwordStrength && passwordStrength.score >= MIN_PASSWORD_SCORE;
};

export const validatePassword = password => (
  password && isPasswordStrong(password) && password !== examplePassword
);
