
import { AWSSyncStore } from '@avira-pwm/services/LicenseService';
import { Credentials as S3FileCredentials } from '@avira-pwm/services/S3FileService';
import { Credentials } from 'aws-sdk';
import {
  DASHBOARD_SET_EXTENSION_CONNECTED,
  DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED,
  DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED,
  DASHBOARD_SET_SYNC_DATA,
  DASHBOARD_SET_S3_FILE_AUTH_DATA,
  DASHBOARD_ACTIVATE_UNREGISTERED_MODE,
  DASHBOARD_DEACTIVATE_UNREGISTERED_MODE,
  DASHBOARD_DISMISS_CONGRATS_REGISTER,
  DASHBOARD_SET_EXTENSION_DISCONNECTED,
  DASHBOARD_SET_ERROR,
  DASHBOARD_CLEAR_ERROR,
  DASHBOARD_SET_LOADING_OVERLAY,
  DASHBOARD_CLEAR_LOADING_OVERLAY,
  DASHBOARD_SET_SCROLLING,
  DashboardActionTypes,
  DashboardError,
} from './DashboardActionTypes';
import { USER_AUTH_STORE } from '../user/UserActionTypes';
import config from '../config';

export type Milestone = {
  id: number;
  name: string;
}

export type State = {
  error: null | DashboardError;
  scrolling: boolean;

  /**
   * @desc AWS Cognito Credentials
   */
  syncData: AWSSyncStore | null;
  aviraAwsCredentials: Credentials | null;

  s3FileAuthData: S3FileCredentials | null;
  nlokS3Credentials: Credentials | null;

  loadingOverlay: boolean;
  loadingOverlayMessage: string | null;
  loadingOverlayTimeout: number | null;

  extensionCompatible: null | boolean;
  extensionConnected: null | boolean;
  /**
   * @desc Extension currently installed and being used
   */
  extensionInstalledVersion: null | string;
  /**
   * @desc Latest extension version from Chrome Store (NOT THE ONE INSTALLED)
   */
  extensionCurrentVersion: null | string;
  extensionCurrentHash: null | string;
  extensionIsOutdated: null | boolean;
  extensionOutdatedTimestamp: null | number;

  extensionAdapterConnected: null | boolean;
  cloudSyncAdapterConnected: null | boolean;


  isUnregisteredMode: boolean;
  initialExtensionAuthReceived: boolean;
  showCongratsOnRegistration: boolean;
  milestones: { [K: string]: Milestone };
};


export function defaultState(): State {
  return {
    error: null,
    scrolling: false,

    syncData: null,
    aviraAwsCredentials: null,
    s3FileAuthData: null,
    nlokS3Credentials: null,

    loadingOverlay: false,
    loadingOverlayMessage: null,
    loadingOverlayTimeout: null,

    extensionCompatible: null,
    extensionConnected: null,
    extensionInstalledVersion: null,
    extensionCurrentVersion: null,
    extensionCurrentHash: null,
    extensionIsOutdated: null,
    extensionOutdatedTimestamp: null,

    extensionAdapterConnected: null,
    cloudSyncAdapterConnected: null,

    isUnregisteredMode: false,
    initialExtensionAuthReceived: false,
    showCongratsOnRegistration: false,
    milestones: config.milestones,
  };
}

// eslint-disable-next-line complexity
export default function dashboardReducer(
  state = defaultState(),
  action: DashboardActionTypes | { type: typeof USER_AUTH_STORE; authToken?: string },
): State {
  switch (action.type) {
    case DASHBOARD_SET_ERROR:
      return {
        ...state,
        error: action.value,
      };
    case DASHBOARD_CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case DASHBOARD_SET_LOADING_OVERLAY:
      return {
        ...state,
        loadingOverlay: true,
        loadingOverlayMessage: action.value.message,
        loadingOverlayTimeout: action.value.timeout,
      };
    case DASHBOARD_CLEAR_LOADING_OVERLAY:
      return {
        ...state,
        loadingOverlay: false,
        loadingOverlayMessage: null,
      };
    case DASHBOARD_SET_SYNC_DATA:
      return {
        ...state,
        syncData: action.value.data,
        aviraAwsCredentials: action.value.credentials,
      };
    case DASHBOARD_SET_S3_FILE_AUTH_DATA:
      return {
        ...state,
        s3FileAuthData: action.value.data,
        nlokS3Credentials: action.value.credentials,
      };
    case DASHBOARD_SET_EXTENSION_DISCONNECTED:
      return {
        ...state,
        extensionConnected: false,
        extensionCompatible: null,
        extensionCurrentVersion: null,
        extensionIsOutdated: null,
      };
    case DASHBOARD_SET_EXTENSION_CONNECTED:
      return {
        ...state,
        extensionConnected: action.value.connected,
        extensionCompatible: action.value.compatible,
        extensionInstalledVersion: action.value.installedVersion,
        extensionCurrentVersion: action.value.currentVersion,
        extensionCurrentHash: action.value.currentHash,
        extensionIsOutdated: action.value.isOutdated,
        extensionOutdatedTimestamp: action.value.outdatedTimestamp,
      };
    case DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED:
      return {
        ...state,
        extensionAdapterConnected: action.value,
      };
    case DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED:
      return {
        ...state,
        cloudSyncAdapterConnected: action.value,
      };
    case DASHBOARD_ACTIVATE_UNREGISTERED_MODE:
      return {
        ...state,
        isUnregisteredMode: true,
        showCongratsOnRegistration: false,
      };
    case DASHBOARD_DEACTIVATE_UNREGISTERED_MODE:
      return {
        ...state,
        isUnregisteredMode: false,
        showCongratsOnRegistration: true,
      };
    case USER_AUTH_STORE:
      // deactivate unregistered mode if authToken exists and unregistered mode is still active
      return (
        state.isUnregisteredMode && action.authToken
          ? { ...state, isUnregisteredMode: false, showCongratsOnRegistration: true }
          : state
      );
    case DASHBOARD_DISMISS_CONGRATS_REGISTER:
      return {
        ...state,
        showCongratsOnRegistration: false,
      };
    case DASHBOARD_SET_SCROLLING:
      return {
        ...state,
        scrolling: action.scrolling,
      };
    default:
      return state;
  }
}
