import { createSelector } from 'reselect';
import {
  FILTER_ALL, filterHelper, FILTER_FAVORITES_ACCOUNT, FILTER_FAVORITES_DEFAULT, FILTER_FILES,
} from './MyDataHelper';
import { filterFiles } from '../files/helpers/FileDataHelper';


export const createTagHeaderSelector = (
  type,
  entitySelector,
  selector,
  favoriteKey,
// eslint-disable-next-line max-params
) => createSelector(
  entitySelector,
  state => state.files,
  selector,
  (entities, files, counts) => {
    const ids = Object.keys(entities);
    const tags = [];

    const entitiesWithFilesEntires = Object.values(files)
      .filter(file => file.entityID.indexOf(`${type}:`) === 0)
      .map(file => ([
        file.entityID.split(':')[1],
        true,
      ]));

    const entitiesWithFiles = Object.fromEntries(entitiesWithFilesEntires);

    let customTags = [];
    if (Object.keys(counts).length > 0) {
      customTags = Object.entries(counts)
        .map(([tag, count]) => ({
          id: tag,
          text: tag,
          filterCount: count,
          filter: data => (Array.isArray(data.tags) && data.tags.indexOf(tag) >= 0),
        }))
        .sort((a, b) => a.text.localeCompare(b.text));
    }

    const favoriteFilterFn = data => !!data[favoriteKey];
    const favoriteCount = ids.filter(filterHelper(entities, '', favoriteFilterFn)).length;
    const filesFilterFn = data => entitiesWithFiles[data.id] === true;

    if (favoriteCount > 0) {
      tags.push({
        id: favoriteKey,
        label: 'dashboard.accounts.showFavorites',
        icon: 'favorites-outline',
        filterCount: favoriteCount,
        filter: favoriteFilterFn,
      });
    }

    if (favoriteCount > 0 || customTags.length > 0) {
      tags.push({
        id: FILTER_ALL,
        label: 'dashboard.accounts.showAll',
        filterCount: ids.length,
      });
    }

    if (entitiesWithFilesEntires.length > 0) {
      tags.push({
        id: FILTER_FILES,
        label: 'attachments',
        icon: 'favorites-outline',
        filterCount: entitiesWithFilesEntires.length,
        filter: filesFilterFn,
      });
    }

    return tags.concat(customTags);
  },
);

export const createTagCountsSelector = type => createSelector(
  state => state[type],
  (entities) => {
    const counts = {};

    Object.values(entities).forEach(({ tags }) => {
      if (tags && tags.length > 0) {
        tags.forEach((tag) => {
          counts[tag] = (counts[tag] || 0) + 1;
        });
      }
    });

    return counts;
  },
);

export const createTagListSelector = selector => (
  createSelector(selector, counts => Object.keys(counts))
);


export const accountTagCountsSelector = createTagCountsSelector('accounts');
export const accountTagHeaderSelector = createTagHeaderSelector('Account', ({ accounts }) => accounts, accountTagCountsSelector, FILTER_FAVORITES_ACCOUNT);

export const noteTagCountsSelector = createTagCountsSelector('notes');
export const noteTagHeaderSelector = createTagHeaderSelector('Note', ({ notes }) => notes, noteTagCountsSelector, FILTER_FAVORITES_DEFAULT);

export const creditCardsTagCountsSelector = createTagCountsSelector('creditCards');
export const creditCardsTagHeaderSelector = createTagHeaderSelector('CreditCard', ({ creditCards }) => creditCards, creditCardsTagCountsSelector, FILTER_FAVORITES_DEFAULT);

export const createFileListSelector = type => createSelector(
  state => state[type],
  state => state.files,
  (entities, files) => {
    Object.keys(entities).forEach(entityID => (
      Object.assign(entities[entityID], {
        files: filterFiles(files, entityID),
      })
    ));

    return entities;
  },
);

export const notesFileListSelector = createFileListSelector('notes');
export const accountsFileListSelector = createFileListSelector('accounts');
export const walletFileListSelector = createFileListSelector('creditCards');
