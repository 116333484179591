import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Banner from '../../componentLib/Banner';

const PasswordDayBanner: React.FC<{}> = () => (
  <Box mr="s" flex="0 0 auto">
    <Banner size="small">
      <Paragraph>
        <FormattedHTMLMessage
          id="dashboard.securityStatus.passwordDay.freeBanner"
          defaultMessage="Let's celebrate <strong>Change Your Password Day</strong> together. Enjoy our Security Status pro feature for free till Feburary 7<sup style='font-size: smaller; vertical-align: super;'>th</sup>"
        />
      </Paragraph>
    </Banner>
  </Box>
);

export default PasswordDayBanner;
