import React, { PropsWithChildren } from 'react';
import CloseIcon from 'pwm-components/icons/Close';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import TextButton from 'pwm-components/components/TextButton';
import Box from 'pwm-components/components/Box';
import Card from './CardWrapper';

type Props = PropsWithChildren<{
  actionButton?: JSX.Element | null;
  onClose?: (() => void) | null;
  size?: 'small' | 'normal';
  p?: string;
}>;

const Banner: React.SFC<Props> = ({
  actionButton, children, onClose, size, p = 'xxs',
}) => (
  <Box p={p}>
    <Card
      p={size === 'small' ? 'l' : 'xl'}
      topRightIcon={onClose && (
        <TextButton onClick={onClose}>
          <CloseIcon variant="linkAlternate" />
        </TextButton>
      )}
    >
      <Grid size="small" align="center">
        <Column>
          {children}
        </Column>
        <Column size="auto">
          {actionButton}
        </Column>
      </Grid>
    </Card>
  </Box>
);

Banner.defaultProps = {
  onClose: null,
  actionButton: null,
  size: 'normal',
};

export default Banner;
