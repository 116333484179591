
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TextButton from 'pwm-components/components/TextButton';
import withTooltip from 'pwm-components/components/Tooltip/withTooltip';
import CopyIcon from 'pwm-components/icons/Copy';
import clipboardCopy from '../../../lib/CopyToClipboard';

const CopyItemWithTooltip = withTooltip(CopyIcon);

type Props = {
  copyValue: string;
  onClick?: () => void;
  tabIndex?: number | undefined;
}

const CopyButton: React.FC<Props> = (props) => {
  const { copyValue, onClick, tabIndex } = props;

  async function copyHandler(): Promise<void> {
    if (copyValue) {
      clipboardCopy(copyValue);
    }
    if (onClick) {
      onClick();
    }
  }

  return (
    <TextButton
      focusable
      onClick={copyHandler}
    >
      <CopyItemWithTooltip
        tabIndex={tabIndex}
        variant="dark"
        tooltipContent={(
          <FormattedMessage
            id="dashboard.wallet.details.copyAction"
            defaultMessage="Copy"
          />
        )}
        feedbackContent={
          (
            <FormattedMessage
              id="dashboard.wallet.details.copyActionFeedback"
              defaultMessage="Copied"
            />
          )
        }
      />
    </TextButton>
  );
};

export default CopyButton;
