import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';

import AuthPageList from './AuthPageList';

const createMasterPasswordWhy = [1, 2].map(number => `dashboard.create.createMasterPasswordWhy${number}`);
const createMasterPasswordImportant = [1, 2, 3].map(number => `dashboard.create.important${number}`);
const createMasterPasswordHint = [1, 2, 3].map(number => `dashboard.create.hint${number}`);

export default () => (
  <>
    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.create.whyMasterPassword"
            defaultMessage="Why Master Password?"
          />
        </Paragraph>
      </strong>
      <AuthPageList>
        {
          createMasterPasswordWhy.map(item => (
            <li key={item}>
              <Text variant="light">

                <FormattedHTMLMessage
                  id={item}
                />
              </Text>
            </li>
          ))
        }
      </AuthPageList>
    </Box>
    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.create.important"
            defaultMessage="Important?"
          />
        </Paragraph>
      </strong>
      <AuthPageList>
        {
          createMasterPasswordImportant.map(item => (
            <li key={item}>
              <Text variant="light">
                <FormattedHTMLMessage
                  id={item}
                />
              </Text>
            </li>
          ))
        }
      </AuthPageList>
    </Box>

    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.create.hint.header"
            defaultMessage="Hint"
          />
        </Paragraph>
      </strong>
      <Text variant="light">
        <FormattedHTMLMessage
          id="dashboard.create.hint"
          defaultMessage="Use a <strong>passphrase</strong> to create a strong and memorable Master Password:"
        />
      </Text>
      <AuthPageList>
        {
          createMasterPasswordHint.map(item => (
            <li key={item}>
              <Text variant="light">
                <FormattedHTMLMessage
                  id={item}
                />
              </Text>
            </li>
          ))
        }
      </AuthPageList>
      <Text variant="light">
        <FormattedHTMLMessage
          id="dashboard.create.hint.example"
          defaultMessage="Example: <strong>Mountain-Lawn-Yellow-Zebra</strong>"
        />
      </Text>
    </Box>
  </>
);
