import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { State as Accounts } from '@avira-pwm/redux/accounts';
import { State as Notes } from '@avira-pwm/redux/notes';
import { State as CreditCards } from '@avira-pwm/redux/credit-cards';
import mixpanel from '../../tracking/mixpanel';

type State = {
  accounts: Accounts;
  notes: Notes;
  creditCards: CreditCards;
};

export default function DataTracker(_props: {}): JSX.Element {
  const accounts = useSelector(({ accounts: stateAccounts }: State) => stateAccounts);
  const notes = useSelector(({ notes: stateNotes }: State) => stateNotes);
  const creditCards = useSelector(({ creditCards: stateCreditCards }: State) => stateCreditCards);

  useEffect(() => {
    const accountsArr = Object.values(accounts);
    const notesArr = Object.values(notes);
    const creditCardsArr = Object.values(creditCards);

    mixpanel.setTotalPasswordsGenerated(
      accountsArr.filter(account => !!account.passwordAutoGenerated).length,
    );
    mixpanel.setTotalCreditCards(creditCardsArr.length);
    mixpanel.setTotalAccounts(accountsArr.length);
    mixpanel.setTotalNotes(notesArr.length);
  },
  [accounts, notes, creditCards]);

  return <></>;
}
