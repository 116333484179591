import React from 'react';
import PropTypes from 'prop-types';
import chromeMagic from '../img/chrome_magic.png';
import chromeMagic2x from '../img/chrome_magic@2x.png';
import edgeMagic from '../img/edge_magic.png';
import edgeMagic2x from '../img/edge_magic@2x.png';
import firefoxMagic from '../img/firefox_magic.png';
import firefoxMagic2x from '../img/firefox_magic@2x.png';
import operaMagic from '../img/opera_magic.png';
import operaMagic2x from '../img/opera_magic@2x.png';
import safariMagic from '../img/safari_magic.png';
import safariMagic2x from '../img/safari_magic@2x.png';

const magicMap = {
  chromeMagic,
  chromeMagic2x,
  edgeMagic,
  edgeMagic2x,
  firefoxMagic,
  firefoxMagic2x,
  operaMagic,
  operaMagic2x,
  safariMagic,
  safariMagic2x,
};

const BrowserMagic = ({ browser }) => (
  <img
    src={magicMap[`${browser}Magic`]}
    srcSet={`${magicMap[`${browser}Magic`]} 1x, ${magicMap[`${browser}Magic2x`]} 2x`}
    alt={`${browser} with Avira PWM extension is awesome`}
  />
);

BrowserMagic.propTypes = {
  browser: PropTypes.string.isRequired,
};

export default BrowserMagic;
