import { DeepReadonly } from 'utility-types';
import {
  USER_LOGOUT,
  USER_AUTH_STORE,
  USER_AUTH_CLEAR,
  STORE_KEY,
  CLEAR_KEY,
  SET_LOCK_REASON,
  UPDATE_ALL_USER,
  ADD_UNREGISTERED_MILESTONE,
  CLEAR_UNREGISTERED_MILESTONES,
  CLEAR_UNREGISTERED_KEYS,
} from './UserActionTypes';

import {
  DASHBOARD_ACTIVATE_UNREGISTERED_MODE,
  DASHBOARD_DEACTIVATE_UNREGISTERED_MODE,
} from '../dashboard/DashboardActionTypes';

type UnregisteredMilestone = {
  id: number;
  name: string;
}

export type State = DeepReadonly<{
  authToken: string | null;
  key: string | null;
  key2: string | null;
  unregisteredMilestones: {
    [key: string]: UnregisteredMilestone;
  } | null;
  tempKey: string | null;
  tempUserId: string | null;
  lockReason: string | null;
}>;

export function defaultState(): State {
  return {
    authToken: null,
    key: null,
    key2: null,
    unregisteredMilestones: null,
    tempKey: null,
    tempUserId: null,
    lockReason: null,
  };
}

interface UserLogout {
  type: typeof USER_LOGOUT;
}
interface UserAuthStore {
  type: typeof USER_AUTH_STORE;
  authToken: string;
}
interface UserAuthClear {
  type: typeof USER_AUTH_CLEAR;
}
interface StoreKey {
  type: typeof STORE_KEY;
  key: string;
  key2: string;
}
interface ClearKey {
  type: typeof CLEAR_KEY;
}
interface SetLockReason {
  type: typeof SET_LOCK_REASON;
  lockReason: string;
}
interface UpdateAllUser {
  type: typeof UPDATE_ALL_USER;
  value: Partial<State>;
}
interface DashboardActivateUnregisteredMode {
  type: typeof DASHBOARD_ACTIVATE_UNREGISTERED_MODE;
  value: Partial<State>;
}
interface DashboardDeactivateUnregisteredMode {
  type: typeof DASHBOARD_DEACTIVATE_UNREGISTERED_MODE;
}
interface AddUnregisteredMilestone {
  type: typeof ADD_UNREGISTERED_MILESTONE;
  data: { [key: string]: UnregisteredMilestone };
}
interface ClearUnregisteredMilestones {
  type: typeof CLEAR_UNREGISTERED_MILESTONES;
}

interface ClearUnregisteredKeys {
  type: typeof CLEAR_UNREGISTERED_KEYS;
}

type UserAction = UserLogout
| UserAuthStore
| UserAuthClear
| StoreKey
| ClearKey
| SetLockReason
| UpdateAllUser
| DashboardActivateUnregisteredMode
| DashboardDeactivateUnregisteredMode
| AddUnregisteredMilestone
| ClearUnregisteredMilestones
| ClearUnregisteredKeys

// eslint-disable-next-line complexity
export default function userReducer(state = defaultState(), action: UserAction): State {
  switch (action.type) {
    case USER_LOGOUT:
      return defaultState();
    case USER_AUTH_STORE:
      return {
        ...state,
        authToken: action.authToken,
      };
    case USER_AUTH_CLEAR:
      return {
        ...state,
        authToken: null,
      };
    case STORE_KEY:
      return {
        ...state,
        key: action.key,
        key2: action.key2,
      };
    case CLEAR_KEY:
      return {
        ...state,
        key: null,
        key2: null,
      };
    case SET_LOCK_REASON:
      return {
        ...state,
        lockReason: action.lockReason,
      };
    case UPDATE_ALL_USER:
      return {
        ...state,
        ...action.value,
      };
    case DASHBOARD_ACTIVATE_UNREGISTERED_MODE:
      return {
        ...state,
        ...action.value,
        unregisteredMilestones: {
          ...(state.unregisteredMilestones || {}),
        },
      };
    case DASHBOARD_DEACTIVATE_UNREGISTERED_MODE:
    case CLEAR_UNREGISTERED_KEYS:
      return {
        ...state,
        tempKey: null,
        tempUserId: null,
      };
    case ADD_UNREGISTERED_MILESTONE:
      return {
        ...state,
        unregisteredMilestones: {
          ...state.unregisteredMilestones,
          ...action.data,
        },
      };
    case CLEAR_UNREGISTERED_MILESTONES:
      return {
        ...state,
        unregisteredMilestones: null,
      };
    default:
      return state;
  }
}
