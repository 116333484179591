import React from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import backgroundImage from '../../img/giant_lock_dark_scheme@1x.png';

const BackgroundImage = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(${backgroundImage});
  background-position: right -120px bottom;
  background-repeat: no-repeat;
`;

const AuthPageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  z-index: 1;
`;

export type Props = {
  Page: () => JSX.Element;
  banner?: JSX.Element | null;
};

const SpotlightAuthPageWrapper: React.FC<Props> = ({
  Page, banner,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    position="relative"
    height="100%"
    width="100%"
    bg="background"
  >
    {!banner && (
      <BackgroundImage />
    )}
    {banner}
    <AuthPageWrapper>
      {Page()}
    </AuthPageWrapper>
  </Box>
);

export default SpotlightAuthPageWrapper;
