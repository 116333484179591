import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  syncUpdateAccount,
  autoLoginAccount,
  trackCredentialsUsed,
  toggleAccountFavorite,
  syncDeleteAccount,
  onAccountCopy,
  trackCredentialsAssociatedUrlShow,
  trackCredentialsAssociatedUrlAdd,
  trackCredentialsAssociatedUrlRemove,
  trackCredentialsAssociatedUrlNavigate,
  updateModifiedAt,
  trackCredentialsDotMenuOpen,
} from '../AccountActions';
import AddEditAccountPage from './AddEditAccountPage';

class EditAccount extends React.Component {
  static propTypes = {
    accountDetails: PropTypes.shape({
      account: PropTypes.shape(),
      accountHistory: PropTypes.array,
    }),
    location: PropTypes.shape({
      pathname: PropTypes.string,
    }),
    onLoginClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    onRevealPasswordClick: PropTypes.func.isRequired,
    onAccountSaveClick: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDotMenuOpen: PropTypes.func.isRequired,
  };

  static defaultProps = {
    accountDetails: {
      account: {},
      accountHistory: [],
    },
    location: {
      pathname: '',
    },
  };

  constructor(props) {
    super(props);
    this.state = {
      listViewPath: props.location.pathname.indexOf('security-status') >= 0 ? '/security-status' : '/mydata/passwords',
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.onDotMenuOpen = this.onDotMenuOpen.bind(this);
  }

  async onSubmit(id, accountChangedProps, config) {
    // TODO: check why this try catch had to be put here
    const { onAccountSaveClick } = this.props;
    // eslint-disable-next-line no-useless-catch
    try {
      await onAccountSaveClick(id, {
        ...accountChangedProps,
      }, config);
    } catch (error) {
      throw error;
    }
  }

  onDotMenuOpen() {
    const { onDotMenuOpen } = this.props;
    onDotMenuOpen('details');
  }

  render() {
    const {
      accountDetails,
      onLoginClick,
      onCopyClick,
      onDelete,
      onRevealPasswordClick,
    } = this.props;
    const { listViewPath } = this.state;
    const { account, accountHistory } = accountDetails;

    return (
      <AddEditAccountPage
        {...this.props}
        account={account}
        disableHistory={accountHistory.length <= 0}
        onAccountSaveClick={this.onSubmit}
        onLoginClick={onLoginClick}
        onCopyClick={onCopyClick}
        onDelete={onDelete}
        onRevealPasswordClick={onRevealPasswordClick}
        listViewPath={listViewPath}
        onDotMenuOpen={this.onDotMenuOpen}
      />
    );
  }
}

const mapStateToProps = ({
  files,
  accountDetails,
  dashboard: { extensionConnected: extensionInstalled },
}) => ({
  files,
  accountDetails,
  extensionInstalled,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onAccountSaveClick: syncUpdateAccount,
  onLoginClick: autoLoginAccount,
  onCopyClick: onAccountCopy,
  onFavoriteClick: toggleAccountFavorite,
  onRevealPasswordClick: trackCredentialsUsed,
  onDelete: syncDeleteAccount,
  onModified: updateModifiedAt,
  onAssociatedUrlShowClicked: trackCredentialsAssociatedUrlShow,
  onAssociatedUrlAddClicked: trackCredentialsAssociatedUrlAdd,
  onAssociatedUrlRemoveClicked: trackCredentialsAssociatedUrlRemove,
  onAssociatedUrlNavigateClicked: trackCredentialsAssociatedUrlNavigate,
  onDotMenuOpen: trackCredentialsDotMenuOpen,
}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditAccount),
);
