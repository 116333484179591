import config from './config';

const proxyConfig = {
  license: {
    from: '/api-license',
    to: config.hostConfig.licenseHost,
  },
  oe: {
    from: '/api-oe',
    to: config.hostConfig.authHost,
  },
  ocl: {
    from: '/api-ocl',
    to: config.hostConfig.oclHost,
  },
  ds: {
    from: '/api-ds',
    to: config.hostConfig.dsHost,
  },
  spoc: {
    from: '/api-spoc',
    to: config.hostConfig.spocHost,
  },
  sso: {
    from: '/api-sso',
    to: config.hostConfig.ssoHost,
  },
  s3File: {
    from: '/api-s3-file',
    to: config.hostConfig.s3FileHost,
  },
};

export default proxyConfig;
