import React, { useState, useEffect, useCallback } from 'react';
import Box from 'pwm-components/components/Box';
import TextButton from 'pwm-components/components/TextButton';
import ThreeDotsIcon from 'pwm-components/icons/ThreeDots';
import DropdownMenu, { Props as DropdownProps } from '../../componentLib/DropdownMenu';

export type Props = {
  disabled?: boolean;
  onDropdownShown?: () => void;
} & Pick<DropdownProps, 'menuItems'>;

const MoreOptions: React.FC<Props> = ({
  disabled,
  menuItems,
  onDropdownShown,
}) => {
  const [showDropdownMenu, setShowDropDownMenu] = useState(false);

  useEffect(() => {
    if (showDropdownMenu && onDropdownShown) {
      onDropdownShown();
    }
  }, [onDropdownShown, showDropdownMenu]);

  useEffect(() => {
    const closeDropdownMenu = (): void => setShowDropDownMenu(false);

    window.addEventListener('click', closeDropdownMenu);

    return () => {
      window.removeEventListener('click', closeDropdownMenu);
    };
  }, []);

  // Fix for IE the click event propagates
  // which closes the dropdown as soon as it is open
  const onOptionClicked = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    setShowDropDownMenu(false);
  }, []);

  return (
    <Box position="relative" onClick={onOptionClicked}>
      <TextButton
        disabled={disabled}
        onClick={(e) => {
          e.stopPropagation();
          setShowDropDownMenu(!showDropdownMenu);
        }}
      >
        <ThreeDotsIcon variant={disabled ? 'disabled' : 'dark'} />
      </TextButton>
      <DropdownMenu
        showDropdownMenu={showDropdownMenu}
        menuItems={menuItems}
      />
    </Box>
  );
};

MoreOptions.defaultProps = {
  disabled: false,
  menuItems: [],
};

export default MoreOptions;
