import { DeepReadonly } from 'utility-types';
import {
  SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION,
  SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST,
  SPOTLIGHT_SET_SPOTLIGHT_CONNECTED,
  SPOTLIGHT_SET_PERMISSION_REQUESTED,
  SPOTLIGHT_SET_SPOTLIGHT_USER_DATA,
  SpotlightActionTypes,
} from './SpotlightActionTypes';

export type State = DeepReadonly<{
  extensionHasNativeMessagingPermission: null | boolean;
  extensionManualPermissionRequest: boolean;
  spotlightConnected: boolean;
  extensionPermissionRequested: boolean;
  spotlightUserData: {
    email: null | string;
    id: null | string;
  };
}>;

export function defaultState(): State {
  return {
    extensionHasNativeMessagingPermission: null,
    extensionManualPermissionRequest: false,
    spotlightConnected: false,
    extensionPermissionRequested: false,
    spotlightUserData: {
      email: null,
      id: null,
    },
  };
}

export default function spotlightReducer(
  state = defaultState(),
  action: SpotlightActionTypes,
): State {
  switch (action.type) {
    case SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION:
      return {
        ...state,
        extensionHasNativeMessagingPermission: action.hasPermission,
      };
    case SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST:
      return {
        ...state,
        extensionManualPermissionRequest: action.manualRequest,
      };
    case SPOTLIGHT_SET_SPOTLIGHT_CONNECTED:
      return {
        ...state,
        spotlightConnected: action.spotlightConnected,
      };
    case SPOTLIGHT_SET_PERMISSION_REQUESTED:
      return {
        ...state,
        extensionPermissionRequested: action.permissionRequested,
      };
    case SPOTLIGHT_SET_SPOTLIGHT_USER_DATA:
      return {
        ...state,
        spotlightUserData: action.spotlightUserData,
      };
    default:
      return state;
  }
}
