import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';

import {
  trackAllowCommunication,
} from '../SpotlightActions';

type Props = {
  browser: string | null;
  onRequestClick: () => void;
  manualRequest: boolean;
};

const GetNativePermissionRequest: React.SFC<Props> = ({
  browser,
  onRequestClick,
  manualRequest,
}: Props) => {
  const dispatch = useDispatch();

  return (
    <>
      <Box
        textAlign="center"
        mt="xxxl"
        mb="l"
      >
        <FormattedHTMLMessage
          id="dashboard.spotlight.grantPermission.allowCommunication"
          values={{ browser }}
        />
      </Box>
      {
        manualRequest ? (
          <Box textAlign="center">
            <Button
              onClick={() => {
                dispatch(trackAllowCommunication());
                onRequestClick();
              }}
            >
              <FormattedMessage id="dashboard.spotlight.grantPermission.button.manual" />
            </Button>
          </Box>
        ) : null

      }
    </>
  );
};

export default GetNativePermissionRequest;
