import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import Box from 'pwm-components/components/Box';
import Media from 'pwm-components/objects/Media';

import List from '../List';
import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

const WhatMakesAPasswordSecure = () => (
  <>
    <Box mb="m">
      <Paragraph>
        <FormattedMessage
          id="dashboard.getStarted.whatMakesAPasswordSecure.step0"
          defaultMessage="A secure password is "
        />
      </Paragraph>
    </Box>
    <List>
      <li>
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.whatMakesAPasswordSecure.step1"
            defaultMessage="long"
          />
        </Text>
      </li>
      <li>
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.whatMakesAPasswordSecure.step2"
            defaultMessage="a combination of random letters, numbers and special characters"
          />
        </Text>
      </li>
      <li>
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.whatMakesAPasswordSecure.step3"
            defaultMessage="uniquely used for one account"
          />
        </Text>
      </li>
    </List>
    <Media
      image={(
        <img
          src={hint}
          srcSet={`${hint} 1x, ${hint2x} 2x`}
          alt="Hint"
        />
      )}
      body={(
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.whatMakesAPasswordSecure.step4"
            defaultMessage="Use the built-in password generator from Avira to get all these things covered."
          />
        </Text>
      )}
    />
  </>
);

export default WhatMakesAPasswordSecure;
