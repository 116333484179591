import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { withTooltip } from 'pwm-components/components/Tooltip';
import Box from 'pwm-components/components/Box';
import CopyIcon from 'pwm-components/icons/Copy';

import clipboardCopy from '../lib/CopyToClipboard';
import { TextButtonOnHover } from './DataListComponents';

const CopyIconWithTooltip = withTooltip(CopyIcon);

type CopyValueProps = {
  buttonId?: string;
  value: string;
  onClick?: () => void;
}

const CopyValue: React.FC<CopyValueProps> = React.memo(({
  children, buttonId, value, onClick,
}) => {
  const onCopyClick = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    clipboardCopy(value);
    if (onClick) {
      onClick();
    }
  }, [onClick, value]);

  return (
    <Box
      display="flex"
      alignItems="center"
    >
      {children}
      <Box
        pl="m"
        flexGrow={0}
        flexShrink={0}
        flexBasis={30}
        verticalAlign="bottom"
      >
        <TextButtonOnHover
          id={buttonId}
          onClick={onCopyClick}
        >
          <CopyIconWithTooltip
            variant="link"
            tooltipContent={(
              <FormattedMessage id="dashboard.account.details.copyAction" />
            )}
            feedbackContent={(
              <FormattedMessage id="dashboard.accounts.copyActionFeedback" />
            )}
          />
        </TextButtonOnHover>
      </Box>
    </Box>
  );
});

export default CopyValue;
