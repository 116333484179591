import React from 'react';
import PropTypes from 'prop-types';

import Box from 'pwm-components/components/Box';
import DomainImage from 'pwm-components/components/DomainImage';
import TextButton from 'pwm-components/components/TextButton';
import PasswordTooltip from 'pwm-components/components/PasswordTooltip';
import EyeIcon from 'pwm-components/icons/Eye';
import EyeCrossedIcon from 'pwm-components/icons/EyeCrossed';
import TextPlaceHolder from 'pwm-components/components/TextPlaceholder';
import styled from 'pwm-components/styled';

import PasswordText from '../../componentLib/PasswordText';
import DataListItem from '../../componentLib/DataListItem';
import { DataListCell } from '../../componentLib/DataListComponents';
import AccountName from '../../accounts/components/AccountName';
import AccountLogo from '../../accounts/components/AccountLogo';
import UnsafeWebsite from './UnsafeWebsite';
import BreachedWebsite from './BreachedWebsite';
import SecurityStatusActionDropdown from './SecurityStatusActionDropdown';
import PasswordStrength from './PasswordStrength';
import UniquePassword from './UniquePassword';
import config from '../../config';

const TRACKING_CONTEXT = 'securityStatus';
// const BoxWithToolTip = withTooltip(Box);

const StyledBox = styled(Box)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

class SecurityStatusListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      revealPassword: false,
      ignored: props.data.ignoreWarnings,
      // changePasswordShowTooltipDetail: false,
    };

    this.ref = {};
    this.onRevealPasswordClick = this.onRevealPasswordClick.bind(this);
    this.hideRevealPassword = this.hideRevealPassword.bind(this);
    this.onAccountClick = this.onAccountClick.bind(this);
    this.onIgnoreChange = this.onIgnoreChange.bind(this);
    this.onActionButtonClick = this.onActionButtonClick.bind(this);
    this.onDeleteUnknownBreachClick = this.onDeleteUnknownBreachClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, currentRevealPasswordId, scrolling } = this.props;
    const { ignored } = this.state;
    const newState = {};

    if (currentRevealPasswordId !== nextProps.currentRevealPasswordId) {
      newState.revealPassword = (data.id === nextProps.currentRevealPasswordId);
    }

    if (scrolling !== nextProps.scrolling && nextProps.scrolling) {
      newState.revealPassword = false;
    }

    if (nextProps.data.ignoreWarnings !== ignored) {
      newState.ignored = nextProps.data.ignoreWarnings;
    }
    this.setState(newState);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { state } = this;
    const { props } = this;
    return state.ignored !== nextState.ignored
      || state.revealPassword !== nextState.revealPassword
      || props.data !== nextProps.data
      || props.extensionInstalled !== nextProps.extensionInstalled
      || state.hover !== nextState.hover;
  }

  onAccountClick(e) {
    e.stopPropagation();
    const {
      data, extensionInstalled, index, onAccountClick, onInteraction, spotlight,
    } = this.props;
    const href = data.login_url || `https://${data.domain}`;
    if (extensionInstalled || spotlight) {
      e.preventDefault();
      onAccountClick(data.id, href, TRACKING_CONTEXT);
    }
    onInteraction(index);
  }

  onActionButtonClick(e, actionType) {
    const { onActionButtonClick } = this.props;
    if (onActionButtonClick) {
      onActionButtonClick(actionType);
    }
    this.onAccountClick(e);
  }

  onIgnoreChange() {
    const { ignored } = this.state;
    const { data, updateIgnoreFilter, onIgnore } = this.props;
    updateIgnoreFilter(data.id);
    onIgnore(data, !ignored);
  }

  onRevealPasswordClick(e) {
    const {
      data, index, onInteraction, onRevealPasswordClick, setCurrentRevealPassword,
    } = this.props;
    const { revealPassword } = this.state;
    e.stopPropagation();
    onRevealPasswordClick(TRACKING_CONTEXT, 'revealPassword', data);
    setCurrentRevealPassword(
      data.id,
      !revealPassword,
    );
    onInteraction(index);
  }

  onDeleteUnknownBreachClick(e) {
    const { data, onDeleteUnknownBreachClick } = this.props;
    e.stopPropagation();
    onDeleteUnknownBreachClick(data.breachKey);
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  hideRevealPassword() {
    const { data, setCurrentRevealPassword } = this.props;
    setCurrentRevealPassword(
      data.id,
      false,
    );
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      data,
      extensionInstalled,
      onDeleteAccountClick,
      isCalculating,
      hasAccessToPro,
    } = this.props;
    const { revealPassword, ignored } = this.state;

    return (
      <DataListItem
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onClick={() => {}}
        disabled={!hasAccessToPro && !data.isDemoAccount}
      >
        <DataListCell>
          {
            data.isDemoAccount || hasAccessToPro
              ? (
                <AccountLogo
                  data={data}
                  onClick={!data.fakeAccount ? this.onAccountClick : null}
                />
              ) : (
                <DomainImage display="flex" />
              )
          }
        </DataListCell>
        {
          data.isDemoAccount || hasAccessToPro
            ? (
              <DataListCell
                display="flex"
                flexBasis="22em"
                shrink
                truncate
                flexWrap="wrap"
                flexShrink={10}
              >
                <StyledBox
                  flex="1 1 10em"
                  pr="16px"
                >
                  <AccountName
                    data={data}
                    onClick={!data.fakeAccount ? this.onAccountClick : null}
                  />
                </StyledBox>
                <StyledBox
                  flex="1 1 12em"
                >
                  {
                    (data.username || data.email)
                    && (
                      <span>
                        {data.username || data.email}
                      </span>
                    )
                  }
                </StyledBox>
              </DataListCell>
            ) : (
              <DataListCell
                display="flex"
                flexBasis="22em"
                shrink
                truncate
                flexShrink={10}
                flexWrap="wrap-reverse"
                height="9px"
                overflow="hidden"
              >
                <StyledBox
                  flex="1 0 10em"
                  display="flex"
                  height="100%"
                >
                  <Box flex="0 1 8em">
                    <TextPlaceHolder />
                  </Box>

                </StyledBox>
                <StyledBox
                  flex="1 0 12em"
                  display='flex'
                  height='100%'
                >
                  <Box flex="0 1 10em">
                    <TextPlaceHolder />
                  </Box>
                </StyledBox>
              </DataListCell>
            )
        }
        <DataListCell spacer />

        <DataListCell
          position="relative"
          style={{
            visibility: data.fakeAccount ? 'hidden' : 'visible',
          }}
          fixed
        >
          <Box display="flex" alignItems="center" mr="l">
            <Box mr="m" flexGrow={0}>
              <PasswordText />
            </Box>
            <Box>
              <TextButton
                onClick={this.onRevealPasswordClick}
                tabIndex={0}
              >
                {
                  revealPassword
                    ? (
                      <EyeCrossedIcon variant="link" />
                    ) : (
                      <EyeIcon variant="link" />
                    )
                }
              </TextButton>
              {
                data.fakeAccount
                  ? null
                  : (
                    <PasswordTooltip
                      password={data.password || ''}
                      visible={revealPassword}
                      onOutsideClick={this.hideRevealPassword}
                      onCloseClick={this.hideRevealPassword}
                      big
                    />
                  )
              }
            </Box>
          </Box>
        </DataListCell>

        <DataListCell spacer fixed />

        <DataListCell style={{ display: 'flex' }}>
          <Box pr="l">
            <BreachedWebsite
              data={data}
              ignored={ignored}
              isCalculating={isCalculating}
            />
          </Box>
          {
            (!data.fakeAccount)
            && (
              <>
                <Box pr="l">
                  <UnsafeWebsite
                    data={data}
                    ignored={ignored}
                  />
                </Box>
                <Box pr="l">
                  <PasswordStrength
                    data={data}
                    ignored={ignored}
                  />
                </Box>
                <Box mr="l">
                  <UniquePassword
                    data={data}
                    ignored={ignored}
                  />
                </Box>
              </>
            )
          }
        </DataListCell>

        <DataListCell spacer fixed />

        <DataListCell flexBasis="13em" mr="0">
          <Box display="flex" justifyContent="flex-end">
            <SecurityStatusActionDropdown
              data={data}
              extensionInstalled={extensionInstalled}
              visibility={data.toImprove || data.fakeAccount ? 'visible' : 'hidden'}
              onButtonClick={this.onActionButtonClick}
              onDeleteAccountClick={onDeleteAccountClick}
              onDeleteBreachedUnknownClick={this.onDeleteUnknownBreachClick}
              onIgnoreChange={this.onIgnoreChange}
            />
          </Box>
        </DataListCell>
      </DataListItem>
    );
  }
}

SecurityStatusListItem.propTypes = {
  currentRevealPasswordId: PropTypes.string.isRequired,
  data: PropTypes.object,
  extensionInstalled: PropTypes.bool,
  index: PropTypes.number.isRequired,
  isCalculating: PropTypes.func.isRequired,
  hasAccessToPro: PropTypes.bool,
  onAccountClick: PropTypes.func.isRequired,
  onIgnore: PropTypes.func.isRequired,
  onInteraction: PropTypes.func,
  spotlight: PropTypes.bool,
  scrolling: PropTypes.bool.isRequired,
  setCurrentRevealPassword: PropTypes.func.isRequired,
  updateIgnoreFilter: PropTypes.func.isRequired,
  onActionButtonClick: PropTypes.func,
  onDeleteAccountClick: PropTypes.func.isRequired,
  onDeleteUnknownBreachClick: PropTypes.func.isRequired,
  onRevealPasswordClick: PropTypes.func.isRequired,
};

SecurityStatusListItem.defaultProps = {
  data: {
    ignoreWarnings: false,
    breachKey: '',
  },
  extensionInstalled: null,
  onInteraction: () => { },
  onActionButtonClick: null,
  spotlight: config.spotlight,
  hasAccessToPro: false,
};

export default SecurityStatusListItem;
