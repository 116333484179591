import { createSelector } from 'reselect';
import { identity } from 'lodash';
import ms from 'ms';
import MILESTONES from '../lib/MilestonesHelper';
import { getExtensionBrowserName, isSafari } from '../lib/UserAgent';
import config from '../config';
import { isUnregisteredMode } from '../dashboard/selectors';

const { name: UNLOCK_PRO_MILESTONE, expiration: UNLOCK_PRO_END } = MILESTONES.Add5Pro;

export const getDaysLeft = (end = UNLOCK_PRO_END, start = new Date()) => Math.max(Math.ceil((end - start) / ms('1d')), 0);

export const getProFreeMilestoneConditionMet = createSelector(
  state => state.accounts,
  state => state.userData.isPro,
  (accounts, isPro) => !isPro && Object.keys(accounts).length >= 5,
);

const createMilestoneWatcher = (milestoneId, conditionWatcher, expirationDate) => {
  const selector = createSelector(
    state => state.userData.milestones,
    state => state.userData.isPro,
    conditionWatcher,
    (milestones, isPro, conditionMet) => (
      !isPro && (!!(milestones && milestones[milestoneId]) || conditionMet)
    ),
  );

  return state => selector(state) && !!getDaysLeft(expirationDate);
};

export const unlockProFreeWatcher = createMilestoneWatcher(
  UNLOCK_PRO_MILESTONE, getProFreeMilestoneConditionMet, UNLOCK_PRO_END,
);

export const showCongratsBanner = state => (
  unlockProFreeWatcher(state) && !state.userData.milestones[UNLOCK_PRO_MILESTONE]
);

export const isChangeYourPasswordWeek = () => {
  const startDate = new Date(2020, 1, 1).getTime(); // Sat Feb 01 2020 00:00:00
  const endDate = new Date(2020, 1, 8).getTime(); // Sat Feb 08 2020 00:00:00
  const currentDate = Date.now();
  return (currentDate >= startDate && currentDate < endDate);
};

export const isCoronaSpecial = () => {
  const startDate = new Date(2020, 2, 28).getTime(); // Sat Mar 28 2020 00:00:00
  const endDate = new Date(2020, 4, 16).getTime(); //  Sat May 16 2020 00:00:00
  const currentDate = Date.now();
  return (currentDate >= startDate && currentDate < endDate);
};

export const canDisplayBanners = state => state.mobile.loadedDevices;

export const showFilesBanner = (state) => {
  const {
    oeData: { date_added: dateRegistered },
    preferences: { updatedFileBannerDismissed },
  } = state;
  if (updatedFileBannerDismissed) {
    return false;
  }

  // TODO: Add register cutoff date here
  const startRegDate = new Date('2020-12-20').getTime();
  const regDate = new Date(dateRegistered).getTime();

  return regDate <= startRegDate;
};

export const showExtBanner = (state) => {
  const { preferences: { extBannerDismissed }, dashboard: { extensionConnected } } = state;
  const currentBrowser = getExtensionBrowserName();
  const isBrowserSupported = Object.keys(config.links).includes(currentBrowser);

  return isBrowserSupported && !extensionConnected && !extBannerDismissed;
};

export const showGetMobileBanner = (state) => {
  const { devices, preferences: { getMobileBannerDismissed } } = state;
  const mobileDevices = devices.filter(device => (device.device_type === 'Phone'));
  return !getMobileBannerDismissed && mobileDevices.length === 0;
};

export const isCurrentUserPro = createSelector(state => state.userData.isPro, identity);

export const getHasAccessToPro = state => state.userData.isPro
  || unlockProFreeWatcher(state)
  || isChangeYourPasswordWeek()
  || isCoronaSpecial();

export const showGetProFree = state => !getHasAccessToPro(state) && !!getDaysLeft();

export const isUpsellAllowed = createSelector(
  isCurrentUserPro,
  state => state.dashboard,
  (isPro, dashboard) => !isPro && !(isSafari() && dashboard.extensionConnected),
);

export const getRelevantUserKey = createSelector(
  state => state.user,
  state => state.dashboard.isUnregisteredMode,
  (user, isUnregisteredModeVal) => (isUnregisteredModeVal ? user.tempKey : user.key),
);

export const getRelevantUserId = createSelector(
  state => state.user,
  state => state.dashboard.isUnregisteredMode,
  state => state.userData,
  (user, isUnregisteredModeVal, userData) => (
    isUnregisteredModeVal ? user.tempUserId : userData.id
  ),
);

export const isUserAuthenticated = createSelector(
  state => state.oe.token,
  state => state.dashboard.isUnregisteredMode,
  (oeToken, isUnregisteredModeVal) => !!(oeToken || isUnregisteredModeVal),
);

export const isUnlocked = createSelector(
  getRelevantUserKey,
  key => !!key,
);

export const checkHibpUsernames = (state) => {
  const { isPro, breach_check: breachCheck } = state.userData;
  return isPro && breachCheck;
};

export const isTransitioningFromUnregisteredMode = createSelector(
  isUnregisteredMode,
  state => state.user.tempKey,
  state => state.user.tempUserId,
  (isUnregMode, tempKey, tempUserId) => !isUnregMode && !!tempKey && !!tempUserId,
);
