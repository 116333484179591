import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';

import Card from '../../componentLib/CardWrapper';

const ForgotMasterPasswordStep = ({
  cancelText,
  confirmText,
  children,
  onConfirm,
  onCancel,
  confirmDisabled,
  cancelButtonColor,
  title,
}) => (
  <Card
    p="xl"
    title={(
      <FormattedMessage id={title} />
    )}
    variant="secondary"
  >
    {children}
    <Box display="flex" mt="xl">
      {
        onConfirm ? (
          <Box mr="s">
            <Button
              size="mini"
              onClick={onConfirm}
              disabled={confirmDisabled}
            >
              <FormattedMessage id={confirmText} />
            </Button>
          </Box>
        ) : null
      }
      {
        onCancel ? (
          <Button
            size="mini"
            onClick={onCancel}
            color={cancelButtonColor}
          >
            <FormattedMessage id={cancelText} />
          </Button>
        ) : null
      }
    </Box>
  </Card>
);

ForgotMasterPasswordStep.propTypes = {
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmDisabled: PropTypes.bool,
  cancelButtonColor: PropTypes.string,
};

ForgotMasterPasswordStep.defaultProps = {
  title: 'resetAccount.newMasterPassword.title.newMasterPassword',
  cancelText: 'resetAccount.button.no',
  confirmText: 'resetAccount.button.yes',
  onConfirm: null,
  onCancel: null,
  confirmDisabled: false,
  cancelButtonColor: '',
};

export default ForgotMasterPasswordStep;
