import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch } from 'react-redux';

import Loading from '../app/components/Loading';
import SpotlightLogin from './components/SpotlightLogin';
import OESpotlightLogin from '../oe/components/OESpotlightLogin';
import { trackTimeout } from '../tracking/TrackingActions';

const SpotlightLoginContainer: React.FC = () => {
  const dispatch = useDispatch();
  return (
    <OESpotlightLogin
      renderWhileLoading={() => (
        <Loading
          onRetry={() => window.location.reload()}
          timeout={40}
          onTimeout={() => {
            (dispatch as ThunkDispatch<any, any, any>)(trackTimeout('SpotlightLoginContainer'));
          }}
        >
          <FormattedMessage
            id="dashboard.status.authenticatingUser"
            defaultMessage="Authenticating user..."
          />
        </Loading>
      )}
    >
      <SpotlightLogin />
    </OESpotlightLogin>
  );
};

export default SpotlightLoginContainer;
