import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bool, func } from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import Register from './Register';
import {
  registerOEUser,
  loginOEUser,
  authDropout,
  trackAuthShown,
  getOEUserData,
  registerLoginOEUserWithSocialMedia,
  trackRegisterOEUserError,
  validateUser,
} from '../OEActions';
import {
  authUpdateEmail,
  logoutUser,
} from '../../authentication/AuthenticationActions';
import AuthPageWrapper from './AuthPageWrapper';
import { showGetProFree } from '../../user/selectors';
import UserIdentity from './UserIdentity';
import EmbargoError from '../../app/components/EmbargoError';
import { trackEvent } from '../../tracking/TrackingActions';
import { MP_OE_REGISTER_INITIATED } from '../../tracking/MixpanelEvents';

const RegisterContainer = (props) => {
  const [registrationState, setRegistrationState] = useState(null);
  const [error, setError] = useState(undefined);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const onRegisterSubmit = async (register) => {
    dispatch(trackEvent(MP_OE_REGISTER_INITIATED, { context: register.context }));
    await dispatch(validateUser(register));
    setRegistrationState(register);
    setStep(1);
  };

  const onUserIdSubmit = async ({ firstName, lastName, country }) => {
    try {
      if (registrationState != null) {
        return await dispatch(
          registerOEUser({
            ...registrationState,
            firstName,
            lastName,
            country,
          }),
        );
      }
    } catch (e) {
      setError(e);
      if (e.status !== '451') {
        setStep(0);
        throw e;
      }
    }

    return undefined;
  };

  let Page;

  // eslint-disable-next-line no-restricted-globals
  if (error?.status === '451') {
    Page = (
      <EmbargoError
        message={(
          <FormattedMessage
            id="embargo.error.description.user"
          />
        )}
      />
    );
  } else if (step === 0) {
    Page = <Register {...props} error={error} onFormSubmit={onRegisterSubmit} />;
  } else {
    Page = (
      <UserIdentity
        {...props}
        onFormSubmit={onUserIdSubmit}
        mainCtaLabel={(
          <FormattedMessage
            id="register.action.main"
            defaultMessage="Register"
          />
        )}
        onBackClick={() => setStep(0)}
      />
    );
  }

  return (
    <AuthPageWrapper
      Page={Page}
      showHeaderMenu
      registerDropOut={() => {
        props.onDropOut({
          currentForm: 'register',
          cause: 'switched to login',
          mountTimestamp: Date.now(),
        });
      }}
    />
  );
};

RegisterContainer.propTypes = {
  showGetProFree: bool.isRequired,
  onDropOut: func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    oe, auth, user, preferences,
  } = state;
  return {
    oe,
    email: auth.email,
    user,
    language: preferences.language,
    showGetProFree: showGetProFree(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    getOEUserData,
    onDropOut: authDropout,
    onTryLogin: loginOEUser,
    onComponentShown: trackAuthShown,
    registerLoginOEUserWithSocialMedia,
    onEmailUpdate: authUpdateEmail,
    onSocialMediaError: trackRegisterOEUserError,
    onChangeClick: logoutUser,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(RegisterContainer));
