import { messages } from '../locales';

const MESSAGE_KEY = 'dashboard.note.details.emptyNoteName';

const messagesArray = Object.values(messages)
  .map(lang => lang[MESSAGE_KEY]);

const regexArray = messagesArray.map(msg => msg.replace('{int}', '(\\d+)'));

const regex = new RegExp(`^(?:${regexArray.join('|')})$`);

function getNoteNames(notes) {
  return Object.values(notes).map(note => note.title);
}

export default function getNextNoteName(notes, userLanguage) {
  const noteNames = getNoteNames(notes);
  let nextNumber = 1;
  const matchingNames = noteNames.map(name => name.match(regex)).filter(match => !!match);

  if (matchingNames.length) {
    matchingNames.forEach((match) => {
      const number = +match[0].match(/\d+/)[0];
      if (number >= nextNumber) {
        nextNumber = number + 1;
      }
    });
  }

  return `${messages[userLanguage][MESSAGE_KEY].replace('{int}', nextNumber)}`;
}
