const colorPalette = {
  gray: '#B2BDC7',
  blue: '#33ABF8',
  orange: '#F8760D',
  lilac: '#DC8EED',
  lemon: '#99D41B',
  teal: '#60CBCF',
};

const defaultColor = colorPalette.gray;

export {
  defaultColor,
  colorPalette,
};
