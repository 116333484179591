import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';

import debug from '../../debug';

import { loginSpotlightUser } from '../OEActions';

type Props = {
  renderWhileLoading: () => JSX.Element;
}

const log = debug.extend('OESpotlightLogin');

const OESpotlightLogin: React.FC<Props> = ({ renderWhileLoading, children }) => {
  const dispatch = useDispatch();
  const [nonSpotlightEnvironment, setNonSpotlightEnvironment] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function login(): Promise<void> {
      try {
        log('loginSpotlightUser start');
        await dispatch(loginSpotlightUser());
        log('loginSpotlightUser success');
        setLoading(false);
      } catch (e) {
        setNonSpotlightEnvironment(true);
        log('loginSpotlightUser error', e);
      }
    }

    login();
  }, [dispatch]);

  if (loading) {
    return renderWhileLoading();
  }

  if (nonSpotlightEnvironment) {
    return (
      <Box>
        <Text variant="dark">
          <FormattedHTMLMessage id="dashboard.spotlight.error.environment" defaultMessage="Oops the page was not accessible. Try going to <a href='https:\\passwords.avira.com'>passwords.avira.com<a>." />
        </Text>
      </Box>
    );
  }

  return (
    <>{children}</>
  );
};

export default OESpotlightLogin;
