import React from 'react';
import PropTypes from 'prop-types';

const ActionButton = ({
  label,
  potentialLabels,
  onClick,
  disabled,
  loading,
  block,
  color,
  type,
  className,
  tabIndex,
  size,
  fullHeight,
}) => (
  <button // eslint-disable-line react/button-has-type
    type={type}
    className={`c-button ${
      color ? `c-button--${color}` : ''
    } ${
      loading ? 'c-button--loading' : ''
    } ${
      block ? 'c-button--block' : ''
    } ${
      size ? `c-button--${size}` : ''
    } ${
      fullHeight ? 'c-button--full-height' : ''
    } ${className}`}
    onClick={onClick}
    disabled={disabled || loading}
    tabIndex={tabIndex}
  >
    <span className="u-d-b">{label}</span>
    {
      potentialLabels.map(potentialLabel => (
        <span className="u-fake-label u-d-b" key={potentialLabel}>
          {potentialLabel}
        </span>
      ))
    }
  </button>
);

ActionButton.propTypes = {
  label: PropTypes.string,
  potentialLabels: PropTypes.array,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  block: PropTypes.bool,
  color: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'button']),
  className: PropTypes.string,
  tabIndex: PropTypes.number,
  size: PropTypes.string,
  fullHeight: PropTypes.bool,
};

ActionButton.defaultProps = {
  label: '',
  potentialLabels: [],
  onClick: () => { },
  disabled: false,
  loading: false,
  block: true,
  type: 'submit',
  className: '',
  color: '',
  tabIndex: 0,
  size: '',
  fullHeight: false,
};

export default ActionButton;
