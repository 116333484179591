import { AuthData } from '@avira-pwm/helpers/Auth';
import { AnyAction, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { SpotlightMessaging, GetRequest } from '@avira-pwm/messenger/SpotlightMessaging';

import DashboardMessenger from '@avira-pwm/messenger/dashboard';
import {
  SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION,
  SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST,
  SPOTLIGHT_SET_SPOTLIGHT_CONNECTED,
  SPOTLIGHT_SET_PERMISSION_REQUESTED,
  SPOTLIGHT_SET_SPOTLIGHT_USER_DATA,
  SpotlightActionTypes,
} from './SpotlightActionTypes';

import { State as SpotlightState } from './SpotlightReducer';
import { State as OeState } from '../oe/OEReducer';
import { logoutUser, sendSync, setAuthData } from '../authentication/AuthenticationActions';
import { loginWithSpotlightUser } from '../oe/OEActions';
import { getUserData } from '../user';

import { TrackingActions, MixpanelEvents } from '../tracking';

import debug from '../debug';

const {
  MP_NATIVE_PERMISSION_GRANTED,
  MP_NATIVE_PERMISSION_ALLOW_COMMUNICATION,
  MP_SPOTLIGHT_WELCOME_NEXT,
  MP_SPOTLIGHT_WELCOME_BACK,
} = MixpanelEvents;

const { trackEvent } = TrackingActions;

type State = {
  spotlight: SpotlightState;
  oe: OeState;
}

type ExtraArguments = {
  dashboardMessenger: DashboardMessenger;
  oeService: any;
  licenseService: any;
};

type SpotlightThunkAction<R> = ThunkAction<R, State, ExtraArguments, AnyAction>;

const log = debug.extend('SpotlightActions');

const setExtensionHasNativeMessagingPermission = (
  (hasPermission: boolean): SpotlightActionTypes => ({
    type: SPOTLIGHT_SET_NATIVE_MESSAGING_PERMISSION, hasPermission,
  })
);

const setExtensionManualPermissionRequest = (
  (manualRequest: boolean): SpotlightActionTypes => ({
    type: SPOTLIGHT_SET_MANUAL_PERMISSION_REQUEST, manualRequest,
  })
);

const setSpotlightConnected = (
  (spotlightConnected: boolean): SpotlightActionTypes => ({
    type: SPOTLIGHT_SET_SPOTLIGHT_CONNECTED, spotlightConnected,
  })
);

const setExtensionPermissionRequested = (
  (permissionRequested: boolean): SpotlightActionTypes => ({
    type: SPOTLIGHT_SET_PERMISSION_REQUESTED, permissionRequested,
  })
);

const setSpotlightUserData = (
  (spotlightUserData: {
    email: null | string;
    id: null | string;
  }): SpotlightActionTypes => ({
    type: SPOTLIGHT_SET_SPOTLIGHT_USER_DATA, spotlightUserData,
  })
);

export const extensionCheckNativePermissions = (): SpotlightThunkAction<Promise<undefined>> => (
  async (dispatch, getState, { dashboardMessenger }) => (
    new Promise((resolve) => {
      if (dashboardMessenger.isConnected()) {
        dashboardMessenger.send('dashboard:extension:checkNativePermissions', null, (err, hasPermission) => {
          dispatch(setExtensionHasNativeMessagingPermission(hasPermission));
          resolve();
        });
      } else {
        resolve();
      }
    })
  )
);

export const extensionRequestNativePermissions = (): SpotlightThunkAction<Promise<undefined>> => (
  async (dispatch, getState, { dashboardMessenger }) => (
    new Promise((resolve) => {
      if (dashboardMessenger.isConnected()) {
        dashboardMessenger.send(
          'dashboard:extension:requestNativePermissions',
          null,
          (_err, { error, permissionGranted }: { error: any; permissionGranted: boolean }) => {
            if (error || error === undefined) {
              dispatch(setExtensionManualPermissionRequest(true));
            } else {
              dispatch(setExtensionManualPermissionRequest(false));
              dispatch(setExtensionPermissionRequested(true));
              dispatch(setExtensionHasNativeMessagingPermission(permissionGranted));
            }
            resolve();
          },
        );
      } else {
        resolve();
      }
    })
  )
);

export const connectToSpotlight = (): SpotlightThunkAction<void> => (
  async (dispatch, getState, { dashboardMessenger }) => (
    dashboardMessenger.send(
      'dashboard:extension:connectToSpotlight',
      null,
      (_err, connected: boolean) => {
        dispatch(setSpotlightConnected(!!connected));
      },
    )
  )
);

export const getSpotlightUser = (): SpotlightThunkAction<void> => (
  async (dispatch, getState, { dashboardMessenger }) => {
    log('getting spotlight user data');
    dashboardMessenger.send(
      'dashboard:extension:getSpotlightUser',
      null,
      (_err, data: GetRequest<SpotlightMessaging['SetSpotlightUser']>) => {
        log('getSpotlightUser %O', data);
        dispatch(setSpotlightUserData(data));
      },
    );
  }
);

export const syncAuthDataWithSpotlight = (userId: string): SpotlightThunkAction<void> => (
  (dispatch, getState, { dashboardMessenger, licenseService }) => {
    const actionLog = log.extend('syncAuthDataWithSpotlight');

    return new Promise((resolve, reject) => {
      actionLog('syncing auth data with spotlight');
      dashboardMessenger.send(
        'dashboard:extension:getSpotlightAuthData',
        { userId },
        async (err, { error, data }: { error?: any; data?: AuthData } = {}) => {
          if (err && typeof err.message === 'string') {
            actionLog('error', err.message);
            reject(new Error(err.message));
          } else if (error) {
            actionLog('error', error.message);
            reject(new Error(error));
          } else {
            actionLog('received data');
            const {
              pwmKey: key,
              pwmKey2: key2,
              pwmLockReason: lockReason,
            } = data ?? ({} as any as AuthData);

            const { oe } = getState();
            const timestamp = Date.now();
            actionLog(`getting auth data; oe token present ${!!oe.token}; key present: ${!!key}`);
            const authData = await licenseService.getAuthData(oe.token, oe.email);
            await dispatch(setAuthData({
              key, key2, authToken: authData.auth_token, lockReason, timestamp,
            }));
            await dispatch(getUserData());
            resolve();
          }
        },
      );
    });
  }
);

export const syncData = (): SpotlightThunkAction<void> => (
  async (dispatch) => {
    dispatch(sendSync());
  });

export const changeToSpotlightUser = (): SpotlightThunkAction<void> => (
  async (dispatch, getState, { dashboardMessenger, licenseService }) => {
    const actionLog = log.extend('changeToSpotlightUser');
    return new Promise((resolve) => {
      actionLog('changing to spotlight user');
      dashboardMessenger.send('dashboard:extension:getSpotlightUserOAuth', null, async (_, { error, data }) => {
        if (!error) {
          await dispatch(logoutUser());
          await dispatch(loginWithSpotlightUser(data));
          const { oe } = getState();
          actionLog('getting user id - oe token present?', !!oe.token);
          const userId = await licenseService.getUserId(oe.token);
          await dispatch(syncAuthDataWithSpotlight(userId));
          dispatch(syncData());
          resolve();
        }
      });
    });
  }
);

export const trackNativeMessagingPermissionGranted = () => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_NATIVE_PERMISSION_GRANTED));
};

export const trackAllowCommunication = () => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_NATIVE_PERMISSION_ALLOW_COMMUNICATION));
};

export const trackSpotlightWelcomeNext = () => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_SPOTLIGHT_WELCOME_NEXT));
};

export const trackSpotlightWelcomeBack = () => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_SPOTLIGHT_WELCOME_BACK));
};
