import 'rc-slider/assets/index.css';

import React from 'react';
import PropTypes from 'prop-types';
import RCSlider from 'rc-slider';

const Slider = ({ marks, value, onChange }) => {
  const max = Object.keys(marks).length - 1;
  return (
    <RCSlider
      marks={marks}
      step={null}
      included={false}
      min={0}
      max={max}
      onChange={onChange}
      value={value}
      handleStyle={{
        backgroundColor: '#007eff',
        border: 0,
        height: 16,
        width: 16,
        boxShadow: '0px 1px 1px 1px rgba(0, 0, 0, 0.5)',
      }}
      dotStyle={{
        height: 7,
        width: 7,
        backgroundColor: '#e6eaec',
        border: 0,
      }}
      railStyle={{
        height: 2,
        top: 6,
      }}
    />
  );
};

Slider.propTypes = {
  value: PropTypes.number.isRequired,
  marks: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Slider;
