import React, { useState, useRef, useEffect } from 'react';
import styled from 'pwm-components/styled';
import {
  element, node, bool, func,
} from 'prop-types';
import Arrow from 'pwm-components/components/Arrow';
import Box from 'pwm-components/components/Box';

const CollapseTitle = styled(Box)`
  transition: margin 0.3s ease-out;
  text-transform: uppercase;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

const Collapse = styled('div')`
  background: ${({ theme }) => theme.semanticColors.foreground};
  padding: 32px;
  width: 100%;
  min-width: 680px;
  cursor: ${({ collapsed }) => (collapsed ? 'pointer' : 'default')};
  margin-bottom: 8px;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.25);
  position: relative;
`;

const CollapseContent = styled(Box)`
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
`;

const GetStartedDetailsCard = ({
  children, icon, title, openOnMount, onClick,
}) => {
  const [collapsed, setCollapsed] = useState(true);
  const contentRef = useRef(null);
  const iconRef = useRef(null);

  function animateContent() {
    const contentElement = contentRef.current;
    const end = collapsed ? contentElement.scrollHeight : 0;

    iconRef.current.style.opacity = 0;
    contentElement.style.height = `${end}px`;

    setCollapsed(prevCollapsed => !prevCollapsed);

    const transitionListener = () => {
      contentElement.removeEventListener('transitionend', transitionListener);
      iconRef.current.style.opacity = 1;
    };

    contentElement.addEventListener('transitionend', transitionListener);
  }

  function handleClick() {
    animateContent();

    if (onClick) {
      onClick();
    }
  }

  useEffect(() => {
    if (openOnMount) {
      handleClick();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Collapse
      collapsed={collapsed}
      onClick={collapsed ? handleClick : null}
    >
      <Box
        display="flex"
        alignItems="center"
      >
        <Box
          style={{ flex: '1 1 auto' }}
        >
          <CollapseTitle onClick={collapsed ? null : handleClick}>
            <Box
              height={10}
              width={10}
              mr="xs"
            >
              <Arrow
                direction={collapsed ? 'right' : null}
                display="inline-block"
                style={{
                  position: 'absolute',
                }}
              />
            </Box>
            {title}
          </CollapseTitle>
          <CollapseContent
            ref={contentRef}
          >
            <Box mt="16px" mb="18px" pl="16px" pr="24px">
              {children}
            </Box>
          </CollapseContent>
        </Box>
        <Box>
          <Box display="flex" ref={iconRef}>
            {icon}
          </Box>
        </Box>
      </Box>
    </Collapse>
  );
};

GetStartedDetailsCard.propTypes = {
  children: node.isRequired,
  icon: element,
  title: element.isRequired,
  openOnMount: bool,
  onClick: func,
};

GetStartedDetailsCard.defaultProps = {
  openOnMount: false,
  icon: null,
  onClick: null,
};

export default GetStartedDetailsCard;
