import { NOTES_PREFERENCES_SCROLLPOSITION } from './NoteActionTypes';

type State = Readonly<{
  scrollPosition: number;
}>;

function getInitialState(): State {
  return {
    scrollPosition: 0,
  };
}

interface SetScrollPosition {
  type: typeof NOTES_PREFERENCES_SCROLLPOSITION;
  value: number;
}

export type Action = SetScrollPosition;

export default function accountPreferencesReducer(
  state: State = getInitialState(),
  action: Action,
): State {
  switch (action.type) {
    case NOTES_PREFERENCES_SCROLLPOSITION:
      return {
        ...state,
        scrollPosition: action.value,
      };
    default:
      return state;
  }
}
