import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  syncGetData,
  initSyncListeners,
  clearData,
} from '../DashboardActions';

import {
  trackForbiddenCharsInUsernames,
} from '../../accounts/AccountActions';

class DataWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.initSyncListeners();
    this.props.syncGetData().then(() => {
      this.setState({ loaded: true });
      this.props.trackForbiddenCharsInUsernames();
    });
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  render() {
    if (this.state.loaded) {
      return this.props.children;
    }

    return <div />;
  }
}

DataWrapper.propTypes = {
  children: PropTypes.element,
  initSyncListeners: PropTypes.func.isRequired,
  syncGetData: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  trackForbiddenCharsInUsernames: PropTypes.func.isRequired,
};

DataWrapper.defaultProps = {
  children: <div />,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  syncGetData,
  initSyncListeners,
  clearData,
  trackForbiddenCharsInUsernames,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DataWrapper);
