import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { injectIntl, WrappedComponentProps } from 'react-intl';

import AuthenticationHelper from '../../lib/AuthenticationHelper';
import { validatePasswordStrength } from '../../lib/AuthenticationValidator';
import SpotlightMasterPassword from './SpotlightMasterPassword';
import SpotlightMasterPasswordRedirect from './SpotlightMasterPasswordRedirect';
import MasterPassword, { Props as MPProps } from './MasterPassword';
import ConfigRenderer from '../../componentLib/ConfigRenderer';

const masterPasswordFieldError = [
  'error.unknown',
  'error.outdated.extension.version',
  'error.register.passwordIsNotStrongEnough',
  'masterPassword.error.breached',
  'masterPassword.error.create.empty',
  'masterPassword.error.invalid',
  'masterPassword.error.provide.invalid',
  'masterPassword.error.provide.empty',
];
const masterPasswordConfirmErrors = [
  'masterPassword.error.verify.notIdentical',
  'masterPassword.error.verify.empty',
];

const masterPasswordCheckboxErrors = [
  'masterPassword.error.noConsent',
];

type Props = WrappedComponentProps & RouteComponentProps
& Pick<MPProps, 'usageAs' | 'isUnregisteredMode'>
& {
  mountTimestamp: number;
  onDropOut: (e: any) => void;
  onLogout: (usage: string, action: string) => void;
  onBackButtonClick?: (usage: string, action: string) => void;
  onSubmit: (password: any) => void;
  onSuccessRedirect: string;
  onMasterPasswordInitiated: () => void;
  user: any;
  oe: any;
};

type State = {
  password: string;
  passwordConfirm: string;
  checkboxConfirm: boolean;
  loading: boolean;
  error: any;
};

class MasterPasswordContainer extends React.Component<Props, State> {
  private authHelper: any;

  private focusTimeout: number | null;

  private passwordRef: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);

    const { intl } = this.props;
    this.authHelper = new AuthenticationHelper(intl);

    this.onLogout = this.onLogout.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
    this.onPasswordConfirmChange = this.onPasswordConfirmChange.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);

    this.state = {
      password: '',
      passwordConfirm: '',
      loading: false,
      error: null,
      checkboxConfirm: false,
    };

    this.focusTimeout = null;
    this.passwordRef = React.createRef();
  }

  public componentDidMount(): void {
    this.focusTimeout = window.setTimeout(() => {
      if (this.passwordRef && this.passwordRef.current) {
        this.passwordRef.current.focus();
      }
    }, 0);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { user } = this.props;
    if ((user.pending && !nextProps.user.pending)
      || (nextProps.user && user.error !== nextProps.user.error)) {
      this.setState({
        error: nextProps.user.error,
      });
    }
  }

  public componentWillUnmount(): void {
    if (this.focusTimeout) {
      clearTimeout(this.focusTimeout);
    }
  }

  private onLogout(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault();
    const {
      onDropOut, usageAs, mountTimestamp, onLogout,
    } = this.props;
    onDropOut({
      usageAs,
      mountTimestamp,
    });
    onLogout(usageAs, 'change user');
  }

  private onBackButtonClick(e: any): void {
    e.preventDefault();
    const { onBackButtonClick, usageAs } = this.props;
    if (onBackButtonClick) {
      onBackButtonClick(usageAs, 'back button');
    }
  }

  private async onFormSubmit(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    const {
      onMasterPasswordInitiated, usageAs, onSubmit,
      mountTimestamp,
      history, onSuccessRedirect,
    } = this.props;
    const { password, passwordConfirm, checkboxConfirm } = this.state;
    this.setState({ loading: true });

    if (onMasterPasswordInitiated) {
      onMasterPasswordInitiated();
    }

    try {
      if (usageAs === 'create') {
        await onSubmit({
          usageAs,
          password,
          passwordVerification: passwordConfirm,
          consent: checkboxConfirm,
          mountTimestamp,
        });
      } else {
        await onSubmit(password);
      }

      history.push(onSuccessRedirect);
    } catch (error) {
      this.setState({ loading: false, error });
    }
  }

  private onPasswordChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      password: e.target.value,
      error: null,
    });
  }

  private onPasswordConfirmChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      passwordConfirm: e.target.value,
      error: null,
    });
  }

  private onCheckboxChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      checkboxConfirm: e.target.checked,
      error: null,
    });
  }


  // eslint-disable-next-line complexity
  public render(): JSX.Element {
    const {
      usageAs, isUnregisteredMode, oe, user,
    } = this.props;
    const {
      error, loading, password, passwordConfirm, checkboxConfirm,
    } = this.state;

    let errorPassword = null;
    let errorConfirm = null;
    let errorCheckbox = null;

    if (error) {
      errorPassword = masterPasswordFieldError.indexOf(error.key) !== -1
        ? this.authHelper.getError(error) : null;

      errorConfirm = masterPasswordConfirmErrors.indexOf(error.key) !== -1
        ? this.authHelper.getError(error) : null;

      errorCheckbox = masterPasswordCheckboxErrors.indexOf(error.key) !== -1
        ? this.authHelper.getError(error) : null;
    }

    let passwordScore: {
      score: number;
      feedback?: Array<string>;
    } | null = null;
    if (usageAs === 'create' && password && password.length) {
      passwordScore = validatePasswordStrength(password);

      if (passwordScore) {
        passwordScore.feedback = this.authHelper.prepareList(passwordScore.feedback);
      }
    }

    const props = {
      usageAs,
      loading,
      passwordRef: this.passwordRef,
      userEmail: oe.email,
      isUnregisteredMode,
      password,
      passwordConfirm,
      checkboxConfirm,
      errorPassword,
      errorConfirm,
      errorCheckbox,
      passwordScore,
      user,
      onPasswordChange: this.onPasswordChange,
      onPasswordConfirmChange: this.onPasswordConfirmChange,
      onCheckboxChange: this.onCheckboxChange,
      onFormSubmit: this.onFormSubmit,
      onLogout: this.onLogout,
    };

    return (
      <>
        <ConfigRenderer condition="spotlight">
          {
            usageAs === 'create'
              ? <SpotlightMasterPasswordRedirect />
              : <SpotlightMasterPassword {...props} />
          }
        </ConfigRenderer>
        <ConfigRenderer condition="spotlight" negate>
          <MasterPassword
            {...props}
          />
        </ConfigRenderer>
      </>
    );
  }
}

export default withRouter(injectIntl(MasterPasswordContainer));
