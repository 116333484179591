/* global SymBfw */

import debug from '../debug';

const log = debug.extend('nlok-libs');

const nlokLogger = {
  debug: log,
  log,
  error: log,
  warn: log,
  info: log,
};

Object.assign(SymBfw.logger, nlokLogger);
