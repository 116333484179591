import React from 'react';
import {
  injectIntl, FormattedMessage, WrappedComponentProps,
} from 'react-intl';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import Button from 'pwm-components/components/Button';
import Input from 'pwm-components/components/Input';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';
import Paragraph from 'pwm-components/components/Paragraph';
import FavoriteIcon from 'pwm-components/icons/Favorite';
import FavoriteOutlineIcon from 'pwm-components/icons/FavoriteOutline';
import ModifiedAt from 'pwm-components/components/ModifiedAt';
import { ModelNames } from '@avira-pwm/sync/ModelMerge';
import { MaxLengths, DataStructureV1orV2 } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';
import { MAX_LENGTHS as ACCOUNT_MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/Account';
import { MAX_LENGTHS as NOTE_MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/Note';
import { MAX_LENGTHS as CREDIT_CARD_MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import tabIndex from '../../lib/TabIndexConfig';
import MoreOptions, { Props as MoreOptionsProps } from '../../accounts/components/MoreOptions';

const HeaderBox = styled(Box)`
  z-index: 10;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.2);
  background: ${({ theme: { mode, semanticColors: { background, foregroundSecondary } } }) => (
    mode === 'light' ? background : foregroundSecondary
  )};
`;

const CreatedModifiedBox = styled(Box)<{ show?: boolean }>`
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: opacity ${({ theme: { transition } }) => transition.duration.long};
`;

export type Props = WrappedComponentProps & {
  addMode: boolean;
  loading?: boolean;
  isFavorite?: boolean;
  changesMade: boolean;
  focusNameField: boolean;
  entityName: ModelNames;
  nameLabel: string;
  nameValue: string;
  namePlaceholderId?: string;
  error?: string;
  createdDate?: string | null;
  modifiedDate?: string | null;
  iconElement?: JSX.Element | null;
  onMenuOpen?: () => void;
  headerPopup?: JSX.Element | null;
  extraMenuItems: MoreOptionsProps['menuItems'];
  onChange: (name: string, e: any, callback?: Function) => void;
  onCancelClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onFavoriteClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onDeleteClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  handleSubmit: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const MAX_LENGTHS: Partial<Record<ModelNames, MaxLengths<DataStructureV1orV2>>> = {
  Account: ACCOUNT_MAX_LENGTHS,
  Note: NOTE_MAX_LENGTHS,
  CreditCard: CREDIT_CARD_MAX_LENGTHS,
};

class MyDataDetailsPageHeader extends React.Component<Props> {
  private nameRef: any;

  public static defaultProps = {
    iconElement: null,
    focusNameField: false,
    error: '',
    loading: false,
    createdDate: '',
    modifiedDate: '',
    isFavorite: false,
    extraMenuItems: [],
    onMenuOpen: () => {},
  };

  constructor(props: Props) {
    super(props);

    this.nameRef = React.createRef();
  }

  // eslint-disable-next-line complexity
  public render(): JSX.Element {
    const {
      isFavorite, addMode, changesMade, iconElement,
      onCancelClick, onDeleteClick, onFavoriteClick, handleSubmit, entityName, nameLabel,
      nameValue, namePlaceholderId, onChange, error, loading, intl, createdDate, modifiedDate,
      extraMenuItems, headerPopup, onMenuOpen,
    } = this.props;

    return (
      <HeaderBox
        position="absolute"
        top="0"
        left="0"
        right="0"
        pt="s"
        pb="l"
        height="116px"
      >
        <Box mx="auto" px="l" minWidth="592px" maxWidth="1102px" position="relative">
          <CreatedModifiedBox show={!addMode}>
            <Paragraph mb="s" textAlign="right">
              <Text size="h5" variant="disabled" mr="m">
                {createdDate && createdDate.length > 0 ? (
                  <>
                    <FormattedMessage id="dashboard.account.details.created" defaultMessage="Created" />
                    {' '}
                    <ModifiedAt
                      showDay
                      showTime
                      value={createdDate}
                    />
                  </>
                ) : ''}
              </Text>
              <Text size="h5" variant="disabled">
                {modifiedDate && modifiedDate.length > 0 ? (
                  <>
                    <FormattedMessage id="dashboard.account.details.modified" defaultMessage="Modified" />
                    {' '}
                    <ModifiedAt
                      showDay
                      showTime
                      value={modifiedDate}
                    />
                  </>
                ) : ''}
              </Text>
            </Paragraph>
          </CreatedModifiedBox>
          <Grid
            size="no-padding"
            justify="spaceBetween"
          >
            <Column>
              <Input
                id="a-name-input"
                inputSize="large"
                ref={this.nameRef}
                tabIndex={tabIndex.mainFormFields}
                placeholder={
                  namePlaceholderId
                    ? intl.formatMessage({
                      id: namePlaceholderId,
                    })
                    : intl.formatMessage({
                      id: 'dashboard.account.details.namePlaceholder',
                      defaultMessage: 'e.g. Avira',
                    })
                }
                value={nameValue}
                maxLength={MAX_LENGTHS[entityName]?.[nameLabel]}
                onChange={e => onChange(nameLabel, e.target.value)}
                error={error}
                iconLeft={iconElement}
                icons={[
                  <MoreOptions
                    key="labelMoreOptions"
                    disabled={addMode}
                    onDropdownShown={onMenuOpen}
                    menuItems={[
                      ...extraMenuItems,
                      {
                        labelId: 'dashboard.account.details.delete',
                        menuItemAttrs: {
                          id: 'a-delete-button',
                          onClick: onDeleteClick,
                        },
                      },
                    ]}
                  />,
                  <TextButton id="a-favorite-data-item-button" className={isFavorite ? 'a-favorite' : ''} key="labelFavorite" disabled={addMode} onClick={onFavoriteClick}>
                    {
                      isFavorite
                        ? <FavoriteIcon variant={addMode ? 'disabled' : 'active'} />
                        : <FavoriteOutlineIcon variant={addMode ? 'disabled' : 'dark'} />
                    }
                  </TextButton>,
                ]}
              />
            </Column>
            <Column size="auto">
              <Grid size="no-padding">
                <Box mx="xs">
                  <Button
                    id="a-save-button"
                    variant="primary"
                    loading={loading}
                    disabled={!(changesMade || addMode)}
                    onClick={handleSubmit}
                    tabIndex={tabIndex.primaryActionFields}
                  >
                    <FormattedMessage
                      id="dashboard.account.details.save"
                      defaultMessage="Save"
                    />
                  </Button>
                </Box>
                <Button
                  id="a-close-button"
                  variant="secondary"
                  loading={loading}
                  onClick={onCancelClick}
                  tabIndex={tabIndex.secondaryActionFields}
                >
                  <FormattedMessage
                    id="dashboard.account.details.close"
                    defaultMessage="Close"
                  />
                </Button>
              </Grid>
            </Column>
          </Grid>
          {headerPopup}
        </Box>
      </HeaderBox>
    );
  }
}

export default injectIntl(MyDataDetailsPageHeader);
