import React from 'react';
import { connect } from 'react-redux';
import withBreadcrumbs, { BreadcrumbsProps, BreadcrumbsRoute, Options } from 'react-router-breadcrumbs-hoc';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';

import TextButton from 'pwm-components/components/TextButton';

import { creditCardtTitleHelper } from '../../wallet/WalletHelpers';

import StyledBreadcrumbs from './StyledBreadcrumbs';
import * as SpotlightAPI from '../../lib/SpotlightAPI';

type EntityType = 'passwords' | 'notes' | 'wallet';

type EntityMapEntry = {
  reducer: string;
  entityTitle: (entity: any, intl: any) => React.ReactNode;
}

const entityMap: { [K in EntityType]: EntityMapEntry } = {
  passwords: {
    reducer: 'accounts',
    entityTitle: (entity: any) => entity.label || entity.domain,
  },
  notes: {
    reducer: 'notes',
    entityTitle: (entity: any) => entity.title,
  },
  wallet: {
    reducer: 'creditCards',
    entityTitle: (entity: any, intl: any) => (
      entity.title || creditCardtTitleHelper(intl, entity.cardNumber)
    ),
  },
};

type EntityProps = {
  entity: EntityType;
  data?: any;
  id?: string;
};

const mapEntityNameToProps = (state: any, props: any): EntityProps => {
  const { entity, id } = props.match.params as { entity: EntityType; id: string };
  const { reducer } = entityMap[entity];
  const data = (state[reducer] && state[reducer][id]) || null;

  return {
    entity,
    data,
    id,
  };
};

const mapPageNameToProps = (_: any, props: any): { pageName: string } => {
  const { pageName } = props.match.params as { pageName: string };
  return { pageName };
};

const EntityBreadcrumb: React.FC<EntityProps & WrappedComponentProps> = ({
  entity, data, id, intl,
}) => {
  if (data) {
    if (entityMap[entity]) {
      return entityMap[entity].entityTitle(data, intl);
    }

    return data.id;
  }

  return id;
};

const EntityNameBreadcrumb: React.FC<EntityProps> = ({ entity }) => (
  <FormattedMessage id={`dashboard.spotlight.breadcrumbs.${entity}`} />
);

const PageNameBreadcrumb: React.FC<{ pageName: string }> = ({ pageName }) => (
  <FormattedMessage id={`dashboard.spotlight.breadcrumbs.${pageName}`} />
);

const PrivacyEntity: React.FC = () => (
  <TextButton
    onClick={() => {
      SpotlightAPI.closeHtml('');
    }}
  >
    <FormattedMessage
      id="dashboard.spotlight.breadcrumbs.privacy"
      defaultMessage="Privacy"
    />
  </TextButton>
);

const NewEntity: React.FC = () => (
  <FormattedMessage
    id="spotlight.menu.new"
    defaultMessage="New"
  />
);

const config: BreadcrumbsRoute[] = [
  {
    path: '/',
    breadcrumb: (): JSX.Element => (<EntityNameBreadcrumb entity="passwords" />),
  },
  { path: '/mydata/:entity', breadcrumb: connect(mapEntityNameToProps)(EntityNameBreadcrumb) },
  { path: '/mydata/:entity/new', breadcrumb: NewEntity },
  { path: '/mydata/:entity/:id', breadcrumb: null },
  { path: '/mydata/:entity/:id/edit', breadcrumb: connect(mapEntityNameToProps)(injectIntl(EntityBreadcrumb)) },
  {
    path: '/:pageName',
    breadcrumb: connect(mapPageNameToProps)(PageNameBreadcrumb),
  },
  {
    path: '/:mainPage/:pageName',
    breadcrumb: connect(mapPageNameToProps)(PageNameBreadcrumb),
  },
];

const options: Options = {
  excludePaths: [
    '/login',
    '/register',
    '/mydata',
    '/create-master-password',
    '/unlock',
    '/spotlight-onboarding',
    '/verify-avira-account',
    '/enter-master-password',
    '/verify-email',
    '/spotlight',
    '/spotlight-login',
    '/spotlight-unregistered',
  ],
};

type Props = {
  breadcrumbs: BreadcrumbsProps[];
}

const breadcrumbDisplayFilter = [
  '/mydata/passwords',
];

const BreadcrumbsContainer: React.FC<Props> = ({ breadcrumbs }) => {
  const filteredBreadcrumbs = breadcrumbs.filter(breadcrumb => (
    breadcrumb.breadcrumb
    && !(breadcrumb.match.path === '/' && breadcrumbDisplayFilter.find(p => breadcrumb.location.pathname.indexOf(p) === 0))
  ));

  const privacy = {
    title: <PrivacyEntity />,
  };

  return (
    <StyledBreadcrumbs
      items={[
        privacy,
        ...filteredBreadcrumbs.map(({ breadcrumb, match }) => ({
          path: match.url,
          title: breadcrumb,
        })),
      ]}
    />
  );
};

export default withBreadcrumbs(config, options)(BreadcrumbsContainer as React.FC<Omit<Props, 'breadcrumbs'>>);
