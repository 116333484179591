import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../app/store';
import Loading from '../../app/components/Loading';
import nlokHelper from '../helper';
import { logoutUser } from '../../authentication/AuthenticationActions';

const OCLWrapper: React.FC = ({ children }) => {
  const oeToken = useSelector<RootState, string | null>(({ oe }) => oe.token);
  const expiresAt = useSelector<RootState, number | null>(({ oe }) => oe.expiresAt);
  const machineGUID = useSelector<RootState, string | null>(({ nlokData }) => nlokData.machineGuid);

  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function init(): Promise<void> {
      setLoaded(false);

      if (oeToken && expiresAt && machineGUID) {
        try {
          await nlokHelper.openConnection(oeToken, expiresAt);
        } catch (e) {
          dispatch(logoutUser('OCLWrapper', 'ocl error', e.message));
          return;
        }
      }

      setLoaded(true);
    }

    init();

    return () => {
      nlokHelper.closeConnection();
    };
  }, [dispatch, oeToken, expiresAt, machineGUID]);

  if (!loaded) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default OCLWrapper;
