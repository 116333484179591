//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//

import ItemManager from './VTItemManager';
import constants from './VTConstants';

const {
  utils: {
    isNil,
    isEmptyObject,
    isString,
  },
  logger
} = SymBfw;

const { TAG } = constants;

export default class NoteManager extends ItemManager {
  /**
   * @constructor NoteManager
   * @desc NoteManager interface.
   * @return An instance of the Item.
   */
  constructor() {
    super('NoteManager');
    this.items = {};
    this.tagType = TAG.NOTE;
  }

  /**
   * @async
   * @function search
   * @desc Searches the collection given the input key from UI.
   * @param {string} searchKey The key you want to search on.
   * @param {boolean} isFavourite if the isFavourite=ture then will search only favoite.
   * @returns {Promise<string[]>} Returns the items matches the key.
   */
  async search(searchKey, isFavourite) {
    const searchResult = [];
    if (isEmptyObject(this.items)) {
      logger.info('Empty vault');
      return searchResult;
    }
    if (!isString(searchKey)) {
      throw new Error('Search key cannot be null');
    }

    for (const key of Object.keys(this.items)) {
      const noteItem = this.items[key];
      if (isFavourite) {
        const isFavouriteItem = noteItem.getFavorite();
        if (isNil(isFavouriteItem) || isFavouriteItem === false) {
          continue; // eslint-disable-line no-continue
        }
      }
      const title = noteItem.getTitle();
      searchKey = searchKey.toLowerCase();
      if (title.toLowerCase().indexOf(searchKey) > -1) {
        searchResult.push(key);
      }
    }
    return searchResult;
  }
}
