import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import config from '../../config';

const scoreProps = {
  secure: {
    label: 'dashboard.securityStatus.header.secure',
    className: 'secure',
  },
  average: {
    label: 'dashboard.securityStatus.header.average',
    className: 'average',
  },
  critical: {
    label: 'dashboard.securityStatus.header.critical',
    className: 'critical',
  },
  disabled: {
    label: 'dashboard.securityStatus.header.disabled',
    className: 'disabled',
  },
  ignored: {
    label: 'dashboard.securityStatus.header.ignored',
    className: 'disabled',
  },
  calculating: {
    label: 'dashboard.securityStatus.header.calculating',
    className: 'loading',
  },
};

// eslint-disable-next-line max-params
const getScoreClass = (score, disabled, ignored, calculating) => {
  if (disabled) {
    return scoreProps.disabled;
  }
  if (ignored) {
    return scoreProps.ignored;
  }
  if (calculating) {
    return scoreProps.calculating;
  }
  if (score >= 0 && score < 40) {
    return scoreProps.critical;
  } if (score >= 40 && score < 80) {
    return scoreProps.average;
  }
  return scoreProps.secure;
};

const center = 20;
const strokeWidth = 7;
const radius = 15.915; // taking 100 as the total circumference

class SecurityStatus extends React.Component {
  static propTypes = {
    score: PropTypes.number.isRequired,
    disabled: PropTypes.bool.isRequired,
    ignored: PropTypes.bool.isRequired,
    calculating: PropTypes.bool.isRequired,
    loadedPercent: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    const { disabled, ignored, calculating } = props;
    const startScore = 0;

    this.state = {
      disabled,
      ignored,
      score: startScore,
      ...getScoreClass(startScore, disabled, ignored, calculating),
    };

    this.updateState = this.updateState.bind(this);
    this.scoreChangeInterval = null;
  }

  componentDidMount() {
    const { calculating } = this.props;
    if (!calculating) {
      this.updateState();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      calculating, loadedPercent, ignored, score,
    } = this.props;
    // run updateState only under two conditions
    // 1. done loading usernames and relevant prop changes
    // 2. usernames loaded became less (because of breach_check changing || new username added)
    if ((!calculating
      && (prevProps.loadedPercent !== loadedPercent
        || prevProps.ignored !== ignored
        || prevProps.score !== score))
      || prevProps.loadedPercent > loadedPercent) {
      this.updateState();
    }
  }

  componentWillUnmount() {
    if (this.scoreChangeInterval) {
      clearInterval(this.scoreChangeInterval);
      this.scoreChangeInterval = null;
    }
  }

  updateState() {
    const {
      score, disabled, ignored, calculating,
    } = this.props;

    const {
      score: currentScore,
    } = this.state;

    this.setState({
      disabled,
      ignored,
      ...getScoreClass(score, disabled, ignored, calculating),
    });

    if (calculating) {
      this.setState({ score: 0 });
      return;
    }

    if (currentScore !== score) {
      const difference = score - currentScore;
      const interval = Math.abs(1500 / difference);

      if (this.scoreChangeInterval) {
        clearInterval(this.scoreChangeInterval);
        this.scoreChangeInterval = null;
      }

      this.scoreChangeInterval = setInterval(() => {
        const { score: currentScoreState } = this.state;
        const nextScore = difference < 0 ? currentScoreState - 1 : currentScoreState + 1;
        this.setState({
          score: nextScore,
        });
        if (nextScore === score) {
          clearInterval(this.scoreChangeInterval);
          this.scoreChangeInterval = null;
        }
      }, interval);
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      disabled, ignored, score, label, className,
    } = this.state;
    const { calculating, loadedPercent } = this.props;
    let displayedScore = null;

    if (disabled || ignored) {
      displayedScore = '-';
    } else if (calculating) {
      displayedScore = '?';
    } else {
      displayedScore = score;
    }

    return (
      <div className="o-media o-media--middle">
        <div className="o-media__img">
          <div className="u-txt-pica" style={{ height: 100, width: 100 }}>
            <svg
              className="c-security-graph"
              viewBox="0 0 40 40"
            >
              <defs>
                <linearGradient id="linear-secure" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#39B298" />
                  <stop offset="100%" stopColor="#31B939" />
                </linearGradient>
                <linearGradient id="linear-average" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#FFB30D" />
                  <stop offset="100%" stopColor="#F58023" />
                </linearGradient>
                <linearGradient id="linear-critical" x1="0%" y1="0%" x2="0%" y2="100%">
                  <stop offset="0%" stopColor="#C31D1C" />
                  <stop offset="100%" stopColor="#EE0900" />
                </linearGradient>
              </defs>
              <circle
                className="c-security-graph__ring"
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
              />
              <circle
                className="c-security-graph__segment"
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
                stroke={`url(#linear-${className})`}
                strokeDasharray={`${score} ${100 - score}`}
                strokeDashoffset={25}
              />
              <text
                x="21"
                y={config.spotlight ? '23' : '21'}
                className={`c-security-graph__text c-security-graph__text--${className} `}
                dominantBaseline="central"
              >
                {displayedScore}
                {disabled || ignored || calculating ? '' : (
                  <tspan
                    className={`c-security-graph__text--small c-security-graph__text--${className}`}
                    alignmentBaseline="central"
                    y={config.spotlight ? '55%' : ''}
                  >
                    %
                  </tspan>
                )}
              </text>
            </svg>
          </div>
        </div>
        <div className={`o-media__body c-security-graph__text--${className} u-txt-english`}>
          <span className="u-d-b">
            <FormattedMessage
              id={
                label !== scoreProps.ignored.label
                  ? 'dashboard.securityStatus.header'
                  : label
              }
            />
          </span>
          <span className="u-d-b u-txt-strong u-txt-greatprimer">
            {
              label !== scoreProps.ignored.label
                ? <FormattedMessage id={label} />
                : null
            }
            {
              label === scoreProps.calculating.label
                ? ` (${loadedPercent}%)`
                : null
            }
          </span>
        </div>
      </div>
    );
  }
}

export default SecurityStatus;
