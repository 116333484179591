import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import VerifyEmail from './VerifyEmail';

import { getOEUserData, resendVerificationEmail } from '../../oe/OEActions';
import { logoutUser } from '../../authentication/AuthenticationActions';
import { trackVerified, trackVerifiedFailed } from '../VerifyEmailActions';

const mapStateToProps = ({ oe, dashboard: { isUnregisteredMode } }) => ({ oe, isUnregisteredMode });

const mapDispatchToProps = dispatch => bindActionCreators({
  getOEUserData,
  resendVerificationEmail,
  changeUser: logoutUser,
  trackVerified,
  trackVerifiedFailed,
}, dispatch);

const VerifyEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(VerifyEmail);

export default VerifyEmailContainer;
