import React, {
  useState, useCallback, useEffect, useContext,
} from 'react';
import Box from 'pwm-components/components/Box';
import Dialog from 'pwm-components/components/Dialog';
import FavoriteIcon from 'pwm-components/icons/Favorite';
import FavoriteOutlineIcon from 'pwm-components/icons/FavoriteOutline';
import TextButton from 'pwm-components/components/TextButton';
import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getExtensionBrowserName } from '../../lib/UserAgent';
import checkMark from '../../img/checkmark_verified.png';
import checkMark2x from '../../img/checkmark_verified2x.png';
import config from '../../config';
import SearchParamsContext from '../../app/components/SearchParamsContext';
import { trackEvent } from '../../tracking/TrackingActions';

const Favorite: React.FC<{ checked: boolean }> = ({ checked }) => (
  checked ? <FavoriteIcon size="large" variant="active" /> : <FavoriteOutlineIcon size="large" variant="link" />
);

enum State {
  Vote,
  LowNote,
  HighNote,
}

const FeedbackDialog: React.FC = () => {
  const [note, setNote] = useState<number | null>(null);
  const [state, setState] = useState(State.Vote);
  const [show, setShow] = useState(false);
  const [type, setType] = useState('ongoing');

  const dispatch = useDispatch();

  const searchParams = useContext(SearchParamsContext);

  const onSubmit = useCallback(() => {
    if (note == null) {
      return;
    }

    dispatch(trackEvent('SendFeedback', {
      rating: note,
      type,
    }));

    setState(note >= 4 ? State.HighNote : State.LowNote);
  }, [dispatch, note, type]);

  const onClose = useCallback(() => {
    setShow(false);
    setNote(null);
    setState(State.Vote);
  }, []);

  useEffect(() => {
    if (searchParams.feedback) {
      setShow(true);
      setType(searchParams.feedback as string);
      searchParams.setParam('feedback', null);
    }
  }, [searchParams]);

  let content: React.ReactNode = null;
  let buttons: React.ReactNode = null;

  const currentBrowser = getExtensionBrowserName();

  switch (state) {
    case State.Vote:
      content = (
        <>
          <Paragraph size="h2" mb="m">
            <FormattedMessage
              id="dashboard.feedback.step1.title"
              defaultMessage="How would you rate your experience?"
            />
          </Paragraph>
          <Box textAlign="center">
            {
              (new Array(5).fill(0).map((_, i) => (
                <TextButton key={`note-${i + 1}`} onClick={() => setNote(i + 1)}>
                  <Favorite checked={i < (note ?? 0)} />
                </TextButton>
              )))
            }
          </Box>
        </>
      );
      buttons = (
        <>
          <Button size="small" disabled={note == null} onClick={onSubmit}>
            <FormattedMessage
              id="dashboard.feedback.step1.button"
              defaultMessage="Submit"
            />
          </Button>
        </>
      );
      break;
    case State.LowNote:
      content = (
        <Box textAlign="center">
          <Paragraph mb="m">
            <img
              src={checkMark}
              srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
              alt="completed"
            />
          </Paragraph>
          <Paragraph size="h2" mb="m">
            <FormattedMessage
              id="dashboard.feedback.step2.title"
              defaultMessage="Thank you!"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="dashboard.feedback.step2.subtitle"
              defaultMessage="Your feedback helps us improve the app"
            />
          </Paragraph>
        </Box>
      );
      buttons = (
        <>
          <Button
            size="small"
            onClick={onClose}
          >
            <FormattedMessage
              id="dashboard.feedback.step2.button"
              defaultMessage="Close"
            />
          </Button>
        </>
      );
      break;
    case State.HighNote:
      content = (
        <Box textAlign="center">
          <Paragraph mb="m">
            <img
              src={checkMark}
              srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
              alt="completed"
            />
          </Paragraph>
          <Paragraph size="h2" mb="m">
            <FormattedMessage
              id="dashboard.feedback.step3.title"
              defaultMessage="Thank you for your feedback"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="dashboard.feedback.step3.subtitle"
              defaultMessage="Would you mind taking a moment and rate Password Manager in the Chrome store?"
            />
          </Paragraph>
        </Box>
      );

      buttons = (
        <>
          <Text mr="s">
            <a
              href={(config.links as any)[currentBrowser as string]}
              target="_blank"
              rel="noopener noreferrer"
              onClick={onClose}
            >
              <Button size="small" disabled={note == null}>
                <FormattedMessage
                  id="dashboard.feedback.step3.button"
                  defaultMessage="Rate now"
                />
              </Button>
            </a>
          </Text>
          <Button
            variant="ghost"
            size="small"
            disabled={note == null}
            onClick={onClose}
          >
            <FormattedMessage
              id="dashboard.feedback.step3.later"
              defaultMessage="Later"
            />
          </Button>
        </>
      );
      break;
    default:
      break;
  }

  return (
    <Dialog
      show={show}
      onCloseClick={onClose}
    >
      <Box mb="m">
        {content}
      </Box>
      <Box>
        {buttons}
      </Box>
    </Dialog>
  );
};

export default FeedbackDialog;
