import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Sentry from '@sentry/browser';
import pick from 'lodash/pick';

import Input from 'pwm-components/components/Input';
import NoteIcon from 'pwm-components/components/NoteIcon';

import { MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/Note';
import ColorPalette from '../../componentLib/ColorPalette';
import MyDataDetailsContainer, { Props as MyDataDetailsProps } from '../../myData/components/MyDataDetailsContainer';

import tabIndex from '../../lib/TabIndexConfig';
import { mergeDetails, handleDetailsChange } from '../../lib/MyDataHelper';
import { filterFiles, isAttached } from '../../files/helpers/FileDataHelper';
import Card from '../../componentLib/CardWrapper';

export type Props = MyDataDetailsProps & {
  onSaveNoteClick: (id: string, noteProps: any) => void;
};

type State = {
  note: any;
  initialNote: any;
  changedKeys: any;
  loading: boolean;
};

class AddEditNotePage extends React.Component<Props, State> {
  public static defaultProps = {
    data: {},
  }

  constructor(props: Props) {
    super(props);
    const { data } = props;

    this.state = {
      note: data,
      initialNote: data,
      changedKeys: {},
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNoteChange = this.onNoteChange.bind(this);
    this.onFavoriteClick = this.onFavoriteClick.bind(this);
    this.onNoteColorsClick = this.onNoteColorsClick.bind(this);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { data: noteProp } = this.props;
    const { note: noteState, changedKeys: changedKeysState } = this.state;
    if (nextProps.data !== noteProp) {
      const { data } = nextProps;
      const {
        updatedData: updatedNote,
        updatedChangedKeys: changedKeys,
      } = mergeDetails(data, noteState, Object.keys(changedKeysState));

      this.setState({
        note: updatedNote,
        changedKeys,
        initialNote: data,
        loading: false,
      });
    }
  }

  private onNoteChange(key: string, value: any, callback = () => { }): void {
    const { initialNote, changedKeys } = this.state;
    const {
      newChangeObj,
      changedKeys: nextChangedKeys,
    } = handleDetailsChange(key, value, initialNote, changedKeys);
    const { note } = this.state;
    this.setState({
      note: { ...note, ...newChangeObj },
      changedKeys: nextChangedKeys,
    }, callback);
  }

  private onNoteColorsClick(color: any): void {
    this.onNoteChange('color', color);
  }

  private onFavoriteClick(): void {
    const { onFavoriteClick, data } = this.props;
    if (onFavoriteClick) {
      onFavoriteClick(data);
    }
  }

  private async handleSubmit(e: Event): Promise<void> {
    e.preventDefault();
    const { onSaveNoteClick } = this.props;
    const { note, changedKeys } = this.state;

    try {
      this.setState({ loading: true });
      const changedProps = {
        ...pick(note, Object.keys(changedKeys)),
      };

      await onSaveNoteClick(note.id, changedProps);
    } catch (error) {
      this.setState({ loading: false });
      Sentry.captureException(error);
    }
  }

  public render(): JSX.Element {
    const {
      changedKeys,
      note,
      loading,
    } = this.state;
    const {
      intl,
      files: filesProp,
    } = this.props;

    const files = filterFiles(filesProp, note.id);
    return (
      <MyDataDetailsContainer
        {...this.props}
        focusNameField
        entityName="Note"
        nameLabel="title"
        listViewPath="/mydata/notes"
        confirmDeleteId="dashboard.note.details.confirmDelete"
        namePlaceholderId="dashboard.note.details.namePlaceholder"
        tagsInstructionId="dashboard.note.details.tagInstruction"
        attachmentInstructionId="dashboard.note.details.attachmentInstruction"
        attachmentGetProId="dashboard.note.details.upsellPro"
        enableSyncInstructionId="dashboard.note.details.enableSync"
        data={note}
        files={files}
        loading={loading}
        isFavorite={note.favorite}
        changedKeys={changedKeys}
        nameValue={note.title || ''}
        createdDate={note.createdAt || ''}
        modifiedDate={note.modifiedAt || ''}
        iconElement={(
          <NoteIcon
            color={note.color}
            attached={isAttached(files)}
          />
        )}
        topRightCard={(
          <Card
            mb="m"
            width="100%"
            variant="secondary"
            title={(
              <FormattedMessage
                id="dashboard.note.details.colors"
                defaultMessage="Colors"
              />
            )}
          >
            <ColorPalette
              activeColor={note.color}
              onClick={this.onNoteColorsClick}
              tabIndex={tabIndex.lastFocus}
            />
          </Card>
        )}
        onChange={this.onNoteChange}
        onFavoriteClick={this.onFavoriteClick}
        handleSubmit={this.handleSubmit}
      >
        <Input
          id="a-notes-input"
          kind="textarea"
          rows={14}
          tabIndex={tabIndex.mainFormFields}
          maxLength={MAX_LENGTHS.notes}
          label={
            intl.formatMessage({
              id: 'dashboard.note.details.note',
              defaultMessage: 'Note',
            })
          }
          placeholder={
            intl.formatMessage({
              id: 'dashboard.note.details.notePlaceholder',
              defaultMessage: 'Type your note here',
            })
          }
          value={note.notes}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.onNoteChange('notes', e.target.value)}
        />
      </MyDataDetailsContainer>
    );
  }
}

export default injectIntl(AddEditNotePage);
