import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';

import iconClick from '../../../img/icon-click.png';
import iconClick2x from '../../../img/icon-click@2x.png';

import autosavePassword from '../../../img/animations/autosavePassword.gif';
import autosavePassword2x from '../../../img/animations/autosavePassword2x.gif';

import List from '../List';
import TwoColumnView from '../TwoColumnView';

import GifContainer from '../GifContainer';

export default () => (
  <TwoColumnView
    leftSection={(
      <>
        <Box mb="m">
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.autosavePassword.message"
              defaultMessage="Use the Save Popup to add new accounts while logging into websites."
            />
          </Paragraph>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <Box mr="l">
            <img
              src={iconClick}
              srcSet={`${iconClick} 1x, ${iconClick2x} 2x`}
              alt="Click icon"
            />
          </Box>
          <List>
            <li>
              <Text>
                <FormattedMessage
                  id="dashboard.getStarted.autosavePassword.step1"
                  defaultMessage="Log into one of your accounts which is not saved in Avira Password Manager yet"
                />
              </Text>
            </li>
            <li>
              <Text>
                <FormattedMessage
                  id="dashboard.getStarted.autosavePassword.step2"
                  defaultMessage="Confirm the Save Popup to add this account to Avira Password Manager"
                />
              </Text>
            </li>
          </List>
        </Box>
      </>
    )}

    rightSection={(
      <GifContainer
        gifSrc={autosavePassword}
        gifSrc2x={autosavePassword2x}
        gifAlt="Save a password animation"
      />
    )}
  />
);
