import React from 'react';
import { FormattedMessage } from 'react-intl';
import { string } from 'prop-types';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import chip from '../../../img/icon-chip.png';
import chip2x from '../../../img/icon-chip@2x.png';

const ChipImg = styled('img')`
  margin-top: ${({ theme }) => theme.space.l};
`;

const AboutUs = ({ language }) => (
  <Box>
    <Paragraph>
      <FormattedMessage
        id="dashboard.getStarted.aboutUs.details"
        defaultMessage="We’re award-winning security specialists proud of our 30 years of experience. Over 100 million satisfied customers worldwide trust Avira to keep their digital lives and data safe. Our software is German engineered and our cloud servers are located in Germany – in accordance with EU legislation."
      />
    </Paragraph>
    {language === 'de' && (
      <ChipImg
        src={chip}
        srcSet={`${chip} 1x, ${chip2x} 2x`}
        alt="Chip"
      />
    )}
  </Box>
);

AboutUs.propTypes = {
  language: string.isRequired,
};

export default AboutUs;
