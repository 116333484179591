import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

const HowSafeIsMyData = () => (
  <Box>
    <Paragraph mb="xs">
      <FormattedMessage
        id="dashboard.getStarted.howSafeIsMyData.detail1"

        defaultMessage="All your sensitive data is encrypted with your Master Password, and this is the only way it can also be decrypted. This means that no one apart from you, not even Avira, has access to your data."
      />
    </Paragraph>
    <Box
      mb="m"
    >
      <Media
        image={(
          <img
            src={hint}
            srcSet={`${hint} 1x, ${hint2x} 2x`}
            alt="hint"
          />
        )}
        body={(
          <Text>
            <FormattedHTMLMessage
              id="dashboard.getStarted.howSafeIsMyData.hint"
              defaultMessage="Please make sure to <strong>use a strong and unique Master Password</strong>. It is also important to always remember it. <strong>If you forget your Master Password, there is no way to recover your data</strong>."
            />
          </Text>
        )}
      />
    </Box>

    <Paragraph>
      <FormattedMessage
        id="dashboard.getStarted.howSafeIsMyData.detail2"
        defaultMessage="Furthermore your data is regularly backed up to prevent data loss."
      />
    </Paragraph>
  </Box>
);

export default HowSafeIsMyData;
