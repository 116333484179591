import React, { MouseEvent } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from 'pwm-components/components/Box';
import InfoIcon from 'pwm-components/icons/Info';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';

import { State } from '@avira-pwm/redux/accounts';

import { FormattedMessage } from 'react-intl';
import { trackGetProClicked } from '../../dashboard/DashboardActions';
import { showPlans } from '../../oe/OEActions';

const SpotlightUpsellBanner: React.FC = () => {
  const NumberOfAccounts = useSelector(
    ({ accounts }: { accounts: State }) => Object.keys(accounts).length,
  );

  const dispatch = useDispatch();

  const onUpsellClick = (event: MouseEvent): void => {
    event.stopPropagation();

    dispatch(trackGetProClicked('SpotlightUpsellBanner'));
    dispatch(showPlans());
  };

  return (
    <Box
      flex="0 0 auto"
      bg="foreground"
      display="flex"
      alignItems="center"
      ml="xxs"
      mr="m"
      p="m"
    >
      <Box flex="0 0 auto" mr="m">
        <InfoIcon variant="link" size="large" />
      </Box>
      <Box flex="1 1 auto" mr="m">
        <Box>
          <Text size="h2">
            <FormattedMessage
              id="dashboard.securityStatus.upsell.title"
              defaultMessage="You have free protection"
            />
          </Text>
        </Box>
        <Box>
          <Text size="normal">
            <FormattedMessage
              id="dashboard.securityStatus.upsell.body"
              defaultMessage="Activate full protection to reveal all {accounts} accounts to improve"
              values={{ accounts: NumberOfAccounts }}
            />
          </Text>
        </Box>
      </Box>
      <Box flex="0 0 auto">
        <Button variant="primary" onClick={onUpsellClick}>
          <FormattedMessage
            id="dashboard.securityStatus.upsell.button"
            defaultMessage="Activate Full Protection"
          />
        </Button>
      </Box>
    </Box>

  );
};

export default SpotlightUpsellBanner;
