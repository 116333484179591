import React from 'react';
import CreditCardIcon from 'pwm-components/icons/CreditCard';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import { FormattedMessage } from 'react-intl';
import DataListItem from '../../../componentLib/DataListItem';
import { DataListCell } from '../../../componentLib/DataListComponents';

const CreditCardPromoListItem = (): JSX.Element => (
  <DataListItem
    onClick={() => { }}
    onMouseEnter={() => { }}
    onMouseLeave={() => { }}
    placeholder
  >
    <DataListCell>
      <CreditCardIcon height="30px" width="30px" />
    </DataListCell>
    <DataListCell>
      <Text>
        <FormattedMessage
          id="dashboard.wallet.details.creditCard"
          defaultMessage="Credit Card"
        />
      </Text>
    </DataListCell>

    <DataListCell spacer />

    <DataListCell>
      <Text variant="placeholder">
        0000 0000 0000 0000
      </Text>
    </DataListCell>

    <DataListCell spacer />

    <DataListCell>
      <Box display="flex" flexDirection="row">
        <Text variant="placeholder">
          <FormattedMessage
            id="dashboard.wallet.details.monthPlaceholder"
            defaultMessage="MM"
          />
          /
          <FormattedMessage
            id="dashboard.wallet.details.yearPlaceholder"
            defaultMessage="YY"
          />
        </Text>
      </Box>
    </DataListCell>
    <DataListCell spacer />
    <DataListCell>
      <Box display="flex" flexDirection="row">
        <Box mr="s">
          <Text>
            <FormattedMessage
              id="dashboard.wallet.details.cvc"
              defaultMessage="CVC"
            />
          </Text>
        </Box>
        <Box>
          <Text variant="placeholder">
            000
          </Text>
        </Box>
      </Box>
    </DataListCell>

    <DataListCell spacer />
    <DataListCell spacer />
  </DataListItem>

);

export default CreditCardPromoListItem;
