import React from 'react';
import { FormattedMessage } from 'react-intl';
import Text from 'pwm-components/components/Text';
import Box from 'pwm-components/components/Box';
import Icon from '../../img/icon_password_manager_gray.png';

type Props = {}

const LeftPanel: React.FC<Props> = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="space-around"
    position="relative"
    p="xxxl"
  >
    <Box
      position="absolute"
      style={{ transform: 'translateX(-30px)' }}
      height="100%"
      display="flex"
      alignItems="flex-start"
      pt="l"
    >
      <img src={Icon} alt="" width="22px" />
      <Text size="normal" variant="disabledDark" ml="xs">
        <FormattedMessage id="onboarding.leftpanel.passwordmanager" defaultMessage="Password Manager" />
      </Text>
    </Box>
    <Text size="h1" variant="disabledDark" mb="12px">
      <FormattedMessage id="onboarding.leftpanel.title" defaultMessage="Get more security by protecting your passwords" />
    </Text>
    <Text size="h3" variant="disabledDark">
      <FormattedMessage id="onboarding.leftpanel.text" defaultMessage="Avira Password Manager is free, and it's part of your Avira Security bundle" />
    </Text>
  </Box>
);

export default LeftPanel;
