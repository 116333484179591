import React, { ReactNodeArray, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';

import ErrorComponent from '../../app/components/Error';
import { State } from '../DashboardReducer';
import { getErrorDescription } from '../../lib/ErrorHelper';
import { clearError } from '../DashboardActions';
import envConfig from '../../config';
import stringFormatter from '../../lib/StringFormatter';
import { trackMigrationContactSupportClicked, trackMigrationTryAgainClicked } from '../../nlok/NLOKMigrationTrackingActions';
import { getMigrationAttempts } from '../../nlok/selectors';

interface Props {
  intl: IntlShape;
}

// eslint-disable-next-line complexity
const ErrorWrapper: React.FC<Props> = ({ intl, children }) => {
  const error = useSelector((state: { dashboard: State }) => state.dashboard.error);
  const dispatch = useDispatch();
  const attempts = useSelector(getMigrationAttempts);

  const onClick = useCallback(
    (
      messageId: string | undefined,
      attempt: number,
    ): void => {
      // TODO: Improve check to track migration actions
      if (messageId === 'dashboard.error.contactSupport') {
        if (attempt <= 3) {
          dispatch(trackMigrationTryAgainClicked({
            attempt,
          }));
        } else {
          dispatch(trackMigrationContactSupportClicked());
        }
      }
      window.location.reload();
    }, [dispatch],
  );

  if (error) {
    const {
      message,
      messageId,
      errorCode,
      error: e,
      context,
      retry = true,
      dismissable,
    } = error;
    let msg: ReactNodeArray | string = '';
    if (message) {
      msg = message;
    } else if (messageId) {
      msg = intl.formatMessage({ id: messageId }, {
        'support-link': (...chunks: any[]) => (
          <a
            href={stringFormatter(envConfig.connect.support, intl.locale)}
            rel="noopener noreferrer"
            target="_blank"
          >
            {chunks}
          </a>
        ),
      });
    } else if (errorCode) {
      msg = intl.formatMessage({ id: getErrorDescription(errorCode).key });
    } else if (e) {
      msg = e.message;
    }

    return (
      <ErrorComponent
        message={msg}
        context={context}
        buttonLabel={intl.formatMessage({ id: 'dashboard.error.tryAgain' })}
        error={e}
        onDismiss={dismissable ? () => { dispatch(clearError()); } : undefined}
        onClick={
          retry
            ? () => {
              onClick(messageId, attempts);
            }
            : undefined
        }
      />
    );
  }
  return <>{children}</>;
};

export default injectIntl(ErrorWrapper);
