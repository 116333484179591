import { createSelector } from 'reselect';
import { sha256 } from '@avira-pwm/crypto-tools';
import * as aviraFileHelpers from '@avira-pwm/sync/helpers/file/avira';
import * as nlokFileHelpers from '@avira-pwm/sync/helpers/file/nlok';
import { guidFrom } from '@avira-pwm/sync/adapters/NDSAdapter/helpers/Convert';
import { RootState } from '../app/store';
import jwtDecode from '../lib/JWTDecoder';
import {
  isNonMigratable,
  migrationSucceeded as migrationSucceededSelector,
  userShouldMigrate,
  vaultIsUnlocked,
} from '../nlok/selectors';
import config from '../config';
import {
  isCurrentUserPro as isCurrentUserProSelector,
  isUpsellAllowed as isUpsellAllowedSelector,
} from '../user/selectors';

export const fileAddingAllowed = createSelector(
  migrationSucceededSelector,
  userShouldMigrate,
  vaultIsUnlocked,
  isNonMigratable,
  isCurrentUserProSelector,
  isUpsellAllowedSelector,
  (
    migrationSucceeded,
    shouldMigrateVaultVal,
    vaultUnlocked,
    isNonMigratableVal,
    isCurrentUserPro,
    isUpsellAllowed,
  // eslint-disable-next-line max-params
  ) => (
    (!shouldMigrateVaultVal || (migrationSucceeded && vaultUnlocked) || isNonMigratableVal)
    && (isCurrentUserPro || isUpsellAllowed)
  ),
);

export const getCredentials = createSelector(
  (state: RootState) => ({
    avira: state.dashboard.aviraAwsCredentials,
    nlok: state.dashboard.nlokS3Credentials,
  }),
  migrationSucceededSelector,
  vaultIsUnlocked,
  ({ avira, nlok }, migrationSucceeded, vaultUnlocked) => (
    (migrationSucceeded && vaultUnlocked) ? nlok : avira
  ),
);

export const getFileConfig = createSelector(
  (state: RootState) => state.oe.token,
  migrationSucceededSelector,
  vaultIsUnlocked,
  (state: RootState) => state.dashboard.syncData,
  getCredentials,
  // eslint-disable-next-line max-params
  (oeToken, migrationSucceeded, vaultUnlocked, syncData, credentials) => {
    let folder: string;
    let region: string;
    let bucket: string;
    let helpers: typeof aviraFileHelpers | typeof nlokFileHelpers;
    let getId: (id: string) => string;

    if (migrationSucceeded && vaultUnlocked) {
      const { aguid } = jwtDecode(oeToken!);
      folder = sha256(aguid);
      region = config.s3FileRegion;
      bucket = config.s3FileBucket;
      helpers = nlokFileHelpers;
      getId = id => guidFrom(id);
    } else {
      folder = syncData!.identity_id;
      region = syncData!.region;
      bucket = syncData!.user_files_bucket;
      helpers = aviraFileHelpers;
      getId = id => id;
    }

    return {
      credentials: credentials!,
      region,
      bucket,
      folder,
      helpers,
      getId,
    };
  },
);
