import { AsyncStorage } from '@avira-pwm/storage';

import config from '../config';
import AsyncJsonStorage from './AsyncJsonStorageWrapper';
import SpotlightStorageWrapper from './SpotlightStorageWrapper';

// eslint-disable-next-line import/no-mutable-exports
let storage: AsyncStorage;

if (config.spotlight === true && SpotlightStorageWrapper.isApiAvailable(window.external)) {
  storage = new SpotlightStorageWrapper(window.external);
} else {
  storage = new AsyncJsonStorage(localStorage);
}

export default storage;
