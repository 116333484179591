import React, { useState, useCallback } from 'react';
import Box from 'pwm-components/components/Box';
import IconButton from 'pwm-components/components/IconButton';
import styled from 'pwm-components/styled';
import facebook from '../img/logo_facebook@1x.png';
import facebook2x from '../img/logo_facebook@2x.png';
import { facebookLocales } from '../locales';

const StyledIconButton = styled(IconButton)`
  background: #4267B2;
  border: 1px solid #4267B2;

  :hover{
    background: #4267B2;
  }

  :focus{
    background: #4267B2;
  }

  &[disabled]{
    background: #4267B2;
    opacity: 15%;
    cursor: not-allowed
  }
`;

type Props = {
  clientId: string;
  onClick?: () => void;
  onSuccess?: (accessToken: string, handle: string, socialMediaUniqeId: string) => void;
  onError?: (data: fb.AuthResponse, handle: string) => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
  locale?: string;
}

const FacebookLogin: React.FC<Props> = ({
  clientId,
  onClick: onClickProp = () => {},
  onError = () => {},
  onSuccess = () => {},
  loading = false,
  disabled = false,
  locale = 'en',
}) => {
  const [initialized, setInitialized] = useState(false);

  const signIn = useCallback(() => {
    window.FB.init({
      appId: clientId,
      cookie: true,
      version: 'v2.8',
    });

    window.FB.login((res) => {
      if (res.status === 'connected') {
        const { accessToken, userID } = res.authResponse;
        onSuccess(accessToken, 'facebook', userID);
      } else {
        onError(res.authResponse ?? {}, 'facebook');
      }
    }, { scope: 'email' }); // at the moment we do not get email even if requested.
  }, [clientId, onSuccess, onError]);


  const initScript = useCallback(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://connect.facebook.net/${(facebookLocales as any)[locale]}/sdk.js`;

    const jsScripts = document.getElementsByTagName('script')[0];
    if (jsScripts && jsScripts.parentNode) {
      jsScripts.parentNode.insertBefore(script, jsScripts);
    } else {
      document.head.appendChild(script);
    }
    (window as any).fbAsyncInit = () => {
      setInitialized(true);
      signIn();
    };
  }, [locale, signIn]);

  const onClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    onClickProp();

    if (initialized) {
      signIn();
    } else {
      initScript();
    }
  }, [initialized, initScript, signIn, onClickProp]);

  return (
    <Box
      height="58px"
      width="100%"
      pl="4px"
      pr="4px"
    >
      <StyledIconButton
        type="button"
        size="normal"
        onClick={onClick}
        disabled={disabled || loading}
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <img
            style={{ height: 30, width: 30 }}
            src={facebook}
            srcSet={`${facebook} 1x, ${facebook2x} 2x`}
            alt="facebook"
          />
        </Box>
      </StyledIconButton>
    </Box>
  );
};

export default FacebookLogin;
