import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';


const AboutSecurityQuestions = () => (
  <>
    <Box mb="m">
      <Paragraph mb="xs">
        <FormattedMessage
          id="dashboard.getStarted.aboutSecurityQuestions.step"
          defaultMessage="Security questions are a dangerous way of granting access to the account in case the password is lost.They use your personal data, which is easy to get by online research and social engineering."
        />
      </Paragraph>
      <Media
        image={(
          <img
            src={hint}
            srcSet={`${hint} 1x, ${hint2x} 2x`}
            alt="hint"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.aboutSecurityQuestions.step1"
              defaultMessage="If you must use security questions, we recommend using false answers. You can save them as notes to your password in Avira Password Manager."
            />
          </Text>
        )}
      />
    </Box>
  </>
);

export default AboutSecurityQuestions;
