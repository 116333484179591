import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Select from 'pwm-components/components/Select';

type Tag = {
  count: number;
  disabled: boolean;
  id: string;
  label: string;
}

type Props = WrappedComponentProps & {
  filter: string;
  tags: Array<Tag>;
  onChange: (id: string) => void;
  label?: JSX.Element | string;
}

const TagFilterSelect: React.FC<Props> = ({
  filter,
  intl,
  onChange,
  tags,
  label,
}) => {
  const options = tags.map((tag: Tag) => ({
    id: tag.id,
    label: `${intl.formatMessage({ id: tag.label })} • ${tag.count}`,
    disabled: tag.disabled,
  }));

  return (
    <Select onChange={onChange} options={options} defaultValue={filter} label={label} />
  );
};

export default injectIntl(TagFilterSelect);
