export const ERROR_REQUIRED = 'ERROR_REQUIRED';
export const ERROR_FORMAT = 'ERROR_FORMAT';
export const ERROR_CUSTOM = 'ERROR_CUSTOM';
export const ERROR_GLOBAL = 'ERROR_GLOBAL';

const RULES = {
  required(value) {
    if (!value) {
      return ERROR_REQUIRED;
    }

    return null;
  },

  format(value, formatRule) {
    if (!value) return null;

    if (typeof formatRule === 'function') {
      return formatRule(value) ? null : ERROR_FORMAT;
    }
    return formatRule.test(value) ? null : ERROR_FORMAT;
  },

  custom(value, rule, values) {
    return rule(value, values) ? null : ERROR_CUSTOM;
  },

  global(value, rule, values) {
    return rule(value, values) ? null : ERROR_GLOBAL;
  },
};

export default (values, rules) => {
  const fieldsValidation = Object.keys(rules).reduce((errors, key) => {
    const valueRules = rules[key];
    const validations = valueRules.map(
      rule => RULES[rule.type](values[key], rule[rule.type], values),
    );
    const error = validations.find(valid => !!valid) || null;

    if (error) {
      Object.assign(errors, {
        [key]: error,
      });
    }
    return errors;
  }, {});

  return Object.keys(fieldsValidation).length > 0 ? fieldsValidation : null;
};
