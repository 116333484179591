import {
  BACKUP_DATASETS,
  CLEAR_BACKUP_DATASETS,
  BackupDatasets,
  ClearBackupDatasets,
  Dataset,
} from './ManageBackupsActionTypes';

export type State = Dataset[];

function getInitialState(): State {
  return [];
}

export type Actions = BackupDatasets | ClearBackupDatasets;

export default function manageBackupsReducer(
  state: State = getInitialState(),
  action: Actions,
): State {
  switch (action.type) {
    case BACKUP_DATASETS: {
      return action.value;
    }
    case CLEAR_BACKUP_DATASETS:
      return getInitialState();
    default:
      return state;
  }
}
