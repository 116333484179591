import {
  PREFS_SET_PASSGEN,
  AUTO_LOCK_UPDATE,
  UPDATE_LAST_ACTIVITY,
  UPDATE_ALL_PREFERENCES,
  UPDATE_LANGUAGE,
  PREFS_DISMISS_EXT_BANNER,
  PREFS_DISMISS_FILE_BANNER,
  PREFS_DISMISS_GET_MOBILE_BANNER,
  UPDATE_PASSWORD_SORT_BY,
  UPDATE_NOTE_SORT_BY,
  UPDATE_WALLET_SORT_BY,
  PREFS_DISMISS_BREACH_CHECK_BANNER,
  PREFS_DISMISS_OTHER_BREACHES_BANNER,
  SET_THEME,
  SET_GEO_CHECK,
  SET_SERVER_TIME_OFFSET,
} from './PreferencesActionTypes';
import { getLocale } from '../locales';
import envConfig from '../config';

type Prefs = {
  PassGenLength: number;
  PassGenNumbers: boolean;
  PassGenSymbols: boolean;
  autolock: number | null;
  lastActivity: number;
  language: string;
  extBannerDismissed: boolean;
  fileBannerDismissed: boolean;
  getMobileBannerDismissed: boolean;
  updatedFileBannerDismissed: boolean;
  passwordSortBy: string | null;
  notesSortBy: string | null;
  breachCheckBannerDismissed: boolean;
  otherBreachesBannerDismissed: boolean;
  ftuABTestVersion: string | null; // check
  theme: 'dark' | 'light';
  walletSortBy: string | null;
  geoBlock: boolean;
  geoCheckTimestamp: number;
  serverTimeOffset: number | null;
};

export type State = Readonly<Prefs>;

export const defaultState = (): State => ({
  PassGenLength: 12,
  PassGenNumbers: true,
  PassGenSymbols: true,
  autolock: null,
  lastActivity: 0,
  language: getLocale(navigator.language),
  extBannerDismissed: false,
  fileBannerDismissed: false,
  getMobileBannerDismissed: false,
  updatedFileBannerDismissed: false,
  passwordSortBy: null,
  notesSortBy: null,
  breachCheckBannerDismissed: false,
  otherBreachesBannerDismissed: false,
  ftuABTestVersion: null,
  theme: envConfig.spotlight ? 'dark' : 'light',
  walletSortBy: null,
  geoBlock: false,
  geoCheckTimestamp: 0,
  serverTimeOffset: null,
});

interface SetPasswordGeneratorAction {
  type: typeof PREFS_SET_PASSGEN;
  value: Pick<Prefs, 'PassGenLength' | 'PassGenNumbers' | 'PassGenSymbols'>;
}

interface SetAutolockAction {
  type: typeof AUTO_LOCK_UPDATE;
  value: Prefs['autolock'];
}

interface SetLastActivity {
  type: typeof UPDATE_LAST_ACTIVITY;
  value: Prefs['lastActivity'];
}

interface SetAllPreferences {
  type: typeof UPDATE_ALL_PREFERENCES;
  value: Partial<Prefs>;
}

interface SetLanguage {
  type: typeof UPDATE_LANGUAGE;
  value: Prefs['language'];
}

interface SetExtensionBannerDismissed {
  type: typeof PREFS_DISMISS_EXT_BANNER;
}

interface SetFileBannerDismissed {
  type: typeof PREFS_DISMISS_FILE_BANNER;
}

interface SetGetMobileBannerDismissed {
  type: typeof PREFS_DISMISS_GET_MOBILE_BANNER;
}

interface SetPasswordSortBy {
  type: typeof UPDATE_PASSWORD_SORT_BY;
  value: Prefs['passwordSortBy'];
}

interface SetNoteSortBy {
  type: typeof UPDATE_NOTE_SORT_BY;
  value: Prefs['notesSortBy'];
}

interface SetWalletSortBy {
  type: typeof UPDATE_WALLET_SORT_BY;
  value: Prefs['walletSortBy'];
}

interface SetOtherBreachesBannersDismissed {
  type: typeof PREFS_DISMISS_OTHER_BREACHES_BANNER;
}

interface SetBreachCheckBannerDismissed {
  type: typeof PREFS_DISMISS_BREACH_CHECK_BANNER;
}

interface SetTheme {
  type: typeof SET_THEME;
  value: Prefs['theme'];
}

interface SetGeoCheck {
  type: typeof SET_GEO_CHECK;
  value: Pick<Prefs, 'geoBlock' | 'geoCheckTimestamp'>;
}

interface SetServerTimeOffset {
  type: typeof SET_SERVER_TIME_OFFSET;
  value: Prefs['serverTimeOffset'];
}

export type PreferencesAction = (
  SetPasswordGeneratorAction
  | SetAutolockAction
  | SetLastActivity
  | SetAllPreferences
  | SetLanguage
  | SetExtensionBannerDismissed
  | SetFileBannerDismissed
  | SetGetMobileBannerDismissed
  | SetPasswordSortBy
  | SetNoteSortBy
  | SetWalletSortBy
  | SetOtherBreachesBannersDismissed
  | SetBreachCheckBannerDismissed
  | SetTheme
  | SetGeoCheck
  | SetServerTimeOffset
);

// eslint-disable-next-line complexity
export default (state: State = defaultState(), action: PreferencesAction): State => {
  switch (action.type) {
    case PREFS_SET_PASSGEN:
      return {
        ...state,
        ...action.value,
      };
    case AUTO_LOCK_UPDATE:
      return {
        ...state,
        autolock: action.value,
      };
    case UPDATE_LAST_ACTIVITY:
      return {
        ...state,
        lastActivity: action.value,
      };
    case UPDATE_ALL_PREFERENCES:
      return {
        ...state,
        ...action.value,
      };
    case UPDATE_LANGUAGE:
      return {
        ...state,
        language: action.value,
      };
    case PREFS_DISMISS_EXT_BANNER:
      return {
        ...state,
        extBannerDismissed: true,
      };
    case PREFS_DISMISS_FILE_BANNER:
      return {
        ...state,
        fileBannerDismissed: true,
        updatedFileBannerDismissed: true,
      };
    case PREFS_DISMISS_GET_MOBILE_BANNER:
      return {
        ...state,
        getMobileBannerDismissed: true,
      };
    case UPDATE_PASSWORD_SORT_BY: {
      return {
        ...state,
        passwordSortBy: action.value,
      };
    }
    case UPDATE_NOTE_SORT_BY: {
      return {
        ...state,
        notesSortBy: action.value,
      };
    }
    case UPDATE_WALLET_SORT_BY: {
      return {
        ...state,
        walletSortBy: action.value,
      };
    }
    case PREFS_DISMISS_OTHER_BREACHES_BANNER: {
      return {
        ...state,
        otherBreachesBannerDismissed: true,
      };
    }
    case PREFS_DISMISS_BREACH_CHECK_BANNER: {
      return {
        ...state,
        breachCheckBannerDismissed: true,
      };
    }
    case SET_THEME: {
      return {
        ...state,
        theme: action.value,
      };
    }
    case SET_GEO_CHECK: {
      return {
        ...state,
        ...action.value,
      };
    }
    case SET_SERVER_TIME_OFFSET: {
      return {
        ...state,
        serverTimeOffset: action.value,
      };
    }
    default:
      return state;
  }
};
