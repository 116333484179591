import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';

import Text from 'pwm-components/components/Text';

import BrowserLogo from '../../componentLib/BrowserLogo';
import BrowserMagic from '../../componentLib/BrowserMagic';
import InfoTooltip from '../../componentLib/InfoTooltip';
import Card from '../../componentLib/CardWrapper';
import checkMark from '../../img/checkmark.png';
import checkMark2x from '../../img/checkmark@2x.png';

const ExtensionSettings = ({
  extensionInstalled,
  browser,
  storeLinks,
  isBrowserSupported,
  installedVersion,
}) => {
  let value = null;

  if (isBrowserSupported) {
    if (extensionInstalled) {
      value = (
        <>
          <Box>
            <Text mr="xs" variant="success">
              <FormattedMessage id="dashboard.settings.extension.extensionInstalled" />
            </Text>
            <img
              src={checkMark}
              srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
              alt="Check mark"
            />
          </Box>
          {
            installedVersion != null && (
              <Box>
                <Text size="small">
                  v
                  {installedVersion}
                </Text>
              </Box>
            )
          }
        </>
      );
    } else {
      value = (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <BrowserMagic browser={browser} />

            <Text ml="xs">
              <FormattedMessage id="dashboard.settings.extension.installationMessage" />
            </Text>

          </Box>

          <Box ml="xs">
            <InfoTooltip messageId="dashboard.settings.extension.info" />
          </Box>

          <Box ml="s" flexShrink={0}>
            <a
              href={`${storeLinks[browser]}?subSource=Dashboard%20Settings`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <FormattedMessage id="dashboard.settings.extension.installExtension" />
            </a>
          </Box>
        </Box>
      );
    }
  } else {
    value = (
      <div>
        <Box
          display="flex"
          alignItems="center"
          mb="l"
        >
          <FormattedMessage id="dashboard.settings.extension.browsersAvailable" />
          <InfoTooltip messageId="dashboard.settings.extension.info" />
        </Box>
        <Box display="flex" ml="xs">
          {Object.keys(storeLinks)
            .map(supportedBrowser => (
              <Box mr="m">
                <BrowserLogo
                  key={supportedBrowser}
                  browser={supportedBrowser}
                  storeLink={`${storeLinks[supportedBrowser]}?subSource=Dashboard%20Settings`}
                />
              </Box>
            ))}
        </Box>
      </div>
    );
  }
  return (
    <Card
      title={(
        <FormattedMessage id="dashboard.settings.extension" />
      )}
      mb="l"
    >
      {value}
    </Card>
  );
};

ExtensionSettings.propTypes = {
  extensionInstalled: PropTypes.bool.isRequired,
  isBrowserSupported: PropTypes.bool.isRequired,
  browser: PropTypes.string.isRequired,
  storeLinks: PropTypes.shape({}).isRequired,
  installedVersion: PropTypes.string.isRequired,
};

export default ExtensionSettings;
