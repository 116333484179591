import 'url-search-params-polyfill';

import {
  TRACKING_IDENTIFY,
  TRACKING_SET_LOCAL_SALT,
  TRACKING_SET_OE_IDS,
} from './TrackingActionTypes';

import mixpanel from './mixpanel';

const trackAARRREvent = (event, properties) => (
  _,
  getState,
  { eventTranslator, oeService },
) => {
  const aarrrEvent = eventTranslator.translate(event, mixpanel.getData(properties));

  if (aarrrEvent) {
    const state = getState();
    const {
      oe: { token, unregisteredModeToken },
      tracking: { oeDeviceId, oeUserId },
    } = state;

    // Check table at https://portal.avira.org/display/IPU/AARRR+for+extensions
    // The only time we use anonymous OE tokens is when user is in unregistered mode.
    // Whenever we have a registered oe token we shouldn't send the user id
    let userId = null;
    let oeToken = token;
    if (unregisteredModeToken) {
      userId = oeUserId;
      oeToken = unregisteredModeToken;
    }

    if (oeToken && oeDeviceId) {
      oeService.trackEvent(
        oeToken,
        oeDeviceId,
        userId,
        aarrrEvent,
      ).catch(() => {});
    }
  }
};

export const setOeIds = (oeDeviceId, oeUserId) => ({
  type: TRACKING_SET_OE_IDS,
  payload: {
    oeDeviceId,
    oeUserId,
  },
});

export const trackEvent = (event, props) => (dispatch) => {
  try {
    mixpanel.track(event, props);
    dispatch(trackAARRREvent(event, props));
  } catch (e) { /**/ }
};

export const identify = distinctId => ({
  type: TRACKING_IDENTIFY,
  payload: distinctId,
});

export const tryIdentifying = () => (
  (dispatch, getState, { licenseService }) => {
    const { user, userData } = getState();

    if (user.key && userData.distinct_id) {
      const distinctId = licenseService.getPlainDistinctId(user.key, userData.distinct_id);
      dispatch(identify(distinctId));
    }
  }
);

export const setLocalSalt = localSalt => ({ type: TRACKING_SET_LOCAL_SALT, payload: localSalt });

const getQueryKey = (referrerUrl, key) => (
  (new URLSearchParams(referrerUrl.search)).get(key) || null
);

const marketingParamsXA = {
  source: (_url, referrerUrl) => getQueryKey(referrerUrl, 'x-a-source'),
  subSource: (_url, referrerUrl) => getQueryKey(referrerUrl, 'subSource'),
  sourceMedium: (_url, referrerUrl) => getQueryKey(referrerUrl, 'x-a-medium'),
  sourceVersion: (_url, referrerUrl) => getQueryKey(referrerUrl, 'x-a-version'),
};

const marketingParamsUTM = {
  source: (_url, referrerUrl) => getQueryKey(referrerUrl, 'utm_source'),
  subSource: (_url, referrerUrl) => getQueryKey(referrerUrl, 'message_content'),
  sourceMedium: (_url, referrerUrl) => getQueryKey(referrerUrl, 'utm_term'),
  sourceVersion: (_url, referrerUrl) => getQueryKey(referrerUrl, 'utm_content'),
};

const SOURCES = [
  {
    referrerPattern: /^https:\/\/www.avira.com\/\w\w(-\w\w)?\/avira-password-manager($|\\|\?)/,
    params: {
      source: 'Product Page',
      subSource: (_url, referrerUrl) => getQueryKey(referrerUrl, 'x-a-source'),
      sourceMedium: (_url, referrerUrl) => getQueryKey(referrerUrl, 'x-a-medium'),
      sourceVersion: (_url, referrerUrl) => getQueryKey(referrerUrl, 'x-a-version'),
    },
  },
  {
    referrerPattern: /^https:\/\/www.avira.com\/\w\w(-\w\w)?\/avira-opera-welcome-page($|\\|\?)/,
    params: {
      source: 'Opera FTU',
    },
  },
  {
    referrerPattern: /^https:\/\/www.avira.com\/\w\w(-\w\w)?\/password-manager-acq($|\\|\?)/,
    params: {
      source: 'adw',
    },
  },
  {
    referrerPattern: /^https:\/\/www.avira.com\/\w\w(-\w\w)?\/pwm-pro-release(-lp)?($|\\|\?)/,
    params: marketingParamsXA,
  },
  {
    referrerPattern: /^https:\/\/campaigns.avira.com\//,
    params: marketingParamsXA,
  },
  {
    referrerPattern: /^https:\/\/blog.avira.com\/.*\/[&?]x-a-source=/,
    params: marketingParamsXA,
  },
  {
    referrerPattern: /^https:\/\/blog.avira.com\/.*\/[&?]utm_source=/,
    params: marketingParamsUTM,
  },
  {
    urlPattern: /[&?]extension-context=/,
    params: {
      source: 'Extension',
      subSource: url => getQueryKey(url, 'extension-context'),
    },
  },
  {
    urlPattern: /[&?]spotlight-context=/,
    params: {
      source: 'Spotlight',
      subSource: url => getQueryKey(url, 'spotlight-context'),
    },
  },
  {
    urlPattern: /\/account-verified/,
    params: {
      source: 'Email',
      subSource: 'Account verified',
    },
  },
  {
    urlPattern: /[&?]utm_source=/,
    params: {
      source: 'Email',
      subSource: url => getQueryKey(url, 'utm_source'),
      sourceMedium: url => getQueryKey(url, 'utm_term'),
      sourceVersion: url => getQueryKey(url, 'utm_content'),
    },
  },
  {
    urlPattern: /[&?]x-a-source=/,
    params: {
      source: url => getQueryKey(url, 'x-a-source'),
      subSource: url => getQueryKey(url, 'subSource'),
      sourceMedium: url => getQueryKey(url, 'x-a-medium'),
      sourceVersion: url => getQueryKey(url, 'x-a-version'),
    },
  },
];

export const detectSource = (location, referrer) => () => {
  let referringDomain;
  let params = {
    source: null,
    subSource: null,
    sourceMedium: null,
    sourceVersion: null,
  };

  const url = new URL(location);
  let referrerUrl;

  try {
    referrerUrl = new URL(referrer);
    referringDomain = referrerUrl.hostname;
  } catch (e) {
    referringDomain = null;
  }

  try {
    const sourcePattern = SOURCES.find(
      ({ urlPattern, referrerPattern }) => (
        (!urlPattern || location.match(urlPattern))
        && (!referrerPattern || (referrerUrl && referrerUrl.href.match(referrerPattern)))
      ),
    );

    if (sourcePattern) {
      params = Object.entries(sourcePattern.params)
        .reduce((obj, [key, value]) => Object.assign(obj, {
          [key]: typeof value === 'function'
            ? value(url, referrerUrl)
            : value,
        }), params);
    }
  } catch (e) { /* do nothing */ }

  mixpanel.register({
    Source: params.source,
    SubSource: params.subSource,
    SourceMedium: params.sourceMedium,
    SourceVersion: params.sourceVersion,
    referringDomain,
  });
};

export const trackTimeout = (component, extraProps = {}) => (dispatch) => {
  dispatch(trackEvent('Timeout', { component, ...extraProps }));
};
