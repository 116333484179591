import React from 'react';
import * as Sentry from '@sentry/browser';

class ErrorBoundary extends React.Component {
  public componentDidCatch(error: any, info: any): void {
    if (error instanceof Error) {
      Sentry.withScope((scope) => {
        scope.setExtras(info);
        Sentry.captureException(error);
      });
    }
  }

  public render(): React.ReactNode {
    const { children } = this.props;
    return children;
  }
}

export default ErrorBoundary;
