import React from 'react';
import ProtectedRoute from './ProtectedRoute';

const requirement = ({ user, dashboard: { isUnregisteredMode } }) => (
  !!(user.authToken || isUnregisteredMode)
);

const AuthorizedRoute = props => (
  <ProtectedRoute
    {...props}
    requirement={requirement}
    redirectPath="/enter-master-password"
  />
);

export default AuthorizedRoute;
