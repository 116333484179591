import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Button from 'pwm-components/components/Button';

type props = {
  onBackClick: () => void;
  onNextClick: () => void;
}

const SpotlightOnboardingNewUser: React.FC<props> = ({
  onBackClick,
  onNextClick,
}) => (
  <>
    <Paragraph mb="m" size="h2">
      <FormattedMessage
        id="spotlightOnboardingNewUser.headline"
        defaultMessage="Never forget a password again"
      />
    </Paragraph>
    <Box display="flex" flexWrap="wrap" justifyContent="center" mb="xxl">
      <Paragraph noWrap textAlign="center">
        <FormattedMessage
          id="spotlightOnboardingNewUser.automaticLogin"
          defaultMessage="Automatically login on the websites you use daily."
        />
      </Paragraph>
      <Paragraph ml="xxs" noWrap textAlign="center">
        <FormattedMessage
          id="spotlightOnboardingNewUser.onAllDevices"
          defaultMessage="On all your devices."
        />
      </Paragraph>
    </Box>
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mr="m">
        <Button
          fullWidth
          onClick={onNextClick}
        >
          <FormattedMessage
            id="spotlightOnboardingConainer.next"
            defaultMessage="Next"
          />
        </Button>
      </Box>
      <Box>
        <Button
          variant="secondary"
          onClick={onBackClick}
        >
          <FormattedMessage
            id="spotlightOnboardingConainer.back"
            defaultMessage="Back"
          />
        </Button>
      </Box>
    </Box>
  </>
);

export default SpotlightOnboardingNewUser;
