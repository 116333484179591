import { defineMessages } from 'react-intl';

export default defineMessages({
  msg_zxcvbn_use_a_few_words_avoid_common_phrases: {
    id: 'auth.zxcvbn.UseAFewWordsAvoidCommonPhrases',
    defaultMessage: 'Use a few words, avoid common phrases',
  },
  msg_zxcvbn_no_need_for_symbols_digits_or_uppercase_letters: {
    id: 'auth.zxcvbn.NoNeedForSymbolsDigitsOrUppercaseLetters',
    defaultMessage: 'No need for symbols, digits, or uppercase letters',
  },
  msg_zxcvbn_add_another_word_or_two_uncommon_words_are_better: {
    id: 'auth.zxcvbn.AddAnotherWordOrTwoUncommonWordsAreBetter',
    defaultMessage: 'Add another word or two. Uncommon words are better.',
  },
  msg_zxcvbn_straight_rows_of_keys_are_easy_to_guess: {
    id: 'auth.zxcvbn.StraightRowsOfKeysAreEasyToGuess',
    defaultMessage: 'Straight rows of keys are easy to guess',
  },
  msg_zxcvbn_short_keyboard_patterns_are_easy_to_guess: {
    id: 'auth.zxcvbn.ShortKeyboardPatternsAreEasyToGuess',
    defaultMessage: 'Short keyboard patterns are easy to guess',
  },
  msg_zxcvbn_use_a_longer_keyboard_pattern_with_more_turns: {
    id: 'auth.zxcvbn.UseALongerKeyboardPatternWithMoreTurns',
    defaultMessage: 'Use a longer keyboard pattern with more turns',
  },
  msg_zxcvbn_repeats_like_aaa_are_easy_to_guess: {
    id: 'auth.zxcvbn.RepeatsLikeAaaAreEasyToGuess',
    defaultMessage: 'Repeats like "aaa" are easy to guess',
  },
  msg_zxcvbn_repeats_like_abcabcabc_are_only_slightly_harder_to_guess_than_abc: {
    id: 'auth.zxcvbn.RepeatsLikeAbcabcabcAreOnlySlightlyHarderToGuessThanAbc',
    defaultMessage: 'Repeats like "abcabcabc" are only slightly harder to guess than "abc"',
  },
  msg_zxcvbn_avoid_repeated_words_and_characters: {
    id: 'auth.zxcvbn.AvoidRepeatedWordsAndCharacters',
    defaultMessage: 'Avoid repeated words and characters',
  },
  msg_zxcvbn_sequences_like_abc_or_6543_are_easy_to_guess: {
    id: 'auth.zxcvbn.SequencesLikeAbcOr_6543AreEasyToGuess',
    defaultMessage: 'Sequences like abc or 6543 are easy to guess',
  },
  msg_zxcvbn_avoid_sequences: {
    id: 'auth.zxcvbn.AvoidSequences',
    defaultMessage: 'Avoid sequences',
  },
  msg_zxcvbn_recent_years_are_easy_to_guess: {
    id: 'auth.zxcvbn.RecentYearsAreEasyToGuess',
    defaultMessage: 'Recent years are easy to guess',
  },
  msg_zxcvbn_avoid_recent_years: {
    id: 'auth.zxcvbn.AvoidRecentYears',
    defaultMessage: 'Avoid recent years',
  },
  msg_zxcvbn_avoid_years_that_are_associated_with_you: {
    id: 'auth.zxcvbn.AvoidYearsThatAreAssociatedWithYou',
    defaultMessage: 'Avoid years that are associated with you',
  },
  msg_zxcvbn_dates_are_often_easy_to_guess: {
    id: 'auth.zxcvbn.DatesAreOftenEasyToGuess',
    defaultMessage: 'Dates are often easy to guess',
  },
  msg_zxcvbn_avoid_dates_and_years_that_are_associated_with_you: {
    id: 'auth.zxcvbn.AvoidDatesAndYearsThatAreAssociatedWithYou',
    defaultMessage: 'Avoid dates and years that are associated with you',
  },
  msg_zxcvbn_this_is_a_top_10_common_password: {
    id: 'auth.zxcvbn.ThisIsATop_10CommonPassword',
    defaultMessage: 'This is a top-10 common password',
  },
  msg_zxcvbn_this_is_a_top_100_common_password: {
    id: 'auth.zxcvbn.ThisIsATop_100CommonPassword',
    defaultMessage: 'This is a top-100 common password',
  },
  msg_zxcvbn_this_is_a_very_common_password: {
    id: 'auth.zxcvbn.ThisIsAVeryCommonPassword',
    defaultMessage: 'This is a very common password',
  },
  msg_zxcvbn_this_is_similar_to_a_commonly_used_password: {
    id: 'auth.zxcvbn.ThisIsSimilarToACommonlyUsedPassword',
    defaultMessage: 'This is similar to a commonly used password',
  },
  msg_zxcvbn_a_word_by_itself_is_easy_to_guess: {
    id: 'auth.zxcvbn.AWordByItselfIsEasyToGuess',
    defaultMessage: 'A word by itself is easy to guess',
  },
  msg_zxcvbn_names_and_surnames_by_themselves_are_easy_to_guess: {
    id: 'auth.zxcvbn.NamesAndSurnamesByThemselvesAreEasyToGuess',
    defaultMessage: 'Names and surnames by themselves are easy to guess',
  },
  msg_zxcvbn_common_names_and_surnames_are_easy_to_guess: {
    id: 'auth.zxcvbn.CommonNamesAndSurnamesAreEasyToGuess',
    defaultMessage: 'Common names and surnames are easy to guess',
  },
  msg_zxcvbn_capitalization_doesnt_help_very_much: {
    id: 'auth.zxcvbn.CapitalizationDoesntHelpVeryMuch',
    defaultMessage: 'Capitalization doesn\'t help very much',
  },
  msg_zxcvbn_all_uppercase_is_almost_as_easy_to_guess_as_all_lowercase: {
    id: 'auth.zxcvbn.AllUppercaseIsAlmostAsEasyToGuessAsAllLowercase',
    defaultMessage: 'All-uppercase is almost as easy to guess as all-lowercase',
  },
  msg_zxcvbn_reversed_words_arent_much_harder_to_guess: {
    id: 'auth.zxcvbn.ReversedWordsArentMuchHarderToGuess',
    defaultMessage: 'Reversed words aren\'t much harder to guess',
  },
  'msg_zxcvbn_predictable_substitutions_like_@_instead_of_a_dont_help_very_much': {
    id: 'auth.zxcvbn.PredictableSubstitutionsLikeInsteadOfADontHelpVeryMuch',
    defaultMessage: 'Predictable substitutions like \'@\' instead of \'a\' don\'t help very much',
  },
  'auth.zxcvbn.passwordRatingWeak': {
    id: 'auth.zxcvbn.passwordRatingWeak',
    defaultMessage: 'Weak Password',
  },
  'auth.zxcvbn.passwordRatingMedium': {
    id: 'auth.zxcvbn.passwordRatingMedium',
    defaultMessage: 'Medium Password',
  },
  'auth.zxcvbn.passwordRatingGood': {
    id: 'auth.zxcvbn.passwordRatingGood',
    defaultMessage: 'Good Password',
  },
  'auth.zxcvbn.passwordRatingStrong': {
    id: 'auth.zxcvbn.passwordRatingStrong',
    defaultMessage: 'Strong Password',
  },
  'auth.legalStatement': {
    id: 'auth.legalStatement',
    defaultMessage: 'By proceeding, you are confirming that you have read and accepted the <a href="https://www.avira.com/en/end-user-license-agreement-terms-of-use/" target="_blank" >End User License Agreement (EULA)</a>, the <a href="https://www.avira.com/en/legal-terms" target="_blank" >Terms and Conditions</a> and our <a href="https://www.avira.com/en/general-privacy" target="_blank" >Privacy Policy</a>.',
  },
  'auth.registerOrLogin.or': {
    id: 'auth.registerOrLogin.or',
    defaultMessage: 'Or login with:',
  },
  'ftu.allYourPasswordsAreSecured': {
    id: 'ftu.allYourPasswordsAreSecured',
    defaultMessage: 'All your passwords<br> in one secure place',
  },
  'ftu.easyAccessToYourPasswords': {
    id: 'ftu.easyAccessToYourPasswords',
    defaultMessage: 'Easy access to your passwords<br> from any device',
  },
  'ftu.encryptedMasterPassword': {
    id: 'ftu.encryptedMasterPassword',
    defaultMessage: '<span class="u-color-red u-txt-upper">one</span> enctypted Master Password<br> for <span class="u-color-red u-txt-upper">all</span> your accounts',
  },
  'ftu.testedAndRecommended': {
    id: 'ftu.testedAndRecommended',
    defaultMessage: 'Tested and recommended<br> by CHIP',
  },
  resetEmailAction: {
    id: 'resetEmail.action',
    defaultMessage: 'Get the reset link',
  },
  resetEmailActionSuccess: {
    id: 'resetEmail.action.success',
    defaultMessage: 'Send again',
  },
  authEmailPlaceholder: {
    id: 'auth.email.placeholder',
    defaultMessage: 'Email Address',
  },
  authPasswordPlaceholder: {
    id: 'auth.password.placeholder',
    defaultMessage: 'Password',
  },
  'login.rememberBrowser': {
    id: 'login.rememberBrowser',
    defaultMessage: "Don't ask again for this browser",
  },
  'error.login.noEmail': {
    id: 'error.login.noEmail',
    defaultMessage: 'Enter your email address.',
  },
  'error.login.invalidEmail': {
    id: 'error.login.invalidEmail',
    defaultMessage: 'Enter a valid email address.',
  },
  'error.login.noPassword': {
    id: 'error.login.noPassword',
    defaultMessage: 'Enter your password.',
  },
  'error.login.invalidCredentials': {
    id: 'error.login.invalidCredentials',
    defaultMessage: 'Incorrect email or password.',
  },
  'error.login.disabledAccount': {
    id: 'error.login.disabledAccount',
    defaultMessage: 'Your account is disabled and scheduled for deletion. Please register a new account using a different email address.',
  },
  'error.login.requiredCaptcha': {
    id: 'error.login.requiredCaptcha',
    defaultMessage: 'Solve the puzzle above.',
  },
  'error.login.invalidOtp': {
    id: 'error.login.invalidOtp',
    defaultMessage: 'Please enter a valid verification code.',
  },
  'error.login.retriesExceeded': {
    id: 'error.login.retriesExceeded',
    defaultMessage: 'You have exceeded the number of allowed login attempts. Please try again after sometime.',
  },
  'error.otp.retriesExceeded': {
    id: 'error.otp.retriesExceeded',
    defaultMessage: 'You have exceeded the number of allowed requests. Please try again later.',
  },
  'error.register.passwordIsNotStrongEnough': {
    id: 'error.register.passwordIsNotStrongEnough',
    defaultMessage: 'Password is not strong enough. Please follow the recommendations below.',
  },
  'error.register.emailExists': {
    id: 'error.register.emailExists',
    defaultMessage: 'You already have an Avira account. <a href="/login?dashboardContext=email-exists" class="js-error-login-action">Please log in.</a>',
  },
  'error.failedToFetch': {
    id: 'error.failedToFetch',
    defaultMessage: 'A network request failed, check your internet connection (Failed to fetch)',
  },
  'error.networkRequestFailed': {
    id: 'error.networkRequestFailed',
    defaultMessage: 'A network request failed, check your internet connection (CORS)',
  },
  'error.unknown': {
    id: 'error.unknown',
    defaultMessage: 'Unknown Error.',
  },
  'error.connectionTimeout': {
    id: 'error.connectionTimeout',
    defaultMessage: 'Connection timed out, please try again later.',
  },
  'error.serverError': {
    id: 'error.serverError',
    defaultMessage: 'Oops. Sorry, there was a error in the authentication process. Try again later or contact Support.',
  },
  'error.outdated.extension.version': {
    id: 'error.outdated.extension.version',
    defaultMessage: 'You must update your Password Manager extension before you can continue. Contact <a href="{supportUrl}" target="_blank" >Support</a> if the issue persists.',
  },
  'login.otpChallenge': {
    id: 'login.otpChallenge',
    defaultMessage: 'Please enter the verification code we sent you on your mobile',
  },
  'login.challenge': {
    id: 'login.challenge',
    defaultMessage: 'Log in with your Avira Account',
  },
  'login.loggedIn': {
    id: 'login.loggedIn',
    defaultMessage: 'Logged in as {userEmail}',
  },

  // MasterPassword
  'masterPassword.change.action': {
    id: 'masterPassword.change.action',
    defaultMessage: 'Change',
  },
  'masterPassword.error.breached': {
    id: 'masterPassword.error.breached',
    defaultMessage: 'Your password has been compromised in a data breach according to <a href="https://haveibeenpwned.com/" target="_blank">haveibeenpwned.com</a>. Please choose a different password.',
  },
  'masterPassword.error.empty': {
    id: 'masterPassword.error.empty',
    defaultMessage: 'Enter your Master password.',
  },
  'masterPassword.error.invalid': {
    id: 'masterPassword.error.invalid',
    defaultMessage: 'Enter a valid master password.',
  },
  'masterPassword.error.verify.notIdentical': {
    id: 'masterPassword.error.verify.notIdentical',
    defaultMessage: 'The passwords don\'t match.',
  },
  'masterPassword.error.verify.confirm': {
    id: 'masterPassword.error.verify.confirm',
    defaultMessage: 'Confirm Master Password.',
  },
  'masterPassword.error.noConsent': {
    id: 'masterPassword.error.noConsent',
    defaultMessage: 'Please confirm to continue',
  },
  'masterPassword.error.verify.wrong': {
    id: 'masterPassword.error.verify.wrong',
    defaultMessage: 'Enter the correct master password. This is the key you entered apart from your Avira account password.',
  },
  'masterPassword.create.header': {
    id: 'masterPassword.create.header',
    defaultMessage: 'Create your master password',
  },
  'masterPassword.create.challenge': {
    id: 'masterPassword.create.challenge',
    defaultMessage: 'Your one key to all your accounts. Make it complex and memorable.',
  },
  'masterPassword.create.placeholder': {
    id: 'masterPassword.create.placeholder',
    defaultMessage: 'Master password',
  },
  'masterPassword.create.action': {
    id: 'masterPassword.create.action',
    defaultMessage: 'Done',
  },
  'masterPassword.error.create.empty': {
    id: 'masterPassword.error.create.empty',
    defaultMessage: 'Specify Master Password.',
  },
  'masterPassword.error.create.invalid': {
    id: 'masterPassword.error.create.invalid',
    defaultMessage: 'Enter a valid master password.',
  },
  'masterPassword.verify.header': {
    id: 'masterPassword.verify.header',
    defaultMessage: 'Remember your master password',
  },
  'masterPassword.verify.challenge': {
    id: 'masterPassword.verify.challenge',
    defaultMessage: 'It is so secret that we cannot reset it in case you lose it.',
  },
  'masterPassword.verify.placeholder': {
    id: 'masterPassword.verify.placeholder',
    defaultMessage: 'Confirm master password',
  },
  'masterPassword.verify.action': {
    id: 'masterPassword.verify.action',
    defaultMessage: 'Complete',
  },
  'masterPassword.verify.backAction': {
    id: 'masterPassword.verify.backAction',
    defaultMessage: 'Back',
  },
  'masterPassword.error.verify.empty': {
    id: 'masterPassword.error.verify.empty',
    defaultMessage: 'Confirm your master password.',
  },
  'masterPassword.error.verify.invalid': {
    id: 'masterPassword.error.verify.invalid',
    defaultMessage: 'Enter a valid master password.',
  },
  'masterPassword.provide.header': {
    id: 'masterPassword.provide.header',
    defaultMessage: 'Enter your master password',
  },
  'masterPassword.provide.challenge': {
    id: 'masterPassword.provide.challenge',
    defaultMessage: 'It is the key to decrypt the data you store in Password Manager.',
  },
  'masterPassword.provide.placeholder': {
    id: 'masterPassword.provide.placeholder',
    defaultMessage: 'Master Password',
  },
  'masterPassword.provide.action': {
    id: 'masterPassword.provide.action',
    defaultMessage: 'Done',
  },
  'masterPassword.provide.backAction': {
    id: 'masterPassword.provide.backAction',
    defaultMessage: 'Back',
  },
  'masterPassword.error.provide.invalid': {
    id: 'masterPassword.error.provide.invalid',
    defaultMessage: 'Enter the correct master password. This is the key you entered apart from your Avira account password.',
  },
  'masterPassword.error.provide.empty': {
    id: 'masterPassword.error.provide.empty',
    defaultMessage: 'Enter your Master password.',
  },
  'masterPassword.unlock.header': {
    id: 'masterPassword.unlock.header',
    defaultMessage: 'Avira Password Manager is locked',
  },
  'masterPassword.unlock.passwordLabel': {
    id: 'masterPassword.unlock.passwordLabel',
    defaultMessage: 'Master password',
  },
  'masterPassword.unlock.placeholder': {
    id: 'masterPassword.unlock.placeholder',
    defaultMessage: 'Enter your master password',
  },
  'masterPassword.unlock.action': {
    id: 'masterPassword.unlock.action',
    defaultMessage: 'Done',
  },
  'masterPassword.reset.action': {
    id: 'masterPassword.reset.action',
    defaultMessage: 'Reset your Account',
  },
  'masterPassword.error.unlock.empty': {
    id: 'masterPassword.error.unlock.empty',
    defaultMessage: 'Enter your Master password.',
  },
  'masterPassword.error.unlock.invalid': {
    id: 'masterPassword.error.unlock.invalid',
    defaultMessage: 'Enter the correct master password. This is the key you entered apart from your Avira account password.',
  },
  'masterPassword.error.unlock.wrong': {
    id: 'masterPassword.error.unlock.wrong',
    defaultMessage: 'Enter the correct master password. This is the key you entered apart from your Avira account password.',
  },
  'masterPassword.userInfo.loggedIn': {
    id: 'masterPassword.userInfo.loggedIn',
    defaultMessage: 'Logged in as {userEmail}',
  },
  'masterPassword.userInfo.registered': {
    id: 'masterPassword.userInfo.registered',
    defaultMessage: 'Registered as {userEmail}',
  },
  'masterPassword.userInfo.forgot': {
    id: 'masterPassword.userInfo.forgot',
    defaultMessage: 'Forgot Master Password?',
  },
  'masterPassword.transitionToRegistered.cancel': {
    id: 'masterPassword.transitionToRegistered.cancel',
    defaultMessage: 'Cancel',
  },
  'verifyEmail.header': {
    id: 'verifyEmail.header',
    defaultMessage: 'Verify your Avira account',
  },
  'verifyEmail.description': {
    id: 'verifyEmail.description',
    defaultMessage: 'Check your {email} inbox to verify your account and come back here.',
  },
  'verifyEmail.description.spam': {
    id: 'verifyEmail.description.spam',
    defaultMessage: 'Make sure to look into your spam and junk folder as well.',
  },
  'verifyEmail.verifiedMyEmail': {
    id: 'verifyEmail.verifiedMyEmail',
    defaultMessage: 'I verified my account',
  },
  'verifyEmail.mailNotRecieved': {
    id: 'verifyEmail.mailNotRecieved',
    defaultMessage: 'Didn\'t get the email?',
  },
  'verifyEmail.resend': {
    id: 'verifyEmail.resend',
    defaultMessage: 'Resend it',
  },
  'verifyEmail.resendEmail': {
    id: 'verifyEmail.resendEmail',
    defaultMessage: 'Resend email',
  },
  'verifyEmail.registeredAs': {
    id: 'verifyEmail.registeredAs',
    defaultMessage: 'Registered as {email}',
  },
  'verifyEmail.change': {
    id: 'verifyEmail.change',
    defaultMessage: '(Change)',
  },
  'verifyEmail.error.emailNotVerified': {
    id: 'verifyEmail.error.emailNotVerified',
    defaultMessage: 'To proceed you first need to confirm your email.',
  },
  'verifyEmail.emailSent': {
    id: 'verifyEmail.emailSent',
    defaultMessage: 'Email sent',
  },
  'verifyEmail.cancel': {
    id: 'verifyEmail.cancel',
    defaultMessage: 'Cancel',
  },
  'masterPassword.create.masterPassword': {
    id: 'masterPassword.create.masterPassword',
    defaultMessage: 'Create Master password',
  },

  // Account verified page
  'accountVerified.tokenPresentOrAppInstalled.header': {
    id: 'accountVerified.tokenPresentOrAppInstalled.header',
    defaultMessage: 'Almost there!',
  },
  'accountVerified.tokenPresentOrAppInstalled.message': {
    id: 'accountVerified.tokenPresentOrAppInstalled.message',
    defaultMessage: 'Thank you for verifying your email.',
  },
  'accountVerified.tokenPresentOrAppInstalled.instruction': {
    id: 'accountVerified.tokenPresentOrAppInstalled.instruction',
    defaultMessage: 'To finalize the setup please proceed to the next step.',
  },
  'accountVerified.tokenPresentOrAppInstalled.nextStep': {
    id: 'accountVerified.tokenPresentOrAppInstalled.nextStep',
    defaultMessage: 'Next Step',
  },
  'accountVerified.tokenAbsentOrAppNotInstalled.message': {
    id: 'accountVerified.tokenAbsentOrAppNotInstalled.message',
    defaultMessage: 'Thank you for verifying your email!',
  },
  'accountVerified.tokenAbsentOrAppNotInstalled.instruction': {
    id: 'accountVerified.tokenAbsentOrAppNotInstalled.instruction',
    defaultMessage: 'Please go back to the registration process and finalize your account setup.',
  },
  'accountVerified.tokenAbsent.continue': {
    id: 'accountVerified.tokenAbsent.continue',
    defaultMessage: 'Closed the registration process? This link helps you continue',
  },
  'dashboard.verifyEmail.message1': {
    id: 'dashboard.verifyEmail.message1',
    defaultMessage: 'A verification email has been sent to <strong>{email}</strong>',
  },
  'dashboard.verifyEmail.message2': {
    id: 'dashboard.verifyEmail.message2',
    defaultMessage: 'Check your inbox (also check your Spam or Junk folder)',
  },
  'dashboard.verifyEmail.message3': {
    id: 'dashboard.verifyEmail.message3',
    defaultMessage: 'To verify your account, click the button in the email and come back here',
  },
});
