import { DeepReadonly } from 'utility-types';
import {
  SET_SYNC_STORE, SET_USER_DATA, UPDATE_USER_DATA, ADD_MILESTONE, CLEAR_USER_DATA,
} from './UserActionTypes';
import config from '../config';
import { DASHBOARD_ACTIVATE_UNREGISTERED_MODE } from '../dashboard/DashboardActionTypes';

import { transformMilestones } from '../lib/MilestonesHelper';

type license = {
  id: string;
  type: string;
}

type milstone = {
  id: number;
  name: string;
}

export type State = DeepReadonly<{
  id: number | null;
  isPro: boolean;
  milestones: Array<milstone>;
  licenses: Array<license>;
  breach_check: boolean;
  sync_store_manager: string | null;
  distinct_id: string | null;
  should_migrate?: boolean;
  vault_sdk_enabled?: boolean;
  status_us_compliance_requested: string | null;
  status_us_compliance_verified_date: string | null;
  status_us_compliance_approved: boolean | null;
  migration_status?: {
    status: string;
  };
}>;

function isUserPro(licenses: Array<license> | null): boolean {
  return (licenses && licenses[0].type !== 'free') || config.bypassLicense;
}

export function defaultState(): State {
  return {
    id: null,
    isPro: false,
    milestones: [],
    licenses: [],
    breach_check: false,
    sync_store_manager: null,
    distinct_id: null,
    status_us_compliance_requested: null,
    status_us_compliance_verified_date: null,
    status_us_compliance_approved: null,
  };
}

// eslint-disable-next-line complexity
export default function userDataReducer(state = defaultState(), action: any): State {
  switch (action.type) {
    case SET_SYNC_STORE:
      return {
        ...state,
        sync_store_manager: action.syncStoreManager,
      };
    case SET_USER_DATA:
      return {
        ...action.data,
        isPro: isUserPro(action.data.licenses),
        milestones: transformMilestones(action.data.milestones),
      };
    case CLEAR_USER_DATA:
      return defaultState();
    case UPDATE_USER_DATA:
      return {
        ...state,
        ...action.data,
        isPro: action.data.licenses ? isUserPro(action.data.licenses) : state.isPro,
        milestones: action.data.milestones
          ? transformMilestones(action.data.milestones) : state.milestones,
      };
    case ADD_MILESTONE:
      return {
        ...state,
        milestones: {
          ...state.milestones,
          ...action.data,
        },
      };
    case DASHBOARD_ACTIVATE_UNREGISTERED_MODE:
      return {
        ...state,
        licenses: [{ id: 'unregistered', type: 'unregistered' }],
        breach_check: false,
        isPro: isUserPro(null),
      };
    default:
      return state;
  }
}
