/**
 * @template T
 * @typedef {T extends void ? () => unknown : (val: T) => unknown} Cb
 */

/** @template T */
class Event {
  constructor() {
    /** @type {Cb<T>[]} */
    this._listeners = [];
  }

  /**
   * @param {Cb<T>} callback
   */
  addListener(callback) {
    this._listeners = [...this._listeners, callback];
  }

  /**
   * @param {Cb<T>} callback
   */
  removeListener(callback) {
    this._listeners = this
      ._listeners
      .filter(s => s !== callback);
  }

  /**
   * @param {T} val
   */
  trigger(val) {
    this._listeners.forEach((callback) => {
      callback(val);
    });
  }
}

export default Event;
