/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';

import VersionComparisionRowHelper from '../../../componentLib/VersionComparisionRowHelper';

const FreeVsProOffers = () => (
  <Box>
    <table className="c-why-register">
      <thead>
        <tr className="c-why-register__headerRow u-txt-strong">
          <th style={{ paddingTop: 0 }} />
          <th style={{ paddingTop: 0 }}>
            <FormattedMessage
              id="dashboard.getStarted.freeVsPro.freeVersion"
              defaultMessage="Free Version"
            />
          </th>
          <th style={{ paddingTop: 0 }}>
            <FormattedMessage
              id="dashboard.getStarted.freeVsPro.proVersion"
              defaultMessage="PRO Version"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <VersionComparisionRowHelper
          hideUnregistered
        />
      </tbody>
    </table>
  </Box>
);

export default FreeVsProOffers;
