import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link, Route } from 'react-router-dom';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';
import IconUser from 'pwm-components/icons/User';

import styled from 'pwm-components/styled';
import PWMLogo from '../../componentLib/PWMLogo';
import Upsell from '../../componentLib/Upsell';
import { getDaysLeft, isCoronaSpecial, isUpsellAllowed } from '../../user/selectors';
import UserProfile from './UserProfile';

const HeaderBox = styled(Box)`
  background: ${({ theme: { semanticColors } }) => semanticColors.foreground};
  border-color: ${({ theme: { mode, colors } }) => (mode === 'light' ? colors.gainsboro : colors.martinique)};
`;

const Header = (props) => {
  const {
    hasAccessToPro,
    paymentUrl,
    proUnlocked,
    oe,
    oeData,
    isPro,
    isUnregisteredMode,
    ...menuActions
  } = props;
  const { email } = oe;
  const daysLeft = getDaysLeft();

  const upsellAllowed = useSelector(isUpsellAllowed);

  return (
    <HeaderBox borderBottom zIndex={20} p="s">
      <div className="u-fx u-fx-ai-c">
        <div className="u-fx u-fx-ai-c">
          <PWMLogo isPro={hasAccessToPro} />
        </div>
        <div className="u-ml-xxs u-fx-g-1">
          {proUnlocked
            ? (
              <FormattedMessage
                id="dashboard.getProFree.daysLeft"
                values={{ number: daysLeft }}
              />
            ) : null}
        </div>
        {
          upsellAllowed
          && (
            <Box mr="m">
              <a
                onClick={() => props.onGetProClick('header')}
                href={paymentUrl}
                role="button"
                target="_blank"
                rel="noopener noreferrer"
                id="a-getpro-action"
              >
                <Button variant="upsell" size="mini">
                  <Text>
                    <FormattedMessage id="dashboard.getpro" defaultMessage="Get Pro" />
                  </Text>
                </Button>
              </a>
              {
                !isCoronaSpecial()
                  && <Route exact path="/security-status" component={Upsell} />
              }
            </Box>
          )
        }
        {
          isUnregisteredMode ? (
            <Link to="/register?dashboardContext=header">
              <Button
                size="mini"
                variant="login"
              >
                <IconUser
                  variant="system"
                  size="small"
                  mr="xs"
                />
                <Text>
                  <FormattedMessage id="registerForFree.header" />
                </Text>
              </Button>
            </Link>
          ) : (
            <UserProfile
              profileImage={oeData.profile_image_small}
              email={email}
              isPro={isPro}
              {...menuActions}
            />
          )
        }
      </div>
    </HeaderBox>
  );
};

Header.defaultProps = {
  oeData: null,
  onGetProClick: () => {},
  oe: {
    email: null,
    token: null,
  },
};

Header.propTypes = {
  onLockClick: PropTypes.func.isRequired,
  onLogoutClick: PropTypes.func.isRequired,
  oe: PropTypes.shape({
    email: PropTypes.string,
    token: PropTypes.string,
  }),
  oeData: PropTypes.shape(),
  hasAccessToPro: PropTypes.bool.isRequired,
  onGetProClick: PropTypes.func,
  paymentUrl: PropTypes.string.isRequired,
  proUnlocked: PropTypes.bool.isRequired,
  isUnregisteredMode: PropTypes.bool.isRequired,
  isPro: PropTypes.bool.isRequired,
  openEditAccount: PropTypes.func.isRequired,
  openCancelRenewal: PropTypes.func.isRequired,
  openRenewalSettings: PropTypes.func.isRequired,
  openContractCancelationRefund: PropTypes.func.isRequired,
};

export default Header;
