export const calculateHeight = (el) => {
  const style = window.getComputedStyle(el);
  const height = el.clientHeight
    + parseInt(style.borderBottomWidth, 10)
    + parseInt(style.borderTopWidth, 10);

  return height;
};

export const calculateVerticalPadding = (el) => {
  const style = window.getComputedStyle(el);
  return parseInt(style.paddingBottom, 10) + parseInt(style.paddingTop, 10);
};
