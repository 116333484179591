// since auth is inherited in other components the state is used in those.
import React from 'react';
import PropTypes from 'prop-types';

import AuthenticationHelper from '../../lib/AuthenticationHelper';
import { getErrorDescription } from '../../lib/ErrorHelper';
import config from '../../config';
import intlShape from '../../lib/intlShape';

class Auth extends React.Component {
  constructor(props) {
    super(props);

    const { email, intl, error } = this.props;

    this.state = {
      email,
      password: '',
      error,
      loading: false,
      otpRequired: false,
      aviraPhone: null,
      trustBrowser: false,
      accessToken: null,
      handle: null,
      socialMediaUniqueId: null,
      mountTimestamp: null,
    };

    this.facebookAppId = config.localBuild ? config.facebookAppIdLocal : config.facebookAppId;
    this.googleClientId = config.localBuild ? config.googleClientIdLocal : config.googleClientId;
    this.appleClientId = config.appleClientId;

    this.submitAttempt = this.submitAttempt.bind(this);
    this.afterSubmit = this.afterSubmit.bind(this);
    this.setEmailState = this.setEmailState.bind(this);
    this.authHelper = new AuthenticationHelper(intl);
    this.authenticateWithSocialMedia = this.authenticateWithSocialMedia.bind(this);
    this.handleSocialMediaAuthenticationError = (
      this.handleSocialMediaAuthenticationError.bind(this)
    );
  }

  componentDidMount() {
    this.setState({
      error: this.props.error,
      mountTimestamp: Date.now(),
    });

    if (this.trackOnShown) {
      this.trackOnShown();
    }

    this.focusTimeout = setTimeout(() => {
      if (this.focusElement) {
        this.focusElement.focus();
      }
    }, 100);
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  setErrorState(key) {
    this.setState({
      error: key,
    });
  }

  setEmailState(email) {
    const { onEmailUpdate } = this.props;
    this.setState({
      email,
      error: null,
    });
    onEmailUpdate(email);
  }

  handleSocialMediaAuthenticationError(err, handle) {
    const { onSocialMediaError } = this.props;

    onSocialMediaError(err, handle);

    this.setState({ loading: false });
  }

  async submitAttempt(submit) {
    this.setState({ loading: true });

    try {
      let oeInfo = null;
      const {
        accessToken,
        handle,
        email,
      } = this.state;
      const { registerLoginOEUserWithSocialMedia, context } = this.props;
      if (accessToken && handle) {
        oeInfo = await registerLoginOEUserWithSocialMedia({
          ...this.state,
          accessToken,
          handle,
          context,
        });
      } else {
        oeInfo = await submit({
          ...this.state,
          email: email.trim(),
          context,
        });
      }

      await this.afterSubmit(oeInfo);
      return null;
    } catch (error) {
      this.setState({ loading: false });
      this.setErrorState(error);
      return error;
    }
  }

  // eslint-disable-next-line max-params
  async authenticateWithSocialMedia(accessToken, handle, socialMediaUniqueId, redirectURI) {
    const { onFormSubmit } = this.props;
    await new Promise((resolve) => {
      this.setState({
        accessToken,
        handle,
        socialMediaUniqueId,
        redirectURI,
      }, resolve);
    });

    return this.submitAttempt(onFormSubmit);
  }

  async afterSubmit(oeInfo) {
    const {
      getOEUserData, onOtpRequired, onInvalidOtp, history,
    } = this.props;

    const { otpRequired } = this.state;

    if (oeInfo && oeInfo.otpRequired && !otpRequired) {
      onOtpRequired();
      this.setState({
        otpRequired: true,
        aviraPhone: oeInfo.aviraPhone,
        loading: false,
      });
    } else if (oeInfo && oeInfo.otpRequired && otpRequired) {
      onOtpRequired();
      onInvalidOtp();
      throw getErrorDescription('invalid_otp');
    } else if (typeof getOEUserData === 'function') {
      const { confirm, consent } = await getOEUserData();
      if (!confirm && (confirm || consent)) {
        history.push('/verify-email');
      } else {
        history.push('/master-password');
      }
    } else {
      this.setState({ loading: false });
    }
  }

  async resendOTP(submit) {
    const { registerLoginOEUserWithSocialMedia, onResendOtp } = this.props;
    try {
      const { accessToken, handle } = this.state;
      if (accessToken && handle) {
        await registerLoginOEUserWithSocialMedia({
          accessToken,
          handle,
        });
      } else {
        const {
          email, password, captchaKey, mountTimestamp,
        } = this.state;
        await submit({
          email: email.trim(),
          password,
          captchaKey,
          mountTimestamp,
        });
      }
      onResendOtp();
    } catch (error) {
      // set otp code specific error, since oe uses error.login.retriesExceeded as well
      error.key = 'error.otp.retriesExceeded';
      throw error;
    }
  }
}

Auth.propTypes = {
  intl: intlShape.isRequired,
  email: PropTypes.string,
  // TODO: this could probably be avoided using composition for components that
  // should share the behaviours of this class
  onFormSubmit: PropTypes.func,
  onEmailUpdate: PropTypes.func,
  onInvalidOtp: PropTypes.func,
  onResendOtp: PropTypes.func,
  registerLoginOEUserWithSocialMedia: PropTypes.func,
  context: PropTypes.string,
  history: PropTypes.object,
  getOEUserData: PropTypes.func,
  onOtpRequired: PropTypes.func,
  onSocialMediaError: PropTypes.func,
  error: PropTypes.any,
};

Auth.defaultProps = {
  onFormSubmit: () => { },
  onEmailUpdate: () => { },
  onInvalidOtp: () => { },
  onResendOtp: () => { },
  registerLoginOEUserWithSocialMedia: () => { },
  email: '',
  context: null,
  history: {
    push: () => { },
  },
  getOEUserData: () => { },
  onOtpRequired: () => { },
  onSocialMediaError: () => {},
  error: null,
};

// react-intl's 'injectIntl' should be called on components
// inheriting from this class. Doing it from here breaks the render method as
// 'injectIntl' overwrites it already
export default Auth;
