import React from 'react';
import { object } from 'prop-types';

import Paragraph from 'pwm-components/components/Paragraph';

import AccountLink from './AccountLink';

const AccountName = React.memo(props => (
  <AccountLink
    {...props}
  >
    <Paragraph truncate>
      <strong>{props.data.label || props.data.domain}</strong>
    </Paragraph>
  </AccountLink>
));

AccountName.propTypes = {
  data: object.isRequired,
};

AccountName.displayName = 'AccountName';

export default AccountName;
