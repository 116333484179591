import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import BreachedWebsiteIcon from 'pwm-components/icons/BreachedWebsite';
import NoBreachedWebsiteIcon from 'pwm-components/icons/NoBreachedWebsite';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { withTooltip } from 'pwm-components/components/Tooltip';
import Loading from 'pwm-components/components/Loading';

import TooltipDependentBreachWebsiteAvira from './tooltips/TooltipDependentBreachWebsiteAvira';
import TooltipDependentBreachWebsiteHibp from './tooltips/TooltipDependentBreachWebsiteHibp';
import TooltipDependentBreachUsername from './tooltips/TooltipDependentBreachUsername';
import TooltipDependentBreachAvira from './tooltips/TooltipDependentBreachAvira';
import TooltipBreachedWebsiteAvira from './tooltips/TooltipBreachedWebsiteAvira';
import TooltipBreachedWebsiteHibp from './tooltips/TooltipBreachedWebsiteHibp';
import TooltipBreachedUserAccountAlert from './tooltips/TooltipBreachedUserAccountAlert';
import TooltipBreachedUserAccountWarning from './tooltips/TooltipBreachedUserAccountWarning';
import TooltipbreachedUnknown from './tooltips/TooltipbreachedUnknown';
import TooltipBreachedPassword from './tooltips/TooltipBreachedPassword';
import intlShape from '../../lib/intlShape';

const LoadingTooltip = withTooltip(() => (<Box width="20px" position="relative"><Loading /></Box>));
// eslint-disable-next-line max-statements
const BreachedWebsite = ({
  data,
  ignored,
  intl,
  isCalculating,
}) => {
  let SecurityStatusTooltip = '';
  let otherDomain = '';
  let date = '';

  const IconWithTooltip = useMemo(() => {
    if (data.breached) {
      return withTooltip(BreachedWebsiteIcon);
    }
    if (data.fakeAccount && data.breachedAlert) {
      return withTooltip(BreachedWebsiteIcon);
    }
    return withTooltip(NoBreachedWebsiteIcon);
  }, [data]);

  const breachedFlag = data.breached || data.breachedAlert || data.breachedWarning;
  if (isCalculating() && !breachedFlag) {
    return (
      <LoadingTooltip
        placement='relative'
        bigPadding
        tooltipContent={(
          <Box
            textAlign="left"
          >
            <Paragraph>
              <strong>
                <FormattedMessage
                  id="dashboard.securityStatus.tooltip.calculating.title"
                  defaultMessage="Calculating.."
                />
              </strong>
            </Paragraph>
            <Paragraph mt="xs">
              <FormattedMessage
                id='dashboard.securityStatus.tooltip.calculating.body'
                defaultMessage='Checking for Account breaches...'
              />
            </Paragraph>
          </Box>
        )}
      />
    );
  }
  const getVariant = () => {
    if (ignored) {
      return 'disabled';
    }
    if (data.breachedWarning) {
      return 'warning';
    }
    if (data.breachedAlert) {
      return 'alert';
    }
    return 'success';
  };

  if (data.dependentBreachWarning) {
    date = data.dependentBreachDate;
    otherDomain = data.dependentBreachDomain;
    switch (data.dependentBreachType) {
      case 'aviraBreachedWebsite':
        SecurityStatusTooltip = TooltipDependentBreachWebsiteAvira;
        break;
      case 'hibpBreachedWebsite':
        SecurityStatusTooltip = TooltipDependentBreachWebsiteHibp;
        break;
      case 'hibpBreachedUsername':
        SecurityStatusTooltip = TooltipDependentBreachUsername;
        break;
      case 'auc':
        SecurityStatusTooltip = TooltipDependentBreachAvira;
        break;
      default:
        break;
    }
  }

  if (data.aviraBreachedWebsite) {
    SecurityStatusTooltip = TooltipBreachedWebsiteAvira;
    date = data.aviraBreachedWebsiteDate;
  }

  if (data.hibpBreachedWebsite) {
    SecurityStatusTooltip = TooltipBreachedWebsiteHibp;
    date = data.hibpBreachedWebsiteDate;
  }

  if (data.hibpBreachedAccountWarning) {
    SecurityStatusTooltip = TooltipBreachedUserAccountWarning;
    date = data.hibpBreachedAccountDate;
  }

  if (data.hibpBreachedAccountAlert) {
    SecurityStatusTooltip = data.fakeAccount
      ? TooltipbreachedUnknown
      : TooltipBreachedUserAccountAlert;
    date = data.hibpBreachedAccountDate;
  }

  if (data.hibpBreachedPassword) {
    SecurityStatusTooltip = TooltipBreachedPassword;
  }

  return (
    <IconWithTooltip
      noDelay
      bigPadding
      variant={getVariant()}
      tooltipContent={(
        <Box
          textAlign="left"
        >
          {
            SecurityStatusTooltip
              ? (
                <SecurityStatusTooltip
                  domain={data.domain}
                  date={date ? intl.formatDate(date) : ''}
                  otherDomain={otherDomain}
                  username={data.username}
                />
              ) : (
                <>
                  <Paragraph>
                    <strong>
                      <FormattedMessage
                        id="dashboard.securityStatus.tooltip.header.noBreach"
                        defaultMessage="Great!"
                      />
                    </strong>
                  </Paragraph>
                  <Paragraph mt="xs">
                    <FormattedMessage
                      id="dashboard.securityStatus.tooltip.body.noBreach"
                      defaultMessage="No breaches detected for this password!"
                    />
                  </Paragraph>
                </>
              )
          }
        </Box>
      )}
    />
  );
};

BreachedWebsite.propTypes = {
  data: PropTypes.object.isRequired,
  ignored: PropTypes.bool,
  intl: intlShape.isRequired,
  isCalculating: PropTypes.func.isRequired,
};

BreachedWebsite.defaultProps = {
  ignored: false,
};

export default injectIntl(BreachedWebsite);
