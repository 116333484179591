import {
  TRACKING_IDENTIFY,
  TRACKING_SET_LOCAL_SALT,
  TRACKING_SET_OE_IDS,
} from './TrackingActionTypes';

export type State = Readonly<{
  distinctId: string | null;
  localSalt: string | null;
  oeUserId: string | null;
  oeDeviceId: string | null;
}>;

interface Identify {
  type: typeof TRACKING_IDENTIFY;
  payload: string | null;
}

interface SetLocalSalt {
  type: typeof TRACKING_SET_LOCAL_SALT;
  payload: string | null;
}

interface SetOEIds {
  type: typeof TRACKING_SET_OE_IDS;
  payload: {
    oeUserId: string | null;
    oeDeviceId: string | null;
  };
}

export type Action = Identify | SetLocalSalt | SetOEIds;

function getDefaultState(): State {
  return {
    distinctId: null,
    localSalt: null,
    oeUserId: null,
    oeDeviceId: null,
  };
}

export default function trackingReducer(state = getDefaultState(), action: Action): State {
  switch (action.type) {
    case TRACKING_IDENTIFY:
      return {
        ...state,
        distinctId: action.payload,
      };
    case TRACKING_SET_LOCAL_SALT:
      return {
        ...state,
        localSalt: action.payload,
      };
    case TRACKING_SET_OE_IDS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
