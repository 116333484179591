import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setNotesSortBy,
  setScrollPosition,
  syncDeleteNote,
} from '../NoteActions';
import NotesPage from './NotesPage';
import { isDataUsageDisallowed } from '../../selectors';
import { syncDeleteFile } from '../../files/FileActions';
import {
  showExtBanner,
  showFilesBanner,
  showGetMobileBanner,
  canDisplayBanners,
} from '../../user/selectors';
import { getDevices } from '../../connectedDevices/ConnectedDevicesActions';
import { setDevicesLoaded } from '../../mobile/MobileActions';
import { noteTagHeaderSelector, notesFileListSelector } from '../../lib/MyDataSelectors';

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  isUnregisteredMode: state.dashboard.isUnregisteredMode,
  notes: notesFileListSelector(state),
  preferences: state.preferences,
  notePreferences: state.notePreferences,
  canDisplayBanners: canDisplayBanners(state),
  showGetMobileBanner: showGetMobileBanner(state),
  showExtBanner: showExtBanner(state),
  showFilesBanner: showFilesBanner(state),
  disallowDataUsage: isDataUsageDisallowed(state),
  tags: noteTagHeaderSelector(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onSortByChange: setNotesSortBy,
  onDeleteClick: syncDeleteNote,
  onDeleteFile: syncDeleteFile,
  getDevices,
  setDevicesLoaded,
  setScrollPosition,
}, dispatch);

const NotesContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NotesPage);

export default NotesContainer;
