import React, { useState, ReactNode, useEffect } from 'react';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';
import TextButton from 'pwm-components/components/TextButton';
import CloseIcon from 'pwm-components/icons/Close';
import config from '../../config';

type Props = {
  message: null | string | JSX.Element | ReactNode;
  context?: null | string | JSX.Element | ReactNode;
  error?: Error;
  buttonLabel?: string;
  onDismiss?: () => void;
  onClick?: () => void;
}

const Error: React.FC<Props> = ({
  message,
  context,
  buttonLabel,
  onDismiss,
  onClick,
  error,
}) => {
  const [showDetails, setShowDetails] = useState(false);

  useEffect(() => {
    if (config.localBuild) {
      console.error(error);
    }
  }, [error]);

  return (
    <Box
      bg="background"
      display="flex"
      height="100%"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {
        onDismiss && (
          <Box
            position="absolute"
            top="0"
            right="0"
            p="m"
          >
            <TextButton onClick={onDismiss}>
              <CloseIcon />
            </TextButton>
          </Box>
        )
      }
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-around"
      >
        <Paragraph size="h2" mb="m">
          <FormattedMessage
            id="dashboard.error.message"
            defaultMessage="Oops! Something went wrong"
          />
        </Paragraph>

        <Paragraph mb="m">
          { message }
        </Paragraph>
        { onClick && (
          <Button
            size="small"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onClick();
            }}
          >
            {buttonLabel}
          </Button>
        )}
        { (context || error != null) && (
          <TextButton onClick={() => setShowDetails(!showDetails)} mt="m">
            <FormattedMessage
              id="dashboard.error.showDetails"
              defaultMessage="Show Details"
            />
          </TextButton>
        )}
        { showDetails && (
          <Paragraph mt="l">
            <span>Context: </span>
            {context}
            <br />
            { error && (
              <>
                <pre>
                  {error.toString()}
                </pre>
              </>
            )}
          </Paragraph>
        )}
      </Box>
    </Box>
  );
};

export default Error;
