import { Entity as AccountEntity } from '@avira-pwm/redux/accounts';
import { Entity as NoteEntity } from '@avira-pwm/redux/notes';
import {
  IMPORTER_SELECT_ITEM,
  IMPORTER_SELECT_ITEMS,
  IMPORTER_DESELECT_ITEMS,
  IMPORTER_DESELECT_ITEM,
  IMPORTER_LOAD_ITEMS,
  IMPORTER_CLEAR_ITEMS,
  IMPORTER_SET_MAPPING,
  IMPORTER_SET_BROWSER_DATA,
  IMPORTER_FILL_ACCOUNTS_INFO,
  IMPORTER_FINAL_STATUS_INFO,
  IMPORTER_RESTORE_DEFAULT_DESELECTED,
} from './ImporterActionTypes';
import { defaultImporterMapping } from './components/ImporterAccountMapping';
import { Types as ItemType } from './components/ImportItemTypes';

export type Mapping = {
  name: number | null;
  website: number | null;
  username: number | null;
  secondaryUsername: number | null;
  password: number | null;
  notes: number[];
}

export type RawData = string[];

export type EntityData<T> = Partial<Omit<T, 'id'>>

export type ItemData<T> = {
  id: string;
  data: EntityData<T>;
}

export type State = {
  mapping: Mapping;
  deselected: number[];
  rawData: RawData[];
  items: ItemData<AccountEntity & NoteEntity>[];
  itemsType: ItemType[];
  browserData: {};
  errors: Array<null|{}>;
  finalStatus: {};
}

const getInitialState = (): State => ({
  mapping: defaultImporterMapping,
  deselected: [],
  rawData: [],
  items: [],
  itemsType: [],
  browserData: {},
  errors: [],
  finalStatus: {},
});


// eslint-disable-next-line complexity
export default function importerReducer(state = getInitialState(), action: any): State {
  switch (action.type) {
    case IMPORTER_SET_MAPPING:
      return {
        ...state,
        mapping: action.value,
      };
    case IMPORTER_SELECT_ITEM:
      return {
        ...state,
        deselected: state.deselected.filter(i => i !== action.value),
      };
    case IMPORTER_SELECT_ITEMS:
      return {
        ...state,
        deselected: state.deselected.filter(i => !action.value[i]),
      };
    case IMPORTER_DESELECT_ITEM:
      return {
        ...state,
        deselected: [...state.deselected, action.value],
      };
    case IMPORTER_DESELECT_ITEMS:
      return {
        ...state,
        deselected: Array.from(new Set(state.deselected.concat(action.value))),
      };
    case IMPORTER_RESTORE_DEFAULT_DESELECTED:
      return {
        ...state,
        deselected: action.value,
      };
    case IMPORTER_LOAD_ITEMS:
      return {
        ...getInitialState(),
        rawData: [...action.value],
      };
    case IMPORTER_FILL_ACCOUNTS_INFO:
      return {
        ...state,
        items: action.value.items,
        errors: action.value.errors,
        itemsType: action.value.itemsType,
      };
    case IMPORTER_SET_BROWSER_DATA:
      return {
        ...state,
        browserData: action.value,
      };
    case IMPORTER_FINAL_STATUS_INFO:
      return {
        ...state,
        finalStatus: action.value,
      };
    case IMPORTER_CLEAR_ITEMS:
      return getInitialState();
    default:
      return state;
  }
}
