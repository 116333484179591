import React, { ReactElement } from 'react';
// import { FormattedMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import confirmDeleteIcon from '../img/icon-delete-big.png';

type Props = {
  confirmLabel: string;
  cancelLabel: string;
  loading: boolean;
  show: boolean;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDeleteDialog = (props: Props): ReactElement => {
  const {
    message,
  } = props;

  return (
    <ConfirmationDialog
      {...props}
      cancelButtonType='secondary'
      confirmButtonType='alert'
    >
      <Box mb="l" align-self="center">
        <img src={confirmDeleteIcon} alt="Delete Item" />
      </Box>
      <Box mb="l">
        {message}
      </Box>
    </ConfirmationDialog>
  );
};

export default ConfirmDeleteDialog;
