/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/destructuring-assignment, complexity */
import React from 'react';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { FormattedMessage } from 'react-intl';
import ms from 'ms';
import NotificationServer from '@avira-pwm/notification/NotificationServer';
import {
  checkIfUserHasLocalData,
  connectCloudSyncAdapter,
  connectNotificationServer,
  connectSync,
  disconnectCloudSyncAdapter,
  disconnectNotificationServer,
  disconnectSync,
  triggerSync,
  connectExtensionAdapter,
  disconnectExtensionAdapter,
  initializeSync,
  connectAsyncStorageAdapter,
  configureSyncModelsToMatchExtension,
  setError as setErrorAction,
} from '../DashboardActions';
import { getOEUserData } from '../../oe/OEActions';
import {
  getUserData, setSyncStore, updateEncryptedKey,
} from '../../user/UserActions';
import Loading from '../../app/components/Loading';
import config from '../../config';
import { trackTimeout } from '../../tracking/TrackingActions';

import { logoutUser } from '../../authentication/AuthenticationActions';
import { RootState } from '../../app/store';

const LOGOUT_CONTEXT = 'apiConnector';
const LOGOUT_CAUSE = 'logout notification';

const LOCK_CONTEXT = 'apiConnector';
const LOCK_CAUSE = 'lock notification';

function promiseTimeout<V extends any, D extends any>(
  promise: Promise<V>,
  timeout: number,
  timeoutVal: D,
): Promise<V | D> {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      if (typeof timeoutVal === 'undefined') {
        reject();
      } else {
        resolve(timeoutVal);
      }
    }, timeout);

    promise.then((...args) => {
      clearTimeout(timeoutId);
      resolve(...args);
    });
  });
}

type Props = {
  children: React.ReactNode;
  checkIfUserHasLocalData: (...args: any[]) => any;
  connectCloudSyncAdapter: (...args: any[]) => any;
  connectNotificationServer: () => Promise<NotificationServer>;
  connectAsyncStorageAdapter: (...args: any[]) => any;
  configureSyncModelsToMatchExtension: (...args: any[]) => any;
  connectSync: (...args: any[]) => any;
  disconnectCloudSyncAdapter: (...args: any[]) => any;
  disconnectNotificationServer: (...args: any[]) => any;
  disconnectSync: (...args: any[]) => any;
  onTimeout: (...args: any[]) => any;
  getOEUserData: (...args: any[]) => any;
  getUserData: (...args: any[]) => any;
  triggerSync: (...args: any[]) => any;
  connectExtensionAdapter: (...args: any[]) => any;
  disconnectExtensionAdapter: (...args: any[]) => any;
  user: RootState['user'];
  userData: RootState['userData'];
  dashboard: RootState['dashboard'];
  nlokData: RootState['nlokData'];
  logoutUser: (...args: any[]) => any;
  updateEncryptedKey: (...args: any[]) => any;
  setSyncStore: (...args: any[]) => any;
  initializeSync: (...args: any[]) => any;
  setError: (...args: any[]) => any;
};

type State = {
  loaded: boolean;
  connected: boolean;
};

class ApiConnector extends React.Component<Props, State> {
  private connectionPromise: Promise<any> | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      loaded: false,
      connected: false,
    };

    this.onLogoutNotification = this.onLogoutNotification.bind(this);
    this.onLockNotification = this.onLockNotification.bind(this);
  }

  public async UNSAFE_componentWillMount(): Promise<void> {
    const { dashboard: { isUnregisteredMode } } = this.props;
    if (!isUnregisteredMode) {
      await Promise.all([
        this.props.getOEUserData(), // TODO: check if it's needed here
        this.props.getUserData(),
      ]);
    }

    let userHasData = false;

    try {
      userHasData = await this.props.checkIfUserHasLocalData();
    } catch (error) {
      this.setError(error);
      throw error;
    }

    this.setState({ connected: true }, async () => {
      const { props } = this;

      try {
        if (isUnregisteredMode) {
          await this.setAdaptersState(props);
          await this.props.initializeSync();
          this.setState({ loaded: true });
        } else {
          const adapterPromise = this.setAdaptersState(props)
            .catch((error) => {
              this.setError(error);
              throw error;
            });

          if (!userHasData) {
            await adapterPromise;
          }

          this.connectionPromise = this.connectApi();
          this.connectionPromise.then(async () => {
            this.connectionPromise = null;
            this.setState({ loaded: true });
          }).catch((error) => {
            this.setError(error, props);
            throw error;
          });
        }
      } catch (error) {
        this.setError(error, props);
        throw error;
      }
    });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    if (!this.state.loaded) {
      return;
    }

    if (
      nextProps.user.key !== this.props.user.key
      || nextProps.user.authToken !== this.props.user.authToken
    ) {
      this.disconnectApi();
    }

    if (nextProps.user.key !== this.props.user.key && nextProps.user.key) {
      if (!this.state.connected) {
        this.connectApi();
      }
    }

    if (!this.relevantStateChange(nextProps)) {
      return;
    }

    this.setAdaptersState(nextProps)
      .catch((error) => {
        this.setError(error, nextProps);
        throw error;
      });
  }

  public componentWillUnmount(): void {
    if (this.connectionPromise) {
      this.connectionPromise.finally(() => {
        this.disconnectApi();
      });
    } else {
      this.disconnectApi();
    }
  }

  private onLogoutNotification(): void {
    this.props.logoutUser(LOGOUT_CONTEXT, LOGOUT_CAUSE);
  }

  private onLockNotification(): void {
    this.props.logoutUser(LOCK_CONTEXT, LOCK_CAUSE);
  }

  private setError(error: any, currentProps?: Props): void {
    const { setError } = this.props;
    setError({ context: 'ApiConnector', error });
    Sentry.withScope((scope) => {
      scope.setExtras({
        context: 'ApiConnector',
        vaultState: currentProps?.nlokData.vaultState?.additionalInfo,
      });
      Sentry.captureException(error);
    });
  }

  private async setAdaptersState(props: Props): Promise<void> {
    const { dashboard, userData } = props;

    const isExtensionDependant = dashboard.isUnregisteredMode
      || userData.sync_store_manager !== config.sync.manager;

    const shouldBypassSyncModels = !dashboard.extensionCompatible && isExtensionDependant;

    const shouldConnectExtensionAdapter = dashboard.extensionConnected
      && dashboard.extensionCompatible;

    const shouldConnectSyncAdapters = userData.sync_store_manager === config.sync.manager
      && !shouldConnectExtensionAdapter;

    if (shouldConnectExtensionAdapter) {
      if (!dashboard.extensionAdapterConnected) {
        await this.props.connectExtensionAdapter();
      }
    } else {
      await this.props.disconnectExtensionAdapter();
    }

    if (shouldConnectSyncAdapters) {
      if (!dashboard.cloudSyncAdapterConnected) {
        await this.props.connectCloudSyncAdapter();
      }
    } else {
      await this.props.disconnectCloudSyncAdapter();
    }

    if (config.spotlight && dashboard.isUnregisteredMode) {
      await this.props.connectAsyncStorageAdapter();
    }

    if (shouldBypassSyncModels) {
      this.props.configureSyncModelsToMatchExtension();
    }
  }

  private relevantStateChange(nextProps: Props): boolean {
    if (this.props.dashboard.extensionCompatible !== nextProps.dashboard.extensionCompatible) {
      return true;
    }

    if (this.props.userData.sync_store_manager !== nextProps.userData.sync_store_manager) {
      return true;
    }

    if (this.props.dashboard.isUnregisteredMode !== nextProps.dashboard.isUnregisteredMode) {
      return true;
    }

    return false;
  }

  private async connectApi(): Promise<void> {
    const notificationServer = config.localBuild
      ? null
      : await promiseTimeout(this.props.connectNotificationServer(), ms('5s'), null);
    await this.props.connectSync();

    if (!notificationServer) {
      return;
    }

    notificationServer.on('sync', this.props.triggerSync);
    notificationServer.on('syncChange', async (status) => {
      this.props.setSyncStore(status === 'enabled' ? config.sync.manager : status);
    });
    notificationServer.on('logout', this.onLogoutNotification);
    notificationServer.on('lock', this.onLockNotification);
    notificationServer.on('keyChanged', this.props.updateEncryptedKey);
  }

  private disconnectApi(): void {
    this.props.disconnectNotificationServer();
    this.props.disconnectSync();
  }

  public render(): React.ReactNode {
    if (this.state.loaded) {
      return this.props.children;
    }

    return (
      <Loading
        onRetry={() => window.location.reload()}
        onTimeout={() => this.props.onTimeout('ApiConnector')}
      >
        <FormattedMessage
          id="dashboard.accounts.loading"
        />
      </Loading>
    );
  }
}

const mapStateToProps = ({
  user,
  userData,
  dashboard,
  nlokData,
}: RootState): Partial<Props> => ({
  user,
  userData,
  dashboard,
  nlokData,
});

const mapDispatchToProps = (dispatch: Dispatch): any => bindActionCreators({
  checkIfUserHasLocalData,
  connectCloudSyncAdapter,
  connectNotificationServer,
  configureSyncModelsToMatchExtension,
  connectSync,
  connectAsyncStorageAdapter,
  disconnectCloudSyncAdapter,
  disconnectNotificationServer,
  disconnectSync,
  onTimeout: trackTimeout,
  getOEUserData,
  getUserData,
  setSyncStore,
  triggerSync,
  connectExtensionAdapter,
  disconnectExtensionAdapter,
  logoutUser,
  updateEncryptedKey,
  initializeSync,
  setError: setErrorAction,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ApiConnector);
