import Dexie from 'dexie';
import { FileStatusEnum } from './helpers/FileStatusHelper';

type File = {
  id: string;
  status?: FileStatusEnum;
  [key: string]: string | Uint8Array | FileStatusEnum | undefined;
}
export default class FileDatabase extends Dexie {
  private files: Dexie.Table<File, string>;

  private enabled = true;

  constructor(databaseName: string) {
    super(databaseName);
    this.version(1).stores({
      files: 'id',
    });
    this.files = this.table('files');
  }

  private async wrapCall<R>(fn: () => Promise<R>, errorValue: R): Promise<R> {
    try {
      if (!this.enabled || !this.isOpen()) {
        return errorValue;
      }

      const returnValue = await fn();
      return returnValue;
    } catch (e) {
      this.enabled = false;
      return errorValue;
    }
  }

  public async create(id: string): Promise<void> {
    return this.wrapCall(
      async () => { await this.files.put({ id, error: undefined }); },
      undefined,
    );
  }

  public async get(id: string, name: string): Promise<Uint8Array | undefined> {
    return this.wrapCall(
      async () => {
        const file = await this.files.get(id);
        return file ? file[name] as Uint8Array : undefined;
      },
      undefined,
    );
  }

  public async save(id: string, name: string, data: Uint8Array): Promise<void> {
    return this.wrapCall(
      async () => {
        const file = await this.files.get(id);
        if (file) {
          await this.files.update(id, { [name]: data, error: undefined });
        } else {
          await this.files.put({ id, [name]: data, error: undefined });
        }
      },
      undefined,
    );
  }

  public async remove(id: string): Promise<void> {
    return this.wrapCall(
      async () => {
        await this.files.delete(id);
      },
      undefined,
    );
  }

  public async getStatus(id: string): Promise<FileStatusEnum | null> {
    return this.wrapCall(
      async () => {
        const file = await this.files.get(id);
        return file && file.status ? file.status : null;
      },
      null,
    );
  }

  public async setStatus(id: string, status: FileStatusEnum | null): Promise<void> {
    return this.wrapCall(
      async () => {
        const file = await this.files.get(id);
        if (file) {
          await this.files.update(id, { status });
        } else {
          await this.files.put({ id, status: status || undefined });
        }
      },
      undefined,
    );
  }

  public async getAllStatus(): Promise<Array<Pick<File, 'id' | 'status'> | null>> {
    return this.wrapCall(
      async () => (await this.files.toArray()).map(f => ({
        id: f.id,
        status: f.status,
      })),
      [],
    );
  }
}
