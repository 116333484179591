import merge from 'lodash/merge';

type PartialPropertyCheck<T, TExpected> =
  Exclude<keyof T, keyof TExpected> extends never
    ? { [K in keyof T]: T[K] extends object ? Partial<T[K]> : T[K] }
    : { [K in Exclude<keyof T, keyof TExpected | 'environment' | 'extends'>]: 'Property required in common config' };

export function createConfigVerifier<C extends object>(_commonConfig: C) {
  return function verify<EC extends object>(envConfig: EC & PartialPropertyCheck<EC, C>): EC {
    return envConfig;
  };
}

export default function configResolver<
  C extends object,
  EC extends object,
>(
  commonConfig: C,
  envConfigs: {
    [key: string]: EC & PartialPropertyCheck<EC, C> & {
      environment?: string;
      extends?: string | string[];
    };
  },
  environment: keyof typeof envConfigs,
): C & { environment?: string } {
  type ENVS = keyof typeof envConfigs;

  const { extends: extendsProp, ...envConfig } = envConfigs[environment];
  let extension = {};

  if (typeof extendsProp !== 'undefined') {
    const extendsList = Array.isArray(extendsProp)
      ? extendsProp as Array<keyof ENVS>
      : [extendsProp];

    for (const exProp of extendsList) {
      if (exProp === environment) {
        throw new Error('Environment can\'t extend itself');
      }

      if (!(exProp in envConfigs)) {
        throw new Error(`Unknown environment "${exProp}"`);
      }

      const { extends: extensionExtends, ...extensionConfig } = envConfigs[exProp as keyof ENVS];
      extension = {
        ...extension,
        ...extensionConfig,
      };
    }
  }

  return merge(
    {},
    commonConfig,
    extension,
    envConfig,
  );
}
