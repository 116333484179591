import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

const propTypes = {
  date: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof propTypes>;

const TooltipBreachedUserAccountWarning: React.FC<Props> = ({ date }) => (
  <Box>
    <Paragraph>
      <strong>
        <FormattedMessage id="dashboard.securityStatus.tooltip.header.breachedUserAccount" />
      </strong>
    </Paragraph>

    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage
        id="dashboard.securityStatus.hibp.breachedAccountFound"
        values={{ date }}
      />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedMessage
        id="dashboard.securityStatus.hibp.breachedAccountWarning"
        values={{ date }}
      />
    </Paragraph>
  </Box>
);

export default TooltipBreachedUserAccountWarning;
