import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import PasswordTooltip from 'pwm-components/components/PasswordTooltip';
import Paragraph from 'pwm-components/components/Paragraph';
import TextButton from 'pwm-components/components/TextButton';
import { withTooltip } from 'pwm-components/components/Tooltip';

import CopyIcon from 'pwm-components/icons/Copy';
import EyeIcon from 'pwm-components/icons/Eye';
import EyeCrossedIcon from 'pwm-components/icons/EyeCrossed';
import { useDispatch } from 'react-redux';

import { Type as AccountT } from '@avira-pwm/sync/ModelSpecifics/Account';
import { Type as AccountHistoryT } from '@avira-pwm/sync/ModelSpecifics/AccountHistory';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';

import clipboardCopy from '../../lib/CopyToClipboard';
import PasswordText from '../../componentLib/PasswordText';
import { trackEvent } from '../../tracking/TrackingActions';
import { MP_CREDENTIALS_HISTORY_USED } from '../../tracking/MixpanelEvents';

const CopyIconWithTooltip = withTooltip(CopyIcon);

const EyeIconTransition = styled(EyeIcon)`
  transition: fill 200ms;
`;

type AccountHistoryType = Flattened<AccountHistoryT>;
type AccountType = Flattened<AccountT>;

type PasswordHistoryItemProps = {
  id?: string;
  account: AccountType;
  historyItem?: AccountHistoryType;
  revealPassword?: boolean;
  onReavealPassword: (accountId: string) => void;
  onOutsideClick: () => void;
}

const PasswordHistoryItem: React.FC<PasswordHistoryItemProps> = ({
  id,
  account,
  historyItem,
  revealPassword,
  onReavealPassword,
  onOutsideClick,
}) => {
  const mainData = historyItem ?? account;
  const dispatch = useDispatch();

  const trackCredentialsUsed = useCallback((action: string) => {
    dispatch(trackEvent(MP_CREDENTIALS_HISTORY_USED, {
      context: 'history',
      action,
      domain: account.domain,
    }));
  }, [dispatch, account.domain]);

  const onReavealPasswordClicked = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    onReavealPassword(mainData.id);
    trackCredentialsUsed('revealPassword');
  }, [mainData.id, onReavealPassword, trackCredentialsUsed]);

  const onCopyClick = useCallback((e) => {
    e.stopPropagation();
    clipboardCopy(mainData.password || '');
    trackCredentialsUsed('copyPassword');
  }, [mainData.password, trackCredentialsUsed]);

  return (
    <Box id={id} display="flex" ml="s" flex="0" alignItems="center">
      <Paragraph className="a-password-history-item-username" style={{ flex: '1 0 10em' }} truncate>
        {mainData.email || mainData.username}
      </Paragraph>

      <Box display="flex" alignItems="center" ml="s">
        <Box mr="xxs" style={{ flex: '0 0 auto' }}>
          <PasswordText
            size="large"
          />
        </Box>

        <Box>
          <TextButton
            className={revealPassword ? 'a-hide-password-button' : 'a-show-password-button'}
            onClick={onReavealPasswordClicked}
          >
            {
              revealPassword
                ? (
                  <EyeCrossedIcon variant="linkAlternate" />
                ) : (
                  <EyeIconTransition variant="linkAlternate" />
                )
            }
          </TextButton>
          <PasswordTooltip
            className={revealPassword ? 'a-password-history-item-password' : ''}
            align="center"
            tooltipAlign="right"
            password={mainData.password || ''}
            visible={revealPassword}
            onOutsideClick={onOutsideClick}
            big
          />
        </Box>
      </Box>

      <Box ml="s" flex="0 0 auto">
        <TextButton
          onClick={onCopyClick}
        >
          <CopyIconWithTooltip
            variant="linkAlternate"
            tooltipContent={(
              <FormattedMessage id="dashboard.account.details.copyAction" />
            )}
            feedbackContent={(
              <FormattedMessage id="dashboard.accounts.copyActionFeedback" />
            )}
          />
        </TextButton>
      </Box>
    </Box>
  );
};

export default PasswordHistoryItem;
