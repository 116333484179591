import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import Text from 'pwm-components/components/Text';

const PleaseRegisterMsg = () => (
  <Text variant="alert">
    <FormattedMessage
      id="dashboard.pleaseEnable"
      defaultMessage="Please register to enable this option"
    />
    {' '}
    (
    <Link
      to="/why-register?dashboardContext=settings-message"
      target="_blank"
    >
      <FormattedMessage id="dashboard.whyRegister" defaultMessage="Why Register?" />
    </Link>
    )
  </Text>
);

export default PleaseRegisterMsg;
