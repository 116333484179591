import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import without from 'lodash/without';
import AutoSuggestionField from './AutoSuggestionField';
import { getSuggestedTags } from './selectors';

const mapStateToProps = (state, ownProps) => ({
  suggestionList: without(getSuggestedTags(state), ...ownProps.ignoreList)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

const TagsAutoSuggestContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutoSuggestionField);

export default TagsAutoSuggestContainer;
