import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
} from 'react';
import {
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';

import {
  WrappedComponentProps,
  injectIntl,
} from 'react-intl';

import Box from 'pwm-components/components/Box';
import Loading from 'pwm-components/components/Loading';

import PasswordManagerChangeUser from './PasswordManagerChangeUser';

import { State as SpotlightState } from '../SpotlightReducer';
import MessengerContext from '../../componentLib/MessengerContext';

import debug from '../../debug';

import {
  connectToSpotlight,
  getSpotlightUser,
  changeToSpotlightUser,
} from '../SpotlightActions';

type State = {
  oe: any;
  userData: any;
  spotlight: SpotlightState;
  render: JSX.Element;
};

type Props = WrappedComponentProps & {};

const log = debug.extend('GetSpotlightData');

const GetSpotlightData: React.FC<Props> = () => {
  const dashboardMessenger = useContext(MessengerContext);
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [oeUserMismatch, setOeUserMismatch] = useState(false);
  const store = useStore();
  const { oe, userData } = store.getState();

  const spotlightData = useSelector(
    (state: State) => state.spotlight.spotlightUserData,
  );

  const spotlightConnected = useSelector(
    (state: State) => state.spotlight.spotlightConnected,
  );

  const connectSpotlightUser = useCallback((): void => {
    log('connectSpotlightUser - enter');
    if (dashboardMessenger && dashboardMessenger.isConnected()) {
      log('connectSpotlightUser - triggering spotlight connection');
      const { oe: { id: oeId }, userData: { id: userId }, user: { key } } = store.getState();
      dashboardMessenger.send('dashboard:extension:connectSpotlightUser', {
        id: oeId,
        userId,
      }, (err, connected) => {
        if (err) {
          log('error', err);
          return;
        }

        log(`connectSpotlightUser - received answer: ${connected}`);

        if (connected) {
          dashboardMessenger.send('dashboard:extension:focusSpotlight');

          if (key == null) {
            history.push('/master-password');
          } else {
            history.push('/mydata/passwords');
          }
        }
      });
    }
  }, [dashboardMessenger, history, store]);

  // connects to Spotlight
  useEffect(() => {
    if (dashboardMessenger!.isConnected()) {
      dispatch(connectToSpotlight());
    }
  }, [dispatch, dashboardMessenger]);

  // gets the Spotlight user data
  useEffect(() => {
    if (spotlightConnected) {
      log('getSpotlightUser');
      dispatch(getSpotlightUser());
    }
  }, [dispatch, spotlightConnected]);

  // checks and connect users to Spotlight
  useEffect(() => {
    const spotlightOeId = spotlightData.id;
    const oeId = oe.id;
    if (spotlightOeId && oeId && oeId === spotlightOeId) {
      log('connectSpotlightUser');
      connectSpotlightUser();
    } else {
      log('setOeUserMismatch: true');
      setOeUserMismatch(true);
    }
    setLoading(false);
  }, [spotlightData, oe, userData, history, dashboardMessenger, connectSpotlightUser]);

  function dontConnectSpotlight(): void {
    if (dashboardMessenger!.isConnected()) {
      dashboardMessenger!.send('dashboard:extension:spotlightConnectClosed');
    }
    history.push('/login');
  }

  async function changeCurrentDashboardUser(): Promise<void> {
    await dispatch(changeToSpotlightUser());
    connectSpotlightUser();
  }

  if (loading) {
    return (
      <Box height="100%" position="relative">
        <Loading />
      </Box>
    );
  }

  if (oeUserMismatch) {
    return (
      <PasswordManagerChangeUser
        pwmUserEmail={oe.email}
        spotlightUserEmail={spotlightData.email}
        onConnectClick={changeCurrentDashboardUser}
        onDontConnectSpotlight={dontConnectSpotlight}
      />
    );
  }

  return (
    <Redirect to={{ pathname: '/page-not-found' }} />
  );
};

export default injectIntl(GetSpotlightData);
