import * as Sentry from '@sentry/browser';
import { ThunkAction } from 'redux-thunk';
import { AnyAction, Dispatch, Action } from 'redux';
import mean from 'lodash/mean';

import * as fileActions from '@avira-pwm/redux/files';
import { ActionWrapper } from '@avira-pwm/redux/createEntityModule';
import { SyncUtils, ModelCrypto, SyncEncryptionWrapper } from '@avira-pwm/sync';
import { generateFileKey, decryptFileKey } from '@avira-pwm/crypto-tools';
import * as fileHelpers from '@avira-pwm/sync/helpers/file';
import { TrackingActions, MixpanelEvents } from '../tracking';
import { RootState } from '../app/store';
import { storeKey, setUserData } from '../user/UserActions';
import { getRelevantUserKey } from '../user/selectors';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { getTotalFilePreviewSize } from './helpers/FileDataHelper';
import { FileStatusEnum } from './helpers/FileStatusHelper';
import { FileManagerProps } from './FileManagerContext';
import {
  generatePreviews,
  getNameAndType,
  encryptAndCacheFile,
} from './FileHelper';
import { ThunkExtraArgument } from '../app/thunk';
import { getFileConfig } from './selectors';
import { setS3FileAuthData } from '../dashboard/DashboardActions';
import { handleSyncError } from '../lib/ErrorHelper';

const { trackEvent } = TrackingActions;
const {
  MP_FILE_ADD,
  MP_FILE_CREATED,
  MP_FILE_DELETED,
  MP_FILE_VIEWED,
} = MixpanelEvents;

export const SINGLE_FILE_SIZE_LIMIT = 2.5e+7;

export const STORAGE_SIZE_LIMIT = 1e+9;

export const STORAGE_OVERFLOW_BUFFER = 20e+6;

export const syncGetFiles: ActionWrapper<typeof fileActions.getAll, {}, ThunkExtraArgument> = (
  () => async (dispatch, getState, { syncInstance }) => {
    try {
      dispatch(fileActions.getAll({ sync: syncInstance, key: getRelevantUserKey(getState()) }));
    } catch (e) {
      Sentry.captureException(e);
    }
  }
);

export const clearFiles: ActionWrapper<typeof fileActions.clear, {}, ThunkExtraArgument> = (
  () => (dispatch) => {
    dispatch(fileActions.clear());
  }
);

type SyncAddFiles = FileManagerProps & {
  file: File;
  entityID: string;
}

export const getStorageSize = (config?: FileManagerProps): ThunkAction<
Promise<{ bytesUsed: number; bytesLimit: number } | null>, RootState, any, AnyAction
> => async (_dispatch, getState, { licenseService }) => {
  const { user: { authToken }, oe: { token: oeToken } } = getState();

  try {
    const {
      bytes_used: bytesUsed,
      bytes_limit: bytesLimit,
    } = await licenseService.getStorageUsage(authToken, oeToken);

    if (config) {
      config.fileManagerActions.setFileStorage(bytesUsed, bytesLimit);
    }

    return { bytesUsed, bytesLimit };
  } catch {
    return null;
  }
};

export const syncAddFiles = (
  (id: string, {
    file,
    entityID,
    fileManager,
    fileManagerActions,
  }: SyncAddFiles): ThunkAction<
  Promise<void>,
  RootState,
  ThunkExtraArgument,
  Action<any>
  // eslint-disable-next-line complexity, max-statements
  > => async (
    dispatch, getState, { syncInstance },
  ) => {
    const state = getState() as RootState;
    const { user: { key2: fileKey } } = state;
    const userKey = getRelevantUserKey(state);
    const { fileDatabase, fileStorage } = fileManager;
    const { setFileStatus, setFileThumbnail, setFileProgress } = fileManagerActions;
    const config = getFileConfig(state);
    let { credentials } = config;
    const {
      region,
      bucket,
      folder,
      helpers,
      getId,
    } = config;

    await fileDatabase?.create(id);

    // throw error if no file key is present
    if (!fileKey) {
      await setFileStatus(id, FileStatusEnum.DecryptFailed);
      return;
    }

    // Start uploading process
    await setFileStatus(id, FileStatusEnum.Uploading);
    await setFileProgress(id, 0);
    try {
      try {
        await dispatch(
          fileActions.create(
            { sync: syncInstance, key: userKey },
            id,
            {
              ...getNameAndType(file.name),
              entityID,
            },
          ),
        );
      } catch (e) {
        handleSyncError(e, 'triggerImport');
      }

      // tracking adding files
      dispatch(trackEvent(MP_FILE_CREATED));
    } catch (e) {
      Sentry.captureException(e);
    }

    const crypto = new ModelCrypto(userKey);
    const fileEntity = (new SyncEncryptionWrapper(syncInstance, crypto)).get('File', id);

    // throw error if single file over limit
    if (file.size > SINGLE_FILE_SIZE_LIMIT) {
      await setFileStatus(id, FileStatusEnum.SizeLimit);
      return;
    }

    // generate thumbnails; this needs the correct id and path (NLOK converted or not)
    const filesToUpload = await generatePreviews(getId(id), file).catch(async () => {
      await setFileStatus(id, FileStatusEnum.UploadFailed);
      return null;
    });

    if (!filesToUpload) {
      return;
    }

    // get storage usage
    const { bytesUsed, bytesLimit } = fileStorage || {
      bytesUsed: 0, bytesLimit: STORAGE_SIZE_LIMIT,
    };

    // throw error if storage usage over limit
    const totalUploadSize = getTotalFilePreviewSize(filesToUpload);
    if (bytesUsed + totalUploadSize > (bytesLimit + STORAGE_OVERFLOW_BUFFER)) {
      setFileStatus(id, FileStatusEnum.StorageLimit);
      return;
    }


    // pipe the thumbnail to file manager
    const thumbnailFilename = Object.keys(filesToUpload).find(name => name.includes('thumb'));
    if (thumbnailFilename && setFileThumbnail) {
      setFileThumbnail(id, filesToUpload[thumbnailFilename]);
    }

    // get new credentials if expired
    if (credentials.expired) {
      await dispatch(setS3FileAuthData());
      credentials = getFileConfig(state).credentials;
    }

    const encryptor = helpers.getEncryptor(fileEntity, fileKey);

    // starts uploading
    const progress = Object.entries(filesToUpload).map(() => 0);
    const uploadQueue = Object.entries(filesToUpload)
      .sort(
        ([, aData], [, bData]) => (aData?.byteLength || 0) - (bData?.byteLength || 0),
      )
      .filter(([_, data]) => data != null)
      .map(async ([fileName, data], i) => {
        const encrypted = await encryptAndCacheFile({
          fileDatabase,
          encryptor,
          id,
          fileName,
          data,
        });

        // throw error if encryption fails
        if (encrypted == null) {
          await setFileStatus(id, FileStatusEnum.UploadFailed);
          throw new Error('unable to encrypt file');
        }

        await fileHelpers.uploadFile({
          credentials,
          region,
          bucket,
          folder,
          fileName,
        }, encrypted, (p) => {
          progress[i] = p;
          setFileProgress(id, mean(progress));
        });
      });

    // clean up on file statuses
    await Promise.all(uploadQueue).then(() => (
      setFileStatus(id, FileStatusEnum.Uploaded)
    )).catch(() => (
      setFileStatus(id, FileStatusEnum.UploadFailed)
    )).finally(async () => {
      setTimeout(async () => {
        // TODO: this should get storage from the correct place
        await dispatch(getStorageSize({ fileManager, fileManagerActions }));
      }, 5000);
    });
  }
);

export const syncDeleteFile = (
  (
    fileId: string,
    config: FileManagerProps,
  ): ThunkAction<Promise<void>, RootState, ThunkExtraArgument, Action<any>> => (
    async (
      dispatch, getState, { syncInstance },
    ) => {
      const state = getState();
      const sync = getSyncInstance(syncInstance, getRelevantUserKey(state));

      await config?.fileManager.fileDatabase?.remove(fileId);

      const {
        credentials,
        region,
        folder,
        bucket,
        helpers,
      } = getFileConfig(state);

      await Promise.all([
        null,
        'thumb',
        'mobpre',
        'fullpre',
      ].map((suffix) => {
        const fileName = helpers.getFileName({ id: fileId }, suffix);
        return fileHelpers.deleteFile({
          credentials,
          bucket,
          region,
          folder,
          fileName,
        }).catch(() => {});
      }));

      try {
        await SyncUtils.deleteData(sync, 'File', fileId);
      } catch (e) {
        handleSyncError(e, 'syncDeleteFile');
      }
      await dispatch(getStorageSize(config));
      dispatch(trackEvent(MP_FILE_DELETED));
    }
  )
);

export const createRefreshFileKey = (password: string): ThunkAction<void, {
  user: any; oe: any;
}, ThunkExtraArgument, AnyAction
> => async (dispatch, getState, { licenseService }) => {
  const { user: { key, key2, authToken }, oe: { token: oeToken } } = getState();

  if (key2 && key2.length > 0) {
    return;
  }

  // if not present, refreshes user data
  const { user } = await licenseService.getUserData(authToken, oeToken);
  dispatch(setUserData(user));

  const { key2: encKey2 } = user;
  if (!(encKey2 && encKey2.length > 0)) {
    const fileKey = generateFileKey();
    await licenseService.updateUserKeys(authToken, password, key, fileKey);
    dispatch(storeKey(key, fileKey));
  } else {
    const decFileKey = decryptFileKey(password, encKey2);
    dispatch(storeKey(key, decFileKey));
  }
};

export const trackFileAdd = () => async (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_FILE_ADD));
};

export const trackFileViewed = () => async (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_FILE_VIEWED));
};
