import * as Sentry from '@sentry/browser';
import { Dispatch, AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import * as creditCardActions from '@avira-pwm/redux/credit-cards';
import ServerDate from '@avira-pwm/helpers/ServerDate';
import { ActionWrapper } from '@avira-pwm/redux/createEntityModule';

import { getRelevantUserKey } from '../user/selectors';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { TrackingActions, MixpanelEvents } from '../tracking';
import { SortOption } from './WalletHelpers';
import * as actions from './WalletActionTypes';
import { ThunkExtraArgument } from '../app/thunk';
import { handleSyncError } from '../lib/ErrorHelper';

export const CCUsedActions: Record<string, string> = {
  autofill: 'autofill',
  cardNumber: 'copyNumber',
  cvc: 'copyCVC',
  cardPin: 'copyPIN',
  cardholderName: 'copyCardholder',
};

export type TrackingObject = {
  action: string;
  context: string;
}

const { trackEvent } = TrackingActions;

const {
  MP_CREDITCARD_ADD,
  MP_CREDITCARD_CREATED,
  MP_CREDITCARD_DELETED,
  MP_CREDITCARD_EDIT,
  MP_CREDITCARD_EDITED,
  MP_CREDITCARD_USED,
  MP_CREDENTIALS_LIST_SORTED,
} = MixpanelEvents;

const walletSortBy = (sortOption: SortOption): actions.WalletActionTypes => ({
  type: actions.UPDATE_WALLET_SORT_BY,
  value: sortOption,
});

export const clearWallet = (): actions.WalletActionTypes => ({ type: actions.CLEAR_WALLET });

export const setScrollPosition = (scrollPosition: number): actions.WalletActionTypes => ({
  type: actions.WALLET_DETAILS_SCROLLPOSITION,
  value: scrollPosition,
}
);

const ALLOWED_FILTERS = ['all', 'favorite'];

export const setWalletSortBy = (
  sortType: SortOption,
  filter: string,
): ThunkAction<void, any, ThunkExtraArgument, AnyAction> => (
  (dispatch) => {
    dispatch(trackEvent(MP_CREDENTIALS_LIST_SORTED, {
      sortedBy: sortType,
      filterBy: ALLOWED_FILTERS.includes(filter) ? filter : 'custom',
    }));
    dispatch(walletSortBy(sortType));
  }
);


export const updateCreditCard: ActionWrapper<
typeof creditCardActions.update,
{},
ThunkExtraArgument
> = (
  (id, changedCreditCardProps) => async (dispatch, getState, { syncInstance }) => {
    try {
      await dispatch(creditCardActions.update(
        { sync: syncInstance, key: getRelevantUserKey(getState()) },
        id,
        changedCreditCardProps,
      ));
    } catch (e) {
      handleSyncError(e, 'cc:updateCreditCard');
    }

    dispatch(trackEvent(MP_CREDITCARD_EDITED));
  }
);

type UpdateLastUsedAtWrapper = ActionWrapper<
typeof creditCardActions.updateLastUsedAt,
{},
ThunkExtraArgument
>;

export const updateLastUsedAt: (
  id: Parameters<UpdateLastUsedAtWrapper>[0]
) => ReturnType<UpdateLastUsedAtWrapper> = (
  id => async (dispatch, getState, { syncInstance }) => {
    const now = new ServerDate().toISOString();

    try {
      await dispatch(
        creditCardActions.updateLastUsedAt(
          { sync: syncInstance, key: getRelevantUserKey(getState()) },
          id,
          now,
        ),
      );
    } catch (e) {
      handleSyncError(e, 'cc:updateLastUsedAt');
    }
  }
);

export const updateModifiedAt: UpdateLastUsedAtWrapper = (
  (id: string, newDate: string) => async (_dispatch, getState, { syncInstance }) => {
    const updatedMetadata = {
      modifiedAt: newDate,
    };
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    try {
      await sync.updateMetadata('Note', id, updatedMetadata);
    } catch (e) {
      handleSyncError(e, 'cc:updateLastUsedAt');
    }
  }
);


export const syncDeleteCreditCard: ActionWrapper<
  typeof creditCardActions.deleteData, {}, ThunkExtraArgument
> = (
  id => async (dispatch, getState, { syncInstance }) => {
    try {
      await dispatch(
        creditCardActions.deleteData(
          { sync: syncInstance, key: getRelevantUserKey(getState()) },
          id,
        ),
      );
    } catch (e) {
      handleSyncError(e, 'cc:syncDeleteCreditCard');
    }

    dispatch(trackEvent(MP_CREDITCARD_DELETED));
  }
);

export const syncGetCreditCards: ActionWrapper<
typeof creditCardActions.getAll,
{},
ThunkExtraArgument
> = (
  () => async (dispatch, getState, { syncInstance }) => {
    try {
      dispatch(
        creditCardActions.getAll({ sync: syncInstance, key: getRelevantUserKey(getState()) }),
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  }
);

export const triggerCreditCardCreation: ActionWrapper<
typeof creditCardActions.create,
{},
ThunkExtraArgument
> = (
  (id, newCreditCardProps) => async (dispatch, getState, { syncInstance }) => {
    try {
      await dispatch(creditCardActions.create(
        { sync: syncInstance, key: getRelevantUserKey(getState()) },
        id,
        newCreditCardProps,
      ));
    } catch (e) {
      handleSyncError(e, 'cc:triggerCreditCardCreation');
    }

    dispatch(trackEvent(MP_CREDITCARD_CREATED));
  }
);

export const trackCreditCardAdd = () => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_CREDITCARD_ADD));
};

export const trackCreditCardEdit = () => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_CREDITCARD_EDIT));
};

export const trackCreditCardUsed = (
  _: string, trackingObj: TrackingObject,
) => (dispatch: Dispatch<any>) => {
  dispatch(trackEvent(MP_CREDITCARD_USED, trackingObj));
};
