import React from 'react';
import { string, func, number } from 'prop-types';
import { defaultColor, colorPalette } from '../lib/DefaultColorConfig';
import ColorButton from './ColorButton';


const ColorPalette = ({
  activeColor, className, onClick, tabIndex,
}) => (
  <div
    className={`u-outline-none ${className}`}
  >
    {
      Object.keys(colorPalette).map(color => (
        <ColorButton
          key={colorPalette[color]}
          color={colorPalette[color]}
          onClick={onClick}
          selected={colorPalette[color] === (activeColor || defaultColor)}
          tabIndex={tabIndex}
        />
      ))
    }
  </div>
);

ColorPalette.propTypes = {
  activeColor: string,
  className: string,
  onClick: func.isRequired,
  tabIndex: number,
};

ColorPalette.defaultProps = {
  activeColor: defaultColor,
  className: '',
  tabIndex: 0,
};

export default ColorPalette;
