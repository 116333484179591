import { MobileActionType, SET_LOADED_DEVICES } from './MobileActionTypes';

type State = {
  loadedDevices: boolean;
};

function getInitialState(): State {
  return {
    loadedDevices: false,
  };
}

export default function MobileReducer(state = getInitialState(), action: MobileActionType): State {
  switch (action.type) {
    case SET_LOADED_DEVICES:
      return {
        loadedDevices: true,
      };
    default:
      return state;
  }
}
