import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { setTheme } from '../../preferences';
import Loading from './Loading';

type Params = {
  theme: string;
}

const ChangeTheme: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<Params>();

  useEffect(() => {
    let theme = params.theme?.toLowerCase();
    if (theme === 'bright') {
      theme = 'light';
    }
    dispatch(setTheme(theme));
    history.goBack();
  }, [history, params, dispatch]);

  return (
    <Loading>
      <FormattedMessage id="dashboard.changingTheme" defaultMessage="Changing theme..." />
    </Loading>
  );
};

export default ChangeTheme;
