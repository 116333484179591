import React from 'react';
import { bool, func } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';
import BreachedwebsiteIcon from 'pwm-components/icons/BreachedWebsite';
import Box from 'pwm-components/components/Box';
import Banner from '../../componentLib/Banner';

class ActivateHibpBanner extends React.Component {
  constructor(props) {
    super(props);

    const { breachCheck, breachCheckBannerDismissed, overrideBannerVisibility } = this.props;

    this.state = {
      activated: false,
      hidden: false,
      // initialize banner visibility based on breach checks being activated or banner dismissed
      // show banner if breached tag is active, even if banner was previously dismissed
      bannerVisible: !(breachCheck || (breachCheckBannerDismissed && !overrideBannerVisibility)),
    };

    this.animateBanner = this.animateBanner.bind(this);
    this.onActivate = this.onActivate.bind(this);
  }

  // eslint-disable-next-line complexity
  componentDidUpdate(prevProps) {
    if (this.props.breachCheckBannerDismissed
      && this.props.breachCheckBannerDismissed !== prevProps.breachCheckBannerDismissed) {
      this.animateBanner();
      return;
    }
    if (this.props.breachCheck && this.props.breachCheck !== prevProps.breachCheck) {
      this.animationTimeout = setTimeout(this.animateBanner, 3000);
      return;
    }
    if (this.props.overrideBannerVisibility !== prevProps.overrideBannerVisibility) {
      if (prevProps.overrideBannerVisibility && this.props.breachCheckBannerDismissed) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ bannerVisible: false });
      }

      if (this.props.overrideBannerVisibility && !this.props.breachCheck) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ bannerVisible: true });
      }
    }
  }

  componentWillUnmount() {
    if (this.animationTimeout) clearTimeout(this.animationTimeout);
    if (this.afterAnimationTimeout) clearTimeout(this.afterAnimationTimeout);
  }

  onActivate() {
    if (!this.state.activated) {
      this.setState({ activated: true });
      this.props.onActivate();
    }
  }

  animateBanner() {
    this.setState({ hidden: true }, () => {
      this.afterAnimationTimeout = setTimeout(() => {
        this.setState({ hidden: false, bannerVisible: false });
      }, 1000); // animation time
    });
  }

  render() {
    const { onClose, overrideBannerVisibility } = this.props;
    const { activated, hidden, bannerVisible } = this.state;
    return (
      bannerVisible && !hidden
        ? (
          <Box mr="s" flex="0 0 auto">
            <Banner
              onClose={overrideBannerVisibility ? null : onClose}
              actionButton={(
                <Button size="mini" onClick={this.onActivate}>
                  <FormattedMessage id={activated ? 'dashboard.securityStatus.hibp.activated' : 'dashboard.securityStatus.hibp.activate'} />
                </Button>
              )}
            >
              <BreachedwebsiteIcon mr="m" variant="alert" size="large" />
              <Text>
                <FormattedHTMLMessage
                  id="dashboard.securityStatus.hibp.activateMsg"
                />
              </Text>
            </Banner>
          </Box>
        )
        : null
    );
  }
}

ActivateHibpBanner.propTypes = {
  onActivate: func.isRequired,
  onClose: func.isRequired,
  breachCheck: bool.isRequired,
  breachCheckBannerDismissed: bool.isRequired,
  overrideBannerVisibility: bool.isRequired,
};

export default ActivateHibpBanner;
