import { identity } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { PickByValue } from 'utility-types';
import { RootState } from '../app/store';

import configuration from '../config';

function xor(a: boolean, b: boolean): boolean {
  return a !== b;
}

export type Props = {
  condition?: (
    keyof PickByValue<typeof configuration, boolean>
    | ((config: typeof configuration, state: RootState) => boolean)
  );
  negate?: boolean;
};

const ConfigRenderer: React.FC<Props> = ({
  condition,
  negate = false,
  children,
}) => {
  let conditionMet = true;

  const state = useSelector<RootState, RootState>(identity);

  if (typeof condition === 'string') {
    conditionMet = xor(!!configuration[condition], negate);
  } else if (typeof condition === 'function') {
    conditionMet = xor(
      !!condition(configuration, state),
      negate,
    );
  }

  if (conditionMet) {
    return (
      <>
        {children}
      </>
    );
  }

  return (<></>);
};

export default ConfigRenderer;
