/* global chrome, browser */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import AWS from 'aws-sdk';

import VaultSDK from '@nlok/vault-sdk';

import BreachedWebsiteService from '@avira-pwm/services/BreachedWebsiteService';
import LicenseService from '@avira-pwm/services/LicenseService';
import OEService from '@avira-pwm/services/OEService';
import EventTranslator from '@avira-pwm/aarrr/EventTranslator';

import Sync from '@avira-pwm/sync/Sync';

import ExtensionAdapter from '@avira-pwm/sync/adapters/ExtensionAdapter';
import LocalStorageAdapter from '@avira-pwm/sync/adapters/LocalStorageAdapter';
import IndexedDBAdapter from '@avira-pwm/sync/adapters/IndexedDBAdapter';
import AsyncStorageAdapter from '@avira-pwm/sync/adapters/AsyncStorageAdapter';
import NDSAdapter from '@avira-pwm/sync/adapters/NDSAdapter';

import CognitoAdapter from '@avira-pwm/sync/adapters/CognitoAdapter';

import NotificationServer from '@avira-pwm/notification';
import OERequest from 'oe-oauth/dist/lib/oe-request';
import DashboardMessenger from '@avira-pwm/messenger/dashboard';

import AUC from 'auc-2-lib';

import S3FileService from '@avira-pwm/services/S3FileService';
import { ModelCrypto, SyncEncryptionWrapper } from '@avira-pwm/sync';
import { Models } from '@avira-pwm/sync/ModelMerge';
import VaultState from '@nlok/vault-sdk/source/VTVaultState';
import createStore from './store';

import { getLocaleData } from '../locales';

import asyncJsonStorage from '../lib/asyncJsonStorage';

import config from '../config';
import proxyConfig from '../proxyConfig';
import getAucConfig from './aucConfig';

import persistentStateCreator from './StorePersistence';
import sentryLogger from './SentryLogger';
import { OEReducerDefaultState } from '../oe';
import { defaultState as NLOKDataReducerDefaultState } from '../nlok/NLOKDataReducer';
import App from './components/App';

import { PreferencesReducerDefaultState } from '../preferences';
import { getBrowser, getExtensionBrowserName } from '../lib/UserAgent';

import AsyncJsonStorageWrapper from '../lib/AsyncJsonStorageWrapper';

import SpotlightAdapter from '../lib/SpotlightAdapter';

import ErrorBoundary from './components/ErrorBoundary';

import createThunk from './thunk';
import ServiceContext from './components/ServiceContext';
import debug from '../debug';

const SPOTLIGHT_STORAGE_MIGRATION = 'SPOTLIGHT_STORAGE_MIGRATION';

// eslint-disable-next-line complexity, max-statements
async function appInit() {
  sentryLogger();

  if (config.spotlight) {
    const oldStorage = new AsyncJsonStorageWrapper(window.localStorage);

    const { [SPOTLIGHT_STORAGE_MIGRATION]: spotlightStorageMigration } = (
      await asyncJsonStorage.get([SPOTLIGHT_STORAGE_MIGRATION])
    );

    if (!spotlightStorageMigration) {
      await asyncJsonStorage.migrateFrom(oldStorage);
      await asyncJsonStorage.set({ [SPOTLIGHT_STORAGE_MIGRATION]: true });
    }
  }

  let { device_id: deviceId } = await asyncJsonStorage.get(['device_id']);

  if (deviceId == null) {
    deviceId = Math.floor(Math.random() * 1e10).toString();
    // eslint-disable-next-line @typescript-eslint/camelcase, camelcase
    await asyncJsonStorage.set({ device_id: deviceId });
  }

  const { userAgent } = navigator;


  if (config.useProxy) {
    window.SymO2.authSCD.O2_URL = proxyConfig.ocl.from;
    window.SymO2.authSCD.DS_URL = proxyConfig.ds.from;
    window.SymO2.authSCD.SPOC_URL = proxyConfig.spoc.from;

    window.SymO2.authSCD.SSO_LOGIN_URL = window.SymO2.authSCD.SSO_LOGIN_URL
      .replace(config.hostConfig.ssoHost, proxyConfig.sso.from);

    window.SymO2.authSCD.SSO_LOGOUT_URL = window.SymO2.authSCD.SSO_LOGOUT_URL
      .replace(config.hostConfig.ssoHost, proxyConfig.sso.from);

    window.SymO2.authSCD.SSO_OPEN_ID_URL = window.SymO2.authSCD.SSO_OPEN_ID_URL
      .replace(config.hostConfig.ssoHost, proxyConfig.sso.from);

    window.SymO2.authSCD.SSO_OPENID_CONNECT_LOGIN_URL = (
      window.SymO2.authSCD.SSO_OPENID_CONNECT_LOGIN_URL
        .replace(config.hostConfig.ssoHost, proxyConfig.sso.from)
    );

    window.SymO2.authSCD.SSO_OPENID_CONNECT_TOKEN_URL = (
      window.SymO2.authSCD.SSO_OPENID_CONNECT_TOKEN_URL
        .replace(config.hostConfig.ssoHost, proxyConfig.sso.from)
    );

    window.SymO2.authSCD.SSO_OPENID_CONNECT_DISCOVER_URL = (
      window.SymO2.authSCD.SSO_OPENID_CONNECT_DISCOVER_URL
        .replace(config.hostConfig.ssoHost, proxyConfig.sso.from)
    );
  }

  const licenseHost = config.useProxy
    ? `${window.location.origin}${proxyConfig.license.from}`
    : config.hostConfig.licenseHost;

  const licenseService = new LicenseService({
    host: licenseHost,
    application: 'dashboard',
    version: config.version,
    device: config.spotlight ? 'Spotlight' : null,
    deviceId,
    userAgent,
    browser: getBrowser(),
  });

  const s3FileService = new S3FileService({
    host: config.useProxy ? proxyConfig.s3File.from : config.hostConfig.s3FileHost,
    bucket: config.s3FileBucket,
    region: config.s3FileRegion,
  });

  const oeHost = config.useProxy
    ? `${window.location.origin}${proxyConfig.oe.from}`
    : config.hostConfig.authHost;

  const oeService = new OEService({
    host: oeHost,
  });

  const oeRequest = new OERequest(
    oeHost,
    config.authConfig.client,
    config.authConfig.authSecret,
  );

  const notificationServer = new NotificationServer({
    env: config.environment,
    host: config.hostConfig.notificationHost,
  });

  const breachedWebsiteService = new BreachedWebsiteService({
    host: config.hostConfig.breachedWebsiteHost,
  });

  const aucLib = new AUC(await getAucConfig(asyncJsonStorage));

  const eventTranslator = new EventTranslator({ platform: 'WebDashboard' });

  let browserApi = null;

  try {
    if (typeof chrome !== 'undefined') {
      browserApi = chrome;
    }

    if (typeof browser !== 'undefined') {
      browserApi = browser;
    }
  } catch (e) { /* empty */ }

  const dashboardMessenger = new DashboardMessenger({
    browser: browserApi,
    extensionId: process.env.NODE_ENV === 'production'
      ? config.extension[getExtensionBrowserName()]
      : 'beheljgafhongelhcppapeeachkckgbi',
  });

  const cloudSyncAdapter = new CognitoAdapter({ AWS });
  const localStorageAdapter = new LocalStorageAdapter();
  const indexedDBAdapter = new IndexedDBAdapter();
  const extensionAdapter = new ExtensionAdapter({ messenger: dashboardMessenger });
  const asyncStorageAdapter = new AsyncStorageAdapter({ storage: asyncJsonStorage, prefix: 'ASA' });
  const spotlightAdapter = new SpotlightAdapter(window.external, 'SpotlightDashboard');

  const ndsAdapter = new NDSAdapter(VaultSDK);

  /**
   * @type {(import('@avira-pwm/sync/ModelMerge').ModelNames)[]}
   */
  const models = Object.keys(Models);

  const sync = new Sync({
    adapters: [
      cloudSyncAdapter,
      extensionAdapter,
      localStorageAdapter,
      indexedDBAdapter,
      asyncStorageAdapter,
      spotlightAdapter,
      ndsAdapter,
    ],
    models,
    logger: debug.extend('Sync'),
  });

  const thunkMiddleware = createThunk({
    licenseService,
    s3FileService,
    oeService,
    breachedWebsiteService,
    oeRequest,
    aucLib,
    eventTranslator,
    syncInstance: sync,
    notificationServer,
    syncAdapters: {
      cloudSync: cloudSyncAdapter,
      extension: extensionAdapter,
      localStorage: localStorageAdapter,
      indexedDB: indexedDBAdapter,
      asyncStorage: asyncStorageAdapter,
      spotlight: spotlightAdapter,
      nds: ndsAdapter,
    },
    models,
    dashboardMessenger,
  });

  if (process.env.NODE_ENV === 'development') {
    window.triggerSync = () => {
      if (cloudSyncAdapter.canBeSynced()) {
        cloudSyncAdapter.sync();
      } else {
        // eslint-disable-next-line no-console
        console.warn(cloudSyncAdapter.name, ' can\'t be synced');
      }
    };
  }

  const persistentState = await persistentStateCreator(
    asyncJsonStorage,
    [
      'auth',
      'oe',
      'nlokData',
      'preferences',
      'sidebar',
      'tracking',
      'user',
      'abTest',
    ],
  );

  const { initial } = persistentState;
  const initialState = {
    ...persistentState.initial,
    nlokData: {
      ...NLOKDataReducerDefaultState(),
      ...initial.nlokData,
      // eslint-disable-next-line no-undef
      vaultState: initial.nlokData?.vaultState
        ? new VaultState(
          initial.nlokData.vaultState.state,
          initial.nlokData.vaultState.additionalInfo,
        )
        : null,
    },
    oe: { ...OEReducerDefaultState(), ...initial.oe },
    preferences: { ...PreferencesReducerDefaultState(), ...initial.preferences },
  };
  initialState.intl = getLocaleData(initialState.preferences.language);

  const store = createStore({
    initialState,
    thunkMiddleware,
  });

  store.subscribe(() => {
    persistentState.persist(store);
  });

  const rootEl = document.getElementById('root');

  if (config.localBuild) {
    window.PWM.sync = {
      syncInstance: sync,
      getSyncWrapper: () => {
        const state = store.getState();
        if (!state.user.key) {
          throw new Error('not unlocked');
        }

        return new SyncEncryptionWrapper(sync, new ModelCrypto(state.user.key));
      },
    };
  }


  ReactDOM.render((
    <ServiceContext.Provider value={{
      license: licenseService,
      oe: oeService,
    }}
    >
      <ErrorBoundary>
        <BrowserRouter>
          <LastLocationProvider>
            <App store={store} messenger={dashboardMessenger} />
          </LastLocationProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </ServiceContext.Provider>
  ), rootEl);
}

appInit();
