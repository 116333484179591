export const GET_DEVICES = 'GET_DEVICES';

// TODO: this type should be in pwm-user-data on the Service
export type Device = {
  browser: string;
  country: string;
  device: string;
  device_type: string;
  id: string;
  last_seen_at: string;
  platform: string;
  product: string;
  self: boolean;
};

export interface GetDevices {
  type: typeof GET_DEVICES;
  value: Device[];
}

export const LOG_OUT_DEVICE = 'LOG_OUT_DEVICE';

export interface LogOutDevice {
  type: typeof LOG_OUT_DEVICE;
  deviceId: string;
}
