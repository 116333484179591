import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';

import { changeAutoLockDuration, updateLanguageChoice } from '../../preferences';

import { logoutUser } from '../../authentication/AuthenticationActions';
import { getOEUserData, openConnectDashboard, showPlans } from '../../oe/OEActions';
import { toggleSyncStore, getUserData } from '../../user/UserActions';
import { deleteAccount, setHibpBreachCheck } from '../SettingsActions';
import { checkHibpUsernames } from '../../user/selectors';

import Settings from './Settings';

const mapStateToProps = (state) => {
  const {
    oe,
    oeData,
    user,
    userData,
    preferences,
    dashboard: { isUnregisteredMode, extensionConnected: extensionInstalled },
    userPreferences,
  } = state;

  return ({
    oe,
    oeData,
    user,
    userData,
    extensionInstalled,
    isUnregisteredMode,
    autolockDuration: userPreferences ? userPreferences.autolock.value : preferences.autolock,
    language: userPreferences ? userPreferences.language.value : preferences.language,
    checkUsernames: checkHibpUsernames(state),
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onLogoutClick: logoutUser,
  onGetProClick: showPlans,
  updateOEUserData: getOEUserData,
  updateLanguageChoice,
  onAutoLockChange: changeAutoLockDuration,
  deleteAccount,
  toggleSyncStore,
  getUserData,
  openEditAccount: context => openConnectDashboard('/dashboard/my-account/tabs/profile', context),
  openEditTwoFactorAuthentication: context => openConnectDashboard('/dashboard/my-account/tabs/security', context),
  setHibpBreachCheck,
}, dispatch);

export default withRouter(
  withLastLocation(
    connect(
      mapStateToProps,
      mapDispatchToProps,
    )(Settings),
  ),
);
