import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { bool, string } from 'prop-types';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';

import config from '../../../config';

import firefox from '../../../img/icon-firefox-large.png';
import firefox2x from '../../../img/icon-firefox-large@2x.png';

import chrome from '../../../img/icon-chrome-large.png';
import chrome2x from '../../../img/icon-chrome-large@2x.png';

import opera from '../../../img/icon-opera-large.png';
import opera2x from '../../../img/icon-opera-large@2x.png';

import edge from '../../../img/edge.png';
import edge2x from '../../../img/edge@2x.png';

import safari from '../../../img/icon-safari-large.png';
import safari2x from '../../../img/icon-safari-large@2x.png';

import avira from '../../../img/icon-avira-large.png';
import avira2x from '../../../img/icon-avira-large@2x.png';

import MobileAppLinks from '../../../componentLib/MobileAppLinks';

const ImageLink = styled('a')`
  margin-right: ${({ theme }) => theme.space.xxl};

  &:last-child {
    margin-right: 0;
  }
`;

const ComponentsOfAviraPWM = ({ isUnregisteredMode, language }) => (
  <>
    <Box
      mb="m"
    >
      <Box
        mb="xs"
      >
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.componentsOfAviraPwm.webDashboard"
            defaultMessage="Web Dashboard (you are here)"
          />
        </Text>
      </Box>
      <Paragraph>
        {isUnregisteredMode ? (
          <FormattedMessage
            id="dashboard.getStarted.componentsOfAviraPwm.webDashboard.unregisteredDetail"
            defaultMessage="Access and administer all your password managed accounts and secure notes. Review your password security status and take action on findings (PRO feature). You can access the web dashboard via your browser extension."
          />
        ) : (
          <FormattedMessage
            id="dashboard.getStarted.componentsOfAviraPwm.webDashboard.detail"
            defaultMessage="Access and administer all your password managed accounts and secure notes. Review your password security status and take action on findings (PRO feature). You can access the web dashboard via your browser extension or via https://passwords.avira.com on any browser (log in with your Avira account and unlock with your Master Password)."
          />
        )}
      </Paragraph>
    </Box>

    <Box
      mb="m"
    >
      <Box
        mb="xs"
      >
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.componentsOfAviraPwm.browserExtension"
            defaultMessage="Browser Extension"
          />
        </Text>
      </Box>
      <Paragraph mb="s">
        <FormattedMessage
          id="dashboard.getStarted.componentsOfAviraPwm.browserExtension.detail"
          defaultMessage="The browser extension (aka plugin) is complementing the web dashboard and it enables autofill into login forms on your websites."
        />
      </Paragraph>
      <Paragraph mb="s">
        <FormattedMessage
          id="dashboard.getStarted.componentsOfAviraPwm.browserExtension.availableFor"
          defaultMessage="Available for:"
        />
      </Paragraph>
      <Box>
        <ImageLink
          href={`${config.links.opera}?subSource=get-started`}
          target="_blank"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
        >
          <img
            src={opera}
            srcSet={`${opera} 1x, ${opera2x} 2x`}
            alt="opera"
          />
        </ImageLink>
        <ImageLink
          href={`${config.links.chrome}?subSource=get-started`}
          target="_blank"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
        >
          <img
            src={chrome}
            srcSet={`${chrome} 1x, ${chrome2x} 2x`}
            alt="chrome"
          />
        </ImageLink>
        <ImageLink
          href={`${config.links.firefox}?subSource=get-started`}
          target="_blank"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
        >
          <img
            src={firefox}
            srcSet={`${firefox} 1x, ${firefox2x} 2x`}
            alt="firefox"
          />
        </ImageLink>
        <ImageLink
          href={`${config.links.edge}?subSource=get-started`}
          target="_blank"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
        >
          <img
            src={edge}
            srcSet={`${edge} 1x, ${edge2x} 2x`}
            alt="edge"
          />
        </ImageLink>
        <ImageLink
          href={`${config.links.safari}?subSource=get-started`}
          target="_blank"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
        >
          <img
            src={safari}
            srcSet={`${safari} 1x, ${safari2x} 2x`}
            alt="safari"
          />
        </ImageLink>
        <ImageLink
          href={`${config.links.chrome}?subSource=get-started`}
          target="_blank"
          onClick={e => e.stopPropagation()}
          rel="noopener noreferrer"
        >
          <img
            src={avira}
            srcSet={`${avira} 1x, ${avira2x} 2x`}
            alt="avira secure browser"
          />
        </ImageLink>
      </Box>
    </Box>

    <Box
      mb="m"
    >
      <Box
        mb="xs"
      >
        <Text>
          <FormattedMessage
            id="dashboard.getStarted.componentsOfAviraPwm.mobile"
            defaultMessage="Avira Password Manager on your mobile"
          />
        </Text>
      </Box>
      <Paragraph mb="s">
        {isUnregisteredMode ? (
          <FormattedHTMLMessage
            id="dashboard.getStarted.componentsOfAviraPwm.mobile.unregisteredDetail"

            defaultMessage="Have all your passwords and notes always at hand, wherever you go. Auto-fill passwords into mobile apps and mobile browsers. Setup and manage two factor authentication for your accounts (registration required, <a href='/why-register?dashboardContext=get-started' target='_blank'>more details</a>)."
          />
        ) : (
          <FormattedMessage
            id="dashboard.getStarted.componentsOfAviraPwm.mobile.detail"

            defaultMessage="Have all your passwords and notes always at hand, wherever you go. Autofill passwords into mobile apps and mobile browsers. Setup and manage two factor authentication for your accounts."
          />
        )}
      </Paragraph>
      <Paragraph mb="s">
        <FormattedMessage
          id="dashboard.getStarted.componentsOfAviraPwm.mobile.availableFor"
          defaultMessage="Available for:"
        />
      </Paragraph>
      <MobileAppLinks language={language} />
    </Box>

  </>
);

ComponentsOfAviraPWM.propTypes = {
  isUnregisteredMode: bool.isRequired,
  language: string.isRequired,
};

export default ComponentsOfAviraPWM;
