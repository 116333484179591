import React from 'react';

import styled, { css } from 'pwm-components/styled';
import { Link } from 'react-router-dom';
import Paragraph from 'pwm-components/components/Paragraph';

type BreadcrumbProps = {
  last?: boolean;
};

const StyledBreadcrumb = styled(Paragraph)<BreadcrumbProps>`
display: inline-block;
vertical-align: top;

${({ last }) => !last && css`
    max-width: 150px;
    &:after {
      content: '›';
      padding: 0 ${({ theme: { space } }) => space.xs};
    }
  `}
`;

StyledBreadcrumb.displayName = 'StyledBreadcrumb';

type Breadcrumb = {
  title: React.ReactNode;
  path?: string;
  onClick?: (e: React.MouseEvent) => void;
};

type Props = {
  items: Breadcrumb[];
};

const StyledBreadcrumbs: React.FC<Props> = ({ items }) => (
  <>
    {items.map((item, i) => (
      <Link
        key={`${item.path}:${item.title}`}
        to={item.path || '#'}
        onClick={item.onClick}
      >
        <StyledBreadcrumb
          size="h3"
          variant={items.length - 1 === i ? 'disabled' : 'normal'}
          last={items.length - 1 === i}
          truncate
        >
          {item.title}
        </StyledBreadcrumb>
      </Link>
    ))}
  </>
);

StyledBreadcrumbs.displayName = 'StyledBreadcrumbs';

export default StyledBreadcrumbs;
