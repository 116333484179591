import Adapter, { PWMUserConfig } from '@avira-pwm/sync/adapters/Adapter';
import { ModelNames } from '@avira-pwm/sync/ModelMerge';
import { SpotlightApi, SpotlightData } from '../spotlight-api-types';

type ConnectConfig = { user: PWMUserConfig };

class SpotlightAdapter<
  T extends SpotlightApi.Sync & SpotlightApi.Messaging
> extends Adapter<ConnectConfig> {
  public static isApiAvailable(api: Partial<SpotlightApi.Sync & SpotlightApi.Messaging>): boolean {
    return (
      typeof api.SpotlightAdapterGetAll !== 'undefined'
      && typeof api.SpotlightAdapterPutChanges !== 'undefined'
    );
  }

  public name = 'SpotlightAdapter';

  private clientId: string;

  private userId: string | null = null;

  private api: T;

  constructor(api: T, clientId: string) {
    super();
    this.api = api;
    this.clientId = clientId;
  }

  protected connectImpl(config: ConnectConfig): Promise<boolean> {
    return new Promise((resolve) => {
      if (typeof this.api.RegisterEventListener !== 'undefined') {
        this.userId = config.user.id;

        this.api.RegisterEventListener('SpotlightAdapterSyncUpdate', () => {
          this.emit('updated');
        });

        this.api.SpotlightAdapterConnect(this.clientId, this.userId, (_err, _data) => {
          resolve(true);
        });
      } else {
        resolve(false);
      }
    });
  }

  protected async disconnectImpl(): Promise<void> {
    this.userId = null;
    this.api.RemoveEventListener('SpotlightAdapterSyncUpdate');
  }

  public getAll(type: ModelNames): Promise<any[]> {
    return new Promise((resolve, reject) => {
      if (!this.userId) {
        reject(new Error(`Adapter ${this.name} is not connected`));
        return;
      }

      this.api.SpotlightAdapterGetAll(
        this.clientId,
        this.userId!,
        type,
        (err, data) => {
          if (err) {
            reject(new Error(err));
            return;
          }

          if (!data) {
            resolve([]);
            return;
          }

          try {
            const parsedData = JSON.parse(data) as SpotlightData.StringifiedEntityRecord;
            const entities = Object.values(parsedData).map(d => JSON.parse(d));
            resolve(entities);
          } catch (e) {
            reject(e);
          }
        },
      );
    });
  }

  public putAll(type: ModelNames, _records: any[], changed: any[]): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.userId) {
        reject(new Error(`Adapter ${this.name} is not connected`));
        return;
      }

      const dataToPush = changed.reduce((obj, curr) => ({ [curr.id]: JSON.stringify(curr) }), {});
      this.api.SpotlightAdapterPutChanges(
        this.clientId,
        this.userId!,
        type,
        JSON.stringify(dataToPush),
        (err, _data) => {
          if (err) {
            reject(new Error(err));
          } else {
            resolve();
          }
        },
      );
    });
  }
}

export default SpotlightAdapter;
