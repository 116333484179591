import * as Sentry from '@sentry/browser';
import { SyncUtils } from '@avira-pwm/sync';

import {
  SET_PASSWORDS_BREACHES,
  PASSWORDS_BREACHES_CLEAR,
  PASSWORDS_BREACHES_DATA,
} from './SecurityStatusActionTypes';
import { generateID } from '../lib/AccountHelper';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { getSecurityStatus } from './selectors';
import { getRelevantUserKey } from '../user/selectors';
import { handleSyncError } from '../lib/ErrorHelper';


const setUpSyncOperation = (method, argument) => async (dispatch, getState, { syncInstance }) => {
  try {
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    await SyncUtils[method](sync, 'PasswordBreaches', argument);
  } catch (e) {
    try {
      handleSyncError(e, `pb:${method}`);
    } catch (rethrownError) {
      Sentry.captureException(rethrownError);
    }
  }
};

const createPasswordsBreaches = breachesToCreate => dispatch => dispatch(setUpSyncOperation('createBatch', breachesToCreate));

const updatePasswordsBreaches = breachesToUpdate => dispatch => dispatch(setUpSyncOperation('updateBatch', breachesToUpdate));

const deletePasswordBreach = id => dispatch => dispatch(setUpSyncOperation('deleteData', id));


// eslint-disable-next-line max-params
const addBreach = (initialBreach, type, date, domain, breachId) => {
  if (breachId
    && (
      !initialBreach.type
      || (date && (new Date(initialBreach.date) < new Date(date)))
    )
  ) {
    return {
      ...initialBreach,
      type,
      date,
      domain,
      breachId: type === 'auc' ? domain : breachId,
      toModify: true,
    };
  }
  return initialBreach;
};

const addAccount = (passwordsBreaches, breachesByPassword, account) => {
  const {
    hibpBreachedAccountDate,
    hibpBreachedAccountId,
    hibpBreachedWebsiteDate,
    hibpBreachedWebsiteId,
    aviraBreachedWebsiteDate,
    auc,
    password,
    domain,
  } = account;

  if (password === '') {
    return passwordsBreaches;
  }

  let { aviraBreachedWebsiteId } = account;
  aviraBreachedWebsiteId = aviraBreachedWebsiteId && aviraBreachedWebsiteId.toString();

  const initialBreach = breachesByPassword[password]
    || { id: generateID(), password, toCreate: true };
  const breach = [
    ['hibpBreachedUsername', hibpBreachedAccountDate, hibpBreachedAccountId],
    ['hibpBreachedWebsite', hibpBreachedWebsiteDate, hibpBreachedWebsiteId],
    ['aviraBreachedWebsite', aviraBreachedWebsiteDate, aviraBreachedWebsiteId],
    ['auc', null, auc],
  ].reduce(
    (p, [type, date, breachId]) => addBreach(p, type, date, domain, breachId),
    initialBreach,
  );
  return breach !== initialBreach
    ? { ...passwordsBreaches, [breach.id]: breach }
    : passwordsBreaches;
};

const getUniquePasswordsBreaches = (passwordsBreaches) => {
  const breachesToDelete = [];
  const breachesByPassword = Object.values(passwordsBreaches).reduce((hash, breach) => {
    const { id, password } = breach;

    if (password === '') {
      // Revert this commit when PWM-6514 is done on iOS
      // breachesToDelete.push(id);
      return hash;
    }

    const prevBreach = hash[password];
    if (prevBreach) {
      if (new Date(breach.date) < new Date(prevBreach.date)) {
        breachesToDelete.push(id);
        return hash;
      }
      breachesToDelete.push(prevBreach.id);
    }

    return {
      ...hash,
      [breach.password]: breach,
    };
  }, {});

  return { breachesByPassword, breachesToDelete };
};

export const buildPasswordBreaches = () => (dispatch, getState) => {
  const { accountsSecurityStatus: accounts } = getSecurityStatus(getState());
  let { passwordsBreaches } = getState();
  const initialPasswordsBreaches = passwordsBreaches;
  const {
    breachesByPassword, breachesToDelete,
  } = getUniquePasswordsBreaches(initialPasswordsBreaches);

  breachesToDelete.forEach(breachId => dispatch(deletePasswordBreach(breachId)));

  passwordsBreaches = Object.values(accounts)
    .reduce((p, account) => addAccount(p, breachesByPassword, account), passwordsBreaches);

  if (initialPasswordsBreaches !== passwordsBreaches) {
    const breachesToUpdate = [];
    const breachesToCreate = [];

    Object.values(passwordsBreaches).forEach((breach) => {
      const {
        toCreate, toModify, id, ...data
      } = breach;
      passwordsBreaches[id] = { id, ...data };
      const syncObject = { id, data };

      if (toCreate) breachesToCreate.push(syncObject);
      else if (toModify) breachesToUpdate.push(syncObject);
    });

    dispatch({ type: SET_PASSWORDS_BREACHES, payload: passwordsBreaches });
    if (breachesToCreate.length) dispatch(createPasswordsBreaches(breachesToCreate));
    if (breachesToUpdate.length) dispatch(updatePasswordsBreaches(breachesToUpdate));
  }
};

export const syncGetPasswordsBreaches = () => async (dispatch, getState, { syncInstance }) => {
  try {
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    const breaches = SyncUtils.getAllModelData(sync, 'PasswordBreaches');
    dispatch({ type: PASSWORDS_BREACHES_DATA, payload: breaches });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const clearPasswordsBreaches = () => ({ type: PASSWORDS_BREACHES_CLEAR });
