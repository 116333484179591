import { Status } from '@avira-pwm/services/licenseMigration';

export const MigrationStatusChanged = 'MigrationStatusChanged';
export type MigrationStatusChangedType = {
  status: Status;
  errorCode?: number;
}

export const MigrationTryAgainClicked = 'MigrationTryAgainClicked';
export type MigrationTryAgainClickedType = {
  attempt: number;
}

export const MigrationContactSupportClicked = 'MigrationContactSupportClicked';
