const milestones = {
  Add5Pro: {
    id: 1,
    name: 'Add5Pro',
    expiration: new Date('Jan 01 2019'),
  },
};

export default milestones;

export function transformMilestones(input = []) {
  return input.reduce((hash, obj) => (
    {
      ...hash,
      [obj.name]: obj,
    }
  ), {});
}
