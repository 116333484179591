import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import debounce from 'lodash/debounce';
import { markActive, checkAutoLock } from '../AutoLockTrackerActions';
import Loading from '../../app/components/Loading';

class AutoLockTracker extends React.Component {
  constructor(props) {
    super(props);
    this.markActive = debounce(this.markActive.bind(this), 500);
    this.state = { initialCheckDone: false };
  }

  async componentDidMount() {
    if (!await this.props.checkAutoLock()) { // check the timer for auto once logged in.
      this.setState({ initialCheckDone: true });
    }
    document.addEventListener('click', this.markActive);
    document.addEventListener('scroll', this.markActive);
    document.addEventListener('keypress', this.markActive);
    this.autoLockInterval = setInterval(this.props.checkAutoLock, 60 * 1000);
    this.activeTimeout = setTimeout(() => {
      this.markActive();
    }, 500);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.markActive);
    document.removeEventListener('scroll', this.markActive);
    document.removeEventListener('keypress', this.markActive);
    clearTimeout(this.activeTimeout);
    clearInterval(this.autoLockInterval);
  }

  markActive() {
    this.props.markActive();
  }

  render() {
    const { initialCheckDone } = this.state;
    return initialCheckDone ? this.props.children : (
      <Loading
        onRetry={() => window.location.reload()}
        timeout={40}
      >
        <FormattedMessage id="autoLockTracker.loading" defaultMessage="Checking autolock settings" />
      </Loading>
    );
  }
}

AutoLockTracker.propTypes = {
  markActive: PropTypes.func.isRequired,
  checkAutoLock: PropTypes.func.isRequired,
  children: PropTypes.element,
};

AutoLockTracker.defaultProps = {
  children: <div />,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  markActive,
  checkAutoLock,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AutoLockTracker);
