import React from 'react';
import PropTypes from 'prop-types';

const WizardSteps = ({ steps, active, onStepClick }) => (
  <div className="c-wizard-steps">
    {
      steps.map((label, step) => (
        <div
          tabIndex="0"
          onClick={() => onStepClick(step)}
          role="button"
          key={label}
          className={`c-wizard-steps__step ${active === step ? 'c-wizard-steps__step--active' : ''} u-outline-none`}
        >
          <div className="c-wizard-steps__step__badge" data-step={step + 1}>
            <span className="c-wizard-steps__step__label">
              {label}
            </span>
          </div>
        </div>
      ))
    }
  </div>
);

WizardSteps.propTypes = {
  active: PropTypes.number,
  onStepClick: PropTypes.func,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

WizardSteps.defaultProps = {
  active: 0,
  onStepClick: () => {},
};

export default WizardSteps;
