import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ThemeProvider from 'pwm-components/ThemeProvider';
import GlobalStyle from 'pwm-components/global';
import FTUComponent from './components/FTUComponent';
import MessengerContext from '../componentLib/MessengerContext';
import StepWelcome from './components/steps/StepWelcome';
import StepSetAsDefault from './components/steps/StepSetAsDefault';
import StepAllowDefault from './components/steps/StepAllowDefault';
import StepDone from './components/steps/StepDone';
import { StepProps } from './components/steps/StepProps';

const FTU: React.FC = () => {
  const [step, setStep] = useState(0);
  const dashboardMessenger = useContext(MessengerContext);

  useEffect(() => {
    if (dashboardMessenger && dashboardMessenger?.isConnected()) {
      dashboardMessenger.send(
        'dashboard:extension:isPasswordManagerEnabled',
        null,
        (_err, { error, value }: { error?: string | null; value?: boolean }) => {
          if (!error && value === false) {
            setStep(3);
          }
        },
      );
    }
  }, [dashboardMessenger]);

  const goToNextStep = useCallback((): void => {
    setStep(step + 1);
  }, [step, setStep]);
  const goToPreviousStep = useCallback((): void => {
    setStep(step - 1);
  }, [step, setStep]);

  const steps: StepProps[] = useMemo(() => ([{
    stepComponent: StepWelcome,
    stepName: 'Welcome',
    goToNextStep,
    goToPreviousStep,
  }, {
    stepComponent: StepSetAsDefault,
    stepName: 'SetAsDefault',
    goToNextStep,
    goToPreviousStep,
  }, {
    stepComponent: StepAllowDefault,
    stepName: 'AllowDefault',
    goToNextStep,
    goToPreviousStep,
  }, {
    stepComponent: StepDone,
    stepName: 'DonePage',
    goToNextStep,
    goToPreviousStep,
  }]), [goToNextStep, goToPreviousStep]);

  return (
    <ThemeProvider mode="dark">
      <GlobalStyle />
      <FTUComponent
        Step={steps[step]}
      />
    </ThemeProvider>
  );
};


export default FTU;
