import React, { useState, useEffect, useMemo } from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import StyledErrorLabel from 'pwm-components/components/Input/StyledErrorLabel';
import DateInput from './DateInput';
import {
  getMonth,
  removeChars,
  isPastDate,
} from '../../WalletHelpers';
import * as DataValidatorErrors from '../../../lib/DataValidator';

const fields = {
  VALID_TO_MONTH: 'validToMonth',
  VALID_TO_YEAR: 'validToYear',
  VALID_FROM_MONTH: 'validFromMonth',
  VALID_FROM_YEAR: 'validFromYear',
  VALID_FROM_DATE: 'validFromDate',
  VALID_TO_DATE: 'validToDate',
  VALID_DATE_RANGE: 'validDateRange',
  CARDNUMBER: 'cardNumber',
};

type Props = WrappedComponentProps & {
  changeHandler: (id: string, value: string) => void;
  getFieldError: (fieldName: string) => string | null;
  errorChangeHandler: (errors: Record<string, string>) => void;
  validFromMonth: string | undefined;
  validFromYear: string | undefined;
  validToMonth: string | undefined;
  validToYear: string | undefined;
}

const FromDateToDateInput = (props: Props): JSX.Element => {
  const {
    intl,
    changeHandler,
    errorChangeHandler,
    getFieldError,
    validFromMonth,
    validFromYear,
    validToMonth,
    validToYear,
  } = props;

  const [dateError, setDateError] = useState('');

  // eslint-disable-next-line complexity
  useEffect(() => {
    const errorString = getFieldError(fields.VALID_DATE_RANGE)
      || getFieldError(fields.VALID_FROM_DATE)
      || getFieldError(fields.VALID_TO_DATE)
      || getFieldError(fields.VALID_FROM_MONTH)
      || getFieldError(fields.VALID_TO_MONTH)
      || getFieldError(fields.VALID_FROM_YEAR)
      || getFieldError(fields.VALID_TO_YEAR);
    setDateError(errorString || '');
  }, [getFieldError]);

  const isValidMonthFormat = (month = ''): boolean => {
    if (!month) {
      return true;
    }

    const val = parseInt(month, 10);
    if (val > 12 || month === '1' || val === 0) {
      return false;
    }
    return true;
  };

  const isValidYearFormat = (year = ''): boolean => {
    if (!year) {
      return true;
    }
    return year.length === 2;
  };

  // eslint-disable-next-line eqeqeq
  const isValidDateFormat = (month = '', year = ''): boolean => (Boolean(month) == Boolean(year));

  const isValidRange = (
    fromMonth = '', fromYear = '', toMonth = '', toYear = '',
    // eslint-disable-next-line max-params
  ): boolean => {
    if (fromMonth && fromYear && toMonth && toYear) {
      const fromDate = new Date(2000 + parseInt(fromYear, 10), parseInt(fromMonth, 10));
      const toDate = new Date(2000 + parseInt(toYear, 10), parseInt(toMonth, 10));
      return fromDate < toDate;
    }
    return true;
  };

  const isValidFromMonth = useMemo(() => isValidMonthFormat(validFromMonth), [validFromMonth]);
  const isValidToMonth = useMemo(() => isValidMonthFormat(validToMonth), [validToMonth]);
  const isValidFromYear = useMemo(() => isValidYearFormat(validFromYear), [validFromYear]);
  const isValidToYear = useMemo(() => isValidYearFormat(validToYear), [validToYear]);
  const isValidFromDate = useMemo(() => isValidDateFormat(
    validFromMonth, validFromYear,
  ), [validFromMonth, validFromYear]);
  const isValidToDate = useMemo(() => isValidDateFormat(
    validToMonth, validToYear,
  ), [validToMonth, validToYear]);
  const isValidDateRange = useMemo(() => isValidRange(
    validFromMonth, validFromYear, validToMonth, validToYear,
  ), [validFromMonth, validFromYear, validToMonth, validToYear]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    let newErrors = {};

    if (!isValidFromMonth) {
      newErrors = { ...newErrors, [fields.VALID_FROM_MONTH]: DataValidatorErrors.ERROR_FORMAT };
    }
    if (!isValidFromYear) {
      newErrors = { ...newErrors, [fields.VALID_FROM_YEAR]: DataValidatorErrors.ERROR_FORMAT };
    }
    if (!isValidToMonth) {
      newErrors = { ...newErrors, [fields.VALID_TO_MONTH]: DataValidatorErrors.ERROR_FORMAT };
    }
    if (!isValidToYear) {
      newErrors = { ...newErrors, [fields.VALID_TO_YEAR]: DataValidatorErrors.ERROR_FORMAT };
    }
    if (!isValidFromDate) {
      newErrors = { ...newErrors, [fields.VALID_FROM_DATE]: DataValidatorErrors.ERROR_REQUIRED };
    }
    if (!isValidToDate) {
      newErrors = { ...newErrors, [fields.VALID_TO_DATE]: DataValidatorErrors.ERROR_REQUIRED };
    }
    if (!isValidDateRange) {
      newErrors = { ...newErrors, [fields.VALID_DATE_RANGE]: DataValidatorErrors.ERROR_CUSTOM };
    }
    errorChangeHandler(newErrors);
  // TODO: errorChangeHandler can't be in these deps as it'd cause a infinite loop.
  // We should think about fixing this and removing the error propagation that currently happens
  // FromDateToDateInput -> CreditCardInputFields -> AddEditPage
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isValidFromMonth,
    isValidToMonth,
    isValidFromYear,
    isValidToYear,
    isValidFromDate,
    isValidToDate,
    isValidDateRange,
  ]);

  const isExpired = useMemo(() => isPastDate(
    validToMonth, validToYear,
  ), [validToMonth, validToYear]);

  function monthChangeHandler(id: string, value: string): void {
    changeHandler(id, getMonth(removeChars(value)));
  }

  function yearChangeHandler(id: string, value: string): void {
    changeHandler(id, removeChars(value));
  }

  return (
    <Box>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box display="flex" flexDirection="row" alignItems="center">
          <DateInput
            error={dateError}
            label={(
              <FormattedMessage
                id="dashboard.wallet.details.validFrom"
                defaultMessage="Valid From"
              />
            )}
            monthValue={validFromMonth}
            yearValue={validFromYear}
            monthInputID='validFromMonth'
            yearInputID='validFromYear'
            placeholderMonth={intl.formatMessage({ id: 'dashboard.wallet.details.monthPlaceholder' })}
            placeholderYear={intl.formatMessage({ id: 'dashboard.wallet.details.yearPlaceholder' })}
            onMonthChange={monthChangeHandler}
            onYearChange={yearChangeHandler}
          />
          <Box mt="16px" mx="m">
            &ndash;
          </Box>
          <DateInput
            error={dateError}
            label={(
              <FormattedMessage
                id="dashboard.wallet.details.validTo"
                defaultMessage="To"
              />
            )}
            monthValue={validToMonth}
            yearValue={validToYear}
            monthInputID='validToMonth'
            yearInputID='validToYear'
            placeholderMonth={intl.formatMessage({ id: 'dashboard.wallet.details.monthPlaceholder' })}
            placeholderYear={intl.formatMessage({ id: 'dashboard.wallet.details.yearPlaceholder' })}
            onMonthChange={monthChangeHandler}
            onYearChange={yearChangeHandler}
          />
        </Box>
        <Box mt="16px" mx="s">
          {isExpired
            ? (
              <Text
                variant="alert"
                size="h4"
              >
                <strong>
                  {intl.formatMessage({ id: 'dashboard.wallet.details.expired' })}
                </strong>
              </Text>
            )
            : <></>}
        </Box>
      </Box>
      <StyledErrorLabel variant="alert" size="small">
        {dateError}
      </StyledErrorLabel>
    </Box>
  );
};

export default injectIntl(FromDateToDateInput);
