import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

function Card(props) {
  let clickAttrs = {};
  if (props.onClick) {
    clickAttrs = {
      onClick: props.onClick,
      role: 'link',
      tabIndex: '0',
    };
  }
  return (
    <div
      className={`c-card u-outline-none ${props.className} ${props.large ? 'c-card--large' : ''} ${props.noPadding ? 'c-card--nopad' : ''}`}
      {...clickAttrs}
    >
      {props.title
        && (
        <div className={`c-card__label ${props.classNameLabel}`}>
          <FormattedMessage id={props.title} />
        </div>
        )}
      <div
        className={`c-card__tile ${props.classNameTile}`}
      >
        {props.children}
      </div>
    </div>
  );
}

Card.defaultProps = {
  className: '',
  classNameLabel: '',
  classNameTile: '',
  large: false,
  noPadding: false,
  title: '',
  onClick: null,
};

Card.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  classNameLabel: PropTypes.string,
  classNameTile: PropTypes.string,
  large: PropTypes.bool,
  noPadding: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default Card;
