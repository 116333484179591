import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateAccountDetails,
} from '../AccountActions';

class AccountDetailsWrapper extends React.Component {
  static propTypes = {
    accounts: PropTypes.shape().isRequired,
    children: PropTypes.element,
    updateAccountDetails: PropTypes.func.isRequired,
    params: PropTypes.shape().isRequired,
  };

  static defaultProps = {
    children: <div />,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.updateAccountDetails(this.props.params.id).then(() => {
      this.setState({ loaded: true });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.accounts !== this.props.accounts
      || this.props.params.id !== nextProps.params.id) {
      this.props.updateAccountDetails(nextProps.params.id);
    }
  }

  render() {
    if (this.state.loaded) {
      return this.props.children;
    }

    return <div />;
  }
}

const mapStateToProps = ({ accounts }) => ({ accounts });

const mapDispatchToProps = dispatch => bindActionCreators({
  updateAccountDetails,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetailsWrapper);
