/* eslint-disable import/prefer-default-export */
import { State as Accounts, Entity as Account } from '@avira-pwm/redux/accounts';
import { State as Notes, Entity as Note } from '@avira-pwm/redux/notes';

export interface RelevantState {
  accounts: Accounts;
  notes: Notes;
}

export interface ExportEntry {
  name: string;
  website: string;
  username: string;
  secondary_username: string;
  password: string;
  notes: string;
}

const getUsername = ({ username, email }: Account): string => username || email || '';
const getSecondaryUsername = ({ username, email }: Account): string => ((username && email) ? email : '');

const getAccountEntry = (account: Account): ExportEntry => {
  const {
    domain, label, password, notes: accNotes,
  } = account;

  return ({
    name: label || '',
    website: domain || '',
    username: getUsername(account),
    secondary_username: getSecondaryUsername(account),
    password: password || '',
    notes: accNotes || '',
  });
};

const getAccountSubomainEntries = (account: Account): ExportEntry[] => {
  const result: ExportEntry[] = [];
  const {
    label, password, notes: accNotes, subdomain,
  } = account;

  if (subdomain && subdomain.length) {
    subdomain.forEach((sub) => {
      if (sub?.visible) {
        result.push({
          name: label || '',
          website: (sub && sub.subdomain) || '',
          username: getUsername(account),
          secondary_username: getSecondaryUsername(account),
          password: password || '',
          notes: accNotes || '',
        });
      }
    });
  }

  return result;
};

const getNoteEntry = ({ title, notes: noteEntry }: Note): ExportEntry => ({
  name: title || '',
  website: '',
  username: '',
  secondary_username: '',
  password: '',
  notes: noteEntry || '',
});

export const exportEntriesSelector = ({ accounts, notes }: RelevantState): Array<ExportEntry> => {
  let result: Array<ExportEntry> = [];

  Object.values(accounts).forEach((account) => {
    const { subdomain } = account;
    if (subdomain && subdomain.length) {
      const sdomains: ExportEntry[] = getAccountSubomainEntries(account);
      result = result.concat(sdomains);
    } else {
      result.push(getAccountEntry(account));
    }
  });

  Object.values(notes).forEach((note) => {
    result.push(getNoteEntry(note));
  });

  return result;
};
