import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import KeyIcon from 'pwm-components/icons/Key';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Button from 'pwm-components/components/Button';
import * as SpotlightAPI from '../../lib/SpotlightAPI';

const MasterPasswordKeyIcon = styled(KeyIcon)`
  width: 90px;
  height: 90px;
  fill: ${({ theme: { colors } }) => colors.cerulean};
`;

const SparkleIcon = styled('div')`
  position: absolute;
  &:: after {
    content: '+';
    color: #858C8F;
    font-size: 20px;
    font-weight: 600;
  }
`;

const SpotlightMasterPasswordRedirect: React.FC = () => (
  <Paragraph as="div" textAlign="center" id="a-master-password-redirect">
    <Box position="relative" mb="xxl" mx="a" width="150px">
      <MasterPasswordKeyIcon />
      <SparkleIcon style={{ top: -24, left: 36 }} />
      <SparkleIcon style={{ top: 36, left: 0 }} />
      <SparkleIcon style={{ bottom: 0, right: 16 }} />
    </Box>
    <Paragraph as="div" mb="m" size="h2">
      <FormattedMessage
        id="masterPassword.redirect.neverForget"
        defaultMessage="Never forget a password again"
      />
    </Paragraph>
    <Box display="flex" flexWrap="wrap" justifyContent="center" mb="xxl">
      <Paragraph as="div" noWrap textAlign="center">
        <FormattedMessage
          id="masterPassword.redirect.automaticLogin"
          defaultMessage="Automatically login on the websites you use daily."
        />
      </Paragraph>
      <Paragraph as="div" ml="xxs" noWrap textAlign="center">
        <FormattedMessage
          id="masterPassword.redirect.onAllDevices"
          defaultMessage="On all your devices."
        />
      </Paragraph>
    </Box>
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mr="m">
        <Button
          fullWidth
          onClick={() => SpotlightAPI.openURL(SpotlightAPI.routeHelper(''))}
        >
          <FormattedMessage
            id="masterPassword.redirect.openDashboard"
            defaultMessage="Open Password Manager"
          />
        </Button>
      </Box>
      <Box>
        <Button
          variant="secondary"
          onClick={() => SpotlightAPI.closeHtml('Return Welcome Page Password Manager')}
        >
          <FormattedMessage
            id="masterPassword.redirect.back"
            defaultMessage="Back"
          />
        </Button>
      </Box>
    </Box>
  </Paragraph>
);

export default SpotlightMasterPasswordRedirect;
