export enum PhotoSizes {
  thumbnail = 'thumb',
  mobilePreview = 'mobpre',
  fullPreview = 'fullpre',
}

type ResizeConfigs = {
  file: File;
  maxWidth?: number;
  maxHeight?: number;
  filled?: boolean;
  mimeType?: string;
  quality?: number;
};

export type PhotoSizesType = keyof typeof PhotoSizes;

export function resizeImage({
  file, maxWidth: mW, maxHeight: mH, filled = false, mimeType = 'image/png', quality = 1,
}: ResizeConfigs): Promise<string> {
  if (!file) {
    throw (new Error('File Not Found'));
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (mimeType.includes('gif')) {
        const gifURI = (reader.result || '').toString();
        const preview = document.getElementById('img-base64');
        if (preview) {
          (preview as HTMLImageElement).src = gifURI;
        }

        resolve(gifURI.replace(/^data:image\/.*;base64,/, ''));
      }

      const image = new Image();
      image.src = (reader.result || '').toString();

      // eslint-disable-next-line complexity, max-statements
      image.onload = () => {
        const canvas = document.createElement('canvas');
        let { width, height } = image;
        const maxWidth = mW || width;
        const maxHeight = mH || height;

        if (filled && width > maxWidth && height > maxHeight) {
          if (width >= height) {
            width *= maxHeight / height;
            height = maxHeight;
          } else {
            height *= maxWidth / width;
            width = maxWidth;
          }

          canvas.width = maxWidth;
          canvas.height = maxHeight;
        } else {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;
        }

        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(image, 0, 0, width, height);
          const imageURI = canvas.toDataURL(mimeType, quality);
          if (canvas && canvas.parentNode) {
            canvas.parentNode.removeChild(canvas);
          }

          resolve(imageURI.replace(/^data:image\/.*;base64,/, ''));
        } else {
          reject(new Error('Failed to create canvas context'));
        }
      };
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export async function createResizedPreviews(
  image: File, size: PhotoSizesType | string,
): Promise<string> {
  let options: ResizeConfigs = {
    file: image,
  };

  switch (size) {
    case 'thumbnail':
      options = {
        ...options, maxWidth: 250, maxHeight: 250, filled: true,
      };
      break;
    case 'mobilePreview':
      options = {
        ...options, maxWidth: 1242, maxHeight: 2688, mimeType: image.type,
      };
      break;
    case 'fullPreview':
      options = {
        ...options, mimeType: image.type, quality: 0.85,
      };
      break;
    case 'desktopPreview':
    default:
      options = { ...options, mimeType: image.type };
      break;
  }

  return resizeImage(options);
}

export type Previews = Record<string, Uint8Array>;

export async function generatePreviews(
  id: string,
  file: File,
): Promise<Previews> {
  const previewPromises = Object.keys(PhotoSizes).map(async (key) => {
    const data = await createResizedPreviews(file, key);
    return {
      [`${id}_${PhotoSizes[key as keyof typeof PhotoSizes] as string}`]: Buffer.from(data, 'base64'),
    };
  }, {});

  return (await Promise.all(previewPromises)).reduce(
    (obj, preview) => ({ ...obj, ...preview }),
    {},
  );
}
