import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Select from 'pwm-components/components/Select';

import Card from '../../componentLib/CardWrapper';

import { supported } from '../../locales';

type Props = {
  handleLanguageChange: (id: string) => void;
  language: string;
}

const Language: React.FC<Props> = ({ handleLanguageChange, language }) => {
  const supportedLanguages = Object.entries(supported).map(([locale, label]) => ({
    id: locale,
    label,
    disabled: false,
  }));
  return (
    <Card
      title={(
        <FormattedMessage id="dashboard.settings.language" />
      )}
      mb="l"
    >
      <Box width='150px'>
        <Select
          defaultValue={language}
          onChange={id => handleLanguageChange(id)}
          options={supportedLanguages}
        />
      </Box>
    </Card>
  );
};

export default Language;
