import { WalletActionTypes, WALLET_DETAILS_SCROLLPOSITION } from './WalletActionTypes';

export type State = Readonly<{
  scrollPosition: number;
}>;

const getInitialState = (): State => ({
  scrollPosition: 0,
});

const WalletPreferencesReducer = (state = getInitialState(), action: WalletActionTypes): any => {
  switch (action.type) {
    case WALLET_DETAILS_SCROLLPOSITION:
      return {
        ...state,
        scrollPosition: action.value,
      };
    default:
      return state;
  }
};

export default WalletPreferencesReducer;
