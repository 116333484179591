/* ———————————————————————————————————————————————————————————————————————————————————————

IDSCNode.js : Wrapper around the top level Node.

Symantec copyright header start

Copyright © 2015, Symantec Corporation, All rights reserved.

THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.

The Licensed Software and Documentation are deemed to be commercial computer
software as defined in FAR 12.212 and subject to restricted rights as defined
in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
Computer Software Documentation”, as applicable, and any successor regulations.
Any use, modification, reproduction release, performance, display or disclosure
of the Licensed Software and Documentation by the U.S. Government shall be
solely in accordance with the terms of this Agreement.

Symantec copyright header stop

BrowserFramework
watermark CB70-6840-3597-44-15-4
PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
Copyright © 2014, Symantec Corporation, All rights reserved.

Change History :
Wed Feb 20 2019 rahuraman Adding to P4.

——————————————————————————————————————————————————————————————————————————————————————— */
import constants from './VTConstants';

const dsv2Messages = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const { Value, Node } = dsv2Messages;
const {
  isNil, isntNil, isFunction 
} = SymBfw.utils;


class IDSCNode {
  /**
    * @function setEncryptionKey
    * @desc Sets the encryption key on the account.
    * @param {arraybuffer} key The array buffer that contains the encryption key.
    */
  setEncryptionKey(key) {
    this.encryptionKey = key;
  }

  /**
    * @function setObfuscationKey
    * @desc Sets the obfuscation key on the account.
    * @param {arraybuffer} key The array buffer that contains the obfuscation key.
    */
  setObfuscationKey(key) {
    this.obfuscationKey = key;
  }

  /**
    * @function setPBDKSalt
    * @desc Sets the login ID for the account.
    * @param {arraybuffer} salt The array buffer that contains the PBDK Salt.
    */
  setPBDKSalt(salt) {
    this.salt = salt;
  }

  /**
    * @function setLoginID
    * @desc Sets the login ID for the account.
    * @param {uint64} id The login ID for the IDSafe account (internally represented by Long.js)
    */
  setLoginID(id) {
    this.loginID = id;
  }

  /**
    * @function getLoginID
    * @desc Gets the Login ID for the account.
    * @returns {uint64} id The login ID for the IDSafe account (internally represented by Long.js)
    */
  getLoginID() {
    return this.loginID;
  }

  /**
    * @function getEncryptionKey
    * @desc Gets the encryption key on the account.
    * @returns {arraybuffer} key The array buffer that contains the encryption key.
    */
  getEncryptionKey() {
    return this.encryptionKey;
  }

  /**
    * @function getObfuscationKey
    * @desc Gets the obfuscation key on the account.
    * @returns {arraybuffer} key The array buffer that contains the obfuscation key.
    */
  getObfuscationKey() {
    return this.obfuscationKey;
  }

  /**
    * @function getPBDKSalt
    * @desc Gets the PBKDF salt.
    * @returns {arraybuffer} salt The array buffer that contains the salt.
    */
  getPBDKSalt() {
    if (isNil(this.salt)) {
      return null;
    }
    return this.salt.slice(0, this.salt.byteLength - constants.DEFAULT_CRC_SIZE);
  }

  /**
    * @function setNodeModified
    * @desc sets the time at which node was modified.
    * @param {Long} modified The time when server updated the current node.
    */
  setNodeModified(modified) {
    this.nodeModified = modified;
  }

  /**
    * @function getNodeModified
    * @desc Gets the time at which node was modified.
    * @returns {Long} modified The time when server updated the current node.
    */
  getNodeModified() {
    return this.nodeModified;
  }

  /**
    * @function serialize
    * @desc Serializes the given class into its datastore "Node" representation.
    * @param {array} childNodes Array of ChildNodeMeta 
    * that are under the current node tree structure in the datastore.
    * @returns Returns a Node object.
    */
  serialize(childNodes) {
    const node = new Node();
    const values = node.getValues();
    node.setPath(constants.DEFAULT_APPID_IDSC_NODE);

    const encryptionKeyValue = new Value();
    encryptionKeyValue.setType(Value.DataTypeID.TID_BINARY);
    encryptionKeyValue.setDataBinary(this.getEncryptionKey());
    encryptionKeyValue.setName(constants.DEFAULT_VAULT_ENCRYPTION_KEY);
    values.push(encryptionKeyValue);

    const loginValue = new Value();
    loginValue.setType(Value.DataTypeID.TID_UINT64);
    loginValue.setDataUint64(this.getLoginID());
    loginValue.setName(constants.DEFAULT_LOGIN_ID);
    values.push(loginValue);

    const saltValue = new Value();
    saltValue.setType(Value.DataTypeID.TID_BINARY);
    saltValue.setDataBinary(this.salt);
    saltValue.setName(constants.DEFAULT_VAULT_PBDK_SALT);
    values.push(saltValue);

    const obfuscationValue = new Value();
    obfuscationValue.setType(Value.DataTypeID.TID_BINARY);
    obfuscationValue.setDataBinary(this.getObfuscationKey());
    obfuscationValue.setName(constants.DEFAULT_VAULT_OBFUSCATION_KEY);
    values.push(obfuscationValue);

    node.setValues(values);
    // check if we have any child nodes to add. Very likely that we will.
    if (isntNil(childNodes)) {
      node.setChildNodes(childNodes);
    }
    // Once we have set all the values then just return the node object.
    return node;
  }

  /**
    * @function reset
    * @desc Resets IDSCNode. Initializes everything to null
    */
  reset() {
    for (const key in this) {
      if (!isFunction(this[key])) {
        this[key] = null;
      }
    }
  }
}
const idscNode = new IDSCNode();
export default idscNode;
