import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';

import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import AuthPageWrapper from './AuthPageWrapper';

import { isMobilePlatform } from '../../lib/UserAgent';

class AccountVerified extends React.Component {
  constructor(props) {
    super(props);

    const { oe } = this.props;

    this.state = {
      tokenPresent: Boolean(oe.token),
      mobilePlatform: isMobilePlatform(),
    };
  }

  render() {
    const { tokenPresent, mobilePlatform } = this.state;
    const instruction = tokenPresent ? (
      <Box>
        <Paragraph>
          <FormattedMessage
            id="accountVerified.tokenPresentOrAppInstalled.finalize"
            defaultMessage="Thank you for verifying your email. To finalize the setup please proceed to the next step."
          />
        </Paragraph>
      </Box>
    ) : (
      <Box>
        <Paragraph>
          <FormattedMessage
            id="accountVerified.tokenPresentOrAppInstalled.message"
            defaultMessage="Thank you for verifying your email."
          />
        </Paragraph>
        <Paragraph>
          <FormattedMessage
            id="accountVerified.tokenAbsentOrAppNotInstalled.instruction"
            defaultMessage="Please go back to the registration process and finalize your account setup."
          />
        </Paragraph>
      </Box>
    );
    let cta = null;
    if (tokenPresent) {
      cta = (
        <Box
          mt="xl"
        >
          <Link to="/create-master-password">
            <Button
              onClick={this.onSubmit}
              variant="login"
              style={{ width: '100%' }}
            >
              <FormattedMessage
                id="accountVerified.tokenPresentOrAppInstalled.nextStep"
                defaultMessage="Next Step"
              />
            </Button>
          </Link>
        </Box>
      );
    } else if (!mobilePlatform) {
      cta = (
        <Box
          mt="xl"
        >
          <Paragraph>
            <FormattedMessage
              id="accountVerified.tokenAbsent.closedRegistration"
              defaultMessage="Closed the registration process?"
            />
          </Paragraph>
          <Paragraph>
            <Link to="/create-master-password">
              <FormattedMessage
                id="accountVerified.tokenAbsent.continueRegistration"
                defaultMessage="This link helps you continue"
              />
            </Link>
          </Paragraph>
        </Box>
      );
    }

    return (
      <AuthPageWrapper
        Page={() => (
          <>
            <Box
              height="80px"
              display="flex"
              alignItems="center"
            >
              <strong>
                <Text>
                  <FormattedMessage
                    id="accountVerified.tokenPresentOrAppInstalled.header"
                  />
                </Text>
              </strong>
            </Box>
            <Box>
              {instruction}
              {cta}
            </Box>
          </>
        )}
      />
    );
  }
}

AccountVerified.propTypes = {
  oe: PropTypes.shape({
    token: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = ({ oe }) => ({ oe });

const mapDispatchToProps = () => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AccountVerified));
