import {
  SIDEBAR_COLLAPSED,
} from './SidebarActionTypes';
import {
  TrackingActions,
  MixpanelEvents,
} from '../tracking';

const { trackEvent } = TrackingActions;

const {
  MP_SIDEBAR_COLLAPSED,
  MP_SIDEBAR_EXPANDED,
} = MixpanelEvents;

// eslint-disable-next-line import/prefer-default-export
export const setCollapseState = state => (dispatch) => {
  const eventName = state ? MP_SIDEBAR_COLLAPSED : MP_SIDEBAR_EXPANDED;
  dispatch({ type: SIDEBAR_COLLAPSED, value: state });
  dispatch(trackEvent(eventName));
};
