import React from 'react';
import PropTypes from 'prop-types';
import config from '../../config';
import { HIBP_CHECKS_INTERVAL_MS } from '../constants';

class SecurityStatusTracker extends React.Component {
  constructor(props) {
    super(props);

    this.getHibp = this.getHibp.bind(this);
    this.updateIgnoreWarnings = this.updateIgnoreWarnings.bind(this);

    this.state = {
      aucInitialized: false,
    };
  }

  async UNSAFE_componentWillMount() {
    const {
      breachCheck,
      getHibpBreachedPasswords,
      getPersistedHibpUsernames,
      initAUC,
      loadAUC,
      loadAviraBreaches,
      loadHibpBreachedWebsites,
    } = this.props;

    if (config.enableSecurityStatus) {
      loadAviraBreaches();
      loadHibpBreachedWebsites();
      await getHibpBreachedPasswords();
      this.hibpPasswordsInterval = setInterval(getHibpBreachedPasswords, HIBP_CHECKS_INTERVAL_MS);

      if (breachCheck) {
        await getPersistedHibpUsernames();
        this.getHibp();
      }

      try {
        // Retry is done by auc lib itself
        await initAUC();
      } catch (e) { /**/ }
      this.setState({ aucInitialized: true }, () => {
        loadAUC();
      });
    }
  }

  // eslint-disable-next-line complexity
  async componentDidUpdate(prevProps) {
    const {
      accounts,
      breachCheck,
      breachesLoaded,
      buildPasswordBreaches,
      clearHibpUsernames,
      getHibpBreachedPasswords,
      getPersistedHibpUsernames,
      loadAUC,
      trackSecurityStatusInitialize,
    } = this.props;

    const { aucInitialized } = this.state;

    if (config.enableSecurityStatus) {
      if (accounts !== prevProps.accounts) {
        if (aucInitialized) {
          loadAUC();
        }
        await getHibpBreachedPasswords();
        this.updateIgnoreWarnings(accounts);
        buildPasswordBreaches();
      }

      if (
        breachCheck !== prevProps.breachCheck
        || (breachesLoaded === false && breachesLoaded !== prevProps.breachesLoaded)
      ) {
        if (breachCheck) {
          await getPersistedHibpUsernames();
          this.getHibp();
        } else {
          clearHibpUsernames();
        }
      }
    }

    if (prevProps.breachesLoaded !== breachesLoaded && breachesLoaded) {
      trackSecurityStatusInitialize();
      buildPasswordBreaches();
    }
  }

  componentWillUnmount() {
    const { clearHibpData } = this.props;
    clearHibpData();
    clearInterval(this.hibpUsernamesInterval);
    clearInterval(this.hibpPasswordsInterval);
  }

  getHibp() {
    const { getHibpBreachedUsernames } = this.props;
    getHibpBreachedUsernames();
    if (this.hibpUsernamesInterval) {
      clearInterval(this.hibpUsernamesInterval);
    }
    this.hibpUsernamesInterval = setInterval(this.getHibp, HIBP_CHECKS_INTERVAL_MS);
  }

  updateIgnoreWarnings(accounts) {
    const { updateIgnoreWarnings } = this.props;
    updateIgnoreWarnings(accounts);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

SecurityStatusTracker.propTypes = {
  accounts: PropTypes.shape({}).isRequired,
  breachCheck: PropTypes.bool,
  children: PropTypes.element,
  clearHibpUsernames: PropTypes.func.isRequired,
  clearHibpData: PropTypes.func.isRequired,
  getHibpBreachedPasswords: PropTypes.func.isRequired,
  getHibpBreachedUsernames: PropTypes.func.isRequired,
  getPersistedHibpUsernames: PropTypes.func.isRequired,
  updateIgnoreWarnings: PropTypes.func.isRequired,
  loadAviraBreaches: PropTypes.func.isRequired,
  loadHibpBreachedWebsites: PropTypes.func.isRequired,
  initAUC: PropTypes.func.isRequired,
  loadAUC: PropTypes.func.isRequired,
  breachesLoaded: PropTypes.bool.isRequired,
  trackSecurityStatusInitialize: PropTypes.func.isRequired,
  buildPasswordBreaches: PropTypes.func.isRequired,
};

SecurityStatusTracker.defaultProps = {
  breachCheck: false,
  children: <div />,
};

export default SecurityStatusTracker;
