import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import ms from 'ms';

import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import AccountListItemContainer from './AccountListItemContainer';
import {
  defaultSortBy, sortOptions, searchQueryLabelDomain, searchQueryUsernameEmail, defaultFilter,
} from '../../lib/AccountHelper';

import config from '../../config';
import confirmDeleteIcon from '../../img/icon-delete-big.png';
import giftBox from '../../img/gift_box_red.png';
import giftBox2x from '../../img/gift_box_red@2x.png';
import { MyDataContainer } from '../../myData';
import AccountPromoListItem from './AccountPromoListItem';
import milestones from '../../lib/MilestonesHelper';
import { ExtensionCardWrapper } from '../../extension';
import RegisterBanner from '../../componentLib/RegisterBanner';
import FilesBanner from '../../files/components/FilesBanner';

import UnlockProBg from '../../img/passwords_unlock_pro_bg.png';

import user from '../../img/user.png';
import user2x from '../../img/user@2x.png';
import Loading from '../../app/components/Loading';
import ExtensionOutdatedBanner from '../../extension/components/ExtensionOutdatedBanner';
import { getBrowser } from '../../lib/UserAgent';
import { installFirefoxExtension } from '../../lib/ExtensionHelper';
import { withFileManager } from '../../files/FileManagerContext';
import GetMobileBanner from '../../mobile/components/GetMobileBanner';
import intlShape from '../../lib/intlShape';
import NonMigratableBanner from '../../nlok/components/NonMigratableBanner';

const getProFreeSteps = ['one', 'two', 'three', 'four', 'five'];
const defaultSteps = !config.spotlight ? ['one', 'two', 'three'] : ['one', 'two'];

const getProFreePlaceholderListItems = getProFreeSteps.map(
  step => (
    <AccountPromoListItem
      messageId={`dashboard.getProFree.accountPlaceholder.${step}`}
      key={`dashboard.getProFree.accountPlaceholder.${step}`}
    />
  ),
);

const defaultPlaceholderListItems = defaultSteps.map(
  step => (
    <AccountPromoListItem
      messageId={`dashboard.default.accountPlaceholder.${step}`}
      key={`dashboard.default.accountPlaceholder.${step}`}
    />
  ),
);

const getProFreeBanner = (
  <div
    className="u-fx u-fx-ai-c u-pos-a u-color-slategrey u-pl-xxs u-pt-s"
    style={{ top: 380 }}
  >
    <img className="u-d-ib u-mr-l" srcSet={`${giftBox} 1x, ${giftBox2x} 2x`} alt="Gift box" />
    <div className="u-mt-l">
      <p className="u-txt-strong u-mb-xxs">
        <FormattedMessage
          id="dashboard.milestones.getProFree.header"
        />
      </p>
      <p>
        <FormattedMessage
          id="dashboard.milestones.getProFree.message"
        />
      </p>
    </div>
  </div>
);


class AccountsPage extends React.PureComponent {
  static propTypes = {
    authToken: PropTypes.string.isRequired,
    accounts: PropTypes.shape({}).isRequired, // TODO: this should be better checked
    scrollPosition: PropTypes.number.isRequired,
    preferences: PropTypes.shape({
      passwordSortBy: PropTypes.string,
    }).isRequired,
    onAddAccountClick: PropTypes.func.isRequired,
    setScrollPosition: PropTypes.func.isRequired,
    disallowDataUsage: PropTypes.bool.isRequired,
    onDeleteFile: PropTypes.func.isRequired,
    onSortByChange: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    fileManager: PropTypes.any.isRequired,
    fileManagerActions: PropTypes.any.isRequired,
    intl: intlShape.isRequired,
    tags: PropTypes.array,
    canDisplayBanners: PropTypes.bool.isRequired,
    isNonMigratable: PropTypes.bool.isRequired,
    showGetProFree: PropTypes.bool.isRequired,
    addMilestone: PropTypes.func.isRequired,
    dismissCongratsOnRegistration: PropTypes.func.isRequired,

    isUnregisteredMode: PropTypes.bool.isRequired,
    showGetMobileBanner: PropTypes.bool.isRequired,
    showExtBanner: PropTypes.bool.isRequired,
    showFilesBanner: PropTypes.bool.isRequired,
    showCongratsOnRegistration: PropTypes.bool.isRequired,
    extensionIsOutdated: PropTypes.bool,
    extensionOutdatedTimestamp: PropTypes.instanceOf(Date),
    extensionCurrentHash: PropTypes.string,
    getDevices: PropTypes.func.isRequired,
    setDevicesLoaded: PropTypes.func.isRequired,
  };

  static defaultProps = {
    tags: [],
    extensionIsOutdated: null,
    extensionOutdatedTimestamp: null,
    extensionCurrentHash: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentRevealPasswordId: '',
      currentAssociatedUrlsId: '',
      loading: false,
      showDeleteConfirmDialog: false,
      accountIdToDelete: '',
    };

    this.setCurrentRevealPassword = this.setCurrentRevealPassword.bind(this);
    this.setCurrentAssociatedUrls = this.setCurrentAssociatedUrls.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onProUnlockedClick = this.onProUnlockedClick.bind(this);
    this.listItemRender = this.listItemRender.bind(this);
    this.getAllDevices = this.getAllDevices.bind(this);
  }

  componentDidMount() {
    this.getAllDevices();
  }

  onDeleteClick(id) {
    this.setState({ showDeleteConfirmDialog: true, accountIdToDelete: id });
  }

  async onDeleteConfirm() {
    const {
      accounts, fileManager, fileManagerActions, onDeleteClick, onDeleteFile,
    } = this.props;
    const { accountIdToDelete } = this.state;

    const { files } = accounts[accountIdToDelete];
    this.setState({ loading: true }, async () => {
      await onDeleteClick(accountIdToDelete);
      Object.keys(files || {}).forEach(id => onDeleteFile(id, { fileManager, fileManagerActions }));
      this.setState({ showDeleteConfirmDialog: false, loading: false });
    });
  }

  onDeleteCancel() {
    this.setState({ showDeleteConfirmDialog: false });
  }

  onProUnlockedClick() {
    const { addMilestone } = this.props;
    addMilestone(milestones.Add5Pro);
  }

  setCurrentRevealPassword(id, visible) {
    if (visible) {
      this.setState({ currentRevealPasswordId: id });
    } else {
      this.setState({ currentRevealPasswordId: '' });
    }
  }

  setCurrentAssociatedUrls(id, visible) {
    if (visible) {
      this.setState({ currentAssociatedUrlsId: id });
    } else {
      this.setState({ currentAssociatedUrlsId: '' });
    }
  }

  async getAllDevices() {
    const { getDevices, authToken, setDevicesLoaded } = this.props;
    try {
      await getDevices(authToken);
      setDevicesLoaded();
    } catch { /* used only to display banner; can be safely ignored */ }
  }

  listItemRender(props) {
    const {
      currentRevealPasswordId,
      currentAssociatedUrlsId,
    } = this.state;

    return (
      <AccountListItemContainer
        {...props}
        currentRevealPasswordId={currentRevealPasswordId}
        setCurrentRevealPassword={this.setCurrentRevealPassword}
        currentAssociatedUrlsId={currentAssociatedUrlsId}
        setCurrentAssociatedUrls={this.setCurrentAssociatedUrls}
        onDeleteClick={this.onDeleteClick}
      />
    );
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      accounts,
      scrollPosition,
      showGetProFree,
      intl,
      disallowDataUsage,
      setScrollPosition,
      tags,
      preferences,
      onSortByChange,
      onAddAccountClick,
      dismissCongratsOnRegistration,

      isUnregisteredMode,
      canDisplayBanners,
      showGetMobileBanner,
      showExtBanner,
      showFilesBanner,
      showCongratsOnRegistration,
      extensionIsOutdated,
      extensionOutdatedTimestamp,
      extensionCurrentHash,
      isNonMigratable,
    } = this.props;
    const {
      loading,
      showDeleteConfirmDialog,
      currentRevealPasswordId,
      currentAssociatedUrlsId,
    } = this.state;

    const accountsCount = Object.keys(accounts || {}).length;
    let placeholderListItems = null;

    if (showGetProFree) {
      placeholderListItems = getProFreePlaceholderListItems;
    } else if (accountsCount < defaultSteps.length) {
      placeholderListItems = defaultPlaceholderListItems;
    }

    let banner = null;

    if (isNonMigratable) {
      banner = (
        <NonMigratableBanner supportLink={intl.formatMessage({ id: 'dashboard.nonMigratableBanner.supportLink' })} />
      );
    } else if (canDisplayBanners) {
      if (getBrowser() === 'Firefox'
        && extensionIsOutdated
        && extensionOutdatedTimestamp < Date.now() - ms('3d')) {
        banner = (
          <ExtensionOutdatedBanner
            onUpdateClick={() => installFirefoxExtension(
              config.extension.firefox,
              extensionCurrentHash,
            )}
          />
        );
      } else if (isUnregisteredMode) {
        banner = <RegisterBanner />;
      } else if (showExtBanner) {
        banner = <ExtensionCardWrapper page="accounts" />;
      } else if (showGetMobileBanner) {
        banner = <GetMobileBanner />;
      } else if (showFilesBanner) {
        banner = <FilesBanner />;
      }
    }

    const bottomBanner = showGetProFree ? getProFreeBanner : null;

    return showCongratsOnRegistration && accountsCount === 0 ? (
      <Loading>
        <FormattedMessage id="dashboard.accounts.firstSync" defaultMessage="Synchronizing passwords" />
      </Loading>
    ) : (
      <MyDataContainer
        id="a-accounts-list-container"
        header={intl.formatMessage({ id: 'dashboard.myData.passwords' })}
        list={accounts}
        listItem={(
          <AccountListItemContainer
            currentRevealPasswordId={currentRevealPasswordId}
            setCurrentRevealPassword={this.setCurrentRevealPassword}
            currentAssociatedUrlsId={currentAssociatedUrlsId}
            setCurrentAssociatedUrls={this.setCurrentAssociatedUrls}
            onDeleteClick={this.onDeleteClick}
          />
        )}
        disable={disallowDataUsage}
        afterScroll={setScrollPosition}
        scrollPosition={scrollPosition}
        emptyListMessage="dashboard.accounts.empty"
        emptySearchMessage="dashboard.accounts.search.empty"
        searchQueryMain={searchQueryLabelDomain}
        searchQuerySub={searchQueryUsernameEmail}
        defaultFilter={defaultFilter}
        tags={tags}
        sortBy={preferences.passwordSortBy}
        defaultSortBy={defaultSortBy}
        onSortByChange={onSortByChange}
        sortOptions={sortOptions}
        add="/mydata/passwords/new"
        onAddClick={onAddAccountClick}
        bottomBanner={bottomBanner}
        placeholderListItems={placeholderListItems}
        background={showGetProFree ? `url(${UnlockProBg}) no-repeat` : null}
        pageBanner={banner}
      >
        <ConfirmationDialog
          show={showDeleteConfirmDialog}
          confirmButtonType="alert"
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.delete' })}
          cancelLabel={intl.formatMessage({ id: 'dashboard.account.details.cancel' })}
          onConfirm={this.onDeleteConfirm}
          onCancel={this.onDeleteCancel}
          loading={loading}
        >
          <Box mb="l">
            <Paragraph mb="l" textAlign="center">
              <img src={confirmDeleteIcon} alt="Delete Password" />
            </Paragraph>

            <FormattedMessage
              id="dashboard.account.details.confirmDelete"
              defaultMessage="Are you sure you want to delete this password?"
            />
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          show={showCongratsOnRegistration}
          confirmLabel={intl.formatMessage({ id: 'dashboard.registerForFree.congrats.ok' })}
          onConfirm={dismissCongratsOnRegistration}
        >
          <Paragraph textAlign="center" mb="l">
            <Paragraph mb="l">
              <img
                src={user}
                srcSet={`${user} 1x, ${user2x} 2x`}
                alt="User Icon"
              />
            </Paragraph>
            <Paragraph mb="s">
              <FormattedMessage
                id="dashboard.registerForFree.congrats.title"
                defaultMessage="Congratulations!"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="dashboard.registerForFree.congrats.message"
                defaultMessage="You successfully registered your Avira Password Manager Account. Your data is now safely stored in the Avira Password Manager Cloud and strongly encrypted with your new Master Password."
              />
            </Paragraph>
          </Paragraph>
        </ConfirmationDialog>
      </MyDataContainer>
    );
  }
}

export default injectIntl(withFileManager(AccountsPage));
