import React from 'react';
import { string } from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

import Paragraph from 'pwm-components/components/Paragraph';

const messageIds = [1, 2, 3];

const WhyDisableSavingPasswords = ({ browser }) => (
  <>
    {messageIds.map(id => (
      <Paragraph
        key={id}
        {...(id !== 3 ? { mb: 'm' } : {})}
      >
        <FormattedHTMLMessage
          id={`dashboard.getStarted.whyDisable.message.${id}`}
          values={{ browser }}
        />
      </Paragraph>
    ))}
  </>
);

WhyDisableSavingPasswords.propTypes = {
  browser: string.isRequired,
};

export default WhyDisableSavingPasswords;
