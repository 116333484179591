import React, {
  useState, useEffect, useCallback, useRef,
} from 'react';
import { rgba } from 'polished';
import { useSelector } from 'react-redux';
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { State as FilesState } from '@avira-pwm/redux/files';

import styled from 'pwm-components/styled';
import { withTooltip } from 'pwm-components/components/Tooltip';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import Paragraph from 'pwm-components/components/Paragraph';
import DeleteIcon from 'pwm-components/icons/Delete';
import ActualSizeIcon from 'pwm-components/icons/ActualSize';
import ZoomInIcon from 'pwm-components/icons/ZoomIn';
import ZoomOutIcon from 'pwm-components/icons/ZoomOut';
import ClockwiseIcon from 'pwm-components/icons/Clockwise';
import DownloadIcon from 'pwm-components/icons/Download';
import CloseCircleIcon from 'pwm-components/icons/CloseCircle';
import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import TextButton from 'pwm-components/components/TextButton';

import confirmDeleteIcon from '../../img/icon-delete-big.png';
import { truncateMiddle } from '../helpers/FileNameHelper';
import { usePrevious } from '../../lib/hooks';
import { isStatusResolvable } from '../helpers/FileStatusHelper';
import { getMimeTypeByExtension, getFileTypeByExtension, FileType } from '../helpers/FileTypeHelper';
import { useFileManager } from '../FileManagerContext';
import FilesContext from './FilesContext';
import ImageViewer from './ImageViewer';
import PdfViewer from './PdfViewer';
import AudioVideoViewer from './AudioVideoViewer';
import UnsupportedViewer from './UnsupportedViewer';
import StatusViewer from './StatusViewer';
import { isUpsellAllowed } from '../../user/selectors';

const TextButtonWithTooltip = withTooltip(TextButton);

const MAX_NAME_LENGTH = 50;

const Modal = styled('div')<{ show: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 30;
  overflow-y: scroll;
  overflow-x: hidden;
  background: ${({ theme: { colors } }) => rgba(colors.shark, 0.7)};
`;

const FileViewerContainer = styled('div')`
  margin: auto;
  [class*="container"]  {
    overflow: visible;
    user-select: text;
  }
`;

const GetProBanner = styled('div')`
  display: flex;
  width: 90vh;
  height: 90vh;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { semanticColors } }) => semanticColors.foregroundSecondary};
`;

const ToolboxContainer = styled('div')`
  position: fixed;
  display: flex;
  flex-direction: row;
  width: 320px;
  left: calc(50% - 160px);
  bottom: 7.5vh;
`;

const ToolBox = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: rgba(0,0,0,0.5);
`;

const LeftToolbox = styled(ToolBox)`
  border-radius: 20px 0 0 20px;
  margin-right: 1px;
`;

const RightToolbox = styled(ToolBox)`
  border-radius: 0 20px 20px 0;
`;

const ArrowContainer = styled(TextButton)`
  position: fixed;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 50%;
  background-color: rgba(0,0,0,0.5);

  &::after {
    content: '';
    display: inline-block;
    margin-top: 9px;
    margin-left: -4px;
    width: 16px;
    height: 16px;
    border-top: 2px solid;
    border-right: 2px solid;
    border-color: ${({ theme: { colors } }) => colors.white}
  }
`;

const RightArrow = styled(ArrowContainer)`
  right: ${({ theme: { space } }) => space.xxl};
  transform: rotate(45deg) translate(0, -50%);
`;

const LeftArrow = styled(ArrowContainer)`
  left: ${({ theme: { space } }) => space.xxl};
  transform: rotate(-135deg) translate(50%, 0);
`;

const NameContainer = styled(ToolBox)`
  position: fixed;
  top: 7.5vh;
  left: 50%;
  transform: translate(-50%, 0);
  height: 22px;
  border-radius: 11px;
  padding: 0 ${({ theme: { space } }) => space.xs};
`;

type Props = WrappedComponentProps & {
  show: boolean;
  fileID: string;
  isPro?: boolean;
  onGetProClick?: () => void;
  onNextClick?: () => void;
  onPreviousClick?: () => void;
  onCloseClick?: () => void;
  onDeleteClick?: () => Promise<void>;
};

// eslint-disable-next-line complexity
const FileViewer: React.FC<Props> = ({
  intl,
  show,
  fileID,
  isPro = false,
  onGetProClick,
  onNextClick,
  onPreviousClick,
  onCloseClick,
  onDeleteClick,
}) => {
  const resetRef = useRef<() => void>();
  const prevFileID = usePrevious(fileID);
  const { fileManager: { fileStatuses } } = useFileManager();
  const [rotation, setRotate] = useState(0);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [data, setData] = useState<Uint8Array>(new Uint8Array());
  const [showDeleteConfirmDialog, setShowDelete] = useState(false);

  const { file, mimeType, fileType } = useSelector(({ files }: { files: FilesState }) => {
    const f = files[fileID];
    if (!f) {
      return {};
    }

    return {
      file: f,
      mimeType: getMimeTypeByExtension(f.type),
      fileType: getFileTypeByExtension(f.type),
    };
  });

  const disableDownload = !data || data.length === 0 || loading;
  const disableRotate = disableDownload || !isPro || fileType !== FileType.Image;
  const disableZoom = fileType === FileType.Pdf ? disableDownload : disableRotate;
  const fileName = truncateMiddle(
    `${file ? file.name : ''}.${file ? file.type.toLocaleLowerCase() : ''}`,
    MAX_NAME_LENGTH,
  );

  const upsellAllowed = useSelector(isUpsellAllowed);

  const getViewerByFileType = useCallback((): JSX.Element => {
    switch (fileType) {
      case FileType.Pdf:
        return <PdfViewer data={data} />;
      case FileType.Image:
        return <ImageViewer data={data} mimeType={mimeType} rotation={rotation} />;
      case FileType.Video:
        return <AudioVideoViewer data={data} shouldPlay={show} mimeType={mimeType} type="video" />;
      case FileType.Audio:
        return <AudioVideoViewer data={data} shouldPlay={show} mimeType={mimeType} type="audio" />;
      default:
        return <UnsupportedViewer data={data} fileType={fileType} fileName={fileName} />;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, rotation, fileID]);

  const downloadFile = useCallback((): void => {
    if (!file || !mimeType || !data) {
      return;
    }

    const link = document.createElement('a');
    link.href = `data:${mimeType};base64,${Buffer.from(data).toString('base64')}`;
    link.download = fileName;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }, [file, mimeType, data, fileName]);

  useEffect(() => {
    if (fileID !== prevFileID) {
      setRotate(0);
      if (resetRef && resetRef.current) {
        resetRef.current();
      }
    }
  }, [fileID, prevFileID, resetRef]);

  const onDeleteConfirm = useCallback(async (): Promise<void> => {
    setDeleteLoading(true);

    if (onDeleteClick) {
      await onDeleteClick();
    }

    setShowDelete(false);
    setDeleteLoading(false);
    if (onCloseClick) {
      await onCloseClick();
    }
  }, [onCloseClick, onDeleteClick]);

  useEffect(() => {
    function onKeydown(e: KeyboardEvent): void {
      switch (e.keyCode) {
        case 27: // ESC
          if (onCloseClick) {
            onCloseClick();
          }
          break;
        case 39: // Right Arrow
          if (onNextClick) {
            onNextClick();
          }
          break;
        case 37: // Left Arrow
          if (onPreviousClick) {
            onPreviousClick();
          }
          break;
        default:
          break;
      }
    }

    if (show) {
      document.addEventListener('keydown', onKeydown);
    } else {
      document.removeEventListener('keydown', onKeydown);
    }

    return () => document.removeEventListener('keydown', onKeydown);
  }, [onCloseClick, onNextClick, onPreviousClick, show]);

  return (
    <>
      <Modal
        id="a-attachments-viewer"
        show={show}
        onClick={onCloseClick}
      >
        <TransformWrapper
          reset={{ disabled: false }}
          wheel={{ disabled: disableRotate }}
          pan={{ disabled: disableZoom }}
          pinch={{ disabled: disableZoom }}
          zoomIn={{ disabled: disableZoom }}
          zoomOut={{ disabled: disableZoom }}
          doubleClick={{ disabled: disableZoom }}
        >
          {/* eslint-disable-next-line complexity */}
          {({ zoomIn, zoomOut, resetTransform }: any) => {
            if (!resetRef || !resetRef.current) {
              resetRef.current = resetTransform;
            }

            return (
              <>
                <FileViewerContainer onClick={e => e.stopPropagation()}>
                  <TransformComponent>
                    {isPro && show ? (
                      <FilesContext
                        files={file ? { [file.id]: file } : {}}
                        suffix={fileType === FileType.Image ? 'fullpre' : undefined}
                      >
                        {(decFiles) => {
                          const status = fileID ? (fileStatuses || {})[fileID] : undefined;
                          const decData = decFiles[fileID];

                          if ((status && !isStatusResolvable(status)) || !decFiles || !decData) {
                            return <StatusViewer status={status} />;
                          }

                          setData(decData);
                          setLoading(false);
                          return getViewerByFileType();
                        }}
                      </FilesContext>
                    ) : (
                      <GetProBanner>
                        <Box maxWidth={350} display="flex" alignItems="center" justifyContent="center" flexDirection="column">
                          {/* File Thumbnail here */}
                          <Paragraph mt="l" mb="l" size="h2" textAlign="center">
                            <FormattedMessage id="dashboard.account.details.files.renew" />
                          </Paragraph>
                          {
                            upsellAllowed
                              ? (
                                <Button fullWidth variant="upsell" onClick={onGetProClick}>
                                  <FormattedMessage id="dashboard.account.details.files.getPro" />
                                </Button>
                              )
                              : null
                          }
                        </Box>
                      </GetProBanner>
                    )}
                  </TransformComponent>
                </FileViewerContainer>
                {fileName && fileName.length > 0 && (
                  <NameContainer onClick={e => e.stopPropagation()}>
                    <Paragraph variant="light" size="small">
                      {fileName}
                    </Paragraph>
                  </NameContainer>
                )}
                <ToolboxContainer>
                  <LeftToolbox onClick={e => e.stopPropagation()}>
                    <TextButtonWithTooltip
                      className="a-delete-attachment-action"
                      ml="m"
                      mr="xs"
                      onClick={() => setShowDelete(true)}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.delete"
                          defaultMessage="Delete"
                        />
                      )}
                    >
                      <DeleteIcon variant="alert" />
                    </TextButtonWithTooltip>
                    <TextButtonWithTooltip
                      mx="s"
                      disabled={disableZoom}
                      onClick={resetTransform}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.originalSize"
                          defaultMessage="Original Size"
                        />
                      )}
                    >
                      <ActualSizeIcon variant={disableZoom ? 'disabled' : 'light'} />
                    </TextButtonWithTooltip>
                    <TextButtonWithTooltip
                      mx="s"
                      disabled={disableZoom}
                      onClick={zoomIn}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.zoomIn"
                          defaultMessage="Zoom In"
                        />
                      )}
                    >
                      <ZoomInIcon variant={disableZoom ? 'disabled' : 'light'} />
                    </TextButtonWithTooltip>
                    <TextButtonWithTooltip
                      mx="s"
                      disabled={disableZoom}
                      onClick={zoomOut}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.zoomOut"
                          defaultMessage="Zoom Out"
                        />
                      )}
                    >
                      <ZoomOutIcon variant={disableZoom ? 'disabled' : 'light'} />
                    </TextButtonWithTooltip>
                    <TextButtonWithTooltip
                      mx="s"
                      disabled={disableRotate}
                      onClick={() => setRotate(rotation + 90)}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.rotate"
                          defaultMessage="Rotate"
                        />
                      )}
                    >
                      <ClockwiseIcon variant={disableRotate ? 'disabled' : 'light'} />
                    </TextButtonWithTooltip>
                    <TextButtonWithTooltip
                      className="a-download-attachment-action"
                      mx="s"
                      disabled={disableDownload}
                      onClick={downloadFile}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.download"
                          defaultMessage="Download"
                        />
                      )}
                    >
                      <DownloadIcon variant={disableDownload ? 'disabled' : 'light'} />
                    </TextButtonWithTooltip>
                  </LeftToolbox>
                  <RightToolbox>
                    <TextButtonWithTooltip
                      className="a-close-attachments-viewer-action"
                      mx="m"
                      onClick={onCloseClick}
                      tooltipContent={(
                        <FormattedMessage
                          id="dashboard.account.details.files.close"
                          defaultMessage="Close"
                        />
                      )}
                    >
                      <CloseCircleIcon variant="light" />
                    </TextButtonWithTooltip>
                  </RightToolbox>
                </ToolboxContainer>
              </>
            );
          }}
        </TransformWrapper>
        <RightArrow
          onClick={(e) => {
            e.stopPropagation();
            if (onNextClick) {
              onNextClick();
            }
          }}
        />
        <LeftArrow
          onClick={(e) => {
            e.stopPropagation();
            if (onPreviousClick) {
              onPreviousClick();
            }
          }}
        />
      </Modal>
      <ConfirmationDialog
        confirmButtonType="alert"
        loading={deleteLoading}
        show={showDeleteConfirmDialog}
        confirmLabel={intl.formatMessage({
          id: 'dashboard.account.details.files.delete',
          defaultMessage: 'Delete',
        })}
        cancelLabel={intl.formatMessage({
          id: 'dashboard.account.details.files.cancel',
          defaultMessage: 'Cancel',
        })}
        onConfirm={onDeleteConfirm}
        onCancel={() => setShowDelete(false)}
      >
        <Box mb="l">
          <Paragraph mb="l" textAlign="center">
            <img src={confirmDeleteIcon} alt="Delete" />
          </Paragraph>
          <FormattedMessage id="dashboard.note.details.files.confirmDelete" />
        </Box>
      </ConfirmationDialog>
    </>
  );
};

export default injectIntl(FileViewer);
