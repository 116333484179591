import React from 'react';
import { bool, node } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import LogoIcon from 'pwm-components/components/LogoIcon';

import envConfig from '../config';

const PWMLogoHeaderContainer = styled(Box)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const PWMLogo = ({ isPro, suffix }) => (
  <Link
    to="/mydata/passwords"
    style={{ display: 'inline-block' }}
  >
    <PWMLogoHeaderContainer>
      <LogoIcon />
      <Box ml="s">
        <Text variant="normal" size="h2" mr="xxs">
          <FormattedMessage
            id="product.avira"
            defaultMessage="Avira"
          />
        </Text>
        <Text variant="normal" size="large">
          {
            isPro && envConfig.environment !== 'beta' ? (
              <FormattedMessage
                id="product.name.pro"
                defaultMessage="Password Manager Pro"
              />
            ) : (
              <FormattedMessage
                id="product.name"
                defaultMessage="Password Manager"
              />
            )
          }
        </Text>
        <Text variant="normal" size="normal" ml="xxs">
          {suffix}
        </Text>
        {
          envConfig.environment === 'beta'
          && (
          <Text variant="normal" size="h2" ml="xxs">
            Beta
          </Text>
          )
        }
      </Box>
    </PWMLogoHeaderContainer>
  </Link>
);

PWMLogo.propTypes = {
  isPro: bool,
  suffix: node,
};

PWMLogo.defaultProps = {
  isPro: false,
  suffix: null,
};

export default PWMLogo;
