import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import Box from 'pwm-components/components/Box';
import MessengerContext from '../../../componentLib/MessengerContext';
import { StepComponentProps } from './StepProps';
import Shield from '../../../img/shield_bright_bg.png';
import Key from '../../../img/key_passwords_bright_bg.png';
import Browser from '../../../img/browser_bright_bg.png';
import mixpanel from '../../../tracking/mixpanel';

const StepWelcome: React.FC<StepComponentProps> = ({
  goToNextStep,
  stepName,
}) => {
  const [disable, setDisable] = useState(false);
  const dashboardMessenger = useContext(MessengerContext);

  useEffect(() => {
    if (!dashboardMessenger?.isConnected()) {
      setDisable(true);
    }
  }, [dashboardMessenger]);

  useEffect(() => {
    mixpanel.track('PwmActivationShown', { stepName });
  }, [stepName]);

  const onClick = useCallback((): void => {
    mixpanel.track('PwmActivationClick', { stepName, buttonLabel: 'Get Started' });
    goToNextStep();
  }, [goToNextStep, stepName]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="485px"
      flex="0 1 485px"
      m="xxxl"
      ml="64px"
    >
      <Text size="h1" variant="light">
        <FormattedMessage id="onboarding.rightPanel.first.title" defaultMessage="Why Avira Password Manager?" />
      </Text>

      <Box
        display="flex"
        pt="l"
        alignItems="center"
      >
        <Box
          pr="l"
        >
          <img src={Shield} style={{ width: '48px', height: 'auto' }} alt='' />
        </Box>
        <Box>
          <Text size="normal" variant="light">
            <FormattedMessage id="onboarding.rightPanel.first.store" defaultMessage="Stores your passwords in a secure place and monitors them for data breaches" />
          </Text>
        </Box>
      </Box>

      <Box
        display="flex"
        pt="l"
        alignItems="center"
      >
        <Box
          pr="l"
        >
          <img src={Key} style={{ width: '48px', height: 'auto' }} alt="" />
        </Box>
        <Box>
          <Text size="normal" variant="light" pt="10px">
            <FormattedMessage id="onboarding.rightPanel.first.create" defaultMessage="Get more security by protecting your Automatically creates and stores unique and secure passwords" />
          </Text>
        </Box>
      </Box>

      <Box
        display="flex"
        pt="l"
        alignItems="center"
      >
        <Box
          pr="l"
        >
          <img src={Browser} style={{ width: '48px', height: 'auto' }} alt="" />
        </Box>
        <Box>
          <Text size="normal" variant="light" pt="10px">
            <FormattedMessage id="onboarding.rightPanel.first.remember" defaultMessage="Remember only one Master Password to access all your accounts wherever you need them" />
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        pt="l"
        pl="68px"
      >
        <Button variant="login" size="mini" onClick={onClick} disabled={disable}>
          <FormattedMessage id="onboarding.rightPanel.first.getStarted" defaultMessage="Get started" />
        </Button>
      </Box>

    </Box>
  );
};

export default StepWelcome;
