//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//

import Address from './VTAddress';
import Identity from './VTIdentity';
import Item from './VTItem';
import vaultManager from './VTVaultManager';


const {
  utils: bfwUtils
} = SymBfw;

const { isNil, isntNil } = bfwUtils;

export default class Contact extends Item {
  /**
   * @constructor Contact
   * @desc Constructor for creating a new Contact item.
   * @return An instance of the Contact item.
   */
  constructor() {
    super();
    this.identityItem = new Identity();
    this.addressItem = new Address();
  }

  /**
   * @function createItemFromKey
   * @desc create an encapsulated identity and address item of contact key.
   * @param {string} key of the contact item
   */
  createItemFromKey(key) {
    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    this.identityItem = identityManager.getItem(key);
    const addressGuid = this.identityItem.getAddressGuid();
    if (isntNil(addressGuid)) {
      this.addressItem = addressManager.getItem(addressGuid);
    }
    if (isNil(this.addressItem)) {
      this.addressItem = new Address();
    }
  }

  /**
   * @function initFromDictionary
   * @desc Create identity and address node from the dictionary.
   * @param {object} itemDict contains identity and address dictionary to create nodes
   * - {identityItem}
   * - {addressItem}
   */
  initFromDictionary(itemDict) {
    if (isNil(itemDict)) {
      throw new Error('Item dict cannot be null.');
    }
    const identityDict = itemDict.identityNode || {};
    const addressDict = itemDict.addressNode || {};
    if (isNil(identityDict.addressGuid) || isNil(addressDict.path) || identityDict.addressGuid !== addressDict.path) {
      const path = `{${bfwUtils.createNewGuid()}}`;
      identityDict.addressGuid = path;
      addressDict.path = path;
    }
    this.identityItem.initFromDictionary(identityDict);
    this.addressItem.initFromDictionary(addressDict);
  }

  /**
   * @function updateFromDictionary
   * @desc Update identity and address node from the dictionary
   * @param {object} itemDict contains identity and address dictionary to update nodes
   *  - {addressNode}
   *  - {identityNode}
   */
  updateFromDictionary(itemDict) {
    const identityDict = itemDict.identityNode;
    const addressDict = itemDict.addressNode;
    if (isNil(identityDict.addressGuid) || isNil(addressDict.path) || identityDict.addressGuid !== addressDict.path) {
      throw new Error('Invalid arguments to Contact.updateFromDictionary');
    }
    const identityUpdateResult = this.identityItem.updateFromDictionary(identityDict);
    const addressUpdateResult = this.addressItem.updateFromDictionary(addressDict);
    return identityUpdateResult || addressUpdateResult;
  }

  /**
   * @function schemaForKey
   * @desc Create schema for the item.
   * @param {string} key key of contact item
   */
  schemaForKey(key) {
    return this.identityItem.schemaForKey(key) || this.addressItem.schemaForKey(key);
  }

  /**
   * @function serializeNode
   * @desc Serialize the node. This is added for the usage in vaultTabUtil to return object to UI.
   * @return {Object} Serialized Item for UI
   */
  serializeNode() {
    let jsonString = this.addressItem.serializeNode(false, false);
    const addressDict = JSON.parse(jsonString);
    addressDict.id = addressDict.path;
    delete addressDict.path;

    jsonString = this.identityItem.serializeNode(false, false);
    const identityDict = JSON.parse(jsonString);
    identityDict.id = identityDict.path;
    delete identityDict.path;

    const serializedUIItem = {};
    serializedUIItem.addressNode = addressDict;
    serializedUIItem.identityNode = identityDict;
    return serializedUIItem;
  }
}
