import React from 'react';
import styled from 'pwm-components/styled';
import Paragraph from 'pwm-components/components/Paragraph';
import { FileType, getIconByFileType } from '../helpers/FileTypeHelper';
import ViewerProps from './ViewerProps';

const ViewerContainer = styled('div')`
  display: flex;
  width: 90vh;
  height: 90vh;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${({ theme: { semanticColors } }) => semanticColors.foregroundSecondary};
`;

export type Props = ViewerProps & {
  fileName?: string;
  fileType?: FileType;
};

const UnsupportedViewer: React.FC<Props> = ({ fileName, fileType = FileType.Unknown }) => {
  const Icon = getIconByFileType(fileType);

  return (
    <ViewerContainer>
      <Icon style={{ width: 100, height: 100 }} />
      {fileName && fileName.length > 0 && (
        <Paragraph size="large" mt="l">
          <strong>
            {fileName}
          </strong>
        </Paragraph>
      )}
    </ViewerContainer>
  );
};

export default UnsupportedViewer;
