import { Breach } from 'hibp';

export const SET_HIBP_PASSWORDS = 'SET_HIBP_PASSWORDS';
export const SET_HIBP_BREACHED_WEBSITES = 'SET_HIBP_BREACHED_WEBSITES';
export const SET_HIBP_USERNAMES = 'SET_HIBP_USERNAMES';

export const CLEAR_HIBP_DATA = 'CLEAR_HIBP_DATA';
export const CLEAR_HIBP_USERNAMES = 'CLEAR_HIBP_USERNAMES';

export const STOP_HIBP_USERNAME_CHECKS = 'STOP_HIBP_USERNAME_CHECKS';


interface SetHIBPPasswords {
  type: typeof SET_HIBP_PASSWORDS;
  payload: {
    [K: string]: {
      isBreached: boolean;
      lastChecked: number;
    };
  };
}

interface SetHIBPBreachedWebsites {
  type: typeof SET_HIBP_BREACHED_WEBSITES;
  payload: {
    [K: string]: Breach[];
  };
}

interface SetHIBPUsernames {
  type: typeof SET_HIBP_USERNAMES;
  payload: {
    [K: string]: {
      breaches: Breach[];
      lastChecked: number;
    };
  };
}

interface ClearHIBPData {
  type: typeof CLEAR_HIBP_DATA;
}

interface ClearHIBPUsernames {
  type: typeof CLEAR_HIBP_USERNAMES;
}

interface StopHIBPUsernameChecks {
  type: typeof STOP_HIBP_USERNAME_CHECKS;
}

export type HIBPActionTypes =
  SetHIBPPasswords
  | SetHIBPBreachedWebsites
  | SetHIBPUsernames
  | ClearHIBPData
  | ClearHIBPUsernames
  | StopHIBPUsernameChecks;
