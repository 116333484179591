import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { lockUser } from '../../user/UserActions';
import { logoutUser } from '../../authentication/AuthenticationActions';
import Header from './Header';
import { trackGetProClicked } from '../../dashboard/DashboardActions';
import {
  openConnectDashboard,
  openCancelRenewal,
  openContractCancelationRefund,
} from '../../oe/OEActions';

import { unlockProFreeWatcher, getHasAccessToPro } from '../../user/selectors';

const mapStateToProps = (state) => {
  const {
    oe, oeData, userData, dashboard: { isUnregisteredMode },
  } = state;
  return {
    oe,
    oeData,
    isUnregisteredMode,
    isPro: userData.isPro,
    paymentUrl: (oeData && oeData.paymentUrl) || '',
    proUnlocked: unlockProFreeWatcher(state),
    hasAccessToPro: getHasAccessToPro(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onLockClick: () => lockUser('header', 'lock', null),
  onLogoutClick: logoutUser,
  onGetProClick: trackGetProClicked,
  openEditAccount: context => openConnectDashboard('/dashboard/my-account/tabs/profile', context),
  openCancelRenewal: context => openCancelRenewal(context),
  openRenewalSettings: context => openConnectDashboard('/dashboard/renewal/tabs/plans', context),
  openContractCancelationRefund: context => openContractCancelationRefund(context),
}, dispatch);

const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Header);

export default HeaderContainer;
