import React from 'react';
import ProtectedRoute from './ProtectedRoute';
import { isDataUsageDisallowed } from '../../selectors';

const SyncEnabledRoute = props => (
  <ProtectedRoute
    {...props}
    requirement={
      state => !isDataUsageDisallowed(state)
    }
    redirectPath="/mydata/passwords"
  />
);

export default SyncEnabledRoute;
