//
//     Symantec copyright header start
//
// Copyright © 2017, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// Browser Protection
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//

/* eslint no-continue: "off" */

import vaultKeys from './VTKeys';
import vaultUtils from './VTUtils';
import constants from './VTConstants';
import telemetryWrapper from './VTTelemetryWrapper';

const { Long } = dcodeIO;

const o2Utils = SymO2.utils;
const { Node, Value } = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const { DataTypeID } = Value;


const {
  utils: {
    isNil,
    isntNil,
    isBoolean,
    isString,
    createNewGuid
  },
  logger
} = SymBfw;


export default class Item {
  constructor() {
    if (this.constructor === Item) {
      throw new Error("Abstract classes can't be instantiated.");
    }
  }


  /**
   * @function parseNode
   * @desc Interface function that parses the protobuf node object and fills up the Item.
   * @param {Node} node The protobuf node Object.
   * @summary The class that overrides this "interface" should implement this method.
   */
  parseNode(node) {
    const { values } = node;
    for (const index in values) {
      if (Object.prototype.hasOwnProperty.call(values, index)) {
        const value = values[index];
        // get data based on type
        let data;
        const schema = this.schemaForKey(value.name);
        if (isntNil(schema)) {
          const { serializedType, setter } = schema;
          if (serializedType === value.type) {
            switch (value.type) {
              case DataTypeID.TID_STRING:
                data = value.data_string;
                break;
              case DataTypeID.TID_UINT64:
                data = value.data_uint64;
                break;
              case DataTypeID.TID_BINARY:
                data = value.data_binary.toArrayBuffer();
                break;
              default:
                logger.error(`Unsupported data type: ${value.type}`);
            }
            setter.apply(this, [data, constants.ITEM_INTERNAL_CONTEXT]);
          }
        } else {
          logger.error(
            `Name:${value.name}:WrongType\nExpectedType:${null}\nActual Type:${value.type}`
          );
        }
      }
    }
  }

  /**
     * @function getPath
     * @desc Implementation of interface function that gets the path <GUID> to the current node.
     * @returns {string} Returns a string that contains a GUID that uniquely represents the current item.
     */
  getPath() {
    return this.path;
  }


  /**
   * @function getNodePath
   * @desc Interface function that returns the full path to the current item in the datastore.
   * @summary The class that overrides this "interface" should implement this method.
   * @returns Returns a string that contains the full path to the item in the datastore.
   */
  getNodePath() {
    throw new Error('Super class should not be called directly for this method');
  }

  /**
   * [updateFromDictionary description]
   * @desc Interface function that will updated the changed values
   * @param {object} itemDict The dictionary Object should have the changed values.
   * @return {boolean} Returns true if updated
   */
  updateFromDictionary(itemDict) {
    if (isNil(itemDict)) {
      throw new Error('Item dict cannot be null.');
    }

    if (this.encrypted === true) {
      return false;
    }

    let updated = false;
    for (const key in itemDict) {
      if (Object.prototype.hasOwnProperty.call(itemDict, key)) {
        let schemaKey = null;
        try {
          schemaKey = this.propertyToSchemaKey(key);
        } catch (e) {
          logger.error(`Error: ${e}\nKey does not have a matching Schema:${key}`);
        }

        const schema = this.schemaForKey(schemaKey);
        if (isntNil(schema)) {
          const newValue = itemDict[key];
          const { getter, setter } = schema;
          const currentValue = getter.apply(this);
          if (newValue !== currentValue) {
            updated = true;
            setter.apply(this, [newValue]);
          }
        }
      }
    }
    if (updated) {
      this.setLastUpdate(new Date().getTime() / 1000);
    }
    return updated;
  }

  /**
   * @function initFromDictionary
   * @desc Given a dictionary representation of the item populate the current item.
   * @param {object} itemDict The dictionary representation the item.
   */
  initFromDictionary(itemDict) {
    if (isNil(itemDict)) {
      throw new Error('Item dict cannot be null.');
    }

    if (isNil(itemDict.path)) {
      itemDict.path = `{${createNewGuid()}}`;
    }

    if (isNil(itemDict.lastUpdate)) {
      itemDict.lastUpdate = new Date().getTime() / 1000;
    }

    this.encrypted = false;

    for (const key in itemDict) {
      if (Object.prototype.hasOwnProperty.call(itemDict, key)) {
        let schemaKey = null;
        try {
          schemaKey = this.propertyToSchemaKey(key);
        } catch (e) {
          logger.error(`Error: ${e}\nKey does not have a matching Schema:${key}`);
        }

        const schema = this.schemaForKey(schemaKey);
        if (isntNil(schema)) {
          const jsObject = itemDict[key];
          const { setter } = schema;
          setter.apply(this, [jsObject]);
        }
      }
    }
  }

  /**
   * @function propertyToSchemaKey
   * @desc Given a property of Item returns the schema key
   * that can then be used to be look up the schema.
   * @param {object} property The property you want to look up.
   * @returns {object} schemaKey The schema key that can be used to look up schema map.
   * @summary The schema key usually refers to the data in the datastore.
   * The property key is the property or ivar of an Item instance.
   */
  propertyToSchemaKey(property) {
    if (isString(property) === false) {
      throw new Error('Input property cannot be null');
    }

    const schemaKey = this.propertyToSchemaKeyMap[property];
    if (isNil(schemaKey)) {
      throw new Error('Property does not have a mapping.');
    }

    return schemaKey;
  }

  /**
   * @function schemaForKey
   * @param {string} key
   * @desc Returns the schema for the input key
   * @return {any} A dictionary that contains the schema for the key.
   */
  schemaForKey(key) {
    throw new Error('Super class should not be called directly for this method');
  }

  /**
   * @function serializeNode
   * @desc Interface function that serializes the current Item into a node protobuf object.
   * @param {boolean} shouldDelete Lets us know if we need to delete the current node.
   * Defaults to false.
   * @param {boolean} shouldEncrypt Lets us know if we need to encrypt the node
   * before serializing it. Defaults to true. Need false only during automation
   * @returns {Node|String} node The protobuf node Object. JSON string if shouldEncrypt=false
   * @summary The class that overrides this "interface" should implement this method.
   */
  serializeNode(shouldDelete = false, shouldEncrypt = true) {
    // let getter;
    if (!shouldEncrypt) {
      // if the login is encrypted then decrypt the data
      if (this.encrypted) {
        this.decrypt();
      }
      const serializedObj = {};

      for (const key in this.propertyToSchemaKeyMap) {
        if (Object.prototype.hasOwnProperty.call(this.propertyToSchemaKeyMap, key)) {
          const vaultKey = this.propertyToSchemaKeyMap[key];
          const schema = this.schemaForKey(vaultKey);
          if (isntNil(schema)) {
            serializedObj[key] = schema.getter.apply(this);
          }
        }
      }
      return JSON.stringify(serializedObj);
    }

    // if the login is not encrypted then encrypt the data
    if (!this.encrypted) {
      this.encrypt();
    }

    const node = new Node();
    const values = node.getValues();
    node.setPath(this.getNodePath());
    const { keys } = this;
    for (let index = 0; index < keys.length; index += 1) {
      const itemKey = keys[index];
      const schemaForKey = this.schemaForKey(itemKey);
      // pass context coz we need items as they in memory.
      const serializedData = schemaForKey.getter.apply(this, [constants.ITEM_INTERNAL_CONTEXT]);
      if (isntNil(serializedData)) {
        // if value was never previously set then don't bother

        const dataValue = new Value();
        dataValue.setType(schemaForKey.serializedType);
        dataValue.setName(itemKey);
        switch (schemaForKey.serializedType) {
          case DataTypeID.TID_BINARY:
            dataValue.setDataBinary(serializedData);
            break;
          case DataTypeID.TID_STRING:
            dataValue.setDataString(serializedData);
            break;

          case DataTypeID.TID_UINT64:
            dataValue.setDataUint64(serializedData);
            break;

          case DataTypeID.TID_UINT32:
            dataValue.setDataUint32(serializedData);
            break;
          default:
        }
        values.push(dataValue);
      }
    }
    node.setValues(values);
    if (shouldDelete) {
      node.setDeleted(true);
    }
    // Once we have set all the values then just return the node object.
    return node;
  }

  /**
     * @function setPath
     * @desc setter to set the guid    of this node
     * @param {string} value the value to set
     */
  setPath(value) {
    this.path = value;
  }

  /**
     * @function getSecure
     * @desc gets the secure state
     * @return {boolean}  the secure state
     */
  getSecure() {
    return this.secure;
  }

  /**
       * @function setSecure
       * @desc sets the secure state
       * @param  {boolean} value the value to set
       */
  setSecure(value) {
    this.secure = value;
  }

  /**
     * @function getLastUpdate
     * @desc gets the last updated time stamp
     * @return {Long}  lastUpdate time stamp
     */
  getLastUpdate() {
    if (isNil(this.lastUpdate)) {
      this.lastUpdate = (new Date().getTime() / 1000);
    }
    return this.lastUpdate;
  }

  /**
       * @function setLastUpdate
       * @desc setter to set the last updated time stamp of this node
       * @param  {Long} value time stamp
       */
  setLastUpdate(value) {
    this.lastUpdate = value;
  }

  /**
       * @function getFavorite
       * @desc getter for favorite
       * @return {boolean} favorite
       */
  getFavorite() {
    return this.favorite;
  }

  /**
       * @function setFavorite
       * @desc setter for favorite
       * @param  {boolean} value the value to set
       */
  setFavorite(value) {
    this.favorite = value;
  }


  /**
   * @function clone
   * @desc clone the item.
   * @returns {object} Item The clone object.
   */
  clone() {
    const serializedString = this.serializeNode(false, false);
    const itemDict = JSON.parse(serializedString);
    const itemClone = new this.constructor();
    itemClone.initFromDictionary(itemDict);
    return itemClone;
  }


  /**
   * @function encrypt
   * @desc Interface function that encrypts the current Item.
   * This method is usually called by serializeNode.
   * @summary The class that overrides this "interface" should implement this method.
   */
  encrypt() {
    if (this.encrypted === true) {
      // node is already decrypted so don't bother
      return;
    }

    const { keys } = this;
    for (let index = 0; index < keys.length; index += 1) {
      const itemKey = keys[index];
      const schemaForKey = this.schemaForKey(itemKey);
      const {
        deserializedType,
        serializedType,
        encrypted,
        obfuscated,
        setter,
        getter
      } = schemaForKey;
      const deserializedValue = getter.apply(this, [constants.ITEM_INTERNAL_CONTEXT]);
      if (serializedType === deserializedType && encrypted === false && obfuscated === false) {
        // nothing to do here. Just continue.
        continue;
      }
      let serializedValue = deserializedValue;

      if (isntNil(deserializedValue)) {
        let dataType = this.dataTypeIDToVaultSerializeType(deserializedType);

        if (obfuscated === false && encrypted === false) {
          // if both encryption/obfuscation are false then we need to convert
          // to Uint8 before adding to protobuf since serializedType is BINARY.
          if (serializedType === DataTypeID.TID_BINARY
            && deserializedType === DataTypeID.TID_BOOL) {
            serializedValue = this._boolToBinary(serializedValue);
          }

          if (serializedType === DataTypeID.TID_BINARY
            && deserializedType === DataTypeID.TID_STRING) {
            // We currently don't have an example for this but it could happen in the future
            // where we want to store an plain string as an array buffer.
            const byteArray = o2Utils.stringUTF16LEToByteArray(serializedValue);
            serializedValue = new Uint8Array(byteArray);
          }
        }

        // NOTE The order here is very important.
        // encryption - sequence -> obfuscate data ->  encryption
        // decryption - We always should first decrypt and then followed by
        // obfuscation when encrypt = true and obfuscation = true.
        // This order cannot be changed.
        if (obfuscated === true) {
          serializedValue = vaultUtils.dataToBufferWithSerializedType(serializedValue, dataType);
          // NOTE obfuscate actually deobfuscates as well. It does nothing but a simple XOR.
          serializedValue = vaultUtils.obfuscateData(serializedValue,
            vaultKeys.getObfuscationKey());
          dataType = constants.SERIALIZE_DATA_TYPE.BINARY;
        }

        if (encrypted === true) {
          const name = itemKey;
          const path = constants.DEFAULT_IDSC_PATH_CRC + this.getNodePath();
          serializedValue = vaultUtils.createEnvelopeAndEncryptData(vaultKeys.getEncryptionKey(),
            serializedValue, dataType, path, name, true);
        }

        setter.apply(this, [serializedValue, constants.ITEM_INTERNAL_CONTEXT]);
      }
    }

    this.encrypted = true;
  }

  /**
   * @function decrypt
   * @desc Implementation of interface function that decrypts the current Item.
   * This method is usually called after parseNode.
   * @summary This function first gets the schema or metadata for each key for the current item.
   * If the deserialized and serialized types are the same and encrypted, obfuscation flags
   * are set to false then we skip that key. As the value is stored as is in the vault.
   * If the above statement is false, we look at the encrypted flag.
   * If the value is encrypted, then it proceeds with its decryption.
   * Next, we look at the obfuscated flag. Obfuscation is just an XOR with the obfuscation key.
   * If the data is obfuscated then we deobfuscated it.
   * Next, we check the serialized and deserialized type.
   * We support two transformations for now BINARY->BOOL and BINARY->STRING
   */
  decrypt() {
    if (this.encrypted === false) {
      // node is already decrypted so don't bother
      return;
    }

    const { keys } = this;
    for (let index = 0; index < keys.length; index += 1) {
      const itemKey = keys[index];
      const schemaForKey = this.schemaForKey(itemKey);
      const {
        deserializedType,
        serializedType,
        encrypted,
        obfuscated,
        setter,
        getter
      } = schemaForKey;
      const serializedValue = getter.apply(this, [constants.ITEM_INTERNAL_CONTEXT]);

      if (serializedType === deserializedType && encrypted === false && obfuscated === false) {
        // nothing to do here. Just continue.
        continue;
      }
      let deserializedValue = serializedValue;

      if (isNil(serializedValue)) {
        continue;
      }

      let expectedType = this.dataTypeIDToVaultSerializeType(deserializedType);

      if (obfuscated === true) {
        expectedType = constants.SERIALIZE_DATA_TYPE.BINARY;
      }

      // NOTE The order here is very important. We always should first decrypt and then followed by
      // obfuscation when encrypt = true and obfuscation = true.
      // This order cannot be changed.

      const path = constants.DEFAULT_IDSC_PATH_CRC + this.getNodePath();
      const name = itemKey;

      if (encrypted === true) {
        // first decrypt the buffer
        deserializedValue = vaultUtils.decryptEnvelopeData(deserializedValue,
          vaultKeys.getEncryptionKey(), path, name, expectedType);
      }

      if (isNil(deserializedValue)) {
        logger.error(`Unable to decrypt value at path:${path} name:${name}`);
        setter.apply(this, [null, constants.ITEM_INTERNAL_CONTEXT]);
        continue;
      }

      if (obfuscated === true) {
        // NOTE obfuscate actually deobfuscates as well. It does nothing but a simple XOR.
        deserializedValue = vaultUtils.obfuscateData(deserializedValue,
          vaultKeys.getObfuscationKey());
      }

      if (isNil(deserializedValue)) {
        telemetryWrapper.sendIDSCryptoError(
          `Unable to obfuscate value at path: ${path} name: ${name}`,
          telemetryWrapper.IDS_CRYPTO_ERROR_CONSTANTS.UNABLE_TO_OBFUSCATE_ITEM
        );
        setter.apply(this, [null, constants.ITEM_INTERNAL_CONTEXT]);
        continue;
      }

      if (serializedType === DataTypeID.TID_BINARY && deserializedType === DataTypeID.TID_BOOL) {
        const boolValue = this._binaryToBool(deserializedValue);
        setter.apply(this, [boolValue, constants.ITEM_INTERNAL_CONTEXT]);
        continue;
      }

      if (serializedType === DataTypeID.TID_BINARY && deserializedType === DataTypeID.TID_STRING) {
        let stringValue = null;
        do {
          if (deserializedValue.byteLength === 0) {
            stringValue = '';
            break;
          }
          stringValue = o2Utils.arrayBufferToStringUTF16LE(deserializedValue);
        } while (false);

        setter.apply(this, [stringValue, constants.ITEM_INTERNAL_CONTEXT]);
        continue;
      }

      if (serializedType === DataTypeID.TID_BINARY && deserializedType === DataTypeID.TID_BINARY) {
        // this will only happen when the schema says either encrypt or obfuscate and not both
        // and has the same serialize and deserialize type
        // For example password. Encrypt is set to true but obfuscate is set to false.
        // This is because the setter/getter are responsible for doing the obfuscation.
        setter.apply(this, [deserializedValue, constants.ITEM_INTERNAL_CONTEXT]);
      }
    }

    this.encrypted = false;
  }

  /**
   * @function _binaryToBool
   * @desc Helper function that converts an unencrypted array buffer to a BOOL.
   * @returns boolean true if array buffer's first byte contains a non-zero value.
   * @private
   */
  _binaryToBool(binary) {
    let tempBinary = binary;
    if (tempBinary instanceof ArrayBuffer) {
      tempBinary = new Uint8Array(binary);
    }
    if (tempBinary.byteLength === 0) {
      return false;
    }

    const firstByte = tempBinary[0];
    return firstByte !== 0;
  }

  /**
   * @function _boolToBinary
   * @desc Helper function that converts an bool to unencrypted array buffer
   * @returns Uint8Array ArrayBuffer in which the first byte contains the bool value.
   * @private
   */
  _boolToBinary(boolValue) {
    // create a Uint8Array of one byte.
    const binary = new Uint8Array(1);
    if (boolValue === false) {
      binary[0] = 0;
    } else {
      binary[0] = 1;
    }
    return binary;
  }

  /**
   * @function dataTypeIDToVaultSerializeType
   * @desc Converts mapping from DataTypeID to constants.SERIALIZE_DATA_TYPE
   * @param {number} deserializedType
   * @returns {number}
   */
  dataTypeIDToVaultSerializeType(deserializedType) {
    let dataType = null;
    if (isNil(deserializedType)) {
      throw new Error('DeserializedType cannot be null');
    }
    switch (deserializedType) {
      case DataTypeID.TID_BOOL:
        dataType = constants.SERIALIZE_DATA_TYPE.BOOL;
        break;
      case DataTypeID.TID_STRING:
        dataType = constants.SERIALIZE_DATA_TYPE.STRING;
        break;
      case DataTypeID.TID_UINT32:
        dataType = constants.SERIALIZE_DATA_TYPE.UINT32;
        break;
      case DataTypeID.TID_UINT64:
        dataType = constants.SERIALIZE_DATA_TYPE.LONG;
        break;
      case DataTypeID.TID_TIMESTAMP:
        dataType = constants.SERIALIZE_DATA_TYPE.LONG;
        break;
      case DataTypeID.TID_BINARY:
        dataType = constants.SERIALIZE_DATA_TYPE.BINARY;
        break;
      default:
        throw new Error(`Unknown deserializedType passed: ${deserializedType}`);
    }

    return dataType;
  }

  verifyJSObjectWithDeserializedType(type, jsObject) {
    let verified = false;
    if (isNil(type)) {
      throw new Error('DeserializedType cannot be null');
    }

    if (isNil(jsObject)) {
      throw new Error('JSObject cannot be null');
    }

    switch (type) {
      case DataTypeID.TID_BOOL:
        verified = isBoolean(jsObject);
        break;
      case DataTypeID.TID_STRING:
        verified = isString(jsObject);
        break;
      case DataTypeID.TID_UINT32:
        verified = typeof jsObject === 'number';
        break;
      case DataTypeID.TID_UINT64:
        verified = jsObject instanceof Long || typeof jsObject === 'number';
        break;
      case DataTypeID.TID_TIMESTAMP:
        verified = jsObject instanceof Long || typeof jsObject === 'number';
        break;
      case DataTypeID.TID_BINARY:
        verified = jsObject instanceof ArrayBuffer || jsObject instanceof Uint8Array;
        break;
      default:
        break;
    }
    return verified;
  }

  /**
   * @function setNodeModified
   * @desc sets the time at which node was modified.
   * @param {Long} modified The time when server updated the current node.
   */
  setNodeModified(modified) {
    this.nodeModified = modified;
  }

  /**
   * @function getNodeModified
   * @desc Gets the time at which node was modified.
   * @returns {Long} modified The time when server updated the current node.
   */
  getNodeModified() {
    return this.nodeModified;
  }

  /**
   * Helper function to allow subclasses to get either
   * the obfuscated string binary or deobfuscated string for UI.
   * @param  {Object} prop    The obfuscated property.
   * @param  {String} context Context string to let us know if its internal get call.
   * @return {ArrayBuffer|String|Object} Returns array buffer or string for the property.
   */
  getDeobfuscatedStringProperty(prop, context) {
    if (context === constants.ITEM_INTERNAL_CONTEXT) {
      return prop;
    }

    if (isNil(prop)) {
      return null;
    }

    const obfuscatedData = vaultUtils.obfuscateData(prop, vaultKeys.getObfuscationKey());
    if (isNil(obfuscatedData) || obfuscatedData.byteLength === 0) {
      return null;
    }
    return o2Utils.arrayBufferToStringUTF16LE(obfuscatedData);
  }

  /**
   * Helper function to allow subclasses to set either
   * the obfuscated string binary or deobfuscated string for UI.
   * @param  {String} propName    The property name you want to set.
   * @param {ArrayBuffer|String} value The array buffer or string you want to store in the property.
   * @param  {String} context Context string to let us know if its internal get call.
   */
  setClearOrObfuscatedStringProperty(propName, value, context) {
    if (isNil(value)) {
      this[propName] = null;
      return;
    }

    if (context === constants.ITEM_INTERNAL_CONTEXT) {
      this[propName] = value;
      return;
    }

    const byteArray = o2Utils.stringUTF16LEToByteArray(value);
    const propBuff = new Uint8Array(byteArray);
    this[propName] = vaultUtils.obfuscateData(propBuff, vaultKeys.getObfuscationKey());
  }
}
