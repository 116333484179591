import UserAgentParser from 'mixpanel-lib/source/UserAgentParser';
import { getBrowser as getBrowserName, getBrowserEngine, getBrowserExtensionPlatform } from '@avira-pwm/helpers/userAgent';

import config from '../config';

const uaParser = new UserAgentParser(navigator.userAgent);

export function getBrowser() {
  return getBrowserName();
}

export function getEngine() {
  return getBrowserEngine();
}

export function getExtensionBrowserName() {
  return getBrowserExtensionPlatform(Object.keys(config.extension));
}

export function getOS() {
  return uaParser.getOS();
}

export function getBuildVersion() {
  return uaParser.getBuildVersion();
}

export function getMajorVersion() {
  return uaParser.getMajorVersion();
}

export function isMobilePlatform() {
  const os = getOS().toLowerCase();
  return os === 'android' || os === 'ios';
}

export function isInternetExplorer() {
  const browser = getBrowser();
  // eslint-disable-next-line no-undef
  return browser?.toLowerCase() === 'internet explorer';
}

export function isChrome() {
  return getBrowser() === 'Chrome';
}

export function isSafari() {
  return getBrowser() === 'Safari';
}

export function isFirefox() {
  return getBrowser() === 'Firefox';
}

export function isBrowserPreinstalledWithExtension() {
  const browser = getBrowser();
  return !!browser && config.browsersPreinstalledWithExtension.includes(browser);
}
