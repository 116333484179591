import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import BenefitList from '../List';

const WhereIsMyDataStoredRegistered = () => (
  <Box>
    <Paragraph mb="xs">
      <FormattedMessage
        id="dashboard.getStarted.whereIsMyDataStored.registeredDetail"

        defaultMessage="Your data is stored locally on your device and also heavily encrypted in the cloud. We encrypt your data with your master password which only you know."
      />
    </Paragraph>
    <Paragraph mb="xs">
      <FormattedMessage
        id="dashboard.getStarted.whereIsMyDataStored.benefits"
        defaultMessage="Storing the data in the cloud has two key benefits:"
      />
    </Paragraph>
    <BenefitList>
      <li>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.whereIsMyDataStored.benefit1"
            defaultMessage="Your data is regularly backed up to keep it safe."
          />
        </Paragraph>
      </li>
      <li>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.whereIsMyDataStored.benefit2"
            defaultMessage="You can always access it from multiple devices."
          />
        </Paragraph>
      </li>
    </BenefitList>
  </Box>
);

export default WhereIsMyDataStoredRegistered;
