import AWS from 'aws-sdk';
import ServerDate from '@avira-pwm/helpers/ServerDate';

export function setServerTimeOffset(offset: number): void {
  ServerDate.setOffset(offset);
  AWS.config.systemClockOffset = offset;
}

export function setServerTime(serverTime: Date): void {
  ServerDate.setServerDate(serverTime);
  setServerTimeOffset(ServerDate.getOffset());
}

export function getServerTimeOffset(): number {
  return ServerDate.getOffset();
}
