/* eslint-disable max-classes-per-file */

import { AuthData } from '@avira-pwm/helpers/Auth';
import { SpotlightMessaging, GetResponse } from '@avira-pwm/messenger/SpotlightMessaging';

import config from '../config';
import { SpotlightApis } from '../spotlight-api-types';

type DataCallback<T = any> = (data: T) => void;
type ErrDataCallback<T = any, E = any> = (err: E | null, data: T | null) => void;

type SpotlightCallback<T extends keyof SpotlightMessaging> =
  DataCallback<GetResponse<SpotlightMessaging[T]>>;

type SpotlightErrCallback<T extends keyof SpotlightMessaging> =
  ErrDataCallback<GetResponse<SpotlightMessaging[T]>>;

type SpotlightApiKeys = keyof SpotlightApis;

export class MethodNotAvailable<K extends SpotlightApiKeys = SpotlightApiKeys> extends TypeError {
  public name = 'MethodNotAvailable';

  constructor(public method: K) {
    super(`Method "${method}" not available`);
  }
}

export class MethodNotEnabled<K extends SpotlightApiKeys = SpotlightApiKeys> extends TypeError {
  public name = 'MethodNotAvailable';

  constructor(public method: K) {
    super(`Method "${method}" not available`);
  }
}

interface ApiMethod<T extends any[] = any[]> {
  (...args: T): void;
  isAvailable(): boolean;
}

export function checkMethodAvailability(method: SpotlightApiKeys): boolean {
  try {
    return typeof window.external[method] !== 'undefined';
  } catch {
    return false;
  }
}

function createApiMethod<
  M extends SpotlightApiKeys,
  T extends any[]
>(
  method: M,
  transformArgs: (...args: T) => Parameters<SpotlightApis[M]>,
  {
    runAfter,
    runOnError = (e) => { throw e; },
    featureAvailable = true,
  }: {
    runAfter?: () => void;
    runOnError?: (error: Error) => void;
    featureAvailable?: boolean;
  } = {},
): ApiMethod<T> {
  // eslint-disable-next-line complexity
  const apiMethod: ApiMethod<T> = (...args: T) => {
    const innerArgs = transformArgs(...args);
    try {
      if (!featureAvailable) {
        throw new MethodNotEnabled(method);
      }

      switch (innerArgs.length) {
        case 0:
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.external[method]();
          break;
        case 1:
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.external[method](innerArgs[0]);
          break;
        case 2:
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.external[method](innerArgs[0], innerArgs[1]);
          break;
        case 3:
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          window.external[method](innerArgs[0], innerArgs[1], innerArgs[2]);
          break;
        default:
          break;
      }
    } catch (e) {
      let err = e;
      if (e.message.indexOf(`'${method}'`) && !(e instanceof MethodNotEnabled)) {
        err = new MethodNotAvailable(method);
      }

      runOnError(err);
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    runAfter?.();
  };

  apiMethod.isAvailable = () => featureAvailable && checkMethodAvailability(method);

  return apiMethod;
}

export const openURL = createApiMethod('OpenUrl', (url: string) => [url]);

export const closeHtml = createApiMethod('CloseHtml', (info: string) => [info]);

export function routeHelper(route: string): string {
  if (!config.spotlight) {
    return route;
  }

  let baseURL = '';
  if (config.localBuild) {
    baseURL = window.location.origin;
  } else {
    baseURL = config.hostConfig.dashboardHost;
  }

  return new URL(route, baseURL).toString();
}

export const showRegisterPage = createApiMethod('ShowRegisterPage', () => [], {
  runOnError(e) {
    if (e instanceof MethodNotAvailable) {
      openURL(config.hostConfig.myAviraHost);
    }
  },
});

export const showPlans = createApiMethod(
  'ShowPlans', () => [],
);

export const onLanguageChanged = createApiMethod(
  'OnLanguageChanged',
  (callback: (err: string | null, language: string) => void) => [callback],
);

export const onColorSchemeChanged = createApiMethod(
  'OnColorSchemeChanged',
  (callback: (err: string | null, colorScheme: string) => void) => [callback],
);

export const onUserChanged = createApiMethod(
  'OnUserChanged',
  (callback: (err: string | null, data: string | null) => void) => [callback],
);

export const onUserOnboarded = createApiMethod(
  'OnUserOnboarded',
  (callback: (err: string | null, data: string | null) => void) => [callback],
  { featureAvailable: config.spotlightOnboarding },
);

export const registerEventListener = createApiMethod(
  'RegisterEventListener',
  (eventName: string, callback: DataCallback<string | null>) => [eventName, callback],
);

export const syncAuthData = createApiMethod(
  'SyncAuthData',
  (data: AuthData) => [JSON.stringify(data)],
  { featureAvailable: config.spotlightOnboarding && config.spotlightSync },
);

export const getAuthData = createApiMethod(
  'GetAuthData',
  (
    userId: string,
    callback: SpotlightErrCallback<'GetAuthData'>,
  ) => [userId, (err, data) => callback(err, JSON.parse(data))],
  { featureAvailable: config.spotlightOnboarding && config.spotlightSync },
);

export const setLastActivity = createApiMethod(
  'SetLastActivity',
  (userId: string, activity: number) => [userId, activity.toString()],
  { featureAvailable: config.spotlightOnboarding },
);

export const getLastActivity = createApiMethod(
  'GetLastActivity',
  (
    userId: string,
    callback: SpotlightErrCallback<'GetLastActivity'>,
  ) => [userId, (err, data) => callback(err, JSON.parse(data))],
  { featureAvailable: config.spotlightOnboarding },
);

export const setAutolock = createApiMethod(
  'SetAutolock',
  (userId: string, autolock: { value: number; timestamp: number }) => (
    [userId, JSON.stringify(autolock)]
  ),
);

export const getAutolock = createApiMethod(
  'GetAutolock',
  (
    userId: string,
    callback: SpotlightErrCallback<'GetAutolock'>,
  ) => [userId, (err, data) => callback(err, JSON.parse(data))],
);

type GetSpotlightUserOnboardingStatusResponse = {
  activated: boolean;
  connected: boolean;
  supportedBrowsersAvailable: boolean;
};

export const getSpotlightUserOnboardingStatus = createApiMethod(
  'GetSpotlightUserOnboardingStatus',
  (
    userId: string,
    callback: ErrDataCallback<GetSpotlightUserOnboardingStatusResponse>,
  ) => [
    userId,
    (err, data) => callback(err, (data ? JSON.parse(data) : null)),
  ],
  { featureAvailable: config.spotlightOnboarding },
);

export const setSpotlightUserActivated = createApiMethod(
  'SetSpotlightUserActivated',
  (userId: string, isActivated: boolean) => [userId, isActivated.toString()],
  { featureAvailable: config.spotlightOnboarding },
);

export const showBrowserExtensions = createApiMethod(
  'ShowBrowserExtensions',
  (userId: string | null) => [userId],
  { featureAvailable: config.spotlightOnboarding },
);

export const setPasswordManagerDistinctId = createApiMethod(
  'SetPasswordManagerDistinctId',
  (distinctId: string) => [distinctId],
  { featureAvailable: config.spotlightOnboarding },
);
