import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';
import Dropdown from 'pwm-components/components/DropdownMenu/DropdownMenu';
import { DropdownMenuItem } from 'pwm-components/components/DropdownMenu';
import TextButton from 'pwm-components/components/TextButton';

type HTMLLinkAttr = React.HTMLProps<HTMLAnchorElement>;

type ButtonAttr = React.ComponentProps<typeof TextButton>;

type LinkAttr = LinkProps;

export type MenuItem = {
  labelId: string;
  menuItemAttrs: HTMLLinkAttr | ButtonAttr | LinkAttr;
}

export type Props = {
  showDropdownMenu?: boolean;
  actionMenu?: boolean;
  menuItems: MenuItem[];
}

const DropdownMenu: React.FC<Props> = ({
  showDropdownMenu = false,
  menuItems = [],
  actionMenu = false,
}) => (
  <Dropdown
    hidden={!showDropdownMenu}
    actionMenu={actionMenu}
    arrowSize={3}
  >
    {
      menuItems.map(({ labelId, menuItemAttrs }) => (
        <DropdownMenuItem
          key={labelId}
          variant="dark"
        >
          { menuItemAttrs && menuItemAttrs.href && (
            <a
              role="button"
              tabIndex={0}
              {...menuItemAttrs as HTMLLinkAttr}
              style={{ color: 'inherit' }}
            >
              <FormattedMessage id={labelId} />
            </a>
          )}
          { menuItemAttrs && menuItemAttrs.onClick && (
            <TextButton
              tabIndex={0}
              {...menuItemAttrs as ButtonAttr}
              variant="dark"
              className="a-dropdown-menu-item"
            >
              <FormattedMessage id={labelId} />
            </TextButton>
          )}
          { menuItemAttrs && (menuItemAttrs as LinkAttr).to && (
            <Link {...menuItemAttrs as LinkAttr}>
              <TextButton variant="dark">
                <FormattedMessage id={labelId} />
              </TextButton>
            </Link>
          )}
        </DropdownMenuItem>
      ))
    }
  </Dropdown>
);

export default DropdownMenu;
