import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Loading from '../../app/components/Loading';
import nlokHelper from '../helper';

const VaultConfigWrapper: React.FC = ({ children }) => {
  const machineGUID = useSelector<RootState, string | null>(({ nlokData }) => nlokData.machineGuid);

  const [configured, setConfigured] = useState(false);

  useEffect(() => {
    async function init(): Promise<void> {
      if (machineGUID) {
        await nlokHelper.configureVault(machineGUID);
        setConfigured(true);
      }
    }

    init();
  }, [machineGUID]);

  if (!configured) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default VaultConfigWrapper;
