export const BACKUP_DATASETS = 'BACKUP_DATASETS';

// TODO: type should be in pwm-user-data close to CognitoAdapter
export type Dataset = {
  createdAt: string;
  key: string;
  records: number;
};

export interface BackupDatasets {
  type: typeof BACKUP_DATASETS;
  value: Dataset[];
}

export const CLEAR_BACKUP_DATASETS = 'CLEAR_BACKUP_DATASETS';

export interface ClearBackupDatasets {
  type: typeof CLEAR_BACKUP_DATASETS;
}

export const BACKUP_DATA = 'BACKUP_DATA';

export interface BackupData {
  type: typeof BACKUP_DATA;
  value: {
    accounts: any;
    accountsHistory: any;
    metadata: any;
  };
}

export const CLEAR_BACKUP_DATA = 'CLEAR_BACKUP_DATA';

export interface ClearBackupData {
  type: typeof CLEAR_BACKUP_DATA;
}
