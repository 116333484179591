import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bool, func } from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Login from './Login';
import {
  loginOEUser,
  authDropout,
  trackAuthShown,
  trackInvalidOtp,
  trackResendOtp,
  getOEUserData,
  registerLoginOEUserWithSocialMedia,
  trackLoginOEUserError,
} from '../OEActions';
import { authUpdateEmail, logoutUser } from '../../authentication/AuthenticationActions';
import SpotlightMasterPasswordRedirect from '../../user/components/SpotlightMasterPasswordRedirect';
import { showGetProFree as showGetProFreeSelector } from '../../user/selectors';
import UnlockProBanner from '../../componentLib/UnlockProBanner';
import AuthPageWrapper from './AuthPageWrapper';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import { AuthPageContainer } from '../../authentication';
import { trackEvent } from '../../tracking/TrackingActions';
import { MP_OE_LOGIN_INITIATED } from '../../tracking/MixpanelEvents';
import EmbargoError from '../../app/components/EmbargoError';
import UserIdentity from './UserIdentity';


const DashLogin = ({ showGetProFree, onDropOut, ...loginProps }) => {
  const [showHeaderMenu, setShowHeaderMenu] = useState(true);

  const [loginState, setLoginState] = useState(null);
  const [error, setError] = useState(undefined);
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();

  const onLoginSubmit = async (login) => {
    dispatch(trackEvent(MP_OE_LOGIN_INITIATED, { context: loginProps.context }));
    setLoginState(login);

    try {
      return await dispatch(loginOEUser(login));
    } catch (e) {
      setError(e);
      if (e.key === 'error.login.missingUserInfo') {
        setStep(1);
      }

      throw e;
    }
  };

  const onUserIdSubmit = async ({ firstName, lastName, country }) => {
    try {
      if (loginState != null) {
        return await dispatch(
          loginOEUser({
            ...(loginState ?? {}),
            updateUserInfo: true,
            firstName,
            lastName,
            country,
          }),
        );
      }
    } catch (e) {
      setError(e);
      if (e.status !== '451') {
        setStep(0);
        throw e;
      }
    }

    return undefined;
  };

  let Page;

  // eslint-disable-next-line no-restricted-globals
  if (error?.status === '451') {
    Page = (
      <EmbargoError
        message={(
          <FormattedMessage
            id="embargo.error.description.user"
          />
        )}
      />
    );
  } else if (step === 0) {
    Page = (
      <Login
        {...loginProps}
        onFormSubmit={onLoginSubmit}
        onOtpRequired={() => { setShowHeaderMenu(false); }}
        onLogoutClicked={() => { setShowHeaderMenu(true); }}
        onForgotPasswordClicked={() => {
          onDropOut({
            currentForm: 'login',
            cause: 'forgot password',
            mountTimestamp: Date.now(),
          });
        }}
      />
    );
  } else {
    Page = (
      <UserIdentity
        {...loginProps}
        onFormSubmit={onUserIdSubmit}
        mainCtaLabel={(
          <FormattedMessage
            id="login.action.register"
            defaultMessage="Register"
          />
        )}
        onBackClick={() => setStep(0)}
      />
    );
  }

  return (
    <AuthPageWrapper
      banner={showGetProFree ? <UnlockProBanner /> : null}
      Page={Page}
      showHeaderMenu={showHeaderMenu}
      loginDropOut={() => {
        onDropOut({
          currentForm: 'login',
          cause: 'switched to register',
          mountTimestamp: Date.now(),
        });
      }}
    />
  );
};

DashLogin.propTypes = {
  showGetProFree: bool.isRequired,
  onDropOut: func.isRequired,
};


const LoginContainer = props => (
  <>
    <ConfigRenderer condition="spotlight">
      <AuthPageContainer>
        <SpotlightMasterPasswordRedirect />
      </AuthPageContainer>
    </ConfigRenderer>
    <ConfigRenderer condition="spotlight" negate>
      <DashLogin {...props} />
    </ConfigRenderer>
  </>
);

LoginContainer.propTypes = {
  showGetProFree: bool.isRequired,
  onDropOut: func.isRequired,
};


const mapStateToProps = (state) => {
  const {
    oe, auth, user, preferences,
  } = state;
  return {
    oe,
    email: auth.email,
    user,
    language: preferences.language,
    showGetProFree: showGetProFreeSelector(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  getOEUserData,
  onDropOut: authDropout,
  onComponentShown: trackAuthShown,
  onInvalidOtp: trackInvalidOtp,
  onResendOtp: trackResendOtp,
  registerLoginOEUserWithSocialMedia,
  onEmailUpdate: authUpdateEmail,
  logoutUser,
  onSocialMediaError: trackLoginOEUserError,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginContainer);
