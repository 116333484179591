import React from 'react';
import Video from '../Video';

interface Props {
  onCompleted: () => void;
  language: string;
}

const HowDoesItWorkVideo: React.FC<Props> = ({ onCompleted, language }) => {
  const videoId = language === 'de' ? 'M9aMKpMDfN8' : 'dgyJyPwxbr4';

  return (
    <Video
      videoId={videoId}
      language={language}
      onCompleted={onCompleted}
    />
  );
};

export default HowDoesItWorkVideo;
