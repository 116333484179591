import React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { withTooltip } from 'pwm-components/components/Tooltip';

import { truncateMiddle } from '../helpers/FileNameHelper';
import {
  getIconFromStatus, getMessageFromStatus, isStatusResolved, isStatusResolvable, FileStatusEnum,
} from '../helpers/FileStatusHelper';
import { getFileTypeByExtension, getIconByFileType } from '../helpers/FileTypeHelper';

const THUMBNAIL_NAME_MAX_LENGTH = 15;

export type Props = WrappedComponentProps & {
  id?: string;
  srcBase64?: string;
  progress?: number;
  name?: string;
  type?: string;
  status?: FileStatusEnum;
  onClick?: () => void;
};

const ThumbnailBackground = styled('div')`
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
  background-color: ${({ theme: { mode, colors } }) => (mode === 'light' ? colors.alabaster : colors.darkslategrey)};
`;

const PhotoThumbnail = styled('div') <{ srcBase64?: string }>`
  width: 100px;
  height: 100px;
  background-size: cover;
  background-image: url(data:image/jpg;base64,${({ srcBase64 }) => srcBase64});
  ${({ srcBase64 }) => (srcBase64 && srcBase64.length > 0 ? `
    opacity: 1;
    filter: none;
  ` : `
    opacity: 0;
    filter: blur(10px);
  `)}
  transition: filter ${({ theme: { transition } }) => transition.duration.short};
`;

const StatusIconContainer = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProgressOverlaySVG = styled('svg')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
  transform: rotate(90deg) scale(-1,1);
`;

const ProgressPie = styled('circle').attrs(({ progress }: { progress: number }) => ({
  style: {
    strokeDasharray: `${(2 * Math.PI * 50) * (1 - progress)}px ${2 * Math.PI * 50}px`,
    strokeOpacity: progress > 0 ? 0.8 : 0,
  },
}))<{ progress: number }>`
  r: 50px;
  cx: 50px;
  cy: 50px;
  fill: transparent;
  stroke-width: 100px;
  transition: stroke-dasharray 1s;
  stroke: ${({ theme: { semanticColors } }) => semanticColors.foreground};
`;

const NameContainer = styled('div')`
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme: { semanticColors } }) => (semanticColors.foreground)};
`;

const ThumbnailContainer = styled('div')`
  width: 100px;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
`;

const ThumbnailContainerWithTooltip = withTooltip(ThumbnailContainer);

const FileThumbnail: React.FC<Props> = ({
  id,
  intl,
  srcBase64 = '',
  progress = 1.0,
  name = '',
  type = '',
  status = FileStatusEnum.Downloaded,
  onClick,
}) => {
  const fileName = `${name}.${type.toLowerCase()}`;
  const fileType = getFileTypeByExtension(type);
  const FileTypeIcon = getIconByFileType(fileType);
  const StatusIcon = getIconFromStatus({
    status, defaultShowWrapper: false, defaultIcon: FileTypeIcon,
  });

  return (
    <ThumbnailContainerWithTooltip
      id={id}
      onClick={onClick}
      tooltipContent={(
        <Box display="flex" flexDirection="row">
          <Paragraph>
            <strong>{fileName}</strong>
            {isStatusResolved(status) ? '' : `: ${getMessageFromStatus(status, intl)}`}
          </Paragraph>
        </Box>
      )}
    >
      <ThumbnailBackground>
        <PhotoThumbnail srcBase64={srcBase64} />
        {srcBase64.length === 0 && (
          <StatusIconContainer>
            {StatusIcon}
          </StatusIconContainer>
        )}
        <ProgressOverlaySVG>
          <ProgressPie progress={isStatusResolvable(status) ? progress : 1.0} />
        </ProgressOverlaySVG>
        <svg />
      </ThumbnailBackground>
      <NameContainer>
        <Paragraph size="mini" variant="link">
          {truncateMiddle(fileName, THUMBNAIL_NAME_MAX_LENGTH)}
        </Paragraph>
      </NameContainer>
    </ThumbnailContainerWithTooltip>
  );
};

export default injectIntl(FileThumbnail);
