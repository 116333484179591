import { AUC_ADD_RESULTS } from './SecurityStatusActionTypes';

function getInitialState() {
  return {};
}

export default function aucReducer(state = getInitialState(), action) {
  switch (action.type) {
    case AUC_ADD_RESULTS:
      return action.value.reduce((p, q) => ({ ...p, [q.url]: q.category }), state);
    default:
      return state;
  }
}
