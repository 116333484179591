import React, { useCallback, useContext } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import Button from 'pwm-components/components/Button';

import * as SpotlightAPI from '../../lib/SpotlightAPI';
import iconInfo1x from '../../img/icon-info-blue@1x.png';
import iconInfo2x from '../../img/icon-info-blue@2x.png';
import config from '../../config';
import SearchParamsContext from '../../app/components/SearchParamsContext';

import Card from '../../componentLib/CardWrapper';

enum AviraLogin {
  BuiltIn = 'builtin',
}

const SpotlightSignInRedirectBanner: React.FC = () => {
  const { 'avira-login': aviraLogin } = useContext(SearchParamsContext);

  const showRegisterPage = useCallback(() => {
    if (aviraLogin === AviraLogin.BuiltIn) {
      SpotlightAPI.showRegisterPage();
    } else {
      SpotlightAPI.openURL(config.hostConfig.myAviraHost);
    }
  }, [aviraLogin]);

  return (
    <Box position="relative" m="l" mt="xxxl" mb="0">
      <Card p="s" variant="secondary">
        <Grid align="middle">
          <Column size="auto">
            <img
              src={iconInfo1x}
              srcSet={`${iconInfo1x} 1x, ${iconInfo2x} 2x`}
              alt="Sign In Banner"
            />
          </Column>
          <Column>
            <Box
              display="flex"
              flexDirection="column"
            >
              <Paragraph size="h2" mb="xs" noWrap>
                <FormattedMessage
                  id="masterPassword.redirect.alreadyAnUser"
                  defaultMessage="Already an Avira Password Manager user?"
                />
              </Paragraph>
              <Paragraph noWrap>
                <FormattedHTMLMessage
                  id="masterPassword.redirect.subheading"
                  defaultMessage="<strong>Sign in</strong> with your Avira account and enter your Master Password"
                />
              </Paragraph>
            </Box>
          </Column>
          <Column size="auto">
            <Button
              onClick={showRegisterPage}
            >
              <FormattedMessage
                id="dashboard.spotlight.banner.login"
                defaultMessage="Log in"
              />
            </Button>
          </Column>
        </Grid>
      </Card>
    </Box>
  );
};

export default SpotlightSignInRedirectBanner;
