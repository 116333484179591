import { createSelector } from 'reselect';
import sumBy from 'lodash/sumBy';

import { getAccountSecurityStatus, getReusedPasswords } from './accountSelector';
import { getSuggestedUsernames, getUsernamesToSkip } from '../componentLib/selectors';
import { HIBP_CHECKS_INTERVAL_MS } from './constants';
import { checkHibpUsernames } from '../user/selectors';

const isInsecure = accountStatus => accountStatus.reused
  || accountStatus.unsafeWebsite
  || accountStatus.breached
  || accountStatus.passwordStrength;

const isToImprove = accountStatus => isInsecure(accountStatus) && !accountStatus.ignoreWarnings;

export const getNextHibpUsernameToCheck = createSelector(
  getSuggestedUsernames,
  getUsernamesToSkip,
  state => state.hibp.usernames,
  (usernames, usernamesToSkip, hibpUsernames) => {
    for (let i = 0; i < usernames.length; i += 1) {
      if (!usernamesToSkip[usernames[i]] && (!hibpUsernames[usernames[i]]
        || Date.now() - hibpUsernames[usernames[i]].lastChecked >= HIBP_CHECKS_INTERVAL_MS)) {
        return usernames[i];
      }
    }
    return null;
  },
);

export const getAllBreachesLoaded = createSelector(
  getNextHibpUsernameToCheck,
  state => state.aviraBreaches.loaded,
  state => state.auc,
  state => state.hibp.breachedWebsites,
  checkHibpUsernames,
  // eslint-disable-next-line max-params
  (nextUsername, aviraBreachesLoaded, auc, hibpBreaches, breachCheck) => (
    !(breachCheck && nextUsername)
    && aviraBreachesLoaded
    && Object.keys(auc).length !== 0
    && Object.keys(hibpBreaches).length !== 0
  ),
);

export const getUsernameBreachesLoadedPercent = createSelector(
  getSuggestedUsernames,
  getUsernamesToSkip,
  state => state.hibp.usernames,
  checkHibpUsernames,
  state => state.hibp.stopHibpPolling,
  // eslint-disable-next-line max-params
  (usernames, usernamesToSkip, hibpUsernames, breachCheck, stopHibpPolling) => (
    breachCheck && !stopHibpPolling
      ? Math.round((Object.keys(hibpUsernames).length
        / (usernames.length - Object.keys(usernamesToSkip).length)) * 100)
      : 100
  ),
);

export const getMetadata = createSelector(
  getAccountSecurityStatus,
  // eslint-disable-next-line max-statements
  (status) => {
    const accountIds = Object.keys(status);

    const sumAccountsBy = func => sumBy(accountIds, id => func(status[id]));
    const countAccountsBy = func => sumAccountsBy(acc => (func(acc) ? 1 : 0));
    const countNotIgnoredAccountsBy = func => (
      countAccountsBy(acc => !acc.ignoreWarnings && !acc.fakeAccount && func(acc))
    );
    const someAccounts = func => accountIds.some(id => func(status[id]));

    const fakeAccounts = countAccountsBy(acc => acc.fakeAccount);
    const allAccounts = accountIds.length - fakeAccounts;

    const actualScore = sumAccountsBy(acc => !acc.ignoreWarnings
      && !acc.fakeAccount && acc.securityScore);
    const ignored = countAccountsBy(acc => acc.ignoreWarnings);

    const toImprove = countNotIgnoredAccountsBy(acc => isToImprove(acc));
    const toImproveExists = someAccounts(acc => isToImprove(acc));

    const reused = countNotIgnoredAccountsBy(acc => acc.reused);
    const reusedExists = someAccounts(acc => acc.reused);

    const breached = countNotIgnoredAccountsBy(acc => acc.breached);
    const breachedExists = someAccounts(acc => acc.breached);

    const passwordStrength = countNotIgnoredAccountsBy(acc => acc.passwordStrength);
    const passwordStrengthExists = someAccounts(acc => acc.passwordStrength);

    const unsafeWebsite = countNotIgnoredAccountsBy(acc => acc.unsafeWebsite);
    const unsafeWebsiteExists = someAccounts(acc => acc.unsafeWebsite);

    const auc = countNotIgnoredAccountsBy(acc => acc.auc);

    const scorePercent = Math.min(Number(actualScore
      && Math.round(actualScore / (allAccounts - ignored))), (toImprove ? 99 : 100));

    const result = {
      scorePercent,
      actualScore,
      allAccounts,
      ignored,
      reused,
      reusedExists,
      toImprove,
      toImproveExists,
      breached,
      breachedExists,
      passwordStrength,
      passwordStrengthExists,
      unsafeWebsite,
      unsafeWebsiteExists,
      auc,
      fakeAccounts,
    };
    return result;
  },
);

export const getSecurityStatus = createSelector(
  getAccountSecurityStatus,
  getMetadata,
  (accountsSecurityStatus, metadata) => ({ accountsSecurityStatus, metadata }),
);

export const getSecurityStatusTrackingData = createSelector(
  getSecurityStatus,
  state => state.userData,
  getReusedPasswords,
  getSuggestedUsernames,
  // eslint-disable-next-line max-params
  (securityStatus, { licenses, breach_check }, resusedPasswords, suggestedUsernames) => {
    const {
      accountsSecurityStatus,
      metadata: {
        scorePercent, allAccounts, toImprove, ignored,
        breached, unsafeWebsite, auc, passwordStrength, reused,
      },
    } = securityStatus;

    const breachedDomains = {
      aucDomains: [],
      breachedPasswordDomains: [],
      breachedPasswordDomainsCount: 0,
      breachedWebsiteDomains: [],
      breachedWebsiteDomainsCount: 0,
      breachedUserDomains: [],
      breachedUserCount: 0,
      ignoredDomains: [],
    };

    // eslint-disable-next-line complexity
    Object.values(accountsSecurityStatus).forEach((account) => {
      const {
        auc: aucAccount, hibpBreachedPassword, domain,
        aviraBreachedWebsite, hibpBreachedWebsite,
        hibpBreachedAccountAlert, hibpBreachedAccountWarning,
        ignoreWarnings,
      } = account;
      if (aucAccount && !ignoreWarnings && !breachedDomains.aucDomains.includes(domain)) {
        breachedDomains.aucDomains.push(domain);
      }
      if (hibpBreachedPassword && !ignoreWarnings) {
        if (!breachedDomains.breachedPasswordDomains.includes(domain)) {
          breachedDomains.breachedPasswordDomains.push(domain);
        }
        breachedDomains.breachedPasswordDomainsCount += 1;
      }
      if ((aviraBreachedWebsite || hibpBreachedWebsite) && !ignoreWarnings) {
        if (!breachedDomains.breachedWebsiteDomains.includes(domain)) {
          breachedDomains.breachedWebsiteDomains.push(domain);
        }
        breachedDomains.breachedWebsiteDomainsCount += 1;
      }
      if ((hibpBreachedAccountAlert || hibpBreachedAccountWarning) && !ignoreWarnings) {
        if (!breachedDomains.breachedUserDomains.includes(domain)) {
          breachedDomains.breachedUserDomains.push(domain);
        }
        breachedDomains.breachedUserCount += 1;
      }
      if (ignoreWarnings && !breachedDomains.ignoredDomains.includes(domain)) {
        breachedDomains.ignoredDomains.push(domain);
      }
    });

    const distinctPasswords = Object.keys(resusedPasswords).length;
    const distinctUsernames = Object.keys(suggestedUsernames).length;

    const trackingData = {
      // eslint-disable-next-line no-undef
      licenseType: licenses[0]?.type,
      securityStatusScore: scorePercent,
      totalAccounts: allAccounts,
      accountsToImprove: toImprove,
      breached,
      unsafeWebsite,
      auc,
      passwordStrength,
      reusedPasswords: reused,
      distinctPasswords,
      distinctUsernames,
      breachChecksEnabled: breach_check,
      ignored,
      ...breachedDomains,
    };

    return trackingData;
  },
);
