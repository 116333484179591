//
//     Symantec copyright header start
//
// Copyright © 2016, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2016, Symantec Corporation, All rights reserved.
//
// Tue 22 Jan kalpana_m adding to Git

import constants from './VTConstants';

const {
  isNil,
  isntNil,
  isString,
  isInteger
} = SymBfw.utils;
const { telemetryConstants } = SymBfw;
const {
  LOGIN_TYPE_SPOC,
  LOGIN_TYPE_LOCAL
} = constants;

class TelemetryWrapper {
  constructor() {
    this.IDS_CRYPTO_ERROR_CONSTANTS = {
      DIGEST_COMPARISON_FAILURE: 'Digest Comparison failure',
      TYPE_COMPARISON_FAILURE: 'Type comparison failure',
      CHECKSUM_COMPARISON_FAILURE: 'Checksum comparison failure',
      UNABLE_TO_ANSWER_CHALLENGE: 'Unable to answer challenge',
      UNABLE_TO_CALCULATE_CHECKSUM: 'Unable to calculate checksum',
      UNABLE_TO_DECRYPT_ITEM: 'Unable to decrypt item',
      UNABLE_TO_ENCRYPT_ITEM: 'Unable to encrypt item',
      UNABLE_TO_OBFUSCATE_ITEM: 'Unable to obfuscate item'
    };

    /**
     * @property  TELEMETRY_CATEGORY
     * @desc object containing IDSafe component names
     * @private
     */
    this.TELEMETRY_CATEGORY = {
      IDSCRYPTO: 'IDSCrypto',
      BROWSER: 'Browser',
      IDSAFE: 'IDSafe',
      LOGIN: 'Login',
      WAX: 'Wax',
      OPENID: 'OpenID',
      CCT: 'cct',
      IDSVAULT: 'IDSVault',
      PASSWORDCHANGER: 'PasswordChanger',
      WELCOME_NOTIFICATION: 'Welcome_Notification'
    };


    this.LOGIN_TYPE_SPOC = 'SPOC';
    this.LOGIN_TYPE_LOCAL = 'LOCAL';

    /**
     * @property  IDS_CRYPTO_ERROR_CONSTANTS
     * @desc constants containing IDSafe crypto error for telemetry
     * @private
     */


    /**
     * @property ACTION_TYPE
     * @desc map of action types available for IDSafe
     */
    this.ACTION_TYPE = {
      /**
       * @property BROWSER
       * @desc map of event actions available for Browser
       */
      BROWSER: {
        /**
         * @property LAUNCH
         * @desc constant to represent Browser launch actions
         */
        LAUNCH: 'launch',
        /**
         * @property UPDATE
         * @desc constant to represent Browser update actions
         */
        UPDATE: 'update'
      },
      /**
       * @property CCT
       * @desc map of event actions available for CCT
       */
      CCT: {
        /**
         * @property LAUNCH
         * @desc constant to represent CCT launch actions
         */
        LAUNCH: 'launch',
        /**
         * @property SUCCESS
         * @desc constant to represent CCT success actions
         */
        SUCCESS: 'success',

      },
      /**
       * @property IDSAFE
       * @desc map of event actions available for IDSafe
       */
      IDSAFE: {
        /**
         * @property UPDATE
         * @desc constant to represent IDSafe update actions
         */
        UPDATE: 'update',
      },

      /**
       * @property LOGIN
       * @desc map of event actions available for Login
       */
      LOGIN: {
        /**
         * @property ADD
         * @desc constant to represent Login add actions
         */
        ADD: 'add',
        /**
         * @property MODIFY
         * @desc constant to represent Login modify actions
         */
        MODIFY: 'modify',
        /**
         * @property DELETE
         * @desc constant to represent Login delete actions
         */
        DELETE: 'delete',
        /**
         * @property SEARCH
         * @desc constant to represent Login search actions
         */
        SEARCH: 'search',
        /**
         * @property SIGNIN
         * @desc constant to represent Signin actions
         * */
        SIGNIN: 'signin'
      },
      /**
       * @property OPENID
       * @desc map of event actions available for OpenID
       */
      OPENID: {
        /**
         * @property LAUNCH
         * @desc constant to represent OpenID launch actions
         */
        LAUNCH: 'launch',
        /**
         * @property LOGINSUCCESS
         * @desc constant to represent OpenID loginsuccess actions
         */
        LOGINSUCCESS: 'loginsuccess',

        /**
         * @property PAGECLOSED
         * @desc constant to represent OpenID pageclosed actions
         */
        PAGECLOSED: 'pageclosed',

      },

      /**
       * @property PASSWORDCHANGER
       * @desc map of event actions available for PASSWORDCHANGER
       */
      PASSWORDCHANGER: {
        /**
         * @property  AUTOCHANGEPASSWORD
         * @desc constant to AUTOCHANGEPASSWORD action
         */
        AUTOCHANGEPASSWORD: 'autochangepassword'

      },
      /**
       * @property IDSVAULT
       * @desc map of event actions available for IDSVault
       */
      IDSVAULT: {
        /**
         * @property MERGE
         * @desc constant to represent OpenID merge actions
         */
        MERGE: 'merge',
        /**
         * @property VAULTSTATISTICS
         * @desc constant to represent OpenID vaultstatistics actions
         */
        VAULTSTATISTICS: 'vaultstatistics',
        /**
         * @property LOGINURLCOUNT
         * @desc constant to represent OpenID loginurlcount actions
         */
        LOGINURLCOUNT: 'loginurlcount',
        /**
         * @property UPDATE
         * @desc constant to represent OpenID update actions
         */
        UPDATE: 'update',
        /**
         * @property CREATEVAULT
         * @desc constant to represent Vault Creation actions
         */
        CREATEVAULT: 'createvault',
        /**
         * @property DELETEVAULT
         * @desc constant to represent delete vault actions
         */
        DELETEVAULT: 'deletevault',
        /**
         * @property CHANGEVAULTPASSWORD
         * @desc constant to represent change vault password actions
         */
        CHANGEVAULTPASSWORD: 'changevaultpassword',
        /**
         * @property OPENVAULT
         * @desc constant to represent open vault actions
         */
        OPENVAULT: 'openvault'
      }
    };


    /**
     * @property OPENID_LABEL
     * @desc map of labels used by OPENID component
     */
    this.OPENID_LABEL = {
      /**
       * @property DELETEVAULT
       * @desc constant to represent OPENID actions in delete vault flow
       */
      DELETEVAULT: 'deletevault'
    };
    /**
     * @property CCT_LABEL
     * @desc map of labels used by CCT component
     */
    this.CCT_LABEL = {
      /**
       * @property CCTLAUNCH
       * @desc constant to launch label
       */
      CCTLAUNCH: 'cctLaunch',
      /**
       * @property CCTSUCCESS
       * @desc constant to represent CCT actions in success of cct flow
       */
      CCTSUCCESS: 'cctSuccess'
    };

    /**
     * @property IDSVAULT_LABEL
     * @desc map of labels used by IDSVAULT component
     */
    this.IDSVAULT_LABEL = {
      /**
       * @property  SIZE
       * @desc constant to size label
       */
      SIZE: 'size',
      /**
       * @property  AVAILABILITY
       * @desc constant to availability label
       */
      AVAILABILITY: 'availability',
      /**
       * @property  PASSWORD
       * @desc constant to password label
       */
      PASSWORD: 'password',
      /**
       * @property  PASSWORDHINT
       * @desc constant to passwordhint label
       */
      PASSWORDHINT: 'passwordhint',
      /**
       * @property  CREATEVAULTSUCCESS
       * @desc constant for create vault success
       */
      CREATEVAULTSUCCESS: 'createvaultsuccess',
      /**
       * @property  FAILEDTOCREATE
       * @desc constant for vault creation failure
       */
      FAILEDTOCREATE: 'failedtocreate',
      /**
       * @property  DELETEVAULTSUCCESS
       * @desc constant for create vault success
       */
      DELETEVAULTSUCCESS: 'deletevaultsuccess',
      /**
       * @property  FAILEDTODELETE
       * @desc constant for vault deletion failure
       */
      FAILEDTODELETE: 'failedtodelete',
      /**
       * @property  CHANGEVAULTPASSWORDFAILED
       * @desc constant for change vault password failure
       */
      CHANGEVAULTPASSWORDFAILED: 'changevaultpasswordfailed',
      /**
       * @property  CHANGEVAULTPASSWORDSUCCESS
       * @desc constant for change vault password failure
       */
      CHANGEVAULTPASSWORDSUCCESS: 'changevaultpasswordsuccess'
    };

    this.MIN_VALUE = 0;

    this.MAX_VALUE = 1;
  }

  /**
      * @function sendIDSCryptoError
      * @desc Sends telemetry parameters of category IDSCrypto and action Error
      * @param message {string} The error message to be sent
      * @param type {string} Type of error
      */
  sendIDSCryptoError(message, type) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSCRYPTO;

    this.checkStringIsValid(message);
    this.checkStringIsValid(type);

    this.send(EVENT_CATEGORY,
      type,
      message);
  }

  /**
      * @function createVaultFailure
      * @desc Sends telemetry parameters for create vault failure
      */
  createVaultFailure(state, additionalInfo) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.CREATEVAULT;
    const EVENT_LABEL = `${this.IDSVAULT_LABEL.FAILEDTOCREATE}(additionalInfo-${additionalInfo},state-${state})`;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      EVENT_LABEL);
  }

  /**
      * @function createVaultSuccess
      * @desc Sends telemetry parameters for create vault success
      */
  createVaultSuccess() {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.CREATEVAULT;
    const EVENT_LABEL = this.IDSVAULT_LABEL.CREATEVAULTSUCCESS;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      EVENT_LABEL);
  }

  /**
      * @function deleteVaultSuccess
      * @desc Sends telemetry parameters for delete vault success
      */
  deleteVaultSuccess() {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.DELETEVAULT;
    const EVENT_LABEL = this.IDSVAULT_LABEL.DELETEVAULTSUCCESS;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      EVENT_LABEL);
  }

  /**
      * @function deleteVaultFailure
      * @desc Sends telemetry parameters for delete vault failure
      */
  deleteVaultFailure(state, additionalInfo) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.DELETEVAULT;
    const EVENT_LABEL = `${this.IDSVAULT_LABEL.FAILEDTODELETE}(additionalInfo-${additionalInfo},state-${state})`;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      EVENT_LABEL);
  }

  /**
      * @function changeVaultPasswordFailure
      * @desc Sends telemetry parameters when failed to change the vault password
      */
  changeVaultPasswordFailure(state, additionalInfo) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.CHANGEVAULTPASSWORD;
    const EVENT_LABEL = `${this.IDSVAULT_LABEL.CHANGEVAULTPASSWORDFAILED}(additionalInfo-${additionalInfo},state-${state})`;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      EVENT_LABEL);
  }

  /**
      * @function changeVaultPasswordSuccess
      * @desc Sends telemetry parameters when failed to change the vault password
      */
  changeVaultPasswordSuccess() {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.CHANGEVAULTPASSWORD;
    const EVENT_LABEL = this.IDSVAULT_LABEL.CHANGEVAULTPASSWORDSUCCESS;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      EVENT_LABEL);
  }


  /**
      * @function sendLoginAdd
      * @desc Sends telemetry parameters of category Login and action add
      * @param type {string} The type of event (SPOC or LOCAL).
      * Will be appended to the event action LOGIN.ADD
      * @param value {number} Indicates number of added logins under SPOC.
      * Default will be 1 (for LOCAL)
      */
  sendLoginAdd(type, value = 1) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.LOGIN;

    if (type !== LOGIN_TYPE_SPOC && type !== LOGIN_TYPE_LOCAL) {
      throw new Error('type must be either SPOC or LOCAL');
    }

    const EVENT_ACTION = `${this.ACTION_TYPE.LOGIN.ADD}-${type}`;

    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      `${value}`);
  }

  /**
      * @function sendLoginModify
      * @desc Sends telemetry parameters of category Login and action modify
      * @param type {string} The type of event (SPOC or LOCAL).
      *  Will be appended to the event action LOGIN.MODIFY
      * @param value {number} Indicates number of updated logins under SPOC.
      * Default will be 1 (for LOCAL)
      */
  sendLoginModify(type, value = 1) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.LOGIN;
    if (type !== LOGIN_TYPE_SPOC && type !== LOGIN_TYPE_LOCAL) {
      throw new Error('type must be either SPOC or LOCAL');
    }

    const EVENT_ACTION = `${this.ACTION_TYPE.LOGIN.MODIFY}-${type}`;

    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      `${value}`);
  }

  /**
      * @function sendLoginDelete
      * @desc Sends telemetry parameters of category Login and action delete
      * @param type {string} The type of event (SPOC or LOCAL).
      * Will be appended to the event action LOGIN.DELETE
      * @param value {number} Indicates number of deleted logins under SPOC.
      * Default will be 1 (for LOCAL)
      */
  sendLoginDelete(type, value = 1) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.LOGIN;
    if (type !== LOGIN_TYPE_SPOC && type !== LOGIN_TYPE_LOCAL) {
      throw new Error('type must be either SPOC or LOCAL');
    }

    const EVENT_ACTION = `${this.ACTION_TYPE.LOGIN.DELETE}-${type}`;

    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      `${value}`);
  }

  /**
      * @function sendIDSVaultMerge
      * @desc Sends telemetry parameters of category IDSVault and action merge
      * @param message {string} The error message to be sent if failure occurs
      */
  sendIDSVaultMerge(message) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.MERGE;

    this.checkStringIsValid(message);

    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      message);
  }

  /**
      * @function sendIDSVaultStatistics
      * @desc Sends telemetry parameters of category IDSVault and action vaultstatistics
      * @param label {string} Either size or availability of vault.
      * Will be appended to Event Action IDSVAULT.VAULTSTATISTICS
      * @param value {integer} Represents if vault present or not present if label == "availability"
      * and represents number of login items in vault if label == "size"
      */
  sendIDSVaultStatistics(label, value) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;

    switch (label) {
      case this.IDSVAULT_LABEL.SIZE:
        this.checkValueInRange(value);
        break;
      case this.IDSVAULT_LABEL.AVAILABILITY:
        this.checkValueInRange(value, this.MIN_VALUE, this.MAX_VALUE);
        break;
      default:
        throw new Error("label must be either 'size' or 'availability'");
    }

    // Event action will be send as vaultstatistics-size or vaultstatistics-availability
    const EVENT_ACTION = `${this.ACTION_TYPE.IDSVAULT.VAULTSTATISTICS}-${label}`;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      `${value}`);
  }

  /**
      * @function sendIDSVaultLoginURLCount
      * @desc Sends telemetry parameters of category IDSVault and action loginurlcount
      * @param siteName {string} The site name for which the login count is performed. 
      * Will be appended to Event Action IDSVAULT.LOGINURLCOUNT
      * @param value {integer} The number of login items matching the domain <site name>
      */
  sendIDSVaultLoginURLCount(siteName, value) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;

    this.checkStringIsValid(siteName);
    this.checkValueInRange(value);

    const EVENT_ACTION = `${this.ACTION_TYPE.IDSVAULT.LOGINURLCOUNT}-${siteName}`;
    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      `${value}`);
  }

  /**
      * @function sendIDSVaultUpdate
      * @desc Sends telemetry parameters of category IDSVault and action update
      * @param label {string} either password (update password) or passwordhint (update hint)
      */
  sendIDSVaultUpdate(label) {
    const EVENT_CATEGORY = this.TELEMETRY_CATEGORY.IDSVAULT;
    const EVENT_ACTION = this.ACTION_TYPE.IDSVAULT.UPDATE;
    const PASSWORD_LABEL = this.IDSVAULT_LABEL.PASSWORD;
    const PASSWORD_HINT_LABEL = this.IDSVAULT_LABEL.PASSWORDHINT;

    if (label !== PASSWORD_LABEL && label !== PASSWORD_HINT_LABEL) {
      throw new Error("label must be either 'password' or 'passwordhint'");
    }

    this.send(EVENT_CATEGORY,
      EVENT_ACTION,
      label);
  }

  /**
       * @function send
       * @desc sends the telemetry
       * @param  {string|Error} category     the category of the event
       * @param  {string|Error} action       the action of the event
       * @param  {string|Error} label        the label of the event
       * @example example usage of send method
       * telemetryWrapper.send(telemetryWrapper.CATEGORY.WAX,
       *                       ACTION_TYPE.WAX.FORMSUBMIT,
       *                       telemetryWrapper.parameters.PAYLOAD);
       */
  send(category, action, label) {
    this.checkStringIsValid(category);
    this.checkStringIsValid(action);
    this.checkStringIsValid(label);

    if (!this.actionExists(category, action)) {
      throw new Error(`${category} does not have action ${action}.`);
    }

    const parameterList = {};
    parameterList[telemetryConstants.parameters.HIT_TYPE] = telemetryConstants.HIT_TYPE.EVENT;
    parameterList[telemetryConstants.parameters.CATEGORY] = category;
    parameterList[telemetryConstants.parameters.ACTION] = action;
    parameterList[telemetryConstants.parameters.LABEL] = label;

    // get the telemetry object based on context
    const telemetry = SymBfw.telemetry || SymBfw.telemetryProxy;
    telemetry.send(parameterList);
  }

  /**
       * @function checkValueInRange
       * @desc checks to make sure the value is within the range of min and max
       * @private
       * @param  {integer} value             The value to check
       * @param {number} min (OPTIONAL arg) The minimum number the value can be
       * @param {integer} max (OPTIONAL arg) The maximum number the value can be
       */
  checkValueInRange(value, min = 0, max) {
    if (!SymBfw.utils.isInteger(value)) {
      throw new Error('value must be an integer');
    }

    if (value < min) {
      throw new Error(`value must be greater than or equal to ${min}`);
    }

    if (isntNil(max) && value > max) {
      throw new Error(`value must be less than or equal to ${max}`);
    }
    return this;
  }

  /**
       * @function checkStringIsValid
       * @desc checks to make sure the value passed in is a valid string
       * @private
       * @param  {string} string     The value to be checked if it is a string
       */
  checkStringIsValid(string) {
    if (!isString(string)) {
      throw new Error(`${string} must be of type string`);
    }
    return this;
  }

  /**
       * @function actionExists
       * @desc makes sure that the telemetryWrapper category contains a specific action type
       * @private
       * @param  {string} category     The telemetryWrapper IDSafe category
       * @param {string} action        An IDSafe category's action
       */
  actionExists(category, action) {
  // check if the action is in crypto constants for crypto error category
    if (category === this.TELEMETRY_CATEGORY.IDSCRYPTO) {
      const cryptoConstants = this.IDS_CRYPTO_ERROR_CONSTANTS;
      for (const actionType in cryptoConstants) {
        if (cryptoConstants[actionType] === action) {
          return true;
        }
      }
      return false;
    }

    if (isNil(this.ACTION_TYPE[category.toUpperCase()])
              && isNil(telemetryConstants.actionType[category.toUpperCase()])) {
      return false;
    }

    const indexDivider = action.indexOf('-');
    let strippedAction = action;
    // verify just the action name if it is appended with a variable value
    if (indexDivider > -1) {
      strippedAction = action.substring(0, indexDivider);
    }

    if (isntNil(this.ACTION_TYPE[category.toUpperCase()])
    && isntNil(this.ACTION_TYPE[category.toUpperCase()][strippedAction.toUpperCase()])) {
      return true;
    }

    return !!(isntNil(telemetryConstants.actionType[category.toUpperCase()])
    && isntNil(
      telemetryConstants.actionType[category.toUpperCase()][strippedAction.toUpperCase()]
    ));
  }

  /**
      * @function sendLoginTelemetryFromSPOC
      * @desc Helper method to send telemetry events when logins added, updated, or deletedNodes
      * @param nodeStatusList {Object} Object with fields for number of added/updated/deleted
      *  nodes after SPOC bump
      */
  sendLoginTelemetryFromSPOC(nodeStatusList) {
    if (isNil(nodeStatusList)) {
      throw new Error('nodeStatusList is nil');
    }

    if (typeof nodeStatusList !== 'object') {
      throw new Error('nodeStatusList must be of type object');
    }

    const {
      added,
      updated,
      deleted
    } = nodeStatusList;


    if (!isInteger(added) || !isInteger(updated) || !isInteger(deleted)) {
      throw new Error('nodeStatusList addedNodes/updatedNodes/deleteNodes must be integers');
    }

    if (added > 0) {
      this.sendLoginAdd(LOGIN_TYPE_SPOC,
        added);
    }

    if (updated > 0) {
      this.sendLoginModify(LOGIN_TYPE_SPOC,
        updated);
    }

    if (deleted > 0) {
      this.sendLoginDelete(LOGIN_TYPE_SPOC,
        deleted);
    }
  }
}
const telemetryWrapper = new TelemetryWrapper();
export default telemetryWrapper;
