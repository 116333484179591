import React from 'react';
import ReactFileReader from 'react-file-reader';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ActionButton from '../../componentLib/ActionButton';
import Card from '../../componentLib/Card';
import intlShape from '../../lib/intlShape';

const ImportCsvCard = ({
  handleFiles, intl,
}) => (
  <Card
    title="dashboard.settings.import.title.fromCsv"
    className="u-fx-g-1 u-fx-s-1 u-fx-b-0"
    classNameTile="u-fx-1"
  >
    <div className="u-txt-left u-height-full u-fx u-fx-d-c u-fx-jc-sb">
      <div className="u-mb-xl">
        <FormattedMessage id="dashboard.settings.import.fromCsv" />
      </div>
      <div className="u-d-ib">
        <ReactFileReader
          handleFiles={handleFiles}
          fileTypes={['.csv', '.txt']}
        >
          <ActionButton
            type="button"
            block={false}
            label={intl.formatMessage({
              id: 'dashboard.settings.import.button.fromCsv',
            })}
          />
        </ReactFileReader>
      </div>
    </div>
  </Card>
);

ImportCsvCard.propTypes = {
  handleFiles: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ImportCsvCard);
