import React from 'react';
import { bool, number, string } from 'prop-types';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';

const GetStartedCardTile = styled(Box)`
  display: flex;
  height: 200px;
  align-items: center;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  padding: 80px 0;
  box-shadow: inset 0 -1px 1px 0 rgba(0,0,0,0.25);
`;

const tileColorsMap = {
  started: {
    bg: 'darkorange',
    name: 'light',
    score: 'light',
  },
  completed: {
    bg: 'white',
    name: 'success',
    score: 'success',
  },
  default: {
    bg: 'bermudagrey',
    name: 'light',
    score: 'disabled',
  },
};

const GetStartedTile = ({
  tileId,
  started,
  completed,
  maxPoints,
  points,
  browser,
  showProgress,
}) => {
  let colorScheme = tileColorsMap.default;

  if (started) {
    colorScheme = tileColorsMap[`${completed ? 'completed' : 'started'}`];
  }

  return (
    <GetStartedCardTile bgOverride={colorScheme.bg}>
      <Text style={{ position: 'relative' }} variant={colorScheme.name}>
        <FormattedMessage
          id={`dashboard.getStarted.${tileId}`}
          values={{
            browser,
          }}
        />
        <Box
          position="absolute"
          right="40%"
          mt="xxs"
        >
          { showProgress
            ? (
              <Text variant={colorScheme.score}>
                {' '}
                {points}
                {' '}
/
                {' '}
                {maxPoints}
                {' '}
              </Text>
            )
            : null}
        </Box>
      </Text>
    </GetStartedCardTile>
  );
};

GetStartedTile.propTypes = {
  tileId: string.isRequired,
  started: bool.isRequired,
  completed: bool.isRequired,
  maxPoints: number.isRequired,
  points: number.isRequired,
  browser: string.isRequired,
  showProgress: bool.isRequired,
};

export default GetStartedTile;
