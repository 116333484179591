import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { Fields, MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import Box from 'pwm-components/components/Box';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Input from 'pwm-components/components/Input';
import PasswordInput from 'pwm-components/components/PasswordInput';
import omit from 'lodash/omit';
import CopyButton from './CopyButton';
import FromDateToDateInput from './FromDateToDateInput';
import {
  getCardNumberValidator,
  PIN_INPUT_LENGTH,
  CVC_INPUT_LENGTH,
  removeChars,
} from '../../WalletHelpers';
import * as DataValidatorErrors from '../../../lib/DataValidator';
import CardnumberInput from './CardnumberInput';
import { CCUsedActions, trackCreditCardUsed, updateLastUsedAt } from '../../WalletActions';
import tabIndex from '../../../lib/TabIndexConfig';

interface Props extends Fields, WrappedComponentProps {
  id: string;
  addMode: boolean;
  changedKeys: Record<string, boolean>;
  changeHandler: (id: string, value: string) => void;
  onClick: (e: React.ChangeEvent<HTMLInputElement>) => void;
  getFieldError: (field: string) => string | null;
  onErrorsChange: (errors: Record<string, string>) => void;
}

const CONTEXT = 'details';

// eslint-disable-next-line complexity
const CreditCardInputFields: React.FC<Props> = (props) => {
  const {
    id,
    addMode,
    bank,
    changedKeys,
    changeHandler,
    cardNumber,
    cvc,
    cardPin,
    cardholderName,
    getFieldError,
    intl,
    validFromMonth,
    validFromYear,
    validToMonth,
    validToYear,
    onErrorsChange,
  } = props;

  const validator = useMemo(() => getCardNumberValidator(cardNumber), [cardNumber]);
  const dispatch = useDispatch();
  const [showCCNumberWarning, setShowCCNumberWarning] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setErrors(cardNumber ? omit(errors, 'cardNumber') : { ...errors, cardNumber: DataValidatorErrors.ERROR_REQUIRED });
    // Dependency not included to prevent a loop.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardNumber, validator.isValid]);

  useEffect(() => {
    onErrorsChange(errors);
  }, [errors, onErrorsChange]);

  const errorChangeHandler = useCallback((dateErrors: Record<string, string>): void => {
    setErrors(errors.hasOwnProperty('cardNumber') ? { ...dateErrors, cardNumber: DataValidatorErrors.ERROR_REQUIRED } : dateErrors);
  }, [errors]);

  const blurHandler = useCallback((): void => {
    setShowCCNumberWarning(changedKeys.cardNumber && !validator.isValid);
  }, [changedKeys.cardNumber, validator.isValid]);

  const focusHandler = useCallback((): void => {
    setShowCCNumberWarning(false);
  }, []);

  const onCardnumberChange = useCallback((value: string): void => {
    changeHandler('cardNumber', value);
  }, [changeHandler]);

  const copyTrackingHelper = useCallback((ccUsedAction: string): void => {
    if (id) {
      dispatch(trackCreditCardUsed(id, { action: ccUsedAction, context: CONTEXT }));
      dispatch(updateLastUsedAt(id));
    }
  }, [id, dispatch]);

  const copyCardnumber = useCallback(() => {
    copyTrackingHelper(CCUsedActions.cardNumber);
  }, [copyTrackingHelper]);

  const copyCvc = useCallback(() => {
    copyTrackingHelper(CCUsedActions.cvc);
  }, [copyTrackingHelper]);

  const copyCardholderName = useCallback(() => {
    copyTrackingHelper(CCUsedActions.cardholderName);
  }, [copyTrackingHelper]);

  const copyCardPin = useCallback(() => {
    copyTrackingHelper(CCUsedActions.cardPin);
  }, [copyTrackingHelper]);

  return (
    <Box display="flex" flexDirection="column" height="100%" alignContent="space-between">
      <Box mb="l">
        <Box mb="m">
          <CardnumberInput
            error={getFieldError('cardNumber') || ''}
            icons={[<CopyButton
              key="copyNumber"
              copyValue={cardNumber}
              onClick={copyCardnumber}
              tabIndex={tabIndex.optionalActionFields}
            />]}
            label={intl.formatMessage(
              { id: 'dashboard.wallet.details.cardNumber' },
            )}
            onBlur={blurHandler}
            onChange={onCardnumberChange}
            onFocus={focusHandler}
            placeholder={intl.formatMessage({
              id: 'dashboard.wallet.details.cardNumberPlaceholder',
              defaultMessage: 'e.g. 0123 4567 8910 1112',
            })}
            startVisible={addMode}
            tabIndex={tabIndex.mainFormFields}
            eyeIconTabIndex={tabIndex.optionalActionFields}
            validator={validator}
            value={cardNumber}
            warning={showCCNumberWarning
              ? intl.formatMessage({ id: 'dashboard.wallet.warning.cardNumber' })
              : ''}
          />
        </Box>
        <Box mb="m">
          <FromDateToDateInput
            validFromMonth={validFromMonth}
            validToMonth={validToMonth}
            validFromYear={validFromYear}
            validToYear={validToYear}
            changeHandler={changeHandler}
            errorChangeHandler={errorChangeHandler}
            getFieldError={getFieldError}
          />
        </Box>
        <Input
          autoComplete="off"
          icons={[<CopyButton
            key="copyName"
            onClick={copyCardholderName}
            copyValue={cardholderName || ''}
            tabIndex={tabIndex.optionalActionFields}
          />]}
          value={cardholderName}
          label={(
            <FormattedMessage
              id="dashboard.wallet.details.cardholderName"
              defaultMessage="Cardholder Name"
            />
          )}
          maxLength={MAX_LENGTHS.cardholderName}
          onChange={e => changeHandler('cardholderName', e.target.value)}
          placeholder={intl.formatMessage({
            id: 'dashboard.wallet.details.cardholderNamePlaceholder',
            defaultMessage: 'e.g. Max Mustermann',
          })}
          tabIndex={tabIndex.mainFormFields}
        />
      </Box>

      <Box display="flex" flexDirection="row" mb="s" width="320px">
        <Box mr="s" flex="1 1 auto" minWidth="0%">
          <PasswordInput
            value={cvc || ''}
            onChange={e => changeHandler('cvc', removeChars(e.target.value))}
            placeholder={intl.formatMessage({
              id: 'dashboard.wallet.details.cvcPlaceholder',
              defaultMessage: 'e.g. 123',
            })}
            icons={[<CopyButton
              key="copyCvc"
              onClick={copyCvc}
              copyValue={cvc || ''}
              tabIndex={tabIndex.optionalActionFields}
            />]}
            label={intl.formatMessage({
              id: 'dashboard.wallet.details.cvc',
            })}
            maxLength={CVC_INPUT_LENGTH}
            startVisible={addMode}
            tabIndex={tabIndex.mainFormFields}
            eyeIconTabIndex={tabIndex.optionalActionFields}
          />
        </Box>
        <Box mx="s" flex="0 0 auto" />
        <Box flex="1 1 auto" minWidth="0%">
          <PasswordInput
            value={cardPin || ''}
            onChange={e => changeHandler('cardPin', removeChars(e.target.value))}
            placeholder={intl.formatMessage({
              id: 'dashboard.wallet.details.cardPinPlaceholder',
              defaultMessage: 'e.g. 1234',
            })}
            icons={[<CopyButton
              key="copyCardPin"
              onClick={copyCardPin}
              copyValue={cardPin || ''}
              tabIndex={tabIndex.optionalActionFields}
            />]}
            label={intl.formatMessage(
              { id: 'dashboard.wallet.details.cardPin', defaultMessage: 'PIN' },
            )}
            maxLength={PIN_INPUT_LENGTH}
            startVisible={addMode}
            tabIndex={tabIndex.mainFormFields}
            eyeIconTabIndex={tabIndex.optionalActionFields}
          />
        </Box>
      </Box>

      <Box width="320px">
        <Input
          autoComplete="off"
          value={bank}
          label={(
            <FormattedMessage
              id="dashboard.wallet.details.bank"
              defaultMessage="Bank"
            />
          )}
          maxLength={MAX_LENGTHS.bank}
          onChange={e => changeHandler('bank', e.target.value)}
          placeholder={intl.formatMessage({
            id: 'dashboard.wallet.details.bankPlaceholder',
            defaultMessage: 'Enter the name of your bank',
          })}
          tabIndex={tabIndex.mainFormFields}
        />
      </Box>
    </Box>
  );
};

export default injectIntl(CreditCardInputFields);
