import { SET_ACTIVE_TAB } from './MyDataActionTypes';
import { TrackingActions, MixpanelEvents } from '../tracking';

const { trackEvent } = TrackingActions;

const {
  MP_SEARCH,
  MP_SEARCH_INTERACTION,
  MP_TAG_FILTER,
  MP_CREDENTIALS_LIST_DISPLAY,
  MP_NOTES_LIST_DISPLAY,
  MP_CREDITCARDS_LIST_DISPLAY,
} = MixpanelEvents;

export const setActiveTab = activeTab => ({ type: SET_ACTIVE_TAB, value: activeTab });

export const trackSearchInteraction = (index, resultsLength, tab) => (dispatch) => {
  dispatch(trackEvent(MP_SEARCH_INTERACTION, { Index: index, 'Results Length': resultsLength, context: tab }));
};

export const trackSearchUsed = () => (dispatch) => {
  dispatch(trackEvent(MP_SEARCH));
};

const TAGS_MAP = {
  is_favorite: 'favorite',
  favorite: 'favorite',
  all: 'all',
};

const DEFAULT_TAG_TRACK = 'custom';

export const trackTagsFilterClick = (tagType, reFilter, tab) => (dispatch) => {
  dispatch(trackEvent(MP_TAG_FILTER, {
    type: TAGS_MAP[tagType] ?? DEFAULT_TAG_TRACK,
    reFilter,
    context: tab,
  }));
};

// eslint-disable-next-line complexity
export const trackListDisplay = ({ source, destination }) => (dispatch) => {
  let event = null;
  let sourceName = '';

  switch (destination) {
    case '/mydata/passwords':
    case '/security-status':
      event = MP_CREDENTIALS_LIST_DISPLAY;
      sourceName = 'Credentials';
      break;
    case '/mydata/notes':
      event = MP_NOTES_LIST_DISPLAY;
      sourceName = 'Notes';
      break;
    case '/mydata/wallet':
      event = MP_CREDITCARDS_LIST_DISPLAY;
      sourceName = 'Wallet';
      break;
    default:
      break;
  }

  if (event) {
    let sourceAction = '';
    let actionHeader = 'close';
    if (source.includes('edit')) {
      sourceAction = 'Details';
    } else if (source.includes('new')) {
      sourceAction = 'New';
    } else {
      sourceName = '';
      actionHeader = 'mainMenu';
    }

    dispatch(trackEvent(event, { source: `${actionHeader}${sourceName}${sourceAction}` }));
  }
};
