import { DeepReadonly } from 'utility-types';

import { ACCOUNTS_PREFERENCES_SCROLLPOSITION, AccountsPreferencesScrollPosition } from './AccountActionTypes';

export type State = DeepReadonly<{
  scrollPosition: number;
}>;

function getInitialState(): State {
  return {
    scrollPosition: 0,
  };
}

type Actions = AccountsPreferencesScrollPosition;

export default function accountPreferencesReducer(
  state: State = getInitialState(),
  action: Actions,
): State {
  switch (action.type) {
    case ACCOUNTS_PREFERENCES_SCROLLPOSITION:
      return {
        ...state,
        scrollPosition: action.value,
      };
    default:
      return state;
  }
}
