import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Banner from '../../componentLib/Banner';
import phoneIcon from '../../img/ic-phone.png';
import { dismissGetMobileBanner } from '../../preferences/PreferencesActions';

const GetMobileBanner: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onStartInstallationClick = useCallback((): void => {
    history.push('/mobile/banner');
  }, [history]);

  const onBannerClose = useCallback(() => {
    dispatch(dismissGetMobileBanner());
  }, [dispatch]);

  return (
    <Box mt="-36px" mb="xl">
      <Banner
        size="small"
        onClose={onBannerClose}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            alignItems="center"
          >
            <img src={phoneIcon} alt="mobile" />
            <FormattedMessage id="dashboard.mobile.banner.title" defaultMessage="Install the mobile app and have your passwords always with you" />
          </Box>
          <Button
            onClick={onStartInstallationClick}
          >
            <FormattedMessage id="dashboard.mobile.banner.button" defaultMessage="Start installation" />
          </Button>
        </Box>
      </Banner>
    </Box>
  );
};

export default GetMobileBanner;
