import { useRef, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export function usePrevious<T>(value: T): T {
  const ref = useRef<T>(value);

  useEffect((): void => {
    ref.current = value;
  }, [value]);

  return ref.current;
}
