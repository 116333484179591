export const AUTH_NEW_TIMESTAMP = 'AUTH_NEW_TIMESTAMP';

export interface AuthNewTimestamp {
  type: typeof AUTH_NEW_TIMESTAMP;
}

export const AUTH_SET_TIMESTAMP = 'AUTH_SET_TIMESTAMP';

export interface AuthSetTimestamp {
  type: typeof AUTH_SET_TIMESTAMP;
  timestamp: number;
}

export const AUTH_CLEAR = 'AUTH_CLEAR';

export interface AuthClear {
  type: typeof AUTH_CLEAR;
}

export const AUTH_EMAIL = 'AUTH_EMAIL';

export interface AuthEmail {
  type: typeof AUTH_EMAIL;
  email: string;
}

export const AUTH_SET_DID_RELOAD = 'AUTH_SET_DID_RELOAD';

export interface AuthSetDidReload {
  type: typeof AUTH_SET_DID_RELOAD;
  didReload: boolean;
}
