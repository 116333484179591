import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { syncStateWithStorage } from '../SyncStateActions';
import { isInternetExplorer } from '../../lib/UserAgent';

class SyncState extends React.Component {
  constructor(props) {
    super(props);

    this.onStorageChange = this.onStorageChange.bind(this);
    this.storageChangeHandlerSet = null;
  }

  UNSAFE_componentWillMount() {
    if (!isInternetExplorer()) {
      window.addEventListener('storage', this.onStorageChange);
      this.storageChangeHandlerSet = true;
    }
  }

  componentWillUnmount() {
    if (this.storageChangeHandlerSet) {
      window.removeEventListener('storage', this.onStorageChange);
      this.storageChangeHandlerSet = null;
    }
  }

  onStorageChange(e) {
    if (e.isTrusted) {
      const { onStorageChange } = this.props;
      onStorageChange(e.key, e.newValue, e.oldValue);
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

SyncState.propTypes = {
  onStorageChange: PropTypes.func.isRequired,
  children: PropTypes.element,
};

SyncState.defaultProps = {
  children: <div />,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  onStorageChange: syncStateWithStorage,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SyncState);
