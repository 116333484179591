import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  clearHibpData,
  clearHibpUsernames,
  getHibpBreachedPasswords,
  getHibpBreachedUsernames,
  getPersistedHibpUsernames,
  loadHibpBreachedWebsites,
} from '../HIBPActions';
import {
  initAUC,
  loadAUC,
  trackSecurityStatusInitialize,
  updateIgnoreWarnings,
} from '../SecurityStatusActions';
import { loadAviraBreaches } from '../AviraBreachesActions';
import { buildPasswordBreaches } from '../PasswordsBreachesActions';
import SecurityStatusTracker from './SecurityStatusTracker';
import { getAllBreachesLoaded } from '../selectors';
import { checkHibpUsernames } from '../../user/selectors';

const mapStateToProps = (state) => {
  const { accounts } = state;
  return {
    accounts,
    breachCheck: checkHibpUsernames(state),
    breachesLoaded: getAllBreachesLoaded(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  buildPasswordBreaches,
  clearHibpData,
  clearHibpUsernames,
  getHibpBreachedPasswords,
  getHibpBreachedUsernames,
  getPersistedHibpUsernames,
  initAUC,
  loadAUC,
  loadAviraBreaches,
  loadHibpBreachedWebsites,
  trackSecurityStatusInitialize,
  updateIgnoreWarnings,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SecurityStatusTracker);
