import React from 'react';
import {
  bool, func, node, object, string,
} from 'prop-types';
import { constructValidUrl } from '@avira-pwm/helpers/url';

const AccountLink = React.memo(({
  children, className, data, disableLink, onClick,
}) => (
  (data.login_url || data.domain) && !disableLink
    ? (
      <a
        className={className}
        href={constructValidUrl(data.login_url || data.domain)}
        onClick={onClick}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    )
    : children
));

AccountLink.propTypes = {
  children: node.isRequired,
  className: string,
  data: object.isRequired,
  onClick: func,
  disableLink: bool,
};

AccountLink.defaultProps = {
  className: '',
  disableLink: false,
  onClick: () => {},
};

export default AccountLink;
