//
//     Symantec copyright header start
//
// Copyright © 2021, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2021, Symantec Corporation, All rights reserved.
//
// Change History :
// Thu 9 2021 kalpana_m adding to Git
//


import ItemManager from './VTItemManager';
import nodeSync from './VTNodeSync';
import vaultManager from './VTVaultManager';
import constants from './VTConstants';

const {
  utils: {
    isNil,
    isntNil,
    isEmptyObject,
    isSuccessCode,
    isArray
  },
  logger
} = SymBfw;

const { TAG, TAG_NODE } = constants;
const tagNodeMap = {
  [TAG.LOGIN]: TAG_NODE.LOGIN,
  [TAG.IDENTITY]: TAG_NODE.IDENTITY,
  [TAG.ADDRESS]: TAG_NODE.ADDRESS,
  [TAG.CREDIT_CARD]: TAG_NODE.CREDIT_CARD,
  [TAG.BANK_ACCOUNT]: TAG_NODE.BANK_ACCOUNT,
  [TAG.NOTE]: TAG_NODE.NOTE
};

/**
 * @typedef {import('./VTTag').default} Tag
 */

/**
 * @typedef {import('./VTTagItem').default} TagItem
 */

export default class TagManager extends ItemManager {
  /**
   * @constructor TagManager
   * @desc TagManager interface.
   */
  constructor() {
    super('TagManager');
    this.items = {};
  }


  /**
    * @function addTag
    * @desc This function will add new Tag
    * @param {Tag} tag tag object
    * @param {boolean} shouldSyncToCloud A boolean denoting
    * whether to sync the data to cloud or not. Defaults to true
    * */
  async addTag(tag, shouldSyncToCloud) {
    if (isEmptyObject(tag)) {
      throw new Error('tag must be an Object');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }
    const nodeList = [];
    const guid = tag.getPath();
    if (isNil(guid)) {
      logger.error('GUID is not found. Item has not been created correctly');
      throw new Error('GUID is not found. Item has not been created correctly');
    }
    this.items[guid] = tag;
    nodeList.push(tag.clone().serializeNode());
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }


  /**
    * @function addItemToTag
    * @desc This function will add new items into login,identity,address,creditCard,bankAccount,note nodes.
    * @param {string} tagGuid tag guid
    * @param {string} itemType item type
    * @param {string} itemGuid item guid
    * @param {boolean} shouldSyncToCloud A boolean denoting
    * whether to sync the data to cloud or not. Defaults to true
    * */
  async addItemToTag(tagGuid, itemType, itemGuid, shouldSyncToCloud) {
    if (isNil(tagGuid)) {
      logger.error('tag guid cannot be null');
      throw new Error('tag guid cannot be null');
    }
    if (isNil(itemType)) {
      logger.error('itemType cannot be null');
      throw new Error('itemType cannot be null');
    }
    if (isNil(itemGuid)) {
      logger.error('item guid cannot be null');
      throw new Error('item guid cannot be null');
    }
    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }
    const tag = this.items[tagGuid];
    if (isNil(tag)) {
      logger.error('tag is not found. tag has not been created correctly');
      throw new Error('tag is not found. tag has not been created correctly');
    }
    tag.addItem(tag, itemType, itemGuid);
    const nodeList = [];
    switch (itemType) {
      case TAG.LOGIN:
        if (tag.login.length !== 0) {
          const loginNode = this.createItemNode(tag.login, TAG_NODE.LOGIN);
          nodeList.push(loginNode);
        }
        break;
      case TAG.IDENTITY:
        if (tag.identity.length !== 0) {
          const identityNode = this.createItemNode(tag.identity, TAG_NODE.IDENTITY);
          nodeList.push(identityNode);
        }
        break;
      case TAG.ADDRESS:
        if (tag.address.length !== 0) {
          const addressNode = this.createItemNode(tag.address, TAG_NODE.ADDRESS);
          nodeList.push(addressNode);
        }
        break;
      case TAG.CREDIT_CARD:
        if (tag.creditCard.length !== 0) {
          const creditCardNode = this.createItemNode(tag.creditCard, TAG_NODE.CREDIT_CARD);
          nodeList.push(creditCardNode);
        }
        break;
      case TAG.BANK_ACCOUNT:
        if (tag.bankAccount.length !== 0) {
          const bankAccountNode = this.createItemNode(tag.bankAccount, TAG_NODE.BANK_ACCOUNT);
          nodeList.push(bankAccountNode);
        }
        break;
      case TAG.NOTE:
        if (tag.note.length !== 0) {
          const noteNode = this.createItemNode(tag.note, TAG_NODE.NOTE);
          nodeList.push(noteNode);
        }
        break;
      default:
        break;
    }

    if (isNil(nodeList)) {
      return;
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }


  /**
    * @function createItemNode
    * @desc This function will consolidate all array items and convert it a single tag item node.
    * @param {TagItem[]} itemArray Array of tag item
    * @param {string} nodeId
    * whether to sync the data to cloud or not. Defaults to true
    * */
  createItemNode(itemArray, nodeId) {
    if (itemArray.length === 0) {
      logger.error('Item Array cannot be null');
      throw new Error('Item Array cannot be null');
    }
    const itemMap = itemArray.map((item) => {
      const guid = item.getPath();
      if (isNil(guid)) {
        logger.error('GUID is not found. Item has not been created correctly');
        throw new Error('GUID is not found. Item has not been created correctly');
      }
      item.setItemNode(nodeId);
      return item.clone().serializeNode();
    });
    if (itemMap.length != 0) {
      for (const index in itemMap) {
        if (index != 0) {
          itemMap[0].values.push(itemMap[index].values[0]);
        }
      }
    }
    return itemMap[0];
  }

  /**
    ** @function getAllItems
    ** @desc gets all the items in tag object
    * */
  getAllItems() {
    const tagItems = vaultManager.getItemManager(vaultManager.TAG_ITEM_MANAGER).getAllItems();
    for (const [tagGuid, tagItem] of Object.entries(tagItems)) {
      if (isntNil(this.items[tagGuid])) {
        this.items[tagGuid].login = tagItem.login;
        this.items[tagGuid].identity = tagItem.identity;
        this.items[tagGuid].address = tagItem.address;
        this.items[tagGuid].creditCard = tagItem.creditCard;
        this.items[tagGuid].bankAccount = tagItem.bankAccount;
        this.items[tagGuid].note = tagItem.note;
      }
    }
    return this.items;
  }

  /**
    ** @function getAllTags
    ** @desc gets all the items in tag object
    * */
  getAllTags() {
    return this.getAllItems();
  }

  /**
   * @private
   * @function _findAndRemoveTagItem
   * @desc removes item guid from tagItem Array and convert to node format
   * @param {string} itemGuid guid of the item
   * @param {TagItem[]} tagItemArray tag Item Array
   * @param {string} nodeId tag Item Array
   * @returns {any} - item in node format
  * */
  _findAndRemoveTagItem(itemGuid, tagItemArray, nodeId) {
    let nodeList = null;
    if (tagItemArray.length > 1) {
      for (const i in tagItemArray) {
        if (tagItemArray[i].getTagItem() === itemGuid) {
          tagItemArray.splice(i, 1);
          nodeList = this.createItemNode(tagItemArray, nodeId);
        }
      }
    } else if (tagItemArray[0].getTagItem() === itemGuid) {
      nodeList = tagItemArray[0].serializeNode(true);
      tagItemArray.splice(0, 1);
    }
    return nodeList;
  }

  /**
    * @function _removeEmptyTag
    * @desc checks if the tag has no items and remove emtries if its empty
    * @param {string} tagGuid tag guid
    * @returns {boolean}
    */
  _removeEmptyTag(tagGuid) {
    const tag = this.items[tagGuid];
    const tagItemManager = vaultManager.getItemManager(vaultManager.TAG_ITEM_MANAGER);
    const tagItems = tagItemManager.getAllItems();
    if (isntNil(tag)) {
      if ((tag.login.length === 0) && (tag.identity.length === 0)
    && (tag.address.length === 0) && (tag.creditCard.length === 0)
    && (tag.bankAccount.length === 0) && (tag.note.length === 0)) {
        for (const key of Object.keys(tagItems)) {
          if (key === tagGuid) {
            tagItemManager.deleteTagItem(key);
          }
        }
        delete this.items[tagGuid];
        return true;
      }
    }
    return false;
  }

  /**
    * @async
    * @function deleteTagItem
    * @desc Delete selected guid from the tag
    * @param {string} tagGuid tag guid
    * @param {string} itemGuid item guid
    * @param {boolean} shouldSyncToCloud if we should sync this item to the cloud. Defaults to true.
    */
  async deleteTagItem(tagGuid, itemType, itemGuid, shouldSyncToCloud = true) {
    if (isNil(tagGuid)) {
      logger.error('tag guid cannot be null');
      throw new Error('tag guid cannot be null');
    }
    if (isNil(itemType)) {
      logger.error('itemType cannot be null');
      throw new Error('itemType cannot be null');
    }
    if (isNil(itemGuid)) {
      logger.error('item guid cannot be null');
      throw new Error('item guid cannot be null');
    }
    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }
    const nodeList = [];
    const tag = this.items[tagGuid];
    if (isNil(tag)) {
      logger.info('No tag item found to delete');
      return;
    }
    let deletedNode = null;
    switch (itemType) {
      case TAG.LOGIN:
        if (tag.login.length !== 0) {
          deletedNode = this._findAndRemoveTagItem(itemGuid, tag.login,
            TAG_NODE.LOGIN);
        }
        break;
      case TAG.IDENTITY:
        if (tag.identity.length !== 0) {
          deletedNode = this._findAndRemoveTagItem(itemGuid, tag.identity,
            TAG_NODE.IDENTITY);
        }
        break;
      case TAG.ADDRESS:
        if (tag.address.length !== 0) {
          deletedNode = this._findAndRemoveTagItem(itemGuid, tag.address,
            TAG_NODE.ADDRESS);
        }
        break;
      case TAG.CREDIT_CARD:
        if (tag.creditCard.length !== 0) {
          deletedNode = this._findAndRemoveTagItem(itemGuid, tag.creditCard,
            TAG_NODE.CREDIT_CARD);
        }
        break;
      case TAG.BANK_ACCOUNT:
        if (tag.bankAccount.length !== 0) {
          deletedNode = this._findAndRemoveTagItem(itemGuid, tag.bankAccount,
            TAG_NODE.BANK_ACCOUNT);
        }
        break;
      case TAG.NOTE:
        if (tag.note.length !== 0) {
          deletedNode = this._findAndRemoveTagItem(itemGuid, tag.note,
            TAG_NODE.NOTE);
        }
        break;
      default:
        throw new Error('Not supported type');
    }
    if (isntNil(deletedNode)) {
      nodeList.push(deletedNode);
    }
    if (this._removeEmptyTag(tagGuid)) {
      nodeList.push(tag.serializeNode(true));
    }
    if (nodeList.length === 0) {
      throw new Error('Item guid not found');
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
  * @function getTag
  * @desc returns item by guid
  * @param {string} guid tag guid
  * @returns {Tag | undefined} tag item
  */
  getTag(guid) {
    const tagItems = vaultManager.getItemManager(vaultManager.TAG_ITEM_MANAGER);
    const item = tagItems.getItem(guid);
    if (isntNil(item) && isntNil(this.items[guid])) {
      this.items[guid].login = item.login;
      this.items[guid].identity = item.identity;
      this.items[guid].address = item.address;
      this.items[guid].creditCard = item.creditCard;
      this.items[guid].bankAccount = item.bankAccount;
      this.items[guid].note = item.note;
    }
    return this.items[guid];
  }

  /**
  * @function getItemsByTagId
  * @param {string} tagGuid tag guid
  * @param {string} itemType item type
  * @returns {TagItem} tag item
  */
  getItemsByTagId(tagGuid, itemType) {
    const item = this.getTag(tagGuid);
    if (isNil(item)) {
      return;
    }
    for (const key in item) {
      if ({}.hasOwnProperty.call(item, key)) {
        return item[itemType];
      }
    }
  }

  /**
  * @function getTagsByItemId
  * @param {string} itemGuid item guid
  * @param {string} itemType item type
  * @returns {Record<string, Tag>} tag item
  */
  getTagsByItemId(itemGuid, itemType) {
    const tagItems = this.getAllItems();
    const tags = {};
    if (isNil(tagItems)) {
      return;
    }
    for (const key in tagItems) {
      for (const i in tagItems[key][itemType]) {
        if (tagItems[key][itemType][i].getTagItem() === itemGuid) {
          const tagGuid = tagItems[key][itemType][i].getPath();
          tags[tagGuid] = this.getTag(tagGuid);
        }
      }
    }
    return tags;
  }

  /**
    * @function bulkUpload
    * @desc This function will array of tags and items in it.
    * @param {Tag[]} tagArray Array of tag object
    * @param {boolean} shouldSyncToCloud A boolean denoting
    * whether to sync the data to cloud or not. Defaults to true
    */
  async bulkUpload(tagArray, shouldSyncToCloud = true) {
    if (!isArray(tagArray)) {
      throw new Error('must be an array');
    }
    const nodeList = [];
    const tagKeys = Object.keys(tagNodeMap);
    for (const tag of tagArray) {
      const guid = tag.getPath();
      if (isNil(guid)) {
        logger.error('GUID is not found. Item has not been created correctly');
        throw new Error('GUID is not found. Item has not been created correctly');
      }
      this.items[guid] = tag;
      nodeList.push(tag.clone().serializeNode());
      tagKeys.forEach((itemType) => {
        if (tag[itemType].length !== 0) {
          const itemNode = this.createItemNode(tag[itemType], tagNodeMap[itemType]);
          nodeList.push(itemNode);
        }
      });
    }
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }
}
