import React, { useMemo } from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import Box from 'pwm-components/components/Box';
import WebsiteAlertIcon from 'pwm-components/icons/WebsiteAlert';
import SafeWebsiteIcon from 'pwm-components/icons/SafeWebsite';
import { withTooltip } from 'pwm-components/components/Tooltip';

import Paragraph from 'pwm-components/components/Paragraph';
import TooltipAucDetection from './tooltips/TooltipAucDetection';
import TooltipUnsafeWebsite from './tooltips/TooltipUnsafeWebsite';

import { aucMap } from '../../lib/SecurityStatusHelper';

type Data = {
  auc: boolean;
  domain: string;
  aucCategory: 2 | 3 | 4;
  insecureProtocol: boolean;
  unsafeWebsite: boolean;
  unsafeWebsiteAlert: boolean;
  unsafeWebsiteWarning: boolean;
}

type Props = WrappedComponentProps & {
  data: Data;
  ignored: boolean;
}

const UnsafeWebsite: React.FC<Props> = ({
  data,
  ignored,
  intl,
}) => {
  const {
    auc,
    aucCategory,
    insecureProtocol,
    unsafeWebsite,
    unsafeWebsiteAlert,
    unsafeWebsiteWarning,
  } = data;
  let aucType = '';
  let aucResult = '';

  const IconWithTooltip = useMemo(
    () => (unsafeWebsite ? withTooltip(WebsiteAlertIcon) : withTooltip(SafeWebsiteIcon)
    ), [unsafeWebsite],
  );

  const variant = useMemo(() => {
    if (ignored) {
      return 'disabled';
    }
    if (unsafeWebsiteWarning) {
      return 'warning';
    }
    if (unsafeWebsiteAlert) {
      return 'alert';
    }
    return 'success';
  }, [ignored, unsafeWebsiteAlert, unsafeWebsiteWarning]);

  if (auc) {
    aucResult = aucMap[aucCategory].descriptionId;
    aucType = aucMap[aucCategory].categoryId;
  }

  const getToolTip = (): JSX.Element => {
    if (auc) {
      return (
        <TooltipAucDetection
          aucType={intl.formatMessage({ id: aucType })}
          aucResult={aucResult}
        />
      );
    }
    if (insecureProtocol) {
      return (
        <TooltipUnsafeWebsite />
      );
    }
    return (
      <>
        <Paragraph>
          <strong>
            {
              intl.formatMessage({ id: 'dashboard.securityStatus.tooltip.header.safeWebsite' })
            }
          </strong>
        </Paragraph>
        <Paragraph mt="xs">
          {
            intl.formatMessage({ id: 'dashboard.securityStatus.tooltip.body.safeWebsite' })
          }
        </Paragraph>
      </>
    );
  };

  return (
    <IconWithTooltip
      noDelay
      bigPadding
      variant={variant}
      tooltipContent={(
        <Box textAlign="left">
          {
            getToolTip()
          }
        </Box>
      )}
    />
  );
};

export default injectIntl(UnsafeWebsite);
