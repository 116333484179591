import React, { useState, useEffect, PropsWithChildren } from 'react';
import debounce from 'lodash/debounce';
import ms from 'ms';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch } from 'react-redux';

import responseCodes from '@avira-pwm/services/responseCodes';
import { generateKey } from '@avira-pwm/crypto-tools';

import { FormattedMessage } from 'react-intl';
import { State as OEState } from '../../oe/OEReducer';
import { State as UserDataState } from '../../user/UserDataReducer';
import { State as AuthState } from '../AuthReducer';
import { State as UserState } from '../../user/UserReducer';
import { State as TrackingState } from '../../tracking/TrackingReducer';

import SpotlightMasterPasswordRedirect from '../../user/components/SpotlightMasterPasswordRedirect';
import { validatePasswordStrength } from '../../lib/AuthenticationValidator';
import { usePrevious } from '../../lib/hooks';
import { mapErrorCode, isNetworkError, isOETimeoutError } from '../../lib/ErrorHelper';
import { setLocalSalt } from '../../tracking/TrackingActions';

import configuration from '../../config';
import { DashboardError } from '../../dashboard/DashboardActionTypes';
import { getSpotlightAuthData } from '../AuthenticationActions';

import debug from '../../debug';
import AuthPageWrapper from '../../oe/components/AuthPageWrapper';
import EmbargoError from '../../app/components/EmbargoError';

const log = debug.extend('Authentication');

const debouncedRefresh = debounce(() => {
  window.location.reload();
}, 200);

export interface StateProps {
  auth: AuthState;
  dashboard: { extensionConnected: boolean | null };
  oe: Pick<OEState, 'expiresAt' | 'token' | 'refreshToken'>;
  userKey: UserState['key'];
  userData: UserDataState;
  tracking: TrackingState;
}

export interface DispatchProps {
  refreshOE: () => Promise<void>;
  initAuth: (isSpotlight: boolean) => Promise<void>;
  onOpen: () => void;
  onError: (e: DashboardError) => void;
  logoutUser: (context?: string, cause?: string, error?: string) => Promise<void>;
  setDidReload: (didRefresh: boolean) => void;
}

export type OwnProps = PropsWithChildren<{
  renderWhileLoading?: () => React.ReactNode;
  mixpanel: { setLicense(...args: string[]): void; remove(licences: string[]): void };
}>;

export type Props = StateProps & DispatchProps & OwnProps;

// eslint-disable-next-line max-statements
const Authentication: React.FC<Props> = ({
  children,
  auth,
  oe,
  dashboard,
  userKey,
  userData,
  tracking,
  renderWhileLoading,
  refreshOE,
  initAuth,
  logoutUser,
  onOpen,
  setDidReload,
  onError,
  mixpanel,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [generateKeyAvailable, setGenerateKeyAvailable] = useState(true);
  const dispatch = useDispatch();
  const [embargoed, setEmbargoed] = useState(false);

  const prevInitialized = usePrevious(initialized);

  useEffect(() => {
    // eslint-disable-next-line complexity, max-statements
    async function initialize(): Promise<void> {
      const logInitialize = log.extend('initialize');
      logInitialize('start');
      if (configuration.spotlight && (!configuration.localBuild || typeof (window.external || {}).AcpGet !== 'undefined')) {
        logInitialize('loginSpotlightUser start');
        try {
          generateKey();
        } catch (e) {
          setGenerateKeyAvailable(false);
          return;
        }
      } else if (!dashboard.extensionConnected) {
        logInitialize('no extension connected');
        logInitialize('refreshOE start');
        try {
          await refreshOE();
          logInitialize('refreshOE success');
        } catch (e) {
          const errorCode = mapErrorCode(e);
          logInitialize('refreshOE error', errorCode, e);

          if (errorCode === responseCodes.oe.INVALIDATED_TOKEN && !auth.didReload) {
            setDidReload(true);
            debouncedRefresh();
            return;
          }

          if (isNetworkError(e) || isOETimeoutError(e)) {
            onError({ errorCode: mapErrorCode(e), context: 'Authentication:initialize:refreshOE', error: e });
            return;
          }

          logInitialize('logoutUser start');
          await logoutUser('Auth init', 'failed to refresh oe token', errorCode || e.message);
        }
      }

      logInitialize('initAuth start');
      try {
        await initAuth(configuration.spotlight);
      } catch (e) {
        logInitialize('initAuth error', e);
        onError({ errorCode: mapErrorCode(e), context: 'Authentication:initialize:initAuth', error: e });
        return;
      }

      if (configuration.spotlight) {
        await dispatch(getSpotlightAuthData());
      }

      setDidReload(false);
      setInitialized(true);
    }

    initialize();

    // dashboard.extensionConnected and auth.didReload are needed here but
    // this effect shouldn't be called if they change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    initAuth,
    logoutUser,
    onOpen,
    refreshOE,
    setDidReload,
  ]);

  useEffect(() => {
    if (!initialized) {
      return;
    }

    if (userData.status_us_compliance_verified_date != null) {
      if (userData.status_us_compliance_approved === false) {
        setEmbargoed(true);
        return;
      }
    }

    if (!prevInitialized) {
      setLoaded(true);
      onOpen();
    }
  }, [initialized, prevInitialized, userData, onOpen]);

  useEffect(() => {
    if (!tracking.localSalt) {
      dispatch(setLocalSalt(uuidv4()));
    }
  }, [tracking.localSalt, dispatch]);

  useEffect((): () => void => {
    if (oe.expiresAt && loaded && !dashboard.extensionConnected) {
      let timeoutId: number;
      const scheduleRefresh = (timeout: number): void => {
        timeoutId = window.setTimeout(async () => {
          try {
            await refreshOE();
          } catch (e) {
            if (isNetworkError(e) || isOETimeoutError(e)) {
              scheduleRefresh(ms('1m'));
            } else {
              logoutUser('Auth refresh timer', 'failed to refresh oe token', mapErrorCode(e));
            }
          }
        }, Math.max(10, timeout));
      };

      const expiresIn = oe.expiresAt - Date.now();

      scheduleRefresh(expiresIn);

      return (): void => {
        clearTimeout(timeoutId);
      };
    }

    return (): void => { };
  }, [oe.expiresAt, refreshOE, loaded, dashboard.extensionConnected, logoutUser]);

  const previousKey = usePrevious(userKey);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore validatePasswordStrength has no typing definitions
    validatePasswordStrength.cache.clear();

    if (configuration.spotlight) {
      return;
    }

    // type coercion used because any falsy key (or oe token in the next effect)
    // should be treated as the same
    if (!userKey && !!previousKey) {
      debouncedRefresh();
    }
  }, [previousKey, userKey]);

  const previousOEToken = usePrevious(oe.token);

  useEffect(() => {
    if (!oe.token && !!previousOEToken && !userKey && !!previousKey) {
      debouncedRefresh();
    }
  }, [oe.token, previousOEToken, userKey, previousKey]);

  useEffect(() => {
    if (userData.licenses && userData.licenses[0]) {
      const { id, type } = userData.licenses[0];
      mixpanel.setLicense(id, type);
    } else {
      mixpanel.remove(['license', 'licenseType']);
    }
  }, [userData.licenses, mixpanel]);

  if (!generateKeyAvailable) {
    return (
      <SpotlightMasterPasswordRedirect />
    );
  }

  if (embargoed) {
    return (
      <AuthPageWrapper
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore AuthPageWrapper isn't typed
        showInfoSection={false}
        Page={() => (
          <EmbargoError
            message={(
              <FormattedMessage id="embargo.error.description.user" />
            )}
          />
        )}
      />
    );
  }

  if (!loaded && renderWhileLoading != null) {
    return (
      <>
        {renderWhileLoading()}
      </>
    );
  }

  return (
    <>
      {children}
    </>
  );
};

Authentication.defaultProps = {
  renderWhileLoading: (): React.ReactNode => null,
};

export default Authentication;
