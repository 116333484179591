export const MP_OPEN = 'Open';
export const MP_CLOSE = 'Close';
export const MP_LOCK = 'Lock';

export const MP_OE_LOGIN_SHOWN = 'OeLoginShown';
export const MP_OE_LOGIN_INITIATED = 'OeLoginInitiated';
export const MP_OE_LOGIN = 'OeLogin';
export const MP_OE_LOGIN_FAILED = 'OeLoginFailed';
export const MP_OE_LOGIN_DROP_OUT = 'OeLoginDropOut';
export const MP_OE_VERIFIED = 'OeVerified';
export const MP_OE_VERIFIED_FAILED = 'OeVerifiedFailed';

export const MP_OE_REGISTER_SHOWN = 'OeRegisterShown';
export const MP_OE_REGISTER_INITIATED = 'OeRegisterInitiated';
export const MP_OE_REGISTER = 'OeRegister';
export const MP_OE_REGISTER_FAILED = 'OeRegisterFailed';
export const MP_OE_REGISTER_DROP_OUT = 'OeRegisterDropOut';

export const MP_PWM_REGISTER_SHOWN = 'PwmRegisterShown';
export const MP_PWM_REGISTER_INITIATED = 'PwmRegisterInitiated';
export const MP_PWM_REGISTER = 'PwmRegister';
export const MP_PWM_REGISTER_USER = 'PwmRegister_User';
export const MP_PWM_REGISTER_FAILED = 'PwmRegisterFailed';
export const MP_PWM_REGISTER_DROP_OUT = 'PwmRegisterDropOut';

export const MP_UNLOCK_SHOWN = 'UnlockShown';
export const MP_UNLOCK_INITIATED = 'UnlockInitiated';
export const MP_UNLOCK = 'Unlock';
export const MP_UNLOCK_FAILED = 'UnlockFailed';
export const MP_UNLOCK_DROP_OUT = 'UnlockDropOut';

export const MP_SIDEBAR_COLLAPSED = 'SidebarCollapsed';
export const MP_SIDEBAR_EXPANDED = 'SidebarExpanded';

export const MP_CREDENTIALS_ADD = 'Credentials_add';
export const MP_CREDENTIALS_EDIT = 'Credentials_edit';
export const MP_CREDENTIALS_USED = 'Credentials_used';
export const MP_CREDENTIALS_SAVED = 'Credentials_saved';
export const MP_CREDENTIALS_EDITED = 'Credentials_edited';
export const MP_CREDENTIALS_DELETED = 'Credentials_deleted';
export const MP_CREDENTIALS_SAVED_ERROR = 'Credentials_errors';
export const MP_CREDENTIALS_INVALID_USERNAME = 'Credentials_invalidUsername';

export const MP_CREDENTIALS_ASSOCIATED_URL_ADD = 'AssociatedURL_add';
export const MP_CREDENTIALS_ASSOCIATED_URL_REMOVE = 'AssociatedURL_delete';
export const MP_CREDENTIALS_ASSOCIATED_URL_SHOW = 'AssociatedURL_shown';
export const MP_CREDENTIALS_ASSOCIATED_URL_NAVIGATE = 'AssociatedURL_navigate';

export const MP_CREDENTIALS_DOTMENU_OPEN = 'Credentials_DotMenu_open';

export const MP_CREDENTIALS_HISTORY_OPEN = 'CredentialsHistory_open';
export const MP_CREDENTIALS_HISTORY_USED = 'CredentialsHistory_used';

export const MP_CREDITCARD_ADD = 'CreditCard_add';
export const MP_CREDITCARD_CREATED = 'CreditCard_created';
export const MP_CREDITCARD_EDIT = 'CreditCard_edit';
export const MP_CREDITCARD_EDITED = 'CreditCard_edited';
export const MP_CREDITCARD_DELETED = 'CreditCard_deleted';
export const MP_CREDITCARD_USED = 'CreditCard_used';

export const MP_GENERATE_PASSWORD = 'GeneratePassword';

export const MP_SEARCH = 'Search';
export const MP_SEARCH_INTERACTION = 'Search_interaction';

export const MP_TAG_FILTER = 'Tag_filter';

export const MP_LOGOUT = 'Logout';

export const MP_OE_LOGIN_RESEND_OTP = 'OeResendOtp';

export const MP_NOTE_CREATED = 'Note_created';
export const MP_NOTE_EDITED = 'Note_edited';
export const MP_NOTE_DELETED = 'Note_deleted';

export const MP_SECURITYSTATUS_INITIALIZE = 'SecurityStatusInitialize';
export const MP_SECURITYSTATUS_DISPLAY = 'SecurityStatusDisplay';
export const MP_SECURITYSTATUS_ACTION_BUTTON_CLICKED = 'SecurityStatusActionButtonClicked';
export const MP_SECURITYSTATUS_IGNORED = 'SecurityStatusIgnored';
export const MP_SECURITYSTATUS_UNIGNORED = 'SecurityStatusUnignored';
export const MP_SECURITYSTATUS_HIBP_ERROR = 'SecurityStatusHIBPError';

export const MP_GETPRO_CLICKED = 'GetProClicked';

export const MP_DELETE_EXTENSION_CLICKED = 'DeleteExtensionClicked';

export const MP_RESET_ACCOUNT = 'ResetAcount';
export const MP_RESET_ACCOUNT_MOBILE_YES = 'ResetAcountHasMobileYes';
export const MP_RESET_ACCOUNT_MOBILE_NO = 'ResetAcountHasMobileNo';
export const MP_RESET_ACCOUNT_BIOMETRIC_YES = 'ResetAcountHasBiometricYes';
export const MP_RESET_ACCOUNT_BIOMETRIC_NO = 'ResetAcountHasBiometricNo';
export const MP_RESET_ACCOUNT_MOBILE_CHANGE_SUCCESS = 'ResetAcountMobileChangeSuccess';
export const MP_RESET_ACCOUNT_MOBILE_CHANGE_FAIL = 'ResetAcountMobileChangeFail';
export const MP_RESET_ACCOUNT_SEND_EMAIL = 'ResetAcountSendEmail';
export const MP_RESET_ACCOUNT_SEND_EMAIL_CANCEL = 'ResetAcountSendEmailCancel';

export const MP_RESET_ACCOUNT_CONFIRMED = 'ResetAcountConfirmed';
export const MP_RESET_ACCOUNT_CONFIRM_ERROR = 'ResetAcountConfirmError';

export const MP_IMPORT_IMPORT_OPEN = 'Import_open';
export const MP_IMPORT_IMPORT_SOURCE_SELECTED = 'Import_sourceSelected';
export const MP_IMPORT_IMPORT_MAPPED = 'Import_mapped';
export const MP_IMPORT_REVIEWED = 'Import_reviewed';

export const MP_WHY_REGISTER_SHOWN = 'WhyRegisterShown';

export const MP_GETSTARTED_SHOWN = 'GetStarted_shown';
export const MP_GETSTARTED_VIEW_TILE = 'GetStarted_viewTile';
export const MP_GETSTARTED_POINT_EARNED = 'GetStarted_pointEarned';

export const MP_WHY_DO_I_SEE_THIS_MORE = 'WhyDoISeeThisTellMeMore';

export const MP_FILE_ADD = 'File_add';
export const MP_FILE_CREATED = 'File_created';
export const MP_FILE_DELETED = 'File_deleted';
export const MP_FILE_VIEWED = 'File_viewed';

export const MP_NATIVE_PERMISSION_GRANTED = 'NativeMessagingPermissionGranted';
export const MP_NATIVE_PERMISSION_ALLOW_COMMUNICATION = 'AllowCommunication';

export const MP_SPOTLIGHT_WELCOME_NEXT = 'WelcomeScreenNext';
export const MP_SPOTLIGHT_WELCOME_BACK = 'WelcomeScreenBack';

export const MP_CREDENTIALS_LIST_DISPLAY = 'CredentialsListDisplay';
export const MP_NOTES_LIST_DISPLAY = 'NotesListDisplay';
export const MP_CREDITCARDS_LIST_DISPLAY = 'WalletListDisplay';

export const MP_CREDENTIALS_LIST_SORTED = 'CredentialsListSorted';
export const MP_NOTES_LIST_SORTED = 'NotesListSorted';
export const MP_CREDITCARDS_LIST_SORTED = 'WalletListSorted';

export const MP_OPEN_ACCOUNT = 'OpenAccount';

export const MP_CHANGE_MASTER_PASSWORD_BUTTON_CLICKED = 'ChangeMasterPasswordButtonClicked';
