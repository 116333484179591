import ms from 'ms';
import { ColorKey } from 'pwm-components/variables/colors';
import { ButtonColorVariants } from 'pwm-components/variables/buttonColors';
import { TextColorVariants } from 'pwm-components/variables/textColors';


const PASSWORD_RATING_MSG = [
  'auth.zxcvbn.passwordRatingWeak',
  'auth.zxcvbn.passwordRatingWeak',
  'auth.zxcvbn.passwordRatingMedium',
  'auth.zxcvbn.passwordRatingGood',
  'auth.zxcvbn.passwordRatingStrong',
];

const BAR_RATING_COLORS: ColorKey[] = [
  'scarlet',
  'scarlet',
  'pizazzorange',
  'limeade',
  'limeade',
];

const TEXT_RATING_COLORS: TextColorVariants[] = [
  'alert',
  'alert',
  'warning',
  'success',
  'success',
];

const BUTTON_RATING_COLORS: ButtonColorVariants[] = [
  'alert',
  'alert',
  'upsell',
  'secondary',
  'secondary',
];

export const scoreLimit = PASSWORD_RATING_MSG.length;

export const passwordRatingMessageHelper = (score: number): string => PASSWORD_RATING_MSG[score];

export const passwordRatingbarColorHelper = (score: number): ColorKey => BAR_RATING_COLORS[score];

export const passwordRatingtextColorHelper = (
  score: number,
): TextColorVariants => TEXT_RATING_COLORS[score];

export const buttonColorHelper = (
  score: number,
): ButtonColorVariants => BUTTON_RATING_COLORS[score] || 'secondary';

const ONE_SECOND = ms('1s') / 1000;
const ONE_MINUTE = ms('1m') / 1000;
const ONE_HOUR = ms('1h') / 1000;
const ONE_DAY = ms('1d') / 1000;
const ONE_YEAR = ms('1y') / 1000;

const ONE_MILLION = 1000000;
const ONE_THOUSAND = 1000;
const AGE_OF_THE_UNIVERSE = 14000 * ONE_MILLION * ONE_YEAR;

const TIMES: any[] = [
  [ONE_YEAR * ONE_MILLION, 'million'],
  [ONE_YEAR * ONE_THOUSAND, 'thousand'],
  [ONE_YEAR, 'year'],
  [ONE_DAY, 'day'],
  [ONE_HOUR, 'hour'],
  [ONE_MINUTE, 'minute'],
  [ONE_SECOND, 'second'],
];

export const round = (value: number, decimals: number): number => Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);

export const getTimeToHack = (time: number): any => {
  if (time >= AGE_OF_THE_UNIVERSE) {
    return {
      message: 'dashboard.account.details.yearsToHack.ageOfTheUniverse.1',
    };
  }

  const thresholdIndex = TIMES.findIndex(([t], i, a) => time > t || i === (a.length - 1));
  const [threshold, messageType] = TIMES[thresholdIndex];
  const displayTime = round(time / threshold, time < threshold ? 4 : 0);
  const isPlural = displayTime >= 2 && thresholdIndex >= 2;

  return {
    displayTime,
    message: `dashboard.account.details.yearsToHack.${messageType}${isPlural ? 's' : ''}`,
  };
};
