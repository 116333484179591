import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Text from 'pwm-components/components/Text';
import Box from 'pwm-components/components/Box';
import DomainImage from 'pwm-components/components/DomainImage';
import TextButton from 'pwm-components/components/TextButton';
import Account from '../../img/account.svg';
import mixpanel from '../../tracking/mixpanel';

import Card from '../../componentLib/CardWrapper';

export type Props = {
  stepName: string;
};

const CustomBanner: React.FC<Props> = ({
  stepName,
}) => {
  const history = useHistory();

  const onLoginClick = useCallback((): void => {
    mixpanel.track('PwmActivationClick', { stepName, buttonLabel: 'Login' });
    history.push('/login');
  }, [history, stepName]);

  return (
    <Card p="xs" size="small">
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        pl="s"
      >
        <Box>
          <DomainImage domain="avira.com" />
          <Text size="large" variant="disabledDark" pl='xs'>
            <FormattedMessage id="onboarding.banner.avira" defaultMessage="Avira" />
          </Text>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Box pr="xs">
            <img src={Account} alt="" width="18px" />
          </Box>
          <Text size="normal" variant="disabledDark" pr='8px'>
            <FormattedMessage id="onboarding.banner.signin.text" defaultMessage="Already have an Avira Password Manager account?" />
          </Text>
          <TextButton onClick={onLoginClick}>
            <Text size="normal" variant="link">
              <FormattedMessage id="onboarding.banner.signin.button" defaultMessage="Sign in" />
            </Text>
          </TextButton>
        </Box>
      </Box>
    </Card>
  );
};

export default CustomBanner;
