import reduce from 'lodash/reduce';
import { SET_PASSWORDS_BREACHES, PASSWORDS_BREACHES_DATA, PASSWORDS_BREACHES_CLEAR } from './SecurityStatusActionTypes';

const arrayToHash = dataArray => reduce(dataArray, (r, v) => {
  r[v.data.id] = v.data;
  return r;
}, {});

function getInitialState() {
  return {};
}

export default function passwordBreachesReducer(state = getInitialState(), { type, payload }) {
  switch (type) {
    case SET_PASSWORDS_BREACHES:
      return payload;
    case PASSWORDS_BREACHES_DATA:
      return arrayToHash(payload);
    case PASSWORDS_BREACHES_CLEAR:
      return getInitialState();
    default:
      return state;
  }
}
