import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { toggleNoteFavorite, updateLastUsedAt } from '../NoteActions';
import NoteListItem from './NoteListItem';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  onFavoriteClick: toggleNoteFavorite,
  onEditClick: updateLastUsedAt,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoteListItem);
