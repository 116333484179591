import React from 'react';
import { element, func, shape } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  updateNoteDetails,
} from '../NoteActions';

class NoteDetailsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.updateNoteDetails(this.props.params.id).then(() => {
      this.setState({ loaded: true });
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notes !== this.props.notes || this.props.params.id !== nextProps.params.id) {
      this.props.updateNoteDetails(nextProps.params.id);
    }
  }

  render() {
    if (!this.state.loaded) {
      return <></>;
    }

    return this.props.children;
  }
}

NoteDetailsWrapper.propTypes = {
  notes: shape({}).isRequired,
  children: element,
  updateNoteDetails: func.isRequired,
  params: shape({}).isRequired,
};

NoteDetailsWrapper.defaultProps = {
  children: <div />,
};

const mapStateToProps = ({ notes, files }) => ({ notes, files });

const mapDispatchToProps = dispatch => bindActionCreators({ updateNoteDetails }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NoteDetailsWrapper);
