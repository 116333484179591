import * as Sentry from '@sentry/browser';
import { SyncUtils } from '@avira-pwm/sync';
import { DELETED_UNKNOWN_BREACHES_DATA, DELETED_UNKNOWN_BREACHES_CLEAR } from './SecurityStatusActionTypes';
import { generateID } from '../lib/AccountHelper';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { getRelevantUserKey } from '../user/selectors';
import { handleSyncError } from '../lib/ErrorHelper';

export const syncGetDeletedUnknownBreaches = () => async (dispatch, getState, { syncInstance }) => {
  try {
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    const deletedUnknownBreaches = await SyncUtils.getAllModelData(sync, 'DeletedUnknownBreach');
    dispatch({ type: DELETED_UNKNOWN_BREACHES_DATA, payload: deletedUnknownBreaches });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const deleteUnknownBreach = props => async (dispatch, getState, { syncInstance }) => {
  try {
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    const id = generateID();
    await SyncUtils.create(sync, 'DeletedUnknownBreach', id, props);
  } catch (e) {
    try {
      handleSyncError(e, 'deleteUnknownBreach');
    } catch (rethrownError) {
      Sentry.captureException(rethrownError);
    }
  }
};

export const clearDeletedUnknownBreaches = () => ({ type: DELETED_UNKNOWN_BREACHES_CLEAR });
