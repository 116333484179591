import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from 'pwm-components/objects/Grid';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Button from 'pwm-components/components/Button';
import Banner from '../../componentLib/Banner';

type Props = {
  supportLink: string;
}

const NonMigratableBanner: React.FC<Props> = ({ supportLink }) => (
  <Box mt="-36px" mb="xl">
    <Banner
      actionButton={(
        <a
          href={supportLink}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button variant="alert">
            <FormattedMessage
              id="dashboard.nonMigratableBanner.cta"
              defaultMessage="Open now"
            />
          </Button>
        </a>
      )}
    >
      <Grid size="no-padding" align="center">
        <Paragraph ml="l">
          <FormattedMessage id="dashboard.nonMigratableBanner.text" defaultMessage="Your Password Manager account is not synchronizing. To resolve the issue, we recommend deleting data you no longer need. You can also find more information in this help article." />
        </Paragraph>
      </Grid>
    </Banner>
  </Box>
);

export default NonMigratableBanner;
