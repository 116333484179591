import React, { useRef, useImperativeHandle } from 'react';

import styled, { css } from 'pwm-components/styled';
import StyledInputField from 'pwm-components/components/Input/StyledInputField';
import UnderlinedInput from 'pwm-components/components/UnderlinedInput';

type InputProps = React.ComponentPropsWithoutRef<typeof UnderlinedInput> & {
  hideInput?: boolean;
};

const StyledSearchInput = styled(UnderlinedInput)<InputProps>`
  ${StyledInputField} {
    width: 180px;
    padding: ${({ theme: { space } }) => space.xs};
    transition: width 0.2s;
  }

  ${({ hideInput }) => (hideInput
    ? css`
      ${StyledInputField} {
        width: 0;

        &:focus {
          width: 180px;
        }
      }
    `
    : ''
  )}
`;

type FocusedInput = Pick<HTMLInputElement, 'focus'>;

type Props = InputProps & {
  inputRef: React.Ref<HTMLInputElement>;
};

export default React.forwardRef<FocusedInput, Props>((props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => {
      if (inputRef.current != null) {
        inputRef.current.focus();
      }
    },
  }));

  return (
    <StyledSearchInput
      {...props}
      ref={inputRef}
    />
  );
});
