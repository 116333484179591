import React from 'react';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';
import Text from 'pwm-components/components/Text';

import iconClick from '../../../img/icon-click.png';
import iconClick2x from '../../../img/icon-click@2x.png';

import locateTheExtension from '../../../img/animations/locateTheExtension.gif';
import locateTheExtension2x from '../../../img/animations/locateTheExtension2x.gif';

import TwoColumnView from '../TwoColumnView';
import GifContainer from '../GifContainer';

export default () => (
  <TwoColumnView
    leftSection={(
      <>
        <Box mb="m">
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.locateExtension.message"

              defaultMessage="Once installed, the extension appears in the extension panel of your browser."
            />
          </Paragraph>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Box mr="l">
            <img
              src={iconClick}
              srcSet={`${iconClick} 1x, ${iconClick2x} 2x`}
              alt="Click icon"
            />
          </Box>
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.locateExtension.instruction"
              defaultMessage="Click on the Extension icon to see what is happening."
            />
          </Text>
        </Box>
      </>
    )}

    rightSection={(
      <GifContainer
        gifSrc={locateTheExtension}
        gifSrc2x={locateTheExtension2x}
        gifAlt="Extension in browser toolbar animation"
      />
    )}
  />
);
