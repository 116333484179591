import { createSelector } from 'reselect';

// eslint-disable-next-line import/prefer-default-export
export const getRelevantOeTokens = createSelector(
  state => state.oe,
  state => state.dashboard.isUnregisteredMode,
  (oe, isUnregisteredMode) => (isUnregisteredMode ? {
    token: oe.unregisteredModeToken,
    refreshToken: oe.unregisteredModeRefreshToken,
    expiresAt: oe.unregisteredModeExpiresAt,
  } : {
    token: oe.token,
    refreshToken: oe.refreshToken,
    expiresAt: oe.expiresAt,
  }),
);
