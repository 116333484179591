import { v4 as uuidv4 } from 'uuid';
import { FILTER_ALL, FILTER_FAVORITES_ACCOUNT, SORT_TYPE } from './MyDataHelper';

export const generateID = () => uuidv4().replace(/-/g, '').toUpperCase();

export const sortOptions = {
  name: {
    sortKey: account => (account.label || account.domain || ''),
    sortOrder: SORT_TYPE.ASC,
  },
  created: {
    sortKey: 'createdAt',
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
  modified: {
    sortKey: 'modifiedAt',
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
  used: {
    sortKey: account => (account.lastUsedAt || account.modifiedAt || account.createdAt),
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
};

export const defaultSortBy = 'name';

const checkSubdomain = (subdomains, query) => {
  if (!subdomains || subdomains.length === 0) {
    return false;
  }

  return subdomains.find(
    (subdomainData) => {
      if (subdomainData) {
        const { subdomain, visible } = subdomainData;
        if (subdomain && visible && subdomain.toLowerCase().indexOf(query) >= 0) {
          return true;
        }
      }
      return false;
    },
  );
};

export const tags = [{
  id: FILTER_FAVORITES_ACCOUNT,
  label: 'dashboard.accounts.showFavorites',
}, {
  id: FILTER_ALL,
  label: 'dashboard.accounts.showAll',
}];

export const searchQueryLabelDomain = ({ label, domain, subdomain }, query) => (
  (label && label.toLowerCase().indexOf(query) >= 0)
  || (domain && domain.toLowerCase().indexOf(query) >= 0)
  || checkSubdomain(subdomain, query)
);

export const searchQueryUsernameEmail = ({ username, email }, query) => (
  (username && username.toLowerCase().indexOf(query) >= 0)
  || (email && email.toLowerCase().indexOf(query) >= 0)
);

export const defaultFilter = FILTER_ALL;
