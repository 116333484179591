import { SORT_TYPE } from './MyDataHelper';

export const FILTER_ALL = 'all';
export const FILTER_TO_IMPROVE = 'toImprove';

export const searchQueryLabelDomain = ({ label, domain }, query) => (
  (label && label.toLowerCase().indexOf(query) >= 0)
  || (!label && domain && domain.toLowerCase().indexOf(query) >= 0)
);

export const searchQueryUsernameEmail = ({ username, email }, query) => (
  (username && username.toLowerCase().indexOf(query) >= 0)
  || (!username && email && email.toLowerCase().indexOf(query) >= 0)
);

export const isSecurityStatusIgnored = (account, status) => {
  if (!status.toImprove) return false;

  const ignoreAviraBreachedWebsite = account.ignoreAviraBreachedWebsite || [];
  const ignoreHibpBreachedWebsite = account.ignoreHibpBreachedWebsite || [];
  const ignoreHibpBreachedAccount = account.ignoreHibpBreachedAccount || [];
  const ignoreDependentWarnings = account.ignoreDependentWarnings || [];

  return !!(
    (!status.reused || !!account.ignoreReused)
    && (!status.passwordStrength || ((account.ignorePasswordStrength != null)
      && status.passwordScore >= account.ignorePasswordStrength)) // avoid checking number >= null
    && (!status.insecureProtocol || account.ignoreInsecureProtocol)
    && (!status.auc || !!account.ignoreAUC)
    && (
      status.aviraBreachedWebsiteId == null
      || ignoreAviraBreachedWebsite.includes(status.aviraBreachedWebsiteId)
    ) && (
      status.hibpBreachedWebsiteId == null
      || ignoreHibpBreachedWebsite.includes(status.hibpBreachedWebsiteId)
    ) && (
      status.hibpBreachedAccountId == null
      || ignoreHibpBreachedAccount.includes(status.hibpBreachedAccountId)
    ) && (
      !status.hibpBreachedPassword
      || account.ignoreHibpBreachedPassword === account.password
    ) && (
      status.dependentBreachId == null
      || ignoreDependentWarnings.includes(status.dependentBreachId)
    )
  );
};

// eslint-disable-next-line max-params
export const validateBreach = (breachDate, created, modified, passwordAutoGenerated) => {
  // discovered that the password modified is sometimes 1 millisecond more on
  // Staging/Production environment and 1000 milliseconds more on the test Environment
  // than the created for newly created accounts
  const isTrusted = modified - created > 1000 || !!passwordAutoGenerated; //
  return {
    breached: breachDate >= modified || !isTrusted,
    isWarning: breachDate < modified && !isTrusted,
  };
};

export const securityScoreMap = {
  default: 100,
  reused: 50,
  password: {
    0: 100,
    1: 100,
    2: 50,
    3: 0,
    4: 0,
  },
  unsafeWebsiteWarning: 50,
  unsafeWebsiteAlert: 100,
  breachedWarning: 50,
  breachedAlert: 100,
};

export const aucMap = {
  2: {
    categoryId: 'dashboard.securityStatus.unsafeWebsite.aucCategory.malware',
    descriptionId: 'dashboard.securityStatus.unsafeWebsite.auc.malware',
  },
  3: {
    categoryId: 'dashboard.securityStatus.unsafeWebsite.aucCategory.phishing',
    descriptionId: 'dashboard.securityStatus.unsafeWebsite.auc.phishing',
  },
  4: {
    categoryId: 'dashboard.securityStatus.unsafeWebsite.aucCategory.spam',
    descriptionId: 'dashboard.securityStatus.unsafeWebsite.auc.spam',
  },
};

export const AUC_BAD_CATEGORIES = Object.keys(aucMap).map(Number);

export const sortOptions = {
  name: {
    sortKey: account => (account.label || account.domain || ''),
    sortOrder: SORT_TYPE.ASC,
  },
  showcaseOnTop: {
    comparator: (idA, idB, data) => {
      const dataA = data[idA];
      const dataB = data[idB];
      const k1val = (`${dataA.isDemoAccount ? 'a' : 'b'}${dataA.label || dataA.domain || ''}`);
      const k2val = (`${dataB.isDemoAccount ? 'a' : 'b'}${dataB.label || dataB.domain || ''}`);

      return k1val.localeCompare(k2val);
    },
    sortOrder: SORT_TYPE.ASC,
  },
};

export const proDefaultSortby = 'name';
export const defaultSortBy = 'showcaseOnTop';

export const exemptedUnknownUsernames = ['admin', 'administrator', 'root', 'test'];
