import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';

import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import config from '../../config';
import { toggleSyncStore } from '../../user/UserActions';
import alertIcon from '../../img/alert.png';
import alertIcon2x from '../../img/alert@2x.png';
import InfoTooltip from '../../componentLib/InfoTooltip';
import { getExtensionBrowserName } from '../../lib/UserAgent';

import { isDataUsageDisallowed } from '../../selectors';
import intlShape from '../../lib/intlShape';

function handleInstallExtension(event) {
  event.preventDefault();
  const currentBrowser = getExtensionBrowserName();
  window.open(config.links[currentBrowser]);
  return false;
}

class SyncDisabledWarning extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showErrorMessage: false,
    };
    this.handleSwitchOnSync = this.handleSwitchOnSync.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({ showErrorMessage: false });
  }

  async handleSwitchOnSync() {
    this.setState({ loading: true });
    try {
      await this.props.toggleSyncStore(this.props.user.authToken);
    } catch (e) {
      this.setState({ showErrorMessage: true });
    } finally {
      this.setState({ loading: false });
    }
  }


  render() {
    const currentBrowser = getExtensionBrowserName();
    const isBrowserSupported = Object.keys(config.links).includes(currentBrowser);

    return (
      <ConfirmationDialog
        overlay
        cancelButtonType="login"
        size={isBrowserSupported ? 'wide' : 'normal'}
        loading={this.state.loading}
        show={this.props.disallowDataUsage}
        confirmLabel={this.props.intl.formatMessage({
          id: 'dashboard.syncDisabledWarning.switchOnbackupAndSync',
          defaultMessage: 'Turn ON Backup & Sync',
        })}
        cancelLabel={isBrowserSupported
          ? this.props.intl.formatMessage({
            id: 'dashboard.syncDisabledWarning.installExtension',
            defaultMessage: 'Install Extension',
          })
          : null}
        onConfirm={this.handleSwitchOnSync}
        onCancel={handleInstallExtension}
      >
        <Box mb="l">
          <Paragraph mb="l" textAlign="center">
            <img
              src={alertIcon}
              srcSet={`${alertIcon} 1x, ${alertIcon2x} 2x`}
              alt="Delete Password"
            />
          </Paragraph>
          <Paragraph>
            {
              this.state.showErrorMessage
                ? <FormattedMessage id="dashboard.error.message" />
                : (
                  <FormattedMessage
                    id={isBrowserSupported ? 'dashboard.syncDisabledWarning.supportedBrowser' : 'dashboard.syncDisabledWarning.unsupportedBrowser'}
                  />
                )
            }
            {!isBrowserSupported && <InfoTooltip messageId="dashboard.syncDisabledWarning.info" />}
          </Paragraph>
        </Box>
      </ConfirmationDialog>
    );
  }
}

SyncDisabledWarning.defaultProps = {
  user: {
    authToken: null,
  },
};

SyncDisabledWarning.propTypes = {
  disallowDataUsage: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    authToken: PropTypes.string,
  }),
  toggleSyncStore: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => ({
  user: state.user,
  disallowDataUsage: isDataUsageDisallowed(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  toggleSyncStore,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(SyncDisabledWarning));
