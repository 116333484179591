import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ProtectedRoute from '../../app/components/ProtectedRoute';
import { SecurityStatusPageContainer } from '../../securityStatus';
import SyncEnabledRoute from '../../app/components/SyncEnabledRoute';
import GetStartedContainer from '../../getStarted';

import {
  AccountsContainer,
  AddAccount,
  EditAccount,
  PasswordHistory,
  AccountDetailsWrapper,
} from '../../accounts';

import {
  NotesContainer,
  NoteDetailsWrapper,
  AddNote,
  EditNote,
} from '../../notes';

import AddCreditCard from '../../wallet/components/addEditView/AddCreditCard';
import EditCreditCard from '../../wallet/components/addEditView/EditCreditCard';
import WalletPageContainer from '../../wallet/components/walletDetailsView/WalletPageContainer';
import MobilePageContainer from '../../mobile/components/MobilePageContainer';

import ConnectedDevices from '../../connectedDevices';
import { SettingsContainer, ImporterContainer, ChangeMasterPasswordContainer } from '../../settings';

import DashboardPageWrapper from './DashboardPageWrapper';
import Initializers from './Initializers';
import Page from './Page';

const DashboardContainer = () => (
  <Initializers>
    <Page>
      <Switch>
        <Redirect from="/accounts" to="/mydata/passwords" />
        <Route
          path="/mydata"
          render={({ match: { path } }) => (
            <Switch>
              <Redirect exact push from={`${path}`} to="/mydata/passwords" />

              <SyncEnabledRoute
                path={`${path}/passwords/new`}
                render={() => (
                  <DashboardPageWrapper fullWidth withHeader>
                    <AddAccount />
                  </DashboardPageWrapper>
                )}
              />
              <SyncEnabledRoute
                path={`${path}/passwords/:id/edit`}
                render={({ match }) => (
                  <DashboardPageWrapper fullWidth withHeader>
                    <AccountDetailsWrapper params={match.params}>
                      <EditAccount />
                    </AccountDetailsWrapper>
                  </DashboardPageWrapper>
                )}
              />
              <SyncEnabledRoute
                path={`${path}/passwords/:id/history`}
                render={({ match }) => (
                  <DashboardPageWrapper fixedHeight>
                    <AccountDetailsWrapper params={match.params}>
                      <PasswordHistory />
                    </AccountDetailsWrapper>
                  </DashboardPageWrapper>
                )}
              />

              <SyncEnabledRoute
                path={`${path}/notes/new`}
                render={() => (
                  <DashboardPageWrapper fixedHeight fullWidth withHeader>
                    <AddNote />
                  </DashboardPageWrapper>
                )}
              />

              <SyncEnabledRoute
                path={`${path}/notes/:id/edit`}
                render={({ match }) => (
                  <DashboardPageWrapper fixedHeight fullWidth withHeader>
                    <NoteDetailsWrapper params={match.params}>
                      <EditNote />
                    </NoteDetailsWrapper>
                  </DashboardPageWrapper>
                )}
              />

              <SyncEnabledRoute
                path={`${path}/wallet/new`}
                render={() => (
                  <DashboardPageWrapper fullWidth withHeader>
                    <AddCreditCard />
                  </DashboardPageWrapper>
                )}
              />

              <SyncEnabledRoute
                path={`${path}/wallet/:id/edit`}
                render={({ match }) => (
                  <DashboardPageWrapper fullWidth withHeader>
                    <EditCreditCard params={match.params} />
                  </DashboardPageWrapper>
                )}
              />

              <Route
                path={`${path}/passwords`}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <AccountsContainer />
                  </DashboardPageWrapper>
                )}
              />

              <Route
                path={`${path}/notes`}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <NotesContainer />
                  </DashboardPageWrapper>
                )}
              />
              <Route
                path={`${path}/wallet`}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <WalletPageContainer />
                  </DashboardPageWrapper>
                )}
              />
            </Switch>
          )}
        />


        <Route
          path="/connected-devices"
          render={() => (
            <DashboardPageWrapper fixedHeight>
              <ConnectedDevices />
            </DashboardPageWrapper>
          )}
        />

        <Route
          path="/get-started"
          render={({ match: { path } }) => (
            <Switch>
              <Route
                path={`${path}/import`}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <ImporterContainer />
                  </DashboardPageWrapper>
                )}
              />
              <Route
                path={path}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <GetStartedContainer />
                  </DashboardPageWrapper>
                )}
              />
            </Switch>
          )}
        />

        <Route
          path="/settings"
          render={({ match: { path } }) => (
            <Switch>
              <Route
                path={`${path}`}
                exact
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <SettingsContainer />
                  </DashboardPageWrapper>
                )}
              />
              <Route
                path={`${path}/import`}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <ImporterContainer />
                  </DashboardPageWrapper>
                )}
              />
              <ProtectedRoute
                path={`${path}/backups`}
                requirement={() => (false)}
                redirectPath="/settings"
              />
              <Route
                path={`${path}/change-master-password`}
                render={() => (
                  <DashboardPageWrapper>
                    <ChangeMasterPasswordContainer />
                  </DashboardPageWrapper>
                )}
              />
            </Switch>
          )}
        />

        <Route
          path="/security-status"
          render={({ match: { path } }) => (
            <Switch>
              <Route
                path={`${path}`}
                render={() => (
                  <DashboardPageWrapper fixedHeight>
                    <SecurityStatusPageContainer />
                  </DashboardPageWrapper>
                )}
                exact
              />
              <Route
                path={`${path}/:id/edit`}
                render={({ match }) => (
                  <DashboardPageWrapper fullWidth withHeader>
                    <AccountDetailsWrapper params={match.params}>
                      <EditAccount />
                    </AccountDetailsWrapper>
                  </DashboardPageWrapper>
                )}
              />
            </Switch>
          )}
        />
        <Route
          path='/mobile'
          render={({ match: { path } }) => (
            <DashboardPageWrapper fixedHeight>
              <MobilePageContainer path={path} />
            </DashboardPageWrapper>
          )}
        />

        <Route render={() => (<Redirect to={{ pathname: '/page-not-found' }} />)} />
      </Switch>
    </Page>
  </Initializers>
);

export default DashboardContainer;
