import { AviraBreach } from './AviraBreachesTypes';
import {
  SET_AVIRA_BREACHED_WEBSITES,
  AviraBrechesActionTypes,
} from './AviraBreachesActionTypes';

export type State = {
  [K: string]: {
    domain: string;
    breaches: AviraBreach[];
  } | boolean;
}

function getInitialState(): State {
  return {
    loaded: false,
  };
}

export default function aviraBreachesReducer(
  state: State = getInitialState(),
  action: AviraBrechesActionTypes,
): State {
  switch (action.type) {
    case SET_AVIRA_BREACHED_WEBSITES:
      return {
        ...action.value,
        loaded: true,
      };
    default:
      return state;
  }
}
