import { TrackingActions, MixpanelEvents } from '../tracking';

const {
  MP_OE_VERIFIED,
  MP_OE_VERIFIED_FAILED,
} = MixpanelEvents;

const { trackEvent } = TrackingActions;

export const trackVerified = () => async (dispatch) => {
  dispatch(trackEvent(MP_OE_VERIFIED));
};

export const trackVerifiedFailed = () => async (dispatch) => {
  dispatch(trackEvent(MP_OE_VERIFIED_FAILED));
};
