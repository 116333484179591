import React from 'react';
import Box from 'pwm-components/components/Box';
import YouTubeVideo from '../../componentLib/YouTubeVideo';

interface Props {
  onCompleted: () => void;
  videoId: string;
  language: string;
}

const Video: React.FC<Props> = ({ onCompleted, ...otherProps }): React.ReactElement => (
  <Box position="relative">
    <YouTubeVideo
      onPlay={onCompleted}
      {...otherProps}
    />
  </Box>
);

export default Video;
