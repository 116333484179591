import { DeepReadonly } from 'utility-types';
import {
  OE_AUTH_STORE,
  OE_LOGOUT,
  OE_UPDATE_TOKEN,
  OE_SET_GDPR,
  OE_SET_UNREGISTERED_TOKENS,
} from './OEActionTypes';

export type State = DeepReadonly<{
  email: string | null;
  id: string | null;
  token: string | null;
  gdprConsent: boolean | null;
  gdprConfirm: boolean | null;
  expiresAt: number | null;
  expiresIn: number | null;
  refreshToken: string | null;
  trustedTokens: {
    [key: string]: string | undefined;
  };
  socialMediaUniqueId: string | null;
  unregisteredModeToken: string | null;
  unregisteredModeRefreshToken: string | null;
  unregisteredModeExpiresAt: number | null;
  unregisteredModeExpiresIn: number | null;
}>;

export function defaultState(): State {
  return {
    email: null,
    id: null,
    token: null,
    gdprConsent: null,
    gdprConfirm: null,
    expiresAt: null,
    expiresIn: null,
    refreshToken: null,
    trustedTokens: {},
    socialMediaUniqueId: null,
    unregisteredModeToken: null,
    unregisteredModeRefreshToken: null,
    unregisteredModeExpiresAt: null,
    unregisteredModeExpiresIn: null,
  };
}

export default function oeReducer(state = defaultState(), action: any): State {
  switch (action.type) {
    case OE_AUTH_STORE:
      return {
        ...state,
        email: action.email,
        id: action.id,
        token: action.token,
        expiresAt: action.expiresAt,
        expiresIn: action.expiresIn,
        refreshToken: action.refreshToken,
        socialMediaUniqueId: action.socialMediaUniqueId,
        trustedTokens: action.trustedToken ? {
          ...state.trustedTokens,
          [action.email || action.socialMediaUniqueId]: action.trustedToken,
        } : state.trustedTokens,
      };
    case OE_UPDATE_TOKEN: {
      const { token, refreshToken, expiresAt } = action;
      return {
        ...state,
        token,
        refreshToken,
        expiresAt,
      };
    }
    case OE_SET_GDPR:
      return {
        ...state,
        gdprConsent: action.consent,
        gdprConfirm: action.confirm,
      };
    case OE_LOGOUT:
      return {
        ...defaultState(),
        trustedTokens: state.trustedTokens,
      };
    case OE_SET_UNREGISTERED_TOKENS: {
      return {
        ...state,
        unregisteredModeToken: action.token,
        unregisteredModeRefreshToken: action.refreshToken,
        unregisteredModeExpiresAt: action.expiresAt,
        unregisteredModeExpiresIn: action.expiresIn,
      };
    }
    default:
      return state;
  }
}
