import React from 'react';
import { bool } from 'prop-types';

import Box from 'pwm-components/components/Box';
import IconCross from 'pwm-components/icons/Cross';
import IconTick from 'pwm-components/icons/Tick';
import IconInfo from 'pwm-components/icons/InfoCircle';
import { withTooltip } from 'pwm-components/components/Tooltip';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';

const versionFeatures = [{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.localPasswordsStorage.title"
      defaultMessage="Local passwords storage"
    />
  ),
  unregisteredVersion: true,
  registeredVersion: true,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.localPasswordsStorage.tooltip"
      defaultMessage="Avira Password Manager extenstion stores your data locally on your device. If you uninstall the extension, you will lose your data."
    />
  ),
  id: 'versionFeatures',
  hideInfo: true,
},
{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.secureCloudStorage.title"
      defaultMessage="Secure cloud storage"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: true,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.secureCloudStorage.tooltip"
      defaultMessage="Avira Password Manager stores your data in the highly protected Avira cloud."
    />
  ),
  id: 'secureCloudStorage',
  hideInfo: false,
},
{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.autoBackup.title"
      defaultMessage="Auto-backup"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: true,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.autoBackup.tooltip"
      defaultMessage="Protected by Avira Cloud, your data will also be backed up to prevent data loss."
    />
  ),
  id: 'autoBackup',
  hideInfo: false,
},
{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.syncBetweenDevices.title"
      defaultMessage="Sync between devices"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: true,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.syncBetweenDevices.tooltip"
      defaultMessage="Install Avira Password Manager on all your devices: PCs, phones, and tablets. Once you create an Avira account, you can log in to the account to access your passwords and notes on all your devices. The changes you make on one device will be automatically applied through all your devices."
    />
  ),
  id: 'syncBetweenDevices',
  hideInfo: false,
},

{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.unlimitedNumberOfPasswords.title"
      defaultMessage="Unlimited number of passwords"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: true,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.unlimitedNumberOfPasswords.tooltip"
      defaultMessage="After registration, you will be able to save as many passwords and notes as you need."
    />
  ),
  id: 'unlimitedNumberOfPasswords',
  hideInfo: true,
},

{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.timeUnlimitedUsage.title"
      defaultMessage="Time unlimited usage"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: true,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.timeUnlimitedUsage.tooltip"
      defaultMessage="In the unregistered version, you can only save new passwords and edit all your passwords for a limited time."
    />
  ),
  id: 'timeUnlimitedUsage',
  hideInfo: true,
},

{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.securityStatus.title"
      defaultMessage="Security status"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: false,
  proVersion: true,
  tooltipContent: (
    <div>
      <FormattedMessage
        id="dashboard.whyRegister.securityStatus.Header"
        defaultMessage="Breach Checks"
      />
      <br />
      <FormattedMessage
        id="dashboard.whyRegister.securityStatus.Body"
        defaultMessage="Has one of your accounts been hacked? A notification will appear in your security status if your data has been stolen after a breach."
      />
      <br />
      <br />
      <FormattedMessage
        id="dashboard.whyRegister.securityStatus.Header1"
        defaultMessage="Website Checks"
      />
      <br />
      <FormattedMessage
        id="dashboard.whyRegister.securityStatus.Body1"
        defaultMessage="Some of the websites you're registered for may be unsafe! The security status checks whether the website is known as unsafe."
      />
      <br />
      <br />
      <FormattedMessage
        id="dashboard.whyRegister.securityStatus.Header2"
        defaultMessage="Password Checks"
      />
      <br />
      <FormattedMessage
        id="dashboard.whyRegister.securityStatus.Body2"
        defaultMessage="The Security Status analyzes your existing passwords and notifies you of weak or duplicate ones."
      />
    </div>
  ),
  id: 'securityStatus',
  hideInfo: false,
},
{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.customerSupport.title"
      defaultMessage="Customer support"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: false,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.customerSupport.tooltip"
      defaultMessage="Get access to Avira Support. Avira professionals are on standby to help you, both via email and telephone."
    />
  ),
  id: 'customerSuppert',
  hideInfo: false,
},
{
  name: (
    <FormattedMessage
      id="dashboard.whyRegister.secureFileStorage.title"
      defaultMessage="Secure File Storage"
    />
  ),
  unregisteredVersion: false,
  registeredVersion: false,
  proVersion: true,
  tooltipContent: (
    <FormattedMessage
      id="dashboard.whyRegister.secureFileStorage.tooltip"
      defaultMessage="Securely store up to 1GB of content including documents, photos, videos, and other files encrypted in Avira Password Manager."
    />
  ),
  id: 'secureFileStorage',
  hideInfo: false,
}];

const iconTick = <IconTick variant="link" />;
const iconCross = <IconCross variant="alert" />;
const IconInfoWithTooltip = withTooltip(IconInfo);

const Row = styled('tr')`
  & td {
    vertical-align: bottom;
    text-align: right;
  }

  & td:first-child > span {
    display: inline-block;
  }

  & td:not(:first-child) > span {
    text-align: center;
  }

  & td:first-child > span > span {
    margin-right: ${({ theme }) => theme.space.xxs};
  }
`;

const Cell = styled('span')`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};
  display: block;
  padding: 6px;
`;

const VersionComparisionRowHelper = ({ hideUnregistered, hidePro, hideFree }) => (
  // eslint-disable-next-line complexity
  versionFeatures.map(rowData => (
    <Row key={rowData.id}>
      <td>
        <Cell>
          <span style={{ whiteSpace: 'nowrap' }}>{rowData.name}</span>
          {hideUnregistered && rowData.hideInfo
            ? <Box display="inline-block" height={16} width={20} />
            : (
              <IconInfoWithTooltip
                noDelay
                bigPadding
                tooltipAlign="bottomRight"
                bgOverride="royalblue"
                color="white"
                variant="linkAlternate"
                tooltipContent={rowData.tooltipContent}
              />
            )}
        </Cell>
      </td>
      {!hideUnregistered
        && (
        <td>
          <Cell>
            {rowData.unregisteredVersion ? iconTick : iconCross}
          </Cell>
        </td>
        )}
      {!hideFree
        && (
        <td>
          <Cell>
            {rowData.registeredVersion ? iconTick : iconCross}
          </Cell>
        </td>
        )}
      {!hidePro
        && (
        <td>
          <Cell>
            {rowData.proVersion ? iconTick : iconCross}
          </Cell>
        </td>
        )}
    </Row>
  ))
);

VersionComparisionRowHelper.propTypes = {
  hideUnregistered: bool,
  hidePro: bool,
  hideFree: bool,
};

VersionComparisionRowHelper.defaultProps = {
  hideUnregistered: false,
  hidePro: false,
  hideFree: false,
};

export default VersionComparisionRowHelper;
