import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';

import styled from 'pwm-components/styled';

import DeleteIcon from 'pwm-components/icons/Delete';
import EditIcon from 'pwm-components/icons/Edit';
import EyeCrossedIcon from 'pwm-components/icons/EyeCrossed';
import EyeIcon from 'pwm-components/icons/Eye';
import FavoriteIcon from 'pwm-components/icons/Favorite';
import FavoriteOutlineIcon from 'pwm-components/icons/FavoriteOutline';

import Box from 'pwm-components/components/Box';
import ModifiedAt from 'pwm-components/components/ModifiedAt';
import Paragraph from 'pwm-components/components/Paragraph';
import PasswordTooltip from 'pwm-components/components/PasswordTooltip';
import TextButton from 'pwm-components/components/TextButton';

import { getDomain, constructValidUrl } from '@avira-pwm/helpers/url';
import CopyValue from '../../componentLib/CopyValue';
import PasswordText from '../../componentLib/PasswordText';
import DataListItem from '../../componentLib/DataListItem';
import {
  DataListCell,
  DataListCellOnHover,
  TextButtonOnHover,
  DataListActions,
} from '../../componentLib/DataListComponents';
import { sortOptions, defaultSortBy } from '../../lib/AccountHelper';

import NavigateUrl from '../../componentLib/NavigateUrl';

import config from '../../config';
import * as SpotlightAPI from '../../lib/SpotlightAPI';

const TRACKING_CONTEXT = 'list';

const EyeIconTransition = styled(EyeIcon)`
  transition: fill 200ms;
`;

class AccountListItem extends React.Component {
  static propTypes = {
    currentRevealPasswordId: PropTypes.string.isRequired,
    currentAssociatedUrlsId: PropTypes.string.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      domain: PropTypes.string,
      login_url: PropTypes.string,
      label: PropTypes.string,
      is_favorite: PropTypes.bool,
      email: PropTypes.string,
      username: PropTypes.string,
      password: PropTypes.string,
      passwordAutoGenerated: PropTypes.bool,
      subdomain: PropTypes.array,
    }).isRequired,
    extensionInstalled: PropTypes.bool,
    index: PropTypes.number.isRequired,
    sortBy: PropTypes.string,
    onAccountClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onEditAccountClick: PropTypes.func.isRequired,
    onFavoriteClick: PropTypes.func.isRequired,
    onInteraction: PropTypes.func,
    onRevealPasswordClick: PropTypes.func.isRequired,
    scrolling: PropTypes.bool.isRequired,
    setCurrentRevealPassword: PropTypes.func.isRequired,
    setCurrentAssociatedUrls: PropTypes.func.isRequired,
    updateIgnoreFilter: PropTypes.func.isRequired,
    updateIgnoreSort: PropTypes.func.isRequired,
    history: PropTypes.object,
    match: PropTypes.object,
    onAssociatedUrlNavigateClicked: PropTypes.func.isRequired,
  };

  static defaultProps = {
    extensionInstalled: false,
    onInteraction: () => { },
    sortBy: defaultSortBy,
    history: {
      push: () => { },
    },
    match: {
      url: '',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      copyEmail: false,
      revealPassword: false,
      showLinkedUrl: false,
      copyPassword: false,
      hover: false,
    };

    const { onCopyClick } = this.props;

    this.onCopyClick = this.onCopyClick.bind(this);
    this.showTooltip = this.showTooltip.bind(this);
    this.hideTooltip = this.hideTooltip.bind(this);
    this.onAccountClick = this.onAccountClick.bind(this);
    this.onCardClick = this.onCardClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onFavoriteClick = this.onFavoriteClick.bind(this);
    this.onRevealPasswordClick = this.onRevealPasswordClick.bind(this);
    this.hideRevealPassword = this.hideRevealPassword.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.debouncedOnCopyClick = debounce(onCopyClick);
    this.displayLinkedUrls = this.displayLinkedUrls.bind(this);
    this.closeLinkedUrls = this.closeLinkedUrls.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      currentRevealPasswordId,
      currentAssociatedUrlsId,
      data,
      scrolling,
    } = this.props;

    if (currentRevealPasswordId !== nextProps.currentRevealPasswordId) {
      this.setState({
        revealPassword: (data.id === nextProps.currentRevealPasswordId),
      });
    }

    if (currentAssociatedUrlsId !== nextProps.currentAssociatedUrlsId) {
      this.setState({
        showLinkedUrl: (data.id === nextProps.currentAssociatedUrlsId),
      });
    }

    if (scrolling !== nextProps.scrolling && nextProps.scrolling) {
      this.setState({
        copyEmail: false,
        revealPassword: false,
        showLinkedUrl: false,
        copyPassword: false,
        hover: false,
      });
    }
  }

  // eslint-disable-next-line complexity
  shouldComponentUpdate(nextProps, nextState) {
    const { state } = this;
    const { props } = this;

    if (nextProps.scrolling !== props.scrolling) {
      return !!state.revealPassword;
    }

    const shouldUpdate = (
      state.copyEmail !== nextState.copyEmail
        || state.copyPassword !== nextState.copyPassword
        || state.revealPassword !== nextState.revealPassword
        || state.showLinkedUrl !== nextState.showLinkedUrl
        || props.data !== nextProps.data
        || props.sortBy !== nextProps.sortBy
        || state.hover !== nextState.hover
    );

    return shouldUpdate;
  }

  // eslint-disable-next-line complexity
  onAccountClick(e, subdomain = {}) {
    const {
      extensionInstalled,
      onAccountClick,
      onInteraction,
      index,
      data,
    } = this.props;
    let loginActionUrl = data.login_url;
    // eslint-disable-next-line no-undef
    const subdomainUrl = subdomain?.loginUrl ?? subdomain?.subdomain;

    e.preventDefault();

    if (!loginActionUrl || (
      getDomain(data.domain) !== getDomain(loginActionUrl)
    )) {
      loginActionUrl = constructValidUrl(data.domain);
    }

    if (subdomainUrl) {
      loginActionUrl = constructValidUrl(subdomainUrl);
    }

    if (extensionInstalled && !subdomainUrl) {
      onAccountClick(data.id, e.target.href, TRACKING_CONTEXT, subdomain);
    } else if (config.spotlight) {
      SpotlightAPI.openURL(loginActionUrl);
    } else {
      window.open(loginActionUrl);
    }
    onInteraction(index);
  }

  onEditClick(e) {
    const { onInteraction, onEditAccountClick, index } = this.props;

    e.stopPropagation();
    onInteraction(index);
    onEditAccountClick();
  }

  onFavoriteClick(e) {
    const {
      updateIgnoreSort,
      updateIgnoreFilter,
      onFavoriteClick,
      data,
    } = this.props;

    e.stopPropagation();
    updateIgnoreSort();
    updateIgnoreFilter(data.id);
    onFavoriteClick(data);
  }

  onCopyClick(key) {
    const {
      data,
      index,
      updateIgnoreSort,
      onInteraction,
    } = this.props;
    this.showTooltip(key);
    setTimeout(() => {
      this.hideTooltip(key);
    }, 1000);
    updateIgnoreSort();
    // use debounce so that the render cycle does not slow down showing of tooltip
    this.debouncedOnCopyClick(TRACKING_CONTEXT, key, data);
    onInteraction(index);
  }

  onCardClick() {
    const {
      data,
      index,
      history,
      match,
      onInteraction,
      onEditAccountClick,
    } = this.props;
    history.push(`${match.url}/${data.id}/edit`);
    onInteraction(index);
    onEditAccountClick();
  }

  onDeleteClick(e) {
    const { onDeleteClick } = this.props;

    e.stopPropagation();
    const { data: { id } } = this.props;
    onDeleteClick(id);
  }

  onRevealPasswordClick(e) {
    const {
      data,
      index,
      onRevealPasswordClick,
      setCurrentRevealPassword,
      onInteraction,
    } = this.props;
    const { revealPassword } = this.state;

    e.stopPropagation();
    onRevealPasswordClick(TRACKING_CONTEXT, 'revealPassword', data);
    setCurrentRevealPassword(
      data.id,
      !revealPassword,
    );
    onInteraction(index);
  }

  onMouseEnter() {
    this.setState({ hover: true });
  }

  onMouseLeave() {
    this.setState({ hover: false });
  }

  onCopyPasswordClick = () => {
    this.onCopyClick('copyPassword');
  }

  onCopyEmailClick = () => {
    this.onCopyClick('copyEmail');
  }

  hideRevealPassword() {
    const { setCurrentRevealPassword, data } = this.props;
    setCurrentRevealPassword(
      data.id,
      false,
    );
  }

  showTooltip(key) {
    this.setState({
      [key]: true,
    });
  }

  hideTooltip(key) {
    this.setState({
      [key]: false,
    });
  }

  displayLinkedUrls() {
    const { setCurrentAssociatedUrls, data } = this.props;
    const { showLinkedUrl } = this.state;
    setCurrentAssociatedUrls(
      data.id,
      !showLinkedUrl,
    );
  }

  closeLinkedUrls() {
    const { setCurrentAssociatedUrls, data } = this.props;
    setCurrentAssociatedUrls(
      data.id,
      false,
    );
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      data,
      sortBy,
      match,
      index,
      onAssociatedUrlNavigateClicked,
    } = this.props;
    const { revealPassword, showLinkedUrl } = this.state;
    const { sortKey, isDate } = sortOptions[sortBy];
    const sortValue = typeof sortKey === 'function' ? sortKey(data) : data[sortKey];
    const accLinkedUrls = (
      // eslint-disable-next-line no-undef
      data.subdomain ? (data.subdomain.filter(subdomainData => subdomainData?.visible)) : []
    );

    return (
      <DataListItem
        onClick={this.onCardClick}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
      >
        <DataListCell
          id={`a-domain-${index}`}
          flexBasis="20em"
          flexShrink={2}
          shrink
          position="relative"
          display="flex"
          alignItems="center"
          minWidth={config.spotlight ? 'auto' : '0px'}
        >
          <NavigateUrl
            associatedUrlsCollection={accLinkedUrls}
            onClick={this.onAccountClick}
            data={data}
            onAssociatedUrlNavigateClicked={onAssociatedUrlNavigateClicked}
            showLinkedUrl={showLinkedUrl}
            displayLinkedUrls={this.displayLinkedUrls}
            closeLinkedUrls={this.closeLinkedUrls}
          />
        </DataListCell>

        <DataListCellOnHover spacer separator />

        <DataListCell
          id={`a-username-${index}`}
          flexBasis="16em"
          shrink
          truncate
        >
          {
            (data.username || data.email)
            && (
              <CopyValue
                buttonId={`a-copy-username-button-${index}`}
                value={data.username || data.email}
                onClick={this.onCopyEmailClick}
              >
                <Paragraph
                  position="relative"
                  truncate
                >
                  {data.username || data.email}
                </Paragraph>
              </CopyValue>
            )
          }
        </DataListCell>


        <DataListCellOnHover spacer separator />


        <DataListCell
          id={`a-password-${index}`}
          fixed
        >
          <CopyValue
            buttonId={`a-copy-password-button-${index}`}
            value={data.password}
            onClick={this.onCopyPasswordClick}
          >
            <Box display="flex" alignItems="center">
              <Box mr="m" flexGrow={0}>
                <PasswordText size="large" />
              </Box>

              <Box>
                <TextButton
                  onClick={this.onRevealPasswordClick}
                  tabIndex={0}
                >
                  {
                    revealPassword
                      ? (
                        <EyeCrossedIcon variant="link" />
                      ) : (
                        <EyeIconTransition variant="link" />
                      )
                    }
                </TextButton>
                {
                  revealPassword
                    ? (
                      <PasswordTooltip
                        password={data.password || ''}
                        visible={revealPassword}
                        onOutsideClick={this.hideRevealPassword}
                        onCloseClick={this.hideRevealPassword}
                        big
                      />
                    )
                    : null
                }
              </Box>
            </Box>
          </CopyValue>
        </DataListCell>

        {isDate
          ? <DataListCellOnHover spacer separator />
          : ''}

        <DataListCell
          flexBasis="8em"
          textAlign="center"
          flexShrink={2}
          shrink
          truncate
          hideOnShrink
        >
          {isDate
            ? <ModifiedAt value={sortValue} showDay />
            : ''}
        </DataListCell>


        <DataListCellOnHover spacer separator />


        <DataListActions fixed>
          <Link
            id={`a-edit-data-item-button-${index}`}
            to={`${match.url}/${data.id}/edit`}
            onClick={this.onEditClick}
          >
            <EditIcon variant="link" />
          </Link>
        </DataListActions>


        <DataListCell spacer />


        <DataListActions fixed>
          <TextButtonOnHover
            onClick={this.onDeleteClick}
            id={`a-delete-data-item-button-${index}`}
            tabIndex="-1"
          >
            <DeleteIcon variant="link" />
          </TextButtonOnHover>
        </DataListActions>


        <DataListCell spacer />


        <DataListCellOnHover
          visible={data.is_favorite}
        >
          <TextButton
            id={`a-favorite-account-button-${index}`}
            onClick={this.onFavoriteClick}
            tabIndex="-1"
          >
            {data.is_favorite
              ? <FavoriteIcon variant="active" /> : <FavoriteOutlineIcon variant="link" />}
          </TextButton>
        </DataListCellOnHover>
      </DataListItem>
    );
  }
}

export default withRouter(AccountListItem);
