import reduce from 'lodash/reduce';
import { DELETED_UNKNOWN_BREACHES_DATA, DELETED_UNKNOWN_BREACHES_CLEAR } from './SecurityStatusActionTypes';

const arrayToHash = dataArray => reduce(dataArray, (r, v) => {
  r[v.data.breachKey] = v.data;
  return r;
}, {});

function getInitialState() {
  return {};
}

export default function deletedUnknownReducer(state = getInitialState(), { type, payload }) {
  switch (type) {
    case DELETED_UNKNOWN_BREACHES_DATA:
      return arrayToHash(payload);
    case DELETED_UNKNOWN_BREACHES_CLEAR:
      return getInitialState();
    default:
      return state;
  }
}
