import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import styled from 'pwm-components/styled';
import Tag from 'pwm-components/components/Tag';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';
import StyledInputFieldWrapper from 'pwm-components/components/Input/StyledInputFieldWrapper';
import CloseIcon from 'pwm-components/icons/Close';
import DoneIcon from 'pwm-components/icons/Done';
import Grid from 'pwm-components/objects/Grid';
import TagsAutoSuggestContainer from './TagsAutoSuggestContainer';

const MAX_TAG_LENGTH = 15;

const AddLabel = styled('span')`
  position: absolute;
  font-size: 24px;
  font-weight: 500;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ActionTag = styled(Tag)<{ focused: boolean; onClick: () => void }>`
  position: relative;
  height: 32px;
  width: 32px;
  line-height: 0;
  overflow: visible;
  min-width: ${({ focused }) => (focused ? '200px' : '0')};
  padding: ${({ theme: { space }, focused }) => (focused ? `${space.xxs} ${space.m}` : '0')};
  flex: 0 0 32px;

  & ${StyledInputFieldWrapper} {
    background: none;
    border: none;
  }

  & input {
    padding: 0;
    height: 22px;
    color: ${({ theme: { semanticColors } }) => semanticColors.text};
  }
`;

type Props = WrappedComponentProps & {
  onCreate: (value: string) => void;
  ignoreList: Array<string>;
  instructionLabel?: JSX.Element | null;
  tagMaxLength?: number;
}

type State = {
  inputFocused: boolean;
  addMode: boolean;
  value: string;
}

const defaultState = {
  addMode: false,
  inputFocused: false,
  value: '',
};

class CreateTag extends React.Component<Props, State> {
  public static defaultProps = {
    ignoreList: [],
    instructionLabel: null,
  };

  constructor(props: Props) {
    super(props);

    this.state = { ...defaultState };

    this.onActionClick = this.onActionClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onConfirmClick = this.onConfirmClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.submit = this.submit.bind(this);
  }

  private onActionClick(): void {
    const { addMode } = this.state;
    this.setState({ addMode: !addMode });
  }

  private onChange(e: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({ value: e.target.value });
  }

  private onConfirmClick(): void {
    const { value } = this.state;
    this.submit(value);
  }

  private onSubmit(value: string): void {
    const { value: currentValue } = this.state;
    this.submit(value || currentValue);
  }

  private onCancelClick(): void {
    this.setState({
      ...defaultState,
    });
  }

  private onFocus(): void {
    this.setState({ inputFocused: true });
  }

  private onBlur(): void {
    const { value } = this.state;
    this.setState({ inputFocused: false, addMode: value.length > 0 });
  }

  private submit(value: string): void {
    const { onCreate } = this.props;
    const val = (value || '').trim();
    if (val) {
      onCreate(val);
    }
    this.setState({
      ...defaultState,
    });
  }

  public render(): JSX.Element {
    const { addMode, inputFocused, value } = this.state;
    const {
      ignoreList,
      instructionLabel,
      intl,
      tagMaxLength,
    } = this.props;

    return (
      <Grid
        mr="xs"
        size="no-padding"
        align="center"
      >
        <ActionTag
          focused={inputFocused || addMode}
          onClick={() => { if (!addMode) { this.onActionClick(); } }}
        >
          {!addMode ? (
            <AddLabel>+</AddLabel>
          ) : (
            <TagsAutoSuggestContainer
              focusOnMount
              value={value}
              onChange={this.onChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              ignoreList={ignoreList}
              onSubmit={this.onSubmit}
              onSelect={(val: string) => this.setState({ value: val })}
              onEscape={this.onCancelClick}
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              maxLength={tagMaxLength ?? MAX_TAG_LENGTH}
              placeholder={intl.formatMessage({
                id: 'dashboard.account.details.createTagPlaceholder',
                defaultMessage: 'Enter tag name',
              })}
              icons={[
                <TextButton key="createTagCheck" onClick={this.onConfirmClick}>
                  <DoneIcon variant="link" size="small" />
                </TextButton>,
                <TextButton key="createTagCancel" onClick={this.onCancelClick}>
                  <CloseIcon variant="link" size="small" />
                </TextButton>,
              ]}
            />
          )}
        </ActionTag>
        {
          (instructionLabel && !addMode) && (
            <Text ml="xs">
              {instructionLabel}
            </Text>
          )
        }
      </Grid>
    );
  }
}

export default injectIntl(CreateTag);
