import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UnlockMasterPassword from './UnlockMasterPassword';
import {
  checkMasterPassword,
  trackMasterPasswordShown,
  trackMasterPasswordDropout,
  trackMasterPasswordInitiated,
  trackMasterPasswordFailed,
} from '../MasterPasswordActions';

const mapStateToProps = ({ user, oe }) => ({ user, oe });

const mapDispatchToProps = dispatch => bindActionCreators({
  onFormSubmit: checkMasterPassword,
  onComponentShown: trackMasterPasswordShown,
  onDropOut: trackMasterPasswordDropout,
  trackMasterPasswordInitiated,
  trackMasterPasswordFailed,
}, dispatch);

const UnlockMasterPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UnlockMasterPassword);

export default UnlockMasterPasswordContainer;
