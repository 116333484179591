import React from 'react';
import omit from 'lodash/omit';
import cardValidator from 'card-validator';
import { Fields, MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import PaperClipIcon from 'pwm-components/icons/PaperClip';
import { IntlShape } from 'react-intl';
import { SORT_TYPE } from '../lib/MyDataHelper';
import * as cardIcon from '../img/cardProviders';

const AttachedIcon = styled(PaperClipIcon)`
  position: absolute;
  right: -5px;
  bottom: -5px;
  stroke: ${({ theme: { colors } }) => colors.white};
`;

export const cardProviders = {
  PLACEHOLDER: 'Placeholder',
};

export const CARDNUMBER_MAX = MAX_LENGTHS.cardNumber!;
export const PIN_INPUT_LENGTH = MAX_LENGTHS.cardPin!;
export const CVC_INPUT_LENGTH = MAX_LENGTHS.cvc!;
export const CARDNUMBER_INPUT_LENGTH = CARDNUMBER_MAX + 4;
export const DIGIT_MASK = '\u2022';
export const DEFAULT_CARDNUMBER_LENGTH = 16;
export const DEFAULT_CARDNUMBER_GAPS = [4, 8, 12, 16];
export const DEFAULT_GAPS_WITH_SPACING = [4, 9, 14, 19];

export const searchQueryMain = (creditCard: Fields, stringToFind: string): boolean => (
  !!(creditCard.title && creditCard.title.toLowerCase().indexOf(stringToFind) >= 0)
);

export const searchQuerySub = (creditCard: Fields, stringToFind: string): boolean => (
  !!(creditCard.notes && creditCard.notes.toLowerCase().indexOf(stringToFind) >= 0)
);

export const walletItemType = {
  CREDIT_CARD: 'CreditCard',
};

export const defaultSortBy = 'name';

export interface SortOption {
  sortKey: string;
  sortOrder: string;
}

export const sortOptions = {
  name: {
    sortKey: 'title',
    sortOrder: SORT_TYPE.ASC,
  },
  created: {
    sortKey: 'createdAt',
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
  modified: {
    sortKey: 'modifiedAt',
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
};

export const getCardNumberValidator = (cardNumber: string): cardValidator.validNumber => {
  const validator = cardValidator.number(cardNumber);
  return validator;
};

export function creditCardtTitleHelper(intl: IntlShape, cardNumber: string): string {
  const validator = getCardNumberValidator(cardNumber);
  if (validator.card) {
    return (validator.card.type === 'american-express'
      ? `${validator.card.niceType} ${DIGIT_MASK} ${cardNumber.slice(-5)}`
      : `${validator.card.niceType} ${DIGIT_MASK} ${cardNumber.slice(-4)}`
    );
  }
  return (cardNumber
    ? `${intl.formatMessage({ id: 'dashboard.wallet.details.creditCard' })} ${DIGIT_MASK} ${cardNumber.slice(-4)}`
    : '');
}

/** Default pretty print to return */
export function prettyPrintDefault(cardNumber: string): string {
  const offsets = DEFAULT_CARDNUMBER_GAPS;
  const components = [];

  if (cardNumber) {
    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i];
      const end = Math.min(offsets[i + 1], cardNumber.length);
      components.push(cardNumber.substring(start, end));
    }
    return components.join(' ');
  }
  return ''.padStart(DEFAULT_CARDNUMBER_LENGTH, DIGIT_MASK);
}

/**
 * Helper function to print a card number with the card provider specic spacing
 * Assumes array of gaps is always provided sorted ascending;
 * If number length is greater than last gap, the remaining chars are pushed to the result.
 */
export function prettyPrintCard(cardNumber: string): string {
  if (cardNumber) {
    const validator = cardValidator.number(cardNumber);
    const gaps = validator.card ? validator.card.gaps : DEFAULT_CARDNUMBER_GAPS;

    const components = [];
    const offsets = [0, ...gaps];

    if (offsets[offsets.length - 1] < cardNumber.length) {
      offsets.push(cardNumber.length);
    }

    for (let i = 0; offsets[i] < cardNumber.length; i++) {
      const start = offsets[i];
      const end = Math.min(offsets[i + 1], cardNumber.length);
      components.push(cardNumber.substring(start, end));
    }
    return components.join(' ');
  }
  return cardNumber;
}

export function concealPrintPrefix(cardNumber: string): JSX.Element {
  const concealPad = 12;
  const padSize = concealPad;

  if (cardNumber.length < 4) {
    return (
      <>
        {prettyPrintCard(''.padStart(
          padSize + DEFAULT_CARDNUMBER_LENGTH - concealPad - cardNumber.length,
          DIGIT_MASK,
        ))}
      </>
    );
  }

  return (
    <>
      {prettyPrintCard(''.padStart(padSize, DIGIT_MASK))}
      &nbsp;
    </>
  );
}

export function concealPrintSuffix(cardNumber: string): string {
  return `${DIGIT_MASK}${DIGIT_MASK}${DIGIT_MASK}${DIGIT_MASK}${cardNumber}`.slice(-4);
}

/** Helper to determine if a month and year are less than the current date,
 * indicated they are expired
 * Assumptions:
 * Expects to receive non-numeric strings, will return false with invalid strings.
 * no value prior to 2000 will be entered.
 * Strings are of correct format mm yy
 * returns true only if strings are valid numeric and the date is greater than now;
*/
export function isPastDate(month: string | undefined, year: string | undefined): boolean {
  if (month && year && month.length === 2 && year.length === 2) {
    const now = new Date();
    const date = new Date(2000 + parseInt(year, 10), parseInt(month, 10));
    return now > date;
  }
  return false;
}

export function getMonth(month: string): string {
  const value = parseInt(month, 10);
  // handles [2-9] -> 02-09
  if (month.length === 1 && value > 1) {
    return '0'.concat(month);
  }
  if (month.length === 2) {
    // handles [00]
    if (parseInt(month, 10) === 0) {
      return '0';
    }
    // handles [01-09]
    if (parseInt(month.charAt(0), 10) === 0) {
      return month;
    }
    // handles 10 - 12
    if (value < 13) {
      return month;
    }
  }
  // handles 1
  return month;
}

export function makeDate(aDate: string): Array<string> {
  const aMonth = getMonth(aDate.substring(0, 2));
  const aYear = aDate.substring(2, 4) || '';
  return [aMonth, aYear];
}

// This assumes date that does not span '99-2000;
// eslint-disable-next-line max-params
export function isValidDateRange(validFromMonth: string | null | undefined,
  validFromYear: string | null | undefined,
  validToMonth: string | null | undefined,
  validToYear: string | null | undefined): boolean {
  if (validFromMonth && validFromYear && validToMonth && validToYear) {
    const fromDate = new Date(parseInt(validFromYear, 10), parseInt(validFromMonth, 10));
    const toDate = new Date(parseInt(validToYear, 10), parseInt(validToMonth, 10));
    return (fromDate < toDate);
  }
  return true;
}


export const addKeyValue = (record: Record<string, string>,
  object: Record<string, string>): Record<string, string> => ({ ...object, ...record });

export function removeKeyValue(
  key: string,
  object: Record<string, string>,
): Record<string, string> {
  return omit(object, key);
}

export function removeChars(value: string): string {
  return value.replace(/[^0-9]/g, '');
}

const cardTypes: Record<string, Record<string, string>> = {
  'american-express': {
    id: 'American Express',
    icon1x: cardIcon.amExpr1x,
    icon2x: cardIcon.amExpr2x,
    icon3x: cardIcon.amExpr3x,
    alt: 'American Express logo',
  },
  'chinaT-union': {
    id: 'China T-union',
    icon1x: cardIcon.chTunion1x,
    icon2x: cardIcon.chTunion2x,
    icon3x: cardIcon.chTunion3x,
    alt: 'China T-union logo',
  },
  dankort: {
    id: 'Dankort',
    icon1x: cardIcon.dankort1x,
    icon2x: cardIcon.dankort2x,
    icon3x: cardIcon.dankort3x,
    alt: 'Dankort logo',
  },
  'diners-club': {
    id: 'Diners Club International',
    icon1x: cardIcon.dinClub1x,
    icon2x: cardIcon.dinClub2x,
    icon3x: cardIcon.dinClub3x,
    alt: 'Diners Club International logo',
  },
  discover: {
    id: 'Discover',
    icon1x: cardIcon.discover1x,
    icon2x: cardIcon.discover2x,
    icon3x: cardIcon.discover3x,
    alt: 'Discover logo',
  },
  jcb: {
    id: 'JCB',
    icon1x: cardIcon.jcb1x,
    icon2x: cardIcon.jcb2x,
    icon3x: cardIcon.jcb3x,
    alt: 'JCB logo',
  },
  lankapay: {
    id: 'LankaPay',
    icon1x: cardIcon.lankapay1x,
    icon2x: cardIcon.lankapay2x,
    icon3x: cardIcon.lankapay3x,
    alt: 'LankaPay logo',
  },
  maestro: {
    id: 'Maestro',
    icon1x: cardIcon.maestro1x,
    icon2x: cardIcon.maestro2x,
    icon3x: cardIcon.maestro3x,
    alt: 'Maestro logo',
  },
  mastercard: {
    id: 'Mastercard',
    icon1x: cardIcon.mastercard1x,
    icon2x: cardIcon.mastercard2x,
    icon3x: cardIcon.mastercard3x,
    alt: 'Mastercard logo',
  },
  mir: {
    id: 'Mir',
    icon1x: cardIcon.mir1x,
    icon2x: cardIcon.mir2x,
    icon3x: cardIcon.mir3x,
    alt: 'Mir logo',
  },
  placeholder: {
    id: 'Placeholder',
    icon1x: cardIcon.placeholder1x,
    icon2x: cardIcon.placeholder2x,
    icon3x: cardIcon.placeholder3x,
    alt: 'Placeholder card logo',
  },
  rupay: {
    id: 'RuPay',
    icon1x: cardIcon.rupay1x,
    icon2x: cardIcon.rupay2x,
    icon3x: cardIcon.rupay3x,
    alt: 'RuPay logo',
  },
  troy: {
    id: 'Troy',
    icon1x: cardIcon.troy1x,
    icon2x: cardIcon.troy2x,
    icon3x: cardIcon.troy3x,
    alt: 'Troy logo',
  },
  uatp: {
    id: 'UATP',
    icon1x: cardIcon.uatp1x,
    icon2x: cardIcon.uatp2x,
    icon3x: cardIcon.uatp3x,
    alt: 'UATP logo',
  },
  unionpay: {
    id: 'UnionPay',
    icon1x: cardIcon.unionPay1x,
    icon2x: cardIcon.unionPay2x,
    icon3x: cardIcon.unionPay3x,
    alt: 'UnionPay logo',
  },
  verve: {
    id: 'Verve',
    icon1x: cardIcon.verve1x,
    icon2x: cardIcon.verve2x,
    icon3x: cardIcon.verve3x,
    alt: 'Verve logo',
  },
  visa: {
    id: 'Visa',
    icon1x: cardIcon.visa1x,
    icon2x: cardIcon.visa2x,
    icon3x: cardIcon.visa3x,
    alt: 'Visa logo',
  },
};

export const getIcon = (cardProvider: string, attached?: boolean): JSX.Element => {
  let cardType;

  if (cardProvider in cardTypes) {
    cardType = cardTypes[cardProvider];
  } else {
    cardType = cardTypes.placeholder;
  }

  return (
    <Box position="relative" display="flex" alignItems="center">
      <img
        id={cardType.id}
        src={`${cardType.icon1x}`}
        srcSet={`${cardType.icon1x} 1x, ${cardType.icon2x} 2x, ${cardType.icon3x} 3x`}
        alt={cardType.alt}
      />
      {attached && (
        <AttachedIcon size="small" variant="dark" />
      )}
    </Box>
  );
};
