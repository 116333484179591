import React from 'react';
import { bool } from 'prop-types';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

import HowDoesPwmWorkRegistered from './HowDoesPwmWorkRegistered';
import HowDoesPwmWorkUnregistered from './HowDoesPwmWorkUnregistered';

const HowDoesPwmWorkContainer = styled('ul')`
  & > li {
    padding-bottom: ${({ theme }) => theme.space.l};
  }
  & > li:last-child {
    padding-bottom: 0;
  }
`;

const HowDoesPwmWork = ({ isUnregisteredMode }) => (
  <Box position="relative">
    <HowDoesPwmWorkContainer>
      {
        (isUnregisteredMode ? <HowDoesPwmWorkUnregistered /> : <HowDoesPwmWorkRegistered />)
      }
    </HowDoesPwmWorkContainer>
  </Box>
);

HowDoesPwmWork.propTypes = {
  isUnregisteredMode: bool,
};

HowDoesPwmWork.defaultProps = {
  isUnregisteredMode: false,
};

export default HowDoesPwmWork;
