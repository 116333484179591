import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { initializeUserPreferences } from '../PreferencesActions';

import Loading from '../../app/components/Loading';

const UserPreferencesInitializer: React.FC<{}> = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      await dispatch(initializeUserPreferences());
      setLoaded(true);
    };
    initialize();
  }, [dispatch]);

  return loaded ? children as React.ReactElement : (
    <Loading>
      <FormattedMessage id="userPreferenceInitializer.loading" defaultMessage="Initializing preferences" />
    </Loading>
  );
};

export default UserPreferencesInitializer;
