import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';

import { dismissOtherBreachesBanner } from '../SecurityStatusActions';

import Banner from '../../componentLib/Banner';

import info from '../../img/icon-info.png';
import info2x from '../../img/icon-info@2x.png';

type Props = {
  otherBreachesBannerDismissed: boolean;
  overrideBannerVisibility: boolean;
}

const OtherBreachesBanner: React.FC<Props> = ({
  otherBreachesBannerDismissed,
  overrideBannerVisibility,
}) => {
  const dispatch = useDispatch();
  const showBanner = overrideBannerVisibility && !otherBreachesBannerDismissed;

  return (
    showBanner ? (
      <Box mr="s" flex="0 0 auto">
        <Banner
          onClose={() => {
            dispatch(dismissOtherBreachesBanner());
          }}
        >
          <Box
            my="s"
            mr="xxxl"
            display="flex"
            alignItems="center"
          >
            <Box
              mr="m"
              display="flex"
              alignItems="center"
              flex="1 0 auto"
            >
              <img
                src={info}
                srcSet={`${info} 1x, ${info2x} 2x`}
                alt="hint"
              />
            </Box>
            <Text>
              <FormattedHTMLMessage id="dashboard.securityStatus.otherBreaches.banner" />
            </Text>
          </Box>
        </Banner>
      </Box>
    ) : null
  );
};

export default OtherBreachesBanner;
