import {
  SET_ACTIVE_TAB, SetActiveTab,
} from './MyDataActionTypes';

export type State = {
  activeTab: string;
}

function getInitialState(): State {
  return {
    activeTab: 'passwords',
  };
}

export type Actions = SetActiveTab;

export default function dashboardReducer(
  state: State = getInitialState(),
  action: Actions,
): State {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.value,
      };
    default:
      return state;
  }
}
