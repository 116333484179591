import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';

import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import SecurityStatus from './SecurityStatus';
import SecurityStatusListItemContainer from './SecurityStatusListItemContainer';
import SpotlightUpsellBanner from './SpotlightUpsellBanner';

import {
  searchQueryLabelDomain, searchQueryUsernameEmail,
  sortOptions, defaultSortBy, proDefaultSortby, FILTER_ALL, FILTER_TO_IMPROVE,
} from '../../lib/SecurityStatusHelper';
import { MyDataContainer } from '../../myData';
import confirmDeleteIcon from '../../img/icon-delete-big.png';
import giftBox from '../../img/gift_box_red.png';
import giftBox2x from '../../img/gift_box_red@2x.png';
import ActivateHibpBanner from './ActivateHibpBanner';
import OtherBreachesBanner from './OtherBreachesBanner';
import PasswordDayBanner from './PasswordDayBanner';
import CoronaSpecialBanner from './CoronaSpecialBanner';
import UnlockProBanner from '../../componentLib/UnlockProBanner';
import { isChangeYourPasswordWeek, isCoronaSpecial } from '../../user/selectors';
import config from '../../config';
import intlShape from '../../lib/intlShape';


const getSecurityCount = (count, classExists) => (count === 0 && classExists ? '-' : count);

class SecurityStatusPage extends React.Component {
  static propTypes = {
    otherBreachesBannerDismissed: PropTypes.bool,
    breachCheck: PropTypes.bool,
    breachCheckBannerDismissed: PropTypes.bool,
    securityStatus: PropTypes.shape({
      metadata: PropTypes.shape({
        scorePercent: PropTypes.number,
        allAccounts: PropTypes.number,
        ignored: PropTypes.number,
        reused: PropTypes.number,
        reusedExists: PropTypes.bool,
        toImprove: PropTypes.number,
        toImproveExists: PropTypes.bool,
        breached: PropTypes.number,
        breachedExists: PropTypes.bool,
        passwordStrength: PropTypes.number,
        passwordStrengthExists: PropTypes.bool,
        unsafeWebsite: PropTypes.number,
        unsafeWebsiteExists: PropTypes.bool,
        fakeAccounts: PropTypes.number,
      }).isRequired,
      accountsSecurityStatus: PropTypes.shape({}).isRequired,
    }).isRequired,
    securityStatusPreferences: PropTypes.shape({
      scrollPosition: PropTypes.number.isRequired,
    }).isRequired,
    setScrollPosition: PropTypes.func.isRequired,
    onBannerClose: PropTypes.func.isRequired,
    onBannerActivate: PropTypes.func.isRequired,
    hasAccessToPro: PropTypes.bool.isRequired,
    setShowcaseAccountId: PropTypes.func.isRequired,
    trackSecurityStatusDisplay: PropTypes.func.isRequired,
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }).isRequired,
    usernamesLoadedPercent: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
    onDeleteUnknownBreachClick: PropTypes.func.isRequired,
    onDeleteAccountClick: PropTypes.func.isRequired,
    showGetProFree: PropTypes.bool.isRequired,
    isPro: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    otherBreachesBannerDismissed: false,
    breachCheck: false,
    breachCheckBannerDismissed: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentRevealPasswordId: '',
      confirmDialogKey: 'deleteAccount',
      showDeleteConfirmDialog: false,
      confirmDialogLoading: false,
      accountToDelete: '',
      breachKeyToDelete: '',
    };

    const {
      onDeleteAccountClick,
      onDeleteUnknownBreachClick,
      setShowcaseAccountId,
    } = this.props;

    this.state = {
      ...this.state,
      confirmDialogMap: {
        deleteAccount: {
          dialogMessage: (
            <FormattedMessage
              id="dashboard.account.details.confirmDelete"
            />
          ),
          action: () => onDeleteAccountClick(this.state.accountToDelete),
        },
        deleteUnknownBreach: {
          dialogMessage: (
            <FormattedMessage
              id="dashboard.securityStatus.hibp.breachedUnknown.confirmDelete"

              defaultMessage="Are you sure you want to remove this account from the breached unknown list?"
            />
          ),
          action: () => onDeleteUnknownBreachClick({ breachKey: this.state.breachKeyToDelete }),
        },
      },
    };

    setShowcaseAccountId();
    this.getBanner = this.getBanner.bind(this);
    this.isCalculating = this.isCalculating.bind(this);
    this.setCurrentRevealPassword = this.setCurrentRevealPassword.bind(this);
    this.onActivate = this.onActivate.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteUnknownBreachClick = this.onDeleteUnknownBreachClick.bind(this);
    this.onDeleteAccountClick = this.onDeleteAccountClick.bind(this);
  }

  componentDidMount() {
    const { trackSecurityStatusDisplay } = this.props;
    trackSecurityStatusDisplay();
  }

  onActivate() {
    const { onBannerActivate } = this.props;
    onBannerActivate(true);
  }

  onDeleteUnknownBreachClick(breachKey) {
    this.setState({ showDeleteConfirmDialog: true, breachKeyToDelete: breachKey, confirmDialogKey: 'deleteUnknownBreach' });
  }

  onDeleteAccountClick(id) {
    this.setState({ showDeleteConfirmDialog: true, accountToDelete: id, confirmDialogKey: 'deleteAccount' });
  }

  onDeleteConfirm() {
    const { confirmDialogMap, confirmDialogKey } = this.state;
    const { setShowcaseAccountId } = this.props;
    const { action } = confirmDialogMap[confirmDialogKey];
    this.setState({ confirmDialogLoading: true }, async () => {
      await action();
      setShowcaseAccountId();
      this.setState({ showDeleteConfirmDialog: false, confirmDialogLoading: false });
    });
  }

  onDeleteCancel() {
    this.setState({ showDeleteConfirmDialog: false });
  }

  setCurrentRevealPassword(id, visible) {
    if (visible) {
      this.setState({ currentRevealPasswordId: id });
    } else {
      this.setState({ currentRevealPasswordId: '' });
    }
  }

  getBanner() {
    const { isPro } = this.props;
    if (isPro) {
      const {
        breachCheck,
        breachCheckBannerDismissed,
        location,
        otherBreachesBannerDismissed,
        onBannerClose,
      } = this.props;
      const { tag } = queryString.parse(location.search);
      return (
        <>
          <ActivateHibpBanner
            onClose={onBannerClose}
            onActivate={this.onActivate}
            breachCheck={breachCheck}
            breachCheckBannerDismissed={breachCheckBannerDismissed}
            overrideBannerVisibility={tag === 'breached'}
          />
          <OtherBreachesBanner
            onClose={onBannerClose}
            onActivate={this.onActivate}
            breachCheck={breachCheck}
            otherBreachesBannerDismissed={otherBreachesBannerDismissed}
            overrideBannerVisibility={tag === 'fakeAccount'}
          />
        </>
      );
    }
    if (isChangeYourPasswordWeek()) {
      return <PasswordDayBanner visible />;
    }
    if (isCoronaSpecial()) {
      return <CoronaSpecialBanner />;
    }
    return null;
  }

  isCalculating() {
    const { usernamesLoadedPercent } = this.props;
    return usernamesLoadedPercent < 100;
  }

  // eslint-disable-next-line max-statements, complexity
  render() {
    const {
      securityStatus, location, usernamesLoadedPercent, hasAccessToPro,
      showGetProFree, setScrollPosition, securityStatusPreferences, intl,
    } = this.props;
    const { tag } = queryString.parse(location.search);

    const {
      confirmDialogKey,
      confirmDialogLoading,
      confirmDialogMap,
      currentRevealPasswordId,
      showDeleteConfirmDialog,
    } = this.state;

    const { dialogMessage } = confirmDialogMap[confirmDialogKey];

    const {
      allAccounts,
      scorePercent,
      ignored,
      reused,
      reusedExists,
      toImprove,
      toImproveExists,
      breached,
      breachedExists,
      passwordStrength,
      passwordStrengthExists,
      unsafeWebsite,
      unsafeWebsiteExists,
      fakeAccounts,
    } = securityStatus.metadata;

    const showIgnored = ignored > 0 || tag === 'ignoreWarnings';

    const accountsToImprove = getSecurityCount(toImprove, toImproveExists);
    const passwordsReused = getSecurityCount(reused, reusedExists);
    const passwordsStrength = getSecurityCount(passwordStrength, passwordStrengthExists);
    const breachedAccounts = getSecurityCount(breached, breachedExists);
    const unsafeWebsites = getSecurityCount(unsafeWebsite, unsafeWebsiteExists);

    const checkWarningsFilterFn = data => !data.ignoreWarnings;
    const isFakeAccount = data => !!data.fakeAccount;

    const tags = [{
      id: 'all',
      count: allAccounts,
      label: 'dashboard.securityStatus.filter.all',
      disabled: false,
      filter: data => !isFakeAccount(data),
    }, {
      id: 'toImprove',
      count: accountsToImprove,
      label: 'dashboard.securityStatus.filter.improve',
      disabled: (accountsToImprove === 0 || accountsToImprove === '-'),
      filter: data => !!data.toImprove && checkWarningsFilterFn(data) && !isFakeAccount(data),
    }, {
      id: 'breached',
      count: breachedAccounts,
      label: 'dashboard.securityStatus.filter.breached',
      disabled: (breachedAccounts === 0 || breachedAccounts === '-'),
      filter: data => !!data.breached && checkWarningsFilterFn(data) && !isFakeAccount(data),
    }, {
      id: 'unsafeWebsite',
      count: unsafeWebsites,
      label: 'dashboard.securityStatus.filter.unsafe',
      disabled: (unsafeWebsites === 0 || unsafeWebsites === '-'),
      filter: data => !!data.unsafeWebsite && checkWarningsFilterFn(data) && !isFakeAccount(data),
    }, {
      id: 'passwordStrength',
      count: passwordsStrength,
      label: 'dashboard.securityStatus.filter.passwordStrength',
      disabled: (passwordsStrength === 0 || passwordsStrength === '-'),
      filter: data => (
        !!data.passwordStrength && checkWarningsFilterFn(data) && !isFakeAccount(data)
      ),
    }, {
      id: 'reused',
      count: passwordsReused,
      label: 'dashboard.securityStatus.filter.reusedPasswords',
      disabled: (passwordsReused === 0 || passwordsReused === '-'),
      filter: data => !!data.reused && checkWarningsFilterFn(data) && !isFakeAccount(data),
    }];

    if (showIgnored) {
      tags.push({
        id: 'ignoreWarnings',
        count: ignored,
        label: 'dashboard.securityStatus.filter.ignoredAccounts',
        disabled: false,
        filter: data => !!data.ignoreWarnings && !isFakeAccount(data),
      });
    }

    if (fakeAccounts) {
      tags.push({
        id: 'fakeAccount',
        count: fakeAccounts,
        label: 'dashboard.securityStatus.filter.otherBreaches',
        disabled: false,
        separate: true,
        filter: isFakeAccount,
      });
    }

    let banner = null;

    if (hasAccessToPro) {
      banner = this.getBanner();
    }

    return (
      <MyDataContainer
        securityStatus={(
          <SecurityStatus
            score={scorePercent}
            disabled={(scorePercent === 0 && allAccounts === 0)}
            ignored={(allAccounts === ignored)}
            calculating={this.isCalculating()}
            loadedPercent={usernamesLoadedPercent}
          />
        )}
        header={<FormattedMessage id="dashboard.securityStatus.title" defaultMessage="Security Status" />}
        list={securityStatus.accountsSecurityStatus}
        listItem={(
          <SecurityStatusListItemContainer
            currentRevealPasswordId={currentRevealPasswordId}
            setCurrentRevealPassword={this.setCurrentRevealPassword}
            isCalculating={this.isCalculating}
            onDeleteAccountClick={this.onDeleteAccountClick}
            onDeleteUnknownBreachClick={this.onDeleteUnknownBreachClick}
          />
        )}
        afterScroll={setScrollPosition}
        scrollPosition={securityStatusPreferences.scrollPosition}
        sortBy={hasAccessToPro ? proDefaultSortby : defaultSortBy}
        sortOptions={sortOptions}
        tags={tags}
        tagStyle="select"
        searchQueryMain={searchQueryLabelDomain}
        searchQuerySub={searchQueryUsernameEmail}
        onSortByChange={() => { }}
        emptyListMessage="dashboard.securityStatus.empty"
        emptySearchMessage="dashboard.accounts.search.empty"
        defaultSortBy={defaultSortBy}
        defaultFilter={accountsToImprove ? FILTER_TO_IMPROVE : FILTER_ALL}
        hideExtensionMessage
        banner={banner}
        hideSearch={!hasAccessToPro}
        securityStatusPromoBanner={showGetProFree
          ? <UnlockProBanner page="security-status" />
          : null}
        securityStatusGiftBox={showGetProFree
          ? (
            <img
              className="c-upsell__gift-box"
              src={giftBox}
              srcSet={`${giftBox} 1x, ${giftBox2x} 2x`}
              alt="giftBox"
            />
          )
          : null}
      >
        <ConfirmationDialog
          show={showDeleteConfirmDialog}
          confirmButtonType="alert"
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.delete' })}
          cancelLabel={intl.formatMessage({ id: 'dashboard.account.details.cancel' })}
          onConfirm={this.onDeleteConfirm}
          onCancel={this.onDeleteCancel}
          loading={confirmDialogLoading}
        >
          <Box mb="l">
            <Paragraph mb="l" textAlign="center">
              <img src={confirmDeleteIcon} alt="Delete Password" />
            </Paragraph>
            {dialogMessage}
          </Box>
        </ConfirmationDialog>
        {
          config.spotlight
          && !hasAccessToPro
          && <SpotlightUpsellBanner />
        }
      </MyDataContainer>
    );
  }
}

export default withRouter(injectIntl(SecurityStatusPage));
