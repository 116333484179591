import * as Sentry from '@sentry/browser';

import { SyncUtils } from '@avira-pwm/sync';
import ServerDate from '@avira-pwm/helpers/ServerDate';
import * as notesActions from '@avira-pwm/redux/notes';
import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ActionWrapper } from '@avira-pwm/redux/createEntityModule';

import {
  NOTES_PREFERENCES_SCROLLPOSITION, UPDATE_NOTE_DETAILS,
} from './NoteActionTypes';
import { UPDATE_NOTE_SORT_BY } from '../preferences/PreferencesActionTypes';
import { TrackingActions, MixpanelEvents } from '../tracking';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { getRelevantUserKey } from '../user/selectors';
import { ThunkExtraArgument } from '../app/thunk';
import { handleSyncError } from '../lib/ErrorHelper';

const { trackEvent } = TrackingActions;
const {
  MP_NOTE_CREATED,
  MP_NOTE_EDITED,
  MP_NOTE_DELETED,
  MP_NOTES_LIST_SORTED,
} = MixpanelEvents;

type E = ThunkExtraArgument;

export const syncGetNotes: ActionWrapper<typeof notesActions.getAll, {}, E> = (
  () => async (dispatch, getState, { syncInstance }) => {
    try {
      dispatch(notesActions.getAll({ sync: syncInstance, key: getRelevantUserKey(getState()) }));
    } catch (e) {
      Sentry.captureException(e);
    }
  }
);

export const triggerNoteCreation: ActionWrapper<typeof notesActions.create, {}, E> = (
  (id, noteProps) => async (
    dispatch, getState, { syncInstance },
  ) => {
    try {
      await dispatch(
        notesActions.create(
          { sync: syncInstance, key: getRelevantUserKey(getState()) },
          id,
          noteProps,
        ),
      );
    } catch (e) {
      handleSyncError(e, 'note:triggerNoteCreation');
    }

    dispatch(trackEvent(MP_NOTE_CREATED, {
      NoteLength: (noteProps.notes || '').trim().length,
      ColorChanged: !!noteProps.color,
      FavoriteFlag: noteProps.favorite,
    }));
  }
);

export const updateNote: ActionWrapper<typeof notesActions.update, {}, E> = (
  (id, noteChangedProps) => async (
    dispatch, getState, { syncInstance },
  ) => {
    const state = getState();

    try {
      await dispatch(notesActions.update(
        { sync: syncInstance, key: getRelevantUserKey(state) },
        id,
        noteChangedProps,
      ));
    } catch (e) {
      handleSyncError(e, 'note:updateNote');
    }

    const { noteDetails }: any = state;
    const updatedNote = { ...noteDetails.note, ...noteChangedProps };
    dispatch(trackEvent(MP_NOTE_EDITED, {
      NoteLength: (updatedNote.notes || '').trim().length,
      ColorChanged: noteDetails.note.color !== updatedNote.color,
      FavoriteFlag: updatedNote.favorite,
    }));
  }
);

export const syncDeleteNote: ActionWrapper<typeof notesActions.deleteData, {}, E> = (
  noteId => async (dispatch, getState, { syncInstance }) => {
    const state = getState();
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(state));
    try {
      await SyncUtils.deleteData(sync, 'Note', noteId);
    } catch (e) {
      handleSyncError(e, 'note:syncDeleteNote');
    }
    dispatch(trackEvent(MP_NOTE_DELETED));
  }
);

export const toggleNoteFavorite = (
  (note: notesActions.Entity): ThunkAction<void, {}, E, AnyAction> => (dispatch) => {
    dispatch(updateNote(note.id, { favorite: !note.favorite }));
  }
);

export const updateNoteDetails = (
  (noteId: string): ThunkAction<void, { notes: notesActions.State }, E, AnyAction> => (
    async (dispatch, getState) => {
      const { notes } = getState();
      const note = notes[noteId];
      // update note history here in the not too distant future
      dispatch({ type: UPDATE_NOTE_DETAILS, value: { note } });
    }
  )
);

const ALLOWED_FILTERS = ['all', 'favorite'];

export const setNotesSortBy = (
  sortType: string,
  filter: string,
): ThunkAction<void, any, E, AnyAction> => (
  (dispatch) => {
    dispatch(trackEvent(MP_NOTES_LIST_SORTED, {
      sortedBy: sortType,
      filterBy: ALLOWED_FILTERS.includes(filter) ? filter : 'custom',
    }));
    dispatch({ type: UPDATE_NOTE_SORT_BY, value: sortType });
  }
);

export const setScrollPosition = (scrollPosition: number): AnyAction => (
  { type: NOTES_PREFERENCES_SCROLLPOSITION, value: scrollPosition }
);

export const clearNotes: ActionWrapper<typeof notesActions.clear, {}, E> = () => (dispatch) => {
  dispatch(notesActions.clear());
};

export const updateLastUsedAt: ActionWrapper<typeof notesActions.updateLastUsedAt, {}, E> = (
  (id: string) => async (dispatch, getState, { syncInstance }) => {
    const now = new ServerDate().toISOString();

    try {
      await dispatch(
        notesActions.updateLastUsedAt(
          { sync: syncInstance, key: getRelevantUserKey(getState()) },
          id,
          now,
        ),
      );
    } catch (e) {
      handleSyncError(e, 'note:updateLastUsedAt');
    }
  }
);

export const updateModifiedAt: ActionWrapper<typeof notesActions.updateLastUsedAt, {}, E> = (
  (id: string, newDate: string) => async (_dispatch, getState, { syncInstance }) => {
    const updatedMetadata = {
      modifiedAt: newDate,
    };
    const sync = getSyncInstance(syncInstance, getRelevantUserKey(getState()));
    try {
      await sync.updateMetadata('Note', id, updatedMetadata);
    } catch (e) {
      handleSyncError(e, 'note:updateModifiedAt');
    }
  }
);
