/* eslint-disable no-unused-vars */
//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// Change History :
// Wed Feb 20 2019 Rahuraman Adding to Git.
//

import constants from './VTConstants';
import nodeSync from './VTNodeSync';
import vaultManager from './VTVaultManager';

const { SymBfw, SymO2 } = global;
const {
  logger,
  utils: {
    isNil, isntNil, isArray, isSuccessCode
  }
} = SymBfw;

const { TAG } = constants;

/**
 * @typedef {import('./VTItem').default} Item
 */

class ItemManager {
  /**
    * @constructor ItemManager
    * @desc ItemManager interface.
    * @param {string} name manager name
    */
  constructor(name) {
    this.name = name;
  }

  /**
    * @function getName
    * @desc Name of the manager.
    * @return Returns the name of the manager
    */
  getName() {
    return this.name;
  }

  /**
    * @function addItem
    * @desc addItem to the manager's collection
    * @param {Item} item The item you want to add.
    * @param {boolean} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async addItem(item, shouldSyncToCloud = true) {
    if (isNil(item)) {
      throw new Error('Item cannot be null');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const guid = item.getPath();
    if (isNil(guid)) {
      logger.error('GUID is not found. Item has not been created correctly');
      return;
    }
    const currentItem = this.items[guid];
    if (isntNil(currentItem)) {
      throw new Error('Item is already added. If you are updating an item please use the updateItem API');
    }

    this.items[guid] = item;

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(item.clone().serializeNode(), null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
  * @async
  * @function addItems
  * @desc Add the Items to the vault and sync to cloud if required
  * @param {object[]} items Array of items to be added to the Vault
  * @param {boolean} shouldSyncToCloud A boolean denoting
  *         whether to sync the data to cloud or not. Defaults to true
  * @throws If any error occurs while adding the items
  * @validates items to be a valid and items doesn't already exist
  */
  async addItems(items, shouldSyncToCloud = true) {
    if (!isArray(items)) {
      throw new Error('Items must be an array');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const nodeList = [];
    for (const key in items) {
      if (Object.prototype.hasOwnProperty.call(items, key)) {
        const item = items[key];
        const guid = item.getPath();
        if (isNil(guid)) {
          logger.error('GUID is not found. Item has not been created correctly');
          throw new Error('GUID is not found. Item has not been created correctly');
        }
        const currentItem = this.items[guid];
        if (isntNil(currentItem)) {
          throw new Error('Item is already added. If you are updating an item please use the updateItem API');
        }

        this.items[guid] = item;
        nodeList.push(item.clone().serializeNode());
      }
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
   * @desc handles uploadeNodes response
   * @param {object} obj
   * @param {number} obj.status
   * @param {any?} obj.error
   * @param {{ [key: string]: string }} obj.responseHeaders
   */
  handleErrorResponse({ status, error, responseHeaders }) {
    let errorList;
    let requestId;

    try {
      if (isntNil(responseHeaders)) {
        if (
          isntNil(responseHeaders[SymO2.constants.header.SCHEMA_ERROR])
          && isntNil(SymO2.utils.parseSchemaError)
        ) {
          errorList = SymO2.utils.parseSchemaError(responseHeaders[SymO2.constants.header.SCHEMA_ERROR]);
        }

        if (isntNil(responseHeaders[SymO2.constants.header.REQUEST_ID])) {
          requestId = responseHeaders[SymO2.constants.header.REQUEST_ID];
        }
      }
    } catch (e) { /** no need to do anything if this fails */ }


    const uploadResult = {
      requestId,
      status,
      error,
      errorList,
    };

    throw uploadResult;
  }

  /**
    * @desc The callback definition for search
    * @param {array} Returns an array of results dictionary.
    * @param {object} error Error that was thrown
    * @callback searchResultsCallback
    */

  /**
    * @async
    * @function search
    * @desc Searches the collection given the input key from UI.
    * @param {string} key The key you want to search on.
    * @param {boolean} isFavourite if the isFavourite=ture then will search only favoite.
    * @returns {Promise<{ [key: string]: string[] } | string[]>} Returns the items matches the key.
    */
  async search(key, isFavourite) {
    // This method has no implementation. It should be overridden in derived class
    throw new Error('This method has to be implemented in derived class');
  }

  /**
    * @function getItem
    * @desc Returns the item given the input guid.
    * @param {string} guid The guid that identifies the item.
    * @returns {Item} Returns the item you are looking for.
    */
  getItem(guid) {
    if (isNil(guid)) {
      throw new Error('GUID cannot be null');
    }

    return this.items[guid];
  }

  /**
    * @function getAllItems
    * @desc Returns the entire collection as is.
    * @returns {object} Returns the entire items collection that is owned by the current manager.
    */
  getAllItems() {
    return this.items;
  }

  /**
    * @function updateItem
    * @desc Update an item
    * @param {Item} item The item you want to update.
    * @param {boolean} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async updateItem(item, shouldSyncToCloud = true) {
    if (isNil(item)) {
      throw new Error('Item cannot be null');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const guid = item.getPath();

    const currentItem = this.items[guid];
    if (isNil(currentItem)) {
      throw new Error('New item is being added. Please use the addItem interface');
    }

    this.items[guid] = item;

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(item.clone().serializeNode(), null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
   * @async
   * @function updateItems
   * @param items {Item[]} Array of items to be updated
   * @param shouldSyncToCloud {boolean} whether to sync the data to cloud or not. Defaults to true
   * @return {Promise<void>}
   */
  async updateItems(items, shouldSyncToCloud = true) {
    if (!isArray(items)) {
      throw new Error('Item must be an array.');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const nodeList = [];

    for (const item of items) {
      const guid = item.getPath();
      if (isNil(guid)) {
        throw new Error('GUID is not found. Item has not been created correctly');
      }
      this.items[guid] = item;
      nodeList.push(item.clone().serializeNode());
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
    * @function postSyncSetup
    * @desc Performs post sync setup processing.
    */
  postSyncSetup() {
    for (const key in this.items) {
      if ({}.hasOwnProperty.call(this.items, key)) {
        const item = this.items[key];
        if (item.encrypted) {
          item.decrypt();
        }
      }
    }
  }

  /**
    * @function deleteItem
    * @desc Deletes an item from the collection
    * @param {string} guid The guid for the item.
    * @param {bool} shouldSync lets us know if we should sync
    * this item to the cloud. Defaults to true.
    */
  async deleteItem(guid, shouldSyncToCloud = true) {
    if (isNil(guid)) {
      return;
    }

    const item = this.items[guid];
    if (isNil(item)) {
      logger.info('No item found to delete');
      return;
    }

    const node = item.serializeNode(true);
    delete this.items[guid];

    if (isNil(shouldSyncToCloud)) {
      return;
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(node, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }

    await this.removeTagItems([guid], shouldSyncToCloud);
  }

  /**
    * @async
    * @function deleteAllItems
    * @desc Deletes all the items from the collection
    * @param {bool} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async deleteAllItems(shouldSyncToCloud = true) {
    const nodeList = [];
    const itemGuids = [];
    for (const key in this.items) {
      if (Object.prototype.hasOwnProperty.call(this.items, key)) {
        const node = this.items[key].serializeNode(true);
        itemGuids.push(key);
        delete this.items[key];
        nodeList.push(node);
      }
    }
    if (nodeList.length === 0) {
      // nothing to delete.
      return;
    }

    if (isNil(shouldSyncToCloud)) {
      return;
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
    await this.removeTagItems(itemGuids, shouldSyncToCloud);
  }


  /**
    * @async
    * @function deleteItems
    * @desc Deletes all the guids from the Array
    * @param {bool} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    * @return {Promise<void>}
    */

  async deleteItems(guids, shouldSyncToCloud = true) {
    if (!isArray(guids)) {
      throw new Error('guids must be an array.');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const nodeList = [];
    for (const key in guids) {
      if (Object.prototype.hasOwnProperty.call(guids, key)) {
        const item = this.items[guids[key]];
        if (isNil(item)) {
          logger.error('GUID is not found. No item found to delete');
          throw new Error('GUID is not found. No item found to delete');
        }
        const node = item.serializeNode(true);
        delete this.items[guids[key]];
        nodeList.push(node);
      }
    }
    if (nodeList.length === 0) {
      // nothing to delete.
      logger.error('No item found to delete');
      throw new Error('No item found to delete');
    }
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }

    await this.removeTagItems(guids, shouldSyncToCloud);
  }

  /**
   * @function removeTagItems
   * @desc removes tag item
   * @param {guids} item guid
   * @returns none
  */
  async removeTagItems(guids, shouldSyncToCloud) {
    if (isNil(this.tagType) || !isArray(guids)) {
      return;
    }

    for (const guid of guids) {
      await this._removeItemFromTag(guid, this.tagType, shouldSyncToCloud);
    }
  }

  /**
   * @function getAllTags
   * @desc returns tag object that contains items
   * @param {none}
   * @returns {tag}
  */
  getAllTags() {
    const tagManagerItems = vaultManager.getItemManager(vaultManager.TAG_MANAGER).getAllItems();
    const tagItems = {};
    for (const key of Object.getOwnPropertyNames(tagManagerItems)) {
      if (tagManagerItems[key][this.tagType].length != 0) {
        tagItems[key] = tagManagerItems[key];
      }
    }
    return tagItems;
  }

  /**
   * @function _removeItemFromTag
   * @desc deletes tags associated with the item
   * @param {guid} - item guid
   * @param {itemType} - item type
   * @returns {none}
  */
  async _removeItemFromTag(guid, itemType, shouldSyncToCloud = true) {
    if (isNil(guid) && isNil(itemType)) {
      return;
    }
    const tagManager = vaultManager.getItemManager(vaultManager.TAG_MANAGER);
    const tags = tagManager.getTagsByItemId(guid, itemType);
    if (isNil(tags)) {
      return;
    }
    for (const tag of Object.getOwnPropertyNames(tags)) {
      await tagManager.deleteTagItem(tag, itemType, guid, shouldSyncToCloud);
    }
  }
}

export default ItemManager;
