import { createSelector } from 'reselect';

// eslint-disable-next-line import/prefer-default-export
export const groupAccountsByPassword = createSelector(
  state => state.accounts,
  accounts => Object.values(accounts).reduce((hash, { domain, label, password }) => {
    const newHash = { ...hash };
    newHash[password] = newHash[password] || [];
    newHash[password].push(label || domain);
    return newHash;
  }, {}),
);
