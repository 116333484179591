import PropTypes from 'prop-types';

import styled from 'pwm-components/styled';

const FakeInputField = styled('input')`
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  top: -10000px;
  left: -10000px;
`;

FakeInputField.propTypes = {
  defaultValue: PropTypes.string,
  type: PropTypes.string.isRequired,
};

FakeInputField.defaultProps = {
  defaultValue: '',
  tabIndex: -1,
};

export default FakeInputField;
