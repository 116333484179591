import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';

import { usePrevious } from '../../lib/hooks';

type InternalState = {
  [key: string]: string | Function;
};

type SetParam = <T extends string | { [key: string]: string | null }>(
  param: T,
  val?: T extends string ? string | null : never,
) => void;

type State = InternalState & {
  setParam: SetParam;
};

const Context = React.createContext<State>({
  setParam: () => {},
});

export default Context;

const { Provider: SearchParamsProvider } = Context;

export const { Consumer } = Context;

function getNewState(obj: InternalState, searchString: string): InternalState {
  const search = new URLSearchParams(searchString);
  const state: InternalState = {
    ...obj,
  };

  for (const [key, val] of search) {
    state[key] = val;
  }

  return state;
}

export const Provider: React.FC = ({ children }) => {
  const history = useHistory();

  const [params, setParams] = useState<InternalState>(
    getNewState({}, history.location.search),
  );

  const setParam = useCallback<SetParam>(
    (param, val) => {
      if (param === 'setParam') {
        return;
      }

      const newState = {
        ...params,
      };

      const values = Object.entries(
        typeof param === 'string' ? { [param]: val } : param,
      );

      values.forEach(([key, value]) => {
        if (value == null) {
          delete newState[key];
        } else {
          newState[key] = value;
        }
      });

      const { ...rest } = newState;

      const search = new URLSearchParams(
        rest as Record<string, string>,
      ).toString();

      history.push({
        pathname: history.location.pathname,
        search,
      });

      setParams(newState);
    },
    [history, params],
  );

  const previousSearch = usePrevious(history.location.search);

  const finalState = useMemo<State>(
    () => ({
      ...params,
      setParam,
    }),
    [params, setParam],
  );

  useEffect(() => {
    if (history.location.search !== previousSearch) {
      setParams(p => getNewState(p, history.location.search));
    }
  }, [history.location.search, previousSearch]);

  return (
    <SearchParamsProvider value={finalState}>{children}</SearchParamsProvider>
  );
};

export const withSearchParams = <P extends object>(Component: React.ComponentType<P>) => (
  (props: P) => (
    <Consumer>
      {params => <Component {...props} searchParams={params} />}
    </Consumer>
  )
);
