import React from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

import AuthPageWrapper from '../oe/components/AuthPageWrapper';

import iconUnlock from '../img/icon-unlock.png';
import iconUnlock2x from '../img/icon-unlock@2x.png';

const PwmIconContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;

  @media screen and (max-width: 500px) {
    margin: 24px 16px;

    & > img {
      visibility: hidden;
      height: 24px;
    }
  }
`;

const SpotlightConnectContainer: React.FC = ({ children }) => (
  <AuthPageWrapper
    Page={() => (
      <Box display="flex" flexDirection="column" height="100%">
        <PwmIconContainer>
          <img
            src={iconUnlock}
            srcSet={`${iconUnlock} 1x, ${iconUnlock2x} 2x`}
            alt="Icon unlock"
          />
        </PwmIconContainer>
        { children }
      </Box>
    )}
  />
);

export default SpotlightConnectContainer;
