import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Type } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';
import * as Sentry from '@sentry/browser';
import { syncDeleteCreditCard, updateCreditCard, updateModifiedAt } from '../../WalletActions';
import AddEditPage from './AddEditPage';

type Props = {
  creditCard: Flattened<Type>;
  files: any;
  params: { id: string };
  handleSubmit: (id: string, changedCreditCardProps: Record<string, string>) => void;
  onDelete: () => void;
  favoriteClickHandler: (id: string, value: {favorite: boolean}) => void;
}

const EditCreditCard = (props: Props): JSX.Element => {
  const {
    creditCard,
    files,
    handleSubmit,
    onDelete,
    favoriteClickHandler,
  } = props;

  const onSubmit = async (
    id: string, changedCreditCardProps: Record<string, string>,
  ): Promise<void> => {
    try {
      await handleSubmit(id, changedCreditCardProps);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const onFavoriteClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    const value = { favorite: !creditCard.favorite };
    favoriteClickHandler(creditCard.id, value);
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <AddEditPage
      data={creditCard}
      files={files}
      onDelete={onDelete}
      onSaveItemClick={onSubmit}
      onFavoriteClick={onFavoriteClick}
    />
  );
};

const mapStateToProps = (state: any, ownProps: Props): any => ({
  creditCard: state.creditCards[ownProps.params.id],
  files: state.files,
});


const mapDispatchToProps = (dispatch: Dispatch): any => bindActionCreators({
  handleSubmit: updateCreditCard,
  onDelete: syncDeleteCreditCard,
  onModified: updateModifiedAt,
  favoriteClickHandler: updateCreditCard,
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(EditCreditCard);
