import React from 'react';
import PropTypes from 'prop-types';
import Box from 'pwm-components/components/Box';
import Checkbox from 'pwm-components/components/Checkbox';

import Card from '../../componentLib/Card';
import Icon from '../../componentLib/Icon';
import ScrollingTable from '../../componentLib/ScrollingTable';
import Select from '../../componentLib/Select';

import { calculateHeight } from '../../lib/ElementHelper';
import { STATUS_MAPPING, mapStatus } from '../../lib/importHelper';

import ImporterAccountMapping, { autoAccountMapping } from './ImporterAccountMapping';
import intlShape from '../../lib/intlShape';

class ImporterTable extends React.Component {
  static propTypes = {
    allowMapping: PropTypes.bool.isRequired,
    deselected: PropTypes.arrayOf(PropTypes.number).isRequired,
    intl: intlShape.isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
    itemsType: PropTypes.array.isRequired,
    itemStatus: PropTypes.array.isRequired,
    mapping: PropTypes.shape({
      notes: PropTypes.array.isRequired,
    }).isRequired,
    onChangeItem: PropTypes.func.isRequired,
    onChangeAllItems: PropTypes.func.isRequired,
    onChangeMapping: PropTypes.func.isRequired,
    onMount: PropTypes.func,
    onUnmount: PropTypes.func,
    selectBoxes: PropTypes.bool,
    maxHeight: PropTypes.number,
    someSelected: PropTypes.bool.isRequired,
    allSelected: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    onMount: () => { },
    onUnmount: () => { },
    selectBoxes: false,
    maxHeight: null,
  };

  constructor(props) {
    super(props);

    this.onChangeItem = this.onChangeItem.bind(this);

    this.state = {
      columns: this.getColumnsNumber(this.props),
      headerHeight: 0,
    };
  }

  componentDidMount() {
    if (this.headerRef) {
      this.setState({
        headerHeight: calculateHeight(this.headerRef),
      });
    }

    this.autoSuggestMapping();
  }

  componentWillUnmount() {
    const { onUnmount } = this.props;
    onUnmount();
  }

  onChangeItem({ target: { name, checked } }) {
    const { onChangeItem } = this.props;
    onChangeItem(checked, parseInt(name, 10));
  }

  getColumnsNumber(props) {
    const { items } = props;
    return items.reduce((val, item) => Math.max(item.length, val), 0);
  }

  setMapping(value, prevValue, index) {
    if (value === prevValue) return;

    const { mapping, onChangeMapping } = this.props;

    const obj = value === '-'
      ? { [prevValue]: prevValue === 'notes' ? mapping.notes.filter(item => index !== item) : null }
      : { [value]: value === 'notes' ? [...new Set([...mapping.notes, index])] : index };

    const newMappings = { ...mapping, ...obj };

    const repeated = Object.keys(ImporterAccountMapping)
      .find(type => type !== value
        && (type === 'notes'
          ? mapping[type].includes(index)
          : mapping[type] === index
        ));

    if (repeated) {
      const newObj = repeated === 'notes'
        ? { [repeated]: mapping.notes.filter(item => index !== item) }
        : { [repeated]: null };
      Object.assign(newMappings, newObj);
    }

    onChangeMapping(newMappings);
  }

  autoSuggestMapping() {
    const { items, onChangeMapping } = this.props;
    const newSuggestionMapping = autoAccountMapping(items);
    onChangeMapping(newSuggestionMapping);
  }

  render() {
    const { columns, headerHeight } = this.state;
    const {
      intl, mapping, allowMapping, selectBoxes, title,
      maxHeight, onMount, allSelected, someSelected, onChangeAllItems,
    } = this.props;

    const headerKeys = Object.keys(ImporterAccountMapping);
    const selectOptions = headerKeys.reduce((obj, type) => Object.assign(obj, {
      [type]: intl.formatMessage({ id: ImporterAccountMapping[type].label }),
    }), {});

    const mappedIndexes = headerKeys.reduce((arr, type) => (
      type !== 'notes'
        ? [...arr, mapping[type]]
        : arr.concat(
          mapping[type].length
            ? mapping[type]
            : [null],
        )
    ), []);

    let header = (new Array(columns)).fill('')
      .map((item, index) => headerKeys.find(type => (
        type === 'notes'
          ? mapping[type].includes(index)
          : mapping[type] === index
      )));

    let { items } = this.props;

    if (!allowMapping) {
      header = mappedIndexes.reduce((arr, index) => {
        if (index != null) arr.push(header[index]);
        return arr;
      }, []);
    }

    header = header
      .map((selected, index) => (
        <Select
          className={`a-import-select-${index}`}
          options={selectOptions}
          onChange={value => this.setMapping(value, selected, index)}
          name={index}
          value={selected || '-'}
          withEmpty
          disabled={!allowMapping}
        />
      ));

    let checkedList = null;

    if (selectBoxes) {
      checkedList = [];
      header.unshift(
        <Box
          position="absolute"
          right={2}
          m="xs"
        >
          <Checkbox
            indeterminate={someSelected}
            checked={someSelected || allSelected}
            onChange={onChangeAllItems}
            label=''
          />
        </Box>,
      );
      const { itemStatus, itemsType, deselected } = this.props;

      items = items.map((item, i) => {
        const itemType = itemsType[i];
        const errorId = mapStatus(itemStatus[i]);
        const checked = deselected.indexOf(i) === -1;
        checkedList.push(checked);

        const status = STATUS_MAPPING[errorId];

        return [
          <div
            className="u-txt-center a-import-status"
            data-status={status.status}
          >
            <span className="u-d-ib u-mr-s u-va-m">
              <Icon icon={itemType} />
            </span>
            <span
              className="u-d-ib u-mr-s u-va-m"
              title={intl.formatMessage({ id: status.tooltip })}
            >
              <Icon icon={status.icon} color={status.iconColor} />
            </span>
            <Checkbox
              name={`${i}`}
              checked={checked}
              disabled={status.disabled}
              onChange={this.onChangeItem}
              className="a-import-checkbox"
              label=''
            />
          </div>,
          ...mappedIndexes.reduce((arr, index) => {
            if (index != null) arr.push(item[index]);
            return arr;
          }, []),
        ];
      });
    }

    return (
      <Card noPadding>
        <ul className="c-card-list">
          <li
            className="c-card-list__item u-pt-l u-pr-l u-pb-l u-pl-l"
            ref={(ref) => { this.headerRef = ref; }}
          >
            {title}
          </li>
          <li>
            {headerHeight
              ? (
                <form onChange={this.onChange}>
                  <ScrollingTable
                    header={header}
                    items={items}
                    fixedColumn={selectBoxes}
                    fixedColumnWidth={120}
                    // hack to have it the size of the select box. We should find a better way
                    headerHeight={54}
                    maxHeight={maxHeight - headerHeight}
                    onMount={() => onMount()}
                  />
                </form>
              )
              : ''}
          </li>
        </ul>
      </Card>
    );
  }
}

export default ImporterTable;
