import mime from 'mime';
import ArchiveIcon from 'pwm-components/icons/Archive';
import DocIcon from 'pwm-components/icons/Doc';
import FilmIcon from 'pwm-components/icons/Film';
import MicrophoneIcon from 'pwm-components/icons/Microphone';
import PhotoIcon from 'pwm-components/icons/Photo';
import PieIcon from 'pwm-components/icons/Pie';
import TableIcon from 'pwm-components/icons/Table';
import UnknownIcon from 'pwm-components/icons/Unknown';

export enum FileType {
  Archive,
  Audio,
  Image,
  Pdf,
  Presentation,
  Table,
  Text,
  Unknown,
  Video,
}

const AUDIO_MIME_TYPES = [
  'application/ogg',
];

const ARCHIVE_MIME_TYPES = [
  'application/mac-binhex40',
  'application/rar',
  'application/zip',
  'application/x-apple-diskimage',
  'application/x-debian-package',
  'application/x-gtar',
  'application/x-iso9660-image',
  'application/x-lha',
  'application/x-lzh',
  'application/x-lzx',
  'application/x-stuffit',
  'application/x-tar',
  'application/x-webarchive',
  'application/x-webarchive-xml',
  'application/gzip',
  'application/x-7z-compressed',
  'application/x-deb',
  'application/x-rar-compressed',
  'application/x-freearc',
  'application/x-bzip',
  'application/x-bzip2',
  'application/vnd.rar',
];

const PRESENTATION_MIME_TYPES = [
  'application/vnd.stardivision.impress',
  'application/vnd.sun.xml.impress',
  'application/vnd.sun.xml.impress.template',
  'application/x-kpresenter',
  'application/vnd.oasis.opendocument.presentation',
  'application/vnd.google-apps.presentation',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.presentationml.template',
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
];

const TABLE_MIME_TYPES = [
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.spreadsheet-template',
  'application/vnd.stardivision.calc',
  'application/vnd.sun.xml.calc',
  'application/vnd.sun.xml.calc.template',
  'application/x-kspread', 'application/vnd.google-apps.spreadsheet',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
];

const TEXT_MIME_TYPES = [
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.text-master',
  'application/vnd.oasis.opendocument.text-template',
  'application/vnd.oasis.opendocument.text-web',
  'application/vnd.stardivision.writer',
  'application/vnd.stardivision.writer-global',
  'application/vnd.sun.xml.writer',
  'application/vnd.sun.xml.writer.global',
  'application/vnd.sun.xml.writer.template',
  'application/x-abiword',
  'application/x-kword',
  'application/vnd.google-apps.document',
  'application/vnd.amazon.ebook',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  'application/epub+zip',
  'application/json',
  'application/ld+json',
  'application/x-httpd-php',
  'application/rtf',
  'application/x-sh',
  'application/xhtml+xml',
  'application/xml',
  'application/vnd.mozilla.xul+xml',
];

const PDF_MIME_TYPES = [
  'application/pdf',
];

export function isPdf(mimeType: string): boolean {
  return PDF_MIME_TYPES.includes(mimeType);
}

export function isVideo(mimeType: string): boolean {
  return mimeType.indexOf('video/') === 0;
}

export function isImage(mimeType: string): boolean {
  return mimeType.indexOf('image/') === 0;
}

export function isAudio(mimeType: string): boolean {
  return mimeType.indexOf('audio/') === 0 || AUDIO_MIME_TYPES.includes(mimeType);
}

export function isText(mimeType: string): boolean {
  return mimeType.indexOf('text/') === 0 || TEXT_MIME_TYPES.includes(mimeType);
}

export function isArchive(mimeType: string): boolean {
  return ARCHIVE_MIME_TYPES.includes(mimeType);
}

export function isPresentation(mimeType: string): boolean {
  return PRESENTATION_MIME_TYPES.includes(mimeType);
}

export function isTable(mimeType: string): boolean {
  return TABLE_MIME_TYPES.includes(mimeType);
}

const typeMap = new Map([
  [isVideo, FileType.Video],
  [isImage, FileType.Image],
  [isAudio, FileType.Audio],
  [isText, FileType.Text],
  [isArchive, FileType.Archive],
  [isPresentation, FileType.Presentation],
  [isTable, FileType.Table],
  [isPdf, FileType.Pdf],
]);

export function getFileType(mimeType: string): FileType {
  for (const [fn, type] of typeMap) {
    if (fn(mimeType)) {
      return type;
    }
  }

  return FileType.Unknown;
}

export function getMimeTypeByExtension(extension: string): string | null {
  const type = mime.getType(extension.toLowerCase());
  return type;
}

export function getFileTypeByExtension(extension: string): FileType {
  const type = getMimeTypeByExtension(extension);
  return type ? getFileType(type) : FileType.Unknown;
}

// eslint-disable-next-line complexity
export function getIconByFileType(fileType: FileType): React.FC<any> {
  switch (fileType) {
    case FileType.Archive:
      return ArchiveIcon;
    case FileType.Audio:
      return MicrophoneIcon;
    case FileType.Image:
      return PhotoIcon;
    case FileType.Pdf:
    case FileType.Text:
      return DocIcon;
    case FileType.Presentation:
      return PieIcon;
    case FileType.Table:
      return TableIcon;
    case FileType.Video:
      return FilmIcon;
    case FileType.Unknown:
    default:
      return UnknownIcon;
  }
}
