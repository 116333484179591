import React, { useCallback, useState } from 'react';
import IconButton from 'pwm-components/components/IconButton';
import Box from 'pwm-components/components/Box';
import styled from 'pwm-components/styled';
import google from '../img/icon-google.png';
import google2x from '../img/icon-google@2x.png';

const StyledIconButton = styled(IconButton)`
  background: #ffffff;
  border: 2px solid #4285F4;

  :hover{
    background: #ffffff;
    border: 1px solid #4285F4;
  }

  :focus{
    background: #ffffff;
    border: 1px solid #4285F4;
  }

  &[disabled]{
    background: #ffffff;
    border: 1px solid #4285F4;
    opacity: 15%;
    cursor: not-allowed;
  }
`;

type Props = {
  clientId: string;
  onClick?: () => void;
  onSuccess?: (accessToken: string, handle: string, socialMediaUniqeId: string) => void;
  onError?: (data: any, handle: string) => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

const GoogleLogin: React.FC<Props> = ({
  clientId,
  onClick: onClickProp = () => {},
  onError = () => {},
  onSuccess = () => {},
  loading = false,
  disabled = false,
}) => {
  const [initialized, setInitialized] = useState(false);

  const initClient = useCallback((): Promise<void> => {
    const { gapi } = window;

    return new Promise((resolve) => {
      gapi.load('auth2', () => {
        gapi.auth2.init({
          client_id: clientId,
          scope: 'https://www.googleapis.com/auth/plus.login https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        });
        resolve();
      });
    });
  }, [clientId]);

  const signIn = useCallback((): void => {
    const auth = window.gapi.auth2.getAuthInstance();
    auth.signIn().then((res) => {
      onSuccess(res.getAuthResponse().access_token, 'google', res.getBasicProfile().getId());
    }, (err) => {
      onError(err, 'google');
    });
  }, [onError, onSuccess]);

  const initScript = useCallback((): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://apis.google.com/js/api.js';

    const jsScripts = document.getElementsByTagName('script')[0];
    if (jsScripts && jsScripts.parentNode) {
      jsScripts.parentNode.insertBefore(script, jsScripts);
    } else {
      document.head.appendChild(script);
    }
    script.onload = async () => {
      await initClient();
      setInitialized(true);
      signIn();

      // eslint-disable-next-line no-unused-expressions
      script.parentElement?.removeChild(script);
    };
  }, [initClient, signIn]);

  const onClick = useCallback((e: React.MouseEvent): void => {
    e.preventDefault();
    onClickProp();

    if (initialized) {
      signIn();
    } else {
      initScript();
    }
  }, [initialized, initScript, signIn, onClickProp]);

  return (
    <Box
      height="58px"
      width="100%"
      pl="4px"
    >
      <StyledIconButton
        size="normal"
        onClick={onClick}
        disabled={disabled || loading}
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <img
            style={{ height: 30, width: 30 }}
            src={google}
            srcSet={`${google} 1x, ${google2x} 2x`}
            alt="google"
          />
        </Box>
      </StyledIconButton>
    </Box>
  );
};

export default GoogleLogin;
