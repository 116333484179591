import React from 'react';
import styled from 'pwm-components/styled';
import KeyIcon from 'pwm-components/icons/Key';
import UserIcon from 'pwm-components/icons/User';
import Box from 'pwm-components/components/Box';

const MasterPasswordKeyIcon = styled(KeyIcon)`
  width: 90px;
  height: 90px;
  fill: ${({ theme: { colors } }) => colors.cerulean};
`;

const AviraUserIcon = styled(UserIcon)`
  width: 90px;
  height: 90px;
  position: relative;
  bottom: -26px;
  stroke: ${({ theme: { colors } }) => colors.cerulean};
  fill: transparent;
`;


const SparkleIcon = styled('div')`
  position: absolute;
  &:: after {
    content: '+';
    color: #858C8F;
    font-size: 20px;
    font-weight: 600;
  }
`;

const SpotlightPwmIconBox = styled(Box)`
  width: 150px;
  position: relative;
`;

type props = {
  type: 'user' | 'key';
}

const SpotlightPwmIcon: React.FC<props> = ({ type }) => (
  <SpotlightPwmIconBox>
    {
      type === 'key' && <MasterPasswordKeyIcon />
    }
    {
      type === 'user' && <AviraUserIcon />
    }
    <SparkleIcon style={{ top: -24, left: 36 }} />
    <SparkleIcon style={{ top: 36, left: 0 }} />
    <SparkleIcon style={{ bottom: 0, right: 16 }} />
  </SpotlightPwmIconBox>
);

export default SpotlightPwmIcon;
