import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { detectSource, tryIdentifying } from '../../tracking/TrackingActions';
import { trackAppClose } from '../AppActions';
import { State as TrackingState } from '../../tracking/TrackingReducer';
import { State as DashboardState } from '../../dashboard/DashboardReducer';
import { State as UserDataState } from '../../user/UserDataReducer';
import { getRelevantUserKey } from '../../user/selectors';
import mixpanel from '../../tracking/mixpanel';
import debug from '../../debug';
import { getMigrationStatus, getShouldBypassVault } from '../../nlok/selectors';

const log = debug.extend('Tracking');

const Tracking: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const distinctId = useSelector(
    ({ tracking }: { tracking: TrackingState }) => tracking.distinctId,
  );

  useEffect(() => {
    log('initialize', distinctId);
    mixpanel.initialize(distinctId);
    dispatch(detectSource(document.location.href, document.referrer));
  }, [dispatch, distinctId]);

  useEffect(() => {
    log('identify', distinctId);
    mixpanel.identify(distinctId);
  }, [distinctId]);

  const isUnlocked = useSelector(state => !!getRelevantUserKey(state));

  const encDistinctId = useSelector(({ userData }: { userData: UserDataState }) => (
    userData.distinct_id
  ));

  const migrationStatus = useSelector(getMigrationStatus);
  const bypassVault = !!useSelector(getShouldBypassVault);

  useEffect(() => {
    mixpanel.register({
      // eslint-disable-next-line no-restricted-globals
      migrationStatus: migrationStatus?.status ?? undefined,
      bypassVault,
    });
  }, [migrationStatus, bypassVault]);

  useEffect(() => {
    dispatch(tryIdentifying());
  }, [dispatch, encDistinctId]);

  useEffect(() => {
    mixpanel.register({ isUnlocked });
  }, [isUnlocked]);

  const isUnregisteredMode = useSelector(
    ({ dashboard }: { dashboard: DashboardState }) => dashboard.isUnregisteredMode,
  );

  useEffect(() => {
    mixpanel.setUnregisteredMode(isUnregisteredMode);
  }, [isUnregisteredMode]);

  useEffect(() => {
    const mountTimestamp = Date.now();
    const onBeforeUnload = (): void => {
      dispatch(trackAppClose(mountTimestamp));
    };
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => window.removeEventListener('beforeunload', onBeforeUnload);
  }, [dispatch]);

  return <>{children}</>;
};

export default Tracking;
