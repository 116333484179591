import React from 'react';
import { bool } from 'prop-types';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';
import ExtensionStatus from '../ExtensionStatus';

const InstallExtension = ({ extensionConnected }) => (
  <ExtensionStatus
    extensionConnected={extensionConnected}
    installedMessage={(
      <FormattedMessage
        id="dashboard.getStarted.installExtension.installed"
        defaultMessage="Very good. The browser extension is installed on your browser"
      />
)}
    notInstalledMessage={(
      <FormattedMessage
        id="dashboard.getStarted.installExtension.instruction"

        defaultMessage='Click "Install Extension". This will lead you to the extension store. Please follow the instruction to install the extension and then come back here.'
      />
)}
    notSupportedMessage={(
      <>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.installExtension.notSupported"
            defaultMessage="We are sorry. Your browser is not supported."
          />
        </Paragraph>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.installExtension.recommendedBrowsers"
            defaultMessage="We recommend you use one of the following browsers:"
          />
        </Paragraph>
      </>
)}
    ctaMessage={(
      <FormattedMessage
        id="dashboard.getStarted.installExtension.cta"
        defaultMessage="Install Extension"
      />
)}
  />
);

InstallExtension.propTypes = {
  extensionConnected: bool.isRequired,
};

export default InstallExtension;
