import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import TextButton from 'pwm-components/components/TextButton';

import { FormattedMessage } from 'react-intl';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';

import { SidebarItem as SidebarItemType } from './SidebarTypes';

type Props = {
  items: SidebarItemType[];
};

type ItemProps = {
  active?: boolean;
};

const NavWrapper = styled(Box).attrs(({ theme }) => ({ borderBottom: true, borderColor: theme.mode === 'dark' ? 'mako' : 'gray' }))`
  & > ${Grid} {
    margin-bottom: -1px;
  }
`;

const NavItemTemp = styled(TextButton)<ItemProps>``;

const NavItem = styled(NavItemTemp).attrs<ItemProps>(({ active }) => ({
  variant: active ? 'link' : 'normal',
}))`
  padding: ${({ theme: { space } }) => `${space.xs} ${space.m}`};
  border-bottom: solid 2px;
  border-color: ${({ active, theme: { colors, semanticTextColors } }) => (
    active ? semanticTextColors.link : colors.transparent
  )};
  text-transform: capitalize;
`;

const SpotlightNav: React.FC<Props> = ({ children, items }) => {
  const { pathname } = useLocation();

  return (
    <NavWrapper>
      <Grid align="bottom">
        <Column>
          {items.map(({ id, label, linkTo }) => (
            <Link key={id} to={linkTo}>
              <NavItem active={pathname.indexOf(`/${id}`) >= 0}>
                <FormattedMessage
                  id={`sidebar.menu.${label}`}
                />
              </NavItem>
            </Link>
          ))}
        </Column>
        <Column size="auto">
          {children}
        </Column>
      </Grid>
    </NavWrapper>
  );
};

export default SpotlightNav;
