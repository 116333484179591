import AWS from 'aws-sdk';
import { FileEncryptor } from '@avira-pwm/sync/helpers/file';
import { FileType, getFileType } from './helpers/FileTypeHelper';
import { getFileData } from './helpers/FileDataHelper';
import * as ImageHelpers from './helpers/ImageHelpers';
import * as PdfHelpers from './helpers/PdfHelpers';
import * as VideoHelpers from './helpers/VideoHelpers';
import FileDatabase from './FileDatabase';

const FILENAME_EXT_REGEXP = /(.+)(?:\.(.+))/;

type S3FileProps = {
  aws?: any;
  id: string;
  fileName: string;
};

type Cacheprops = {
  fileDatabase?: FileDatabase;
};

type EncryptDecryptCacheProps = Cacheprops & Omit<S3FileProps, 'aws'> & {
  data?: Uint8Array | null;
  encryptor: FileEncryptor;
};

type UploadProps = Pick<EncryptDecryptCacheProps, 'data'> & S3FileProps & {
  data: Uint8Array;
  onProgress?: (progress: number) => void;
}

type DownloadProps = S3FileProps & {
  retry?: number;
}

export async function encryptAndCacheFile({
  fileDatabase,
  encryptor,
  id,
  fileName,
  data,
}: EncryptDecryptCacheProps): Promise<Uint8Array | null> {
  if (!data) return null;

  try {
    const encrypted = encryptor.encryptFile(data);

    try {
      await fileDatabase?.save(id, fileName, encrypted);
    } catch { /* it shouldn't fail if cache database fails to save */ }

    return encrypted;
  } catch (e) {
    return null;
  }
}

export async function uploadFile({
  aws, fileName, data, onProgress,
}: UploadProps): Promise<void> {
  const bucket = aws.user_files_bucket;
  const key = `${aws.identity_id}/${fileName}`;

  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: bucket,
    },
  });

  return new Promise((resolve, reject) => {
    s3.upload({ Key: key, Bucket: bucket, Body: data }, (err: any, res: any) => {
      if (err) {
        reject(new Error('File upload failed'));
      } else {
        resolve(res);
      }
    }).on('httpUploadProgress', (progress) => {
      if (onProgress) {
        onProgress(progress.loaded / progress.total);
      }
    });
  });
}

export async function downloadFile({
  aws, fileName,
}: DownloadProps): Promise<Uint8Array | null> {
  const bucket = aws.user_files_bucket;
  const key = `${aws.identity_id}/${fileName}`;

  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: aws.user_files_bucket,
    },
  });

  const data = await new Promise<Uint8Array | null>((resolve) => {
    s3.getObject({ Key: key, Bucket: bucket }, (err, response) => {
      if (err) {
        resolve(null);
      } else {
        resolve(response.Body as Uint8Array);
      }
    }).send();
  });

  return data;
}

export function checkFile(
  bucket: string, key: string,
): Promise<boolean> {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: {
      Bucket: bucket,
    },
  });

  return new Promise((resolve) => {
    s3.headObject({ Key: key, Bucket: bucket }, (err, response) => {
      if (err || !response) {
        resolve(false);
      } else {
        resolve(true);
      }
    });
  });
}

export function checkIfFileExists({ aws, fileName }: Omit<S3FileProps, 'id'>): Promise<boolean> {
  return checkFile(
    aws.user_files_bucket,
    `${aws.identity_id}/${fileName}`,
  );
}

export function getNameAndType(fileName: string): { name: string; type: string } {
  const match = fileName.match(FILENAME_EXT_REGEXP);

  if (!match) {
    return { name: fileName, type: '' };
  }

  return {
    name: match[1],
    type: (match[2]).toUpperCase(),
  };
}

export async function generatePreviews(
  id: string,
  file: File,
): Promise<Record<string, Uint8Array>> {
  const fileType = getFileType(file.type);
  switch (fileType) {
    case FileType.Image:
      return ImageHelpers.generatePreviews(id, file);
    case FileType.Pdf:
      return PdfHelpers.generatePreviews(id, file);
    case FileType.Video:
      return VideoHelpers.generatePreviews(id, file);
    default:
      return {
        [id]: await getFileData(file),
      };
  }
}
