export const IMPORTER_SELECT_ITEM = 'IMPORTER_SELECT_ITEM';
export const IMPORTER_SELECT_ITEMS = 'IMPORTER_SELECT_ITEMS';
export const IMPORTER_DESELECT_ITEM = 'IMPORTER_DESELECT_ITEM';
export const IMPORTER_DESELECT_ITEMS = 'IMPORTER_DESELECT_ITEMS';
export const IMPORTER_RESTORE_DEFAULT_DESELECTED = 'IMPORTER_RESTORE_DEFAULT_DESELECTED';
export const IMPORTER_LOAD_ITEMS = 'IMPORTER_LOAD_ITEMS';
export const IMPORTER_CLEAR_ITEMS = 'IMPORTER_CLEAR_ITEMS';
export const IMPORTER_SET_MAPPING = 'IMPORTER_SET_MAPPING';
export const IMPORTER_SET_BROWSER_DATA = 'IMPORTER_SET_BROWSER_DATA';

export const IMPORTER_FILL_ACCOUNTS_INFO = 'IMPORTER_FILL_ACCOUNTS_INFO';
export const IMPORTER_FINAL_STATUS_INFO = 'IMPORTER_FINAL_STATUS_INFO';
