import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import iconClick from '../../../img/icon-click.png';
import iconClick2x from '../../../img/icon-click@2x.png';

import navigateToSite from '../../../img/animations/navigateToSite.gif';
import navigateToSite2x from '../../../img/animations/navigateToSite2x.gif';

import TwoColumnView from '../TwoColumnView';
import GifContainer from '../GifContainer';

export default () => (
  <TwoColumnView
    leftSection={(
      <>
        <Box mb="m">
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.navigateToSite.message"

              defaultMessage="Instead of first navigating to a website and the selecting the account to autofill, you can use the extension for quick access to your accounts."
            />
          </Paragraph>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <Box mr="l">
            <img
              src={iconClick}
              srcSet={`${iconClick} 1x, ${iconClick2x} 2x`}
              alt="Click icon"
            />
          </Box>
          <Paragraph>
            <FormattedMessage
              id="dashboard.getStarted.navigateToSite.instruction"

              defaultMessage="Open the extension and click on one of your sites. The extension will navigate you to the site and autofill user name and password for you."
            />
          </Paragraph>
        </Box>
      </>
    )}

    rightSection={(
      <GifContainer
        gifSrc={navigateToSite}
        gifSrc2x={navigateToSite2x}
        gifAlt="Navigate to a site animation"
      />
    )}
  />
);
