import React from 'react';
import { string } from 'prop-types';
import ProtectedRoute from './ProtectedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import AuthorizedRoute from './AuthorizedRoute';

const requirement = ({ user, dashboard: { isUnregisteredMode } }) => (
  !!(user.key || isUnregisteredMode)
);

const UnlockedRoute = ({ path, ...otherProps }) => (
  <AuthenticatedRoute
    path={path}
    render={() => (
      <AuthorizedRoute
        path={path}
        render={() => (
          <ProtectedRoute
            path={path}
            requirement={requirement}
            redirectPath="/unlock"
            {...otherProps}
          />
        )}
      />
    )}
  />
);

UnlockedRoute.propTypes = {
  path: string.isRequired,
};

export default UnlockedRoute;
