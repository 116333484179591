import ms from 'ms';
import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData } from '..';
import { isUnregisteredMode } from '../../dashboard/selectors';
import debug from '../../debug';

const log = debug.extend('UserDataWrapper');

function UserDataWrapper({ children }: PropsWithChildren<{}>): JSX.Element {
  const dispatch = useDispatch();

  const isUnregistered = useSelector(isUnregisteredMode);

  useEffect(() => {
    const interval = setInterval(() => {
      log('updating user data');

      if (!isUnregistered) {
        dispatch(getUserData());
      }
    }, ms('10m'));

    return () => {
      clearInterval(interval);
    };
  }, [dispatch, isUnregistered]);

  return <>{children}</>;
}

export default UserDataWrapper;
