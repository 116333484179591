import React from 'react';
import { string } from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import DataListItem from '../../componentLib/DataListItem';

const PlaceHolderContentBox = styled(Box)`
  display: flex;
  align-items: center;
  height: 30px;
`;

const AccountPromoListItem = ({ messageId }) => {
  let importNow = null;
  if (messageId === 'dashboard.default.accountPlaceholder.three') {
    importNow = (
      <>
        &nbsp;
        <Link to="/settings/import?context=passwordList">
          <FormattedMessage id="dashboard.default.accountPlaceholder.importNow" />
        </Link>
      </>
    );
  }

  return (
    <DataListItem placeholder>
      <PlaceHolderContentBox>
        <FormattedHTMLMessage id={messageId} />
        {importNow}
      </PlaceHolderContentBox>
    </DataListItem>
  );
};

AccountPromoListItem.propTypes = {
  messageId: string.isRequired,
};

export default AccountPromoListItem;
