import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getBackupDatasets } from '../ManageBackupsActions';

import Loading from '../../app/components/Loading';
import { ManageBackupsContainer } from '..';

class ManageBackupsWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.props.getBackupDatasets().then(() => {
      this.setState({ loaded: true });
    });
  }

  render() {
    if (this.state.loaded) {
      return <ManageBackupsContainer />;
    }

    return <Loading />;
  }
}

ManageBackupsWrapper.propTypes = {
  getBackupDatasets: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({
  getBackupDatasets,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ManageBackupsWrapper);
