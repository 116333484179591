import React from 'react';
import { string } from 'prop-types';

import Box from 'pwm-components/components/Box';

import config from '../config';

import appstore from '../img/icon-appstore.png';
import appstore2x from '../img/icon-appstore@2x.png';

import playstore from '../img/icon-playstore.png';
import playstore2x from '../img/icon-playstore@2x.png';

const MobileAppLinks = ({ language }) => (
  <Box>
    <a
      className="u-mr-m"
      href={config.mobileAppLinks.playStore.replace('<language>', language)}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={playstore}
        srcSet={`${playstore} 1x, ${playstore2x} 2x`}
        alt="Google Play Store"
      />
    </a>
    <a
      href={config.mobileAppLinks.appStore}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src={appstore}
        srcSet={`${appstore} 1x, ${appstore2x} 2x`}
        alt="Apple App Store"
      />
    </a>
  </Box>
);

MobileAppLinks.propTypes = {
  language: string.isRequired,
};

export default MobileAppLinks;
