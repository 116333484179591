/* eslint-disable max-statements */
import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { FormattedMessage, WrappedComponentProps, injectIntl } from 'react-intl';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import EyeIcon from 'pwm-components/icons/Eye';
import EyeCrossedIcon from 'pwm-components/icons/EyeCrossed';
import PasswordTooltip from 'pwm-components/components/PasswordTooltip';
import TextButton from 'pwm-components/components/TextButton';
import FavoriteIcon from 'pwm-components/icons/Favorite';
import FavoriteOutlineIcon from 'pwm-components/icons/FavoriteOutline';
import { Fields } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import DeleteIcon from 'pwm-components/icons/Delete';
import EditIcon from 'pwm-components/icons/Edit';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import WarningIcon from 'pwm-components/icons/Warning';
import withTooltip from 'pwm-components/components/Tooltip/withTooltip';

import DataListItem from '../../../componentLib/DataListItem';
import CopyValue from '../../../componentLib/CopyValue';
import { DataListCell, DataListCellOnHover, DataListActions } from '../../../componentLib/DataListComponents';
import { TrackingObject, CCUsedActions } from '../../WalletActions';
import {
  prettyPrintCard,
  DIGIT_MASK,
  getIcon,
  getCardNumberValidator,
  concealPrintPrefix,
  isPastDate,
  creditCardtTitleHelper,
} from '../../WalletHelpers';
import { isAttached } from '../../../files/helpers/FileDataHelper';

const EyeIconTransition = styled(EyeIcon)`
  transition: fill 200ms;
`;

const SensitiveContentText = styled.span`
  letter-spacing: 5px;

  @media screen and (max-width: 1030px) {
    letter-spacing: 2px;
  }
`;

const WarningWithToolTip = withTooltip(WarningIcon);

const DEFAULT_CVC_DISPLAY = [DIGIT_MASK, DIGIT_MASK, DIGIT_MASK].join('');
const CONTEXT = 'list';


type DataProps = Fields & {
  id: string;
  files: any;
  placeholder: boolean;
  iconElement: JSX.Element;
}

type Favorite = { favorite: boolean }

interface Push {
  push: (url: string) => { url: string };
}

export type Props = {
  data?: DataProps;
  index?: number;
  sortBy?: string;
  history?: Push;
  match?: { url: string };
  currentReveal?: Record<string, string>;
  setCurrentReveal: (id: string, property: string) => void;
  trackCreditCardUsed?: (id: string, trackingObject: TrackingObject) => void;
  updateLastUsedAt?: (id: string) => Promise<void>;
  onDeleteClick?: (id: string) => void;
  onEditClick?: () => void;
  onFavoriteClick?: (id: string, value: Favorite) => void;
}

type ListItemProps = Props & RouteComponentProps & WrappedComponentProps;

// eslint-disable-next-line complexity
const CreditCardListItem: React.FC<ListItemProps> = (props) => {
  const {
    data,
    history,
    intl,
    match,
    currentReveal,
    setCurrentReveal,
    trackCreditCardUsed,
    updateLastUsedAt,
    onDeleteClick,
    onEditClick,
    onFavoriteClick,
  } = props!;

  const {
    id,
    favorite,
    files,
    title,
    cardNumber,
    cvc,
    validToMonth,
    validToYear,
  } = data!;

  const [hover, setHover] = useState(false);
  const [showCardNumber, setShowCardNumber] = useState(false);
  const [showCvc, setShowCvc] = useState(false);
  const [icon, setIcon] = useState(getIcon('', isAttached(files)));

  const concealedCardPrefix = useMemo(() => (
    // TODO: this shouldn't be needed. We need a more robus mechanism for this
    // 1030 is approx. where it starts cropping the number for AMEX cards,
    // which are the longest default titles
    concealPrintPrefix(cardNumber)
  ), [cardNumber]);
  const visibleCardSuffix = useMemo(() => cardNumber.slice(-4), [cardNumber]);
  const isExpired = useMemo(() => isPastDate(
    validToMonth, validToYear,
  ), [validToMonth, validToYear]);
  const cardTitle = useMemo(() => title || creditCardtTitleHelper(intl, data ? data.cardNumber : ''), [title, intl, data]);

  useEffect(() => {
    const validator = getCardNumberValidator(cardNumber);

    setIcon(getIcon(validator.card ? validator.card.type : '', isAttached(files)));
    if (currentReveal!.id === id) {
      if (currentReveal!.property === 'cardNumber') {
        setShowCardNumber(true);
      } else {
        setShowCvc(true);
      }
    } else {
      setShowCardNumber(false);
      setShowCvc(false);
    }
  }, [id, cardNumber, validToYear, validToMonth, currentReveal, files]);

  const onFavoriteClickHandler = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    const value = { favorite: !favorite };
    onFavoriteClick!(id, value);
  }, [favorite, id, onFavoriteClick]);

  const onMouseEnter = useCallback((): void => {
    setHover(true);
  }, []);

  const onMouseLeave = useCallback((): void => {
    setHover(false);
  }, []);

  const editClickHandler = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    onEditClick!();
  }, [onEditClick]);

  const onCardClick = (): void => {
    history!.push(`${match!.url}/${id}/edit`);
    onEditClick!();
  };

  const onDeleteClickHandler = useCallback((e: React.MouseEvent, itemID: string): void => {
    e.stopPropagation();
    onDeleteClick!(itemID);
  }, [onDeleteClick]);

  const copyCardNumber = useCallback((): void => {
    if (trackCreditCardUsed && updateLastUsedAt) {
      trackCreditCardUsed(id, { action: CCUsedActions.cardNumber, context: CONTEXT });
      updateLastUsedAt(id);
    }
  }, [id, trackCreditCardUsed, updateLastUsedAt]);

  const copyCvc = useCallback((): void => {
    if (trackCreditCardUsed && updateLastUsedAt) {
      trackCreditCardUsed(id, { action: CCUsedActions.cvc, context: CONTEXT });
      updateLastUsedAt(id);
    }
  }, [id, trackCreditCardUsed, updateLastUsedAt]);

  const hideRevealCvc = useCallback((): void => {
    setShowCvc(false);
    setCurrentReveal('', '');
  }, [setCurrentReveal]);

  const revealCvcClickHandler = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    if (showCvc) {
      hideRevealCvc();
    } else {
      setShowCardNumber(false);
      setShowCvc(true);
      setCurrentReveal(id, 'cvc');
    }
  }, [hideRevealCvc, id, setCurrentReveal, showCvc]);

  const hideRevealCardNumber = useCallback((): void => {
    setShowCardNumber(false);
    setCurrentReveal('', '');
  }, [setCurrentReveal]);

  const revealCardNumberClickHandler = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    if (showCardNumber) {
      hideRevealCardNumber();
    } else {
      setShowCvc(false);
      setShowCardNumber(true);
      setCurrentReveal(id, 'cardNumber');
    }
  }, [hideRevealCardNumber, id, setCurrentReveal, showCardNumber]);

  return (
    <DataListItem
      onClick={onCardClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      placeholder={false}
    >
      <DataListCell>
        <Box position="relative" display="flex" alignItems="center">
          {icon}
        </Box>
      </DataListCell>
      <DataListCell
        flexBasis="12em"
        flexShrink={2}
        shrink
        truncate
      >
        <Box display="flex" alignItems="center">
          <Paragraph truncate><strong>{cardTitle}</strong></Paragraph>
        </Box>
      </DataListCell>

      <DataListCellOnHover spacer separator />

      <DataListCell fixed>
        <CopyValue
          value={cardNumber}
          onClick={copyCardNumber}
        >
          <Box display="flex" alignItems="center">
            <SensitiveContentText>
              {concealedCardPrefix}
            </SensitiveContentText>
            {visibleCardSuffix}
          </Box>
          <Box ml="m">
            <TextButton onClick={revealCardNumberClickHandler}>
              {
                showCardNumber ? <EyeCrossedIcon variant="link" />
                  : <EyeIconTransition variant="link" />
              }
            </TextButton>
            <PasswordTooltip
              password={prettyPrintCard(cardNumber || '')}
              visible={showCardNumber}
              onOutsideClick={hideRevealCardNumber}
              onCloseClick={hideRevealCardNumber}
              big
            />
          </Box>
        </CopyValue>
      </DataListCell>

      {
        (validToMonth || validToYear) && <DataListCellOnHover spacer separator />
      }

      <DataListCell
        style={{
          flexBasis: '4em',
          alignItems: 'center',
        }}
      >
        {
          (validToMonth || validToYear) && (
            <Box display="flex" alignItems="center">
              <Text>
                {validToMonth}
                /
                {validToYear}
              </Text>
              {
                isExpired ? (
                  <Box ml="m" mb=".36em">
                    <WarningWithToolTip
                      tooltipContent={intl.formatMessage({ id: 'dashboard.wallet.details.expired' })}
                      variant="alert"
                    />
                  </Box>
                ) : (
                  <Box ml="m" width="20px" />
                )
              }
            </Box>
          )
        }
      </DataListCell>

      {
        cvc && <DataListCellOnHover spacer separator />
      }

      <DataListCell
        fixed
        style={{
          alignItems: 'center',
          flexBasis: '10em',
        }}
        shrink
        truncate
      >
        {
          cvc && (
            <CopyValue
              value={cvc!}
              onClick={copyCvc}
            >
              <Box mr="m" display="flex" alignItems="center">
                <FormattedMessage
                  id="dashboard.wallet.details.cvc"
                  defaultMessage="CVC"
                />
              </Box>
              <Box display="flex" alignItems="center" mr="m">
                <SensitiveContentText>
                  {DEFAULT_CVC_DISPLAY}
                </SensitiveContentText>
              </Box>

              <Box>
                <TextButton onClick={revealCvcClickHandler}>
                  {
                    showCvc ? <EyeCrossedIcon variant={hover ? 'link' : 'normal'} />
                      : <EyeIconTransition variant={hover ? 'link' : 'normal'} />
                  }
                </TextButton>
                <PasswordTooltip
                  password={cvc || ''}
                  visible={showCvc}
                  onOutsideClick={hideRevealCvc}
                  onCloseClick={hideRevealCvc}
                  big
                />
              </Box>
            </CopyValue>
          )
        }
      </DataListCell>

      <DataListCellOnHover spacer separator />

      <DataListActions fixed>
        <TextButton>
          <Link
            to={`${match!.url}/${id}/edit`}
            onClick={editClickHandler}
          >
            <EditIcon variant="link" />
          </Link>
        </TextButton>
      </DataListActions>

      <DataListCell spacer />

      <DataListActions fixed>
        <TextButton
          onClick={e => onDeleteClickHandler(e, id)}
          tabIndex={-1}
        >
          <DeleteIcon variant="link" />
        </TextButton>
      </DataListActions>

      <DataListCellOnHover spacer />

      <DataListCellOnHover
        fixed
        style={(favorite ? { opacity: 1 } : {})}
      >
        <TextButton
          onClick={e => onFavoriteClickHandler(e)}
          tabIndex={-1}
        >
          {favorite
            ? <FavoriteIcon variant="active" /> : <FavoriteOutlineIcon variant="link" />}
        </TextButton>
      </DataListCellOnHover>
    </DataListItem>
  );
};

export default withRouter(injectIntl(CreditCardListItem));
