import React from 'react';
import { func, string } from 'prop-types';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';
import appleLogo from '../../../img/apple-logo.png';
import appleLogo2x from '../../../img/apple-logo@2x.png';
import androidLogo from '../../../img/android-logo.png';
import androidLogo2x from '../../../img/android-logo@2x.png';
import YouTubeVideo from '../../../componentLib/YouTubeVideo';

const getVideoIds = language => (
  language === 'de'
    ? ['Ngn-cdoD5ms', 'iRh9g2MHoRY']
    : ['YcN35Q_ucnE', '9vE35e8DLys']
);

const HowToActivateAutofillMobile = ({ onCompleted, language }) => {
  const [androidVideoId, iosVideoId] = getVideoIds(language);

  const videosParams = [
    {
      logo: androidLogo,
      logo2x: androidLogo2x,
      alt: 'Android Logo',
      id: androidVideoId,
    },
    {
      logo: appleLogo,
      logo2x: appleLogo2x,
      alt: 'Apple logo',
      id: iosVideoId,
    },
  ];

  return (
    <>
      <Paragraph mb="xl">
        <FormattedMessage
          id="dashboard.getStarted.activateAutofillMobile.message"

          defaultMessage="You can easily autofill your user names and passwords on your mobile device into browsers and apps. Watch the video to see how autofill works on your mobile device and to learn how to activate autofill"
        />
      </Paragraph>
      {videosParams.map(({
        alt, id, logo, logo2x,
      }) => (
        <Box
          key={id}
          mb="l"
          display="flex"
          alignItems="flex-start"
        >
          <img
            src={logo}
            srcSet={`${logo} 1x, ${logo2x} 2x`}
            alt={alt}
            style={{ marginRight: 12 }}
          />
          <YouTubeVideo
            videoId={id}
            language={language}
            onPlay={onCompleted}
          />
        </Box>
      ))}
    </>
  );
};

HowToActivateAutofillMobile.propTypes = {
  onCompleted: func.isRequired,
  language: string.isRequired,
};

export default HowToActivateAutofillMobile;
