import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AuthPageWrapper from './AuthPageWrapper';
import Register from './Register';

import { setFTUParameters } from '../../preferences/PreferencesActions';
import {
  authDropout,
  loginOEUser,
  registerOEUser,
  trackAuthShown,
  getOEUserData,
  registerLoginOEUserWithSocialMedia,
} from '../OEActions';
import {
  authUpdateEmail,
  logoutUser,
} from '../../authentication/AuthenticationActions';

class FTUContainer extends React.Component {
  constructor(props) {
    super(props);
    const existing = props.location.pathname.indexOf('existing') >= 0;
    this.state = this.props.setFTUParameters(existing);
  }

  render() {
    const { setFTUParameters: _, ...props } = this.props;
    return (
      <AuthPageWrapper
        showUninstall
        Page={() => <Register {...props} context="FTU" />}
        showHeaderMenu
      />
    );
  }
}

FTUContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  setFTUParameters: PropTypes.func.isRequired,
  onComponentShown: PropTypes.func.isRequired,
};

FTUContainer.defaultProps = {
  location: {
    pathname: '',
  },
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setFTUParameters,
    onFormSubmit: registerOEUser,
    getOEUserData,
    onComponentShown: trackAuthShown,
    onDropOut: authDropout,
    onTryLogin: loginOEUser,
    registerLoginOEUserWithSocialMedia,
    onEmailUpdate: authUpdateEmail,
    onChangeClick: logoutUser,
  },
  dispatch,
);

export default connect(null, mapDispatchToProps)(FTUContainer);
