import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';

import { State as OEType } from '../../oe/OEReducer';
import { State as OEDataType } from '../../oe/OEDataReducer';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import Card from '../../componentLib/CardWrapper';

type Props = {
  oe: OEType;
  oeData: OEDataType;
  onLogoutClick: (e: React.MouseEvent) => void;
  openEditTwoFactorAuthentication: (e: React.MouseEvent) => void;
  openEditAccount: (e: React.MouseEvent) => void;
  onDeleteAccountClick: () => void;
  isUnregisteredMode: boolean;
};

// eslint-disable-next-line complexity
const AccountSettings: React.SFC<Props> = ({
  oe,
  oeData,
  onLogoutClick,
  onDeleteAccountClick,
  openEditAccount,
  openEditTwoFactorAuthentication,
  isUnregisteredMode,
}) => {
  const tfaEnabled = !isUnregisteredMode && (oeData.status_tfa === 'active');

  return (
    <Card
      title={(
        <FormattedMessage id="dashboard.settings.accountSettings" />
      )}
      p="0"
    >
      <ConfigRenderer condition="spotlight" negate>
        <Box
          px="m"
          py="s"
          borderColor="gainsboro"
          borderBottom
          display="flex"
          justifyContent="space-between"
        >
          <div>
            <FormattedMessage id="dashboard.settings.accountSettings.loggedInAs" />
            <strong>
              <Text ml="xs">
                {
                  isUnregisteredMode
                    ? (
                      <FormattedMessage
                        id="dashboard.notRegistered"
                        defaultMessage="Not Registered"
                      />
                    )
                    : oe.email
                }
              </Text>
            </strong>
          </div>
          {
            isUnregisteredMode
              ? (
                <Link to="/register?dashboardContext=settings">
                  <FormattedMessage id="registerForFree.header" />
                </Link>
              ) : (
                <TextButton
                  onClick={onLogoutClick}
                  variant="link"
                  id="a-logout-action"
                >
                  <FormattedMessage id="dashboard.settings.accountSettings.logout" />
                </TextButton>
              )
          }
        </Box>
        {
          !isUnregisteredMode
          && (
            <Box
              px="m"
              py="s"
              borderColor="gainsboro"
              borderBottom
            >
              <TextButton
                onClick={openEditAccount}
                variant="link"
                id="a-editAviraAccount-action"
              >
                <FormattedMessage id="dashboard.settings.accountSettings.editAviraAccount" />
              </TextButton>
            </Box>
          )
        }
        <Box
          px="m"
          py="s"
          borderColor="gainsboro"
          borderBottom
        >
          <TextButton
            onClick={onDeleteAccountClick}
            variant="link"
            id="a-deletePWMData-action"
          >
            <FormattedMessage id="dashboard.settings.accountSettings.deletePWMData" />
          </TextButton>
        </Box>
        {
          !isUnregisteredMode
          && (
            <Box
              px="m"
              py="s"
              borderColor="gainsboro"
              borderBottom
              display="flex"
              justifyContent="space-between"
            >
              <div>
                <FormattedMessage id="dashboard.settings.accountSettings.twoFactorAuth" />
                <Text ml="s" size="normal">
                  <strong>
                    <FormattedMessage id={tfaEnabled ? 'dashboard.settings.accountSettings.twoFactorAuth.on' : 'dashboard.settings.accountSettings.twoFactorAuth.off'} />
                  </strong>
                </Text>
              </div>
              <TextButton
                onClick={openEditTwoFactorAuthentication}
                variant="link"
                id="a-twoFactorAuth-action"
              >
                <FormattedMessage id={tfaEnabled ? 'dashboard.settings.accountSettings.twoFactorAuth.disable' : 'dashboard.settings.accountSettings.twoFactorAuth.enable'} />
              </TextButton>
            </Box>
          )
        }
      </ConfigRenderer>
      <ConfigRenderer condition="spotlight" negate>
        {
          !isUnregisteredMode
          && (
            <Box
              px="m"
              py="s"
            >
              <Link
                to="/settings/change-master-password"
                className="u-outline-none u-cursor-p"
                id="a-change-master-password-action"
              >
                <FormattedMessage id="dashboard.settings.accountSettings.changeMasterPassword" />
              </Link>
            </Box>
          )
        }
      </ConfigRenderer>
    </Card>
  );
};

export default AccountSettings;
