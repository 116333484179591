export const AUTO_LOCK_UPDATE = 'AUTO_LOCK_UPDATE';
export const PREFS_DISMISS_EXT_BANNER = 'PREFS_DISMISS_EXT_BANNER';
export const PREFS_DISMISS_FILE_BANNER = 'PREFS_DISMISS_FILE_BANNER';
export const PREFS_DISMISS_GET_MOBILE_BANNER = 'PREFS_DISMISS_GET_MOBILE_BANNER';
export const PREFS_SET_PASSGEN = 'PREFS_SET_PASSGEN';
export const UPDATE_ALL_PREFERENCES = 'UPDATE_ALL_PREFERENCES';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LAST_ACTIVITY = 'UPDATE_LAST_ACTIVITY';
export const UPDATE_NOTE_SORT_BY = 'UPDATE_NOTE_SORT_BY';
export const PREFS_DISMISS_BREACH_CHECK_BANNER = 'PREFS_DISMISS_BREACH_CHECK_BANNER';
export const PREFS_DISMISS_OTHER_BREACHES_BANNER = 'PREFS_DISMISS_OTHER_BREACHES_BANNER';
export const SET_FTU_AB_TEST_VERSION = 'SET_FTU_AB_TEST_VERSION';
export const SET_THEME = 'SET_THEME';
export const UPDATE_PASSWORD_SORT_BY = 'UPDATE_PASSWORD_SORT_BY';
export const UPDATE_WALLET_SORT_BY = 'UPDATE_WALLET_SORT_BY';
export const SET_GEO_CHECK = 'SET_GEO_CHECK';
export const SET_SERVER_TIME_OFFSET = 'SET_SERVER_TIME_OFFSET';
