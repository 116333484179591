import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import InfoCircleIcon from 'pwm-components/icons/InfoCircle';
import { withTooltip } from 'pwm-components/components/Tooltip';

const InfoCircleIconWithTooltip = withTooltip(InfoCircleIcon);

class InfoTooltip extends React.Component {
  static propTypes = {
    messageId: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      infoTooltipVisible: false,
    };
    this.onInfoMouseOver = this.onInfoMouseOver.bind(this);
    this.onInfoMouseOut = this.onInfoMouseOut.bind(this);
    this.leaveTimeout = null;
  }

  onInfoMouseOver() {
    if (this.leaveTimeout) {
      clearTimeout(this.leaveTimeout);
      this.leaveTimeout = null;
    }

    this.setState({ infoTooltipVisible: true });
  }

  onInfoMouseOut() {
    this.leaveTimeout = setTimeout(() => {
      this.leaveTimeout = null;
      this.setState({ infoTooltipVisible: false });
    }, 100);
  }

  render() {
    const { infoTooltipVisible } = this.state;
    const { messageId } = this.props;
    return (
      // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
      <span
        onMouseOver={this.onInfoMouseOver}
        onMouseOut={this.onInfoMouseOut}
        style={{
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <InfoCircleIconWithTooltip
          variant="normal"
          align="right"
          tooltipAlign="left"
          info
          visible={infoTooltipVisible}
          tooltipContent={
            (
              <FormattedMessage id={messageId} />
            )
          }
        />
      </span>
    );
  }
}

export default InfoTooltip;
