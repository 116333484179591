import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  autoLoginAccount, trackCredentialEdit, toggleAccountFavorite,
  trackCredentialsUsed, onAccountCopy, trackCredentialsAssociatedUrlNavigate,
} from '../AccountActions';
import AccountListItem from './AccountListItem';

const mapStateToProps = ({
  dashboard: { extensionConnected: extensionInstalled, scrolling },
}) => ({ extensionInstalled, scrolling });

const mapDispatchToProps = dispatch => bindActionCreators({
  onFavoriteClick: toggleAccountFavorite,
  onRevealPasswordClick: trackCredentialsUsed,
  onCopyClick: onAccountCopy,
  onAccountClick: autoLoginAccount,
  onEditAccountClick: trackCredentialEdit,
  onAssociatedUrlNavigateClicked: trackCredentialsAssociatedUrlNavigate,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountListItem);
