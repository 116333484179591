import React, { useEffect } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { updateLanguageChoice } from '../../preferences';
import { setTheme } from '../../preferences/PreferencesActions';


const SpotlightRoute: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location) {
      const lang = new URLSearchParams(location.search).get('lang');
      const theme = new URLSearchParams(location.search).get('theme');

      if (lang) {
        dispatch(updateLanguageChoice(lang));
      }
      if (theme) {
        if (theme === 'bright') {
          dispatch(setTheme('light'));
        } else {
          dispatch(setTheme(theme));
        }
      }
    }
  }, [location, dispatch]);

  return (
    <Redirect to={{ pathname: '/spotlight-login' }} />
  );
};

export default SpotlightRoute;
