import { DeepReadonly } from 'utility-types';

import {
  AUTH_NEW_TIMESTAMP,
  AUTH_SET_TIMESTAMP,
  AUTH_CLEAR,
  AUTH_EMAIL,
  AUTH_SET_DID_RELOAD,
  AuthNewTimestamp,
  AuthSetTimestamp,
  AuthClear,
  AuthEmail,
  AuthSetDidReload,
} from './AuthActionTypes';

export type State = DeepReadonly<{
  email: string;
  timestamp: number | null;
  didReload: boolean;
}>;

function getDefaultState(): State {
  return {
    email: '',
    timestamp: 0,
    didReload: false,
  };
}

type Actions = AuthNewTimestamp | AuthSetTimestamp | AuthClear | AuthEmail | AuthSetDidReload;

export default function authReducer(state = getDefaultState(), action: Actions): State {
  switch (action.type) {
    case AUTH_NEW_TIMESTAMP:
      return {
        ...state,
        timestamp: Date.now(),
      };
    case AUTH_SET_TIMESTAMP:
      return {
        ...state,
        timestamp: action.timestamp,
      };
    case AUTH_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case AUTH_SET_DID_RELOAD:
      return {
        ...state,
        didReload: action.didReload,
      };
    case AUTH_CLEAR:
      return getDefaultState();
    default:
      return state;
  }
}
