import React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import styled from 'styled-components';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import filesBannerImg from '../../img/file_banner@1x.png';
import filesBannerImg2x from '../../img/file_banner@2x.png';
import Banner from '../../componentLib/Banner';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import { dismissFileBanner } from '../../preferences';

const FileBannerImage = styled('img')`
  position: absolute;
  top: -75px;
  left: -55px;
  width: 175px;
`;

const FilesBanner: React.SFC = () => {
  const dispatch = useDispatch();

  return (
    <ConfigRenderer condition="spotlight" negate>
      <ConfigRenderer condition="showFiles">
        <Box mt="-36px" mb="xl">
          <Banner
            onClose={() => dispatch(dismissFileBanner())}
          >
            <Grid size="no-padding" align="center">
              <Box width="100px" position="relative" mr="xl">
                <FileBannerImage
                  alt="Attach a file"
                  srcSet={`${filesBannerImg} 1x, ${filesBannerImg2x} 2x`}
                />
              </Box>
              <Column>
                <Paragraph mb="xxs">
                  <strong>
                    <FormattedHTMLMessage id="dashboard.ftu.files.attachmentUpdated" defaultMessage="Attachment Updated" />
                  </strong>
                </Paragraph>
                <Paragraph>
                  <FormattedMessage id="dashboard.ftu.files.attachmentUpdateDescription" defaultMessage="Now you are able to add photos to your notes." />
                </Paragraph>
              </Column>
            </Grid>
          </Banner>
        </Box>
      </ConfigRenderer>
    </ConfigRenderer>
  );
};

export default FilesBanner;
