import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Tag from 'pwm-components/components/Tag';

const TagsContainer = styled(Box)`
  display: flex;
  flex-flow: wrap;
  flex: 1 1 auto;
  min-height: 30px;

  & > ${Tag} {
    margin-right: ${({ theme: { space } }) => space.xs};
  }

  & > ${Tag}:last-child {
    margin-right: 0px;
  }
`;

export default TagsContainer;
