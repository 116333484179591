import React from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';

type Props = {
  browser: string | null;
  onRequestClick: () => void;
  onCloseClick: () => void;
};

const GetNativePermissionTryAgain: React.SFC<Props> = ({
  browser,
  onRequestClick,
  onCloseClick,
}: Props) => (
  <>
    <Box
      textAlign="center"
      mt="xxxl"
      mb="l"
    >
      <FormattedHTMLMessage
        id="dashboard.spotlight.grantPermission.permissionDeclined"
        values={{ browser }}
      />
    </Box>
    <Box>
      <Box mb="m">
        <Button
          variant="login"
          fullWidth
          onClick={() => {
            onRequestClick();
          }}
        >
          <FormattedMessage
            id="dashboard.spotlight.grantPermission.button.requestAgain"
          />
        </Button>
      </Box>
      <Box>
        <Button
          variant="secondary"
          fullWidth
          onClick={() => {
            onCloseClick();
          }}
        >
          <FormattedMessage
            id="dashboard.spotlight.grantPermission.button.close"
          />
        </Button>
      </Box>
    </Box>
  </>
);

export default GetNativePermissionTryAgain;
