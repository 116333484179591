import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setScrollPosition,
  trackCredentialAdd,
  setPasswordSortBy,
  onAccountDelete,
} from '../AccountActions';
import AccountsPage from './AccountsPage';
import { isDataUsageDisallowed } from '../../selectors';
import { accountTagHeaderSelector, accountsFileListSelector } from '../../lib/MyDataSelectors';
import {
  showGetProFree,
  showExtBanner,
  showFilesBanner,
  showGetMobileBanner,
  canDisplayBanners,
} from '../../user/selectors';
import { getDevices } from '../../connectedDevices/ConnectedDevicesActions';
import { setDevicesLoaded } from '../../mobile/MobileActions';
import { addMilestone } from '../../user/UserActions';
import { syncDeleteFile } from '../../files/FileActions';
import { dismissCongratsOnRegistration } from '../../dashboard/DashboardActions';
import { isNonMigratable } from '../../nlok/selectors';

const mapStateToProps = state => ({
  oe: state.oe,
  auth: state.auth,
  user: state.user,
  authToken: state.user.authToken,
  accounts: accountsFileListSelector(state),
  scrollPosition: state.accountPreferences.scrollPosition,
  preferences: state.preferences,
  disallowDataUsage: isDataUsageDisallowed(state),
  tags: accountTagHeaderSelector(state),
  canDisplayBanners: canDisplayBanners(state),
  isNonMigratable: isNonMigratable(state),
  showGetMobileBanner: showGetMobileBanner(state),
  showGetProFree: showGetProFree(state),
  showFilesBanner: showFilesBanner(state),
  showExtBanner: showExtBanner(state),
  isUnregisteredMode: state.dashboard.isUnregisteredMode,
  showCongratsOnRegistration: state.dashboard.showCongratsOnRegistration,
  extensionIsOutdated: state.dashboard.extensionIsOutdated,
  extensionOutdatedTimestamp: state.dashboard.extensionOutdatedTimestamp,
  extensionCurrentHash: state.dashboard.extensionCurrentHash,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onAddAccountClick: trackCredentialAdd,
  onSortByChange: setPasswordSortBy,
  onDeleteClick: onAccountDelete,
  onDeleteFile: syncDeleteFile,
  setScrollPosition,
  addMilestone,
  getDevices,
  setDevicesLoaded,
  dismissCongratsOnRegistration,
}, dispatch);

const AccountsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AccountsPage);

export default AccountsContainer;
