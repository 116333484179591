import React, { useRef, useCallback, useState } from 'react';
import styled from 'pwm-components/styled';
import IconButton from 'pwm-components/components/IconButton';
import Box from 'pwm-components/components/Box';
import JWTDecoder from '../lib/JWTDecoder';
import apple from '../img/logo_apple@1x.png';
import apple2x from '../img/logo_apple@2x.png';

const StyledIconButton = styled(IconButton)`
  background: #000000;
  border: 1px solid #000000;

  :hover{
    background: #000000;
  }

  :focus{
    background: #000000;
  }

  &[disabled]{
    background: #000000;
    opacity: 15%;
    cursor: 'not-allowed';
  }
`;

type Props = {
  clientId: string;
  onClick?: () => void;
  onSuccess?: (
    accessToken: string,
    handle: string,
    socialMediaUniqeId: string,
    redirectURI: string,
  ) => void;
  onError?: (err: any, handle: string) => void;
  loading?: boolean;
  disabled?: boolean;
  className?: string;
}

const AppleLogin: React.FC<Props> = ({
  clientId,
  onClick: onClickProp = () => {},
  onError = () => {},
  onSuccess = () => {},
  loading = false,
  disabled = false,
}) => {
  const [initialized, setInitialized] = useState(false);
  const redirectURI = window.location.origin;
  const initState = useRef<string>(Math.floor(Math.random() * 1e16).toString(16));

  // eslint-disable-next-line complexity
  const validateData = useCallback((data: any): boolean => {
    // Invalid response from server
    if (!data.code || !data.id_token) {
      return false;
    }

    // Authorization server returned an invalid state parameter
    if (data.state && data.state !== initState.current) {
      return false;
    }

    const { id_token: idToken } = data;
    const token = JWTDecoder(idToken);

    // Issuer's url differs from Apple's server
    if (token.iss && token.iss !== 'https://appleid.apple.com') {
      return false;
    }

    // Audience's registered claim ID differs from our ID
    if (token.aud && token.aud !== clientId) {
      return false;
    }

    // Token has expired
    if (token.exp < Date.now() / 1000) {
      return false;
    }

    return true;
  }, [initState, clientId]);

  const signIn = useCallback(async (): Promise<void> => {
    try {
      const data = await (window as any).AppleID.auth.signIn();
      if (!data.authorization || !validateData(data.authorization)) {
        throw new Error('Sign in data are invalid');
      }

      const { authorization: { code, id_token: idToken } } = data;
      onSuccess(code, 'apple', idToken, redirectURI);
    } catch (error) {
      onError(error, 'apple');
    }
  }, [onSuccess, onError, redirectURI, validateData]);

  const initClient = useCallback((): void => {
    (window as any).AppleID.auth.init({
      clientId,
      redirectURI,
      scope: 'name email',
      state: initState.current,
      usePopup: true,
    });
  }, [clientId, redirectURI]);

  const initScript = useCallback((): void => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

    const jsScripts = document.getElementsByTagName('script')[0];
    if (jsScripts && jsScripts.parentNode) {
      jsScripts.parentNode.insertBefore(script, jsScripts);
    } else {
      document.head.appendChild(script);
    }

    script.onload = async () => {
      initClient();
      setInitialized(true);
      await signIn();

      // eslint-disable-next-line no-unused-expressions
      script.parentElement?.removeChild(script);
    };
  }, [initClient, signIn]);

  const onClick = useCallback((e: React.MouseEvent): void => {
    e.preventDefault();
    onClickProp();

    if (initialized) {
      signIn();
    } else {
      initScript();
    }
  }, [initScript, onClickProp, initialized, signIn]);

  return (
    <Box
      height="58px"
      width="100%"
      pr="4px"
    >
      <StyledIconButton
        type="button"
        size="normal"
        onClick={onClick}
        disabled={disabled || loading}
      >
        <Box
          display="flex"
          justifyContent="center"
        >
          <img
            style={{ height: 30, width: 'auto' }}
            src={apple}
            srcSet={`${apple} 1x, ${apple2x} 2x`}
            alt="apple"
          />
        </Box>
      </StyledIconButton>
    </Box>
  );
};

export default AppleLogin;
