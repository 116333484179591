import * as Sentry from '@sentry/browser';
import { logoutUser } from '../authentication/AuthenticationActions';
import { UPDATE_USER_DATA } from '../user/UserActionTypes';
import { ACCOUNTS_CLEAR } from '../accounts/AccountActionTypes';
import { deleteVault } from '../nlok/NLOKActions';

export const deleteAccount = () => async (
  dispatch, getState, { dashboardMessenger, licenseService },
) => {
  const { user, dashboard: { isUnregisteredMode } } = getState();
  const context = 'settings';
  const cause = 'deleteUser';
  if (isUnregisteredMode) {
    dispatch({ type: ACCOUNTS_CLEAR });
    dashboardMessenger.send('dashboard:extension:deleteData', {
      user: { id: user.tempUserId },
    });
    return;
  }
  await licenseService.deleteUser(user.authToken);
  await dispatch(deleteVault());
  dispatch(logoutUser(context, cause));
};

export const setHibpBreachCheck = value => (dispatch, getState, { licenseService }) => {
  const data = { breach_check: value };
  const { user, dashboard: { isUnregisteredMode } } = getState();
  dispatch({ type: UPDATE_USER_DATA, data });
  if (!isUnregisteredMode) {
    try {
      licenseService.updateUserData(user.authToken, data);
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};
