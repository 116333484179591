import React from 'react';
import ProtectedRoute from './ProtectedRoute';

const UnauthorizedRoute = props => (
  <ProtectedRoute
    {...props}
    requirement={({ user }) => !user.authToken}
    redirectPath="/mydata/passwords"
  />
);

export default UnauthorizedRoute;
