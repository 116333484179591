import React from 'react';
import {
  FormattedMessage,
  FormattedHTMLMessage,
} from 'react-intl';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';

type Props = {
  pwmUserEmail: string | null;
  spotlightUserEmail: string | null;
  onConnectClick: (e: React.MouseEvent) => void;
  onDontConnectSpotlight: Function;
};

const PasswordManagerChangeUser: React.SFC<Props> = ({
  pwmUserEmail,
  spotlightUserEmail,
  onConnectClick,
  onDontConnectSpotlight,
}: Props) => (
  <>
    {
      pwmUserEmail
        ? (
          <Box
            textAlign="center"
            mt="xxxl"
            mb="l"
          >
            <Text variant="dark">
              <FormattedHTMLMessage
                id="dashboard.spotlight.grantPermission.currentPWMUser"
                values={{ email: pwmUserEmail }}
              />
            </Text>
          </Box>
        )
        : null
    }
    <Box
      textAlign="center"
      mb="l"
    >
      <Text variant="dark">
        <FormattedHTMLMessage
          id="dashboard.spotlight.grantPermission.newPWMUser"
          values={{ email: spotlightUserEmail }}
        />
      </Text>
    </Box>
    <Box>
      <Box mb="m">
        <Button
          variant="login"
          fullWidth
          onClick={onConnectClick}
        >
          <FormattedMessage
            id="dashboard.spotlight.grantPermission.button.connect"
          />
        </Button>
      </Box>
      <Box>
        <Button
          variant="secondary"
          fullWidth
          onClick={() => {
            onDontConnectSpotlight();
          }}
        >
          <FormattedMessage
            id="dashboard.spotlight.grantPermission.button.dontConnect"
          />
        </Button>
      </Box>
    </Box>
  </>
);

export default PasswordManagerChangeUser;
