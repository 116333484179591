import React from 'react';
import pick from 'lodash/pick';
import { injectIntl } from 'react-intl';

import { Fields } from '@avira-pwm/sync/ModelSpecifics/CreditCard';

import MyDataDetailsContainer, { Props as MyDataDetailsProps } from '../../../myData/components/MyDataDetailsContainer';

import * as DataValidatorErrors from '../../../lib/DataValidator';
import { handleDetailsChange, mergeDetails } from '../../../lib/MyDataHelper';

import { getIcon, getCardNumberValidator, creditCardtTitleHelper } from '../../WalletHelpers';
import CreditCardInputFields from './CreditCardInputFields';
import { filterFiles, isAttached } from '../../../files/helpers/FileDataHelper';

type Data = Fields & {
  id: string;
  iconElement: JSX.Element;
}

type Props = MyDataDetailsProps & {
  onSaveItemClick: (id: string, changedProps: Record<string, string>) => Promise<void>;
}

// Having difficulty typing data and changed keys.
type State = {
  errors: Record<string, string>;
  data: any;
  initialData: any;
  changedKeys: any;
  loading: boolean;
  showErrors: boolean;
  titleAutoFilled: boolean;
}

class AddEditPage extends React.Component<Props, State> {
  public static defaultProps = {
    data: {},
    loading: false,
    showErrors: false,
    onDelete: () => { },
    onFavoriteClick: () => { },
  };

  constructor(props: Props) {
    super(props);
    const { data } = props;

    this.state = {
      changedKeys: {},
      data,
      errors: {},
      initialData: data,
      loading: false,
      showErrors: false,
      titleAutoFilled: (!data.title),
    };

    this.getFieldError = this.getFieldError.bind(this);
    this.onDataChange = this.onDataChange.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
    this.createNewTag = this.createNewTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.onErrorsChange = this.onErrorsChange.bind(this);
    this.updateIcon = this.updateIcon.bind(this);
  }

  public componentDidMount(): void {
    const { data } = this.props;
    this.setState({
      data,
      loading: false,
      initialData: data,
      changedKeys: {},
    });
  }

  public componentDidUpdate(prevProps: Props): void {
    const { data: currentData } = this.props;
    const { data: thisStateData, changedKeys: changedKeyState } = this.state;

    if (currentData !== prevProps.data) {
      const {
        updatedData,
        updatedChangedKeys: changedKeys,
      } = mergeDetails(currentData, thisStateData, Object.keys(changedKeyState));
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        data: updatedData,
        loading: false,
        changedKeys,
      });
    }
  }

  private onDataChange(key: string, value: string | Array<string>, callback = () => { }): void {
    const { data, initialData, changedKeys: updatedKeys } = this.state;
    const {
      newChangeObj,
      changedKeys,
    } = handleDetailsChange(key, value, initialData, updatedKeys);

    let { titleAutoFilled } = this.state;
    if (key === 'title') {
      titleAutoFilled = false;
    } else if (key === 'cardNumber' && !data.title) {
      titleAutoFilled = true;
    }

    this.setState({
      data: { ...data, ...newChangeObj },
      changedKeys,
      titleAutoFilled,
    }, callback);
  }

  private onCancelClick = (): void => {
    const { initialData } = this.state;
    this.setState({
      data: initialData,
    });
  }

  private async onSubmitHandler(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> {
    e.preventDefault();
    const { data, errors, changedKeys: updatedKeys } = this.state;
    const { onSaveItemClick } = this.props;

    try {
      this.setState({ loading: true });
      const changedProps = {
        ...pick(data, Object.keys(updatedKeys)),
      };

      if (Object.entries(errors).length === 0) {
        await onSaveItemClick(data.id, changedProps);
        this.setState({ changedKeys: {} });
      } else {
        this.setState({
          showErrors: true,
        });
      }
    } finally {
      this.setState({ loading: false });
    }
  }

  private static getErrorMessageId(field: string, errorType: string): string | null {
    if (errorType === DataValidatorErrors.ERROR_FORMAT) {
      return `dashboard.wallet.error.${field}.format`;
    } if (errorType === DataValidatorErrors.ERROR_REQUIRED) {
      return `dashboard.wallet.error.${field}.required`;
    } if (errorType === DataValidatorErrors.ERROR_CUSTOM) {
      return `dashboard.wallet.error.${field}.custom`;
    }
    return null;
  }

  private onErrorsChange(errors: Record<string, string>): void {
    this.setState({
      errors,
    });
  }

  public getFieldError(field: string): string | null {
    const { intl } = this.props;
    const { errors, showErrors } = this.state;
    if (!errors || !errors[field]) {
      return null;
    }
    const errorMessageId = AddEditPage.getErrorMessageId(field, errors[field]);
    if (errorMessageId && showErrors) {
      return intl.formatMessage({ id: errorMessageId });
    }
    return null;
  }

  private getNameValue(): string {
    const { intl } = this.props;
    const { titleAutoFilled, data } = this.state;
    if (titleAutoFilled) {
      return creditCardtTitleHelper(intl, data.cardNumber);
    }
    return data.title || '';
  }

  private createNewTag(value: string): void {
    const { data } = this.state;
    const tags = data.tags || [];
    if (!tags.includes(value)) {
      this.onDataChange(
        'tags',
        ([...tags, value]).sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        }),
      );
    }
  }

  private removeTag(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tagIndex: number): void {
    const { data } = this.state;
    const tags = data.tags || [];
    e.stopPropagation();
    const updatedTags = [...tags];
    updatedTags.splice(tagIndex, 1);
    this.onDataChange('tags', updatedTags);
  }

  private updateIcon(attached?: boolean): JSX.Element {
    const { data } = this.state;
    const validator = getCardNumberValidator(data.cardNumber || '');
    return (getIcon(validator.card ? validator.card.type : '', attached));
  }

  public render(): JSX.Element {
    const {
      data, changedKeys: updatedKeys, loading,
    } = this.state;
    const {
      addMode, files: filesProp,
    } = this.props;

    const files = filterFiles(filesProp, data.id);
    return (
      <MyDataDetailsContainer
        {...this.props}
        focusNameField
        entityName="CreditCard"
        nameLabel="title"
        listViewPath="/mydata/wallet"
        confirmDeleteId="dashboard.wallet.details.confirmDelete"
        namePlaceholderId="dashboard.wallet.details.namePlaceholder"
        tagsInstructionId="dashboard.wallet.details.tagInstruction"
        attachmentInstructionId="dashboard.wallet.details.attachmentInstruction"
        attachmentGetProId="dashboard.wallet.details.upsellPro"
        enableSyncInstructionId="dashboard.wallet.details.enableSync"
        data={data}
        files={files}
        loading={loading}
        isFavorite={data.favorite}
        changedKeys={updatedKeys}
        iconElement={this.updateIcon(isAttached(files))}
        nameValue={this.getNameValue()}
        createdDate={data.createdAt || ''}
        modifiedDate={data.modifiedAt || ''}
        onChange={this.onDataChange}
        handleSubmit={this.onSubmitHandler}
      >
        <CreditCardInputFields
          {...data}
          addMode={addMode || false}
          changeHandler={this.onDataChange}
          changedKeys={updatedKeys}
          getFieldError={this.getFieldError}
          onClick={() => { }}
          onErrorsChange={this.onErrorsChange}
        />
      </MyDataDetailsContainer>
    );
  }
}

export default injectIntl(AddEditPage);
