import { Store } from 'redux';
import { Json, AsyncStorage } from '@avira-pwm/storage';

const PERSISTENT_STATE = 'PERSISTENT_STATE';

async function getStoreState<T extends string>(
  storage: AsyncStorage,
  names: T[],
): Promise<{ [K in T]?: any }> {
  try {
    const state = await storage.get(names.map(name => `${PERSISTENT_STATE}:${name}`));

    const finalState: { [K in T]?: Json } = {};
    for (const name of names) {
      const value = state[`${PERSISTENT_STATE}:${name}`];
      if (value) {
        finalState[name] = value;
      }
    }

    return finalState;
  } catch (e) {
    return {};
  }
}

async function setStoreState<
  T extends string
>(storage: AsyncStorage, names: T[], state: { [K in T]: any }): Promise<void> {
  try {
    const toPersist = names.reduce((obj, name) => ({
      ...obj,
      [`${PERSISTENT_STATE}:${name}`]: state[name],
    }), {});
    storage.set(toPersist);
  } catch (e) {
    // nothing to be done
  }
}

type PersistentState = {
  initial: {
    [key: string]: any;
  };
  persist: (store: Store) => void;
}

export default async function persistentStateCreator<T extends string>(
  storage: AsyncStorage,
  persistentReducers: T[],
): Promise<PersistentState> {
  const initial = await getStoreState(storage, persistentReducers);
  function persist(store: Store): void {
    if ((window as any).__BLOCK_PERSISTENCE__ === true) {
      return;
    }

    setStoreState(storage, persistentReducers, store.getState());
  }

  return {
    initial,
    persist,
  };
}
