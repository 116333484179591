import React from 'react';
import { bool } from 'prop-types';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';
import ExtensionStatus from '../ExtensionStatus';

const DisableSavingInstallExtension = ({ extensionConnected }) => (
  <ExtensionStatus
    extensionConnected={extensionConnected}
    installedMessage={(
      <FormattedMessage
        id="dashboard.getStarted.disableSavingPasswords.installExtension.installed"

        defaultMessage="Very good. The browser extension is installed on your browser. You meet the prerequisites for autofill and autosave."
      />
)}
    notInstalledMessage={(
      <FormattedMessage
        id="dashboard.getStarted.disableSavingPasswords.installExtension.instruction"

        defaultMessage='With the browser extension you can autosave and autofill your passwords on websites. Click "Install Extension". This will lead you to the extension store. Please follow the instruction to install the extension and then come back here.'
      />
)}
    notSupportedMessage={(
      <>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.disableSavingPasswords.installExtension.notSupported"

            defaultMessage="With the browser extension you can autosave and autofill your passwords on websites. Unfortunately your browser is not supported."
          />
        </Paragraph>
        <Paragraph>
          <FormattedMessage
            id="dashboard.getStarted.disableSavingPasswords.installExtension.recommendedBrowsers"
            defaultMessage="We recommend you use one of the following browsers:"
          />
        </Paragraph>
      </>
)}
    ctaMessage={(
      <FormattedMessage
        id="dashboard.getStarted.disableSavingPasswords.installExtension.cta"
        defaultMessage="Install Extension"
      />
)}
  />
);

DisableSavingInstallExtension.propTypes = {
  extensionConnected: bool.isRequired,
};

export default DisableSavingInstallExtension;
