/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { connect } from 'react-redux';
import { func, string, shape } from 'prop-types';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';
import IconUser from 'pwm-components/icons/User';
import { FormattedMessage } from 'react-intl';
import PWMLogo from '../../componentLib/PWMLogo';
import backgroundImage from '../../img/avira-bg.png';
import Card from '../../componentLib/Card';
import { trackGetProClicked, trackWhyRegisterShown } from '../../dashboard/DashboardActions';
import VersionComparisionRowHelper from '../../componentLib/VersionComparisionRowHelper';

class WhyRegister extends React.Component {
  componentDidMount() {
    let source = null;
    const { location } = this.props;
    if (location) {
      const query = queryString.parse(location.search);
      source = query.dashboardContext;
    }
    this.props.trackWhyRegisterShown(source);
  }

  render() {
    const { onGetProClick, paymentUrl } = this.props;
    return (
      <div className="u-fx u-fx-d-c u-height-full">
        <div
          className="c-auth-page__ftu-bg"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="c-header c-header--transparent-bg">
            <PWMLogo />
          </div>
          <div className="c-dashboard">
            <Card
              className="u-pt-xxxl u-mt-xl"
              title="dashboard.whyRegister.whyRegisterAviraPasswordManager"
              classNameLabel="u-txt-longprimer u-color-slategrey u-txt-strong"
            >
              <table className="c-why-register">
                <thead>
                  <tr className="c-why-register__headerRow u-txt-strong">
                    <th />
                    <th>
                      <FormattedMessage
                        id="dashboard.whyRegister.currentVersion"
                        defaultMessage="Current Version"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="dashboard.whyRegister.onceRegistered"
                        defaultMessage="Once Registered"
                      />
                    </th>
                    <th>
                      <FormattedMessage
                        id="dashboard.whyRegister.proVersion"
                        defaultMessage="PRO Version"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <VersionComparisionRowHelper />
                  <tr className="c-why-register__row">
                    <td />
                    <td />
                    <td className="u-pt-xxxl">
                      <Link
                        to="/register?dashboardContext=why-register"
                      >
                        <Button
                          className="u-width-full"
                          size="small"
                          variant="login"
                        >
                          <IconUser
                            variant="system"
                            size="small"
                            mr="xs"
                          />
                          <Text>
                            <FormattedMessage id="registerForFree.header" />
                          </Text>
                        </Button>
                      </Link>
                    </td>
                    <td className="u-pl-s">
                      <a
                        onClick={() => onGetProClick('whyRegister')}
                        href={paymentUrl}
                        role="button"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          className="u-width-full"
                          size="small"
                          variant="upsell"
                        >
                          <Text>
                            <FormattedMessage id="dashboard.getpro" />
                          </Text>
                        </Button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td />
                    <td />
                    <td className="c-why-register__row-center-text">
                      <FormattedMessage
                        id="dashboard.whyRegister.youPayNothing"
                        defaultMessage="You pay nothing"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}

WhyRegister.propTypes = {
  onGetProClick: func.isRequired,
  trackWhyRegisterShown: func,
  paymentUrl: string,
  location: shape({}).isRequired,
};

WhyRegister.defaultProps = {
  paymentUrl: '',
  trackWhyRegisterShown: () => { },
};

const mapStateToProps = ({ oeData }) => ({ paymentUrl: oeData && oeData.paymentUrl });

const mapDispatchToProps = dispatch => bindActionCreators({
  onGetProClick: trackGetProClicked,
  trackWhyRegisterShown,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WhyRegister);
