import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { trackEvent } from '../tracking/TrackingActions';
import {
  MigrationContactSupportClicked,
  MigrationStatusChanged,
  MigrationStatusChangedType,
  MigrationTryAgainClicked,
  MigrationTryAgainClickedType,
} from './NLOKMigrationTrackingActionTypes';

export const trackMigrationStatusChanged = (
  props: MigrationStatusChangedType,
): ThunkAction<void, {}, {}, AnyAction> => async (dispatch) => {
  dispatch(trackEvent(MigrationStatusChanged, props));
};

export const trackMigrationTryAgainClicked = (
  props: MigrationTryAgainClickedType,
): ThunkAction<void, {}, {}, AnyAction> => async (dispatch) => {
  dispatch(trackEvent(MigrationTryAgainClicked, props));
};

export const trackMigrationContactSupportClicked = (
): ThunkAction<void, {}, {}, AnyAction> => async (dispatch) => {
  dispatch(trackEvent(MigrationContactSupportClicked));
};
