import React from 'react';
import { node } from 'prop-types';
import { SecurityStatusTrackerContainer } from '../../securityStatus';

import ApiConnector from './ApiConnector';
import AutoLockTracker from './AutoLockTracker';
import DataWrapper from './DataWrapper';
import DataTracker from './DataTracker';
import AWSWrapper from './AWSWrapper';
import { FileManagerProvider } from '../../files/FileManagerContext';
import VaultWrapper from '../../nlok/components/VaultWrapper';
import UserDataWrapper from '../../user/components/UserDataWrapper';

/**
 * @type {React.FC}
 */
const Initializers = ({ children }) => (
  <AutoLockTracker>
    <UserDataWrapper>
      <AWSWrapper>
        <VaultWrapper>
          <ApiConnector>
            <DataWrapper>
              <FileManagerProvider>
                <DataTracker />
                <SecurityStatusTrackerContainer>
                  {children}
                </SecurityStatusTrackerContainer>
              </FileManagerProvider>
            </DataWrapper>
          </ApiConnector>
        </VaultWrapper>
      </AWSWrapper>
    </UserDataWrapper>
  </AutoLockTracker>
);

Initializers.propTypes = {
  children: node.isRequired,
};

export default Initializers;
