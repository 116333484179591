import { AsyncStorage, Json } from '@avira-pwm/storage';

export default class AsyncJsonStorageWrapper extends AsyncStorage {
  protected storage: Storage;

  constructor(storage: Storage) {
    super();
    this.storage = storage;
  }

  public async get<T extends string, O extends { [K in T]?: Json }>(keys: T[]): Promise<O> {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const output = {} as O;

    for (const key of keys) {
      const val = this.storage.getItem(key);
      if (val != null) {
        output[key] = this.parseFromStorage(val) as O[T];
      }
    }

    return output;
  }

  public async set(obj: { [key: string]: Json }): Promise<void> {
    for (const [key, value] of Object.entries(obj)) {
      this.storage.setItem(key, this.transformToStorage(value));
    }
  }

  public async remove(keys: string[]): Promise<void> {
    for (const key of keys) {
      this.storage.removeItem(key);
    }
  }

  public async clear(): Promise<void> {
    this.storage.clear();
  }

  public async keys(): Promise<string[]> {
    return Object.keys(this.storage);
  }

  // Our current localStorage consumers write a mix of string and json objects.
  // For this reason AsyncJsonStorageWrapper tries to use the previous values
  // which in turn would be stringified the next time they're written to the storage
  private parseFromStorage(val: string): Json {
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }

  private transformToStorage(val: Json): string {
    return JSON.stringify(val);
  }
}
