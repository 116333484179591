import { TrackingActions, MixpanelEvents } from '../tracking';

const { trackEvent } = TrackingActions;
const {
  MP_OPEN,
  MP_CLOSE,
} = MixpanelEvents;

const getSecondsFromTimestamp = timeStamp => Math.floor((Date.now() - timeStamp) / 1000);

export const trackAppOpen = () => (dispatch) => {
  dispatch(trackEvent(MP_OPEN));
};

export const trackAppClose = mountTimestamp => (dispatch) => {
  dispatch(trackEvent(MP_CLOSE, {
    sessionLength: getSecondsFromTimestamp(mountTimestamp),
  }));
};
