/* eslint-disable react/no-unused-state */
import React from 'react';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import { History } from 'history';
import { LastLocationType } from 'react-router-last-location';

import { State as OEType } from '../../oe/OEReducer';
import { State as OEDataType } from '../../oe/OEDataReducer';
import { State as UserType } from '../../user/UserReducer';
import { State as UserDataType } from '../../user/UserDataReducer';

import AccountSettings from './AccountSettings';
import Language from './Language';

import ImportExport from './ImportExport';
import { ExtensionCardWrapper } from '../../extension';
import Lock from './Lock';
import PageTitle from '../../componentLib/PageTitle';
import PageContentWrapper from '../../componentLib/PageContentWrapper';

import confirmDeleteIcon from '../../img/icon-delete-big.png';
import Loading from '../../app/components/Loading';
import BreachChecks from './BreachChecks';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import config from '../../config';
import { isUpsellAllowed } from '../../user/selectors';

const LOGOUT_CONTEXT = 'settings';
const LOGOUT_CAUSE = 'logout';

type Props = WrappedComponentProps & {
  deleteAccount: () => void;
  extensionInstalled: boolean;
  getUserData: () => void;
  openEditAccount: (context: string) => void;
  openEditTwoFactorAuthentication: (context: string) => void;
  oe: OEType;
  oeData: OEDataType;
  onAutoLockChange: () => void;
  onLogoutClick: (context: string, cause: string) => void;
  onGetProClick: () => void;
  setHibpBreachCheck: () => void;
  toggleSyncStore: () => void;
  updateLanguageChoice: () => void;
  updateOEUserData: () => void;
  user: UserType;
  userData: UserDataType;
  isUnregisteredMode: boolean;
  autolockDuration: number | null;
  language: string;
  checkUsernames: boolean;
  history: History;
  lastLocation: LastLocationType;
};

type State = {
  showDeleteConfirmDialog: boolean;
  loaded: boolean;
  syncEnabled: boolean | null;
}

class Settings extends React.Component<Props, State> {
  public static defaultProps = {
    isUnregisteredMode: false,
    autolockDuration: null,
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      showDeleteConfirmDialog: false,
      loaded: false,
      syncEnabled: null,
    };

    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.openEditTwoFactorAuthentication = this.openEditTwoFactorAuthentication.bind(this);
    this.openEditAccount = this.openEditAccount.bind(this);
    this.onDeleteAccountConfirm = this.onDeleteAccountConfirm.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
  }

  public async UNSAFE_componentWillMount(): Promise<void> {
    const {
      isUnregisteredMode, updateOEUserData, getUserData, userData,
    } = this.props;
    if (!isUnregisteredMode) {
      await updateOEUserData();
      await getUserData();
    }
    this.setState({
      loaded: true,
      syncEnabled: !isUnregisteredMode && userData.sync_store_manager === 'blur',
    });
  }

  public UNSAFE_componentWillReceiveProps(nextProps: Props): void {
    const { userData } = this.props;
    if (nextProps.userData.sync_store_manager !== userData.sync_store_manager) {
      this.setState({ syncEnabled: nextProps.userData.sync_store_manager === 'blur' });
    }
  }

  public onLogoutClick(e: React.MouseEvent): void {
    e.stopPropagation();
    const { onLogoutClick } = this.props;
    onLogoutClick(LOGOUT_CONTEXT, LOGOUT_CAUSE);
  }

  public async onDeleteAccountConfirm(): Promise<void> {
    const { deleteAccount } = this.props;
    await deleteAccount();
    this.setState({ showDeleteConfirmDialog: false });
  }

  private onBackClick(e: React.MouseEvent): void {
    e.preventDefault();

    const { lastLocation, history } = this.props;

    if (lastLocation != null) {
      history.goBack();
    } else {
      history.replace('/');
    }
  }


  public openEditTwoFactorAuthentication(e: React.MouseEvent): void {
    e.stopPropagation();
    const { openEditTwoFactorAuthentication } = this.props;
    openEditTwoFactorAuthentication('settings');
  }

  public openEditAccount(e: React.MouseEvent): void {
    e.stopPropagation();
    const { openEditAccount } = this.props;
    openEditAccount('settings');
  }

  public render(): JSX.Element {
    const {
      oe, oeData, autolockDuration, onAutoLockChange,
      language, updateLanguageChoice, userData, isUnregisteredMode,
      checkUsernames, setHibpBreachCheck, intl, onGetProClick,
    } = this.props;
    const { loaded, showDeleteConfirmDialog } = this.state;
    if (loaded) {
      return (
        <PageContentWrapper position="relative" className="a-settings">
          <PageTitle onBackClick={config.spotlight ? this.onBackClick : undefined}>
            <FormattedMessage id="dashboard.settings.settings" />
          </PageTitle>
          <Box>
            <Box mb="l">
              <AccountSettings
                oe={oe}
                oeData={oeData}
                onLogoutClick={this.onLogoutClick}
                openEditTwoFactorAuthentication={this.openEditTwoFactorAuthentication}
                openEditAccount={this.openEditAccount}
                onDeleteAccountClick={() => this.setState({ showDeleteConfirmDialog: true })}
                isUnregisteredMode={isUnregisteredMode}
              />
            </Box>
            <ConfigRenderer condition="spotlight" negate>
              <Box mb="l">
                <Language
                  handleLanguageChange={updateLanguageChoice}
                  language={language}
                />
              </Box>
            </ConfigRenderer>
            <Box mb="l">
              <Lock
                autolockDuration={isUnregisteredMode ? null : autolockDuration}
                onAutoLockChange={onAutoLockChange}
                isUnregisteredMode={isUnregisteredMode}
              />
            </Box>
            <ConfigRenderer condition="spotlight" negate>
              <Box mb="l">
                <ExtensionCardWrapper page="settings" />
              </Box>
              <Box mb="l">
                <ImportExport />
              </Box>
            </ConfigRenderer>
            <ConfigRenderer condition={(_, state) => isUpsellAllowed(state)}>
              <Box mb="l">
                <BreachChecks
                  allowBreachCheck={checkUsernames}
                  onToggle={setHibpBreachCheck}
                  onGetProClick={onGetProClick}
                  isPro={userData.isPro}
                  link={(oeData && oeData.paymentUrl) || ''}
                />
              </Box>
            </ConfigRenderer>
          </Box>
          <ConfigRenderer condition="spotlight" negate>
            <ConfirmationDialog
              show={showDeleteConfirmDialog}
              confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.delete' })}
              cancelLabel={intl.formatMessage({ id: 'dashboard.account.details.cancel' })}
              onConfirm={this.onDeleteAccountConfirm}
              onCancel={() => { this.setState({ showDeleteConfirmDialog: false }); }}
              cancelButtonType="secondary"
              confirmButtonType="alert"
            >
              <Box mb="l">
                <Paragraph mb="l" textAlign="center">
                  <img src={confirmDeleteIcon} alt="Delete Account" />
                </Paragraph>
                <Paragraph textAlign="center">
                  <FormattedMessage id="dashboard.settings.accountSettings.confirmDelete" />
                </Paragraph>
              </Box>
            </ConfirmationDialog>
          </ConfigRenderer>
        </PageContentWrapper>
      );
    }

    return <Loading />;
  }
}

export default injectIntl(Settings);
