import React from 'react';

import PasswordsIcon from 'pwm-components/icons/Passwords';
import NotesIcon from 'pwm-components/icons/Notes';
import WalletIcon from 'pwm-components/icons/Wallet';

import { SidebarItem as SidebarItemType } from './SidebarTypes';
import config from '../../config';

type ChildProps = {
  items: SidebarItemType[];
};

type Props = {
  component?: React.ComponentType<ChildProps>;
  render?: (props: ChildProps) => React.ReactElement;
};

const SidebarWrapper: React.FC<Props> = ({ component: Component, render }) => {
  const myDataItems = [
    {
      id: 'passwords',
      icon: (
        <PasswordsIcon
          variant="system"
        />
      ),
      label: 'passwords',
      linkTo: '/mydata/passwords',
    },
    {
      id: 'notes',
      icon: (
        <NotesIcon
          variant="system"
        />
      ),
      label: 'notes',
      linkTo: '/mydata/notes',
    },
    {
      id: 'wallet',
      icon: (
        <WalletIcon
          variant="system"
        />
      ),
      label: 'wallet',
      linkTo: '/mydata/wallet',
    },
  ];

  if (config.spotlight) {
    myDataItems.unshift({
      id: 'security-status',
      icon: (<></>), // No Icon Required
      label: 'securityStatus',
      linkTo: '/security-status',
    });
  }

  if (typeof Component !== 'undefined') {
    return (
      <Component items={myDataItems} />
    );
  }

  if (typeof render === 'function') {
    return render({ items: myDataItems });
  }

  console.error(new Error('Sidebar wrapper needs either a "component" or a "render" prop'));
  return null;
};

export default SidebarWrapper;
