import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onChangeMasterPassword, triggerMasterPasswordChange } from '../ChangeMasterPasswordActions';
import ChangeMasterPassword from './ChangeMasterPassword';

const mapDispatchToProps = dispatch => bindActionCreators({
  onSubmit: onChangeMasterPassword,
  onContinueClick: triggerMasterPasswordChange,
}, dispatch);


export default connect(null, mapDispatchToProps)(ChangeMasterPassword);
