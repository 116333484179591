import React, { useEffect } from 'react';
import styled from 'pwm-components/styled';
import ViewerProps from './ViewerProps';

export type Props = ViewerProps & {
  type: 'video' | 'audio';
  mimeType?: string | null;
  shouldPlay?: boolean;
};

const Video = styled('video')`
  display: block;
  max-width: 90vw;
  max-height: 90vh;
`;

const Audio = styled('audio')`
  display: block;
  max-width: 90vw;
  max-height: 90vh;
`;

const AudioVideoViewer: React.FC<Props> = ({
  type, mimeType, data, shouldPlay,
}) => {
  const ref = React.createRef<HTMLVideoElement>();
  const blobType = mimeType || (type === 'video' ? 'video/mp4' : 'audio/mpeg');
  const blob = new Blob([data.buffer], { type: blobType });
  const url = URL.createObjectURL(blob);
  const elProps = {
    ref,
    autoPlay: true,
    controls: true,
    src: url,
    onClick: (e: React.MouseEvent<HTMLVideoElement | HTMLAudioElement, MouseEvent>) => {
      e.stopPropagation();
    },
  };

  useEffect(() => {
    if (!ref || !ref.current) {
      return;
    }

    if (!shouldPlay && !!ref.current.played) {
      ref.current.pause();
    }
  }, [shouldPlay, ref]);

  return (
    (type === 'video') ? <Video {...elProps} /> : <Audio {...elProps} />
  );
};

export default AudioVideoViewer;
