import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Button from 'pwm-components/components/Button';

import Banner from '../../componentLib/Banner';
import ConfigRenderer from '../../componentLib/ConfigRenderer';

const ExtensionOutdatedBanner = ({ onUpdateClick }) => (
  <ConfigRenderer condition="spotlight" negate>
    <Box mb="xl">
      <Banner
        actionButton={(
          <Button
            variant="login"
            onClick={onUpdateClick}
          >
            <FormattedMessage
              id="dashboard.extension.outdated.button"
              defaultMessage="Update extension"
            />
          </Button>
        )}
      >
        <Box>
          <Paragraph size="h2">
            <FormattedMessage
              id="dashboard.extension.outdated.title"
              defaultMessage="Outdated extension"
            />
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="dashboard.extension.outdated.message"
              defaultMessage="Update it now to get the latest features for a better experience"
            />
          </Paragraph>
        </Box>
      </Banner>
    </Box>
  </ConfigRenderer>
);

ExtensionOutdatedBanner.propTypes = {
  onUpdateClick: PropTypes.func.isRequired,
};

export default ExtensionOutdatedBanner;
