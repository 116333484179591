//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//

import Item from './VTItem';
import constants from './VTConstants';

const dsv2Messages = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const { Value } = dsv2Messages;
const { DataTypeID } = Value;
const { logger } = SymBfw;
const { isNil } = SymBfw.utils;
const ITEM_INTERNAL_CONTEXT = 'internal';

const identityKeys = [
  constants.DEFAULT_NODE_ID,
  constants.DEFAULT_LASTUPDATE,
  constants.DEFAULT_FAVORITE,
  constants.IDENTITY.SALUTATION,
  constants.IDENTITY.FIRSTNAME,
  constants.IDENTITY.MIDDLENAME,
  constants.IDENTITY.LASTNAME,
  constants.IDENTITY.LASTNAME2,
  constants.IDENTITY.FIRSTNAME_ALPHABET,
  constants.IDENTITY.LASTNAME_ALPHABET,
  constants.IDENTITY.FIRSTNAME_ROMAN,
  constants.IDENTITY.LASTNAME_ROMAN,
  constants.IDENTITY.DOB_DAY,
  constants.IDENTITY.DOB_MONTH,
  constants.IDENTITY.DOB_YEAR,
  constants.IDENTITY.EMAIL,
  constants.IDENTITY.PHONE_WORK,
  constants.IDENTITY.PHONE_HOME,
  constants.IDENTITY.PHONE_MOBILE,
  constants.IDENTITY.PHONE_EXTENSION,
  constants.IDENTITY.CARDNAME,
  constants.IDENTITY.SECURE,
  constants.IDENTITY.GENDER,
  constants.IDENTITY.ADDRESS_GUID,
  constants.IDENTITY.CREDITCARD_GUID,
  constants.IDENTITY.COUNTRY_REGION,
];

const propertyToSchemaKeyMap = {
  path: constants.DEFAULT_NODE_ID,
  lastUpdate: constants.DEFAULT_LASTUPDATE,
  favorite: constants.DEFAULT_FAVORITE,
  salutation: constants.IDENTITY.SALUTATION,
  firstName: constants.IDENTITY.FIRSTNAME,
  middleName: constants.IDENTITY.MIDDLENAME,
  lastName: constants.IDENTITY.LASTNAME,
  lastName2: constants.IDENTITY.LASTNAME2,
  firstNameAlphabet: constants.IDENTITY.FIRSTNAME_ALPHABET,
  lastNameAlphabet: constants.IDENTITY.LASTNAME_ALPHABET,
  firstNameRoman: constants.IDENTITY.FIRSTNAME_ROMAN,
  lastNameRoman: constants.IDENTITY.LASTNAME_ROMAN,
  dobDay: constants.IDENTITY.DOB_DAY,
  dobMonth: constants.IDENTITY.DOB_MONTH,
  dobYear: constants.IDENTITY.DOB_YEAR,
  email: constants.IDENTITY.EMAIL,
  work: constants.IDENTITY.PHONE_WORK,
  home: constants.IDENTITY.PHONE_HOME,
  mobile: constants.IDENTITY.PHONE_MOBILE,
  extension: constants.IDENTITY.PHONE_EXTENSION,
  secure: constants.IDENTITY.SECURE,
  gender: constants.IDENTITY.GENDER,
  cardName: constants.IDENTITY.CARDNAME,
  creditCardGuid: constants.IDENTITY.CREDITCARD_GUID,
  addressGuid: constants.IDENTITY.ADDRESS_GUID,
  region: constants.IDENTITY.COUNTRY_REGION,
};

/**
 * @constructor Identity
 * @desc Constructor for creating a new Identity item.
 */
class Identity extends Item {
  constructor() {
    super();

    // state of the object (encrypted/decrypted)
    this.encrypted = true;
    this.propertyToSchemaKeyMap = propertyToSchemaKeyMap;
    this.keys = identityKeys;
  }

  schemaForKey(key) {
    switch (key) {
      case constants.DEFAULT_NODE_ID:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setPath,
          getter: this.getPath
        };

      case constants.DEFAULT_LASTUPDATE:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_UINT64,
          deserializedType: DataTypeID.TID_UINT64,
          setter: this.setLastUpdate,
          getter: this.getLastUpdate
        };
      case constants.DEFAULT_FAVORITE:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setFavorite,
          getter: this.getFavorite
        };
      case constants.IDENTITY.SALUTATION:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setSalutation,
          getter: this.getSalutation
        };
      case constants.IDENTITY.FIRSTNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setFirstName,
          getter: this.getFirstName
        };
      case constants.IDENTITY.MIDDLENAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setMiddleName,
          getter: this.getMiddleName
        };
      case constants.IDENTITY.LASTNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setLastName,
          getter: this.getLastName
        };
      case constants.IDENTITY.LASTNAME2:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setLastName2,
          getter: this.getLastName2
        };
      case constants.IDENTITY.FIRSTNAME_ALPHABET:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setFirstNameAlphabet,
          getter: this.getFirstNameAlphabet
        };
      case constants.IDENTITY.LASTNAME_ALPHABET:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setLastNameAlphabet,
          getter: this.getLastNameAlphabet
        };
      case constants.IDENTITY.FIRSTNAME_ROMAN:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setFirstNameRoman,
          getter: this.getFirstNameRoman
        };
      case constants.IDENTITY.LASTNAME_ROMAN:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setLastNameRoman,
          getter: this.getLastNameRoman
        };
      case constants.IDENTITY.DOB_DAY:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setDobDay,
          getter: this.getDobDay
        };
      case constants.IDENTITY.DOB_MONTH:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setDobMonth,
          getter: this.getDobMonth
        };
      case constants.IDENTITY.DOB_YEAR:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setDobYear,
          getter: this.getDobYear
        };
      case constants.IDENTITY.EMAIL:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setEmail,
          getter: this.getEmail
        };
      case constants.IDENTITY.PHONE_WORK:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setPhoneWork,
          getter: this.getPhoneWork
        };
      case constants.IDENTITY.PHONE_HOME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setPhoneHome,
          getter: this.getPhoneHome
        };
      case constants.IDENTITY.PHONE_MOBILE:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setMobile,
          getter: this.getMobile
        };
      case constants.IDENTITY.PHONE_EXTENSION:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setPhoneExtension,
          getter: this.getPhoneExtension
        };
      case constants.IDENTITY.CARDNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setCardName,
          getter: this.getCardName
        };
      case constants.IDENTITY.SECURE:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setSecure,
          getter: this.getSecure
        };
      case constants.IDENTITY.GENDER:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setGender,
          getter: this.getGender
        };
      case constants.IDENTITY.CREDITCARD_GUID:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setCreditCardGuid,
          getter: this.getCreditCardGuid
        };
      case constants.IDENTITY.ADDRESS_GUID:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setAddressGuid,
          getter: this.getAddressGuid
        };

      case constants.IDENTITY.COUNTRY_REGION:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setRegion,
          getter: this.getRegion
        };

      default:
        logger.error(`Invalid data value: ${key}`);
        break;
    }

    return null;
  }


  /**
     * @function getNodePath
     * @desc Implementation of interface function that returns the full path to the current Address
     * @returns Returns a string that contains the full path to the item in the datastore
     */
  getNodePath() {
    // format /11/IDSC/2/<guid>
    return `${constants.DEFAULT_IDENTITY_NODE}/${this.getPath()}`;
  }

  /**
     * @function getSalutation
     * @desc gets the salutation
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  salutation
     */
  getSalutation(context) {
    return super.getDeobfuscatedStringProperty(this.salutation, context);
  }

  /**
     * @function setSalutation
     * @desc setter to set the salutation
     * @param  {string} value the salutation
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setSalutation(value, context) {
    super.setClearOrObfuscatedStringProperty('salutation', value, context);
  }

  /**
     * @function getFirstName
     * @desc gets the firstName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  firstName
     */
  getFirstName(context) {
    return super.getDeobfuscatedStringProperty(this.firstName, context);
  }

  /**
     * @function setFirstName
     * @desc sets the firstName
     * @param  {string} value the firstName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setFirstName(value, context) {
    super.setClearOrObfuscatedStringProperty('firstName', value, context);
  }

  /**
     * @function getMiddleName
     * @desc gets the middleName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  middleName
     */
  getMiddleName(context) {
    return super.getDeobfuscatedStringProperty(this.middleName, context);
  }

  /**
     * @function setMiddleName
     * @desc sets the middleName
     * @param  {string} value the middleName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setMiddleName(value, context) {
    super.setClearOrObfuscatedStringProperty('middleName', value, context);
  }

  /**
     * @function getLastName
     * @desc gets the lastName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  lastName
     */
  getLastName(context) {
    return super.getDeobfuscatedStringProperty(this.lastName, context);
  }

  /**
     * @function setLastName
     * @desc sets the lastName
     * @param  {string} value the lastName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setLastName(value, context) {
    super.setClearOrObfuscatedStringProperty('lastName', value, context);
  }

  /**
     * @function getLastName2
     * @desc gets the lastName2
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  lastName2
     */
  getLastName2(context) {
    return super.getDeobfuscatedStringProperty(this.lastName2, context);
  }

  /**
     * @function setLastName2
     * @desc sets the lastName2
     * @param  {string} value the lastName2
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setLastName2(value, context) {
    super.setClearOrObfuscatedStringProperty('lastName2', value, context);
  }

  /**
     * @function getFirstNameAlphabet
     * @desc gets the firstNameAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  firstNameAlphabet
     */
  getFirstNameAlphabet(context) {
    return super.getDeobfuscatedStringProperty(this.firstNameAlphabet, context);
  }

  /**
     * @function setFirstNameAlphabet
     * @desc sets the firstNameAlphabet
     * @param  {string} value the firstNameAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setFirstNameAlphabet(value, context) {
    super.setClearOrObfuscatedStringProperty('firstNameAlphabet', value, context);
  }

  /**
     * @function getLastNameAlphabet
     * @desc gets the lastNameAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  lastNameAlphabet
     */
  getLastNameAlphabet(context) {
    return super.getDeobfuscatedStringProperty(this.lastNameAlphabet, context);
  }

  /**
     * @function setLastNameAlphabet
     * @desc sets the lastNameAlphabet
     * @param  {string} value the lastNameAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setLastNameAlphabet(value, context) {
    super.setClearOrObfuscatedStringProperty('lastNameAlphabet', value, context);
  }

  /**
     * @function getFirstNameRoman
     * @desc gets the firstNameRoman
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  firstNameRoman
     */
  getFirstNameRoman(context) {
    return super.getDeobfuscatedStringProperty(this.firstNameRoman, context);
  }

  /**
     * @function setFirstNameRoman
     * @desc sets the firstNameRoman
     * @param  {string} value the firstName
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setFirstNameRoman(value, context) {
    super.setClearOrObfuscatedStringProperty('firstNameRoman', value, context);
  }

  /**
     * @function getLastNameRoman
     * @desc gets the lastNameRoman
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  lastNameRoman
     */
  getLastNameRoman(context) {
    return super.getDeobfuscatedStringProperty(this.lastNameRoman, context);
  }

  /**
     * @function setLastNameRoman
     * @desc sets the lastNameRoman
     * @param  {string} value the lastNameRoman
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setLastNameRoman(value, context) {
    super.setClearOrObfuscatedStringProperty('lastNameRoman', value, context);
  }

  /**
     * @function getDobDay
     * @desc gets the dobDay
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  dobDay
     */
  getDobDay(context) {
    return super.getDeobfuscatedStringProperty(this.dobDay, context);
  }

  /**
     * @function setDobDay
     * @desc sets the dobDay
     * @param  {string} value the dobDay
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setDobDay(value, context) {
    super.setClearOrObfuscatedStringProperty('dobDay', value, context);
  }

  /**
     * @function getDobMonth
     * @desc gets the dobMonth
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  dobMonth
     */
  getDobMonth(context) {
    return super.getDeobfuscatedStringProperty(this.dobMonth, context);
  }

  /**
     * @function setDobMonth
     * @desc sets the dobMonth
     * @param  {string} value the dobMonth
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setDobMonth(value, context) {
    super.setClearOrObfuscatedStringProperty('dobMonth', value, context);
  }

  /**
     * @function getDobYear
     * @desc gets the dobYear
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  dobYear
     */
  getDobYear(context) {
    return super.getDeobfuscatedStringProperty(this.dobYear, context);
  }

  /**
     * @function setDobYear
     * @desc sets the dobYear
     * @param  {string} value the dobYear
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setDobYear(value, context) {
    super.setClearOrObfuscatedStringProperty('dobYear', value, context);
  }

  /**
     * @function getEmail
     * @desc gets the email
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  email
     */
  getEmail(context) {
    return super.getDeobfuscatedStringProperty(this.email, context);
  }

  /**
     * @function setEmail
     * @desc sets the email
     * @param  {string} value the email
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setEmail(value, context) {
    super.setClearOrObfuscatedStringProperty('email', value, context);
  }

  /**
     * @function getPhoneWork
     * @desc gets the work
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  work
     */
  getPhoneWork(context) {
    return super.getDeobfuscatedStringProperty(this.work, context);
  }

  /**
     * @function setPhoneWork
     * @desc sets the work
     * @param  {string} value the work
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setPhoneWork(value, context) {
    super.setClearOrObfuscatedStringProperty('work', value, context);
  }

  /**
     * @function getPhoneHome
     * @desc gets the home
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  home
     */
  getPhoneHome(context) {
    return super.getDeobfuscatedStringProperty(this.home, context);
  }

  /**
     * @function setPhoneHome
     * @desc sets the home
     * @param  {string} value the home
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setPhoneHome(value, context) {
    super.setClearOrObfuscatedStringProperty('home', value, context);
  }

  /**
     * @function getMobile
     * @desc gets the mobile
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  mobile
     */
  getMobile(context) {
    return super.getDeobfuscatedStringProperty(this.mobile, context);
  }

  /**
     * @function setMobile
     * @desc sets the mobile
     * @param  {string} value the mobile
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setMobile(value, context) {
    super.setClearOrObfuscatedStringProperty('mobile', value, context);
  }

  /**
     * @function getPhoneExtension
     * @desc gets the extension
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  extension
     */
  getPhoneExtension(context) {
    return super.getDeobfuscatedStringProperty(this.extension, context);
  }

  /**
     * @function setPhoneExtension
     * @desc sets the extension
     * @param  {string} value the extension
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setPhoneExtension(value, context) {
    super.setClearOrObfuscatedStringProperty('extension', value, context);
  }

  /**
     * @function getCardName
     * @desc gets the cardName
     * @return {string}  the cardName
     */
  getCardName() {
    return this.cardName;
  }

  /**
     * @function setCardName
     * @desc sets the cardName
     * @param  {boolean} value the value to set
     */
  setCardName(value) {
    this.cardName = value;
  }

  /**
     * @function getGender
     * @desc gets the Gender state
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     * @return {boolean}  the gender state
     */
  getGender() {
    return this.gender;
    // return Item.prototype.getDeobfuscatedStringProperty.call(this, this.gender, context);
  }

  /**
     * @function setGender
     * @desc sets the Gender state
     * @param  {boolean} value the value to set
     * @param {String=} context Internal context or not. If you are calling this getter for getting 
     * the raw value (if obfuscated the binary) then ignore this value.
     */
  setGender(value) {
    this.gender = value;
    // Item.prototype.setClearOrObfuscatedStringProperty.call(this, "gender", value, context);
  }

  /**
     * @function getCreditCardGuid
     * @desc gets the creditCardGuid
     * @return {string}  the creditCardGuid
     */
  getCreditCardGuid() {
    return this.creditCardGuid;
  }

  /**
     * @function setCreditCardGuid
     * @desc sets the creditCardGuid
     * @param  {boolean} value the value to set
     */
  setCreditCardGuid(value) {
    this.creditCardGuid = value;
  }

  /**
     * @function getAddressGuid
     * @desc gets the addressGuid
     * @return {string}  addressGuid
     */
  getAddressGuid() {
    return this.addressGuid;
  }

  /**
     * @function setAddressGuid
     * @desc sets the addressGuid
     * @param  {string} value the addressGuid
     */
  setAddressGuid(value) {
    this.addressGuid = value;
  }

  /**
     * @function getRegion
     * @desc gets the region
     * @return {string}  region
     */
  getRegion(context) {
    if (context === ITEM_INTERNAL_CONTEXT) {
      return this.region;
    }

    if (isNil(this.region)) {
      return '';
    }
    return this.region.toLowerCase();
  }

  /**
     * @function setRegion
     * @desc sets the region
     * @param  {string} value the region
     */
  setRegion(value) {
    this.region = value;
  }
}

export default Identity;
