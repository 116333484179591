import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import * as Sentry from '@sentry/browser';

import { breaches, pwnedPassword, Breach } from 'hibp';

import { decryptKey, encryptKey, generateKey } from '@avira-pwm/crypto-tools/CryptoTools';

import { getDomain } from '@avira-pwm/helpers/url';
import {
  SET_HIBP_PASSWORDS,
  SET_HIBP_BREACHED_WEBSITES,
  SET_HIBP_USERNAMES,
  CLEAR_HIBP_DATA,
  CLEAR_HIBP_USERNAMES,
  STOP_HIBP_USERNAME_CHECKS,
  HIBPActionTypes,
} from './HIBPActionTypes';

import { State as HIBPState } from './HIBPReducer';
import { State as UserState } from '../user/UserReducer';

import { getNextHibpUsernameToCheck } from './selectors';
import { getReusedPasswords } from './accountSelector';
import { checkHibpUsernames } from '../user/selectors';
import { getSuggestedUsernames } from '../componentLib/selectors';

import { hasUsernameForbiddenHIBPChars } from './helpers';
import { HIBP_CHECKS_INTERVAL_MS } from './constants';

import asyncJsonStorage from '../lib/asyncJsonStorage';

import config from '../config';

import { TrackingActions, MixpanelEvents } from '../tracking';

const { trackEvent } = TrackingActions;
const {
  MP_SECURITYSTATUS_HIBP_ERROR,
} = MixpanelEvents;

type State = {
  hibp: HIBPState;
  user: UserState;
}

type ExtraArguments = {}

type HIBPThunkAction<R> = ThunkAction<R, State, ExtraArguments, AnyAction>

const PERSISTED_HIBP_USERNAMES = 'HIBP_usernames';
const HIBP_SALT = 'HIBP_salt';

let hibpCallInProgress = false;
let hibpCallCount = 0;
let hibpRateLimit = 0;

export const setHibpUsername = (username: string, data: any): HIBPActionTypes => ({
  type: SET_HIBP_USERNAMES,
  payload: { [username]: { breaches: data, lastChecked: Date.now() } },
});

export const clearHibpUsernames = (): HIBPActionTypes => ({ type: CLEAR_HIBP_USERNAMES });

export const clearHibpData = (): HIBPActionTypes => ({ type: CLEAR_HIBP_DATA });

const persistHibpUsernames = (): HIBPThunkAction<void> => async (dispatch, getState) => {
  const { hibp: { usernames }, user: { key } } = getState();
  try {
    let { [HIBP_SALT]: salt } = await asyncJsonStorage.get([HIBP_SALT]) as { [HIBP_SALT]: string};
    if (!salt) {
      salt = generateKey();
      await asyncJsonStorage.set({ [HIBP_SALT]: salt });
    }

    if (key && key.length > 0) {
      const encryptedUsernames = encryptKey(key, JSON.stringify(usernames), salt);
      await asyncJsonStorage.set({ [PERSISTED_HIBP_USERNAMES]: encryptedUsernames });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getPersistedHibpUsernames = ():
HIBPThunkAction<void> => async (dispatch, getState) => {
  const { user: { key } } = getState();
  try {
    const {
      [PERSISTED_HIBP_USERNAMES]: persistedState,
      [HIBP_SALT]: salt,
    } = await asyncJsonStorage.get([PERSISTED_HIBP_USERNAMES, HIBP_SALT]);

    if (!(persistedState && salt)) return;

    if (key && key.length > 0) {
      const decryptedState = JSON.parse(
        decryptKey(key,
          persistedState.toString(),
          null,
          salt.toString()),
      );
      dispatch({ type: SET_HIBP_USERNAMES, payload: decryptedState });
    }
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const getHibpBreachedPasswords = (): HIBPThunkAction<void> => async (dispatch, getState) => {
  const { hibp } = getState();
  const passwordsBreachedState = { ...hibp.passwords };
  const promises: Promise<void>[] = [];
  const passwords = getReusedPasswords(getState());

  Object.keys(passwords).forEach((password) => {
    if (!hibp.passwords[password]
      || Date.now() - hibp.passwords[password].lastChecked >= HIBP_CHECKS_INTERVAL_MS
    ) {
      promises.push(pwnedPassword(password)
        .then((number) => {
          passwordsBreachedState[password] = {
            isBreached: number !== 0, lastChecked: Date.now(),
          };
        })
        .catch(() => { /** HIBP calls might fail; we can ignore it */ }));
    }
  });

  await Promise.all(promises);
  dispatch({ type: SET_HIBP_PASSWORDS, payload: passwordsBreachedState });
};


export const getHibpBreachedUsernames = (): HIBPThunkAction<void> => (dispatch, getState) => {
  if (hibpCallInProgress || getState().hibp.stopHibpPolling) return;

  hibpCallInProgress = true;
  // eslint-disable-next-line max-statements, complexity
  setTimeout(async () => {
    const state = getState();
    if (!checkHibpUsernames(state)) {
      hibpCallInProgress = false;
      return;
    }

    const username = getNextHibpUsernameToCheck(state);

    if (!username) {
      hibpCallInProgress = false;
      return;
    }

    if (hasUsernameForbiddenHIBPChars(username)) {
      dispatch(setHibpUsername(username, []));
      hibpCallInProgress = false;
      dispatch(getHibpBreachedUsernames());
      await dispatch(persistHibpUsernames());
      return;
    }

    hibpCallCount += 1;
    try {
      const response = await fetch(`${config.hostConfig.hibpProxyHost}${encodeURIComponent(username)}`, { mode: 'cors' });

      if (response.ok) {
        const data = await response.json();
        dispatch(setHibpUsername(username, data));
        hibpRateLimit = Number(response.headers.get('wait-before'));
      } else if (response.status === 404) {
        dispatch(setHibpUsername(username, []));
        hibpRateLimit = Number(response.headers.get('wait-before'));
      } else if (response.status === 429) {
        hibpRateLimit = Number(response.headers.get('retry-after'));
      } else {
        const distinctUsernames = Object.keys(getSuggestedUsernames(state)).length;
        const loadedUsernames = Object.keys(state.hibp.usernames).length;
        dispatch({ type: STOP_HIBP_USERNAME_CHECKS });
        dispatch(trackEvent(MP_SECURITYSTATUS_HIBP_ERROR, {
          distinctUsernames,
          loadedUsernames,
          hibpCallCount,
          statusCode: response.status,
        }));
      }
    } catch (e) {
      dispatch(setHibpUsername(username, []));
      Sentry.captureException(e);
    }

    hibpCallInProgress = false;
    dispatch(getHibpBreachedUsernames());
    await dispatch(persistHibpUsernames());
  }, hibpRateLimit);
};

export const loadHibpBreachedWebsites = (): HIBPThunkAction<void> => async (dispatch) => {
  try {
    let hibpBreaches: Breach[] = [];

    try {
      hibpBreaches = await breaches();
    } catch { /** ignore - failed to fetch breaches */ }

    const breachedDomains = hibpBreaches
      .sort((a, b) => new Date(b.BreachDate || '').getTime() - new Date(a.BreachDate || '').getTime())
      .reduce((p, breach) => {
        if (breach.Domain) {
          // convert to tld as domain could have subdomains as well
          const domain = getDomain(breach.Domain);

          if (domain != null) {
            p[domain] = p[domain] || [];
            p[domain].push(breach);
          }
        }
        return p;
      }, {} as { [K: string]: Breach[] });
    dispatch({
      type: SET_HIBP_BREACHED_WEBSITES,
      payload: breachedDomains,
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};
