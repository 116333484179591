//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
//

import ItemManager from './VTItemManager';
import constants from './VTConstants';

const { TAG } = constants;

/**
 * @constructor AddressManager
 * @desc Constructor for creating a new AddressManager.
 */
class AddressManager extends ItemManager {
  constructor() {
    super('AddressManager');
    this.items = {};
    this.tagType = TAG.ADDRESS;
  }
}

export default AddressManager;
