import { pdfjs } from 'react-pdf';
import { getFileData } from './FileDataHelper';

export type Previews = Record<string, Uint8Array>;

type ResizeConfigs = {
  file: File;
  maxWidth?: number;
  maxHeight?: number;
  mimeType?: string;
  quality?: number;
}

export async function resizePdfThumbnail({
  file, maxWidth = 250, maxHeight = 250, mimeType = 'image/png', quality = 1,
}: ResizeConfigs): Promise<string> {
  if (!file) {
    throw (new Error('File Not Found'));
  }

  const data = await getFileData(file);
  return new Promise((resolve, reject) => {
    pdfjs.getDocument(data).promise.then((pdf) => {
      pdf.getPage(1).then((page) => {
        const canvas = document.createElement('canvas');
        let viewport = page.getViewport({ scale: 1.0 });
        const { width, height } = viewport;

        if (width >= height) {
          viewport = page.getViewport({ scale: maxHeight / viewport.height });
        } else {
          viewport = page.getViewport({ scale: maxWidth / viewport.width });
        }

        canvas.height = maxHeight;
        canvas.width = maxWidth;

        const ctx = canvas.getContext('2d');
        if (ctx) {
          page.render({
            canvasContext: ctx,
            viewport,
          }).promise.then(() => {
            const imageURI = canvas.toDataURL(mimeType, quality);
            if (canvas && canvas.parentNode) {
              canvas.parentNode.removeChild(canvas);
            }

            resolve(imageURI.replace(/^data:image\/.*;base64,/, ''));
          });
        } else {
          reject(new Error('Failed to create canvas context'));
        }
      });
    });
  });
}

export async function generatePreviews(
  id: string,
  file: File,
): Promise<Previews> {
  const thumbnail = Buffer.from(await resizePdfThumbnail({ file }), 'base64');
  const data = await getFileData(file);

  return {
    [`${id}_thumb`]: thumbnail,
    [id]: data,
  };
}

export default generatePreviews;
