import { connect } from 'react-redux';
import PWMThemeProvider from 'pwm-components/ThemeProvider';
import { Mode } from 'pwm-components/theme';
import config from '../../config';

const mapStateToProps = ({ preferences }: any): { mode: Mode; spotlight: boolean } => ({
  mode: preferences.theme,
  spotlight: config.spotlight,
});

export default connect(mapStateToProps)(PWMThemeProvider);
