import { TrackingActions, MixpanelEvents } from '../../tracking';

import {
  STEP_MOBILE,
  STEP_MOBILE_BIOMETRIC,
  STEP_MOBILE_CHANGE,
  STEP_DASHBOARD_CHANGE,
  STEP_EMAIL_SENT,
  CONGRATULATION,
} from '../../lib/ResetAccountHelper';

const { trackEvent } = TrackingActions;

const {
  MP_RESET_ACCOUNT,
  MP_RESET_ACCOUNT_MOBILE_YES,
  MP_RESET_ACCOUNT_MOBILE_NO,
  MP_RESET_ACCOUNT_BIOMETRIC_YES,
  MP_RESET_ACCOUNT_BIOMETRIC_NO,
  MP_RESET_ACCOUNT_MOBILE_CHANGE_SUCCESS,
  MP_RESET_ACCOUNT_MOBILE_CHANGE_FAIL,
  MP_RESET_ACCOUNT_SEND_EMAIL,
  MP_RESET_ACCOUNT_SEND_EMAIL_CANCEL,
  MP_RESET_ACCOUNT_CONFIRMED,
  MP_RESET_ACCOUNT_CONFIRM_ERROR,
} = MixpanelEvents;

const stepMapping = {
  [STEP_MOBILE]: MP_RESET_ACCOUNT,
  [STEP_MOBILE_BIOMETRIC]: MP_RESET_ACCOUNT_MOBILE_YES,
  [STEP_DASHBOARD_CHANGE + STEP_MOBILE]: MP_RESET_ACCOUNT_MOBILE_NO,
  [STEP_MOBILE_CHANGE]: MP_RESET_ACCOUNT_BIOMETRIC_YES,
  [STEP_DASHBOARD_CHANGE + STEP_MOBILE_BIOMETRIC]: MP_RESET_ACCOUNT_BIOMETRIC_NO,
  [CONGRATULATION]: MP_RESET_ACCOUNT_MOBILE_CHANGE_SUCCESS,
  [STEP_DASHBOARD_CHANGE + STEP_MOBILE_CHANGE]: MP_RESET_ACCOUNT_MOBILE_CHANGE_FAIL,
  [STEP_MOBILE + STEP_DASHBOARD_CHANGE]: MP_RESET_ACCOUNT_SEND_EMAIL_CANCEL,
  [STEP_EMAIL_SENT]: MP_RESET_ACCOUNT_SEND_EMAIL,
};

export const trackResetAccount = (nextStep, prevStep = '') => async (dispatch) => {
  dispatch(trackEvent(stepMapping[nextStep + prevStep]));
};

export const trackResetAccountConfirmed = () => async (dispatch) => {
  dispatch(trackEvent(MP_RESET_ACCOUNT_CONFIRMED));
};

export const trackResetAccountError = error => async (dispatch) => {
  dispatch(trackEvent(MP_RESET_ACCOUNT_CONFIRM_ERROR, { error }));
};
