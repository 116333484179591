import React from 'react';
import { Subdomain } from '@avira-pwm/sync/ModelSpecifics/Account';

import { Entity as AccountEntity } from '@avira-pwm/redux/accounts';

import styled from 'pwm-components/styled';

import Box from 'pwm-components/components/Box';
import ChevronArrow from 'pwm-components/components/ChevronArrow';
import LoginIcon from 'pwm-components/icons/Login';

import AssociatedUrlsListView from './AssociatedUrlsListView';
import AccountLogo from '../accounts/components/AccountLogo';
import AccountName from '../accounts/components/AccountName';

import { StyledDualButton, DataListCellOnHover } from './DataListComponents';

type NavigateUrlProps = {
  associatedUrlsCollection: Array<Subdomain>;
  onClick: (
    e: React.MouseEvent,
    linkedUrlEntry?: Subdomain,
    loginUrl?: string
  ) => void;
  data: AccountEntity;
  onAssociatedUrlNavigateClicked: (tracking: object) => void;
  showLinkedUrl: boolean;
  displayLinkedUrls: () => void;
  closeLinkedUrls: () => void;
}

const DualButtonLoginIcon = styled(LoginIcon)`
  position: relative;
  vertical-align: middle;
  top: -4px;

  &:hover {
    color: ${({ theme: { colors } }) => colors.white};
    fill: ${({ theme: { colors } }) => colors.white};
  }
`;

const DualButtonChevronArrow = styled(ChevronArrow)`
  vertical-align: middle;
  top: -2px;
`;

const NavigateUrl: React.FC<NavigateUrlProps> = React.memo(({
  associatedUrlsCollection,
  onClick,
  data,
  onAssociatedUrlNavigateClicked,
  showLinkedUrl,
  displayLinkedUrls,
  closeLinkedUrls,
}) => {
  const onAccountClick = (e: React.MouseEvent, subdomain?: Subdomain): void => {
    onClick(e, subdomain);
  };

  let navigationButton = null;
  if (associatedUrlsCollection.length !== 0) {
    navigationButton = (
      <StyledDualButton
        size="small"
        variant="login"
        contentLeft={(
          <DualButtonLoginIcon variant="light" />
        )}
        onClickLeft={(e: React.MouseEvent) => {
          e.stopPropagation();
          onAccountClick(e);
        }}
        contentRight={(
          <DualButtonChevronArrow className="a-subdomains-dropdown-menu-button" direction="down" />
        )}
        onClickRight={(e) => {
          e.stopPropagation();
          if (!showLinkedUrl) {
            displayLinkedUrls();
          } else {
            closeLinkedUrls();
          }
        }}
        visible={showLinkedUrl}
        style={{ padding: 0 }}
      />
    );
  } else if (data.domain) {
    navigationButton = (
      <DataListCellOnHover mr="0">
        <LoginIcon
          variant="link"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onAccountClick(e);
          }}
        />
      </DataListCellOnHover>
    );
  }

  return (
    <>
      <Box
        pr="m"
        flex="0 0 auto"
      >
        <AccountLogo
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
          data={data}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onAccountClick(e);
          }}
        />
      </Box>
      <Box
        flex="1 1 auto"
        minWidth="0px"
      >
        <AccountName
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
          data={data}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            onAccountClick(e);
          }}
        />
      </Box>
      <Box
        pl="m"
        flex="0 0 30px"
        flexGrow={0}
        flexShrink={0}
        flexBasis={30}
        width={30}
        verticalAlign="bottom"
      >
        {navigationButton}
      </Box>
      <AssociatedUrlsListView
        showDropdownMenu={showLinkedUrl}
        domain={data.domain}
        associatedUrlsCollection={associatedUrlsCollection}
        closeLinkedUrls={closeLinkedUrls}
        onLoginClick={onClick}
        onAssociatedUrlNavigateClicked={onAssociatedUrlNavigateClicked}
      />
    </>
  );
});

export default NavigateUrl;
