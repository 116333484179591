import styled, { css } from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

export default styled(Box)`
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme: { space } }) => space.xxxl};
  ${({ extendToBottom }) => !extendToBottom && css`
    padding-bottom: ${({ theme: { space } }) => space.xxxl};
  `}
  justify-content: space-between;
`;
