import * as Sentry from '@sentry/browser';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import AWS from 'aws-sdk';
import { ModelMerge, ModelCrypto, SyncUtils } from '@avira-pwm/sync';
import CognitoAdapter from '@avira-pwm/sync/adapters/CognitoAdapter';
import {
  BACKUP_DATASETS, CLEAR_BACKUP_DATASETS, CLEAR_BACKUP_DATA, BACKUP_DATA,
} from './ManageBackupsActionTypes';
import { generateID } from '../lib/AccountHelper';
import getSyncInstance from '../lib/SyncInstanceHelper';
import { handleSyncError } from '../lib/ErrorHelper';

const arrayToHash = dataArray => keyBy(dataArray, o => o.id);

const connectToCognito = async (dbName) => {
  const cognito = new CognitoAdapter({ dbName, AWS });
  await cognito.connect();

  return cognito;
};

const checkAccountChanged = (oldAccount, newAccount) => {
  let hasChanged = false;
  if (
    oldAccount.domain !== newAccount.domain
    || oldAccount.label !== newAccount.label
    || oldAccount.username !== newAccount.username
    || oldAccount.email !== newAccount.email
    || oldAccount.password !== newAccount.password
    || oldAccount.notes !== newAccount.notes
  ) {
    hasChanged = true;
  }

  return hasChanged;
};

const createAccountHistory = ({
  email, password, username, aid,
}) => ({
  id: generateID(),
  data: {
    email,
    username,
    password,
    aid,
  },
});

const createAccountFromBackup = (backupAccount, backupAccountsHistory) => {
  const omitKeys = ['id'];
  const account = {
    id: generateID(),
    data: {
      ...omit(backupAccount, omitKeys),
    },
  };
  const accountHistory = [];

  backupAccountsHistory.forEach((backupAccountHistory) => {
    if (backupAccountHistory.aid === backupAccount.id) {
      // get username, email, password from backupaccounthistory and the new account id
      accountHistory.push(createAccountHistory({ ...backupAccountHistory, aid: account.id }));
    }
  });
  accountHistory.push(createAccountHistory({ ...account.data, aid: account.id }));
  return {
    account,
    accountHistory,
  };
};

const updateAccountFromBackup = (backupAccount, currentAccount) => {
  const account = {
    id: currentAccount.id,
    data: {},
  };
  const accountHistory = [];
  const dataFields = ['domain', 'label', 'username', 'email', 'password', 'notes'];
  dataFields.forEach((key) => {
    if (currentAccount[key] !== backupAccount[key]) {
      account.data[key] = backupAccount[key];
    }
  });
  accountHistory.push(createAccountHistory({ aid: currentAccount.id, ...currentAccount }));
  return {
    account,
    accountHistory,
  };
};

const decryptAccountsHistory = (accountsHistory, key) => {
  const crypto = new ModelCrypto(key);
  return (accountsHistory && accountsHistory.length > 0)
    ? accountsHistory.map(accountHistory => ModelMerge.flatten('AccountHistory', crypto.decrypt('AccountHistory', accountHistory), true)) : [];
};

const decryptAccounts = (accounts, key) => {
  const crypto = new ModelCrypto(key);
  return (accounts && accounts.length > 0)
    ? arrayToHash(accounts.map(account => ModelMerge.flatten('Account', crypto.decrypt('Account', account), true))) : {};
};

export const getRestoreDataAndMetadata = (
  currentAccounts, currentAccountsHistory, backupAccounts, backupAccountsHistory,
// eslint-disable-next-line max-params
) => {
  const backupMetadata = {
    reverted: 0,
    restored: 0,
    notModified: 0,
  };

  const accountsRestored = {
    created: [],
    updated: [],
  };

  const accountsHistoryRestored = {
    created: [],
  };

  const mergedAccountIds = new Set(
    Object.keys(backupAccounts).concat(Object.keys(currentAccounts)),
  );

  mergedAccountIds.forEach((mergedAccountId) => {
    const currentAccount = currentAccounts[mergedAccountId];
    const backupAccount = backupAccounts[mergedAccountId];

    if (!backupAccount) {
      backupMetadata.notModified += 1;
    } else if (!currentAccount) {
      backupMetadata.restored += 1;
      const {
        account,
        accountHistory,
      } = createAccountFromBackup(backupAccount, backupAccountsHistory);
      accountsRestored.created.push(account);
      accountsHistoryRestored.created = accountsHistoryRestored.created.concat(accountHistory);
    } else if (checkAccountChanged(backupAccount, currentAccount)) {
      backupMetadata.reverted += 1;
      const { account, accountHistory } = updateAccountFromBackup(backupAccount, currentAccount);
      accountsRestored.updated.push(account);
      accountsHistoryRestored.created = accountsHistoryRestored.created.concat(accountHistory);
    }
  });

  return { backupMetadata, accountsRestored, accountsHistoryRestored };
};

export const getBackupDatasets = () => async (dispatch) => {
  const cognito = await connectToCognito();
  const backupDatasets = await cognito.getBackupDatasets();
  dispatch({ type: BACKUP_DATASETS, value: backupDatasets });
};

// eslint-disable-next-line complexity
export const restoreBackup = () => async (dispatch, getState, { syncInstance }) => {
  const { user, backupData, accounts } = getState();
  const sync = getSyncInstance(syncInstance, user.key);
  const promises = [];
  const accountsHistory = decryptAccountsHistory(await syncInstance.getAll('AccountHistory'), user.key);

  const {
    accountsRestored,
    accountsHistoryRestored,
  } = getRestoreDataAndMetadata(accounts, accountsHistory,
    backupData.accounts, backupData.accountsHistory);

  if (accountsRestored.created && accountsRestored.created.length > 0) {
    promises.push(SyncUtils.createBatch(sync, 'Account', accountsRestored.created));
  }
  if (accountsRestored.updated && accountsRestored.updated.length > 0) {
    promises.push(SyncUtils.updateBatch(sync, 'Account', accountsRestored.updated));
  }

  if (accountsHistoryRestored.created && accountsHistoryRestored.created.length > 0) {
    promises.push(SyncUtils.createBatch(sync, 'AccountHistory', accountsHistoryRestored.created));
  }

  try {
    await Promise.all(promises);
  } catch (e) {
    handleSyncError(e, 'restoreBackup');
  }
};

export const setBackupData = dbName => async (
  dispatch, getState, { syncInstance },
) => {
  let backupAccounts;
  let backupAccountsHistory;
  const { user, accounts } = getState();
  const accountsHistory = decryptAccountsHistory(await syncInstance.getAll('AccountHistory'), user.key);
  const cognito = await connectToCognito(dbName);

  await Promise.all([
    cognito.getAll('Account'),
    cognito.getAll('AccountHistory'),
  ]).then(([acc, accHistory]) => {
    backupAccounts = decryptAccounts(acc, user.key);
    backupAccountsHistory = decryptAccountsHistory(accHistory, user.key);
  });

  const { backupMetadata } = getRestoreDataAndMetadata(
    accounts, accountsHistory, backupAccounts, backupAccountsHistory,
  );

  dispatch({
    type: BACKUP_DATA,
    value: {
      accounts: backupAccounts,
      accountsHistory: backupAccountsHistory,
      metadata: backupMetadata,
    },
  });
};


export const deleteBackupDataset = dbName => async (dispatch) => {
  try {
    const cognito = await connectToCognito();
    await cognito.deleteDataset(dbName);
    dispatch(getBackupDatasets());
  } catch (error) {
    Sentry.captureException(error);
  }
};

export const clearBackupDatasets = () => async (dispatch) => {
  dispatch({ type: CLEAR_BACKUP_DATASETS });
};

export const clearBackupData = () => async (dispatch) => {
  dispatch({ type: CLEAR_BACKUP_DATA });
};
