import { DeepReadonly } from 'utility-types';
import { Type as AccountType } from '@avira-pwm/sync/ModelSpecifics/Account';
import { DeletedDataStructure } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';

import {
  ACCOUNTS_DATA,
  AccountsData,
  ACCOUNTS_CLEAR,
  AccountsClear,
} from './AccountActionTypes';
import arrayToHash from '../lib/arrayToHash';

export type State = DeepReadonly<{
  passwords: {
    [key: string]: string | undefined;
  };
}>;

function getInitialState(): State {
  return {
    passwords: {},
  };
}

type Actions = AccountsData | AccountsClear;

export default function accountModifiedAtReducer(
  state: State = getInitialState(),
  action: Actions,
): State {
  switch (action.type) {
    case ACCOUNTS_DATA: {
      return {
        ...state,
        passwords: arrayToHash(
          action.accounts,
          'id',
          {
            filter: (item: AccountType) => !(item as DeletedDataStructure).deleted,
            map: (item: AccountType) => (
              (item.timestamps && item.timestamps.password) || item.modifiedAt
            ),
          },
        ),
      };
    }
    case ACCOUNTS_CLEAR:
      return getInitialState();
    default:
      return state;
  }
}
