/* eslint import/prefer-default-export: 0 */
import { UPDATE_ALL_PREFERENCES } from '../preferences/PreferencesActionTypes';

const allowedStateSyncs = {
  'PERSISTENT_STATE:auth': {
    actionType: null,
    refresh: (newValue, oldValue) => (
      !newValue || !oldValue || newValue.timestamp !== oldValue.timestamp
    ),
  },
  'PERSISTENT_STATE:preferences': {
    actionType: UPDATE_ALL_PREFERENCES,
    refresh: false,
  },
};

export const syncStateWithStorage = (key, newValue, oldValue) => (dispatch) => {
  const stateAction = allowedStateSyncs[key];
  if (stateAction) {
    const { actionType, refresh: stateActionRefresh } = stateAction;
    let refresh = false;
    if (typeof stateActionRefresh === 'function') {
      refresh = stateActionRefresh(JSON.parse(newValue), JSON.parse(oldValue));
    } else if (typeof stateActionRefresh === 'boolean') {
      refresh = stateActionRefresh;
    }

    if (refresh) {
      // TODO: use middlewares and actions for blocking the persistence
      window.__BLOCK_PERSISTENCE__ = true;

      window.location.reload();
    } else if (actionType) {
      dispatch({ type: actionType, value: JSON.parse(newValue) });
    }
  }
};
