import { DeepReadonly } from 'utility-types';
import { Entity as AccountEntity } from '@avira-pwm/redux/accounts';
import { Type as AccountHistoryType } from '@avira-pwm/sync/ModelSpecifics/AccountHistory';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';

import { UPDATE_ACCOUNT_DETAILS, UpdateAccountDetails } from './AccountActionTypes';

type AccountHistoryEntity = Flattened<AccountHistoryType>;

export type State = DeepReadonly<{
  account: AccountEntity;
  accountHistory: AccountHistoryEntity[];
}>;

function getInitialState(): State {
  return {
    // TODO: `{}` should be removed and we should use null in this case
    account: {} as unknown as AccountEntity,
    accountHistory: [],
  };
}

type Actions = UpdateAccountDetails;

export default function accountDetailsReducer(
  state: State = getInitialState(),
  action: Actions,
): State {
  switch (action.type) {
    case UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
}
