import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';

import mobile from '../../../img/icon-mobile.png';
import mobile2x from '../../../img/icon-mobile@2x.png';

import folder from '../../../img/icon-folder.png';
import folder2x from '../../../img/icon-folder@2x.png';

import autofill from '../../../img/icon-autofill.png';
import autofill2x from '../../../img/icon-autofill@2x.png';

import secure from '../../../img/icon-secure.png';
import secure2x from '../../../img/icon-secure@2x.png';

const HowDoesPwmWorkUnregistered = () => (
  <>
    <li>
      <Media
        image={(
          <img
            src={folder}
            srcSet={`${folder} 1x, ${folder2x} 2x`}
            alt="cloud"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.unregisteredStep1"
              defaultMessage="Avira Password Manager remembers all your passwords so you don’t have to"
            />
          </Text>
        )}
      />
    </li>

    <li>
      <Media
        image={(
          <img
            src={autofill}
            srcSet={`${autofill} 1x, ${autofill2x} 2x`}
            alt="cloud"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.unregisteredStep2"
              defaultMessage="You can autofill your passwords in your web browser on your desktop computer"
            />
          </Text>
        )}
      />
    </li>

    <li>
      <Media
        image={(
          <img
            src={secure}
            srcSet={`${secure} 1x, ${secure2x} 2x`}
            alt="cloud"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.unregisteredStep3"
              defaultMessage="The security status checks how secure your accounts and passwords are and warns you in case of findings (PRO feature)"
            />
          </Text>
        )}
      />
    </li>

    <li>
      <Media
        image={(
          <img
            src={mobile}
            srcSet={`${mobile} 1x, ${mobile2x} 2x`}
            alt="cloud"
          />
        )}
        body={(
          <Text>
            <FormattedHTMLMessage
              id="dashboard.getStarted.howDoesPwmWork.unregisteredStep4"
              defaultMessage="After registration, you will be able to synchronize your passwords on all your devices and your data is regularly backed up (<a href='/why-register?dashboardContext=get-started' target='_blank'>more details</a>)"
            />
          </Text>
        )}
      />
    </li>
  </>
);

export default HowDoesPwmWorkUnregistered;
