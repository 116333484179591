import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import KeyIcon from 'pwm-components/icons/Key';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import Tooltip from 'pwm-components/components/Tooltip';
import Checkbox from 'pwm-components/components/Checkbox';
import PasswordInput from 'pwm-components/components/PasswordInput';
import TextButton from 'pwm-components/components/TextButton';
import * as SpotlightAPI from '../../lib/SpotlightAPI';
import { Props as MPProps } from './MasterPassword';

import Card from '../../componentLib/CardWrapper';


const MasterPasswordKeyIcon = styled(KeyIcon)`
  width: 70px;
  height: 70px;
  margin: ${({ theme: { space } }) => `${space.xxl} ${space.a}`};
  margin-top: ${({ theme: { space } }) => space.s};
  fill: ${({ theme: { colors } }) => colors.cerulean};
`;

// eslint-disable-next-line complexity
const SpotlightMasterPassword: React.FC<MPProps> = ({
  intl,
  usageAs,
  loading,
  passwordRef,
  isUnregisteredMode,
  password,
  passwordConfirm,
  checkboxConfirm,
  errorPassword,
  errorConfirm,
  errorCheckbox,
  passwordScore,
  onFormSubmit,
  onPasswordChange,
  onPasswordConfirmChange,
  onCheckboxChange,
}) => {
  let passwordPlaceholderId = '';
  let actionLabelId = '';
  let createHTML: JSX.Element | null = null;
  let resetAccountHTML: JSX.Element | null = null;

  if (usageAs === 'create') {
    passwordPlaceholderId = 'masterPassword.create.placeholder';
    actionLabelId = 'masterPassword.create.action';

    createHTML = (
      <>
        <Box mb="l">
          <PasswordInput
            value={passwordConfirm}
            onChange={onPasswordConfirmChange}
            error={errorConfirm ? errorConfirm.message : null}
            label={intl.formatMessage({ id: 'masterPassword.unlock.passwordConfirmLabel', defaultMessage: 'Confirm Master Password' })}
            placeholder={intl.formatMessage({ id: 'masterPassword.verify.placeholder' })}
          />
        </Box>
        <Box
          id="consentMasterPassword"
          mb="m"
        >
          <Tooltip
            align="left"
            bgOverride="scarlet"
            colorOverride="white"
            visible={!!errorCheckbox}
          >
            {errorCheckbox && errorCheckbox.message ? errorCheckbox.message : null}
          </Tooltip>
          <Box>
            <Checkbox
              onChange={onCheckboxChange}
              checked={checkboxConfirm}
              label={(
                <Text variant="light">
                  <FormattedMessage
                    id="masterPassword.consent.checkbox"
                    defaultMessage="I understand that the Master Password cannot be recovered"
                  />
                </Text>
              )}
            />
          </Box>
        </Box>
      </>
    );
  } else {
    passwordPlaceholderId = 'masterPassword.unlock.placeholder';
    actionLabelId = 'masterPassword.unlock.action';

    if (!isUnregisteredMode) {
      resetAccountHTML = (
        <Box
          textAlign="center"
          mt="a"
        >
          <TextButton
            variant="link"
            onClick={() => SpotlightAPI.openURL(SpotlightAPI.routeHelper('/reset-account'))}
          >
            <u>
              <Text size="h3">
                <FormattedMessage
                  id="masterPassword.account.forgotMasterPassword"
                  defaultMessage="Forgot Master Password?"
                />
              </Text>
            </u>
          </TextButton>
        </Box>
      );
    }
  }

  return (
    <Card
      p="xxl"
      width="360px"
      height="400px"
    >
      <Box display="flex" flexDirection="column" height="100%" id="a-master-password">
        <MasterPasswordKeyIcon />
        <form
          id="form-section"
          onSubmit={onFormSubmit}
        >
          <Box mb="l">
            <PasswordInput
              ref={passwordRef}
              value={password}
              onChange={onPasswordChange}
              error={errorPassword ? errorPassword.message : null}
              label={intl.formatMessage({ id: 'masterPassword.unlock.passwordLabel' })}
              placeholder={intl.formatMessage({ id: passwordPlaceholderId })}
              feedback={passwordScore ? passwordScore.feedback : undefined}
              score={passwordScore ? passwordScore.score : undefined}
            />
          </Box>
          {createHTML}
          <Button
            id="a-unlock-button"
            fullWidth
            loading={loading}
            disabled={!!errorPassword || !!errorConfirm || !!errorCheckbox}
          >
            <FormattedMessage id={actionLabelId} />
          </Button>
        </form>
        <Box mt="l">
          {resetAccountHTML}
        </Box>
      </Box>
    </Card>
  );
};

export default injectIntl(SpotlightMasterPassword);
