import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ServerDate from '@avira-pwm/helpers/ServerDate';

import { RootState } from '../../app/store';
import { setServerTimeOffset as setServerTimeOffsetHelper } from '../helpers';
import { usePrevious } from '../../lib/hooks';
import { setServerTimeOffset } from '../PreferencesActions';

const PreferencesInitializer = ({ children }: React.PropsWithChildren<{}>): JSX.Element => {
  const dispatch = useDispatch();

  const timeOffset = useSelector((state: RootState) => state.preferences.serverTimeOffset);
  const previousOffset = usePrevious(timeOffset);

  useEffect(() => {
    if (
      timeOffset != null
      && ServerDate.getOffset() !== timeOffset
    ) {
      setServerTimeOffsetHelper(timeOffset);
    }

    if (previousOffset === timeOffset) {
      dispatch(setServerTimeOffset(null));
    }
  }, [dispatch, timeOffset, previousOffset]);

  return <>{children}</>;
};

export default PreferencesInitializer;
