import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { bool } from 'prop-types';

import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import Box from 'pwm-components/components/Box';
import Column from 'pwm-components/objects/Column';
import Grid from 'pwm-components/objects/Grid';
import Media from 'pwm-components/objects/Media';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';
import important from '../../../img/important.png';
import important2x from '../../../img/important@2x.png';

const YourMasterPassword = ({ isUnregisteredMode }) => (
  <>
    <Box mb="m">
      <Paragraph mb="xs">
        <FormattedHTMLMessage
          id="dashboard.getStarted.yourMasterPassword.step1"
          defaultMessage="Your master password is used to secure and encrypt all your passwords and content."
        />
        <Paragraph>
          <FormattedHTMLMessage
            id="dashboard.getStarted.yourMasterPassword.step13"
            defaultMessage="As mentioned above, your password should be <strong>long, random, unique</strong> and in addition <strong>easy to remember</strong>."
          />
        </Paragraph>
      </Paragraph>


      <Media
        image={(
          <img
            src={important}
            srcSet={`${important} 1x, ${important2x} 2x`}
            alt="important"
          />
        )}
        body={(
          <strong>
            <Text>
              <FormattedMessage
                id="dashboard.getStarted.yourMasterPassword.step2"
                defaultMessage="Always remember your master password! If you forget it, there is no way to recover your data. Not even Avira can recover your master password."
              />
            </Text>
          </strong>
        )}
      />

      <Media
        image={(
          <img
            src={hint}
            srcSet={`${hint} 1x, ${hint2x} 2x`}
            alt="hint"
          />
        )}
        body={(
          <Text>
            <FormattedHTMLMessage
              id="dashboard.getStarted.yourMasterPassword.step3"
              defaultMessage="Create memorable strong passwords by using the <strong>passphrase</strong> method or the <strong>sentence</strong> method."
            />
          </Text>
        )}
      />

      <Grid
        mb="m"
        align="center"
        size="small"
      >
        <Column size="auto">
          <Paragraph mb="xs">
            <strong>
              <FormattedMessage
                id="dashboard.getStarted.yourMasterPassword.step4"
                defaultMessage="Passphrase method"
              />
            </strong>
          </Paragraph>

          <Paragraph mb="xs">
            <Text>
              <FormattedMessage
                id="dashboard.getStarted.yourMasterPassword.step5"
                defaultMessage="Think about at least 4 random words that you can easily remember. Link them with special characters and make sure to use upper and lower case."
              />
            </Text>
          </Paragraph>
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.yourMasterPassword.step12"
              defaultMessage="Example:"
            />
          </Text>
          <Box display="flex" justifyContent="center" alignItems="center" bgOverride="brightgray" padding="xxl" width={472} height={56} mb={30} mt={2}>
            <Text>
              <FormattedMessage
                id="dashboard.getStarted.yourMasterPassword.passphraseExample"
                defaultMessage="Mountain-Lawn-Yellow-Zebra"
              />
            </Text>
          </Box>
          <Paragraph mb="xs">
            <Text>
              <FormattedHTMLMessage
                id="dashborad.getStarted.yourMasterPassword.step6"
                defaultMessage="<strong>Sentence method</strong>"
              />
            </Text>
          </Paragraph>
          <Paragraph mb="xs">
            <Text>
              <FormattedMessage
                id="dashborad.getStarted.yourMasterPassword.step7"
                defaultMessage="Think about a long sentence that you can easily remember. Use the first character of each word to create your password. Use upper and lower case and combine the letters with numbers and special characters."
              />
            </Text>
          </Paragraph>
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.yourMasterPassword.step12"
              defaultMessage="Example:"
            />
          </Text>
          <Box display="flex" alignItems="center" justifyContent="space-between" bgOverride="brightgray" padding="xxl" width={472} height={56} mb={20} mt={2}>
            <Box flex="1 0 175px" maxWidth="175px">
              <FormattedMessage
                id="dashboard.getStarted.yourMasterPassword.step8"
                defaultMessage="The car is green - it has two doors and four wheels"
              />
            </Box>
            <Box flexGrow={0}>
              &#10132;
            </Box>
            <Box flex="1 0 175px" maxWidth="175px">
              <FormattedMessage
                id="dashboard.getStarted.yourMasterPassword.step10"
                defaultMessage="tCig-ih#2D&fourW"
              />
            </Box>
          </Box>
          {
            isUnregisteredMode
              ? (
                <Media
                  image={(
                    <img
                      src={important}
                      srcSet={`${important} 1x, ${important2x} 2x`}
                      alt="important"
                    />
                  )}
                  body={(
                    <Text>
                      <FormattedMessage
                        id="dashboard.getStarted.yourMasterPasswordUnregistered.message"
                        defaultMessage="You are not registered. It is highly recommended to register. During registration, you will create a Master Password to protect your data. {link}"
                        values={{
                          link: (
                            <Link to="/register">
                              <FormattedMessage
                                id="dashboard.getStarted.yourMasterPasswordUnregistered.register"
                                defaultMessage="Register now"
                              />
                            </Link>
                          ),
                        }}
                      />
                    </Text>
                  )}
                />
              )
              : (
                <Paragraph mb="xs">
                  <FormattedMessage
                    id="dashboard.getStarted.yourMasterPassword.step11"
                    defaultMessage="If you want to change your master password now, {link}"
                    values={{
                      link: (
                        <Link to="/settings/change-master-password">
                          <FormattedMessage
                            id="dashboard.getStarted.yourMasterPassword.step11.link"
                            defaultMessage="click here"
                          />
                        </Link>
                      ),
                    }}
                  />
                </Paragraph>
              )
          }
        </Column>
      </Grid>
    </Box>
  </>
);

YourMasterPassword.propTypes = {
  isUnregisteredMode: bool,
};

YourMasterPassword.defaultProps = {
  isUnregisteredMode: false,
};

export default YourMasterPassword;
