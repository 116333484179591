import React from 'react';
import Video from '../Video';

interface Props {
  onCompleted: () => void;
  language: string;
}

const HowToUseExtension: React.FC<Props> = ({ onCompleted, language }) => {
  const videoId = language === 'de' ? 's0m3-dKizOc' : 'e-5o2bYAcZM';

  return (
    <Video
      videoId={videoId}
      language={language}
      onCompleted={onCompleted}
    />
  );
};

export default HowToUseExtension;
