import React from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import TextButton from 'pwm-components/components/TextButton';

type Props = {
  color: string;
  onClick: (color: string) => void;
  selected?: boolean;
  tabIndex?: number;
}

const ColorBoxWrapper = styled('div') <{ selected?: boolean }>`
  width: 32px;
  height: 32px;
  padding: 7px;
  border-radius: ${({ theme: { space } }) => space.xs};
  ${({ theme: { colors }, selected }) => (selected && `border: solid 2px ${colors.cerulean};`)}
`;

const ColorBox = styled('div') <{ color: string }>`
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  width: 14px;
  height: 14px;
  margin: auto;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`;

const ColorButton: React.SFC<Props> = ({
  color, onClick, selected, tabIndex,
}) => (
  <Box display="inline-block" mr="xs">
    <TextButton tabIndex={tabIndex} onClick={() => onClick(color)}>
      <ColorBoxWrapper selected={selected}>
        <ColorBox color={color} />
      </ColorBoxWrapper>
    </TextButton>
  </Box>
);

ColorButton.defaultProps = {
  selected: false,
  tabIndex: 0,
};

export default ColorButton;
