import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

const TooltipBreachedPassword: React.FC = () => (
  <Box>
    <Paragraph>
      <strong>
        <FormattedMessage id="dashboard.securityStatus.tooltip.header.breachedPassword" />
      </strong>
    </Paragraph>

    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage id="dashboard.securityStatus.hibp.breachedPassword" />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedMessage id="dashboard.securityStatus.tooltip.action.changePassword" />
    </Paragraph>
  </Box>
);

export default TooltipBreachedPassword;
