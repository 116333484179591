import styled from 'pwm-components/styled';
import Text from 'pwm-components/components/Text';

const PasswordText = styled(Text)`
  display: block;
  letter-spacing: 4px;
  &::before {
    content: '••••••••';
  }

  @media screen and (max-width: 960px) {
    &::before {
      content: "•••••";
    }
  }
`;

export default PasswordText;
