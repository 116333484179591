import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import Recaptcha from 'react-recaptcha';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Checkbox from 'pwm-components/components/Checkbox';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';
import Input from 'pwm-components/components/Input';
import PasswordInput from 'pwm-components/components/PasswordInput';

import Auth from './Auth';
import config from '../../config';
import AppleLogin from '../../componentLib/AppleLogin';
import GoogleLogin from '../../componentLib/GoogleLogin';
import FacebookLogin from '../../componentLib/FacebookLogin';
import { oeLocales } from '../../locales';

import checkMark from '../../img/checkmark.png';
import checkMark2x from '../../img/checkmark@2x.png';

const resendTimeout = 30000;

const LOGOUT_CONTEXT = 'enterOtpCode';
const LOGOUT_CAUSE = 'log out';

class Login extends Auth {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.trackOnShown = this.trackOnShown.bind(this);
    this.onResendVerificationCode = this.onResendVerificationCode.bind(this);
    this.showResendVerificationCode = this.showResendVerificationCode.bind(this);
    this.hideResendVerificationCode = this.hideResendVerificationCode.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);

    this.state = {
      ...this.state,
      captchaKey: '',
      codeRequested: false,
      resendingCode: false,
      trustBrowser: false,
    };
  }

  trackOnShown() {
    const { location, onComponentShown } = this.props;
    let source = null;
    if (location) {
      const query = queryString.parse(location.search);
      source = query.dashboardContext;
    }
    onComponentShown('login', source);
  }

  onSubmit(event) {
    event.preventDefault();

    const { onFormSubmit } = this.props;
    this.submitAttempt(onFormSubmit).then((err) => {
      if (err && this.isCaptchaError(err)) {
        this.setState({
          captchaKey: '',
        }, () => {
          if (this.captcha) {
            this.captcha.reset();
          }
        });
      } else if (err && err.message === 'error.login.otpRequired') {
        this.setState({ otpRequired: true });
      }
    });
  }

  updateCaptchaKey(val) {
    this.setState({
      captchaKey: val,
      error: null,
    });
  }

  isCaptchaError(error) {
    return error && error.key === 'error.login.requiredCaptcha';
  }

  isCaptchaRequired() {
    return this.isCaptchaError(this.state.error) || this.state.captchaKey;
  }

  onResendVerificationCode() {
    const { codeRequested, resendingCode } = this.state;

    if (!codeRequested && !resendingCode) {
      this.setState({ resendingCode: true });
      this.showResendVerificationCode();
      this.resendOTP(this.props.onFormSubmit).then(() => {
        this.resendOTPTimeout = setTimeout(() => {
          this.setState({ resendingCode: false });
          this.hideResendVerificationCode();
        }, resendTimeout);
      }).catch((error) => {
        this.setState({ error });
        this.resendOTPTimeout = setTimeout(() => {
          this.setState({ resendingCode: false });
          this.hideResendVerificationCode();
        }, resendTimeout);
      });
    }
  }

  // 2DO: how to clear the password field in login
  onLogoutClick(e) {
    e.stopPropagation();
    const { logoutUser, onLogoutClicked } = this.props;
    logoutUser(LOGOUT_CONTEXT, LOGOUT_CAUSE);
    this.setState({ otpRequired: false });
    onLogoutClicked();
  }

  showResendVerificationCode() {
    this.setState({
      codeRequested: true,
    });
  }

  hideResendVerificationCode() {
    this.setState({
      codeRequested: false,
    });
  }

  componentWillUnmount() {
    clearTimeout(this.resendOTPTimeout);
  }

  onCheckboxChange() {
    const { trustBrowser } = this.state;
    this.setState({ trustBrowser: !trustBrowser });
  }

  // eslint-disable-next-line max-statements, complexity
  render() {
    const oeForgotPasswordUrl = `${config.hostConfig.myAviraHost}/${oeLocales[this.props.language]}/auth/forgot`;
    const { email, onForgotPasswordClicked } = this.props;
    const {
      loading, resendConfirmation, resendingCode, otpRequired,
    } = this.state;

    let error = null;
    let nonFieldError = '';
    let captchaWrapper = '';
    let otpWrapper = '';
    let logout = '';

    if (this.state.error) {
      error = this.authHelper.getError(this.state.error);
    }

    if (error && error.default()) {
      nonFieldError = (
        <p className="u-color-red a-form-error u-mb-m">{error.message}</p>
      );
    }

    let loginWrapper = (
      <Box>
        <Box mb="s">
          <Input
            ref={(el) => { this.focusElement = el; }}
            placeholder={this.authHelper.getMessage('authEmailPlaceholder')}
            value={this.state.email}
            onChange={e => this.setEmailState(e.target.value)}
            error={(error && error.config.email) ? error.message : null}
            id="a-login-email"

          />
        </Box>
        <Box mb="m">
          <PasswordInput
            placeholder={this.authHelper.getMessage('authPasswordPlaceholder')}
            value={this.state.password}
            onChange={e => this.setState({ password: e.target.value, error: null })}
            error={(error && error.config.password) ? error.message : null}
            autoComplete="off"
            id="a-login-password"

          />
        </Box>
      </Box>
    );

    let additionalOptions = (
      <Box
        textAlign="right"
        my="l"
      >
        <a
          href={oeForgotPasswordUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={onForgotPasswordClicked}
          id="a-forgotPassword-action"
        >
          <FormattedMessage
            id="login.forgotPassword"
            defaultMessage="Forgot Password?"
          />
        </a>
      </Box>
    );

    let socialMediaLogin = config.allowSocialMediaLogin ? (
      <div>
        <Box
          my="m"
        >
          <Text variant="dark">
            <FormattedMessage id="auth.registerOrLogin.or" />
          </Text>
        </Box>
        <Box
          display="flex"
          height="49px"
          justifyContent="space-between"
        >
          <AppleLogin
            clientId={this.appleClientId}
            onClick={() => this.setState({ loading: true })}
            onSuccess={this.authenticateWithSocialMedia}
            onError={this.handleSocialMediaAuthenticationError}
            loading={loading}
          />
          <FacebookLogin
            clientId={this.facebookAppId}
            onClick={() => this.setState({ loading: true })}
            onSuccess={this.authenticateWithSocialMedia}
            onError={this.handleSocialMediaAuthenticationError}
            loading={loading}
            locale={this.props.language}
          />
          <GoogleLogin
            clientId={this.googleClientId}
            onClick={() => this.setState({ loading: true })}
            onSuccess={this.authenticateWithSocialMedia}
            onError={this.handleSocialMediaAuthenticationError}
            loading={loading}
          />
        </Box>
      </div>
    ) : '';

    if (this.isCaptchaRequired()) {
      captchaWrapper = (
        <div className="c-captcha u-mb-m">
          <div className="c-captcha__wrapper">
            <Recaptcha
              ref={(captcha) => { this.captcha = captcha; }}
              sitekey={config.captcha.key}
              render="explicit"
              verifyCallback={response => this.updateCaptchaKey(response)}
              expiredCallback={() => this.updateCaptchaKey('')}
            />
          </div>
        </div>
      );
    }

    if (otpRequired) {
      loginWrapper = '';
      socialMediaLogin = '';

      otpWrapper = (
        <Box mb="m">
          <Input
            placeholder="Code"
            value={this.state.otp}
            onChange={e => this.setState({ otp: e.target.value, error: null })}
            error={error && error.config.password ? error.message : null}
            // focusOnMount
          />
        </Box>
      );

      additionalOptions = (
        <Box my="l">
          <div className="u-txt-pica">
            <Checkbox
              name="trust-browser"
              label={(
                <Text variant="dark">
                  <FormattedMessage
                    id="login.rememberBrowser"
                  />
                </Text>
              )}
              onChange={this.onCheckboxChange}
              checked={this.state.trustBrowser}
            />
          </div>
        </Box>
      );

      logout = (
        <Box
          textAlign="center"
          mt="a"
          mb="xxl"
        >
          <Paragraph size="small" variant="dark">
            <FormattedMessage
              id="login.logout"
              values={{ email }}
              defaultMessage="Registered as {email}"
            />
          </Paragraph>
          <TextButton
            variant="link"
            focusable
            onClick={this.onLogoutClick}
          >
            <Text size="small">
              <FormattedMessage
                id="verifyEmail.logout"
                defaultMessage="Log out"
              />
            </Text>
          </TextButton>
        </Box>
      );
    }

    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box
          height="80px"
          display="flex"
          alignItems="center"
        >
          {
            otpRequired
              ? (
                <strong>
                  <Paragraph variant="dark">
                    <FormattedMessage
                      id="login.otpChallengeHeader"
                      defaultMessage="Enter verification code"
                    />
                  </Paragraph>
                </strong>
              ) : (
                <Paragraph variant="dark">
                  <FormattedMessage
                    id="login.challenge"
                  />
                </Paragraph>
              )
          }
        </Box>

        {
          otpRequired
          && (
            <Paragraph
              variant="dark"
              mb="m"
            >
              <FormattedMessage
                id="login.otpChallenge"
              />
              {
                this.state.aviraPhone
                && (
                  <strong>
                    <Text>
                      ******
                      {this.state.aviraPhone}
                    </Text>
                  </strong>
                )
              }
            </Paragraph>
          )
        }

        <form
          id="form-section"
          onSubmit={this.onSubmit}
        >
          {loginWrapper}
          {otpWrapper}
          {captchaWrapper}
          {nonFieldError}
          {additionalOptions}
          <Box>
            <Box>
              <Button
                disabled={
                  !!(this.state.error || (this.isCaptchaRequired() && !this.state.captchaKey))
                }
                loading={loading}
                variant="login"
                style={{ width: '100%' }}
                id="a-login-action"
              >
                <FormattedMessage
                  id="login.action"
                  defaultMessage="Log in"
                />
              </Button>
            </Box>
            {
              otpRequired && (
                resendConfirmation || resendingCode ? (
                  <Box
                    height="44px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mt="m"
                  >
                    <img
                      src={checkMark}
                      srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
                      alt="Check mark"
                    />
                    <Text
                      variant="dark"
                      ml="s"
                      alignItems="center"
                    >
                      <FormattedMessage
                        id="login.verificationCodeResent"
                        defaultMessage="The code has been resent"
                      />
                    </Text>
                  </Box>
                ) : (
                  <Box
                    mt="m"
                  >
                    <Button
                      loading={loading}
                      onClick={this.onResendVerificationCode}
                      variant="ghost"
                      style={{ width: '100%' }}
                    >
                      <FormattedMessage
                        id="login.resendVerificationCode"
                        defaultMessage="Resend verfication code"
                      />
                    </Button>
                  </Box>
                )
              )
            }
          </Box>
        </form>
        {socialMediaLogin}
        {logout}
      </Box>
    );
  }
}

Login.propTypes = {
  ...Auth.propTypes,
  logoutUser: PropTypes.func.isRequired,
  onLogoutClicked: PropTypes.func.isRequired,
  onForgotPasswordClicked: PropTypes.func.isRequired,
};

export default withRouter(injectIntl(Login));
