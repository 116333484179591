import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';

import AuthPageList from './AuthPageList';

const advantageListItemsRegister = [1, 2, 3, 4].map(number => `dashboard.register.advantage${number}`);

export default () => (
  <>
    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.register.secure"
            defaultMessage="Secure"
          />
        </Paragraph>
      </strong>
      <Text variant="light">
        <FormattedMessage
          id="dashboard.register.secureMsg"
          defaultMessage="Store your passwords in a secure place"
        />
      </Text>
    </Box>

    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.register.easy"
            defaultMessage="Easy"
          />
        </Paragraph>
      </strong>
      <Text variant="light">
        <FormattedMessage
          id="dashboard.register.easyMsg"
          defaultMessage="Autofill your passwords in browsers and on your mobile devices"
        />
      </Text>
    </Box>

    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.register.convenient"
            defaultMessage="Convenient"
          />
        </Paragraph>
      </strong>
      <Text variant="light">
        <FormattedMessage
          id="dashboard.register.convenientMsg"
          defaultMessage="Have your passwords at hand anytime when you need them"
        />
      </Text>
    </Box>

    <Box
      mb="m"
    >
      <strong>
        <Paragraph
          variant="light"
          mb="xs"
        >
          <FormattedMessage
            id="dashboard.login.advantages"
            defaultMessage="Use all advantages and keep safe from hackers"
          />
        </Paragraph>
      </strong>
      <AuthPageList>
        {
          advantageListItemsRegister.map(item => (
            <li key={item}>
              <Text variant="light">
                <FormattedMessage
                  id={item}
                />
              </Text>
            </li>
          ))
        }
      </AuthPageList>
    </Box>
  </>
);
