import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import QRCode from 'qrcode.react';
import Card from '../../componentLib/CardWrapper';

import PWMLogo from '../../img/icon-logo-outline.png';

type Props = {
  url: string;
}

const MobilePage: React.FC<Props> = ({ url }) => (
  <Box
    mt="xxxl"
    display="flex"
    flexDirection="column"
  >
    <Text size="h1" mb="20px">
      <FormattedMessage id="dashboard.mobile.page.title" defaultMessage="Install the Password Manager app" />
    </Text>
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      height="50px"
      mb="32px"
    >
      <Text size="large">
        <FormattedMessage id="dashboard.mobile.page.text1" defaultMessage="Have all your passwords always with you" />
      </Text>
      <Text size="large">
        <FormattedMessage id="dashboard.mobile.page.text2" defaultMessage="Add extra security for with the Authenticator" />
      </Text>
    </Box>
    <Card>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100"
        mt="40px"
        ml="30%"
        mr="30%"
        mb="60px"
        textAlign="center"
      >
        <Text size="large">
          <strong>
            <FormattedMessage id="dashboard.mobile.page.textqr" defaultMessage="Point your smartphone camera to the code to download & install" />
          </strong>
        </Text>
        <br />
        <QRCode
          value={url}
          level="M"
          size={150}
          imageSettings={{
            src: PWMLogo,
            height: 50,
            width: 50,
          }}
        />
      </Box>
    </Card>

  </Box>
);

export default MobilePage;
