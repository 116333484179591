/* eslint class-methods-use-this: 0 */

import { validateEmail, validatePassword, isPasswordStrong } from './AuthenticationValidator';
import AuthenticationMessages from './AuthenticationMessages';
import AuthErrorConfig from './AuthErrorConfig';
import envConfig from '../config';
import stringFormatter from './StringFormatter';

class AuthenticationHelper {
  constructor(intl) {
    this.intl = intl;
    this.messages = AuthenticationMessages;
  }

  isValid(email, password) {
    return validateEmail(email) && validatePassword(password);
  }

  isValidEmail(email) {
    return validateEmail(email);
  }

  isValidPassword(password) {
    return validatePassword(password);
  }

  isPasswordStrong(password) {
    return isPasswordStrong(password);
  }

  getMessage(key, values) {
    return this.intl.formatMessage(this.messages[key], values);
  }

  getError(errorState) {
    let { key } = errorState;
    let config = AuthErrorConfig[key];
    if (!config) {
      key = 'error.unknown';
      config = AuthErrorConfig[key];
    }

    return {
      message: this.getMessage(key, {
        supportUrl: stringFormatter(envConfig.connect.support, this.intl.locale),
      }),
      config,
      default() { return !this.config.password && !this.config.email; },
    };
  }

  prepareList(feedback) {
    return feedback
      ? feedback.map((item) => {
        if (this.messages[item]) {
          return this.getMessage(item);
        }
        return item;
      })
      : [];
  }
}

export default AuthenticationHelper;

export const getEmailErrorCode = (email) => {
  if (email === null || email.length === 0) {
    return 'no_email';
  }

  return 'invalid_email';
};

export const getPasswordErrorCode = (password, isBreached = false) => {
  if (password === null || password.length === 0) {
    return 'no_password';
  }

  if (isBreached) return 'masterPassword.error.breached';

  return 'invalid_password';
};

export const getMasterPasswordErrorCode = (
  usageAs, password, isBreached = false, consent = true,
  // eslint-disable-next-line max-params
) => {
  if (password == null || password.length === 0) {
    return `masterPassword.error.${usageAs}.empty`;
  } if (usageAs === 'create' && !validatePassword(password)) {
    return 'Password is not strong enough';
  } if (isBreached) {
    return 'masterPassword.error.breached';
  } if (!validatePassword(password)) {
    return `masterPassword.error.${usageAs}.invalid`;
  } if (consent === false) {
    return 'masterPassword.error.noConsent';
  }
  return 'masterPassword.error.verify.wrong';
};

export const getVerifyMasterPasswordErrorCode = (password) => {
  if (password == null || password.length === 0) {
    return 'masterPassword.error.verify.empty';
  }
  return 'masterPassword.error.verify.notIdentical';
};
