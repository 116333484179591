import { math } from 'polished';

import styled, { css } from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

type Props = {
  fullWidth?: boolean;
};

const StyledDashboardWrapper = styled(Box)<Props>`
  ${({ fullWidth, theme: { space } }) => !fullWidth && css`
    min-width: ${math(`540px + ${space.xxs} * 2 + ${space.m} * 2`)};
    max-width: ${math(`1050px + ${space.xxs} * 2 + ${space.m} * 2`)};
    padding: 0 ${space.m};
    margin: 0 auto;
  `}
`;

export default StyledDashboardWrapper;
