import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import Loading from '../../app/components/Loading';
import { setS3FileAuthData, setSyncData } from '../DashboardActions';
import { RootState } from '../../app/store';

const AWSWrapper: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const { syncData: aws, s3FileAuthData: s3 } = useSelector<RootState, RootState['dashboard']>(({ dashboard }) => dashboard);
  const isUnregisteredMode = useSelector<RootState, boolean>(({ dashboard }) => (
    dashboard.isUnregisteredMode
  ));

  useEffect(() => {
    if (isUnregisteredMode) {
      return;
    }

    if (aws == null) {
      dispatch(setSyncData());
    }

    if (s3 == null) {
      dispatch(setS3FileAuthData());
    }
  }, [dispatch, aws, s3, isUnregisteredMode]);

  return (
    <>
      {(aws && s3) || isUnregisteredMode ? (
        children
      ) : (
        <Loading
          onRetry={() => window.location.reload()}
          timeout={20}
        >
          <FormattedMessage id="awsWrapper.loading" defaultMessage="Initializing web services..." />
        </Loading>
      )}
    </>
  );
};

export default AWSWrapper;
