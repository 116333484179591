import VaultState from '@nlok/vault-sdk/source/VTVaultState';
import { Action } from 'redux';

export const NLOK_SET_MACHINE_GUID = 'NLOK_SET_MACHINE_GUID';

export interface NLOKSetMachineGuid {
  type: typeof NLOK_SET_MACHINE_GUID;
  value: string;
}

export const NLOK_SET_STATE = 'NLOK_SET_STATE';

export interface NLOKSetState extends Action<typeof NLOK_SET_STATE> {
  value: VaultState | null;
}

export const NLOK_SET_KEYS = 'NLOK_SET_KEYS';

export interface NLOKSetKeys {
  type: typeof NLOK_SET_KEYS;
  value: {
    encryptionKey: string | null;
    challengeKey: string | null;
  };
}

export const NLOK_CLEAR_KEYS = 'NLOK_CLEAR_KEYS';

export interface NLOKClearKeys {
  type: typeof NLOK_CLEAR_KEYS;
}

export const NLOK_SET_BYPASS_VAULT = 'NLOK_SET_BYPASS_VAULT';

export interface NLOKSetBypassVault {
  type: typeof NLOK_SET_BYPASS_VAULT;
  value: string | null;
}

export const NLOK_INCREASE_MIGRATION_ATTEMPTS = 'NLOK_INCREASE_MIGRATION_ATTEMPTS';

export interface NLOKIncreaseMigrationAttempts {
  type: typeof NLOK_INCREASE_MIGRATION_ATTEMPTS;
}

export const NLOK_CLEAR_MIGRATION_ATTEMPTS = 'NLOK_CLEAR_MIGRATION_ATTEMPTS';

export interface NLOKClearMigrationAttempts {
  type: typeof NLOK_CLEAR_MIGRATION_ATTEMPTS;
}
