import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

class Checkbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: `${props.name}-${Date.now()}`,
      checked: props.checked,
    };

    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ checked: nextProps.checked });
  }

  onChange(e) {
    const { name, checked } = e.target;
    this.setState({
      checked,
    }, () => this.props.onChange(name, checked));
  }

  render() {
    const {
      label, labelId, name, disabled, className, inputClassName,
    } = this.props;
    const { checked } = this.state;

    return (
      <label
        htmlFor={this.state.id}
        className={`c-checkbox ${this.props.white ? 'c-checkbox--white' : ''} ${className}`}
      >
        <input
          id={this.state.id}
          name={name}
          className={`c-checkbox__input ${inputClassName}`}
          type="checkbox"
          onChange={this.onChange}
          checked={checked}
          disabled={disabled}
        />

        <div className={`c-checkbox__check ${!(label || labelId) ? 'c-checkbox__check--no-margin' : ''}`} />

        {
          labelId
            ? <FormattedHTMLMessage id={labelId} />
            : label
        }
      </label>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  labelId: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  white: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: null,
  className: '',
  disabled: false,
  inputClassName: '',
  label: '',
  labelId: null,
  name: '',
  onChange: () => {},
  white: false,
};

export default Checkbox;
