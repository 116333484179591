import React, { useState } from 'react';
import Box from 'pwm-components/components/Box';

interface Props {
  gifSrc: string;
  gifSrc2x: string;
  gifAlt: string;
}

const GifContainer: React.FC<Props> = ({
  gifSrc,
  gifSrc2x,
  gifAlt,
}) => {
  const [timestamp, setTimestamp] = useState(Date.now());

  return (
    <Box
      onClick={() => setTimestamp(Date.now())} // Restarts the animation
      style={{ cursor: 'pointer' }}
    >
      <img
        src={`${gifSrc}?t=${timestamp}`}
        srcSet={`${gifSrc} 1x, ${gifSrc2x} 2x`}
        alt={gifAlt}
      />
    </Box>
  );
};

export default GifContainer;
