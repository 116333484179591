import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Input from 'pwm-components/components/Input';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import styled from 'pwm-components/styled';

const SuggestionListItem = styled('li')`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  text-align: left;
  padding: ${({ theme: { space } }) => `${space.xs} ${space.s}`};
  background-color: ${({ theme: { colors }, selected }) => selected && colors.alto};
  color: ${({ theme: { colors } }) => colors.martinique};

  &: not(: last-child) {
    border-bottom: solid 1px ${({ theme: { colors } }) => colors.alabaster};
  }
`;

const SuggestionList = styled('ul')`
  overflow: auto;
  border-radius: ${({ theme: { space } }) => space.xxs};
  border: 1px solid ${({ theme: { colors } }) => colors.gray};
  background: ${({ theme: { colors } }) => colors.white};
`;

const SuggestionBox = styled('div')`
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 10;
  overflow: hidden;
  border-radius: ${({ theme: { space } }) => space.xxs};
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.2);
  max-height: ${({ collapsed, maxHeight }) => (collapsed ? 0 : `${maxHeight}px`)};
  transition: max-height ${({ theme: { transition }, collapsed }) => (collapsed ? 0 : transition.duration.long)};
`;

class AutoSuggestionField extends React.Component {
  static propTypes = {
    onRef: PropTypes.func,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onEscape: PropTypes.func,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    value: PropTypes.string,
    suggestionList: PropTypes.array,
    ignoreList: PropTypes.array,
    maxHeight: PropTypes.number,
    focusOnMount: PropTypes.bool,
    id: PropTypes.string,
  };

  static defaultProps = {
    onRef: null,
    onBlur: null,
    onChange: null,
    onSubmit: null,
    onEscape: null,
    onFocus: null,
    onSelect: null,
    suggestionList: [],
    value: null,
    maxHeight: 200,
    focusOnMount: false,
    ignoreList: [],
    id: '',
  };

  constructor(props) {
    super(props);

    const { suggestionList } = props;

    this.state = {
      collapsed: true,
      suggestionList,
      suggestionIndex: -1,
      activeSuggestionList: suggestionList,
      suggestionClicked: false,
      focus: false,
    };

    this.inputRef = React.createRef();
    this.listItems = [];
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onMouseDown = this.onMouseDown.bind(this);
    this.getActiveSuggestionList = this.getActiveSuggestionList.bind(this);
    this.showHideSuggestionList = this.showHideSuggestionList.bind(this);
    this.setScrollPosition = this.setScrollPosition.bind(this);
    this.focus = this.focus.bind(this);
  }

  componentDidMount() {
    const { focusOnMount, onRef } = this.props;

    if (onRef) {
      onRef(this.inputRef);
    }

    if (focusOnMount) {
      this.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { suggestionList } = this.props;
    if (!isEqual(nextProps.suggestionList, suggestionList)) {
      const activeSuggestionList = this.getActiveSuggestionList((nextProps.value || '').trim(),
        nextProps.suggestionList);
      this.setState({
        suggestionList: nextProps.suggestionList,
        activeSuggestionList,
        suggestionIndex: -1,
      });
    }
  }

  onFocus() {
    const { suggestionClicked } = this.state;
    this.setState({ focus: true }, () => {
      if (!suggestionClicked) {
        this.showHideSuggestionList();
      }
      this.setState({ suggestionClicked: false });
    });

    const { onFocus } = this.props;
    if (onFocus) {
      onFocus();
    }
  }

  onBlur() {
    const { onBlur } = this.props;
    const { suggestionClicked, suggestionList } = this.state;
    if (suggestionClicked) {
      if (onBlur) {
        onBlur();
      }
      return;
    }

    this.setState({
      focus: false,
      activeSuggestionList: suggestionList, // to reset the suggestion list
    }, () => {
      this.showHideSuggestionList();
      if (onBlur) {
        onBlur();
      }
    });
  }

  onSubmit(submittedValue) {
    const { onSubmit, value } = this.props;
    if (onSubmit) {
      const trimmedValue = (submittedValue || value).trim();
      const activeSuggestionList = this.getActiveSuggestionList(trimmedValue);
      // add an already-existing tag if match case-insensitively
      if (activeSuggestionList.length === 1
        && activeSuggestionList[0].toLowerCase() === trimmedValue.toLowerCase()) {
        onSubmit(activeSuggestionList[0]);
      } else {
        onSubmit(trimmedValue);
      }
    }
  }

  // eslint-disable-next-line max-statements, complexity
  onKeyDown(e) {
    const { onEscape } = this.props;
    const { collapsed, activeSuggestionList } = this.state;
    const minIndex = 0;
    const maxIndex = activeSuggestionList.length - 1;
    const { suggestionIndex } = this.state;

    switch (e.keyCode) {
      case 38: { // Up key
        if (!collapsed) {
          let nextIndex = suggestionIndex - 1;
          if (nextIndex < minIndex) {
            nextIndex = maxIndex;
          }
          this.setScrollPosition(nextIndex);
          this.setState({ suggestionIndex: nextIndex });
        }
        break;
      }
      case 40: { // Down key
        if (!collapsed) {
          let nextIndex = suggestionIndex + 1;
          if (nextIndex > maxIndex) {
            nextIndex = minIndex;
          }
          this.setScrollPosition(nextIndex);
          this.setState({ suggestionIndex: nextIndex });
        } else {
          this.setState({ collapsed: false });
        }
        break;
      }
      case 13: { // enter
        if (!collapsed && suggestionIndex !== -1) {
          this.onSuggestionClick();
        } else {
          const { value } = this.props;
          this.onSubmit(value);
        }
        break;
      }
      case 27: { // esc
        if (!collapsed) {
          this.setState({ suggestionIndex: -1, collapsed: true });
        }
        if (onEscape) {
          onEscape(collapsed);
        }
        break;
      }
      default:
        break;
    }
  }

  onMouseEnter(index) {
    this.setState({ suggestionIndex: index });
  }

  onMouseLeave() {
    this.setState({ suggestionIndex: -1 });
  }

  onMouseDown() {
    const { suggestionIndex } = this.state;
    if (suggestionIndex !== -1) {
      this.setState({ suggestionClicked: true });
      this.onSuggestionClick();
    }
  }

  // should only be called when suggestion is clicked i.e. suggestion index !== -1.
  onSuggestionClick() {
    const { suggestionIndex, activeSuggestionList } = this.state;
    const { onSelect } = this.props;
    const suggestedValue = activeSuggestionList[suggestionIndex];

    this.setState({ suggestionIndex: -1, collapsed: true }, () => {
      onSelect(suggestedValue);
      this.onSubmit(suggestedValue);
    });
  }

  onChange(e) {
    const { target: { value } } = e;
    const trimmedValue = (value || '').trim();
    const activeSuggestionList = this.getActiveSuggestionList(trimmedValue);
    this.setState({ activeSuggestionList, suggestionIndex: -1 }, () => {
      this.showHideSuggestionList();
    });

    const { onChange } = this.props;
    if (onChange) {
      onChange(e);
    }
  }

  getActiveSuggestionList(filterVal, suggestionList) {
    const { suggestionList: currentList } = this.state;
    let activeSuggestionList = suggestionList || currentList;
    if (activeSuggestionList && activeSuggestionList.length > 0) {
      activeSuggestionList = activeSuggestionList.filter(
        suggestion => suggestion.toLowerCase().indexOf(filterVal.toLowerCase()) === 0,
      );
    }
    return activeSuggestionList;
  }

  setScrollPosition(index) {
    const { maxHeight } = this.props;
    const currentItem = this.listItems[index];
    const scrollTop = (currentItem.offsetTop + currentItem.offsetHeight) - maxHeight;
    if (scrollTop < 0) {
      this.listContainer.scrollTop = 0;
    } else if (this.listContainer.scrollTop < scrollTop) {
      this.listContainer.scrollTop = scrollTop;
    }
  }

  showHideSuggestionList() {
    const { focus, activeSuggestionList } = this.state;
    if (focus && activeSuggestionList.length > 0) {
      this.setState({ collapsed: false });
    } else {
      this.setState({ collapsed: true });
    }
  }

  focus() {
    if (this.inputRef && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const {
      maxHeight,
      onEscape,
      onSubmit,
      onSelect,
      onRef,
      id,
      ...props
    } = this.props;
    const {
      collapsed,
      activeSuggestionList,
      suggestionIndex,
    } = this.state;

    return (
      <Box position="relative" width="100%">
        <Input
          {...props}
          id={id}
          ref={this.inputRef}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onSubmit={this.onSubmit}
          onKeyDown={this.onKeyDown}
          onChange={this.onChange}
        />
        <SuggestionBox
          collapsed={collapsed}
          maxHeight={maxHeight}
        >
          <SuggestionList
            id='a-suggestion-list'
            style={{ maxHeight }}
            ref={(listContainer) => { this.listContainer = listContainer; }}
          >
            {activeSuggestionList.map((suggestion, index) => (
              <SuggestionListItem
                tabIndex="-1"
                key={suggestion}
                selected={suggestionIndex === index}
                ref={(item) => { this.listItems[index] = item; }}
                onMouseEnter={() => this.onMouseEnter(index)}
                onMouseLeave={this.onMouseLeave}
                onMouseDown={this.onMouseDown}
              >
                <Text variant="dark">
                  {suggestion}
                </Text>
              </SuggestionListItem>
            ))}
          </SuggestionList>
        </SuggestionBox>
      </Box>
    );
  }
}

export default AutoSuggestionField;
