import React from 'react';
import PropTypes from 'prop-types';

class Select extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
    };

    this.onChange = this.onChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value !== this.props.value) {
      this.setState({ value: nextProps.value });
    }
  }

  onChange(e) {
    e.stopPropagation();
    this.setState({ value: e.target.value });
    this.props.onChange(e.target.value);
  }

  render() {
    const optionsList = Object.keys(this.props.options)
      .map(option => <option key={option} value={option}>{this.props.options[option]}</option>);

    return (
      <div className={`c-select ${this.props.disabled ? 'c-select--disabled' : ''}`}>
        <select
          className={this.props.className}
          name={this.props.name}
          onChange={this.onChange}
          value={this.state.value}
          disabled={this.props.disabled}
        >
          {this.props.withEmpty && <option>-</option>}
          {optionsList}
        </select>
      </div>
    );
  }
}

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onChange: PropTypes.func,
  options: PropTypes.shape({}).isRequired,
  value: PropTypes.string,
  withEmpty: PropTypes.bool,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  className: '',
  name: '',
  onChange: () => {},
  value: '',
  withEmpty: false,
  disabled: false,
};

export default Select;
