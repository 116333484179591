import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import debug from '../../debug';

import * as SpotlightAPI from '../../lib/SpotlightAPI';
import { State as TrackingState } from '../../tracking/TrackingReducer';
import Loading from '../../app/components/Loading';
import { State as OEState } from '../../oe/OEReducer';

const log = debug.extend('SpotlightLogin');

type StateProps = {
  oe: OEState;
};

const SpotlightLogin: React.FC = () => {
  const onboardingCompatible = SpotlightAPI.getSpotlightUserOnboardingStatus.isAvailable();

  const [showOnboarding, setShowOnboarding] = useState(false);
  const [loading, setLoading] = useState(true);
  const oeId = useSelector<StateProps, string | null>(state => state.oe.id);
  const isUserLoggedIn = !!oeId;
  const distinctId = useSelector(
    ({ tracking }: { tracking: TrackingState }) => tracking.distinctId,
  );

  useEffect(() => {
    if (SpotlightAPI.setPasswordManagerDistinctId.isAvailable() && distinctId) {
      // send the dashboard distinct id to spotlight for mixpanel tracking
      log('Setting PWM distinct id');
      SpotlightAPI.setPasswordManagerDistinctId(distinctId);
    }
  }, [distinctId]);

  useEffect(() => {
    if (oeId && onboardingCompatible) {
      log('getting onboarding status');
      SpotlightAPI.getSpotlightUserOnboardingStatus(oeId.toString(), (_err, data) => {
        log('onboarding status %O', data);
        if (data != null) {
          if (!data.activated) {
            setShowOnboarding(true);
          } else if (data.activated && !data.connected) {
            SpotlightAPI.showBrowserExtensions(oeId);
          }
        }

        setLoading(false);
      });
    } else {
      // this should not happen.
      setLoading(false);
    }
  }, [oeId, setShowOnboarding, setLoading, onboardingCompatible]);

  if (loading) {
    return (
      <Loading />
    );
  }

  let redirect = '/enter-master-password';

  if (onboardingCompatible) {
    if (showOnboarding || !isUserLoggedIn) {
      redirect = '/spotlight-onboarding';
    }
  } else if (!isUserLoggedIn) {
    redirect = '/spotlight-unregistered';
  }

  return (
    <Redirect to={{ pathname: redirect }} />
  );
};

export default SpotlightLogin;
