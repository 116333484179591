import React from 'react';
import { func, bool, string } from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import ToggleSwitch from 'pwm-components/components/ToggleSwitch';
import Box from 'pwm-components/components/Box';

import Card from '../../componentLib/CardWrapper';

class BreachChecks extends React.Component {
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);

    this.getProRef = React.createRef();
  }

  componentDidMount() {
    const { onGetProClick } = this.props;
    const textElement = this.getProRef.current;
    if (onGetProClick && textElement) {
      textElement.querySelector('a').addEventListener('click', (e) => {
        e.preventDefault();
        onGetProClick();
      });
    }
  }

  onToggle(e) {
    const { onToggle } = this.props;
    if (onToggle) {
      onToggle(e.target.checked);
    }
  }

  render() {
    const { allowBreachCheck, isPro, link } = this.props;

    return (
      <Card
        title={(
          <FormattedMessage id="dashboard.settings.breachChecks" />
        )}
        mb="l"
      >
        <Paragraph>
          <FormattedHTMLMessage
            id="dashboard.settings.breachChecks.whenOn"
            defaultMessage="When ON, your user names will be checked for breaches using the service <a target='_blank' href='https://haveibeenpwned.com/'>haveibeenpwned.com</a>"
          />
        </Paragraph>
        <Box mt="l" display="flex" alignItems="flex-end">
          <Text mr="m">
            <FormattedMessage id="dashboard.settings.breachChecks" />
          </Text>
          <Box
            mr="s"
          >
            <ToggleSwitch
              id="breachCheck"
              checked={!!allowBreachCheck}
              disabled={!isPro}
              onChange={this.onToggle}
            />
          </Box>
          {
            isPro
              ? (
                <Text size="h4">
                  <strong>
                    <FormattedMessage id={allowBreachCheck ? 'dashboard.settings.breachChecks.on' : 'dashboard.settings.breachChecks.off'} />
                  </strong>
                </Text>
              )
              : (
                <Text variant="alert" ml="s" ref={this.getProRef}>
                  <FormattedHTMLMessage
                    id="dashboard.settings.breachChecks.getPro"
                    values={{
                      link,
                    }}
                  />
                </Text>
              )
          }
        </Box>
      </Card>
    );
  }
}

BreachChecks.propTypes = {
  allowBreachCheck: bool,
  isPro: bool,
  link: string,
  onToggle: func,
  onGetProClick: func,
};

BreachChecks.defaultProps = {
  allowBreachCheck: false,
  isPro: false,
  link: '',
  onToggle: null,
  onGetProClick: null,
};

export default BreachChecks;
