import React from 'react';
import { withRouter } from 'react-router-dom';
import { bool, func, object } from 'prop-types';
import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';

const HowToImportPasswords = ({ onCompleted, completed, history }) => (
  <Box>
    <Paragraph mb="l">
      <FormattedMessage
        id="dashboard.getStarted.howToImport.detail"
        defaultMessage="Import your passwords easily from your browser or another password manager"
      />
    </Paragraph>
    <Box>
      <Box
        display="inline-block"
      >
        <Button
          onClick={() => {
            onCompleted();
            history.push('/get-started/import?context=get-started');
          }}
        >
          <FormattedMessage
            id="dashboard.getStarted.howToImport.importPasswords"
            defaultMessage="Import passwords"
          />
        </Button>
      </Box>
      {!completed && (
        <Box
          display="inline-block"
          ml="xs"
        >
          <Button
            variant="secondary"
            onClick={() => onCompleted()}
          >
            <FormattedMessage
              id="dashboard.getStarted.howToImport.nothingToImport"
              defaultMessage="Nothing to import"
            />
          </Button>
        </Box>
      )}
    </Box>
  </Box>
);

HowToImportPasswords.propTypes = {
  onCompleted: func.isRequired,
  completed: bool.isRequired,
  history: object.isRequired,
};

export default withRouter(HowToImportPasswords);
