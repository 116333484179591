import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';

import VerifyEmailList from './VerifyEmailList';

import checkMark from '../../img/checkmark.png';
import checkMark2x from '../../img/checkmark@2x.png';

import sendEmail from '../../img/sendEmail.png';
import sendEmail2x from '../../img/sendEmail@2x.png';

const tooltipTimeout = 3000;
const resendTimeout = 30000;

const LOGOUT_CONTEXT = 'verifyEmail';
const LOGOUT_CAUSE = 'change user';

const verifyAccount = [1, 2, 3].map(number => `dashboard.verifyEmail.message${number}`);

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      resendingEmail: false,
      resendConfirmation: false,
      error: {},
    };

    this.onResendVerificationEmail = this.onResendVerificationEmail.bind(this);
    this.onCheckForVerification = this.onCheckForVerification.bind(this);
    this.onChangeClick = this.onChangeClick.bind(this);
    this.showResendConfirmation = this.showResendConfirmation.bind(this);
    this.hideResendConfirmation = this.hideResendConfirmation.bind(this);
    this.removeError = this.removeError.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.resendConfirmationTimeout);
  }

  onResendVerificationEmail() {
    const { resendConfirmation, resendingEmail } = this.state;
    const { resendVerificationEmail } = this.props;
    if (!resendConfirmation && !resendingEmail) {
      this.setState({ resendingEmail: true });
      this.showResendConfirmation();
      resendVerificationEmail().then(() => {
        this.setState({ resendingEmail: false });
        this.resendConfirmationTimeout = setTimeout(() => {
          this.hideResendConfirmation();
        }, resendTimeout);
      }).catch(() => {
        this.setState({ resendingEmail: false });
        this.resendConfirmationTimeout = setTimeout(() => {
          this.hideResendConfirmation();
        }, resendTimeout);
      });
    }
  }

  async onCheckForVerification() {
    const {
      getOEUserData, history, trackVerified, trackVerifiedFailed,
    } = this.props;

    this.setState({ loading: true });

    const userData = await getOEUserData();

    if (userData.confirm) {
      trackVerified();
      history.push('/create-master-password');
    } else {
      trackVerifiedFailed();
      this.setState({
        loading: false,
        error: {
          emailNotVerified: true,
        },
      });
      setTimeout(() => {
        this.removeError();
      }, tooltipTimeout);
    }
  }

  onChangeClick(e) {
    e.stopPropagation();
    const { changeUser } = this.props;
    changeUser(LOGOUT_CONTEXT, LOGOUT_CAUSE);
  }

  showResendConfirmation() {
    this.setState({
      resendConfirmation: true,
    });
  }

  hideResendConfirmation() {
    this.setState({
      resendConfirmation: false,
    });
  }

  removeError() {
    this.setState({
      error: {},
    });
  }

  render() {
    const { oe, isUnregisteredMode } = this.props;

    const {
      loading, error, resendConfirmation, resendingEmail,
    } = this.state;

    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
      >
        <Box
          height="80px"
          display="flex"
          alignItems="center"
        >
          <Text variant="dark">
            <strong>
              <FormattedMessage
                id="verifyEmail.header"
                defaultMessage="Verify your Avira Account"
              />
            </strong>
          </Text>
        </Box>

        <Box
          mb="xl"
        >
          <VerifyEmailList variant="dark">
            {
              verifyAccount.map(item => (
                <li key={item}>
                  <Text variant="dark">
                    <FormattedHTMLMessage
                      id={item}
                      values={{ email: oe.email }}
                    />
                  </Text>
                </li>
              ))
            }
          </VerifyEmailList>
        </Box>
        <Box
          mb="xxxl"
          display="flex"
          justifyContent="center"
        >
          <img
            src={sendEmail}
            srcSet={`${sendEmail} 1x, ${sendEmail2x} 2x`}
            alt="Check mark"
          />
        </Box>

        <Box
          mb="xxxl"
        >
          {
            error.emailNotVerified
            && (
              <div
                className="u-pos-a"
                style={{ bottom: 'calc(100% + 8px)', right: 0 }}
              >
                <div
                  className="c-bubble a-form-error c-bubble--red"
                >
                  <FormattedMessage
                    id="verifyEmail.error.emailNotVerified"
                    defaultMessage="To proceed please verify your email."
                  />
                </div>
              </div>
            )
          }
          <Box>
            <Box
              mb="l"
            >
              <Button
                id="a-verifiedMyEmail-action"
                loading={loading}
                onClick={this.onCheckForVerification}
                variant="login"
                style={{ width: '100%' }}
              >
                <FormattedMessage
                  id="verifyEmail.verifiedMyEmail"
                />
              </Button>
            </Box>
            {
              resendConfirmation || resendingEmail ? (
                <Box
                  height="44px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <img
                    src={checkMark}
                    srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
                    alt="Check mark"
                  />
                  <Text
                    variant="dark"
                    ml="xs"
                    alignItems="center"
                  >
                    <FormattedMessage
                      id="verifyEmail.emailResent"
                      defaultMessage="The email has been resent"
                    />
                  </Text>
                </Box>
              ) : (
                <Button
                  id="a-resendEmail-action"
                  loading={loading}
                  onClick={this.onResendVerificationEmail}
                  variant="dark"
                  style={{ width: '100%' }}
                >
                  <FormattedMessage
                    id="verifyEmail.resendEmail"
                  />
                </Button>
              )
            }
          </Box>
        </Box>

        <Box
          textAlign="center"
          mt="a"
          mb="xxl"
        >
          {
            isUnregisteredMode
              ? (
                <Link to="/">
                  <Box
                    mb="m"
                  >
                    <Text size="small">
                      <FormattedMessage
                        id="verifyEmail.cancelRegistration"
                        defaultMessage="Cancel Registration"
                      />
                    </Text>
                  </Box>
                </Link>
              ) : (
                <Box>
                  <Paragraph size="small" variant="dark">
                    <FormattedMessage
                      id="verifyEmail.registeredAs"
                      values={{ email: oe.email }}
                      defaultMessage="Registered as {email}"
                    />
                  </Paragraph>
                  <TextButton
                    variant="link"
                    focusable
                    onClick={this.onChangeClick}
                  >
                    <Text size="small">
                      <FormattedMessage
                        id="verifyEmail.logout"
                        defaultMessage="Log out"
                      />
                    </Text>
                  </TextButton>
                </Box>
              )
          }
        </Box>
      </Box>
    );
  }
}

VerifyEmail.propTypes = {
  resendVerificationEmail: PropTypes.func.isRequired,
  getOEUserData: PropTypes.func.isRequired,
  changeUser: PropTypes.func.isRequired,
  oe: PropTypes.shape().isRequired,
  trackVerified: PropTypes.func,
  trackVerifiedFailed: PropTypes.func,
  history: PropTypes.object,
  isUnregisteredMode: PropTypes.bool.isRequired,
};

VerifyEmail.defaultProps = {
  trackVerified: () => { },
  trackVerifiedFailed: () => { },
  history: {
    push: () => { },
  },
};

export default withRouter(injectIntl(VerifyEmail));
