//
//     Symantec copyright header start
//
// Copyright © 2017, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2017, Symantec Corporation, All rights reserved.
//

import constants from './VTConstants';
import Item from './VTItem';

const dsv2Messages = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const { Value } = dsv2Messages;
const { DataTypeID } = Value;
const { logger } = SymBfw;
const { isNil } = SymBfw.utils;
const lastFour = 4;


/**
 * Array of vault Keys for this item.
 * @type {Array}
 */
const BankAccountKeys = [
  constants.DEFAULT_NODE_ID,
  constants.DEFAULT_LASTUPDATE,
  constants.DEFAULT_FAVORITE,
  constants.BANK_ACCOUNTS.OWNERNAME,
  constants.BANK_ACCOUNTS.ROUTINGNUMBER,
  constants.BANK_ACCOUNTS.ACCOUNTNUMBER,
  constants.BANK_ACCOUNTS.ACCOUNTNAME,
  constants.BANK_ACCOUNTS.SECURE,
  constants.BANK_ACCOUNTS.BANKNAME,
];

/**
 * Map of JSON keys for item to vault Keys for this item.
 * @type {Object}
 */
const propertyToSchemaKeyMap = {
  path: constants.DEFAULT_NODE_ID,
  lastUpdate: constants.DEFAULT_LASTUPDATE,
  accountName: constants.BANK_ACCOUNTS.ACCOUNTNAME,
  secure: constants.BANK_ACCOUNTS.SECURE,
  ownerName: constants.BANK_ACCOUNTS.OWNERNAME,
  routingNumber: constants.BANK_ACCOUNTS.ROUTINGNUMBER,
  accountNumber: constants.BANK_ACCOUNTS.ACCOUNTNUMBER,
  bankName: constants.BANK_ACCOUNTS.BANKNAME,
  favorite: constants.DEFAULT_FAVORITE,
};


/**
 * @constructor BankAccount
 * @desc Constructor for creating a new BankAccount item.
 */
class BankAccount extends Item {
  constructor() {
    super();

    // state of the object (encrypted/decrypted)
    this.encrypted = true;
    this.propertyToSchemaKeyMap = propertyToSchemaKeyMap;
    this.keys = BankAccountKeys;
  }

  schemaForKey(key) {
    switch (key) {
      case constants.DEFAULT_NODE_ID:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setPath,
          getter: this.getPath,
        };
      case constants.DEFAULT_LASTUPDATE:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_UINT64,
          deserializedType: DataTypeID.TID_UINT64,
          setter: this.setLastUpdate,
          getter: this.getLastUpdate,
        };
      case constants.DEFAULT_FAVORITE:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setFavorite,
          getter: this.getFavorite,
        };
      case constants.BANK_ACCOUNTS.ACCOUNTNUMBER:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setAccountNumber,
          getter: this.getAccountNumber,
        };
      case constants.BANK_ACCOUNTS.ROUTINGNUMBER:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setRoutingNumber,
          getter: this.getRoutingNumber,
        };
      case constants.BANK_ACCOUNTS.OWNERNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setOwnerName,
          getter: this.getOwnerName,
        };
      case constants.BANK_ACCOUNTS.ACCOUNTNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setAccountName,
          getter: this.getAccountName,
        };
      case constants.BANK_ACCOUNTS.BANKNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setBankName,
          getter: this.getBankName,
        };
      case constants.BANK_ACCOUNTS.SECURE:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setSecure,
          getter: this.getSecure,
        };

      default:
        logger.error(`Invalid data value: ${key}`);
        break;
    }
    return null;
  }

  /**
   * Setter for the routing number field.
   * @param {String} value   The Routing number field.
   * @param {String=} context Internal context or not. If you are calling this setter for storing
   *  a new item then you can ignore this param.
   */
  setRoutingNumber(value, context) {
    super.setClearOrObfuscatedStringProperty
      .call(this, 'routingNumber', value, context);
  }

  /**
   * Getter for the routing number
   * @param {String=} context Internal context or not. If you are calling this getter for getting
   *  the raw value (if obfuscated the binary) then ignore this value.
   * @return {String} Routing number when called without any context.
   */
  getRoutingNumber(context) {
    return super.getDeobfuscatedStringProperty
      .call(this, this.routingNumber, context);
  }

  /**
   * Setter for the account number field.
   * @param {String} value   The Account number field.
   * @param {String=} context Internal context or not. If you are calling this setter for storing
   *  a new item then you can ignore this param.
   */
  setAccountNumber(value, context) {
    super.setClearOrObfuscatedStringProperty
      .call(this, 'accountNumber', value, context);
  }

  /**
   * Getter for the account number
   * @param {String=} context Internal context or not. If you are calling this getter for getting
   *  the raw value (if obfuscated the binary) then ignore this value.
   * @return {String} Account number when called without any context.
   */
  getAccountNumber(context) {
    return super.getDeobfuscatedStringProperty
      .call(this, this.accountNumber, context);
  }

  /**
   * Setter for the owner name field
   * @param {String} value   The owner name field
   * @param {String=} context Internal context or not. If you are calling this setter for storing
   *  a new item then you can ignore this param.
   */
  setOwnerName(value, context) {
    super.setClearOrObfuscatedStringProperty
      .call(this, 'ownerName', value, context);
  }

  /**
   * Getter for the owner name field
   * @param {String=} context Internal context or not. If you are calling this getter for getting
   *  the raw value (if obfuscated the binary) then ignore this value.
   * @return {String} owner name when called without any context.
   */
  getOwnerName(context) {
    return super.getDeobfuscatedStringProperty
      .call(this, this.ownerName, context);
  }

  /**
   * Setter for the Bank name field
   * @param {String} value   The Bank name field
   */
  setBankName(value) {
    this.bankName = value;
  }

  /**
   * Getter for the Bank name field
   */
  getBankName() {
    return this.bankName;
  }

  /**
   * Setter for the account name field
   * @param {String} value   The Account name field
   */
  setAccountName(value) {
    this.accountName = value;
  }

  /**
   * Getter for the account name field
   * @return {String} Account name when called without any context.
   */
  getAccountName() {
    return this.accountName;
  }

  /**
   * @function getNodePath
   * @desc Implementation of interface function that returns
   *  the full path to the current BankAccount
   * @returns Returns a string that contains the full path to the item in the datastore
   */
  getNodePath() {
    // format /11/IDSC/13/<guid>
    return `${constants.DEFAULT_BANK_ACCOUNT_NODE}/${this.getPath()}`;
  }

  /**
   * @desc Returns the last four digits on the credit card.
   * @return {String} Last four digits of the credit card.
   */
  getLastFourOnCard() {
    const bankAccountNumber = this.getAccountNumber();
    if (isNil(bankAccountNumber)) {
      return '';
    }
    const { length } = bankAccountNumber;
    if (length > lastFour) {
      return bankAccountNumber.substring(length - lastFour, length);
    }
    return bankAccountNumber;
  }

  /**
   * @function setLastUpdate
   * @desc setter to set the last updated time stamp of this node
   * @param  {Long} value time stamp
   */
  setLastUpdate(value) {
    this.lastUpdate = value;
  }
}

export default BankAccount;
