import React from 'react';
import Box from 'pwm-components/components/Box';

import StyledDashboardWrapper from './StyledDashboardWrapper';

type Props = {
  fixedHeight: boolean;
  fullWidth: boolean;
  withHeader: boolean;
}

const DashboardPageWrapper: React.FC<Props> = ({
  children, fixedHeight, fullWidth, withHeader,
}) => {
  let minHeight = '100%';
  if (withHeader) {
    minHeight = 'calc(100% - 116px)';
  }
  if (fixedHeight) {
    minHeight = '';
  }

  let height = '';

  if (fixedHeight) {
    height = withHeader ? 'calc(100% - 116px)' : '100%';
  }

  return (
    <StyledDashboardWrapper
      id="a-my-data"
      height={height}
      minHeight={minHeight}
      fullWidth={fullWidth}
    >
      <Box height="100%" width="100%">
        {children}
      </Box>
    </StyledDashboardWrapper>
  );
};

export default DashboardPageWrapper;
