import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { authUpdateEmail, logoutUser } from '../AuthenticationActions';
import AuthPageWrapper from '../../oe/components/AuthPageWrapper';
import SpotlightAuthPageWrapper from '../../user/components/SpotlightAuthPageWrapper';
import SpotlightSignInRedirectBanner from '../../user/components/SpotlightSignInRedirectBanner';
import { showGetProFree } from '../../user/selectors';
import UnlockProBanner from '../../componentLib/UnlockProBanner';
import ConfigRenderer from '../../componentLib/ConfigRenderer';

class AuthPageContainer extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    onEmailUpdate: PropTypes.func.isRequired,
    onChangeClick: PropTypes.func.isRequired,
    getProFree: PropTypes.bool.isRequired,
    isUnregisteredMode: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      mountTimestamp: 0,
    };
    this.getTrackingTime = this.getTrackingTime.bind(this);
    this.setTrackingTime = this.setTrackingTime.bind(this);
  }

  getTrackingTime() {
    return this.state.mountTimestamp;
  }

  setTrackingTime() {
    this.setState({
      mountTimestamp: Date.now(),
    });
  }

  render() {
    const {
      getProFree, isUnregisteredMode,
    } = this.props;
    const Page = () => (
      React.cloneElement(this.props.children, {
        onEmailUpdate: this.props.onEmailUpdate,
        onChangeClick: this.props.onChangeClick,
        mountTimestamp: this.state.mountTimestamp,
        setTrackingTime: this.setTrackingTime,
      })
    );

    return (
      <>
        <ConfigRenderer condition="spotlight">
          <SpotlightAuthPageWrapper
            banner={isUnregisteredMode ? <SpotlightSignInRedirectBanner /> : null}
            Page={Page}
          />
        </ConfigRenderer>
        <ConfigRenderer condition="spotlight" negate>
          <AuthPageWrapper
            banner={getProFree ? <UnlockProBanner /> : null}
            Page={Page}
          />
        </ConfigRenderer>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    oe, auth, user,
  } = state;

  return {
    oe,
    user,
    email: auth.email,
    isUnregisteredMode: !oe.email,
    getProFree: showGetProFree(state),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onEmailUpdate: authUpdateEmail,
  onChangeClick: logoutUser,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuthPageContainer);
