import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

const propTypes = {
  date: PropTypes.string.isRequired,
  otherDomain: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof propTypes>;

const TooltipDependentBreachWebsiteAvira: React.FC<Props> = ({ date, otherDomain }) => (
  <Box>
    <Paragraph>
      <strong>
        <FormattedMessage id="dashboard.securityStatus.tooltip.header.breachWarning" />
      </strong>
    </Paragraph>

    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage
        id="dashboard.securityStatus.breachedWebsite.dependentAucBreach"
        values={{ date, otherDomain }}
      />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedMessage id="dashboard.securityStatus.hibp.dependentBreachedAccount.securityHint" />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage id="dashboard.securityStatus.hibp.dependentBreached.cta" />
    </Paragraph>
  </Box>
);

export default TooltipDependentBreachWebsiteAvira;
