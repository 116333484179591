import { createSelector } from 'reselect';
import config from '../config';

const getSyncEnabledStatus = state => state.userData.sync_store_manager === 'blur';
const getExtensionStatus = state => state.dashboard.extensionConnected;

// eslint-disable-next-line import/prefer-default-export
export const isDataUsageDisallowed = createSelector(
  [getSyncEnabledStatus, getExtensionStatus],
  (syncEnabled, extensionInstalled) => !(syncEnabled || extensionInstalled || config.spotlight),
);
