import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import debounce from 'lodash/debounce';


import CloseIcon from 'pwm-components/icons/Close';
import SearchIcon from 'pwm-components/icons/Search';
import Box from 'pwm-components/components/Box';
import UnderlinedInput from 'pwm-components/components/UnderlinedInput';
import TextButton from 'pwm-components/components/TextButton';

import config from '../../config';
import { FILTER_ALL } from '../../lib/MyDataHelper';
import DataList from '../../componentLib/DataList';
import PageTitle from '../../componentLib/PageTitle';
import PageContentWrapper from '../../componentLib/PageContentWrapper';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import { SpotlightNav, SidebarWrapper } from '../../sidebar';
import SearchInputComponent from '../../componentLib/SearchInput';
import { setScrolling as setScrollingAction } from '../../dashboard/DashboardActions';
import intlShape from '../../lib/intlShape';

class DataListContainer extends React.Component {
  // eslint-disable-next-line max-statements
  constructor(props) {
    super(props);

    let search = '';
    let tag = FILTER_ALL;
    let currentTab = '';
    const { location, tabs } = props;

    this.searchRef = React.createRef();

    if (location) {
      const query = queryString.parse(location.search);
      if (query) {
        search = query.q || '';
        tag = query.tag || '';
      }
      if (location.pathname) {
        tabs.forEach((tab) => {
          if (location.pathname.indexOf(tab.pathname) >= 0) {
            currentTab = tab.id;
          }
        });
      }
    }

    this.state = {
      currentTab,
      search,
      tag,
      focused: false,
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onTagChange = this.onTagChange.bind(this);
    this.afterSearch = this.afterSearch.bind(this);
    this.afterScroll = this.afterScroll.bind(this);
    this.onScroll = debounce(this.onScroll.bind(this), 500, { leading: true, trailing: false });
    this.onInteraction = this.onInteraction.bind(this);
  }

  // eslint-disable-next-line complexity
  componentDidMount() {
    const {
      disallowDataUsage, onMount, location, lastLocation,
    } = this.props;
    if (onMount) {
      onMount({
        source: (lastLocation && lastLocation.pathname) || '',
        destination: (location && location.pathname) || '',
      });
    }

    if (this.searchRef && this.searchRef.current && !disallowDataUsage) {
      if (!config.spotlight) {
        this.searchRef.current.focus();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { disallowDataUsage } = this.props;
    if (nextProps.disallowDataUsage !== disallowDataUsage
      && !nextProps.disallowDataUsage && this.searchRef.current) {
      this.searchRef.current.focus();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.focused !== this.state.focused) {
      return false;
    }

    return true;
  }

  onInteraction(index, resultLength) {
    const { search, currentTab } = this.state;
    const { trackInteraction } = this.props;
    if (search) {
      trackInteraction(index, resultLength, currentTab);
    }
  }

  onSearchChange(e) {
    const { value } = e.target;
    const { search } = this.state;
    if (value !== search) {
      this.setState({
        search: value,
      }, this.afterSearch);
    }
  }

  onSearchClick() {
    const { search, focused } = this.state;
    if (search.length || focused) {
      this.setState({ search: '' }, this.afterSearch);
    } else if (this.searchRef && this.searchRef.current) {
      this.searchRef.current.focus();
    }
  }

  onTagChange(tag) {
    this.props.onTagChange(tag, (this.state.tag === tag), this.state.currentTab);
    if (tag !== this.state.tag) {
      this.setState({
        tag,
      }, this.updateBrowserHistory);
    }
  }

  onTabClick(tab) {
    this.props.onTabClick(tab.id);
    this.props.history.push({
      pathname: tab.pathname,
      query: queryString.stringify({
        q: this.state.search,
      }),
    });
    this.setState({
      tag: FILTER_ALL,
      currentTab: tab.id,
    });
  }

  onScroll() {
    const { setScrolling } = this.props;
    setScrolling(true);
  }

  afterScroll(topPosition) {
    const { afterScroll, setScrolling } = this.props;
    setScrolling(false);
    afterScroll(topPosition);
  }

  afterSearch() {
    if (this.state.search) {
      this.props.afterSearch();
    }
    this.updateBrowserHistory();
  }

  updateBrowserHistory() {
    const { search, tag } = this.state;
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: queryString.stringify({
        q: search,
        tag,
      }),
    });
  }

  render() {
    const { search, tag, focused } = this.state;

    const {
      id, hideSearch, header, pageBanner, children, intl,
    } = this.props;

    let SearchInput = UnderlinedInput;
    const searchInputExtraProps = {};

    if (config.spotlight) {
      SearchInput = SearchInputComponent;
      searchInputExtraProps.hideInput = search.length === 0;
    }

    const searchComponent = (
      <SearchInput
        {...searchInputExtraProps}
        id="a-search-input"
        value={search}
        ref={this.searchRef}
        onChange={this.onSearchChange}
        placeholder={focused ? intl.formatMessage({ id: 'dashboard.datalist.searchPlaceholder' }) : ''}
        onFocus={() => { this.setState({ focused: true }); }}
        onBlur={() => { this.setState({ focused: false }); }}
        icons={(
          <TextButton key="searchInput" onClick={this.onSearchClick}>
            {
              search.length || focused ? (
                <CloseIcon id="a-clear-search-icon" variant="link" />
              ) : (
                <SearchIcon id="a-search-icon" variant="normal" />
              )
            }
          </TextButton>
        )}
      />
    );

    return (
      <PageContentWrapper id={id} height="100%" position="relative" extendToBottom>
        <Box
          pr="m"
          pl="xxs"
        >
          {pageBanner}
          <ConfigRenderer condition="spotlight" negate>
            <PageTitle
              renderAfter={() => (
                !hideSearch
                && (
                  <Box width="300px">
                    {searchComponent}
                  </Box>
                )
              )}
            >
              {header}
            </PageTitle>
          </ConfigRenderer>
          <ConfigRenderer condition="spotlight">
            <Box mb="m">
              <SidebarWrapper render={props => (
                <SpotlightNav {...props}>
                  {searchComponent}
                </SpotlightNav>
              )}
              />
            </Box>
          </ConfigRenderer>
        </Box>
        <DataList
          defaultFilter={FILTER_ALL}
          filter={tag}
          search={search}
          onTagClick={this.onTagChange}
          onInteraction={this.onInteraction}
          {...this.props}
          onScroll={this.onScroll}
          afterScroll={this.afterScroll}
        />
        <>
          {children}
        </>
      </PageContentWrapper>
    );
  }
}

DataListContainer.propTypes = {
  id: PropTypes.string,
  tabs: PropTypes.array,
  onTagChange: PropTypes.func,
  onMount: PropTypes.func,
  afterSearch: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.node,
  disallowDataUsage: PropTypes.bool.isRequired,
  onTabClick: PropTypes.func.isRequired,
  trackInteraction: PropTypes.func,
  hideExtensionMessage: PropTypes.bool,
  hideSearch: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  pageBanner: PropTypes.element,
  intl: intlShape.isRequired,
  lastLocation: PropTypes.any,
  setScrolling: PropTypes.func.isRequired,
  afterScroll: PropTypes.func,
};

DataListContainer.defaultProps = {
  id: '',
  afterSearch: () => {},
  onTagChange: () => {},
  onMount: () => {},
  trackInteraction: () => {},
  tabs: [],
  header: null,
  hideExtensionMessage: false,
  children: null,
  hideSearch: false,
  pageBanner: null,
  afterScroll: () => {},
  lastLocation: null,
};


export default connect(
  null,
  { setScrolling: setScrollingAction },
)(withRouter(injectIntl(withLastLocation(DataListContainer))));
