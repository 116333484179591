import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { withLastLocation } from 'react-router-last-location';
import { withFileManager } from '../../files/FileManagerContext';
import {
  syncAddFiles, syncDeleteFile, createRefreshFileKey, trackFileViewed, trackFileAdd,
} from '../../files/FileActions';
import { verifyMasterPassword } from '../../user/MasterPasswordActions';
import { trackGetProClicked } from '../../dashboard/DashboardActions';
import MyDataDetailsPage, { Props as ComponentProps } from './MyDataDetailsPage';

export type Props = ComponentProps;

class MyDataDetailsContainer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);

    this.onDeleteFile = this.onDeleteFile.bind(this);
    this.onGetProClick = this.onGetProClick.bind(this);
    this.onAddFileClick = this.onAddFileClick.bind(this);
    this.onFileThumbnailClick = this.onFileThumbnailClick.bind(this);
  }

  private onDeleteFile(id: string): void {
    const { fileManager, fileManagerActions, onDeleteFile } = this.props;
    onDeleteFile(id, { fileManager, fileManagerActions });
  }

  private onGetProClick(): void {
    const { paymentUrl, onGetProClick } = this.props;
    if (onGetProClick) {
      onGetProClick();
    }

    if (paymentUrl && paymentUrl.length > 0) {
      window.open(paymentUrl);
    }
  }

  private onAddFileClick(): void {
    const {
      onAddFileClick,
    } = this.props;

    if (onAddFileClick) {
      onAddFileClick();
    }
  }

  private onFileThumbnailClick(): void {
    const { onFileThumbnailClick } = this.props;
    if (onFileThumbnailClick) {
      onFileThumbnailClick();
    }
  }

  public render(): JSX.Element {
    return (
      <MyDataDetailsPage
        {...this.props}
        onDeleteFile={this.onDeleteFile}
        onGetProClick={this.onGetProClick}
        onAddFileClick={this.onAddFileClick}
      />
    );
  }
}

const mapStateToProps: any = ({
  userData: { isPro, sync_store_manager }, user: { key2 }, oeData: { paymentUrl },
}: any) => ({
  isPro,
  hasFileKey: !!key2,
  syncEnabled: sync_store_manager === 'blur',
  paymentUrl: paymentUrl || '',
});

const mapDispatchToProps: any = (dispatch: Dispatch) => bindActionCreators({
  onMasterPasswordCheck: verifyMasterPassword,
  onCreateFileKey: createRefreshFileKey,
  onFileUpload: syncAddFiles,
  onDeleteFile: syncDeleteFile,
  onAddFileClick: trackFileAdd,
  onGetProClick: trackGetProClicked,
  onFileThumbnailClick: trackFileViewed,
}, dispatch);


// withLastLocation typing is a bit flawed
export default withLastLocation(
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  withRouter(
    withFileManager(
      injectIntl(
        connect(
          mapStateToProps,
          mapDispatchToProps,
        )(MyDataDetailsContainer),
      ),
    ),
  ),
);
