import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link, LinkProps } from 'react-router-dom';
import { withTooltip } from 'pwm-components/components/Tooltip';
import Text from 'pwm-components/components/Text';
import Paragraph from 'pwm-components/components/Paragraph';
import DropdownButton from 'pwm-components/components/DropdownButton';
import { DropdownMenuItem } from 'pwm-components/components/DropdownMenu';
import TextButton from 'pwm-components/components/TextButton';
import { MenuItem } from '../../componentLib/DropdownMenu';
import TooltipChangePasswordSteps from './tooltips/TooltipChangePasswordSteps';
import TooltipAucDetectionCta from './tooltips/TooltipAucDetectionCta';
import TooltipUnsafeWebsiteCta from './tooltips/TooltipUnsafeWebsiteCta';
import { aucMap } from '../../lib/SecurityStatusHelper';
import AccountLink from '../../accounts/components/AccountLink';
import MessengerContext from '../../componentLib/MessengerContext';
import { updateAccount } from '../../accounts/AccountActions';

type LinkAttr = LinkProps;

type HTMLLinkAttr = React.HTMLProps<HTMLAnchorElement>;

type ButtonAttr = React.ComponentProps<typeof TextButton>;

type Data = {
  auc: boolean;
  aucCategory: keyof typeof aucMap;
  breached: boolean;
  domain: string;
  fakeAccount: boolean;
  id: string;
  ignoreWarnings: boolean;
  insecureProtocol: boolean;
  label: string;
  login_url: string;
  username: string;
}

type Props = WrappedComponentProps & {
  data: Data;
  extensionInstalled: boolean;
  visibility?: 'hidden' | 'visible';
  onButtonClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, actionType: string) => void;
  onDeleteAccountClick: (id: string) => void;
  onDeleteBreachedUnknownClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onIgnoreChange: () => void;
};

const BoxWithToolTip = withTooltip(Box);
const TooltipWrapper = styled(Box)`
  white-space: normal;
`;

// disabled for now until we come up with a better string
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EnableWarningsTooltipWrapper: React.FC = ({ children }) => (
  <BoxWithToolTip
    align="center"
    tooltipAlign="right"
    bigPadding
    noDelay
    tooltipContent={(
      <TooltipWrapper textAlign="left">
        <Paragraph>
          <FormattedMessage id="dashboard.securityStatus.ignore" />
        </Paragraph>
      </TooltipWrapper>
    )}
  >
    {children}
  </BoxWithToolTip>
);

const ChangePasswordTooltipWrapper: React.FC<{
  website: string;
  extensionInstalled: boolean;
}> = ({ children, website, extensionInstalled }) => {
  const [showDetail, setShowDetail] = useState(false);

  return (
    <BoxWithToolTip
      align="center"
      tooltipAlign="right"
      bigPadding
      noDelay
      tooltipContent={(
        <TooltipWrapper textAlign="left">
          <TooltipChangePasswordSteps
            showDetails={showDetail}
            website={website}
            extensionInstalled={extensionInstalled}
            onShowDetailsClick={() => setShowDetail(!showDetail)}
          />
        </TooltipWrapper>
      )}
    >
      {children}
    </BoxWithToolTip>
  );
};

const UseHTTPSTooltipWrapper: React.FC = ({ children }) => (
  <BoxWithToolTip
    align="center"
    tooltipAlign="right"
    bigPadding
    noDelay
    tooltipContent={<TooltipUnsafeWebsiteCta />}
  >
    {children}
  </BoxWithToolTip>
);

const DeleteAccountTooltipWrapper: React.FC<{
  aucCategory: string;
}> = ({ children, aucCategory }) => (
  <BoxWithToolTip
    align="center"
    tooltipAlign="right"
    bigPadding
    noDelay
    tooltipContent={<TooltipAucDetectionCta aucCategory={aucCategory} />}
  >
    {children}
  </BoxWithToolTip>
);

// eslint-disable-next-line max-statements
const SecurityStatusActionDropdown: React.FC<Props> = ({
  data,
  extensionInstalled,
  intl,
  visibility,
  onButtonClick,
  onDeleteAccountClick,
  onDeleteBreachedUnknownClick,
  onIgnoreChange,
}) => {
  const {
    auc, aucCategory, breached, domain, fakeAccount, id, ignoreWarnings,
    insecureProtocol, label, login_url, username,
  } = data;
  const url = login_url || `https://${domain}`;
  const dashboardMessenger = useContext(MessengerContext);
  const dispatch = useDispatch();
  const getIgnoreWarningMenuOption = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.ignoreCurrent',
    menuItemAttrs: {
      onClick: () => onIgnoreChange(),
    },
  });

  const getEditAccountMenuOption = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.edit',
    menuItemAttrs: {
      to: `mydata/passwords/${data.id}/edit`,
    },
  });

  const getDeleteMenuOption = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.delete',
    menuItemAttrs: {
      onClick: () => onDeleteAccountClick(id),
    },
  });

  const getEnableWarningsAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.enableWarnings',
    menuItemAttrs: {
      onClick: () => onIgnoreChange(),
    },
  });

  const getChangePasswordAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.changePassword',
    menuItemAttrs: {
      href: url,
      onClick: e => onButtonClick(e, 'changePassword'),
    },
  });

  const getChangePasswordSecondaryAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.changePassword',
    menuItemAttrs: {
      href: url,
      onClick: e => onButtonClick(e, 'changePassword'),
    },
  });

  const getBreachedUnknownAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.breachedUnknown.add',
    menuItemAttrs: {
      to: ({ pathname: '/mydata/passwords/new/', state: { domain, username } }),
    },
  });

  const getDeleteBreachedUnknownAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.hibp.breachedUnknown.delete',
    menuItemAttrs: {
      onClick: onDeleteBreachedUnknownClick,
    },
  });

  const getUseHTTPSAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.useHttps',
    menuItemAttrs: {
      onClick: (() => {
        try {
          const secureLoginUrl = new URL(login_url);
          if (secureLoginUrl.protocol === 'http:') {
            secureLoginUrl.protocol = 'https:';
          }
          if (dashboardMessenger?.isConnected()) {
            dashboardMessenger.send(
              'dashboard:extension:checkHttps',
              {
                secureLoginUrl: secureLoginUrl.toString(),
                id,
              },
              (_err, loaded) => {
                if (loaded) {
                  dispatch(updateAccount(id, { login_url: secureLoginUrl }));
                } else {
                  alert('Cannot update to https');
                }
              },
            );
          }
        } catch (e) {
          alert('Cannot update to https');
        }
      }),
    },
  });

  const getDeleteAccountAction = (): MenuItem => ({
    labelId: 'dashboard.securityStatus.unsafeWebsite.auc.deleteAccount',
    menuItemAttrs: {
      onClick: (() => onDeleteAccountClick(id)),
    },
  });

  const getPrimaryAction = (): MenuItem => {
    if (ignoreWarnings) { return getEnableWarningsAction(); }
    if (fakeAccount) { return getBreachedUnknownAction(); }
    if (insecureProtocol && !breached) { return getUseHTTPSAction(); }
    if (auc) { return getDeleteAccountAction(); }
    return getChangePasswordAction();
  };

  const getSecondaryAction = (): MenuItem => {
    if (fakeAccount) { return getDeleteBreachedUnknownAction(); }
    if (ignoreWarnings) {
      if (insecureProtocol && !data.breached) { return getUseHTTPSAction(); }
      if (auc) { return getDeleteAccountAction(); }
      return getChangePasswordSecondaryAction();
    }
    return getIgnoreWarningMenuOption();
  };

  const getPrimaryActionTooltipComponent = (): React.ComponentType => {
    if (ignoreWarnings || data.fakeAccount) {
      return ({ children }) => (<Box style={{ visibility }}>{children}</Box>);
    }

    if (insecureProtocol && !breached) {
      return ({ children }) => <UseHTTPSTooltipWrapper>{children}</UseHTTPSTooltipWrapper>;
    }
    if (data.auc) {
      return ({ children }) => (
        <DeleteAccountTooltipWrapper
          aucCategory={intl.formatMessage({ id: aucMap[aucCategory].categoryId })}
        >
          {children}
        </DeleteAccountTooltipWrapper>
      );
    }

    return ({ children }) => (
      <ChangePasswordTooltipWrapper
        extensionInstalled={extensionInstalled}
        website={label || domain}
      >
        {children}
      </ChangePasswordTooltipWrapper>
    );
  };

  const createSecurityStatusAction = (primaryAction: MenuItem): JSX.Element => {
    const { labelId, menuItemAttrs } = primaryAction;
    if (labelId === 'dashboard.securityStatus.changePassword') {
      return (
        <AccountLink data={data}>
          <Text variant="link">
            <FormattedMessage id={labelId} />
          </Text>
        </AccountLink>
      );
    }

    if ((menuItemAttrs as LinkAttr).to != null) {
      return (
        <Link {...menuItemAttrs as LinkAttr}>
          <TextButton variant="normal">
            <FormattedMessage id={labelId} />
          </TextButton>
        </Link>
      );
    }

    return (
      <FormattedMessage id={labelId} />
    );
  };

  const primaryAction = getPrimaryAction();
  const secondaryAction = getSecondaryAction();
  const PrimaryActionTooltipComponent = getPrimaryActionTooltipComponent();
  const securityStatusAction = createSecurityStatusAction(primaryAction);

  let menuItems: MenuItem[];
  if (fakeAccount) {
    menuItems = [secondaryAction];
  } else {
    menuItems = [getEditAccountMenuOption(), secondaryAction, getDeleteMenuOption()];
  }

  return (
    <Box className='a-security-status-dropdown-menu' style={{ visibility }}>
      <DropdownButton
        leftWrapper={PrimaryActionTooltipComponent}
        dropdownContent={
          // eslint-disable-next-line complexity
          menuItems.map(({ labelId, menuItemAttrs }) => (
            <DropdownMenuItem
              key={labelId}
              variant="dark"
            >
              {menuItemAttrs && menuItemAttrs.href && (
                <a
                  role="button"
                  tabIndex={0}
                  {...menuItemAttrs as HTMLLinkAttr}
                  style={{ color: 'inherit' }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage id={labelId} />
                </a>
              )}
              {menuItemAttrs && !menuItemAttrs.href && menuItemAttrs.onClick && (
                <TextButton
                  tabIndex={0}
                  {...menuItemAttrs as ButtonAttr}
                  variant="dark"
                >
                  <FormattedMessage id={labelId} />
                </TextButton>
              )}
              {menuItemAttrs && (menuItemAttrs as LinkAttr).to && (
                <Link {...menuItemAttrs as LinkAttr}>
                  <TextButton variant="dark">
                    <FormattedMessage id={labelId} />
                  </TextButton>
                </Link>
              )}
            </DropdownMenuItem>
          ))
        }
        onClick={primaryAction.menuItemAttrs.onClick || (() => { })}
        allowDropdownOverflow
        align="right"
      >
        { securityStatusAction }
      </DropdownButton>
    </Box>
  );
};

export default injectIntl(SecurityStatusActionDropdown);
