import * as Sentry from '@sentry/browser';
import * as migrationHelpers from '@avira-pwm/sync/helpers/migration';
import { pwnedPassword } from 'hibp';
import { setUserData, getUserData } from '../user/UserActions';
import { groupAccountsByPassword } from '../accounts/selectors';
import { createRefreshFileKey } from '../files/FileActions';
import { validateMasterPassword } from '../user/MasterPasswordActions';
import nlokHelper from '../nlok/helper';
import { closeVault, openVaultWithPassword } from '../nlok/NLOKActions';

const SENTRY_CONTEXT = {
  tags: {
    context: 'changeMasterPassword',
  },
};

export const ERRORS = {
  wrong: {
    messageId: 'masterPassword.error.verify.wrong',
    field: 'oldPassword',
  },
  notIdentical: {
    messageId: 'masterPassword.error.verify.notIdentical',
    field: 'confirmPassword',
  },
  same: {
    messageId: 'dashboard.settings.changeMasterPassword.error.same',
    field: 'newPassword',
  },
  strength: {
    messageId: 'dashboard.settings.changeMasterPassword.error.strength',
    field: 'newPassword',
  },
  breached: {
    messageId: 'dashboard.settings.changeMasterPassword.error.breached',
    field: 'newPassword',
  },
  nonmigratable: {
    messageId: 'dashboard.settings.changeMasterPassword.error.nonmigratable',
    field: 'oldPassword',
  },
  unknown: {
    messageId: 'error.unknown',
    field: 'oldPassword',
  },
};

// eslint-disable-next-line complexity, max-statements
export const triggerMasterPasswordChange = (newPassword, oldPassword) => async (
  dispatch, getState, { licenseService },
) => {
  const {
    user: { authToken, key2 },
    oe: { token: oeToken },
    userData: { key: encKey, verify_key: verKey },
  } = getState();

  try {
    validateMasterPassword(licenseService, oldPassword, encKey, verKey, authToken);
  } catch (e) {
    throw ERRORS.wrong;
  }

  if (!(key2 && key2.length)) {
    await dispatch(createRefreshFileKey(oldPassword));
  }

  await dispatch(getUserData());

  const {
    user: {
      key: userKey,
      key2: fileKey,
    },
    userData: {
      migration_status: {
        status,
      } = { status: null },
    },
  } = getState();

  // non-migratable status handling
  if (migrationHelpers.isNonMigratable(status)) {
    throw ERRORS.nonmigratable;
  }

  // migration in progress
  if (!(migrationHelpers.isMigrationPending(status) || migrationHelpers.isMigratedStatus(status))) {
    throw ERRORS.unknown;
  }

  let vaultPasswordChanged = false;

  Sentry.addBreadcrumb({ message: 'triggering mp change' });
  if (migrationHelpers.isMigratedStatus(status)) {
    try {
      await nlokHelper.changeVaultPassword(oldPassword, newPassword);
      vaultPasswordChanged = true;
      await dispatch(closeVault());
      await dispatch(openVaultWithPassword(newPassword, false));
    } catch (e) {
      Sentry.captureException(e, SENTRY_CONTEXT);
      throw ERRORS.unknown;
    }
  }


  try {
    await licenseService.updateKey(authToken, newPassword, userKey, fileKey);
  } catch (e) {
    Sentry.captureException(e, SENTRY_CONTEXT);
    if (vaultPasswordChanged) {
      // revert password change in vault
      await nlokHelper.changeVaultPassword(newPassword, oldPassword);
      await dispatch(closeVault());
      await dispatch(openVaultWithPassword(oldPassword, false));
    }

    throw ERRORS.unknown;
  }

  const userData = await licenseService.getUserData(authToken, oeToken);
  await dispatch(setUserData(userData.user));
};

export const validateMasterPasswordChange = async (
  oldMP, newMP, confirmMP, score, reusedPasswordDomains,
// eslint-disable-next-line max-params
) => {
  if (score < 3) return ERRORS.strength; // at least GOOD
  if (newMP !== confirmMP) return ERRORS.notIdentical;
  if (oldMP === newMP) return ERRORS.same;

  try {
    const numberOfBreaches = await pwnedPassword(newMP);
    if (numberOfBreaches) return ERRORS.breached;
  } catch (e) { /** HIBP calls might fail; we can ignore it */ }

  if (reusedPasswordDomains) {
    return {
      showConfirmation: true,
      reusedPasswordDomains,
    };
  }

  return null;
};

// eslint-disable-next-line max-params
export const onChangeMasterPassword = (oldMP, newMP, confirmMP, newMPScore) => async (
  dispatch, getState, { licenseService },
) => {
  const state = getState();

  const {
    user: { authToken },
    userData: { key: encKey, verify_key: verKey },
  } = state;

  try {
    validateMasterPassword(licenseService, oldMP, encKey, verKey, authToken);
  } catch (e) {
    throw ERRORS.wrong;
  }

  const reusedPasswordDomains = groupAccountsByPassword(state)[newMP];

  const validationError = await validateMasterPasswordChange(oldMP, newMP, confirmMP,
    newMPScore, reusedPasswordDomains);
  if (validationError) throw validationError;

  try {
    await dispatch(triggerMasterPasswordChange(newMP, oldMP));
  } catch (e) {
    if (e instanceof Error) {
      Sentry.captureException(e, SENTRY_CONTEXT);
    } else {
      Sentry.captureMessage('change master password failed', {
        ...SENTRY_CONTEXT,
        extra: { error: e },
      });
    }
    throw e;
  }
};
