import * as Sentry from '@sentry/browser';
import React from 'react';
import { connect } from 'react-redux';
import { shape, func } from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import {
  updateNote,
  toggleNoteFavorite,
  syncDeleteNote,
  updateModifiedAt,
} from '../NoteActions';
import AddEditNotePage from './AddEditNotePage';

class EditNote extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(id, noteChangedProps) {
    try {
      await this.props.onSaveNoteClick(id, {
        ...noteChangedProps,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  render() {
    const { noteDetails } = this.props;

    return (
      <AddEditNotePage
        {...this.props}
        data={noteDetails}
        onSaveNoteClick={this.onSubmit}
      />
    );
  }
}

EditNote.propTypes = {
  noteDetails: shape().isRequired,
  files: shape().isRequired,
  onSaveNoteClick: func.isRequired,
  onDelete: func.isRequired,
};

const mapStateToProps = ({ noteDetails: { note }, files }) => ({
  noteDetails: note ?? {},
  files,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onSaveNoteClick: updateNote,
  onFavoriteClick: toggleNoteFavorite,
  onDelete: syncDeleteNote,
  onModified: updateModifiedAt,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditNote));
