import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AutoSuggestionField from './AutoSuggestionField';
import { getSuggestedUsernames } from './selectors';

const mapStateToProps = state => ({
  suggestionList: getSuggestedUsernames(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({ }, dispatch);

const UsernameAutoSuggestContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AutoSuggestionField);

export default UsernameAutoSuggestContainer;
