import React, { useRef, useEffect } from 'react';
import { createInput } from 'pwm-components/components/Input/InputWrapper';
import StyledInputField, { Props as SIProps } from 'pwm-components/components/Input/StyledInputField';
import StyledInputFieldWrapper, { Props as SIWProps } from 'pwm-components/components/Input/StyledInputFieldWrapper';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import StyledLabel from 'pwm-components/components/Input/StyledLabel';
import tabIndex from '../../../lib/TabIndexConfig';

type Props = SIWProps & SIProps & {
  label?: JSX.Element;
  monthInputID?: string;
  yearInputID?: string;
  monthValue?: string;
  yearValue?: string;
  placeholderMonth?: string;
  placeholderYear?: string;
  onMonthChange: (id: string, value: string) => void;
  onYearChange: (id: string, value: string) => void;
}

const INPUT_WIDTH = '48px';

const MonthInput = createInput(styled.span`
  ${StyledInputField} {
    text-align: right;
    padding-left: 12px;
    padding-right: 4px;
    width: ${INPUT_WIDTH};
  }
`);

const YearInput = createInput(styled.span`
  ${StyledInputField} {
    padding-left: 4px;
    padding-right: 12px;
      width: ${INPUT_WIDTH};
  }
`);

const Wrapper = styled(StyledInputFieldWrapper)`
  display: flex;
  align-items: center;
  align-content: center;
`;

const DateInput: React.FC<Props> = (props: Props) => {
  const {
    error,
    label,
    monthInputID,
    yearInputID,
    onMonthChange,
    onYearChange,
    placeholderMonth,
    placeholderYear,
    monthValue,
    yearValue,
    ref,
    ...rest
  } = props;

  const onBlurHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { id, value } = e.target;
    if (value === '1') {
      onMonthChange(id, '01');
    }
  };

  const monthRef = useRef<HTMLInputElement>(null);
  const yearRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (document.activeElement === monthRef.current) {
      if (monthValue && monthValue.length === 2 && yearRef.current) {
        yearRef.current.focus();
      }
    }
  }, [monthValue]);

  return (
    <Box>
      <StyledLabel>
        {label}
      </StyledLabel>
      <Wrapper
        {...rest}
        error={error}
      >
        <MonthInput
          inputRef={monthRef}
          autoComplete="off"
          id={monthInputID}
          value={monthValue}
          maxLength={3}
          onChange={e => onMonthChange(e.target.id, e.target.value)}
          onBlur={onBlurHandler}
          placeholder={placeholderMonth}
          tabIndex={tabIndex.mainFormFields}
        />
        <Text variant="dark">
          /
        </Text>
        <YearInput
          inputRef={yearRef}
          autoComplete="off"
          id={yearInputID}
          value={yearValue}
          maxLength={2}
          onChange={e => onYearChange(e.target.id, e.target.value)}
          placeholder={placeholderYear}
          tabIndex={tabIndex.mainFormFields}
        />
      </Wrapper>
    </Box>
  );
};

export default DateInput;
