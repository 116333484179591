import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage, injectIntl } from 'react-intl';
import PWMHeader from '../../componentLib/PWMHeader';
import backgroundImage from '../../img/more-info-background.jpg';
import extensionInteraction from '../../img/extension-interaction.png';
import extensionInteraction2x from '../../img/extension-interaction@2x.png';
import { getBrowser } from '../../lib/UserAgent';
import mobile from '../../img/icon-mobile.png';
import mobile2x from '../../img/icon-mobile@2x.png';

import autofill from '../../img/icon-autofill.png';
import autofill2x from '../../img/icon-autofill@2x.png';

import cloud from '../../img/icon-cloud.png';
import cloud2x from '../../img/icon-cloud@2x.png';

import folder from '../../img/icon-folder.png';
import folder2x from '../../img/icon-folder@2x.png';
import { doNotShowSaveNotification, trackWhyDoISeeThisTellMeMore } from '../../dashboard/DashboardActions';
import intlShape from '../../lib/intlShape';


const WhyDoISeeList = styled('ul')`
  margin-left: ${({ theme }) => theme.space.xxl};
  list-style: disc;

  & > li {
    padding-left: ${({ theme }) => theme.space.s};
    margin-bottom: ${({ theme }) => theme.space.m}
  }
`;

const WhySaveList = styled('ul')`
  & > li {
    margin-bottom: ${({ theme }) => theme.space.m};
  }
`;

const browser = getBrowser() || 'browser';

const MessageContainer = styled('section')`
  width: 50%;

  @media screen and (max-width: 1135px) {
    width: 100%;
  }

  @media screen and (min-width: 1500px) {
    width: 40%;
  }
`;

const MoreInfoPage = ({ intl, onDontShowAnymoreClick, onTellMeMoreClick }) => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
  >
    <PWMHeader />
    <Box
      style={{
        flexGrow: 1,
        backgroundImage: `linear-gradient(to right, white, 90%, rgba(0, 0, 0, 0)), url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: 'left, right',
        backgroundSize: 'auto 100%',
      }}
      p="64px"
    >
      <MessageContainer>
        <Paragraph
          size="h1"
          mb="xl"
        >
          <FormattedMessage
            id="dashboard.moreInfo.whyDoISeeSave"
            defaultMessage="Why do I see the Save Password notification?"
          />
        </Paragraph>

        <WhyDoISeeList>
          <li>
            <Text>
              <FormattedMessage
                id="dashboard.moreInfo.whyDoISeeSave.reason1"
                defaultMessage="The Password Manager browser extension was installed for your {browser} as part of the Avira Security Suite"
                values={{ browser }}
              />
            </Text>
          </li>
          <li>
            <Text>
              <FormattedMessage
                id="dashboard.moreInfo.whyDoISeeSave.reason2"
                defaultMessage="The extension detects when you login to your online services and proposes to save your login information to Avira Password Manager"
              />
            </Text>
          </li>
        </WhyDoISeeList>

        <Box
          my="xxl"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img
            src={extensionInteraction}
            srcSet={`${extensionInteraction} 1x, ${extensionInteraction2x} 2x`}
            alt="Extension in browser toolbar"
          />
        </Box>


        <Paragraph
          size="h1"
          mb="xl"
        >
          <FormattedMessage
            id="dashboard.moreInfo.whyShouldISave"
            defaultMessage="Why should I save?"
          />
        </Paragraph>

        <WhySaveList>
          <li>
            <Box
              display="flex"
              alignItems="center"
            >
              <Box mr="l">
                <img
                  src={folder}
                  srcSet={`${folder} 1x, ${folder2x} 2x`}
                  alt="Folder icon"
                />
              </Box>
              <Paragraph>
                <FormattedMessage
                  id="dashboard.moreInfo.whyShouldISave.reason1"
                  defaultMessage="Avira Password Manager remembers all your passwords so you don't have to"
                />
              </Paragraph>
            </Box>
          </li>
          <li>
            <Box
              display="flex"
              alignItems="center"
            >
              <Box mr="l">
                <img
                  src={cloud}
                  srcSet={`${cloud} 1x, ${cloud2x} 2x`}
                  alt="Saved icon"
                />
              </Box>
              <Paragraph>
                <FormattedMessage
                  id="dashboard.moreInfo.whyShouldISave.reason2"
                  defaultMessage="Your passwords are safely stored inside Avira Password Manager"
                />
              </Paragraph>
            </Box>
          </li>
          <li>
            <Box
              display="flex"
              alignItems="center"
            >
              <Box mr="l">
                <img
                  src={autofill}
                  srcSet={`${autofill} 1x, ${autofill2x} 2x`}
                  alt="Autofill icon"
                />
              </Box>
              <Paragraph>
                <FormattedMessage
                  id="dashboard.moreInfo.whyShouldISave.reason3"
                  defaultMessage="You can auto-login to your online services"
                />
              </Paragraph>
            </Box>
          </li>
          <li>
            <Box
              display="flex"
              alignItems="center"
            >
              <Box mr="l">
                <img
                  src={mobile}
                  srcSet={`${mobile} 1x, ${mobile2x} 2x`}
                  alt="Mobile devices"
                />
              </Box>
              <Paragraph>
                <FormattedMessage
                  id="dashboard.moreInfo.whyShouldISave.reason4"
                  defaultMessage="Your passwords are available for auto-login on all your devices (after registration)"
                />
              </Paragraph>
            </Box>
          </li>
        </WhySaveList>

        <Paragraph
          my="xxl"
        >
          <a
            href={intl.formatMessage({ id: 'dashboard.moreInfo.tellMeMoreLink' })}
            onClick={onTellMeMoreClick}
          >
            <Text
              variant="link"
            >
              <FormattedMessage
                id="dashboard.moreInfo.tellMeMore"
                defaultMessage="Tell me more about Avira Password Manager"
              />
            </Text>
          </a>
        </Paragraph>

        <Paragraph>
          <TextButton
            variant="link"
            onClick={onDontShowAnymoreClick}
          >
            <FormattedMessage
              id="dashboard.moreInfo.doNotShowSave"
              defaultMessage="Do not show save notification anymore"
            />
          </TextButton>
        </Paragraph>
      </MessageContainer>
    </Box>
  </Box>
);

MoreInfoPage.propTypes = {
  intl: intlShape.isRequired,
  onDontShowAnymoreClick: func.isRequired,
  onTellMeMoreClick: func.isRequired,
};

const mapDispatchToProps = {
  onDontShowAnymoreClick: doNotShowSaveNotification,
  onTellMeMoreClick: trackWhyDoISeeThisTellMeMore,
};


export default connect(
  null,
  mapDispatchToProps,
)(injectIntl(MoreInfoPage));
