import React from 'react';
import { bool } from 'prop-types';

import WhereIsMyDataStoredRegistered from './WhereIsMyDataStoredRegistered';
import WhereIsMyDataStoredUnregistered from './WhereIsMyDataStoredUnregistered';

const WhereIsMyDataStored = ({ isUnregisteredMode }) => (
  isUnregisteredMode ? <WhereIsMyDataStoredUnregistered /> : <WhereIsMyDataStoredRegistered />
);

WhereIsMyDataStored.propTypes = {
  isUnregisteredMode: bool,
};

WhereIsMyDataStored.defaultProps = {
  isUnregisteredMode: false,
};

export default WhereIsMyDataStored;
