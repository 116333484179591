//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
import vaultManager from './VTVaultManager';
import nodeSync from './VTNodeSync';
import Contact from './VTContact';
import ItemManager from './VTItemManager';

const { logger } = SymBfw;
const {
  isNil, isntNil, isEmptyObject, isSuccessCode, isArray
} = SymBfw.utils;

class ContactManager extends ItemManager {
  constructor() {
    super('ContactManager');
    this.items = {};
  }


  /**
    * @function addItem
    * @desc This function will add new contact into two nodes address and identity.
    * @param {Contact} item the contact item encapsulated both identity and address item.
    * @param {boolean} shouldSyncToCloud A boolean denoting
    * whether to sync the data to cloud or not. Defaults to true
    * */
  async addItem(item, shouldSyncToCloud = true) {
    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    identityManager.addItem(item.identityItem, false);
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    addressManager.addItem(item.addressItem, false);
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes([
        item.identityItem.clone().serializeNode(),
        item.addressItem.clone().serializeNode(),
      ], null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
  * @async
  * @function addItems
  * @desc This function will add new contacts into two nodes address and identity.
  * @param {Contact[]} items Array contact item encapsulated both identity and address item.
  * @param {boolean} shouldSyncToCloud A boolean denoting
  *         whether to sync the data to cloud or not. Defaults to true
  * @throws If any error occurs while adding the items
  * @validates items to be a valid and items doesn't already exist
  */
  async addItems(items, shouldSyncToCloud = true) {
    if (!isArray(items)) {
      throw new Error('Items must be an Array.');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    const nodeList = [];

    for (const item of items) {
      const identity = item.identityItem;
      const address = item.addressItem;
      identityManager.addItem(identity, false);
      addressManager.addItem(address, false);
      nodeList.push(identity.clone().serializeNode(), address.clone().serializeNode());
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
  * @async
  * @function updateItems
  * @desc This function will update existing contacts into two nodes address and identity.
  * @param {Contact[]} items Array contact item encapsulated both identity and address item.
  * @param {boolean} shouldSyncToCloud A boolean denoting
  *         whether to sync the data to cloud or not. Defaults to true
  * @throws If any error occurs while updating the items
  */
  async updateItems(items, shouldSyncToCloud = true) {
    if (!isArray(items)) {
      throw new Error('Items must be an Array.');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    const nodeList = [];

    for (const item of items) {
      const identity = item.identityItem;
      const address = item.addressItem;
      identityManager.updateItem(identity, false);
      addressManager.updateItem(address, false);
      nodeList.push(identity.clone().serializeNode(), address.clone().serializeNode());
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
    * @async
    * @function deleteItems
    * @desc Deletes all the guids from the Array
    * This function will delete the contact from two nodes address and identity.
    * the identity node key will be passed  as argument to delete identity node and correcponding address node will be deleted.
    * @param {boolean} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    * @return {Promise<void>}
    */

  async deleteItems(guids, shouldSyncToCloud = true) {
    if (!isArray(guids)) {
      throw new Error('guids must be an array.');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    const nodeList = [];
    for (const key in guids) {
      if (Object.prototype.hasOwnProperty.call(guids, key)) {
        const identityItem = identityManager.getItem(guids[key]);
        const addressGuid = identityItem.getAddressGuid();
        const addressItem = addressManager.getItem(addressGuid);
        if (isNil(addressItem) || isNil(identityItem)) {
          throw new Error('Invalid argument key to contactManager.deleteItems');
        }
        // eslint-disable-next-line no-await-in-loop
        await identityManager.removeTagItems([guids[key]], shouldSyncToCloud);
        // eslint-disable-next-line no-await-in-loop
        await addressManager.removeTagItems([addressGuid], shouldSyncToCloud);
        identityManager.deleteItem(guids[key], false);
        addressManager.deleteItem(addressGuid, false);
        const identityNode = identityItem.serializeNode(true);
        const addressNode = addressItem.serializeNode(true);
        nodeList.push(identityNode);
        nodeList.push(addressNode);
      }
    }
    if (nodeList.length === 0) {
      // nothing to delete.
      logger.error('No item found to delete');
      throw new Error('No item found to delete');
    }
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
    * @async
    * @function deleteAllItems
    * @desc Deletes all the contactItems
    * This function will delete the contact from two nodes address and identity.
    * the identity node key will be passed  as argument to delete identity node and corresponding address node will be deleted.
    * @param {boolean} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    * @return {Promise<void>}
    */

  async deleteAllItems(shouldSyncToCloud = true) {
    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }
    const guidList = Object.keys(this.getAllItems());
    await this.deleteItems(guidList, shouldSyncToCloud);
  }

  /**
    * @function updateItem
    * @desc This fucntion will update the contact into two nodes address and identity.
    * @param {Contact} item item.
    * @param {boolean} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async updateItem(item, shouldSyncToCloud = true) {
    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    identityManager.updateItem(item.identityItem, false);

    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    addressManager.updateItem(item.addressItem, false);
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes([
        item.identityItem.clone().serializeNode(),
        item.addressItem.clone().serializeNode(),
      ], null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
    * @function deleteItem
    * @desc This function will delete the contact from two nodes address and identity.
    * the  identity node key will be passed  as argument to delete identity node and correcponding address node will be deleted.
    * @param {string} guid the identity node guid.
    * @param {boolean} shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async deleteItem(guid, shouldSyncToCloud = true) {
    const identityManager = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER);
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    const identityItem = identityManager.getItem(guid);
    const addressGuid = identityItem.getAddressGuid();
    const addressItem = addressManager.getItem(addressGuid);
    if (isNil(addressItem) || isNil(identityItem)) {
      throw new Error('Invalid argument key to contactManager.deleteItem');
    }
    await identityManager.removeTagItems([guid], shouldSyncToCloud);
    await addressManager.removeTagItems([addressGuid], shouldSyncToCloud);
    identityManager.deleteItem(guid, false);
    addressManager.deleteItem(addressGuid, false);
    const identityNode = identityItem.serializeNode(true);
    const addressNode = addressItem.serializeNode(true);
    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes([identityNode, addressNode], null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }
  }

  /**
   * @async
   * @function search
   * @desc Searches the collection given the input key from UI.
   * @param {string} searchKey The key you want to search on.
   * @param {boolean} isFavourite if the isFavourite=ture then will search only favoite.
   * @returns {Promise<string[]>} Returns the items matches the key.
   */
  async search(searchKey, isFavourite) {
    const identityItems = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER).getAllItems();
    if (isEmptyObject(identityItems)) {
      logger.info('Empty identityItems');
      return null;
    }
    const addressManager = vaultManager.getItemManager(vaultManager.ADDRESS_MANAGER);
    const addressItems = addressManager.getAllItems();
    if (isEmptyObject(addressItems)) {
      logger.info('Empty addressItems');
      return null;
    }
    const addressBookItems = [];
    for (const key in identityItems) {
      if (Object.prototype.hasOwnProperty.call(identityItems, key)) {
        const identity = identityItems[key];
        if (isFavourite === true) {
          const isFavouriteItem = identity.getFavorite();
          if (isNil(isFavouriteItem) || isFavouriteItem === false) {
            // eslint-disable-next-line no-continue
            continue;
          }
        }
        const addressGuid = identity.getAddressGuid();
        const address = addressManager.getItem(addressGuid);
        if (isntNil(address)) {
          searchKey = searchKey.toLowerCase();
          let cardName = identity.getCardName();
          let city = address.getCity();
          let firstName = identity.getFirstName();
          cardName = cardName || '';
          city = city || '';
          firstName = firstName || '';
          if ((cardName.toLowerCase().includes(searchKey))
                          || (city.toLowerCase().includes(searchKey))
                         || (firstName.toLowerCase().includes(searchKey))) {
            addressBookItems.push(key);
          }
        }
      }
    }
    return addressBookItems;
  }

  /**
    * @function getItem
    * @desc This fucntion will retrun the contact item for the key that encapsulated identity and address nodes.
    * @param {string} key the key of identity item.
    * @return {Contact} the contactItem
    */
  getItem(key) {
    const contactItem = new Contact();
    contactItem.createItemFromKey(key);
    return contactItem;
  }

  /**
    * @function getAllItems
    * @desc This fucntion will retrun all the  contact item for the key that encapsulated identity and address nodes.
    * @return {object} the contactItem array
    */
  getAllItems() {
    this.items = {};
    const identityItems = vaultManager.getItemManager(vaultManager.IDENTITY_MANAGER).getAllItems();
    for (const key in identityItems) {
      if ({}.hasOwnProperty.call(identityItems, key)) {
        this.items[key] = this.getItem(key);
      }
    }
    return this.items;
  }
}

export default ContactManager;
