import { FILTER_ALL, FILTER_FAVORITES_DEFAULT, SORT_TYPE } from './MyDataHelper';

export const searchQueryTitle = (note, query) => (
  (note.title && note.title.toLowerCase().indexOf(query) >= 0)
);

export const searchQueryNote = (note, query) => (
  (note.notes && note.notes.toLowerCase().indexOf(query) >= 0)
);

export const sortOptions = {
  name: {
    sortKey: 'title',
    sortOrder: SORT_TYPE.ASC,
  },
  created: {
    sortKey: 'createdAt',
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
  modified: {
    sortKey: 'modifiedAt',
    sortOrder: SORT_TYPE.DESC,
    isDate: true,
  },
};

export const defaultSortBy = 'modified';

export const tags = [{
  id: FILTER_FAVORITES_DEFAULT,
  label: 'dashboard.accounts.showFavorites',
}, {
  id: FILTER_ALL,
  label: 'dashboard.accounts.showAll',
}];

export const defaultFilter = FILTER_ALL;
