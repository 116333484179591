//
//     Symantec copyright header start
//
// Copyright © 2021, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2021, Symantec Corporation, All rights reserved.
//
// Change History :
// Thu 9 2021 kalpana_m adding to Git
//

import ItemManager from './VTItemManager';
import constants from './VTConstants';

const {
  utils: {
    isNil,
    isntNil
  },
  logger
} = SymBfw;

const { TAG_NODE, TAG } = constants;
const tagNodeArray = [TAG_NODE.LOGIN, TAG_NODE.IDENTITY, TAG_NODE.ADDRESS,
  TAG_NODE.CREDIT_CARD, TAG_NODE.BANK_ACCOUNT, TAG_NODE.NOTE];

/**
 * @typedef {import('./VTTagItem').default} TagItem
 */

export default class TagItemManager extends ItemManager {
  /**
   * @constructor TagItemManager
   * @desc TagItemManager interface.
   */
  constructor() {
    super('TagItemManager');
    this.items = new Map();
  }

  /**
    * @function isItemExists
    * @param {string} tagGuid
    * @param {string} itemGuid
    * @param {string} itemType
    * @returns {boolean}
    */
  isItemExists(tagGuid, itemGuid, itemType) {
    const item = this.getItem(tagGuid);
    let nodeType = null;
    if (isNil(item)) {
      return false;
    }
    if (tagNodeArray.includes(itemType)) {
      switch (itemType) {
        case TAG_NODE.LOGIN:
          nodeType = TAG.LOGIN;
          break;
        case TAG_NODE.IDENTITY:
          nodeType = TAG.IDENTITY;
          break;
        case TAG_NODE.ADDRESS:
          nodeType = TAG.ADDRESS;
          break;
        case TAG_NODE.CREDIT_CARD:
          nodeType = TAG.CREDIT_CARD;
          break;
        case TAG_NODE.BANK_ACCOUNT:
          nodeType = TAG.BANK_ACCOUNT;
          break;
        case TAG_NODE.NOTE:
          nodeType = TAG.NOTE;
          break;
        default:
          break;
      }
      if (isntNil(nodeType)) {
        itemType = nodeType;
      }
    }
    for (const key in item) {
      for (const i in item[itemType]) {
        if (item[itemType][i].getTagItem() === itemGuid) {
          return true;
        }
      }
    }
    return false;
  }

  /**
  * @function getItem
  * @desc returns item by guid
  * @param {string} guid
  * @returns {TagItem} tag item
  */
  getItem(guid) {
    return this.items.get(guid);
  }

  /**
   * To make getAllItems return type consistent across all Managers
   * @function getAllItems
   * @desc Returns the entire collection as is.
   * @returns {object} Returns the entire items collection that is owned by the current manager.
   */
  getAllItems() {
    return Object.fromEntries(this.items.entries());
  }

  /**
    * @function addItem
    * @desc addItem to the manager's collection
    * @param {TagItem} _item The item you want to add.
    * @param {boolean} _shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async addItem(_item, _shouldSyncToCloud = true) {
    throw new Error('addItem not supported');
  }


  /**
    * @function addItem
    * @desc add Item to the manager's collection only after getting synced from O2
    * @param {TagItem} item The item you want to add.
    * @param {string} nodeId
    */
  addTagItem(item, nodeId) {
    if (isNil(item)) {
      throw new Error('Item cannot be null');
    }
    const guid = item.getPath();
    if (isNil(guid)) {
      logger.error('GUID is not found. Item has not been created correctly');
      return;
    }

    /**
     * @type {Object.<string, TagItem[]>}
     */
    const tagItems = {
      login: [],
      identity: [],
      address: [],
      creditCard: [],
      bankAccount: [],
      note: []
    };

    switch (nodeId) {
      case TAG_NODE.LOGIN:
        tagItems.login.push(item);
        break;
      case TAG_NODE.IDENTITY:
        tagItems.identity.push(item);
        break;
      case TAG_NODE.ADDRESS:
        tagItems.address.push(item);
        break;
      case TAG_NODE.CREDIT_CARD:
        tagItems.creditCard.push(item);
        break;
      case TAG_NODE.BANK_ACCOUNT:
        tagItems.bankAccount.push(item);
        break;
      case TAG_NODE.NOTE:
        tagItems.note.push(item);
        break;
      default:
        break;
    }

    this.items.set(guid, tagItems);
  }

  /**
    * @function updateItem
    * @desc NOT IMPLEMENTED
    * @param {TagItem} _item The item you want to update.
    * @param {boolean} _shouldSyncToCloud lets us know
    * if we should sync this item to the cloud. Defaults to true.
    */
  async updateItem(_item, _shouldSyncToCloud = true) {
    throw new Error('updateItem not supported');
  }

  /**
    * @function updateItem
    * @desc update Item to the manager's collection only after getting synced from O2
    * @param {TagItem} item The item you want to add.
    * @param {string} nodeId
    */
  updateTagItem(item, nodeId) {
    if (isNil(item)) {
      throw new Error('Item cannot be null');
    }

    const guid = item.getPath();
    if (isNil(guid)) {
      logger.error('GUID is not found. Item has not been created correctly');
      return;
    }
    switch (nodeId) {
      case TAG_NODE.LOGIN:
        this.items.get(guid).login.push(item);
        break;
      case TAG_NODE.IDENTITY:
        this.items.get(guid).identity.push(item);
        break;
      case TAG_NODE.ADDRESS:
        this.items.get(guid).address.push(item);
        break;
      case TAG_NODE.CREDIT_CARD:
        this.items.get(guid).creditCard.push(item);
        break;
      case TAG_NODE.BANK_ACCOUNT:
        this.items.get(guid).bankAccount.push(item);
        break;
      case TAG_NODE.NOTE:
        this.items.get(guid).note.push(item);
        break;
      default:
        break;
    }
  }

  /**
    * @function deleteItem
    * @desc overriding super class deleteItem
    * restricting the client to access deleteItem
    */

  async deleteItem(guid, shouldSyncToCloud = true) {
    throw new Error('deleteItem not supported');
  }

  /**
    * @function deleteTagItem
    * @desc removes TagItem from items list
    * @param {string} guid
    */
  deleteTagItem(guid) {
    this.items.delete(guid);
  }
}
