import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { checkUserExists } from '../../user/UserActions';
import Loading from './Loading';
import { State as OEState } from '../../oe/OEReducer';
import { setError } from '../../dashboard/DashboardActions';

type State = {
  oe: OEState;
  render: JSX.Element;
}

type Props = {
  redirectOnly?: boolean;
} & RouteProps;

const UserExistsRoute: React.FC<Props> = ({ redirectOnly, path, render }) => {
  const token = useSelector((state: State) => state.oe.token);
  const [loading, setLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const protectRoutes = async (): Promise<void> => {
      try {
        setUserExists(await dispatch(checkUserExists(token)) as any);
        setLoading(false);
      } catch (e) {
        dispatch(setError({ messageId: e.key, context: 'UserExistsRoute', error: e.error }));
      }
    };
    protectRoutes();
  }, [token, dispatch]);

  if (loading) {
    return (
      <Loading
        onRetry={() => window.location.reload()}
      >
        <FormattedMessage
          id="dashboard.userExisitsRoute.loading"
          defaultMessage="Checking for password manager account..."
        />
      </Loading>
    );
  }

  const redirectPath = userExists ? '/enter-master-password' : '/create-master-password';

  if (redirectOnly || redirectPath !== path) {
    return (
      <Redirect
        to={{
          pathname: redirectPath,
        }}
      />
    );
  }

  return (
    <Route
      path={path}
      render={render}
    />
  );
};

export default UserExistsRoute;
