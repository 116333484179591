import React from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import config from '../../config';
import { trackGetMobileShown } from '../MobileActions';
import MobilePage from './MobilePage';

type Props = {
  path: string;
};

const MobilePageContainer: React.FC<Props> = ({ path }) => {
  const dispatch = useDispatch();

  return (
    <Switch>
      <Route
        path={`${path}/banner`}
        render={() => {
          dispatch(trackGetMobileShown('Banner'));
          return <Redirect to={`${path}`} />;
        }}
      />
      <Route
        path={`${path}/navigation`}
        render={() => {
          dispatch(trackGetMobileShown('Navigation Menu'));
          return <Redirect to={`${path}`} />;
        }}
      />
      <Route
        path={`${path}`}
        exact
      >
        <MobilePage url={config.getMobileLink} />
      </Route>
    </Switch>
  );
};

export default MobilePageContainer;
