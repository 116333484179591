import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { getDevices, logOutDevice } from '../ConnectedDevicesActions';

import ConnectedDevices from './ConnectedDevices';

const mapStateToProps = (
  { user, devices, dashboard: { isUnregisteredMode } },
) => ({ user, devices, isUnregisteredMode });

const mapDispatchToProps = dispatch => bindActionCreators({ getDevices, logOutDevice }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedDevices);
