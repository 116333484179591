import React from 'react';
import { string } from 'prop-types';
import ProtectedRoute from './ProtectedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import AuthorizedRoute from './AuthorizedRoute';

const LockedRoute = ({ path, ...otherProps }) => (
  <AuthenticatedRoute
    path={path}
    render={() => (
      <AuthorizedRoute
        path={path}
        render={() => (
          <ProtectedRoute
            path={path}
            requirement={({ user }) => !user.key}
            redirectPath="/mydata/passwords"
            {...otherProps}
          />
        )}
      />
    )}
  />
);

LockedRoute.propTypes = {
  path: string.isRequired,
};

export default LockedRoute;
