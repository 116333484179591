import * as Sentry from '@sentry/browser';
import responseCodes from '@avira-pwm/services/responseCodes';

import OCLError from '@avira-pwm/sync/adapters/NDSAdapter/helpers/OCLError';
import ErrorDescriptions from './ErrorDescriptions';
import mixpanel from '../tracking/mixpanel';

const isRequestServiceType = e => e.code !== undefined && e.status !== undefined;

const isOAuthType = e => e && e.error !== undefined && e.error_description !== undefined;

const isUsersType = e => e && e.errors !== undefined && e.errors.length > 0;

const networkErrorCodes = [
  'Failed to fetch', // Chrome
  'NetworkError when attempting to fetch resource', // Firefox
  'Network request failed', // Fetch polyfill
];


export const mapErrorCode = (e) => {
  let errorCode;

  if (typeof e === 'string') {
    errorCode = e;
  } else if (isRequestServiceType(e)) {
    errorCode = e.code;
  } else if (isOAuthType(e)) {
    errorCode = e.error;
  } else if (isUsersType(e)) {
    const messages = e.errors.map(obj => obj.detail);

    if (messages.length > 0) {
      const [errorMsg] = messages;
      errorCode = errorMsg;
      // TODO: More than one error message delievered - deal with it.
    }
  } else if (e instanceof Error) {
    return e.message;
  }

  return errorCode;
};

export const getErrorCode = (e) => {
  const errorCode = mapErrorCode(e);

  if (ErrorDescriptions[errorCode]) {
    return errorCode;
  }

  return 'error.unknown';
};

export const getErrorDescription = code => (ErrorDescriptions[code] || ErrorDescriptions['error.unknown']);

export const getError = (e) => {
  const code = getErrorCode(e);
  const description = getErrorDescription(code);

  return {
    ...description,
    error: e,
  };
};

export const isNetworkError = (e) => {
  const errorCode = mapErrorCode(e);
  return networkErrorCodes.some(code => errorCode.indexOf(code) !== -1);
};

export const isOETimeoutError = (e) => {
  const errorCode = mapErrorCode(e);

  if (!errorCode) {
    return false;
  }

  return errorCode === responseCodes.license.EXCEPTION_TIMEOUT_ERROR
    || errorCode === responseCodes.license.TIMEOUT_ERROR
    || errorCode === responseCodes.oe.OE_TIMEOUT_ERROR;
};

/**
 * @typedef {import('@avira-pwm/sync/ISync').SyncErrorMap} SyncErrorMap
 */

/**
 * @param {SyncErrorMap} error
 */
function isDSSizeError(error) {
  return error.adapter.name === 'NDSAdapter'
    && error.error instanceof OCLError
    // TODO: yes, this structure sucks; fix it
    && (error.error.error.status === 403 || error.error.error.status === 413);
}

/**
 * @param {any} e
 * @param {string} context
 * @returns {void}
 */
// eslint-disable-next-line complexity
export function handleSyncError(e, context) {
  if (e instanceof Error) {
    throw e;
  }

  if (Array.isArray(e)) {
    const failedAdapters = new Set();

    for (const syncError of /** @type {SyncErrorMap[]} */(e)) {
      if (syncError.adapter && syncError.error) {
        Sentry.withScope((scope) => {
          scope.setExtra('context', context);
          scope.setExtra('adapter', syncError.adapter.name);
          Sentry.captureException(syncError.error);
        });

        failedAdapters.add(syncError.adapter.name);

        if (isDSSizeError(syncError)) {
          mixpanel.track('NDSError', {
            error: syncError.error.message,
            errorStatus: syncError.error.error.status,
          });
        }
      }
    }

    const indexedDBFailed = failedAdapters.has('IndexedDBAdapter');
    const cognitoAdapterFailed = failedAdapters.has('CognitoAdapter');
    const extensionAdapterFailed = failedAdapters.has('ExtensionAdapter');

    if (indexedDBFailed && (cognitoAdapterFailed || extensionAdapterFailed)) {
      throw new Error(`failed to sync on ${context}`);
    }
  }
}
