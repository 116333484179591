// eslint-disable-next-line import/prefer-default-export
export async function installFirefoxExtension(id, hash) {
  const params = {
    'Avira Password Manager': {
      URL: `https://addons.mozilla.org/firefox/downloads/latest/${id}`,
      Hash: `sha256:${hash}`,
      toString() {
        return this.URL;
      },
    },
  };

  window.InstallTrigger.install(params);
}
