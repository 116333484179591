import { FileType } from './FileTypeHelper';

export function truncateMiddle(s: string, maxLength: number): string {
  if (s.length <= maxLength) return s;

  const separator = '...';
  const charsToShow = maxLength - separator.length;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return s.substr(0, frontChars)
         + separator
         + s.substr(s.length - backChars);
}

export const MainSuffixes: { [K in FileType]?: string } = {
  [FileType.Image]: 'fullpre',
};

export function getFileName(id: string, suffix?: string): string {
  return suffix ? `${id}_${suffix}` : id;
}
