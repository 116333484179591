import React from 'react';
import { FormattedMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Button from 'pwm-components/components/Button';

type props = {
  onNextClick: () => void;
  onBackClick: () => void;
}

const SpotlightOnboardingExistingUser: React.FC<props> = ({ onNextClick, onBackClick }) => (
  <>
    <Paragraph mb="m" size="h2">
      <FormattedMessage
        id="spotlightOnboardingExistingUser.headline"
        defaultMessage="Welcome to your integrated Password Manager"
      />
    </Paragraph>
    <Box display="flex" flexWrap="wrap" justifyContent="center" mb="xxl">
      <Paragraph noWrap textAlign="center">
        <FormattedMessage
          id="spotlightOnboardingExistingUser.connect"
          defaultMessage="Continue with just a few steps to connect your browser extensions and see all your passwords"
        />
      </Paragraph>
    </Box>
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box mr="m">
        <Button
          fullWidth
          onClick={onNextClick}
        >
          <FormattedMessage
            id="spotlightOnboardingConainer.next"
            defaultMessage="Next"
          />
        </Button>
      </Box>
      <Box>
        <Button
          variant="secondary"
          onClick={onBackClick}
        >
          <FormattedMessage
            id="spotlightOnboardingConainer.back"
            defaultMessage="Back"
          />
        </Button>
      </Box>
    </Box>
  </>
);

export default SpotlightOnboardingExistingUser;
