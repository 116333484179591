import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { withTooltip } from 'pwm-components/components/Tooltip';
import Box from 'pwm-components/components/Box';
import PasswordGoodIcon from 'pwm-components/icons/PasswordGood';
import PasswordWeakIcon from 'pwm-components/icons/PasswordWeak';
import PasswordMediumIcon from 'pwm-components/icons/PasswordMedium';
import PasswordStrongIcon from 'pwm-components/icons/PasswordStrong';
import Paragraph from 'pwm-components/components/Paragraph';
import { getTimeToHack } from '../../accounts/components/passwordStrengthHelper';

const actions = [
  'dashboard.securityStatus.tooltip.action.changePassword',
  'dashboard.securityStatus.tooltip.action.changePassword',
  'dashboard.securityStatus.tooltip.action.changePassword',
  'dashboard.securityStatus.tooltip.action.moreSecure',
  '',
];

const headers = [
  'dashboard.securityStatus.tooltip.header.weakPassword',
  'dashboard.securityStatus.tooltip.header.weakPassword',
  'dashboard.securityStatus.tooltip.header.mediumPassword',
  'dashboard.securityStatus.tooltip.header.goodPassword',
  'dashboard.securityStatus.tooltip.header.strongPassword',
];

const messages = [
  'dashboard.securityStatus.tooltip.body.weakPassword',
  'dashboard.securityStatus.tooltip.body.weakPassword',
  'dashboard.securityStatus.tooltip.body.mediumPassword',
  'dashboard.securityStatus.tooltip.body.goodPassword',
  'dashboard.securityStatus.tooltip.body.strongPassword',
];

const icons = [
  PasswordWeakIcon,
  PasswordWeakIcon,
  PasswordMediumIcon,
  PasswordGoodIcon,
  PasswordStrongIcon,
];

type Data = {
  passwordScore: number;
  passwordTimeToCrack: number;
}

type Props = WrappedComponentProps & {
  data: Data;
  ignored: boolean;
}

const PasswordStrength: React.FC<Props> = ({
  ignored,
  intl,
  data,
}) => {
  const timeToHack = getTimeToHack(data.passwordTimeToCrack);
  const timeUnits = intl.formatMessage({ id: timeToHack.message });
  const header = intl.formatMessage({ id: headers[data.passwordScore] });
  const getMessage = (): string => ((timeToHack.message === 'dashboard.account.details.yearsToHack.ageOfTheUniverse.1')
    ? (
      intl.formatMessage({ id: 'dashboard.securityStatus.tooltip.body.unhackable' })
    ) : (
      intl.formatMessage(
        { id: messages[data.passwordScore] },
        { time: `${timeToHack.displayTime} ${timeUnits}` },
      )
    ));

  const getAction = (): string => (actions[data.passwordScore]
    ? intl.formatMessage({ id: actions[data.passwordScore] })
    : ''
  );

  const IconWithTooltip = withTooltip(icons[data.passwordScore]);

  type variant = 'disabled' | 'alert' | 'warning' | 'success';

  const getVariant = (): variant => {
    if (ignored) {
      return 'disabled';
    }
    if (data.passwordScore < 2) {
      return 'alert';
    }
    if (data.passwordScore === 2) {
      return 'warning';
    }
    return 'success';
  };


  return (
    <IconWithTooltip
      variant={getVariant()}
      noDelay
      bigPadding
      tooltipContent={(
        <Box textAlign="left">
          <Paragraph>
            <strong>
              {header}
            </strong>
          </Paragraph>
          <Paragraph mt="xs">
            {getMessage()}
          </Paragraph>
          <Paragraph mt="xs">
            {getAction()}
          </Paragraph>
        </Box>
      )}
    />
  );
};

export default injectIntl(PasswordStrength);
