import React from 'react';
import {
  FormattedMessage, injectIntl, WrappedComponentProps,
} from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import {
  defaultSortBy, sortOptions, searchQueryTitle, searchQueryNote, defaultFilter,
} from '../../lib/NoteHelper';

import NoteListItemContainer from './NoteListItemContainer';

import confirmDeleteIcon from '../../img/icon-delete-big.png';
import { MyDataContainer } from '../../myData';
import { ExtensionCardWrapper } from '../../extension';
import RegisterBanner from '../../componentLib/RegisterBanner';
import FilesBanner from '../../files/components/FilesBanner';
import { withFileManager, FileManagerProps } from '../../files/FileManagerContext';
import GetMobileBanner from '../../mobile/components/GetMobileBanner';

type Props = WrappedComponentProps & FileManagerProps & {
  authToken: string;
  notes: any;
  notePreferences: {
    scrollPosition: number;
  };
  preferences: {
    notesSortBy?: string;
  };
  tags?: Array<string>;
  isUnregisteredMode: boolean;
  canDisplayBanners: boolean;
  showGetMobileBanner: boolean;
  showExtBanner: boolean;
  showFilesBanner: boolean;
  disallowDataUsage: boolean;
  getDevices: (authToken: string) => void;
  setDevicesLoaded: () => void;
  setScrollPosition: () => void;
  onSortByChange: () => void;
  onDeleteClick: (id: string) => void;
  onDeleteFile: (id: string, config?: FileManagerProps) => void;
};

type State = {
  loading: boolean;
  showDeleteConfirmDialog: boolean;
  noteIdToDelete: string;
}

class NotesPage extends React.Component<Props, State> {
  public static defaultProps = {
    tags: [],
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      showDeleteConfirmDialog: false,
      noteIdToDelete: '',
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteCancel = this.onDeleteCancel.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.getAllDevices = this.getAllDevices.bind(this);
  }

  // eslint-disable-next-line max-len
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility, @typescript-eslint/explicit-function-return-type
  componentDidMount() {
    this.getAllDevices();
  }

  public onDeleteClick(id: string): void {
    this.setState({ showDeleteConfirmDialog: true, noteIdToDelete: id });
  }

  public async onDeleteConfirm(): Promise<void> {
    const {
      notes, fileManager, fileManagerActions, onDeleteClick, onDeleteFile,
    } = this.props;
    const { noteIdToDelete } = this.state;
    const { files } = notes[noteIdToDelete];

    this.setState({ loading: true }, async () => {
      await onDeleteClick(noteIdToDelete);
      Object.keys(files).forEach(id => onDeleteFile(id, { fileManager, fileManagerActions }));
      this.setState({ showDeleteConfirmDialog: false, loading: false });
    });
  }

  public onDeleteCancel(): void {
    this.setState({ showDeleteConfirmDialog: false });
  }

  public async getAllDevices(): Promise<void> {
    const { getDevices, authToken, setDevicesLoaded } = this.props;
    await getDevices(authToken);
    setDevicesLoaded();
  }

  public render(): JSX.Element {
    const {
      notes,
      disallowDataUsage,
      setScrollPosition,
      notePreferences,
      tags,
      preferences,
      onSortByChange,
      canDisplayBanners,
      showGetMobileBanner,
      showExtBanner,
      showFilesBanner,
      isUnregisteredMode,
      intl,
    } = this.props;
    const {
      showDeleteConfirmDialog,
      loading,
    } = this.state;

    let banner = null;
    if (canDisplayBanners) {
      if (isUnregisteredMode) {
        banner = <RegisterBanner />;
      } else if (showExtBanner) {
        banner = <ExtensionCardWrapper page="notes" />;
      } else if (showGetMobileBanner) {
        banner = <GetMobileBanner />;
      } else if (showFilesBanner) {
        banner = <FilesBanner />;
      }
    }

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      <MyDataContainer
        id="a-notes-list-container"
        header={<FormattedMessage id="dashboard.myData.notes" />}
        list={notes}
        disable={disallowDataUsage}
        listItem={
          <NoteListItemContainer onDeleteClick={this.onDeleteClick} />
        }
        afterScroll={setScrollPosition}
        scrollPosition={notePreferences.scrollPosition}
        emptyListMessage="dashboard.notes.empty"
        emptySearchMessage="dashboard.notes.search.empty"
        searchQueryMain={searchQueryTitle}
        searchQuerySub={searchQueryNote}
        defaultFilter={defaultFilter}
        tags={tags}
        sortBy={preferences.notesSortBy}
        defaultSortBy={defaultSortBy}
        onSortByChange={onSortByChange}
        sortOptions={sortOptions}
        add="/mydata/notes/new"
        pageBanner={banner}
      >
        <ConfirmationDialog
          show={showDeleteConfirmDialog}
          confirmButtonType="alert"
          confirmLabel={intl.formatMessage({
            id: 'dashboard.note.details.delete',
            defaultMessage: 'Delete',
          })}
          cancelLabel={intl.formatMessage({
            id: 'dashboard.note.details.cancel',
            defaultMessage: 'Cancel',
          })}
          onConfirm={this.onDeleteConfirm}
          onCancel={this.onDeleteCancel}
          loading={loading}
        >
          <Box mb="l">
            <Paragraph mb="l" textAlign="center">
              <img src={confirmDeleteIcon} alt="Delete Note" />
            </Paragraph>
            <FormattedMessage
              id="dashboard.note.details.confirmDelete"
              defaultMessage="Are you sure you want to delete this note?"
            />
          </Box>
        </ConfirmationDialog>
      </MyDataContainer>
    );
  }
}

export default injectIntl(withFileManager(NotesPage));
