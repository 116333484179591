import React, { useMemo } from 'react';
import { FormattedMessage, WrappedComponentProps } from 'react-intl';
import Box from 'pwm-components/components/Box';
import PasswordUniqueIcon from 'pwm-components/icons/PasswordUnique';
import PasswordReused2Icon from 'pwm-components/icons/PasswordReused2';
import PasswordReused3Icon from 'pwm-components/icons/PasswordReused3';
import PasswordReused4Icon from 'pwm-components/icons/PasswordReused4';
import PasswordReused5Icon from 'pwm-components/icons/PasswordReused5';
import PasswordReused6Icon from 'pwm-components/icons/PasswordReused6';
import PasswordReused7Icon from 'pwm-components/icons/PasswordReused7';
import PasswordReused8Icon from 'pwm-components/icons/PasswordReused8';
import PasswordReused9Icon from 'pwm-components/icons/PasswordReused9';
import PasswordReused9PlusIcon from 'pwm-components/icons/PasswordReused9Plus';
import { withTooltip } from 'pwm-components/components/Tooltip';
import Paragraph from 'pwm-components/components/Paragraph';

type Data = {
  reused: boolean;
  reusedCount: number;
}

type Props = WrappedComponentProps & {
  data: Data;
  ignored: boolean;
}

const headers = [
  'dashboard.securityStatus.tooltip.header.uniquePassword',
  'dashboard.securityStatus.tooltip.header.reusedPassword',
];

const messages = [
  'dashboard.securityStatus.tooltip.body.uniquePassword',
  'dashboard.securityStatus.tooltip.body.reusedPassword',
];

const icons = [
  PasswordUniqueIcon,
  PasswordUniqueIcon,
  PasswordReused2Icon,
  PasswordReused3Icon,
  PasswordReused4Icon,
  PasswordReused5Icon,
  PasswordReused6Icon,
  PasswordReused7Icon,
  PasswordReused8Icon,
  PasswordReused9Icon,
  PasswordReused9PlusIcon,
].map(withTooltip);

const UniquePassword: React.FC<Props> = ({
  ignored,
  data,
}) => {
  const passwordCount = Math.min(data.reusedCount ?? 1, 10);
  const IconWithToolTip = icons[passwordCount];

  type Variant = 'disabled' | 'alert' | 'warning' | 'success';

  const variant: Variant = useMemo(() => {
    if (ignored) {
      return 'disabled';
    }
    if (data.reused) {
      return 'alert';
    }

    return 'success';
  }, [ignored, data.reused]);

  return (
    <IconWithToolTip
      variant={variant}
      noDelay
      bigPadding
      tooltipContent={(
        <Box textAlign="left">
          <Paragraph>
            <strong>
              <FormattedMessage
                id={headers[data.reused ? 1 : 0]}
                values={{
                  number: data.reusedCount ?? 1,
                }}
              />
            </strong>
          </Paragraph>
          <Paragraph mt="xs">
            <FormattedMessage id={messages[data.reused ? 1 : 0]} />
          </Paragraph>
          <Paragraph mt="xs">
            {
              data.reused
                ? (
                  <FormattedMessage
                    id="dashboard.securityStatus.tooltip.action.reusedPassword"
                  />
                )
                : null
            }
          </Paragraph>
        </Box>
      )}
    />
  );
};


export default UniquePassword;
