import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { trackEvent } from '../tracking/TrackingActions';
import {
  SET_LOADED_DEVICES,
  MobileActionType,
} from './MobileActionTypes';

export const setDevicesLoaded = (): ThunkAction<void, {}, {}, MobileActionType> => (dispatch) => {
  dispatch({ type: SET_LOADED_DEVICES });
};

export const trackGetMobileShown = (
  source: string,
): ThunkAction<void, {}, {}, AnyAction> => (dispatch) => {
  dispatch(trackEvent('GetMobileShown', {
    platform: 'Dashboard',
    source,
  }));
};
