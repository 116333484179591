import React, { useState } from 'react';
import { useStore } from 'react-redux';
import { FormattedMessage, injectIntl, WrappedComponentProps } from 'react-intl';
import { Link } from 'react-router-dom';
import Papa from 'papaparse';

import Box from 'pwm-components/components/Box';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import Paragraph from 'pwm-components/components/Paragraph';
import TextButton from 'pwm-components/components/TextButton';

import Card from '../../componentLib/CardWrapper';

import { exportEntriesSelector } from '../selectors';

type Props = WrappedComponentProps;

const ImportExport: React.FC<Props> = ({
  intl,
}) => {
  const [showExportConfirm, setShowExportConfirm] = useState(false);
  const store = useStore();
  const { accounts, notes } = store.getState();

  function exportAccounts(): void {
    const dataToExport = exportEntriesSelector({ accounts, notes });

    const link = document.createElement('a');
    const file = new Blob(
      [Papa.unparse(dataToExport)],
      { type: 'text/plain' },
    );

    const now = new Date();

    link.href = URL.createObjectURL(file);
    link.style.display = 'none';
    link.download = `Avira_Export-${
      now.getFullYear()
    }_${
      (`0${now.getMonth() + 1}`).slice(-2)
    }_${
      now.getDate()
    }.csv`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setShowExportConfirm(false);
  }

  return (
    <Card
      title={(
        <FormattedMessage id="dashboard.settings.myData" />
      )}
      p="0"
    >
      <Box
        px="m"
        py="s"
        borderColor="gainsboro"
        borderBottom
      >
        <Link to="/settings/import?context=settings">
          <FormattedMessage id="dashboard.settings.myData.import" />
        </Link>
      </Box>
      <Box
        px="m"
        py="s"
      >
        <TextButton
          variant="link"
          onClick={() => { setShowExportConfirm(true); }}
        >
          <FormattedMessage id="dashboard.settings.myData.export" />
        </TextButton>
      </Box>
      <ConfirmationDialog
        show={showExportConfirm}
        confirmLabel={intl.formatMessage({ id: 'dashboard.settings.export.popup.export' })}
        cancelLabel={intl.formatMessage({ id: 'dashboard.settings.export.popup.cancel' })}
        onConfirm={exportAccounts}
        onCancel={() => { setShowExportConfirm(false); }}
        cancelButtonType="secondary"
        confirmButtonType="primary"
      >
        <Paragraph mb="l" textAlign="center">
          <FormattedMessage
            id="dashboard.settings.export.popup.message"
          />
        </Paragraph>
      </ConfirmationDialog>
    </Card>
  );
};

export default injectIntl(ImportExport);
