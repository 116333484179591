import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import queryString from 'query-string';

import styled from 'pwm-components/styled';
import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import Box from 'pwm-components/components/Box';
import Input from 'pwm-components/components/Input';
import PasswordInput from 'pwm-components/components/PasswordInput';
import TextButton from 'pwm-components/components/TextButton';

import Paragraph from 'pwm-components/components/Paragraph';
import Auth from './Auth';
import { validatePasswordStrength } from '../../lib/AuthenticationValidator';
import GoogleLogin from '../../componentLib/GoogleLogin';
import FacebookLogin from '../../componentLib/FacebookLogin';
import config from '../../config';
import AppleLogin from '../../componentLib/AppleLogin';
import intlShape from '../../lib/intlShape';

const HeaderSpacer = styled(Box)`
  height: 80px;

  @media screen and (max-width: 480px) {
    height: 40px;
  }
`;

class Register extends Auth {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.trackOnShown = this.trackOnShown.bind(this);
  }

  trackOnShown() {
    const { location, onComponentShown } = this.props;
    let source = null;
    if (location) {
      const query = queryString.parse(location.search);
      source = query.dashboardContext;
    }
    onComponentShown('register', source);
  }

  tryLogin() {
    const { onTryLogin } = this.props;
    this.submitAttempt(onTryLogin);
  }

  async onSubmit(e) {
    e.preventDefault();

    const { onFormSubmit } = this.props;
    this.submitAttempt(onFormSubmit);
  }

  // eslint-disable-next-line complexity
  render() {
    const ps = validatePasswordStrength(this.state.password) || {};
    const feedback = this.authHelper.prepareList(ps.feedback);
    let error = null;

    if (this.state.error) {
      error = this.authHelper.getError(this.state.error);
      if (this.state.error.key === 'error.register.emailExists') {
        const { message } = error;
        error.message = (
          <TextButton
            onClick={(e) => {
              e.preventDefault();
              this.tryLogin();
            }}
          >
            <span
              dangerouslySetInnerHTML={{ __html: message }}
            />
          </TextButton>
        );
      }
    }

    return (
      <>
        <HeaderSpacer />

        <form
          id="form-section"
          className="u-mb-xs"
          onSubmit={this.onSubmit}
        >
          <div className="u-mb-s">
            <Input
              ref={(el) => { this.focusElement = el; }}
              placeholder={this.authHelper.getMessage('authEmailPlaceholder')}
              value={this.state.email}
              onChange={e => this.setEmailState(e.target.value)}
              error={error && error.config.email ? error.message : null}
              id="a-register-email"
            />
          </div>
          <div className="u-mb-xl">
            <PasswordInput
              placeholder={this.authHelper.getMessage('authPasswordPlaceholder')}
              value={this.state.password}
              autoComplete="new-password"
              onChange={e => this.setState({ password: e.target.value, error: null })}
              onFocus={() => this.setState({ passwordFocused: true })}
              onBlur={() => this.setState({ passwordFocused: false })}
              error={error && error.config.password ? error.message : null}
              feedback={this.state.password.length ? feedback : undefined}
              score={this.state.password.length ? ps.score : undefined}
              id="a-register-password"
            />
          </div>

          <Paragraph variant="dark" size="mini" textAlign="left" mb="l">
            <FormattedHTMLMessage
              id="auth.legalStatement"
            />
          </Paragraph>

          <div>
            <Button
              disabled={!!this.state.error
                || !this.state.email
                || !this.state.password}
              loading={this.state.loading}
              variant="login"
              style={{ width: '100%' }}
              id="a-register-action"
            >
              <FormattedMessage
                id="register.action.next"
                defaultMessage="Next"
              />
            </Button>
          </div>
        </form>

        {config.allowSocialMediaLogin
          && (
            <div>
              <Box
                mt="m"
                mb="m"
              >
                <Text variant="dark">
                  <FormattedMessage
                    id="register.registerOrLoginWith"
                    defaultMessage="Or with:"
                  />
                </Text>
              </Box>
              <Box
                display="flex"
                height="49px"
                justifyContent="space-between"
              >
                <AppleLogin
                  clientId={this.appleClientId}
                  onClick={() => this.setState({ loading: true })}
                  onSuccess={this.authenticateWithSocialMedia}
                  onError={this.handleSocialMediaAuthenticationError}
                  loading={this.state.loading}
                />
                <FacebookLogin
                  clientId={this.facebookAppId}
                  onClick={() => this.setState({ loading: true })}
                  onSuccess={this.authenticateWithSocialMedia}
                  onError={this.handleSocialMediaAuthenticationError}
                  loading={this.state.loading}
                  locale={this.props.language}
                />
                <GoogleLogin
                  clientId={this.googleClientId}
                  onClick={() => this.setState({ loading: true })}
                  onSuccess={this.authenticateWithSocialMedia}
                  onError={this.handleSocialMediaAuthenticationError}
                  loading={this.state.loading}
                />
              </Box>
            </div>
          )}
      </>
    );
  }
}

Register.propTypes = {
  ...Auth.propTypes,
  intl: intlShape.isRequired,
  onClick: PropTypes.func,
  oe: PropTypes.shape({
    error: PropTypes.shape({
      key: PropTypes.string,
      status: PropTypes.string,
    }),
    pending: PropTypes.bool.isRequired,
  }),
  context: PropTypes.string,
  ftuABTestVersion: PropTypes.string,
};

Register.defaultProps = {
  ...Auth.defaultProps,
  onClick: () => 0,
  oe: {
    error: null,
    pending: false,
  },
  context: null,
  ftuABTestVersion: null,
};

export default injectIntl(Register);
