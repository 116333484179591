import React, { PropsWithChildren } from 'react';
import { WithLastLocationProps } from 'react-router-last-location';
import { Prompt } from 'react-router-dom';
import { FormattedMessage, FormattedHTMLMessage, WrappedComponentProps } from 'react-intl';

import ServerDate from '@avira-pwm/helpers/ServerDate';
import styled from 'pwm-components/styled';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Input from 'pwm-components/components/Input';
import PasswordInput from 'pwm-components/components/PasswordInput';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import InfoCircleIcon from 'pwm-components/icons/InfoCircle';
import KeyIcon from 'pwm-components/icons/Key';

import { ModelNames } from '@avira-pwm/sync/ModelMerge';
import { MaxLengths, DataStructureV1orV2 } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';
import { MAX_LENGTHS as ACCOUNT_MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/Account';
import { MAX_LENGTHS as NOTE_MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/Note';
import { MAX_LENGTHS as CREDIT_CARD_MAX_LENGTHS } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import confirmDeleteIcon from '../../img/icon-delete-big.png';
import upgradeToPro from '../../img/icon-upgrade-to-pro@1x.png';
import upgradeToPro2x from '../../img/icon-upgrade-to-pro@2x.png';

import tabIndex from '../../lib/TabIndexConfig';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import Card from '../../componentLib/CardWrapper';
import AuthenticationHelper from '../../lib/AuthenticationHelper';
import { generateID } from '../../lib/AccountHelper';
import { sortByHelper } from '../../lib/MyDataHelper';
import { FileManagerProps } from '../../files/FileManagerContext';

import Tags from '../../componentLib/Tags';
import FileViewer from '../../files/components/FileViewer';
import Attachments from '../../files/components/Attachments';
import MyDataDetailsPageHeader, { Props as HeaderProps } from './MyDataDetailsPageHeader';
import { isCurrentUserPro, isUpsellAllowed } from '../../user/selectors';

const MyDataDetailsGrid = styled(Grid)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const MyDataDetailsColumn = styled(Column)`
  padding-bottom: ${({ theme: { space } }) => space.m};
  @media screen and (max-width: 768px) {
    flex-shrink: 0;
  }
`;

const MasterPasswordKeyIcon = styled(KeyIcon)`
  width: 70px;
  height: 70px;
  margin: ${({ theme: { space } }) => `${space.xl} ${space.a}`};
  margin-top: ${({ theme: { space } }) => space.s};
  fill: ${({ theme: { colors } }) => colors.cerulean};
`;

const MAX_LENGTHS: Partial<Record<ModelNames, MaxLengths<DataStructureV1orV2>>> = {
  Account: ACCOUNT_MAX_LENGTHS,
  Note: NOTE_MAX_LENGTHS,
  CreditCard: CREDIT_CARD_MAX_LENGTHS,
};

export type Props = WrappedComponentProps
& WithLastLocationProps
& FileManagerProps
& HeaderProps
& {
  data?: any;
  files?: any;
  changedKeys?: any;
  isPro?: boolean;
  hasFileKey?: boolean;
  syncEnabled?: boolean;
  entityName: ModelNames;
  listViewPath: string;
  paymentUrl?: string;
  confirmDeleteId: string;
  tagsInstructionId: string;
  attachmentInstructionId: string;
  attachmentGetProId: string;
  enableSyncInstructionId: string;
  topRightCard?: JSX.Element | null;
  onDelete: (id: string) => void;
  onModified?: (id: string, timestamp: string) => void;
  onCancelClick: () => void;
  onMasterPasswordCheck: (arg: { password: string }) => void;
  onCreateFileKey: (password: string) => void;
  onDeleteFile: (fileID: string, config?: FileManagerProps) => void;
  onFileUpload: (
    fileID: string,
    payload: FileManagerProps & {
      file: File;
      entityID: string;
    }) => void;
  onGetProClick: () => void;
  onAddFileClick: () => void;
  onFileThumbnailClick: () => void;
};

type State = {
  loading: boolean;
  showDeleteConfirmDialog: boolean;
  exitConfirmationDialog: boolean;
  showSyncWarningDialog: boolean;
  showCreateFileKeyDialog: boolean;
  showGetProDialog: boolean;
  showFileViewer: boolean;
  fileViewerID: string;
  masterPassword: string;
  masterPasswordError: any;
};

class MyDataDetailsPage extends React.Component<PropsWithChildren<Props>, State> {
  private authHelper: AuthenticationHelper;

  public static defaultProps = {
    data: {},
    files: {},
    changedKeys: {},
    isPro: false,
    hasFileKey: false,
    syncEnabled: false,
    paymentUrl: '',
    topRightCard: null,
  };

  constructor(props: Props) {
    super(props);

    this.authHelper = new AuthenticationHelper(props.intl);
    this.state = {
      loading: false,
      showDeleteConfirmDialog: false,
      exitConfirmationDialog: false,
      showSyncWarningDialog: false,
      showCreateFileKeyDialog: false,
      showGetProDialog: false,
      showFileViewer: false,
      fileViewerID: '',
      masterPassword: '',
      masterPasswordError: {},
    };

    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onDeleteConfirm = this.onDeleteConfirm.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onMasterPasswordCheck = this.onMasterPasswordCheck.bind(this);
    this.onAddFileClick = this.onAddFileClick.bind(this);
    this.onFileThumbnailClick = this.onFileThumbnailClick.bind(this);
    this.createNewTag = this.createNewTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.navigateFiles = this.navigateFiles.bind(this);
  }

  public componentDidMount(): void {
    window.onbeforeunload = () => {
      const {
        changedKeys,
        loading: loadingProps,
        intl,
      } = this.props;
      const {
        loading,
        exitConfirmationDialog,
      } = this.state;

      if (
        (Object.keys(changedKeys).length > 0)
        && !loading
        && !loadingProps
        && !exitConfirmationDialog
      ) {
        return intl.formatMessage({ id: 'dashboard.account.details.unsavedChangesWarning' });
      }

      return null;
    };
  }

  public componentWillUnmount(): void {
    window.onbeforeunload = null;
  }

  private onDeleteClick(): void {
    this.setState({ showDeleteConfirmDialog: true });
  }

  private onDeleteConfirm(): void {
    const {
      data, files, listViewPath, history, onDelete, onDeleteFile, fileManager, fileManagerActions,
    } = this.props;
    this.setState({ loading: true }, async () => {
      await onDelete(data.id);
      Object.keys(files).forEach(id => onDeleteFile(id, { fileManager, fileManagerActions }));

      this.setState({ showDeleteConfirmDialog: false },
        () => history.replace(listViewPath));
    });
  }

  private onCancelClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault();

    const {
      history,
      listViewPath,
      lastLocation,
      changedKeys,
      addMode,
      files,
      fileManager,
      fileManagerActions,
      intl,
      onDeleteFile,
      onCancelClick,
    } = this.props;
    const { loading } = this.state;

    const action = (): void => {
      if (addMode) {
        Object.keys(files).forEach(id => onDeleteFile(id, { fileManager, fileManagerActions }));
      }

      if (lastLocation != null) {
        history.goBack();
      } else {
        history.replace(listViewPath);
      }

      if (onCancelClick) {
        onCancelClick();
      }
    };

    if ((Object.keys(changedKeys).length > 0) && !loading) {
      const message = intl.formatMessage({
        id: 'dashboard.account.details.unsavedChangesWarning',
      });

      // eslint-disable-next-line no-alert
      if (window.confirm(message)) {
        this.setState({ exitConfirmationDialog: true }, () => {
          action();
        });
      }
    } else {
      action();
    }
  }

  private onAddFileClick(): void {
    const {
      isPro, hasFileKey, syncEnabled, onAddFileClick,
    } = this.props;

    onAddFileClick();
    if (!syncEnabled) {
      this.setState({ showSyncWarningDialog: true });
      return;
    }

    if (isPro) {
      if (!hasFileKey) {
        this.setState({ showCreateFileKeyDialog: true });
      }
    } else {
      this.setState({ showGetProDialog: true });
    }
  }

  private async onMasterPasswordCheck(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>,
  ): Promise<void> {
    if (e) {
      e.preventDefault();
    }

    const { onMasterPasswordCheck, onCreateFileKey } = this.props;
    const { masterPassword } = this.state;
    this.setState({ masterPasswordError: null });

    this.setState({ loading: true });

    try {
      await onMasterPasswordCheck({ password: masterPassword });
      if (onCreateFileKey) {
        await onCreateFileKey(masterPassword);
        this.setState({ showCreateFileKeyDialog: false, loading: false });
      }
    } catch (error) {
      this.setState({
        loading: false,
        masterPasswordError: this.authHelper.getError(error),
      });
    }
  }

  private onFileThumbnailClick(id: string): void {
    const { onFileThumbnailClick } = this.props;
    onFileThumbnailClick();
    this.setState({
      fileViewerID: id,
      showFileViewer: true,
    });
  }

  private async onDeleteFileClick(fileId: string): Promise<void> {
    const {
      data, fileManager, fileManagerActions, onDeleteFile, onModified,
    } = this.props;

    if (fileId) {
      await onDeleteFile(fileId, { fileManager, fileManagerActions });
      this.setState({
        fileViewerID: '',
        showFileViewer: false,
      });

      if (onModified) {
        const now = new ServerDate().toISOString();
        onModified(data.id, now);
      }
    }
  }

  private navigateFiles(indexOffset: number): void {
    const { files } = this.props;
    const { fileViewerID } = this.state;

    const filesIDArray = Object.keys(files)
      .sort(sortByHelper(files, {
        isDate: true,
        sortKey: 'createdAt',
        sortOrder: 'dsc',
      }));

    if (fileViewerID && fileViewerID.length > 0 && filesIDArray.length) {
      const currIndex = filesIDArray.findIndex(id => id === fileViewerID) || 0;
      const idArrayLength = filesIDArray.length;
      const nextIndex = (currIndex + indexOffset + idArrayLength) % idArrayLength;
      this.setState({
        showFileViewer: true,
        fileViewerID: filesIDArray[nextIndex],
      });
    }
  }

  private uploadFiles(fileList: FileList): void {
    const {
      data,
      entityName,
      fileManager,
      fileManagerActions,
      onChange,
      onFileUpload,
      onModified,
    } = this.props;

    const files = [...fileList];
    let id = '';
    if (data.id && data.id.length > 0) {
      id = data.id;
    } else {
      id = generateID();
      onChange('id', id);
    }

    if (onFileUpload && files && files.length > 0) {
      const promises = files.map(async (file) => {
        const fileID = generateID();
        const entityID = `${entityName}:${id}`;

        await onFileUpload(fileID, {
          file,
          entityID,
          fileManager,
          fileManagerActions,
        });
      });

      Promise.all(promises)
        .then(() => {
          if (onModified) {
            const now = new ServerDate().toISOString();
            onModified(id, now);
          }
        });
    }
  }

  private createNewTag(value: string): void {
    const { data, onChange } = this.props;
    const tags = data.tags || [];

    if (!tags.includes(value)) {
      onChange(
        'tags',
        ([...tags, value]).sort((a, b) => {
          if (a.toLowerCase() < b.toLowerCase()) return -1;
          if (a.toLowerCase() > b.toLowerCase()) return 1;
          return 0;
        }),
      );
    }
  }

  private removeTag(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tagIndex: number): void {
    e.stopPropagation();
    const { data, onChange } = this.props;

    const tags = data.tags || [];
    const updatedTags = [...tags];
    updatedTags.splice(tagIndex, 1);

    onChange('tags', updatedTags);
  }

  // eslint-disable-next-line complexity
  public render(): JSX.Element {
    const {
      changedKeys,
      loading: loadingProps,
      confirmDeleteId,
      tagsInstructionId,
      attachmentInstructionId,
      attachmentGetProId,
      enableSyncInstructionId,
      children,
      intl,
      history,
      files,
      data,
      hasFileKey,
      syncEnabled,
      isPro,
      onChange,
      topRightCard,
      onGetProClick,
      entityName,
    } = this.props;
    const {
      loading,
      exitConfirmationDialog,
      showDeleteConfirmDialog,
      showSyncWarningDialog,
      showCreateFileKeyDialog,
      showGetProDialog,
      showFileViewer,
      fileViewerID,
      masterPassword,
      masterPasswordError,
    } = this.state;

    return (
      <Box
        pt="xl"
        pb="xxxl"
        minWidth="592px"
        height="100%"
        overflowY="auto"
      >
        <Prompt
          when={(Object.keys(changedKeys).length > 0)
            && !loading
            && !loadingProps
            && !exitConfirmationDialog}
          message={intl.formatMessage({ id: 'dashboard.account.details.unsavedChangesWarning' })}
        />
        <MyDataDetailsPageHeader
          {...this.props}
          onCancelClick={this.onCancelClick}
          onDeleteClick={this.onDeleteClick}
          changesMade={Object.keys(changedKeys).length > 0}
        />
        <Box
          display="flex"
          m="0 auto"
          width="100%"
          height="100%"
          maxWidth="1102px"
          flex="1"
          flexDirection="column"
          px="l"
        >
          <MyDataDetailsGrid align="inherit">
            <MyDataDetailsColumn>
              {children}
            </MyDataDetailsColumn>
            <MyDataDetailsColumn>
              {
                topRightCard || (
                  <Box mb="s" width="100%" height="206px">
                    <Input
                      id="a-notes-input"
                      kind="textarea"
                      maxLength={MAX_LENGTHS[entityName]?.notes}
                      rows={7}
                      tabIndex={tabIndex.mainFormFields}
                      label={intl.formatMessage({
                        id: 'dashboard.account.details.notes',
                        defaultMessage: 'Notes',
                      })}
                      placeholder={
                        intl.formatMessage({
                          id: 'dashboard.account.details.notesPlaceholder',
                          defaultMessage: 'Type your note here',
                        })
                      }
                      value={data.notes || ''}
                      onChange={e => onChange('notes', e.target.value)}
                    />
                  </Box>
                )
              }
              <Card
                mb="s"
                width="100%"
                variant="secondary"
                title={(
                  <FormattedMessage
                    id="dashboard.account.details.tags"
                    defaultMessage="Tags"
                  />
                )}
              >
                <Tags
                // added default fallback to [] because iOS sets tags as an empty string
                  tags={data.tags || []}
                  onCreate={this.createNewTag}
                  onRemoveTag={this.removeTag}
                  tagMaxLength={MAX_LENGTHS[entityName]?.tags}
                  instructionLabel={(
                    <FormattedMessage
                      id={tagsInstructionId}
                    />
                  )}
                />
              </Card>
              <ConfigRenderer
                condition={(config, state) => (
                  config.showFiles
                    && (
                      isCurrentUserPro(state)
                      || isUpsellAllowed(state)
                      || !!Object.keys(files).length
                    )
                )}
              >
                <Card
                  width="100%"
                  variant="secondary"
                  title={(
                    <FormattedMessage
                      id="dashboard.account.details.attachments"
                      defaultMessage="Attachments"
                    />
                  )}
                >
                  <Attachments
                    files={files}
                    disabled={!hasFileKey || !syncEnabled || !isPro}
                    locked={!hasFileKey}
                    onAddFile={this.onAddFileClick}
                    onFileThumbnailClick={this.onFileThumbnailClick}
                    handleFiles={this.uploadFiles}
                    instructionLabel={(
                      <FormattedMessage
                        id={attachmentInstructionId}
                      />
                    )}
                  />
                </Card>
              </ConfigRenderer>
            </MyDataDetailsColumn>
          </MyDataDetailsGrid>
        </Box>
        <ConfigRenderer condition="showFiles">
          <FileViewer
            isPro={isPro}
            show={showFileViewer}
            fileID={fileViewerID}
            onGetProClick={onGetProClick}
            onNextClick={() => this.navigateFiles(1)}
            onPreviousClick={() => this.navigateFiles(-1)}
            onCloseClick={() => this.setState({ showFileViewer: false })}
            onDeleteClick={() => this.onDeleteFileClick(fileViewerID)}
          />
        </ConfigRenderer>
        <ConfirmationDialog
          confirmButtonType="alert"
          show={showDeleteConfirmDialog}
          confirmLabel={intl.formatMessage({
            id: 'dashboard.account.details.delete',
            defaultMessage: 'Delete',
          })}
          cancelLabel={intl.formatMessage({
            id: 'dashboard.account.details.cancel',
            defaultMessage: 'Cancel',
          })}
          onConfirm={this.onDeleteConfirm}
          onCancel={() => { this.setState({ showDeleteConfirmDialog: false }); }}
          loading={loading}
        >
          <Box mb="l">
            <Paragraph mb="l" textAlign="center">
              <img src={confirmDeleteIcon} alt="Delete" />
            </Paragraph>
            <FormattedMessage id={confirmDeleteId} />
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          show={showGetProDialog}
          size="narrow"
          confirmButtonType="upsell"
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.files.getPro', defaultMessage: 'Get Pro' })}
          secondaryLabel={intl.formatMessage({ id: 'dashboard.account.details.later', defaultMessage: 'Later' })}
          onConfirm={onGetProClick}
          onSecondary={() => this.setState({ showGetProDialog: false })}
        >
          <img
            srcSet={`${upgradeToPro} 1x, ${upgradeToPro2x} 2x`}
            alt='upgradeToPro'
          />
          <Paragraph my="xl" textAlign="center">
            <strong>
              <FormattedMessage
                id={attachmentGetProId}
              />
            </strong>
          </Paragraph>
        </ConfirmationDialog>
        <ConfirmationDialog
          size="narrow"
          confirmButtonType="primary"
          loading={loading}
          show={showCreateFileKeyDialog}
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.proceedGenerateKey', defaultMessage: 'Proceed' })}
          secondaryLabel={intl.formatMessage({ id: 'dashboard.account.details.forgotMasterPassword', defaultMessage: 'I do not remember my master password' })}
          onConfirm={e => this.onMasterPasswordCheck(e)}
          onCloseClick={() => this.setState({ showCreateFileKeyDialog: false })}
          onSecondary={() => window.open('/reset-account')}
        >
          <form onSubmit={e => this.onMasterPasswordCheck(e)} style={{ width: '100%' }}>
            <Box display="flex">
              <MasterPasswordKeyIcon />
            </Box>
            <Paragraph mb="l" size="h2" textAlign="center">
              <FormattedMessage
                id="dashboard.account.details.enterYourMasterPassword"
                defaultMessage="Enter Your Master Password"
              />
            </Paragraph>
            {
              !(files && Object.keys(files).length > 0) && (
                <Paragraph textAlign="center">
                  <FormattedHTMLMessage
                    id="dashboard.account.details.beforeAddingAttachments"
                    defaultMessage="Before adding your <strong>first attachment</strong>, you need an encryption key. We need your master password to generate this key for you."
                  />
                </Paragraph>
              )
            }
            <Box mt="xl" mb="xl" width="100%">
              <PasswordInput
                value={masterPassword}
                error={masterPasswordError ? masterPasswordError.message : null}
                onChange={e => this.setState({ masterPassword: e.target.value })}
                placeholder={intl.formatMessage({ id: 'masterPassword.verify.placeholder', defaultMessage: 'Confirm master password' })}
                label={intl.formatMessage({ id: 'dashboard.account.details.masterPassword', defaultMessage: 'Master Password' })}
              />
            </Box>
          </form>
        </ConfirmationDialog>
        <ConfirmationDialog
          confirmButtonType="primary"
          show={showSyncWarningDialog}
          confirmLabel={intl.formatMessage({ id: 'dashboard.account.details.openSettings', defaultMessage: 'Open Settings' })}
          cancelLabel={intl.formatMessage({ id: 'dashboard.account.details.later', defaultMessage: 'Later' })}
          onConfirm={() => history.push('/settings')}
          onCancel={() => this.setState({ showSyncWarningDialog: false })}
        >
          <Box mb="l">
            <InfoCircleIcon style={{ width: 64, height: 64 }} />
          </Box>
          <Paragraph size="h3" textAlign="center" mb="l">
            <strong>
              <FormattedMessage
                id={enableSyncInstructionId}
              />
            </strong>
          </Paragraph>
        </ConfirmationDialog>
      </Box>
    );
  }
}

export default MyDataDetailsPage;
