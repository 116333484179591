import React, {
  ReactElement, useState,
} from 'react';

import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
  IntlShape,
} from 'react-intl';
import { State as CreditCardMap } from '@avira-pwm/redux/credit-cards';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import CreditCardPromoListItem from './CreditCardPromoListItem';
import CreditCardListItemContainer from './CreditCardListLitemContainer';
import { FILTER_ALL } from '../../../lib/MyDataHelper';
import {
  defaultSortBy, sortOptions, SortOption, creditCardtTitleHelper, searchQueryMain, searchQuerySub,
} from '../../WalletHelpers';
import { MyDataContainer } from '../../../myData';
import { FileManagerProps, useFileManager } from '../../../files/FileManagerContext';
import ConfirmDeleteDialog from '../../../componentLib/ConfirmDeleteDialog';

type Props = WrappedComponentProps & {
  creditCards: CreditCardMap;
  isUnregisteredMode: boolean;
  disallowDataUsage: boolean;
  preferences: Record<string, string>;
  walletPreferences: Record<string, number>;
  setScrollPosition: (postion: number) => void;
  tags?: Array<string>;
  onAddClick: () => void;
  onDeleteClick: (id: string) => Promise<void>;
  onDeleteFile: (id: string, config?: FileManagerProps) => void;
  onFavoriteClick: (id: string, value: { favorite: boolean }) => void;
  onSortByChange: (sortType: SortOption) => void;
}

const placeholderListItems = [<CreditCardPromoListItem key="ccPlaceholder" />];

const createNewMap = (creditCards: CreditCardMap, intl: IntlShape): CreditCardMap => {
  const list = Object.entries(creditCards);
  const updatedMap: CreditCardMap = {};
  for (const [id, card] of list) {
    const title = card.title || creditCardtTitleHelper(intl, card.cardNumber);
    updatedMap[id] = { ...card, title };
  }
  return updatedMap;
};

const newCreditCardMap = createSelector(
  (state: { creditCards: CreditCardMap }): CreditCardMap => state.creditCards,
  (_a: { creditCards: CreditCardMap }, intl: IntlShape) => intl,
  (creditCards, intl) => createNewMap(creditCards, intl),
);

const WalletPage = (props: Props): ReactElement => {
  const {
    disallowDataUsage,
    intl,
    onAddClick,
    onDeleteClick,
    onDeleteFile,
    onFavoriteClick,
    onSortByChange,
    preferences,
    setScrollPosition,
    tags,
    walletPreferences,
  } = props;
  const fileManager = useFileManager();
  const [loading, setLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [creditCardIdToDelete, setCreditCardIdToDelete] = useState('');
  const [propToReveal, setPropToReveal] = useState({ id: '', property: '' });

  const creditCardList = useSelector(
    (state: {creditCards: CreditCardMap}) => newCreditCardMap(state, intl),
  );

  function deleteClickHandler(id: string): void {
    setCreditCardIdToDelete(id);
    setShowDeleteDialog(true);
  }

  async function onDeleteConfirm(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const { files } = creditCardList[creditCardIdToDelete];

    setLoading(true);
    Object.keys(files || {}).forEach(id => onDeleteFile(id, fileManager));
    await onDeleteClick(creditCardIdToDelete).then(() => {
      setCreditCardIdToDelete('');
      setLoading(false);
      setShowDeleteDialog(false);
    });
  }

  function onCancelConfirm(): void {
    setCreditCardIdToDelete('');
    setLoading(false);
    setShowDeleteDialog(false);
  }

  function propertyToRevealHandler(id: string, property: string): void {
    setPropToReveal({ id, property });
  }

  return (
    // TODO: resolve typescript error Property 'children' does not exist.
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <MyDataContainer
      id="a-wallets-list-container"
      add="/mydata/wallet/new"
      afterScroll={setScrollPosition}
      defaultFilter={FILTER_ALL}
      defaultSortBy={defaultSortBy}
      disable={disallowDataUsage}
      emptyListMessage="dashboard.wallet.empty"
      emptySearchMessage="dashboard.wallet.search.empty"
      header={<FormattedMessage id="dashboard.wallet.details.header" defaultMessage="Wallet" />}
      list={creditCardList}
      listItem={
        (
          <CreditCardListItemContainer
            currentReveal={propToReveal}
            setCurrentReveal={propertyToRevealHandler}
            onDeleteClick={deleteClickHandler}
            onFavoriteClick={onFavoriteClick}
          />
        )
      }
      onAddClick={onAddClick}
      onSortByChange={onSortByChange}
      creditCards
      placeholderListItems={placeholderListItems}
      scrollPosition={walletPreferences.scrollPosition}
      searchQueryMain={searchQueryMain}
      searchQuerySub={searchQuerySub}
      sortBy={preferences.walletSortBy}
      sortOptions={sortOptions}
      tags={tags}
    >
      <ConfirmDeleteDialog
        confirmLabel={intl.formatMessage({
          id: 'dashboard.confirmDeleteDialog.delete',
        })}
        cancelLabel={intl.formatMessage({
          id: 'dashboard.confirmDeleteDialog.cancel',
        })}
        loading={loading}
        message={intl.formatMessage({
          id: 'dashboard.wallet.details.confirmDelete',
        })}
        onCancel={onCancelConfirm}
        onConfirm={onDeleteConfirm}
        show={showDeleteDialog}

      />
    </MyDataContainer>
  );
};

export default injectIntl(WalletPage);
