import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Button from 'pwm-components/components/Button';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Grid from 'pwm-components/objects/Grid';
import registerUser from '../img/icon-register-user.png';
import registerUser2x from '../img/icon-register-user@2x.png';

import Banner from './Banner';
import ConfigRenderer from './ConfigRenderer';

const RegisterBanner: React.SFC = () => (
  <ConfigRenderer condition="spotlight" negate>
    <Box mb="xl">
      <Banner
        actionButton={(
          <Link
            to="/why-register?dashboardContext=register-banner"
            target="_blank"
          >
            <Button variant="login">
              <FormattedMessage id="dashboard.whyRegister" />
            </Button>
          </Link>
        )}
      >
        <Grid size="no-padding" align="center">
          <img
            src={registerUser}
            srcSet={`${registerUser} 1x, ${registerUser2x} 2x`}
            alt="Register User"
          />
          <Paragraph ml="l">
            <strong>
              <FormattedMessage
                id="register.stage1.bannerHeader"
                defaultMessage="Thank you for using Avira Password Manager!"
              />
            </strong>
          </Paragraph>
          <Paragraph>
            <FormattedMessage
              id="register.stage1.bannerMsg"
              defaultMessage="To get more from the tool we suggest you register it. It is free, forever."
            />
          </Paragraph>
        </Grid>
      </Banner>
    </Box>
  </ConfigRenderer>
);

export default RegisterBanner;
