//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//

import constants from './VTConstants';

const { utils } = SymBfw;
const { 
  isNil,
  isFunction,
  isntNil 
} = utils;

const dsv2Messages = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const {
  Value,
  Node
} = dsv2Messages;

class AppNode {
  constructor() {
    this._isAvailable = false;
  }

  /**
   * @function setPasswordHint
   * @desc Sets the password for the hint
   * @param {string} hint The password hint that is stored in the vault.
   */
  setPasswordHint(hint) {
    this.hint = hint;
  }

    /**
   * @function setCryptoAlgorithm
   * @desc Sets the crypto configuration
   * @param {string} config crypto configuration .
   */
  setCryptoAlgorithm(config) {
    this.cryptoAlgorithm = config;
  }
  /**
   * @function getPasswordHint
   * @desc Returns the password hint
   */
  getPasswordHint() {
    return this.hint;
  }

  /**
   * @function getCryptoAlgorithm
   * @desc Returns crypto configuration
   */
  getCryptoAlgorithm() {
    return this.cryptoAlgorithm;
  }

  /**
   20 bytes in total.
   16 bytes of IV
   4 bytes of CRC
   iv should be an ArrayBuffer
   */

  /**
   * @function setChallengeIV
   * @desc Sets the challengeIV for the account
   * @param {ArrayBuffer} iv The initialization vector for the account.
   * This is not what is used as part of the challenge.
   */
  setChallengeIV(iv) {
    this.iv = iv;
  }

  /**
   * @function getChallengeIV
   * @desc Gets the challengeIV for the account
   * @returns {ArrayBuffer} iv The initialization vector for the account.
   * This is not what is used as part of the challenge.
   */
  getChallengeIV() {
    if (isNil(this.iv)) {
      return null;
    }
    // Return the buffer without the salt.
    return this.iv.slice(0, this.iv.byteLength - constants.DEFAULT_CRC_SIZE);
  }

  /**
   * @function setProfileID
   * @desc Sets the profile ID for the account
   * @param {string} id The profile ID on the account.
   */
  setProfileID(id) {
    this.profileID = id;
  }

  /**
   * @function getProfileID
   * @desc Gets the profile ID for the account.
   * @returns {string} The profile ID on the account.
   */
  getProfileID() {
    return this.profileID;
  }

  /**
   * @function setLoginID
   * @desc Sets the login ID for the account.
   * @param {uint64} id The login ID for the IDSafe account (internally represented by Long.js)
   */
  setLoginID(id) {
    this.loginID = id;
  }

  /**
   * @function getLoginID
   * @desc Gets the Login ID for the account.
   * @returns {uint64} id The login ID for the IDSafe account (internally represented by Long.js)
   */
  getLoginID() {
    return this.loginID;
  }

  /**
   * @function setChallengeSalt
   * @desc Sets the setChallengeSalt for the account
   * @param {ArrayBuffer} salt The challenge Salt on the account.
   */
  setChallengeSalt(salt) {
    this.challengeSalt = salt;
  }

  /**
   * @function getChallengeSalt
   * @desc Gets the Challenge Salt for the account.
   * @returns {ArrayBuffer} The challengeSalt on the account.
   */
  getChallengeSalt() {
    if (isNil(this.challengeSalt)) {
      return null;
    }
    // Return the buffer without the salt.
    return this.challengeSalt.slice(0, this.challengeSalt.byteLength - constants.DEFAULT_CRC_SIZE);
  }

  /**
   * @function setVaultVersion
   * @desc Sets the vault version for the account
   * @param {number} version The Vault version on the account. This is a uint32 number.
   */
  setVaultVersion(version) {
    this.valutVersion = version;
  }

  /**
   * @function getVaultVersion
   * @desc Gets the Challenge Salt for the account.
   * @returns {number} version The Vault version on the account. This is a uint32 number.
   */
  getVaultVersion() {
    return this.valutVersion;
  }

  /**
   * @function setNodeModified
   * @desc sets the time at which node was modified.
   * @param {Long} modified The time when server updated the current node.
   */
  setNodeModified(modified) {
    this.nodeModified = modified;
  }

  /**
   * @function setAvailability
   * @desc sets whether the appNode is available or not
   * @param {bool} isAvailable True if available
   */
  setAvailability(isAvailable) {
    this._isAvailable = isAvailable;
  }

  /**
   * @function isEmpty
   * @desc returns true if the appNode is empty
   * @returns {bool} True is the appNode is empty
   */
  isEmpty() {
    return (this._isAvailable === false);
  }

  /**
   * @function getNodeModified
   * @desc Gets the time at which node was modified.
   * @returns {Long} modified The time when server updated the current node.
   */
  getNodeModified() {
    return this.nodeModified;
  }

  /**
   * @function serialize
   * @desc Serializes the given class into its datastore "Node" representation.
   * @param {array} childNodes Array of ChildNodeMeta that are under
   * the current node tree structure in the datastore.
   * @returns Returns a Node object.
   */
  serialize(childNodes) {
    const node = new Node();
    const values = node.getValues();
    const cryptoAlgorithm = this.getCryptoAlgorithm();
    node.setPath(constants.DEFAULT_APPID_NODE);

    // Add the profile value
    const profileValue = new Value();
    profileValue.setType(Value.DataTypeID.TID_STRING);
    profileValue.setDataString(this.getProfileID());
    profileValue.setName(constants.DEFAULT_PROFILE_ID);
    values.push(profileValue);

    const loginValue = new Value();
    loginValue.setType(Value.DataTypeID.TID_UINT64);
    loginValue.setDataUint64(this.getLoginID());
    loginValue.setName(constants.DEFAULT_LOGIN_ID);
    values.push(loginValue);

    const versionValue = new Value();
    versionValue.setType(Value.DataTypeID.TID_UINT32);
    versionValue.setDataUint32(this.getVaultVersion());
    versionValue.setName(constants.DEFAULT_VAULT_VERSION);
    values.push(versionValue);

    const ivValue = new Value();
    ivValue.setType(Value.DataTypeID.TID_BINARY);
    ivValue.setDataBinary(this.iv);
    ivValue.setName(constants.DEFAULT_CHALLENGE_IV);
    values.push(ivValue);

    const hintValue = new Value();
    hintValue.setType(Value.DataTypeID.TID_STRING);
    hintValue.setDataString(this.getPasswordHint());
    hintValue.setName(constants.DEFAULT_PASSWORD_HINT);
    values.push(hintValue);

    if (isntNil(cryptoAlgorithm)) {
      const cryptoAlgorithmValue = new Value();
      cryptoAlgorithmValue.setType(Value.DataTypeID.TID_STRING);
      cryptoAlgorithmValue.setDataString(cryptoAlgorithm);
      cryptoAlgorithmValue.setName(constants.DEFAULT_CRYPTO_ALGORITHM);
      values.push(cryptoAlgorithmValue);
    }
    
    const saltValue = new Value();
    saltValue.setType(Value.DataTypeID.TID_BINARY);
    saltValue.setDataBinary(this.challengeSalt);
    saltValue.setName(constants.DEFAULT_CHALLENGE_SALT);
    values.push(saltValue);

    node.setValues(values);
    // check if we have any child nodes to add. Very likely that we will.
    if (isntNil(childNodes)) {
      node.setChildNodes(childNodes);
    }
    // Once we have set all the values then just return the node object.
    return node;
  }

  /**
   * @function reset
   * @desc Resets  Initializes everything to null
   */
  reset() {
    for (const key in this) {
      if (!isFunction(this[key])) {
        this[key] = null;
      }
    }

    this._isAvailable = false;
  }
}

const appNode = new AppNode();
export default appNode;
