import * as Sentry from '@sentry/browser';

import {
  USER_LOGOUT,
  USER_AUTH_STORE,
  USER_AUTH_CLEAR,
  STORE_KEY,
  CLEAR_KEY,
  SET_LOCK_REASON,
  SET_SYNC_STORE,
  SET_USER_DATA,
  UPDATE_USER_DATA,
  ADD_MILESTONE,
  ADD_UNREGISTERED_MILESTONE,
  CLEAR_UNREGISTERED_MILESTONES,
  CLEAR_USER_DATA,
  CLEAR_UNREGISTERED_KEYS,
} from './UserActionTypes';
import { getError } from '../lib/ErrorHelper';
import { TrackingActions, MixpanelEvents } from '../tracking';
import { logoutUser, sendSync } from '../authentication/AuthenticationActions';
import debug from '../debug';
import { clearKeys as clearVaultKeys, closeVault } from '../nlok/NLOKActions';

const { trackEvent } = TrackingActions;

const {
  MP_LOCK,
} = MixpanelEvents;

const log = debug.extend('UserActions');

const persistMilestones = milestones => async (dispatch, getState, { licenseService }) => {
  const { user: { authToken } } = getState();

  const { user } = await licenseService.updateUserData(authToken, { milestones });
  dispatch({ type: UPDATE_USER_DATA, data: user });
};

export const addMilestone = ({ id, name }) => async (dispatch, getState) => {
  const {
    dashboard: { isUnregisteredMode },
  } = getState();

  const data = { [name]: { id, name } };

  if (isUnregisteredMode) {
    dispatch({ type: ADD_UNREGISTERED_MILESTONE, data });
  } else {
    dispatch({ type: ADD_MILESTONE, data });
    try {
      await dispatch(persistMilestones([id]));
    } catch (e) {
      Sentry.captureException(e);
    }
  }
};

export const migrateUnregisteredModeMilestones = () => async (dispatch, getState) => {
  const {
    user: { unregisteredMilestones },
  } = getState();

  const milestones = Object.values(unregisteredMilestones).map(({ id }) => id);

  try {
    await dispatch(persistMilestones(milestones));
    dispatch({ type: CLEAR_UNREGISTERED_MILESTONES });
  } catch (e) {
    Sentry.captureException(e);
  }
};

export const setSyncStore = syncStoreManager => ({ type: SET_SYNC_STORE, syncStoreManager });
export const setUserData = userData => ({ type: SET_USER_DATA, data: userData });
export const clearUserData = () => ({ type: CLEAR_USER_DATA });

export const storeAuthToken = authToken => ({ type: USER_AUTH_STORE, authToken });
export const clearAuthToken = () => ({ type: USER_AUTH_CLEAR });

export const storeKey = (key, key2) => ({ type: STORE_KEY, key, key2 });
export const clearKey = () => ({ type: CLEAR_KEY });
export const setLockReason = lockReason => ({ type: SET_LOCK_REASON, lockReason });

export const lockUser = (context, cause, error) => (dispatch) => {
  log(`locking user
  context: ${context}
  cause: ${cause}
  error: ${error}
  `);
  dispatch(clearUserData());
  dispatch(clearKey());
  dispatch(closeVault());
  dispatch(clearVaultKeys());
  dispatch(setLockReason(context));
  dispatch(trackEvent(MP_LOCK, { context, cause, error }));
  dispatch(sendSync());
};

export const getUserData = () => async (dispatch, getState, { licenseService }) => {
  const { oe, user } = getState();
  try {
    const data = await licenseService.getUserData(user.authToken, oe.token);
    dispatch(setUserData(data.user));
  } catch (e) {
    if (e.status === 'unauthorized') {
      await dispatch(lockUser('get user data'));
    }

    throw e;
  }
};

export const checkUserExists = oeToken => async (dispatch, getState, { licenseService }) => {
  try {
    return await licenseService.isUserRegistered(oeToken);
  } catch (e) {
    Sentry.withScope((scope) => {
      scope.setExtras({
        status: e.status,
        statusText: e.statusText,
      });

      Sentry.captureException(e);
    });
    throw getError(e);
  }
};

export const userLogout = () => ({ type: USER_LOGOUT });

export const toggleSyncStore = authToken => async (
  dispatch, getState, { licenseService, dashboardMessenger },
) => {
  const syncStoreManager = getState().userData.sync_store_manager === 'disabled' ? 'blur' : 'disabled';
  try {
    const { manager } = await licenseService.setSyncStore(authToken, syncStoreManager);
    dashboardMessenger.send('dashboard:extension:refreshData');
    dispatch(setSyncStore(manager));
  } catch (e) {
    throw getError(e);
  }
};

export const prepareNewAccountSetup = () => async (dispatch, getState, { dashboardMessenger }) => {
  const {
    oe, user, auth, tracking,
  } = getState();

  dashboardMessenger.send('dashboard:extension:prepareNewAccountSetup', {
    oeToken: oe.token,
    email: oe.email,
    authToken: user.authToken,
    key: user.key,
    key2: user.key2,
    timestamp: auth.timestamp,
    distinctId: tracking.distinctId,
    expiresAt: oe.expiresAt,
    refreshToken: oe.refreshToken,
  });
};

export const updateEncryptedKey = ({ key, verify_key: verifyKey }) => (dispatch, getState) => {
  const { userData } = getState();
  if (verifyKey !== userData.verify_key) {
    const context = 'apiConnector';
    const cause = 'verifyKey mismatch';
    dispatch(logoutUser(context, cause));
    return;
  }
  dispatch({ type: UPDATE_USER_DATA, data: { key } });
};

export const clearUnregisteredKeys = () => ({ type: CLEAR_UNREGISTERED_KEYS });
