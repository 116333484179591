import clipboardCopy from 'clipboard-copy';
import config from '../config';

const spotlightClipboardCopy = async (copyText: string): Promise<void> => {
  window.external.CopyToClipboard(copyText);
};

export default config.spotlight
  ? spotlightClipboardCopy
  : clipboardCopy;
