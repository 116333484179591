export default {
  'error.unknown': 'unknown error',
  no_email: 'no email',
  invalid_email: 'invalid email',
  no_password: 'no password',
  invalid_password: 'invalid password',
  disabled_account: 'disabled account',
  password_too_weak: 'password to weak',
  invalid_credentials: 'invalid credentials',
  required_captcha: 'captcha error',
  invalid_custom_kit_token: 'network error',
  invalid_otp: 'invalid OTP',
  'Password is not strong enough': 'password to weak',
  'Invalid email address': 'invalid email',
  'This email already exists with this provider': 'existing account',
  'User already exists (state is pending)': 'existing account',
  'Gateway Timeout': 'network error',
  'Bad password': 'invalid master password',
  'error.login.noPassword': 'no password',
  'error.register.passwordIsNotStrongEnough': 'password too weak',
  'masterPassword.error.backend': 'network error',
  'masterPassword.error.breached': 'password breached',
  'masterPassword.error.create.empty': 'no password',
  'masterPassword.error.create.invalid': 'password too weak',
  'masterPassword.error.verify.empty': 'no confirmation password',
  'masterPassword.error.verify.invalid': 'confirmation does not match',
  'masterPassword.error.verify.notIdentical': 'confirmation does not match',
  'masterPassword.error.verify.wrong': 'confirmation does not match',
  'masterPassword.error.unlock.wrong': 'invalid master password',
  'masterPassword.error.unlock.invalid': 'invalid master password',
  'masterPassword.error.unlock.empty': 'no master password',
  'masterPassword.error.provide.wrong': 'invalid master password',
  'masterPassword.error.provide.invalid': 'invalid master password',
  'masterPassword.error.provide.empty': 'no master password',
  'masterPassword.error.noConsent': 'no consent',
  'error.outdated.extension.version': 'outdated extension',
};
