import React from 'react';
import {
  withRouter, RouteComponentProps, Route, Switch,
} from 'react-router-dom';

import Box from 'pwm-components/components/Box';

import config from '../../config';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import HeaderContainer from '../../header';
import { Sidebar, SidebarWrapper } from '../../sidebar';
import SyncDisabledWarning from '../../myData/components/SyncDisabledWarning';
import Breadcrumbs from '../../componentLib/Breadcrumbs';

import SpotlightMenu from '../../header/components/SpotlightMenu';

type Props = RouteComponentProps<{}>;

type State = {
  mountTimestamp: number;
};

class Page extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      mountTimestamp: 0,
    };
    this.getTrackingTime = this.getTrackingTime.bind(this);
    this.setTrackingTime = this.setTrackingTime.bind(this);
  }

  public getTrackingTime(): number {
    const { mountTimestamp } = this.state;
    return mountTimestamp;
  }

  public setTrackingTime(): void {
    this.setState({
      mountTimestamp: Date.now(),
    });
  }

  public render(): JSX.Element {
    const { location, children } = this.props;
    const useSyncDisableComponent = (location.pathname && location.pathname.indexOf('mydata') !== -1);

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <ConfigRenderer condition="spotlight">
          <Route
            exact
            path={['/mydata/(passwords|notes|wallet)', '/security-status']}
            render={() => (
              <Box
                position="fixed"
                top="s"
                right={0}
                zIndex={40}
              >
                <SpotlightMenu />
              </Box>
            )}
          />
        </ConfigRenderer>

        <ConfigRenderer condition="spotlight" negate>
          <HeaderContainer
            location={location}
          />
        </ConfigRenderer>

        <Box
          bg="background"
          display="flex"
          flexGrow={1}
          overflow="hidden"
          flexDirection={config.spotlight ? 'column' : 'row'}
        >
          <ConfigRenderer condition="spotlight" negate>
            <Box zIndex={1}>
              <SidebarWrapper component={Sidebar} />
            </Box>
          </ConfigRenderer>

          <Switch>
            <Route
              exact
              path={[
                '/mydata/(passwords|notes|wallet|security-status)/:id/edit',
                '/mydata/(passwords|notes|wallet)/new',
                '/security-status/:id/edit',
              ]}
              render={() => (
                <Box
                  flexGrow={1}
                  position="relative"
                  overflowY="auto"
                  display="flex"
                  flexDirection="column"
                  className="inside"
                >
                  <ConfigRenderer condition="spotlight">
                    <Box mx="m">
                      <Box position="fixed" top="s" zIndex={40}>
                        <Breadcrumbs />
                      </Box>
                    </Box>
                  </ConfigRenderer>

                  <Box height="116px" width="100%" flexShrink={0} />

                  {children}
                  <ConfigRenderer condition="spotlight" negate>
                    {useSyncDisableComponent && <SyncDisabledWarning />}
                  </ConfigRenderer>
                </Box>
              )}
            />
            <Route
              render={() => (
                <Box
                  flexGrow={1}
                  position="relative"
                  overflowY="auto"
                >
                  {children}
                  <ConfigRenderer condition="spotlight" negate>
                    {useSyncDisableComponent && <SyncDisabledWarning />}
                  </ConfigRenderer>
                </Box>
              )}
            />
          </Switch>

        </Box>
      </Box>
    );
  }
}

export default withRouter(Page);
