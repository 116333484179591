import * as Sentry from '@sentry/browser';
import React from 'react';
import { connect } from 'react-redux';
import { func, shape, string } from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { generateID } from '../../lib/AccountHelper';
import NoteNameHelper from '../../lib/NoteNameHelper';
import { defaultColor } from '../../lib/DefaultColorConfig';
import { triggerNoteCreation } from '../NoteActions';
import AddEditNotePage from './AddEditNotePage';


class AddNote extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.loading && nextProps.notes[this.state.id] != null) {
      this.props.history.replace(`/mydata/notes/${this.state.id}/edit`);
    }
  }

  onSubmit(id, noteProps) {
    let notePropsSaved = { ...noteProps };
    if (!noteProps.title) {
      // call code to compute the necessary number
      const noteName = NoteNameHelper(this.props.notes, this.props.preferences.language);
      notePropsSaved = { ...notePropsSaved, title: noteName };
    }
    if (!noteProps.color) {
      notePropsSaved = { ...notePropsSaved, color: defaultColor };
    }
    this.setState({ id: id || generateID(), loading: true }, async () => {
      try {
        await this.props.onSaveNoteClick(this.state.id, notePropsSaved);
      } catch (error) {
        this.setState({ loading: false });
        Sentry.captureException(error);
      }
    });
  }

  render() {
    return (
      <AddEditNotePage
        {...this.props}
        addMode
        onSaveNoteClick={this.onSubmit}
      />
    );
  }
}

AddNote.propTypes = {
  notes: shape().isRequired,
  preferences: shape({
    language: string.isRequired,
  }).isRequired,
  onSaveNoteClick: func.isRequired,
  history: shape({
    replace: func.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ notes, files, preferences }) => ({
  notes,
  files,
  preferences,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onSaveNoteClick: triggerNoteCreation,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddNote));
