//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//

/* eslint-disable no-continue */
// IDSNodeParser.js : Helper APIs to handle IDSafe Node.

import constants from './VTConstants';
import appNode from './VTAppNode';
import idscNode from './VTIDSCNode';
import Login from './VTLogin';
import Note from './VTNote';
import Address from './VTAddress';
import Identity from './VTIdentity';
import Tag from './VTTag';
import vaultManager from './VTVaultManager';
import BankAccount from './VTBankAccount';
import CreditCard from './VTCreditCard';
import AssociatedUrl from './VTAssociatedUrl';
import LoginHistory from './VTLoginHistory';
import Authenticator from './VTAuthenticator';
import File from './VTFile';
import DeletedUnknownBreach from './VTDeletedUnknownBreach';
import PasswordBreaches from './VTPasswordBreaches';
import LoginIgnoredBreaches from './VTLoginIgnoredBreaches';
import TagItem from './VTTagItem';

const { logger } = SymBfw;
const {
  isNil, isInteger, isntNil
} = SymBfw.utils;
const { TAG_NODE } = constants;
const tagNodeArray = [TAG_NODE.LOGIN, TAG_NODE.IDENTITY, TAG_NODE.ADDRESS,
  TAG_NODE.CREDIT_CARD, TAG_NODE.BANK_ACCOUNT, TAG_NODE.NOTE];


/**
 * @typedef {import('./VTItem').default} Item
 */

/**
 * @typedef {any} Node change once we have Node types from ocl
 */

/**
 * @typedef {any} NodeList change once we have NodeList types from ocl
 */

class NodeParser {
  /**
    * @function parseNodeList
    * @desc Helper function to parse all the nodes in a node list.
    * @param {NodeList} nodeList The nodeList object returned from Datastore.
    * @summary It fills the appNode, idscNode and loginNodes.
    * NOTE that these nodes are not decrypted when filled in.
    * They are simply put into datastructres.
    * @return (JSON Object} nodeListStatus - contains what nodes were deleted,
    * updated, and added after spoc bump
    */
  parseNodeList(nodeList) {
    const nodes = nodeList.getNodes();
    const nodeListStatus = {
      deleted: 0,
      updated: 0,
      added: 0
    };

    for (const index in nodes) {
      if (Object.prototype.hasOwnProperty.call(nodes, index)) {
        const node = nodes[index];
        switch (node.path) {
          case constants.DEFAULT_APPID_NODE:
            this.handleAppIDNode(node);
            break;
          case constants.DEFAULT_APPID_IDSC_NODE:
            this.handleIDSCNode(node);
            break;
          case constants.DEFAULT_LOGIN_NODE:
          case constants.DEFAULT_CREDIT_CARD_NODE:
          case constants.DEFAULT_BANK_ACCOUNT_NODE:
          case constants.DEFAULT_IDENTITY_NODE:
          case constants.DEFAULT_ADDRESS_NODE:
          case constants.DEFAULT_TAG_NODE:
          case constants.DEFAULT_NOTE_NODE:
          case constants.DEFAULT_ASSOCIATED_URL_NODE:
          case constants.DEFAULT_LOGIN_HISTORY_NODE:
          case constants.DEFAULT_AUTHENTICATOR_NODE:
          case constants.DEFAULT_FILE_NODE:
          case constants.DEFAULT_DELETED_UNKNOWN_BREACH_NODE:
          case constants.DEFAULT_PASSWORD_BREACHES_NODE:
          case constants.DEFAULT_LOGIN_IGNORED_BREACHES_NODE:
            nodeListStatus.deleted += this.handleItemManagerUpdate(node);
            break;

          default: {
            // found a node that contains vault information
            const addUpdateStatus = this.handleVaultNode(node);
            if (isNil(addUpdateStatus)) {
              break;
            }

            if (isInteger(addUpdateStatus.addedNodes)) {
              nodeListStatus.added += addUpdateStatus.addedNodes;
            }

            if (isInteger(addUpdateStatus.updatedNodes)) {
              nodeListStatus.updated += addUpdateStatus.updatedNodes;
            }
            break;
          }
        }
      }
    }

    return nodeListStatus;
  }

  /**
    * @function handleItemManagerUpdate
    * @desc Will be called when a Login/AddressBook/Cards/Notes root node has been updated.
    *       This will usually happen when we receive a SPOC bump when something has been deleted.
    * @param {Node} node The new vault node you want to update.
    * @private
    * @summary When you get a item manager update it is very likely
    *          that some of the subnodes that have
    *          been added recently (within the hour) could also be returned
    *          as part of the same node list.This how O2-DS service does merging from DSV1-> DSV2.
    *          If we were to add an item and delete using
    *          a DSV2 client then the nodeList will only contain the changed items.
    *          The newly added item will not appear in the nodelist.
    * @return {number} number of login items deleted
    */
  handleItemManagerUpdate(node) {
    let type = null;
    switch (node.path) {
      case constants.DEFAULT_LOGIN_NODE:
        type = vaultManager.LOGIN_MANAGER;
        break;
      case constants.DEFAULT_CREDIT_CARD_NODE:
        type = vaultManager.CREDIT_CARD_MANAGER;
        break;
      case constants.DEFAULT_BANK_ACCOUNT_NODE:
        type = vaultManager.BANK_ACCOUNT_MANAGER;
        break;
      case constants.DEFAULT_ADDRESS_NODE:
        type = vaultManager.ADDRESS_MANAGER;
        break;
      case constants.DEFAULT_IDENTITY_NODE:
        type = vaultManager.IDENTITY_MANAGER;
        break;
      case constants.DEFAULT_NOTE_NODE:
        type = vaultManager.NOTE_MANAGER;
        break;
      case constants.DEFAULT_ASSOCIATED_URL_NODE:
        type = vaultManager.ASSOCIATED_URL_MANAGER;
        break;
      case constants.DEFAULT_LOGIN_HISTORY_NODE:
        type = vaultManager.LOGIN_HISTORY_MANAGER;
        break;
      case constants.DEFAULT_AUTHENTICATOR_NODE:
        type = vaultManager.AUTHENTICATOR_MANAGER;
        break;
      case constants.DEFAULT_FILE_NODE:
        type = vaultManager.FILE_MANAGER;
        break;
      case constants.DEFAULT_DELETED_UNKNOWN_BREACH_NODE:
        type = vaultManager.DELETED_UNKNOWN_BREACH_MANAGER;
        break;
      case constants.DEFAULT_PASSWORD_BREACHES_NODE:
        type = vaultManager.PASSWORD_BREACHES_MANAGER;
        break;
      case constants.DEFAULT_LOGIN_IGNORED_BREACHES_NODE:
        type = vaultManager.LOGIN_IGNORED_BREACHES_MANAGER;
        break;
      default:
        break;
    }

    if (isNil(type)) {
      logger.info(`Do not support update to vault at path:${node.path}`);
      return 0;
    }

    const itemManager = vaultManager.getItemManager(type);
    const localItems = itemManager.getAllItems();
    const itemsToDelete = {};
    for (const lcItemIndex in localItems) {
      if (Object.prototype.hasOwnProperty.call(localItems, lcItemIndex)) {
        itemsToDelete[lcItemIndex] = true;
      }
    }
    const childNodes = node.getChildNodes();
    for (const index in childNodes) {
      if (Object.prototype.hasOwnProperty.call(childNodes, index)) {
        const childNode = childNodes[index];
        const nodeName = childNode.getNodeName();
        const value = localItems[nodeName];
        if (isNil(value)) {
          continue;
        }
        // This local item exists on server; don't delete it.
        // So remove it from the list of items to delete.
        delete itemsToDelete[nodeName];
      }
    }

    // now remove the remaining from the items array.
    // You shouldn't have to sync this change to the server coz supposedly it already has it.
    let deleteCounter = 0;
    for (const key in itemsToDelete) {
      if (Object.prototype.hasOwnProperty.call(itemsToDelete, key)) {
        deleteCounter += 1;
        itemManager.deleteItem(key, false);
      }
    }
    return deleteCounter;
  }

  /**
    * @function shouldUpdateItem
    * @desc Helper function that lets us know if we need to update an item or not.
    * @param {Item} existingItem The existing item you want to update.
    * @param {Node} node The new vault node you want to update.
    * @return {boolean} if item needs to be updated
    * @private
    */
  shouldUpdateItem(existingItem, node) {
    const currentDateModified = existingItem.getNodeModified();
    const newDateModified = node.getNodeModified();

    let shouldUpdate = true;
    do {
      if (isNil(currentDateModified)) {
        break;
      }

      const compare = currentDateModified.compare(newDateModified);
      // if current date is greater or equal to the new date then don't update it.
      if (compare >= 0) {
        // current version is greater that what server has.
        // This will never be true so just logging here.
        // TODO Should we upload current node to server then ?
        // logger.error("Server AppNode is older than what we have");
        shouldUpdate = false;
        break;
      }
    } while (false);

    return shouldUpdate;
  }

  /**
    * @function handleVaultNode
    * @desc Helper function to parse all the Vault items (Login, AddressBook, Cards etc).
    * @param {Node} node The vault item you want to parse.
    * @return {object} addUpdateStatus - contains how many nodes were added
    * or updated after spoc bump
    * @private
    */
  handleVaultNode(node) {
    let item = null;
    let itemManager = null;
    let type = null;
    const addUpdateStatus = { addedNodes: 0, updatedNodes: 0 };

    if (node.getValues().length === 0) {
      logger.log('Item got deleted');
      return addUpdateStatus;
    }

    do {
      if (node.path.includes(`${constants.DEFAULT_LOGIN_NODE}/`)) {
        item = new Login();
        type = vaultManager.LOGIN_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_CREDIT_CARD_NODE}/`)) {
        item = new CreditCard();
        type = vaultManager.CREDIT_CARD_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_BANK_ACCOUNT_NODE}/`)) {
        item = new BankAccount();
        type = vaultManager.BANK_ACCOUNT_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_ADDRESS_NODE}/`)) {
        item = new Address();
        type = vaultManager.ADDRESS_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_IDENTITY_NODE}/`)) {
        item = new Identity();
        type = vaultManager.IDENTITY_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_NOTE_NODE}/`)) {
        item = new Note();
        type = vaultManager.NOTE_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_ASSOCIATED_URL_NODE}/`)) {
        item = new AssociatedUrl();
        type = vaultManager.ASSOCIATED_URL_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_LOGIN_HISTORY_NODE}/`)) {
        item = new LoginHistory();
        type = vaultManager.LOGIN_HISTORY_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_AUTHENTICATOR_NODE}/`)) {
        item = new Authenticator();
        type = vaultManager.AUTHENTICATOR_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_FILE_NODE}/`)) {
        item = new File();
        type = vaultManager.FILE_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_DELETED_UNKNOWN_BREACH_NODE}/`)) {
        item = new DeletedUnknownBreach();
        type = vaultManager.DELETED_UNKNOWN_BREACH_MANAGER;
        break;
      }

      if (node.path.includes(`${constants.DEFAULT_PASSWORD_BREACHES_NODE}/`)) {
        item = new PasswordBreaches();
        type = vaultManager.PASSWORD_BREACHES_MANAGER;
        break;
      }
      if (node.path.includes(`${constants.DEFAULT_LOGIN_IGNORED_BREACHES_NODE}/`)) {
        item = new LoginIgnoredBreaches();
        type = vaultManager.LOGIN_IGNORED_BREACHES_MANAGER;
        break;
      }
      if (node.path.includes(`${constants.DEFAULT_TAG_NODE}/`)) {
        const innerNode = this.getTagItemNode(node.path);
        if (isntNil(innerNode)) {
          item = new TagItem();
          item.setItemNode(innerNode);
          type = vaultManager.TAG_ITEM_MANAGER;
        } else {
          item = new Tag();
          type = vaultManager.TAG_MANAGER;
        }
        break;
      }
      return addUpdateStatus;
    } while (false);

    if (item instanceof TagItem) {
      return this.parseTagItemNode(node, item, type);
    }

    item.parseNode(node);
    itemManager = vaultManager.getItemManager(type);
    const itemGuid = item.getPath();
    const existingItem = itemManager.getItem(itemGuid);

    if (isNil(existingItem)) {
      // if the item doesn't exist then don't bother with updating it.
      addUpdateStatus.addedNodes += 1;
      itemManager.addItem(item, false);
    } else {
      const shouldUpdate = this.shouldUpdateItem(existingItem, node);

      if (!shouldUpdate) {
        return addUpdateStatus;
      }

      addUpdateStatus.updatedNodes += 1;

      // if we need to update then set the node modified and then update the item.
      item.setNodeModified(node.getNodeModified());
      itemManager.updateItem(item, false);
    }

    return addUpdateStatus;
  }

  /**
    * @function parseTagItemNode
    * @desc function to parse tagItems
    * @param {Node} node The vault node you want to parse.
    * @param {TagItem} item
    * @param {string} type
    * @private
    */
  parseTagItemNode(node, item, type) {
    const itemArray = [];
    const addUpdateStatus = { addedNodes: 0, updatedNodes: 0 };
    // eslint-disable-next-line guard-for-in
    for (const index in node.values) {
      const tagItemNode = Object.assign({}, node, {
        values: [node.values[index]]
      });
      itemArray.push(tagItemNode);
    }
    // eslint-disable-next-line guard-for-in
    for (const index in itemArray) {
      item.parseNode(itemArray[index]);
      const itemManager = vaultManager.getItemManager(type);
      // addUpdateStatus.addedNodes += 1;
      const innerNode = this.getTagItemNode(node.path);
      const itemGuid = item.getPath();
      const existingTag = itemManager.getItem(itemGuid);
      let existingTagItem = null;
      if (isntNil(existingTag)) {
        existingTagItem = itemManager.isItemExists(itemGuid, item.getTagItem(), innerNode);
      }
      if (isntNil(innerNode)) {
        if (isNil(existingTag) && (isNil(existingTagItem) || existingTagItem === false)) {
          addUpdateStatus.addedNodes += 1;
          itemManager.addTagItem(item, innerNode);
        } else if (isntNil(existingTag) && (isNil(existingTagItem) || existingTagItem === false)) {
          // Todo
          // const shouldUpdate = this.shouldUpdateItem(existingTagItem, node);
          // if (!shouldUpdate) {
          //   return addUpdateStatus;
          // }
          // addUpdateStatus.updatedNodes += 1;
          //  // if we need to update then set the node modified and then update the item.
          // item.setNodeModified(node.getNodeModified());
          itemManager.updateTagItem(item, innerNode);
        }
      }
      item = new TagItem();
      item.setItemNode(innerNode);
    }
    return addUpdateStatus;
  }

  /**
    * @function getTagItemNode
    * @desc Helper function to parse the AppID node.
    * @param {string} nodePath The vault node you want to parse.
    * @returns {string|null}
    * @private
    */
  getTagItemNode(nodePath) {
    const splitPath = nodePath.split('/');
    const innerNode = splitPath[splitPath.length - 1];
    if (tagNodeArray.includes(innerNode)) {
      return innerNode;
    }
    return null;
  }

  /**
    * @function handleAppIDNode
    * @desc Helper function to parse the AppID node.
    * @param {Node} node The vault node you want to parse.
    * @private
    */
  handleAppIDNode(node) {
    const values = node.getValues();

    // if the values inside app node is empty
    // then it means that all the appnode contents are removed (vault deleted)
    // reset the appNode
    if (values.length === 0) {
      appNode.reset();
      return;
    }

    const shouldUpdate = this.shouldUpdateItem(appNode, node);

    if (!shouldUpdate) {
      return;
    }
    // if we need to update then set the node modified and then update the item.
    appNode.setNodeModified(node.getNodeModified());

    for (const index in values) {
      if (Object.prototype.hasOwnProperty.call(values, index)) {
        const value = values[index];
        switch (value.name) {
          case constants.DEFAULT_PASSWORD_HINT: {
            const hint = value.data_string;
            appNode.setPasswordHint(hint);
            break;
          }

          case constants.DEFAULT_CRYPTO_ALGORITHM: {
            const cryptoAlgorithm = value.data_string;
            appNode.setCryptoAlgorithm(cryptoAlgorithm);
            break;
          }

          case constants.DEFAULT_CHALLENGE_IV: {
            const challengeIV = value.data_binary.toArrayBuffer();
            appNode.setChallengeIV(challengeIV);
            break;
          }


          case constants.DEFAULT_LOGIN_ID: {
            const loginID = value.data_uint64;
            appNode.setLoginID(loginID);
            break;
          }

          case constants.DEFAULT_PROFILE_ID: {
            const profileID = value.data_string;
            appNode.setProfileID(profileID);
          }
            break;

          case constants.DEFAULT_CHALLENGE_SALT: {
            const salt = value.data_binary.toArrayBuffer();
            appNode.setChallengeSalt(salt);
          }
            break;

          case constants.DEFAULT_VAULT_VERSION: {
            const vaultVersion = value.data_uint64;
            appNode.setVaultVersion(vaultVersion);
          }
            break;
          default:
            break;
        }
      }
    }

    // the appNode is availiable
    appNode.setAvailability(true);
  }

  /**
    * @function handleIDSCNode
    * @desc Helper function to parse the IDSC node.
    * @param {Node} node The vault node you want to parse.
    * @private
    */
  handleIDSCNode(node) {
    const values = node.getValues();
    const shouldUpdate = this.shouldUpdateItem(idscNode, node);

    if (!shouldUpdate) {
      return;
    }
    // if we need to update then set the node modified and then update the item.
    idscNode.setNodeModified(node.getNodeModified());


    let key = null;
    for (const index in values) {
      if (Object.prototype.hasOwnProperty.call(values, index)) {
        const value = values[index];
        switch (value.name) {
          case constants.DEFAULT_VAULT_ENCRYPTION_KEY:
            key = value.data_binary.toArrayBuffer();
            idscNode.setEncryptionKey(key);
            break;

          case constants.DEFAULT_VAULT_OBFUSCATION_KEY:
            key = value.data_binary.toArrayBuffer();
            idscNode.setObfuscationKey(key);
            break;

          case constants.DEFAULT_LOGIN_ID: {
            const loginID = value.data_uint64;
            idscNode.setLoginID(loginID);
          }
            break;

          case constants.DEFAULT_VAULT_PBDK_SALT: {
            const salt = value.data_binary.toArrayBuffer();
            idscNode.setPBDKSalt(salt);
          }
            break;
          default:
            break;
        }
      }
    }
  }
}
const nodeParser = new NodeParser();
export default nodeParser;
