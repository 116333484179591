import React from 'react';
import { FormattedMessage } from 'react-intl';

import Grid from 'pwm-components/objects/Grid';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Button from 'pwm-components/components/Button';
import BrowserMagic from '../../componentLib/BrowserMagic';
import Banner from '../../componentLib/Banner';
import ConfigRenderer from '../../componentLib/ConfigRenderer';

type Props = {
  onCloseClick: () => void;
  browser: string;
  storeLink: string;
}

const ExtensionBanner: React.SFC<Props> = ({ onCloseClick, browser, storeLink }) => (
  <ConfigRenderer condition="spotlight" negate>
    <Box mt="-36px" mb="xl">
      <Banner
        onClose={onCloseClick}
        actionButton={(
          <a
            href={storeLink}
            target="_blank"
            rel="noreferrer noopener"
            id="a-installExtension-action"
          >
            <Button>
              <FormattedMessage id="dashboard.settings.extension.installExtension" />
            </Button>
          </a>
        )}
      >
        <Grid size="no-padding" align="center">
          <BrowserMagic browser={browser} />
          <Paragraph ml="l">
            <FormattedMessage id="dashboard.settings.extension.installationMessage" />
          </Paragraph>
        </Grid>
      </Banner>
    </Box>
  </ConfigRenderer>
);

export default ExtensionBanner;
