import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Banner from '../../componentLib/Banner';

const CoronaSpecialBanner: React.FC<{}> = () => (
  <Box mr="s" flex="0 0 auto">
    <Banner size="small">
      <Box display='flex' flexDirection='column' justifyContent='space-between'>
        <Paragraph size='h3' pb='xs'>
          <FormattedHTMLMessage
            id="dashboard.securityStatus.coronaSpecial.title"
            defaultMessage="Staying home to stay healthy?"
          />
        </Paragraph>
        <Paragraph size='h3'>
          <FormattedHTMLMessage
            id="dashboard.securityStatus.coronaSpecial.body"
            defaultMessage="Use our Security Status pro feature <strong>for free till May 15th</strong> and improve your passwords."
          />
        </Paragraph>
      </Box>
    </Banner>
  </Box>
);

export default CoronaSpecialBanner;
