import React from 'react';
import PropTypes from 'prop-types';

import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import Grid from 'react-virtualized/dist/commonjs/Grid';
import getScrollbarSize from 'dom-helpers/util/scrollbarSize';

import 'react-virtualized/styles.css';

class ScrollingTable extends React.Component {
  constructor(props) {
    super(props);

    this.contentRenderer = this.contentRenderer.bind(this);
    this.contentRendererOffsetColumn = this.contentRendererOffsetColumn.bind(this);
    this.headerRenderer = this.headerRenderer.bind(this);
    this.headerRendererOffsetColumn = this.headerRendererOffsetColumn.bind(this);
    this.setScrollPosition = this.setScrollPosition.bind(this);

    this.ref = {};
    this.state = {
      columns: this.getColumnsNumber(this.props),
      scrollbarSize: getScrollbarSize(),
      height: this.getHeight(),
      scrollTop: 0,
      scrollLeft: 0,
    };
  }

  componentDidMount() {
    this.props.onMount();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      columns: this.getColumnsNumber(nextProps),
      height: this.getHeight(),
    });

    Object.keys(this.ref).forEach((key) => {
      if (this.ref[key]) {
        this.ref[key].forceUpdate();
      }
    });
  }

  getHeight() {
    const { items, rowHeight } = this.props;

    const maxHeight = this.props.maxHeight - this.props.headerHeight;
    const minHeight = rowHeight * 3;
    let height = items.length * rowHeight;

    if (height > minHeight && maxHeight < minHeight) {
      height = minHeight + (rowHeight / 2);
    } else if (height > maxHeight) {
      height = maxHeight;
    }

    return height;
  }

  getColumnsNumber(props) {
    const { items, header } = props;

    return [...items, header].reduce((val, item) => {
      if (item.length > val) return item.length;
      return val;
    }, 0);
  }

  getFixedLeftColumn() {
    const {
      items, headerHeight, rowHeight, fixedColumnWidth,
    } = this.props;
    const { height } = this.state;

    return (
      <div>
        <div
          className="c-scrolling-table__fixed-cell"
          style={{ height: headerHeight }}
        >
          <Grid
            ref={(ref) => { this.ref.fixedCell = ref; }}
            cellRenderer={this.headerRenderer}
            width={fixedColumnWidth}
            height={headerHeight}
            rowHeight={headerHeight}
            columnWidth={fixedColumnWidth}
            rowCount={1}
            columnCount={1}
          />
        </div>
        <div
          className="c-scrolling-table__left-column-wrapper"
          style={{
            top: headerHeight,
            height,
            width: fixedColumnWidth,
            overflow: 'hidden',
          }}
        >
          <Grid
            className="c-scrolling-table__left-column"
            ref={(ref) => { this.ref.leftCol = ref; }}
            containerStyle={{
              top: -this.state.scrollTop,
            }}
            cellRenderer={this.contentRenderer}
            columnWidth={fixedColumnWidth}
            columnCount={1}
            scrollTop={this.state.scrollTop}
            height={height - this.state.scrollbarSize}
            rowHeight={rowHeight}
            rowCount={items.length}
            width={fixedColumnWidth}
          />
        </div>
      </div>
    );
  }

  setScrollPosition({ scrollTop, scrollLeft }) {
    this.setState({ scrollTop, scrollLeft });
  }

  headerRenderer({ key, columnIndex, style }) {
    return (
      <div
        key={key}
        style={style}
        className="c-scrolling-table__cell"
      >
        {this.props.header[columnIndex]}
      </div>
    );
  }

  headerRendererOffsetColumn(args) {
    if (args.columnIndex < 1) {
      return undefined;
    }

    return this.headerRenderer(args);
  }

  calculateWidth(containerWidth) {
    const totalWidth = this.state.columns * this.props.columnWidth;
    if (containerWidth > totalWidth) return containerWidth;
    return totalWidth;
  }

  calculateColumnWidth(containerWidth) {
    const { fixedColumn, fixedColumnWidth, columnWidth } = this.props;
    const { columns } = this.state;
    const columnOffsetCount = fixedColumn ? 1 : 0;
    const contentColumnCount = columns - columnOffsetCount;
    const contentWidth = containerWidth - (fixedColumnWidth * columnOffsetCount);

    if (columnWidth * contentColumnCount < contentWidth) {
      return contentWidth / contentColumnCount;
    }

    return columnWidth;
  }

  contentRendererOffsetColumn(args) {
    if (args.columnIndex < 1) {
      return undefined;
    }

    return this.contentRenderer(args);
  }

  contentRenderer({
    key, columnIndex, rowIndex, style,
  }) {
    const { items } = this.props;

    return (
      <div
        key={key}
        style={style}
        title={typeof items[rowIndex][columnIndex] === 'string' ? items[rowIndex][columnIndex] : ''}
        className={`c-scrolling-table__cell ${
          rowIndex % 2 === 1 ? 'c-scrolling-table__row--even' : ''
        }`}
      >
        {items[rowIndex][columnIndex]}
      </div>
    );
  }

  render() {
    const { items, fixedColumn } = this.props;
    const { columns, height } = this.state;
    const columnOffsetCount = fixedColumn ? 1 : 0;

    return (
      <div
        className="c-scrolling-table"
        style={{ width: '100%', height: '100%', position: 'relative' }}
      >
        {fixedColumn && this.getFixedLeftColumn()}

        <AutoSizer disableHeight>
          {({ width }) => {
            const columnWidth = this.calculateColumnWidth(width);

            return (
              <div>
                <div
                  style={{
                    height: this.props.headerHeight,
                    width: width - this.state.scrollbarSize,
                  }}
                >
                  <Grid
                    ref={(ref) => { this.ref.header = ref; }}
                    className="c-scrolling-table__header-row"
                    style={{ left: (this.props.fixedColumnWidth * columnOffsetCount) }}
                    columnWidth={columnWidth}
                    columnCount={columns}
                    height={this.props.headerHeight}
                    cellRenderer={args => this.headerRenderer({
                      ...args,
                      columnIndex: (args.columnIndex + columnOffsetCount),
                    })}
                    rowHeight={this.props.headerHeight}
                    rowCount={1}
                    scrollLeft={this.state.scrollLeft}
                    width={width}
                  />
                </div>
                <div
                  style={{
                    height,
                    width,
                  }}
                >
                  <Grid
                    ref={(ref) => { this.ref.body = ref; }}
                    className="c-scrolling-table__body"
                    style={{ left: (this.props.fixedColumnWidth * columnOffsetCount) }}
                    columnWidth={columnWidth}
                    columnCount={columns - columnOffsetCount}
                    height={height}
                    onScroll={this.setScrollPosition}
                    cellRenderer={args => this.contentRenderer({
                      ...args,
                      columnIndex: (args.columnIndex + columnOffsetCount),
                    })}
                    rowHeight={this.props.rowHeight}
                    rowCount={items.length}
                    width={width - (this.props.fixedColumnWidth * columnOffsetCount)}
                  />
                </div>
              </div>
            );
          }}
        </AutoSizer>
      </div>
    );
  }
}

ScrollingTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.array).isRequired,
  header: PropTypes.array.isRequired,
  fixedColumn: PropTypes.bool,
  fixedColumnWidth: PropTypes.number,
  columnWidth: PropTypes.number,
  headerHeight: PropTypes.number,
  rowHeight: PropTypes.number,
  maxHeight: PropTypes.number,
  onMount: PropTypes.func,
};

ScrollingTable.defaultProps = {
  fixedColumn: false,
  fixedColumnWidth: 300,
  columnWidth: 300,
  headerHeight: 40,
  rowHeight: 40,
  maxHeight: null,
  onMount: () => {},
};

export default ScrollingTable;
