import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import styled from 'pwm-components/styled';
import Arrow from 'pwm-components/components/Arrow';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';

import DropdownMenu from './DropdownMenu';

const PointerBox = styled(Box)`
  cursor: pointer;
  position: relative;
`;

class SortMenu extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    sortOptions: PropTypes.array.isRequired,
    sortBy: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showSortMenu: false,
      sortBy: props.sortBy,
    };

    this.onClick = this.onClick.bind(this);
    this.onSortMenuClick = this.onSortMenuClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  onClick(e, key) {
    e.stopPropagation();
    this.props.onClick(key);
    this.setState({ sortBy: key, showSortMenu: false });
  }

  onSortMenuClick(e) {
    e.stopPropagation();
    const { showSortMenu } = this.state;
    this.setState({ showSortMenu: !showSortMenu });
  }

  handleClickOutside() {
    if (this.state.showSortMenu) {
      this.setState({ showSortMenu: false });
    }
  }

  render() {
    const fakeLabels = [];
    const sortOptions = [];
    const { sortBy, showSortMenu } = this.state;

    this.props.sortOptions.forEach((sortOption) => {
      const option = {
        menuItemAttrs: {
          onClick: e => this.onClick(e, sortOption.id),
        },
        ...sortOption,
      };

      const fakeLabel = (
        <div className="u-fake-label u-d-b" key={sortOption.id}>
          <span className="u-mr-xxs u-txt-longprimer">
            <FormattedMessage
              id="dashboard.accounts.sortBy"
              defaultMessage="by"
            />
          </span>
          <span>
            <FormattedMessage id={sortOption.labelId} />
          </span>
          <Box mr="10px" />
        </div>
      );

      fakeLabels.push(fakeLabel);
      sortOptions.push(option);
    });

    return (
      <PointerBox
        className="a-sort-criteria"
        onClick={this.onSortMenuClick}
      >
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Text mr="xxs">
            <FormattedMessage
              id="dashboard.sortMenu.sortBy"
              defaultMessage="by"
            />
          </Text>
          <Text mr="xxs">
            <FormattedMessage id={`dashboard.accounts.sortBy.${sortBy}`} />
          </Text>
          <Arrow
            direction="down"
            display="inline-block"
          />
        </Box>

        {fakeLabels}

        <DropdownMenu
          menuItems={sortOptions}
          showDropdownMenu={showSortMenu}
        />
      </PointerBox>
    );
  }
}

export default SortMenu;
