import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import ScrollArea from 'react-scrollbar';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import union from 'lodash/union';
import memoize from 'lodash/memoize';

import styled from 'pwm-components/styled';
import AddButton from 'pwm-components/components/AddButton';
import Text from 'pwm-components/components/Text';
import Box from 'pwm-components/components/Box';
import Tag from 'pwm-components/components/Tag';

import ScrollAreaContent from './ScrollAreaContent';
import { filterHelper, sortByHelper } from '../lib/MyDataHelper';
import SortMenu from './SortMenu';
import TagsContainer from './TagsContainer';
import TagFilterSelect from './TagFilterSelect';

const ScrollAreaShadow = styled('div')`
  pointer-events: none;
  overflow: hidden;
  position: absolute;
  right: 16px;
  left: ${({ theme }) => theme.space.xxs};
  height: 8px;
  top: 0;
  bottom: auto;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
    z-index: 1;
    display: block;
    content: '';
  }
`;

const StyledScrollArea = styled(ScrollArea)`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  padding-right: 12px;

  & > .scrollarea-content {
    padding: 4px 4px 95px;
  position: initial;
  }

  & .scrollbar-container {
    background-color: ${({ theme: { semanticColors } }) => (semanticColors.foreground)} !important;
    overflow: hidden;
    opacity: 1 !important;
    width: 6px !important;
    border-radius: 3px;
    margin-top: 4px;
    z-index: 1 !important;

    &.vertical {
      height: calc(100% - 8px) !important;
    }

    & .scrollbar {
      background-color: ${({ theme: { semanticColors } }) => (semanticColors.text)} !important;
      width: 6px !important;
      border-radius: 3px;
      margin-left: 0 !important;
    }
  }
`;

const getTagLabel = tag => (
  tag.label
    ? (
      <FormattedMessage id={tag.label}>
        {text => <Text width="100%" style={{ fontSize: 'inherit' }}>{text}</Text>}
      </FormattedMessage>
    ) : (
      <Text width="100%" style={{ fontSize: 'inherit' }}>
        {tag.text}
      </Text>
    )
);

const TagWrapper = ({
  id,
  label,
  text,
  count,
  filterCount,
  separate = false,
  disabled = false,

  filter,
  onClick,
}) => {
  const onTagClick = useCallback(() => {
    onClick(id);
  }, [id, onClick]);

  const labelElement = useMemo(() => getTagLabel({ label, text }), [label, text]);

  return (
    <Box key={id} mr="xs" mb="xs" ml={separate ? 'auto' : ''}>
      <Tag
        label={labelElement}
        count={count || filterCount || 0}
        onClick={onTagClick}
        selected={id === filter}
        disabled={disabled}
      />
    </Box>
  );
};

TagWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  text: PropTypes.string,
  count: PropTypes.number,
  filterCount: PropTypes.number,
  separate: PropTypes.bool,
  disabled: PropTypes.bool,
  filter: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

TagWrapper.defaultProps = {
  label: null,
  text: null,
  count: 0,
  filterCount: 0,
  separate: false,
  disabled: false,
};

class DataList extends React.Component {
  // eslint-disable-next-line max-statements
  constructor(props) {
    super(props);
    const list = props.list || {};
    const { ignoreFilter } = props;

    const { search, sortOptions, defaultSortBy } = props;
    const filter = this.getFilter();

    let { sortBy } = props;
    if (!sortOptions[sortBy]) {
      sortBy = defaultSortBy;
    }

    const listIds = Object.keys(list)
      .sort(sortByHelper(list, sortOptions[sortBy]));

    this.state = {
      list,
      listIds,
      filter,
      search,
      sortBy,
      ignoreFilter,
      showTopShadow: false,
      ignoreSort: false,
      listenToScrolling: false,
      ...this.getRenderIndices(),
    };

    this.state = {
      ...this.state,
      listIdsFiltered: this.getIdsFilteredWithPriority(
        listIds, list, search, filter, ignoreFilter,
      ),
    };

    this.addButtonWrapper = React.createRef();

    this.onScroll = this.onScroll.bind(this);
    this.afterScroll = debounce(this.afterScroll, 200);
    this.getSortOptionsLabels = memoize(this.getSortOptionsLabels);
    this.onTagClick = this.onTagClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.afterFilterChange = this.afterFilterChange.bind(this);
    this.setShadowVisibility = this.setShadowVisibility.bind(this);
    this.getIdsToFilter = this.getIdsToFilter.bind(this);
    this.onSortItemClick = this.onSortItemClick.bind(this);
    this.onInteraction = this.onInteraction.bind(this);
    this.updateIgnoreFilter = this.updateIgnoreFilter.bind(this);
    this.updateIgnoreSort = this.updateIgnoreSort.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.insertAddButton = this.insertAddButton.bind(this);
    this.onAddClick = this.onAddClick.bind(this);
  }

  componentDidMount() {
    this.insertAddButton();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.list !== nextProps.list
      || nextProps.sortBy !== this.props.sortBy) {
      const { search, ignoreFilter, sortBy } = this.state;
      const filter = this.getFilter(nextProps);

      let { listIds } = this.state;

      // sort if recieved props was not triggered by favorite icon click
      // or if an note was added or deleted
      if (!this.state.ignoreSort
        || this.state.listIds.length !== Object.keys(nextProps.list).length
      ) {
        listIds = Object.keys(nextProps.list)
          .sort(sortByHelper(nextProps.list, this.props.sortOptions[sortBy]));
      }

      this.setState({
        list: nextProps.list,
        listIds,
        listIdsFiltered: this.getIdsFilteredWithPriority(
          listIds, nextProps.list, search, filter, ignoreFilter,
        ),
        ignoreSort: false,
        filter,
        ...this.getRenderIndices(),
      });
    }

    if (nextProps.search !== this.props.search) {
      this.onSearchChange(null, nextProps.search);
    }

    if (nextProps.filter !== this.props.filter) {
      this.onFilterChange(nextProps.filter);
    }
  }

  onTagClick(tagId) {
    this.props.onTagClick(tagId);
  }

  onScroll(value) {
    const { topPosition } = value;

    if (typeof topPosition === 'undefined') {
      // TODO: for some reason the scrolling lib calls onScroll again
      // for security status (maybe because something in the dimensions of the container change).
      // Try to figure out why that happens.
      return;
    }

    this.setState({
      showTopShadow: topPosition > 8,
      ...this.getRenderIndices(topPosition),
    });
    this.afterScroll(value);
  }

  onSearchChange(inputName, search) {
    if (search !== this.state.search) {
      const ignoreFilter = {};
      const { filter, list } = this.state;
      const listToFilter = this.getIdsToFilter(search, filter);
      this.afterScroll(0);
      this.setState({
        search,
        ignoreFilter,
        listIdsFiltered: this.getIdsFilteredWithPriority(
          listToFilter, list, search, filter, ignoreFilter,
        ),
      }, this.afterSearchChange);
    }
  }

  onFilterChange(filter) {
    const ignoreFilter = {};
    const { search, list } = this.state;
    const listToFilter = this.getIdsToFilter(search, filter);
    this.setState({
      filter,
      ignoreFilter,
      listIdsFiltered: this.getIdsFilteredWithPriority(
        listToFilter, list, search, filter, ignoreFilter,
      ),
    }, this.afterFilterChange);
  }

  onSortItemClick(key) {
    const { filter } = this.state;
    this.setState({
      sortBy: key,
    }, () => {
      const { onSortByChange } = this.props;
      // filter used here for MP tracking only
      onSortByChange(key, filter);
    });
  }

  onInteraction(index) {
    this.props.onInteraction(index, Object.keys(this.state.listIdsFiltered).length);
  }

  onAddClick(e) {
    e.preventDefault();
    const { onAddClick, history, add } = this.props;

    onAddClick();
    history.push(add);
  }

  setShadowVisibility(value, state) {
    const topPosition = (value.topPosition || 0);
    let newState = state;
    if (this.state.showTopShadow !== (topPosition > 8)) {
      newState = {
        ...newState,
        showTopShadow: topPosition > 8,
      };
    }
    if (newState) {
      this.setState(newState);
    }
  }

  getIdsToFilter(search, filter) {
    let listToFilter = this.state.listIds;
    if (!!search.trim()
      && search.indexOf(this.state.search) === 0
      && filter === this.state.filter) {
      listToFilter = this.state.listIdsFiltered;
    }

    // if search reamains same
    // and filter changes
    return listToFilter;
  }

  // eslint-disable-next-line max-params
  getIdsFilteredWithPriority(currentlistIds, list, search, filter, ignoreFilter) {
    if (!(currentlistIds)) {
      return [];
    }

    const currentTag = this.props.tags.find(tag => tag.id === filter);

    const filterFn = (currentTag && currentTag.filter) || (() => true);

    const listMainFiltered = currentlistIds.filter(
      filterHelper(list, search, filterFn, ignoreFilter, this.props.searchQueryMain),
    );

    const listSubFiltered = currentlistIds.filter(
      filterHelper(list, search, filterFn, ignoreFilter, this.props.searchQuerySub),
    );

    // When an item moves from the main list to the sub list it will be added to the top
    // of the sub list, so we need to sort the sublist again.
    const { sortOptions } = this.props;
    const { sortBy } = this.state;
    listSubFiltered.sort(sortByHelper(list, sortOptions[sortBy]));

    return union(listMainFiltered, listSubFiltered);
  }

  getRenderIndices(
    topPosition = (this.scrollAreaRef && this.scrollAreaRef.state.topPosition)
      || this.props.scrollPosition || 0,
  ) {
    const containerHeight = (this.scrollAreaRef && this.scrollAreaRef.state.containerHeight)
      || window.innerHeight;
    const { itemHeight } = this.props;
    return {
      lowIndex: Math.floor(topPosition / itemHeight),
      highIndex: Math.ceil((topPosition + containerHeight) / itemHeight),
    };
  }

  getFilter(nextProps) {
    const { tags, filter, defaultFilter } = nextProps || this.props;
    if (!filter || !tags.some(tag => (tag.id === filter))) {
      return defaultFilter;
    }
    return filter;
  }


  getSortOptionsLabels(options) {
    return Object.keys(options).map(id => ({
      id,
      labelId: `dashboard.accounts.sortBy.${id}`,
    }));
  }

  updateIgnoreFilter(id) {
    const { state } = this;
    const ignoreFilter = { ...state.ignoreFilter };
    ignoreFilter[id] = true;
    this.setState({ ignoreFilter });
  }

  updateIgnoreSort() {
    this.setState({ ignoreSort: true });
  }

  afterFilterChange() {
    this.scrollTop();
  }

  afterSearchChange() {
    this.scrollTop();
  }

  scrollTop() {
    if (this.scrollAreaRef) {
      this.scrollAreaRef.scrollTop();
      // This will remove the scrollbar when switching from a larger list to a smaller one
      setTimeout(() => {
        if (this.scrollAreaRef) {
          const { scrollHeight, clientHeight, lastChild } = this.scrollAreaRef.wrapper;
          lastChild.style.display = (scrollHeight === clientHeight && lastChild.classList.contains('scrollbar-container')) ? 'none' : 'block';
        }
      }, 0);
    }
  }

  afterScroll(value) {
    // at times value does not have topPosition so default it to 0
    this.props.afterScroll(value.topPosition || 0);
  }

  // we need to insert the button this way in order to
  // position it properly in the ScrollArea component in IE11
  insertAddButton() {
    const { add } = this.props;
    const { wrapper } = this.scrollAreaRef;
    const { addButtonWrapper: { current: buttonWrapper } } = this;

    if (add) {
      setTimeout(() => {
        const referenceNode = wrapper.childNodes.length > 1
          ? wrapper.lastChild
          : null;
        wrapper.insertBefore(buttonWrapper, referenceNode);
      }, 0);
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      disable,
      emptyListMessage,
      emptySearchMessage,
      showTags,
      tagStyle,
      tags,
      defaultFilter,
      add,
      scrollPosition,
      sortOptions,
      banner,
      itemHeight,
      bottomBanner,
      securityStatus,
      securityStatusPromoBanner,
      placeholderListItems,
      background,
      listItem,
      listItemRender,
    } = this.props;

    const {
      list,
      listIdsFiltered,
      filter,
      search,
      sortBy,
      lowIndex,
      highIndex,
    } = this.state;
    const listLength = listIdsFiltered.length;
    const showPlaceholders = placeholderListItems && !search;

    let listItems = [
      securityStatusPromoBanner
        ? null
        : (
          <div className="o-section u-mb-m u-txt-center" key={emptyListMessage}>
            <FormattedMessage id={emptyListMessage} />
          </div>
        ),
    ];
    if (listLength) {
      listItems = listIdsFiltered.map((key, index) => {
        if (lowIndex <= index && index <= highIndex) {
          const props = {
            key,
            index,
            data: list[key],
            onInteraction: this.onInteraction,
            updateIgnoreFilter: this.updateIgnoreFilter,
            updateIgnoreSort: this.updateIgnoreSort,
            sortBy,
          };

          if (listItemRender) {
            return listItemRender(props);
          }

          return React.cloneElement(listItem, props);
        }

        return (
          <Box key={key} height={`${itemHeight}px`} />
        );
      });

      if (showPlaceholders) {
        listItems = listItems.concat(placeholderListItems.slice(listItems.length));
      }
    } else if ((filter === defaultFilter || search.length) && Object.keys(list).length) {
      listItems = (
        <div id="a-empty-search-message" className="o-section u-mb-m u-txt-center a-warning-message">
          <FormattedMessage id={emptySearchMessage} />
        </div>
      );
    } else if (placeholderListItems) {
      listItems = placeholderListItems;
    }

    let scrollAreaStyle;

    if (background) {
      scrollAreaStyle = { background };
    }

    return (
      <Box
        position="relative"
        display="flex"
        flexDirection="column"
        overflowY="hidden"
        flexGrow={1}
      >
        <Box
          id="a-tag-list"
          pr="m"
          pl="xxs"
          display="flex"
          flex="0 0 auto"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          {
            // eslint-disable-next-line no-nested-ternary
            showTags
              ? (
                tagStyle === 'badge'
                  ? (
                    <TagsContainer mb="xs" minHeight="42px">
                      {
                      tags.map(tag => (
                        <TagWrapper
                          {...tag}
                          onClick={this.onTagClick}
                          filter={filter}
                          key={tag.id}
                        />
                      ))
                    }
                      {!this.props.hideSort && (
                      <Box
                        alignSelf="flex-end"
                        mr="xxs"
                        mb="xs"
                        ml="auto"
                      >
                        <SortMenu
                          sortBy={this.state.sortBy}
                          sortOptions={this.getSortOptionsLabels(sortOptions)}
                          onClick={this.onSortItemClick}
                        />
                      </Box>
                      )}
                    </TagsContainer>
                  ) : (
                    <>
                      {/* TODO: this file shouldn't know
                        about secirity status - should be refactored */}
                      <Box mb="xs">{securityStatus}</Box>
                      <Box
                        alignSelf="flex-end"
                        mr="xxs"
                        mb="xs"
                      >
                        <TagFilterSelect
                          filter={filter}
                          tags={tags}
                          onChange={this.onTagClick}
                          label={(
                            <FormattedMessage
                              id="dashboard.securityStatus.filter.label"
                              defaultMessage="filter the list to show"
                            />
                        )}
                        />
                      </Box>
                    </>
                  )
              )
              : null
            }
        </Box>
        {banner}
        <StyledScrollArea
          className={`c-scrollwrapper  ${!add ? 'c-scrollwrapper__content--defaultpadding' : ''}`}
          onScroll={this.onScroll}
          disabled={disable}
          style={scrollAreaStyle}
          ref={(scrollArea) => { this.scrollAreaRef = scrollArea; }}
        >
          <ScrollAreaShadow visible={this.state.showTopShadow} />
          <ScrollAreaContent
            scrollYTo={scrollPosition}
            background={!search ? background : null}
            className={securityStatusPromoBanner ? 'c-scrollcontent--bg-height' : ''}
          >
            {listItems}
          </ScrollAreaContent>

          <Box
            position="absolute"
            mb="m"
            mr="xl"
            bottom="0"
            right="0"
            ref={this.addButtonWrapper}
          >
            {
              add
                ? (
                  <AddButton
                    tabIndex={0}
                    onClick={this.onAddClick}
                    id="a-add-action"
                  />
                )
                : null
            }
          </Box>
        </StyledScrollArea>
        {bottomBanner}
      </Box>
    );
  }
}

DataList.propTypes = {
  disable: PropTypes.bool,
  scrollPosition: PropTypes.number,
  itemHeight: PropTypes.number,
  list: PropTypes.object.isRequired,
  listItem: PropTypes.element,
  listItemRender: PropTypes.func,
  emptyListMessage: PropTypes.string.isRequired,
  emptySearchMessage: PropTypes.string.isRequired,
  afterScroll: PropTypes.func,
  filter: PropTypes.string.isRequired,
  defaultFilter: PropTypes.string.isRequired,
  search: PropTypes.string.isRequired,
  searchQueryMain: PropTypes.func.isRequired,
  searchQuerySub: PropTypes.func.isRequired,
  showTags: PropTypes.bool,
  tagStyle: PropTypes.oneOf(['badge', 'select']),
  tags: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    text: PropTypes.string,
    filter: PropTypes.func,
    disabled: PropTypes.bool,
    count: PropTypes.number,
    filterCount: PropTypes.number,
  })),
  onTagClick: PropTypes.func.isRequired,
  onSortByChange: PropTypes.func.isRequired,
  add: PropTypes.string,
  onAddClick: PropTypes.func,
  onInteraction: PropTypes.func,
  sortBy: PropTypes.string,
  defaultSortBy: PropTypes.string.isRequired,
  sortOptions: PropTypes.object.isRequired,
  hideSort: PropTypes.bool,
  banner: PropTypes.element,
  bottomBanner: PropTypes.element,
  placeholderListItems: PropTypes.array,
  securityStatus: PropTypes.element,
  securityStatusGiftBox: PropTypes.element,
  securityStatusPromoBanner: PropTypes.element,
  background: PropTypes.string,
  ignoreFilter: PropTypes.object,
  history: PropTypes.object,
};

DataList.defaultProps = {
  disable: false,
  listItem: null,
  listItemRender: null,
  scrollPosition: 0,
  itemHeight: 70,
  afterScroll: () => { },
  onAddClick: () => { },
  onInteraction: () => { },
  add: '',
  showTags: true,
  tagStyle: 'badge',
  tags: [],
  hideSort: false,
  sortBy: '',
  banner: null,
  bottomBanner: null,
  placeholderListItems: null,
  securityStatus: (<></>),
  securityStatusGiftBox: null,
  securityStatusPromoBanner: null,
  background: null,
  ignoreFilter: {},
  history: {},
};

export const DataListWithoutRouter = DataList;
export default withRouter(DataList);
