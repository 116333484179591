import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { initializeDashboardPageNavigator } from '../DashboardActions';

const DashboardListener = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeDashboardPageNavigator(history));
  }, [dispatch, history]);

  return children;
};


export default DashboardListener;
