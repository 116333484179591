export default {
  name: {
    key: 'label',
    label: 'dashboard.settings.import.fieldType.name',
  },
  website: {
    key: 'domain',
    label: 'dashboard.settings.import.fieldType.website',
  },
  username: {
    key: 'username',
    label: 'dashboard.settings.import.fieldType.username',
  },
  secondaryUsername: {
    key: 'secondaryUsername',
    label: 'dashboard.settings.import.fieldType.secondaryUsername',
  },
  password: {
    key: 'password',
    label: 'dashboard.settings.import.fieldType.password',
  },
  notes: {
    key: 'notes',
    label: 'dashboard.settings.import.fieldType.notes',
  },
};

const knownMappings = {
  name: 'name',
  names: 'name',
  label: 'name',
  labels: 'name',
  title: 'name',
  titles: 'name',
  account: 'name',
  website: 'website',
  websites: 'website',
  site: 'website',
  sites: 'website',
  url: 'website',
  urls: 'website',
  domain: 'website',
  domains: 'website',
  'web site': 'website',
  username: 'username',
  user_id: 'username',
  userid: 'username',
  'login name': 'username',
  secondary_username: 'secondaryUsername',
  email: 'secondaryUsername',
  emails: 'secondaryUsername',
  password: 'password',
  passwords: 'password',
  notes: 'notes',
  note: 'notes',
  extra: 'notes',
  extras: 'notes',
  notesplain: 'notes',
  comments: 'notes',
};

export const defaultImporterMapping = {
  name: null,
  website: null,
  username: null,
  secondaryUsername: null,
  password: null,
  notes: [],
};

export const autoAccountMapping = (parsedData = []) => {
  if (!parsedData || parsedData.length === 0) {
    return defaultImporterMapping;
  }

  const mappingSuggestions = { ...defaultImporterMapping };
  const [headerRow, ...accounts] = parsedData;

  headerRow.forEach((headerKey, index) => {
    const matchedMapping = knownMappings[headerKey.toLowerCase().trim()];
    if (matchedMapping) {
      if (matchedMapping === 'notes') {
        mappingSuggestions.notes = [...new Set([...mappingSuggestions.notes, index])];
      } else {
        mappingSuggestions[matchedMapping] = index;
      }
    }
  });

  // chrome export file contains the domain of the website url as the name
  // which results in having redundant accounts
  // as websites having same data but different name would be marked as unique and imported.
  if (mappingSuggestions.name != null
    && mappingSuggestions.website != null
    && accounts.length > 0) {
    let websiteContainsName = true;
    accounts.forEach((account) => {
      const website = account[mappingSuggestions.website];
      const name = account[mappingSuggestions.name];
      if (name && (!website || website.indexOf(name) === -1)) {
        websiteContainsName = false;
      }
    });
    if (websiteContainsName) {
      mappingSuggestions.name = null;
    }
  }

  return mappingSuggestions;
};
