import { getFileData } from './FileDataHelper';

export type Previews = Record<string, Uint8Array>;

type ResizeConfigs = {
  file: File;
  maxWidth?: number;
  maxHeight?: number;
  mimeType?: string;
  quality?: number;
}

export async function resizeVideoThumbnail({
  file, maxWidth = 250, maxHeight = 250, mimeType = 'image/png', quality = 1,
}: ResizeConfigs): Promise<string> {
  if (!file) {
    throw (new Error('File Not Found'));
  }

  const videoURL = URL.createObjectURL(file);
  return new Promise((resolve, reject) => {
    const video = document.createElement('video');

    video.ontimeupdate = () => {
      video.pause();

      const canvas = document.createElement('canvas');
      let { videoWidth, videoHeight } = video;
      canvas.width = maxWidth;
      canvas.height = maxHeight;

      if (videoWidth >= videoHeight) {
        videoWidth *= maxHeight / videoHeight;
        videoHeight = maxHeight;
      } else {
        videoHeight *= maxWidth / videoWidth;
        videoWidth = maxWidth;
      }

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(video, 0, 0, videoWidth, videoHeight);
        const imageURI = canvas.toDataURL(mimeType, quality);

        if (canvas && canvas.parentNode) {
          canvas.parentNode.removeChild(canvas);
        }

        resolve(imageURI.replace(/^data:image\/.*;base64,/, ''));
      } else {
        reject(new Error('Failed to create canvas context'));
      }
    };

    video.onerror = (error) => {
      reject(error);
    };

    video.preload = 'metadata';
    video.src = videoURL;
    video.muted = true;
    video.play();
  });
}

export async function generatePreviews(
  id: string,
  file: File,
): Promise<Previews> {
  const thumbnail = await resizeVideoThumbnail({ file });
  const data = await getFileData(file);

  return {
    [`${id}_thumb`]: Buffer.from(thumbnail, 'base64'),
    [id]: data,
  };
}

export default generatePreviews;
