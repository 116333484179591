import React from 'react';
import Box from 'pwm-components/components/Box';
import { StepProps } from './steps/StepProps';
import Banner from './Banner';
import LeftPanel from './LeftPanel';

type Props = {
  Step: StepProps;
}

const FTUComponent: React.FC<Props> = ({
  Step: {
    stepComponent: StepComponent,
    stepName,
    goToNextStep,
    goToPreviousStep,
  },
}) => (
  <Box
    display="flex"
    flexDirection="column"
    height="100%"
    bg="background"
  >
    <Box>
      <Banner stepName={stepName} />
    </Box>
    <Box
      display="flex"
      flex="1 0 auto"
    >
      <Box flex="1 0 0" bg="foreground" mt="1px">
        <LeftPanel />
      </Box>
      <Box flex="2 0 0" bg="background">
        <StepComponent
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          stepName={stepName}
        />
      </Box>
    </Box>
  </Box>
);

export default FTUComponent;
