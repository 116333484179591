import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import styled from 'pwm-components/styled';
import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';


const MyTable = styled('table')`
 width: 335px;
 margin-bottom: 30px;
 line-height: 39px;
 text-align: center;

 tr:nth-child(odd) {
    background-color: white

  }
tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.brightgray};

  }
td:nth-child(2) {
    text-align: left;

  }
`;

const WhyLong = () => (
  <>
    <Box mb="m">
      <Paragraph mb="xs">
        <FormattedMessage
          id="dashboard.getStarted.whyLong.step"
          defaultMessage="The longer your password is, the longer it will take to crack it by using a technique called brute force."
        />
      </Paragraph>
      <MyTable>
        <tbody>
          <tr>
            <th>
              <strong>
                <FormattedMessage
                  id="dashboard.getStarted.whyLong.step1"
                  defaultMessage="Password length"
                />
              </strong>
            </th>
            <th style={{ textAlign: 'left' }}>
              <strong>
                <FormattedMessage
                  id="dashboard.getStarted.whyLong.step8"
                  defaultMessage="Time to crack"
                />
              </strong>
            </th>
          </tr>
          <tr>
            <td> 4</td>
            <td>
              <FormattedMessage
                id="dashboard.getStarted.whyLong.step2"
                defaultMessage="17 minutes"
              />
            </td>
          </tr>
          <tr>
            <td>6</td>
            <td>
              <FormattedMessage
                id="dashboard.getStarted.whyLong.step3"
                defaultMessage="1 day"
              />
            </td>
          </tr>
          <tr>
            <td>8</td>
            <td>
              <FormattedMessage
                id="dashboard.getStarted.whyLong.step4"
                defaultMessage="116 days"
              />
            </td>
          </tr>
          <tr>
            <td>12</td>
            <td>
              <FormattedMessage
                id="dashboard.getStarted.whyLong.step5"
                defaultMessage=" 3 000 years"
              />
            </td>
          </tr>
          <tr>
            <td>16</td>
            <td>
              <FormattedMessage
                id="dashboard.getStarted.whyLong.step6"
                defaultMessage="32 000 000 years"
              />
            </td>
          </tr>
        </tbody>
      </MyTable>

      <Media
        image={(
          <img
            src={hint}
            srcSet={`${hint} 1x, ${hint2x} 2x`}
            alt="Hint"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.whyLong.step7"
              defaultMessage="Use at least 20 characters for all your passwords."
            />
          </Text>
        )}
      />
    </Box>
  </>
);

export default WhyLong;
