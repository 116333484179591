import React from 'react';
import Tag from 'pwm-components/components/Tag';
import Box from 'pwm-components/components/Box';
import CreateTag from './CreateTag';
import TagsContainer from './TagsContainer';

type Props = {
  tags: Array<string>;
  onCreate?: ((value: string) => void) | null;
  onRemoveTag?: (
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, tagIndex: number) => void
  ) | null;
  instructionLabel?: JSX.Element | null;
  tagMaxLength?: number;
}

const Tags: React.SFC<Props> = ({
  tags,
  onCreate,
  onRemoveTag,
  instructionLabel,
  tagMaxLength,
}) => (
  <TagsContainer>
    {onCreate && (
      <Box mb={tags.length === 0 ? '0' : 'xs'} width={tags.length === 0 ? '100%' : 'auto'}>
        <CreateTag
          onCreate={onCreate}
          ignoreList={tags}
          instructionLabel={tags.length === 0 ? instructionLabel : null}
          tagMaxLength={tagMaxLength}
        />
      </Box>
    )}
    {
      tags
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .map((tag, index) => (
          <Box key={tag} mr="xs" mb="xs">
            <Tag onRemoveTag={e => onRemoveTag && onRemoveTag(e, index)} label={tag} />
          </Box>
        ))
    }
  </TagsContainer>
);

Tags.defaultProps = {
  tags: [],
  onCreate: null,
  onRemoveTag: null,
  instructionLabel: null,
};

export default Tags;
