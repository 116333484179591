// @ts-nocheck
import { GET_DEVICES, LOG_OUT_DEVICE } from './ConnectedDevicesActionTypes';
import { getErrorDescription } from '../lib/ErrorHelper';
import { lockUser } from '../user/UserActions';


export const getDevices = authToken => async (dispatch, getState, { licenseService }) => {
  try {
    const { sessions } = await licenseService.getConnectedDevices(authToken);
    dispatch({ type: GET_DEVICES, value: sessions });
  } catch (e) {
    if (e.status === 'unauthorized') {
      await dispatch(lockUser('get devices list'));
      return;
    }

    let eCode = 'error.unknown';
    if (e && e.code) {
      eCode = e.code;
    }

    throw getErrorDescription(eCode);
  }
};

export const logOutDevice = (deviceId, authToken) => async (
  dispatch, getState, { licenseService },
) => {
  try {
    await licenseService.logOutDevice(deviceId, authToken);
    dispatch({ type: LOG_OUT_DEVICE, deviceId });
  } catch (e) {
    if (e.status === 'unauthorized') {
      await dispatch(lockUser('logout devices'));
      return;
    }

    let eCode = 'error.unknown';
    if (e && e.code) {
      eCode = e.code;
    }

    throw getErrorDescription(eCode);
  }
};
