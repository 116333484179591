import React, { useState, useEffect, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { Type } from '@avira-pwm/sync/ModelSpecifics/Account';
import { Type as AccHistoryType } from '@avira-pwm/sync/ModelSpecifics/AccountHistory';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';

import Box from 'pwm-components/components/Box';
import ModifiedAt from 'pwm-components/components/ModifiedAt';
import Text from 'pwm-components/components/Text';
import TextButton from 'pwm-components/components/TextButton';
import Timeline, { TimelineItem } from 'pwm-components/components/Timeline';

import CloseIcon from 'pwm-components/icons/Close';
import PasswordManagerIcon from 'pwm-components/icons/PasswordManager';

import InfoPopup from '../../componentLib/InfoPopup';
import Card from '../../componentLib/CardWrapper';

import PasswordHistoryItem from './PasswordHistoryItem';
import { trackEvent } from '../../tracking/TrackingActions';

type AccountType = Flattened<Type>;
type AccountHistoryType = Flattened<AccHistoryType>;

type AccountDetails = { account: AccountType; accountHistory: AccountHistoryType[] };

const PasswordHistory: React.FC<{
  onCloseClick: () => void;
  onOutsideClick: () => void;
  visible: boolean;
}> = ({ onCloseClick, onOutsideClick, visible }) => {
  const { account, accountHistory } = useSelector(
    ({ accountDetails }: { accountDetails: AccountDetails }) => accountDetails,
  );
  const [revealPassword, setRevealPassword] = useState<string | null>(null);

  const reavelPasswordClicked = useCallback((id: string): void => {
    if (revealPassword === id) {
      setRevealPassword(null);
    } else {
      setRevealPassword(id);
    }
  }, [revealPassword]);

  const hidePassword = useCallback((): void => {
    setRevealPassword(null);
  }, []);

  const onPopupClicked = useCallback((e: React.MouseEvent): void => {
    e.stopPropagation();
    hidePassword();
  }, [hidePassword]);

  const handleOutsideClick = useCallback((): void => {
    hidePassword();
    onOutsideClick();
  }, [onOutsideClick, hidePassword]);

  useEffect(() => {
    window.addEventListener('click', onOutsideClick);
    return () => window.removeEventListener('click', onOutsideClick);
  }, [onOutsideClick]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      dispatch(trackEvent('CredentialsHistory_open'));
    }
  }, [dispatch, visible]);

  return account ? (
    <InfoPopup
      show={visible}
      top="calc(100% + 16px)"
      right="xxxl"
      onClick={onPopupClicked}
      className="a-password-history-popup"
    >
      <Box width="400px">
        <Card
          variant="secondary"
          p={0}
          title={(
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <PasswordManagerIcon variant="avira" size="small" mr="xxs" />
              <FormattedMessage
                id="dashboard.account.history.title"
                defaultMessage='Password History for "{domain}"'
                values={{ domain: account.label || account.domain }}
              />
              <span style={{ marginLeft: 'auto', marginRight: '0' }}>
                <TextButton onClick={onCloseClick}>
                  <CloseIcon />
                </TextButton>
              </span>
            </span>
          )}
        >
          <Box maxHeight="308px" overflowY="auto" p="s" onScroll={hidePassword}>
            <Timeline>
              <TimelineItem
                type="disc-active"
                smallPadding
                textRender={() => (
                  <Text size="small">
                    <FormattedMessage
                      id="dashboard.account.passwordHistory.now"
                      defaultMessage="Now"
                    />
                  </Text>
                )}
              >
                <PasswordHistoryItem
                  account={account}
                  revealPassword={account.id === revealPassword}
                  onReavealPassword={reavelPasswordClicked}
                  onOutsideClick={handleOutsideClick}
                />
              </TimelineItem>

              {
                accountHistory
                  .sort((a, b) => (
                    new Date(b.modifiedAt).getTime() - new Date(a.modifiedAt).getTime()
                  ))
                  .map((historyItem, index) => (
                    <TimelineItem
                      key={historyItem.id}
                      type="disc"
                      smallPadding
                      textRender={() => (
                        historyItem.modifiedAt
                          ? (
                            <Text size="small">
                              <ModifiedAt
                                showDay
                                showTime
                                value={historyItem.modifiedAt}
                              />
                            </Text>
                          ) : null
                      )}
                    >
                      <PasswordHistoryItem
                        id={`a-password-history-item-${index}`}
                        account={account}
                        historyItem={historyItem}
                        revealPassword={historyItem && historyItem.id === revealPassword}
                        onReavealPassword={reavelPasswordClicked}
                        onOutsideClick={hidePassword}
                      />
                    </TimelineItem>
                  ))
              }

              <TimelineItem
                type="disc"
                smallPadding
                textRender={() => (
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    {
                      account.createdAt && (
                        <Text size="small" mr="xxs">
                          <ModifiedAt
                            showDay
                            showTime
                            value={account.createdAt || ''}
                          />
                        </Text>
                      )
                    }

                    &ndash;

                    <Text size="small" ml="xxs">
                      <FormattedMessage
                        id="dashboard.account.passwordHistory.accountCreated"
                        defaultMessage="Account created"
                      />
                    </Text>
                  </span>
                )}
              />
            </Timeline>
          </Box>
        </Card>
      </Box>
    </InfoPopup>
  ) : null;
};

export default PasswordHistory;
