import React from 'react';
import styled from 'pwm-components/styled';
import ViewerProps from './ViewerProps';

type ImgProps = {
  mimeType?: string | null;
  rotation?: number;
};

export type Props = ViewerProps & ImgProps;

const Image = styled('img')<ImgProps>`
  display: block;
  object-fit: contain;
  user-select: none;
  ${({ rotation }) => `transform: rotate(${rotation}deg);`}
  ${({ rotation }) => ((rotation || 0) % 180 === 0 ? `
    max-width: 90vw;
    max-height: 90vh;
  ` : `
    max-width: 90vh;
    max-height: 90vw;
  `)}
  transition: 500ms ease-in-out;
`;

const ImageViewer: React.FC<Props> = ({ data, mimeType, ...props }) => {
  const base64 = Buffer.from(data || new Uint8Array()).toString('base64');
  const srcStr = `data:${mimeType || 'image/jpeg'};base64,${base64}`;

  return (
    <Image {...props} src={srcStr} onClick={e => e.stopPropagation()} />
  );
};

export default ImageViewer;
