import {
  BACKUP_DATA,
  CLEAR_BACKUP_DATA,
  BackupData,
  ClearBackupData,
} from './ManageBackupsActionTypes';

export type State = object | {
  accounts: any;
  accountsHistory: any;
  metadata: any;
};

function getInitialState(): State {
  return {};
}

export type Actions = BackupData | ClearBackupData;

export default function backupDataReducer(
  state: State = getInitialState(),
  action: Actions,
): State {
  switch (action.type) {
    case BACKUP_DATA: {
      return action.value;
    }
    case CLEAR_BACKUP_DATA:
      return getInitialState();
    default:
      return state;
  }
}
