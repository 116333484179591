import React from 'react';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedMessage } from 'react-intl';

import alert from '../../img/alert.png';
import alert2x from '../../img/alert@2x.png';

type Props = {
  message: React.ReactNode;
}

const EmbargoError = ({ message }: Props): JSX.Element => (
  <Box
    display="flex"
    justifyContent="center"
    flexDirection="column"
    height="100%"
    // paddingBottom="80px"
  >
    <Paragraph mb="l" textAlign="center">
      <img
        src={alert}
        srcSet={`${alert} 1x, ${alert2x} 2x`}
        alt="alert"
      />
    </Paragraph>
    <Paragraph size="h3" mb="xs" textAlign="center">
      <FormattedMessage
        id="embargo.error.disabled"
        defaultMessage="Avira Password Manager is disabled"
      />
    </Paragraph>
    <Paragraph textAlign="center">
      {message}
    </Paragraph>
  </Box>
);


export default EmbargoError;
