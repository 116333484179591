import { Selector } from 'reselect';

import { RootState } from '../app/store';

export const isConnectedToExtension: Selector<RootState, boolean> = (
  ({ dashboard }) => !!dashboard.extensionConnected
);

export const isUnregisteredMode: Selector<RootState, boolean> = (
  ({ dashboard }) => !!dashboard.isUnregisteredMode
);
