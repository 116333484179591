import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { updateLanguageChoice } from '../../preferences';
import Loading from './Loading';

type Params = {
  language: string;
}

const ChangeLanguage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams<Params>();

  useEffect(() => {
    dispatch(updateLanguageChoice(params.language));
    history.goBack();
  }, [history, params, dispatch]);

  return (
    <Loading>
      <FormattedMessage id="dashboard.changingLanguage" defaultMessage="Changing language..." />
    </Loading>
  );
};

export default ChangeLanguage;
