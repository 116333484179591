import React, { useEffect, useState, useCallback } from 'react';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import ReactFileReader from 'react-file-reader';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Type } from '@avira-pwm/sync/ModelSpecifics/File';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';
import styled, { css } from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import Tag from 'pwm-components/components/Tag';
import Text from 'pwm-components/components/Text';
import { sortByHelper } from '../../lib/MyDataHelper';
import { getDataSizeString } from '../helpers/FileDataHelper';
import { useFileManager } from '../FileManagerContext';
import FilesContext from './FilesContext';
import FileThumbnail from './FileThumbnail';
import { fileAddingAllowed as fileAddingAllowedSelector } from '../selectors';

const FileReaderContainer = styled(Column)`
  align-self: stretch;
  margin-right: ${({ theme: { space } }) => space.xs};
`;

const FileReader = styled(ReactFileReader)`
  input {
    display: none;
    overflow: hidden;
    height: 0;
    width: 0;
  }
`;

const ActionTag = styled(Tag)<{ disabled?: boolean; onClick: () => void }>`
  position: relative;
  height: 32px;
  width: 32px;
  line-height: 0;
  overflow: visible;
  min-width: 0;
  padding: 0;
  flex: 0 0 32px;
`;

const ThumbnailContainer = styled('div')`
  --gap: ${({ theme: { space } }) => space.m};
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--gap));
  margin-bottom: ${({ theme: { space } }) => space.m};
  margin-left: calc(-1/2 * var(--gap));
  width: calc(100% + var(--gap));
  flex: 1 1 auto;
  min-height: 30px;
  align-items: center;

  & > * {
    margin: var(--gap) calc(1/2 * var(--gap)) 0 calc(1/2 * var(--gap));
  }
`;

const AddLabel = styled('span')`
  position: absolute;
  font-size: 24px;
  font-weight: 500;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StorageWrapper = styled('div')<{ show: boolean }>`
  width: 100%;
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity ${({ theme: { transition } }) => transition.duration.default};
`;

const InstructionWrapper = styled(StorageWrapper)`
  position: absolute;
`;

const StorageBarWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  margin-top: ${({ theme: { space } }) => space.xs};
  border-radius: ${({ theme: { space } }) => space.xxs};
  height: ${({ theme: { space } }) => space.xxs};
`;

type PercentageProps = { percentage: number };

const strengthBackground = css<PercentageProps>`
  background-color: ${({ percentage, theme: { mode, colors } }) => {
    if (percentage >= 100) return colors.scarlet;
    if (percentage >= 90) return colors.pizazzorange;
    return mode === 'light' ? colors.cerulean : colors.white;
  }};
  transition: ${({ theme: { transition } }) => transition.duration.default};
`;

const StorageBarBackground = styled('div')<PercentageProps>`
  width: 100%;
  height: 100%;
  opacity: 0.2;
  ${strengthBackground}
`;

const StorageBarForeground = styled('div')<PercentageProps>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: ${({ percentage }) => percentage}%;
  ${strengthBackground}
`;

export type Props = {
  files: { [key: string]: Flattened<Type> };
  disabled?: boolean;
  locked?: boolean;
  instructionLabel?: JSX.Element | null;
  onAddFile?: () => void;
  onFileThumbnailClick?: (e: string) => void;
  handleFiles?: (files: FileList) => void;
}

// eslint-disable-next-line complexity
const Attachments: React.SFC<Props> = ({
  files = {},
  disabled,
  locked,
  instructionLabel,
  handleFiles,
  onAddFile = () => {},
  onFileThumbnailClick = () => {},
}) => {
  const fileList = Object.keys(files);
  const [storageFull, setStorageFull] = useState(disabled);
  const [showStorage, setShowStorage] = useState(fileList.length > 0);
  const { fileManager: { fileStatuses, fileStorage, fileProgresses } } = useFileManager();
  const { bytesUsed, bytesLimit } = fileStorage || { bytesUsed: 0, bytesLimit: 1 };

  useEffect(() => {
    if (bytesLimit === 1) {
      setShowStorage(false);
      return;
    }

    const full = bytesUsed >= bytesLimit;
    setStorageFull(full);
    setShowStorage(full || showStorage || fileList.length !== 0);
  }, [bytesLimit, bytesUsed, fileList, disabled, showStorage]);

  const onAddButtonEnter = useCallback(() => {
    if (fileList.length > 0) {
      return;
    }

    setShowStorage(true);
  }, [fileList]);

  const onAddButtonLeave = useCallback(() => {
    if (fileList.length > 0) {
      return;
    }

    setShowStorage(false);
  }, [fileList]);

  const fileAddingAllowed = useSelector(fileAddingAllowedSelector);

  const onThumbnailClick = useCallback((id) => {
    if (locked) {
      onAddFile();
    } else {
      onFileThumbnailClick(id);
    }
  }, [onAddFile, onFileThumbnailClick, locked]);

  const storagePercentage = fileStorage ? (bytesUsed / bytesLimit) * 100 : 0;
  return (
    <Grid
      mr="xs"
      size="no-padding"
      align="center"
    >
      <FileReaderContainer
        className="a-attachments-input-container"
        size="auto"
        onMouseEnter={onAddButtonEnter}
        onMouseLeave={onAddButtonLeave}
      >
        {onAddFile && (
          <FileReader
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore probably caused by a bug in newer version of @types/styled-components
            fileTypes="*"
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore probably caused by a bug in newer version of @types/styled-components
            multipleFiles
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore probably caused by a bug in newer version of @types/styled-components
            disabled={disabled || storageFull || !fileAddingAllowed}
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore probably caused by a bug in newer version of @types/styled-components
            handleFiles={handleFiles}
          >
            <ActionTag disabled={disabled || storageFull || !fileAddingAllowed} onClick={onAddFile}>
              <AddLabel>+</AddLabel>
            </ActionTag>
          </FileReader>
        )}
      </FileReaderContainer>
      <Column>
        {fileList.length > 0 && (
          <ThumbnailContainer>
            <FilesContext files={files} suffix="thumb">
              {decFiles => (
                <>
                  {
                    fileList
                      .sort(sortByHelper(files, {
                        isDate: true,
                        sortKey: 'createdAt',
                        sortOrder: 'dsc',
                      }))
                      .map((id, index) => {
                        const { name, type } = files[id];
                        const data = decFiles[id];

                        let srcBase64 = '';
                        if (data) {
                          srcBase64 = Buffer.from(data).toString('base64');
                        }

                        return (
                          <FileThumbnail
                            id={`a-attachments-item-${index}`}
                            key={id}
                            name={name}
                            type={type}
                            srcBase64={srcBase64}
                            status={(fileStatuses || {})[id]}
                            progress={(fileProgresses || {})[id]}
                            onClick={() => onThumbnailClick(id)}
                          />
                        );
                      })
                  }
                </>
              )}
            </FilesContext>
          </ThumbnailContainer>
        )}
        <Box position="relative" display="flex" alignItems="center">
          <InstructionWrapper show={!showStorage}>
            {instructionLabel}
          </InstructionWrapper>
          <StorageWrapper show={showStorage}>
            <StorageBarWrapper>
              <StorageBarBackground percentage={storagePercentage} />
              <StorageBarForeground percentage={storagePercentage} />
            </StorageBarWrapper>
            <Grid mt="xxs" size="no-padding">
              <Column>
                <Text size="h5" mr="xxs">
                  <FormattedMessage
                    id="dashboard.account.details.allAttachments"
                    defaultMessage="All Attachments:"
                  />
                </Text>
                <Text className="a-attachments-storage-usage" size="h5">
                  {getDataSizeString(fileStorage?.bytesUsed || 0)}
                </Text>
              </Column>
              <Column size="auto">
                <Text size="h5">
                  {getDataSizeString(fileStorage?.bytesLimit || 0)}
                </Text>
              </Column>
            </Grid>
            {storagePercentage >= 100 && (
              <Text size="small" variant="alert">
                <FormattedMessage
                  id="dashboard.account.details.allAttachmentsFull"
                  defaultMessage="Storage full. Remove some files before adding new ones."
                />
              </Text>
            )}
          </StorageWrapper>
        </Box>
      </Column>
    </Grid>
  );
};

export default Attachments;
