import {
  SIDEBAR_COLLAPSED,
} from './SidebarActionTypes';

export type State = Readonly<{
  collapsed: boolean;
}>;

interface SetCollapsed {
  type: typeof SIDEBAR_COLLAPSED;
  value: boolean;
}

export type SidebarAction = SetCollapsed;

const INITIAL_STATE: State = {
  collapsed: false,
};

export default function sidebarReducer(state: State = INITIAL_STATE, action: SidebarAction): State {
  switch (action.type) {
    case SIDEBAR_COLLAPSED:
      return {
        ...state,
        collapsed: action.value,
      };
    default:
      return state;
  }
}
