import { DeepReadonly } from 'utility-types';
import { Breach } from 'hibp';
import {
  SET_HIBP_PASSWORDS,
  SET_HIBP_BREACHED_WEBSITES,
  SET_HIBP_USERNAMES,
  CLEAR_HIBP_DATA,
  CLEAR_HIBP_USERNAMES,
  STOP_HIBP_USERNAME_CHECKS,
  HIBPActionTypes,
} from './HIBPActionTypes';

export type State = DeepReadonly<{
  passwords: {
    [K: string]: {
      isBreached: boolean;
      lastChecked: number;
    };
  };
  breachedWebsites: {
    [K: string]: Breach[];
  };
  usernames: {
    [K: string]: {
      breaches: Breach[];
      lastChecked: number;
    };
  };
  stopHibpPolling: boolean;
}>

export function defaultState(): State {
  return {
    passwords: {},
    breachedWebsites: {},
    usernames: {},
    stopHibpPolling: false,
  };
}

export default function hibpReducer(state = defaultState(), action: HIBPActionTypes): State {
  switch (action.type) {
    case SET_HIBP_PASSWORDS:
      return {
        ...state,
        passwords: action.payload,
      };
    case SET_HIBP_BREACHED_WEBSITES:
      return {
        ...state,
        breachedWebsites: action.payload,
      };
    case SET_HIBP_USERNAMES:
      return {
        ...state,
        usernames: {
          ...state.usernames,
          ...action.payload,
        },
      };
    case CLEAR_HIBP_DATA:
      return {
        ...defaultState(),
        breachedWebsites: {
          ...state.breachedWebsites,
        },
      };
    case CLEAR_HIBP_USERNAMES:
      return {
        ...state,
        usernames: {},
      };
    case STOP_HIBP_USERNAME_CHECKS:
      return {
        ...state,
        stopHibpPolling: true,
      };
    default:
      return state;
  }
}
