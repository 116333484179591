import type LicenseService from '@avira-pwm/services/LicenseService';
import type OEService from '@avira-pwm/services/OEService';
import React from 'react';

type Value = {
  oe?: OEService;
  license?: LicenseService;
};

const ServiceContext = React.createContext<Value>({});

export default ServiceContext;
