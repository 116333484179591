import React, { useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookie from 'js-cookie';

import MessengerContext from '../../componentLib/MessengerContext';
import { setOeIds } from '../TrackingActions';
import { State } from '../TrackingReducer';

const AARRRSync: React.FC<{}> = ({ children }) => {
  const { stateOeDeviceId, stateOeUserId } = useSelector(({ tracking }: { tracking: State }) => ({
    stateOeDeviceId: tracking.oeDeviceId,
    stateOeUserId: tracking.oeUserId,
  }));
  const messenger = useContext(MessengerContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (messenger!.isConnected()) {
      messenger!.send('aarrr:getData', null, (_err, { oeUserId, oeDeviceId }: any = {}) => {
        dispatch(setOeIds(oeDeviceId, oeUserId));
      });
    } else {
      const oeDeviceId = Cookie.get('DEVICE_ID') || stateOeDeviceId;
      const oeUserId = Cookie.get('USER_ID') || stateOeUserId;

      dispatch(setOeIds(oeDeviceId, oeUserId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messenger, dispatch]);

  return (
    <>
      {children}
    </>
  );
};

export default AARRRSync;
