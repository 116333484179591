import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Sidebar as PwmSideBar,
  SidebarToggle,
  SidebarGroup,
  SidebarItem,
  SidebarText,
  SidebarImage,
} from 'pwm-components/components/Sidebar';
import ToggleSwitch from 'pwm-components/components/ToggleSwitch';

import GetStartedIcon from 'pwm-components/icons/GetStarted';

import SidebarMenuItem from './SidebarMenuItem';
import iconSecurityStatus from '../../img/icon-securitystatus.png';
import iconSecurityStatus2x from '../../img/icon-securitystatus@2x.png';
import iconSecurityAlert from '../../img/icon-securitystatus-alert.png';
import iconSecurityAlert2x from '../../img/icon-securitystatus-alert@2x.png';
import iconConnectedDevices from '../../img/icon-connecteddevices.png';
import iconConnectedDevices2x from '../../img/icon-connecteddevices@2x.png';
import iconSettings from '../../img/icon-settings.png';
import iconSettings2x from '../../img/icon-settings@2x.png';
import iconPhone from '../../img/mobile_section.png';
import iconPhone2x from '../../img/mobile_section@2x.png';
import iconSecurityStatusActive from '../../img/icon-securitystatus-active.png';
import iconSecurityStatusActive2x from '../../img/icon-securitystatus-active@2x.png';
import iconConnectedDevicesActive from '../../img/icon-connecteddevices-active.png';
import iconConnectedDevicesActive2x from '../../img/icon-connecteddevices-active@2x.png';
import iconSettingsActive from '../../img/icon-settings-active.png';
import iconSettingsActive2x from '../../img/icon-settings-active@2x.png';
import iconSidebarToggle from '../../img/sidebar-toggle.png';

import config from '../../config';
import { setCollapseState } from '../SidebarActions';
import { getSecurityStatus } from '../../securityStatus/selectors';
import { toggleTheme } from '../../preferences/PreferencesActions';
import { SidebarItem as SidebarItemType } from './SidebarTypes';
import { isCurrentUserPro, isUpsellAllowed } from '../../user/selectors';

type Props = {
  items: SidebarItemType[];
}

const Sidebar: React.FC<Props> = ({ items: myDataItems }) => {
  const collapsed = useSelector((state: any) => state.sidebar.collapsed);
  const dispatch = useDispatch();
  const location = useLocation();
  const { metadata: { toImprove } } = useSelector(getSecurityStatus);
  const isSecure = toImprove === 0;
  const theme = useSelector((state: any) => state.preferences.theme);
  const securityStatusEnabled = useSelector(state => (
    isCurrentUserPro(state) || isUpsellAllowed(state)
  ));

  function onToggleClick(): void {
    dispatch(setCollapseState(!collapsed));
  }

  const securityStatus = {
    id: 'security-status',
    imgSrc: iconSecurityStatus,
    imgSrc2x: iconSecurityStatus2x,
    imgSrcActive: iconSecurityStatusActive,
    imgSrcActive2x: iconSecurityStatusActive2x,
    label: 'securityStatus',
    linkTo: '/security-status',
  };

  const getStarted = {
    id: 'get-started',
    icon: (
      <GetStartedIcon
        variant="warning"
      />
    ),
    label: 'getStarted',
    linkTo: '/get-started',
    textColor: 'darkorange',
  };

  const otherMenuItems = [
    {
      id: 'connected-devices',
      imgSrc: iconConnectedDevices,
      imgSrc2x: iconConnectedDevices2x,
      imgSrcActive: iconConnectedDevicesActive,
      imgSrcActive2x: iconConnectedDevicesActive2x,
      label: 'devices',
      linkTo: '/connected-devices',
    },
    {
      id: 'settings',
      imgSrc: iconSettings,
      imgSrc2x: iconSettings2x,
      imgSrcActive: iconSettingsActive,
      imgSrcActive2x: iconSettingsActive2x,
      label: 'settings',
      linkTo: '/settings',
    },
  ];
  const bottomMenuItems = [
    {
      id: 'mobile',
      imgSrc: iconPhone,
      imgSrc2x: iconPhone2x,
      imgSrcActive: iconPhone,
      imgSrcActive2x: iconPhone2x,
      label: 'mobile',
      linkTo: '/mobile/navigation',
    },
  ];

  const pathName = location.pathname;

  return (
    <PwmSideBar
      collapsed={collapsed}
      id="a-sidebar"
    >
      <ul>
        <SidebarGroup>
          {
            myDataItems.map(menuItem => (
              <SidebarMenuItem
                key={menuItem.id}
                {...menuItem}
                active={pathName.indexOf(`/${menuItem.id}`) >= 0}
              />
            ))
          }
        </SidebarGroup>
        {
          config.enableSecurityStatus && securityStatusEnabled
          && (
            <SidebarGroup>
              <SidebarMenuItem
                {...securityStatus}
                active={pathName.indexOf('/security-status') >= 0}
                imgOverlay={!isSecure
                  ? (
                    <img
                      className="u-pos-a a-alert-icon"
                      src={iconSecurityAlert}
                      srcSet={`${iconSecurityAlert} 1x, ${iconSecurityAlert2x} 2x`}
                      style={{ top: '-8px', right: '-8px' }}
                      alt="alert"
                    />
                  )
                  : null}
              />
            </SidebarGroup>
          )
        }
        <SidebarGroup>
          <SidebarMenuItem
            {...getStarted}
            active={pathName.indexOf('/get-started') >= 0}
          />
        </SidebarGroup>
        <SidebarGroup>
          {
            otherMenuItems.map(menuItem => (
              <SidebarMenuItem
                key={menuItem.id}
                {...menuItem}
                active={pathName.indexOf(`/${menuItem.id}`) >= 0}
              />
            ))
          }
        </SidebarGroup>
      </ul>
      <SidebarGroup
        position="absolute"
        bottom="0"
        left="0"
        right="0"
      >
        {
          config.allowDarkModeToggle && !collapsed
          && (
            <SidebarItem
              onClick={() => dispatch(toggleTheme())}
            >
              <SidebarImage style={{ position: 'relative' }}>
                <ToggleSwitch
                  name="modeToggle"
                  checked={theme === 'dark'}
                  onChange={() => dispatch(toggleTheme())}
                />
              </SidebarImage>

              <SidebarText>
                Dark mode
              </SidebarText>
            </SidebarItem>
          )
        }
        {
          bottomMenuItems.map(menuItem => (
            <SidebarMenuItem
              key={menuItem.id}
              {...menuItem}
              active={pathName.indexOf(`/${menuItem.id}`) >= 0}
            />
          ))
        }
      </SidebarGroup>
      <SidebarToggle onClick={onToggleClick}>
        <img src={iconSidebarToggle} alt="Sidebar Toggle Button" />
      </SidebarToggle>
    </PwmSideBar>
  );
};

export default Sidebar;
