import configResolver, { createConfigVerifier } from './configResolver';

import commonConfig from './config/common.json';
import stagingConfig from './config/staging.json';
import testConfig from './config/test.json';
import betaConfig from './config/beta.json';
import nightlyConfig from './config/nightly.json';
import productionConfig from './config/production.json';
import spotlightConfig from './config/spotlight.json';
import spotlightTestConfig from './config/spotlightTest.json';
import spotlightStagingConfig from './config/spotlightStaging.json';
import spotlightNightlyConfig from './config/spotlightNightly.json';
import spotlightProductionConfig from './config/spotlightProduction.json';
import internalConfig from './config/internal.json';

import { version } from '../package.json';

const verifyConfig = createConfigVerifier(commonConfig);

// Important: Some entries on commonConfig default to test
const configMap = {
  development: verifyConfig(stagingConfig),
  test: verifyConfig(testConfig),
  staging: verifyConfig(stagingConfig),
  beta: verifyConfig(betaConfig),
  nightly: verifyConfig(nightlyConfig),
  production: verifyConfig(productionConfig),
  spotlight: verifyConfig(spotlightConfig),
  spotlightNightly: verifyConfig(spotlightNightlyConfig),
  spotlightProduction: verifyConfig(spotlightProductionConfig),
  spotlightStaging: verifyConfig(spotlightStagingConfig),
  spotlightTest: verifyConfig(spotlightTestConfig),
  internal: verifyConfig(internalConfig),
};

type Envs = keyof typeof configMap;

let env: Envs = process.env.NODE_ENV;
const reactEnv: Envs = process.env.REACT_APP_ENV as Envs;

if (process.env.REACT_APP_ENV && configMap[reactEnv]) {
  env = reactEnv;
}

const localBuild = (process.env.NODE_ENV === 'development');

const remoteLogger = !!process.env.REACT_APP_REMOTE_LOGGER;

const useProxy = (localBuild && process.env.REACT_APP_PROXY === 'true');

export default {
  ...configResolver(commonConfig, configMap, env),
  version,
  buildNumber: process.env.REACT_APP_BUILD_NUMBER ?? 0,
  localBuild,
  remoteLogger,
  useProxy,
};
