import React, { useState, useEffect } from 'react';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

type InfoPopupBoxProps = {
  visible?: boolean;
  displayNone?: boolean;
}

const InfoPopupBox = styled(Box).attrs<React.ComponentProps<typeof Box> & InfoPopupBoxProps>(({
  visible = false,
  displayNone = false,
  position = 'absolute',
  bg = 'background',
  p = 'm',
  pt = 'xs',
  ...props
}) => ({
  ...props,
  visible,
  displayNone,
  position,
  bg,
  p,
  pt,
})) <InfoPopupProps>`
  z-index: 30;
  display: ${({ displayNone }) => (displayNone && 'none')};
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
  transition: opacity ${({ theme: { transition } }) => transition.duration.long};
`;

InfoPopupBox.displayName = 'InfoPopup';

type InfoPopupProps = React.ComponentProps<typeof Box> & {
  show: boolean;
};

const InfoPopup: React.FC<InfoPopupProps> = ({ children, show, ...props }) => {
  const [visible, setVisible] = useState(show);
  const [displayNone, setDisplayNone] = useState(!visible);

  useEffect(() => {
    let displayTimeout: (number | null) = null;
    if (show) {
      setDisplayNone(false);
      setVisible(true);
    } else {
      setVisible(false);
      displayTimeout = window.setTimeout(() => setDisplayNone(true), 400);
    }

    return (() => {
      if (displayTimeout) {
        clearTimeout(displayTimeout);
      }
    });
  }, [show]);

  return (
    <InfoPopupBox
      visible={visible}
      displayNone={displayNone}
      {...props}
    >
      {children}
    </InfoPopupBox>
  );
};

export default InfoPopup;
