import styled from 'pwm-components/styled';

export default styled('ul')`
  margin-bottom: ${({ theme }) => theme.space.m};
  margin-left: 14px;
  padding-left: 4px;
  list-style: disc;

  & > li {
    padding-left: ${({ theme }) => theme.space.xs};
    margin-bottom: ${({ theme }) => theme.space.xxs}
  }
`;
