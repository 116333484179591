import { DeepReadonly } from 'utility-types';
import {
  OE_USER_DATA_STORE,
  OE_SET_PAYMENT_URL,
} from './OEActionTypes';
import config from '../config';

export type State = DeepReadonly<{
  paymentUrl: string | null;
  status_tfa: string | null;
  id: string | null;
}>;

export function defaultState(): State {
  return {
    paymentUrl: null,
    status_tfa: null,
    id: null,
  };
}

export default function oeDataReducer(state = defaultState(), action: any): State {
  switch (action.type) {
    case OE_USER_DATA_STORE:
      return {
        ...state,
        ...action.data,
      };
    case OE_SET_PAYMENT_URL:
      return {
        ...state,
        paymentUrl: action.paymentUrl || config.getPWMProLink,
      };
    default:
      return state;
  }
}
