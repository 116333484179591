import { identity } from 'lodash';
import { createSelector } from 'reselect';
import sumBy from 'lodash/sumBy';
import { getStartedConfig } from './GetStartedHelper';

const getRelevantMilestones = createSelector(
  state => state.userData.milestones,
  state => state.user.unregisteredMilestones,
  state => state.dashboard.isUnregisteredMode,
  (registeredMilestones, unregisteredMilestones, isUnregisteredMode) => (
    isUnregisteredMode ? unregisteredMilestones : registeredMilestones
  ),
);

export const createTileDataSelector = config => createSelector(
  identity,
  getRelevantMilestones,
  state => state.dashboard.extensionConnected,
  (state, milestones, extensionConnected) => {
    const tiles = config.filter(tile => !tile.disable).map(({ tileId, link, steps }) => {
      const stepResults = steps
        .filter(step => (typeof step.isApplicable === 'function' ? step.isApplicable(state) : true))
        .map(step => ({
          ...step,
          completed: step.isAchieved
            ? step.isAchieved({ milestones, extensionConnected })
            : Boolean(milestones && milestones[step.stepId]),
        }));

      const maxPoints = stepResults.length;
      const points = sumBy(stepResults, step => Number(step.completed));
      return {
        tileId,
        link,
        maxPoints,
        points,
        started: points > 0,
        completed: points === maxPoints,
        steps: stepResults,
      };
    });

    return {
      tiles,
      maxPoints: sumBy(tiles, 'maxPoints'),
      points: sumBy(tiles, 'points'),
    };
  },
);


export const getStartedTilesSelector = createTileDataSelector(getStartedConfig);
