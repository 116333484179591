//
//     Symantec copyright header start
//
// Copyright © 2016, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//

import constants from './VTConstants';
  
const { logger } = SymBfw;
const {
  isNil, isntNil, urlProtocol, getUrlTLD, getCompanyDomain
} = SymBfw.utils;


class LoginSearchManager {
  constructor() {
    this._httpMap = new Map();
    this._httpsMap = new Map();
  }

  /**
    * @function clearMaps
    * @desc clears both http and https maps
    * */
  clearMaps() {
    this._httpMap.clear();
    this._httpsMap.clear();
  }

  /**
    * @function _getProtocolMap
    * @desc get http/https map. Create one if undefined
    * @param {string} protocol. Get map for the given protocol
    * @private
    * */
  _getProtocolMap(protocol) {
    let protocolMap;
    switch (protocol) {
      case constants.HTTP_PROTOCOL:
      case null:
        protocolMap = this._httpMap;
        break;
      case constants.HTTPS_PROTOCOL:
        protocolMap = this._httpsMap;
        break;
      default:
        break;
    }
    return protocolMap;
  }

  /**
    * @function _getSubmapForKey
    * @desc Get submap for given key.Creates a sub map and in the parentMap if not found
    * @param {object} parentMap in which we need to create the new key/value pair
    * @param {string} key Key for which you need to create the submap
    * @return newly created submap
    * @private
    * */
  _getSubmapForKey(parentMap, key) {
    let subMap = parentMap.get(key);
    if (isNil(subMap)) {
      subMap = new Map();
      parentMap.set(key, subMap);
    }
    return subMap;
  }

  /**
    * @function _addLoginIntoMap
    * @desc Adds a login entry into given map
    * @param {object} Login object that we want to add into the map
    * @param {object} Map object in which you want to add the new login object
    * @private
    * */
  _addLoginIntoMap(login, map) {
    const loginGUID = login.getPath();
    if (isntNil(map.get(loginGUID))) {
      logger.error('Item to be added exists in the map');
      return;
    }
    map.set(loginGUID, true);
  }

  /**
    * @function addItemToMap
    * @desc adds an item to search map
    * @param {Login} item to be added into the map
    * */
  addItemToMap(item) {
    if (isNil(item)) {
      throw new Error('addItemToMap did not get the right parameters');
    }
    const loginURL = item.getLoginURL();
    const protocolMap = this._getProtocolMap(urlProtocol(loginURL));
    // get the TLD sub-map
    const tld = getUrlTLD(loginURL);
    const companyDomain = getCompanyDomain(loginURL);
    if (isNil(tld) && isNil(companyDomain)) {
      // we do not care about this URL. It cannot be visited in a browser.
      return;
    }
    if (isNil(tld)) {
      const ipAddressSubMap = this._getSubmapForKey(protocolMap, companyDomain);
      this._addLoginIntoMap(item, ipAddressSubMap);
      return;
    }

    const tldSubMap = this._getSubmapForKey(protocolMap, tld);
    const companyDomainSubMap = this._getSubmapForKey(tldSubMap, companyDomain);
    this._addLoginIntoMap(item, companyDomainSubMap);
  }

  /**
* @function removeItemFromMap
* @desc removes given item from the map
* @param {Login} item to be removed from the map
* @return true/false
* */
  removeItemFromMap(item) {
    if (isNil(item)) {
      throw new Error('removeItemFromMap did not get the right parameters');
    }
    const loginURL = item.getLoginURL();
    const loginPath = item.getPath();

    // Get which map to add into if there is no input map
    const protocolMap = this._getProtocolMap(urlProtocol(loginURL));
    // find the item in map
    const tld = getUrlTLD(loginURL);
    const companyDomain = getCompanyDomain(loginURL);
    if (!protocolMap.has(tld)) {
      logger.info('Item not found');
      return;
    }
    const tldSubMap = protocolMap.get(tld);
    if (!tldSubMap.has(companyDomain)) {
      logger.info('Item not found');
      return;
    }
    const companyDomainSubMap = tldSubMap.get(companyDomain);
    if (!companyDomainSubMap.has(loginPath)) {
      logger.info('Item not found');
      return;
    }
    companyDomainSubMap.delete(loginPath);

    if (companyDomainSubMap.size !== 0) {
      return;
    }
    // Delete companyDomainSubMap if the map is empty
    tldSubMap.delete(companyDomain);
    if (tldSubMap.size !== 0) {
      return;
    }
    // Delete tldSubMap if the map is empty
    protocolMap.delete(tld);
  }

  /**
    * @function rawSearch
    * @desc Returns submap for the given searchkey
    * @param {string} searchKey to look for in the map
    * @param {object} Login object's array.Returns null if not found.
    */
  rawSearch(searchKey) {
    const tld = getUrlTLD(searchKey);
    const companyDomain = getCompanyDomain(searchKey);
    if (isNil(tld) || isNil(companyDomain)) {
      logger.info('Item not found');
      return null;
    }
    const protocolMap = this._getProtocolMap(urlProtocol(searchKey));
    if (isNil(protocolMap)) {
      return null;
    }
    if (!protocolMap.has(tld)) {
      logger.info('Item not found');
      return null;
    }
    const tldSubMap = protocolMap.get(tld);
    if (!tldSubMap.has(companyDomain)) {
      logger.info('Item not found');
      return null;
    }
    return tldSubMap.get(companyDomain);
  }
}

const loginSearchManager = new LoginSearchManager();
export default loginSearchManager;
