import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';


const WhyRandom = () => (
  <>
    <Box mb="m">
      <Paragraph mb="xs">
        <FormattedMessage
          id="dashboard.getStarted.whyRandom.step"
          defaultMessage="Many people use their personal data or favorite movies, actors, activities to create passwords. This makes it very easy for hackers to guess your passwords using online research or social engineering."
        />
      </Paragraph>
      <Paragraph mb="xs">
        <FormattedMessage
          id="dashboard.getStarted.whyRandom.step1"
          defaultMessage="Besides, databases with millions of hacked and commonly used passwords are available online."
        />
      </Paragraph>

      <Media
        image={(
          <img
            src={hint}
            srcSet={`${hint} 1x, ${hint2x} 2x`}
            alt="hint"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.whyRandom.step2"
              defaultMessage="Be random and make it impossible for hackers to guess your passwords."
            />
          </Text>
        )}
      />
    </Box>
  </>
);

export default WhyRandom;
