import React from 'react';

import Box from 'pwm-components/components/Box';
import { DataListCard } from './DataListComponents';

type Props = {
  onClick?: () => void;
  placeholder?: boolean;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  disabled?: boolean;
}

const DataListItem: React.FC<Props> = ({
  onMouseEnter, onMouseLeave,
  placeholder, children, onClick, disabled,
}) => (
  <Box
    mb="xs"
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <DataListCard
      onClick={onClick}
      placeholder={placeholder}
      hoverable={!placeholder && !disabled}
      disabled={disabled}
    >
      <Box
        display="flex"
        alignItems="center"
        opacity={disabled ? 0.4 : 1}
        style={{ pointerEvents: disabled ? 'none' : 'auto' }}
      >
        {children}
      </Box>
    </DataListCard>
  </Box>
);

DataListItem.defaultProps = {
  disabled: false,
};

export default DataListItem;
