import React from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';
import styled from 'pwm-components/styled';
import LockIcon from 'pwm-components/icons/Lock';
import ErrorIcon from 'pwm-components/icons/Error';
import NoConnectionIcon from 'pwm-components/icons/NoConnection';
import Loading from 'pwm-components/components/Loading';

export enum FileStatusEnum {
  NotFound = 'notFoundError',
  DownloadFailed = 'downloadFailedError',
  DecryptFailed = 'decryptFailedError',
  UploadFailed = 'fileUploadError',
  SizeLimit = 'sizeLimitError',
  StorageLimit = 'storageLimitError',
  Downloading = 'downloading',
  Uploading = 'uploading',
  Decrypting = 'decrypting',
  Downloaded = 'downloaded',
  Uploaded = 'uploaded',
}

const IconWrapper = styled('div')<{ showWrapper?: boolean }>`
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ showWrapper }) => (showWrapper ? 'rgba(0,0,0,0.5)' : 'none')};
`;

// eslint-disable-next-line complexity
export function getIconFromStatus({
  status, defaultShowWrapper = false, defaultIcon: DefaultIcon,
}: {
  status: FileStatusEnum; defaultShowWrapper: boolean; defaultIcon?: React.FC<any>;
}): JSX.Element {
  let showWrapper = defaultShowWrapper;
  let icon;
  switch (status) {
    case FileStatusEnum.DecryptFailed:
      icon = <LockIcon size="large" variant={showWrapper ? 'light' : 'normal'} />;
      break;
    case FileStatusEnum.UploadFailed:
    case FileStatusEnum.DownloadFailed:
    case FileStatusEnum.StorageLimit:
      showWrapper = true;
      icon = <NoConnectionIcon size="large" variant="light" />;
      break;
    case FileStatusEnum.NotFound:
    case FileStatusEnum.SizeLimit:
      icon = <ErrorIcon size="large" variant="alert" />;
      break;
    default:
      icon = DefaultIcon ? <DefaultIcon size="large" /> : <Loading />;
      break;
  }

  return (
    <IconWrapper showWrapper={showWrapper}>
      {icon}
    </IconWrapper>
  );
}

export function isStatusResolved(status: FileStatusEnum): boolean {
  switch (status) {
    case FileStatusEnum.Uploaded:
    case FileStatusEnum.Downloaded:
      return true;
    default:
      return false;
  }
}

export function isStatusResolvable(status: FileStatusEnum): boolean {
  if (isStatusResolved(status)) return true;

  switch (status) {
    case FileStatusEnum.NotFound:
    case FileStatusEnum.DecryptFailed:
    case FileStatusEnum.UploadFailed:
    case FileStatusEnum.SizeLimit:
    case FileStatusEnum.StorageLimit:
      return false;
    default:
      return true;
  }
}

export function getMessageFromStatus(
  status: FileStatusEnum, intl?: IntlShape,
): JSX.Element | string {
  let id = null;

  if (status && !isStatusResolved(status)) {
    switch (status) {
      case FileStatusEnum.Uploading:
        id = 'dashboard.account.details.files.uploading';
        break;
      default:
        id = `dashboard.account.details.files.${status}`;
        break;
    }
  } else {
    id = 'dashboard.account.details.files.loading';
  }

  if (intl) {
    return intl.formatMessage({ id });
  }

  return (
    <FormattedMessage id={id} />
  );
}
