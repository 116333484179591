/* eslint-disable @typescript-eslint/camelcase, camelcase */
import { v4 as uuidv4 } from 'uuid';
import { AsyncStorage } from '@avira-pwm/storage';

import config from '../config';

const AUC_GUID = 'AUC_GUID';

export type AucConfig = {
  key: string;
  user_guid: string;
  locale: string;
  metadata: {
    product: string;
    product_version: string;
    lang: string;
    platform: string;
  };
  storage: Storage;
  api: {
    basepath: string;
  };
}

export default async function getAucConfig(
  storage: AsyncStorage,
): Promise<AucConfig> {
  let { [AUC_GUID]: user_guid } = await storage.get([AUC_GUID]);

  if (!user_guid) {
    user_guid = uuidv4();
    await storage.set({ [AUC_GUID]: user_guid });
  }

  return {
    key: config.auc.api_key,
    user_guid: user_guid as string,
    locale: navigator.language,
    metadata: {
      product: config.auc.product,
      product_version: config.version,
      lang: navigator.language,
      platform: navigator.userAgent,
    },
    // normal localStorage here; check src/lib/Storage.ts
    storage: window.localStorage,
    api: {
      basepath: config.auc.server,
    },
  };
}
