import React from 'react';
import { RouteComponentProps, RouteProps, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';

import ConfigRenderer from '../../componentLib/ConfigRenderer';
import OESpotlightLogin from '../../oe/components/OESpotlightLogin';
import Loading from './Loading';
import { trackTimeout } from '../../tracking/TrackingActions';

type Props = RouteComponentProps
& {
  component: RouteProps['component'];
};

const OERouteComponent: React.FC<Props> = ({ match: { path }, component: Component }) => {
  const dispatch = useDispatch();
  return (
    <>
      <ConfigRenderer condition="spotlight" negate>
        <Route
          path={path}
          component={Component}
        />
      </ConfigRenderer>
      <ConfigRenderer condition="spotlight">
        <Route
          path={path}
          render={() => (
            <OESpotlightLogin
              renderWhileLoading={() => (
                <Loading
                  onRetry={() => window.location.reload()}
                  timeout={40}
                  onTimeout={() => {
                    (dispatch as ThunkDispatch<any, any, any>)(trackTimeout('OERouteComponent'));
                  }}
                >
                  <FormattedMessage
                    id="dashboard.status.authenticatingUser"
                    defaultMessage="Authenticating user..."
                  />
                </Loading>
              )}
            />
          )}
        />
      </ConfigRenderer>
    </>
  );
};

export default OERouteComponent;

export function withOERouteComponent(Component: React.ComponentType): typeof OERouteComponent {
  return props => (
    <OERouteComponent {...props} component={Component} />
  );
}
