import React, { ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import {
  WrappedComponentProps, injectIntl, FormattedMessage,
} from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import Button from 'pwm-components/components/Button';
import Tooltip from 'pwm-components/components/Tooltip';
import Checkbox from 'pwm-components/components/Checkbox';
import TextButton from 'pwm-components/components/TextButton';
import PasswordInput from 'pwm-components/components/PasswordInput';
import FakeInputField from '../../componentLib/FakeInputField';
import iconUnlock from '../../img/icon-unlock.png';
import iconUnlock2x from '../../img/icon-unlock@2x.png';

enum USAGE_AS {
  create,
  unlock,
}

const PwmIconContainer = styled(Box)<{ lockReason?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${props => (props.lockReason === 'autolock' ? '24px' : '60px')};
  margin-top: 8px;

  @media screen and (max-width: 500px) {
    margin: 24px 16px;

    & > img {
      visibility: hidden;
      height: 24px;
    }
  }
`;

export type Props = WrappedComponentProps & {
  usageAs: keyof typeof USAGE_AS;
  passwordRef?: React.Ref<HTMLInputElement>;
  errorPassword?: { message: string };
  errorConfirm?: { message: string };
  errorCheckbox?: { message: string };
  loading: boolean;
  userEmail?: string;
  isUnregisteredMode?: boolean;
  password: string;
  passwordConfirm: string;
  checkboxConfirm: boolean;
  passwordScore?: {
    score: number;
    feedback?: Array<string>;
  } | null;
  user: any;
  onFormSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onPasswordConfirmChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onCheckboxChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onLogout?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

// eslint-disable-next-line complexity
const MasterPassword: React.FC<Props> = ({
  intl,
  usageAs,
  passwordRef,
  loading,
  isUnregisteredMode,
  password,
  passwordConfirm,
  checkboxConfirm,
  userEmail,
  errorPassword,
  errorConfirm,
  errorCheckbox,
  passwordScore,
  user,
  onFormSubmit,
  onPasswordChange,
  onPasswordConfirmChange,
  onCheckboxChange,
  onLogout,
}) => {
  let headerHTML: JSX.Element | null = null;
  let resetAccountHTML: JSX.Element | null = null;
  let inputPlaceholderId = '';
  let actionLabelId = '';
  let lockReasonMessage: JSX.Element | null = null;

  const { lockReason } = user;

  if (usageAs === 'create') {
    inputPlaceholderId = 'masterPassword.create.placeholder';
    actionLabelId = 'masterPassword.create.action';

    headerHTML = (
      <Box
        height="80px"
        display="flex"
        alignItems="center"
      >
        <strong>
          <Text variant="dark">
            <FormattedMessage
              id="masterPassword.create.masterPassword"
            />
          </Text>
        </strong>
      </Box>
    );
  } else {
    inputPlaceholderId = 'masterPassword.unlock.placeholder';
    actionLabelId = 'masterPassword.unlock.action';

    headerHTML = (
      <PwmIconContainer
        lockReason={lockReason}
      >
        <img
          src={iconUnlock}
          srcSet={`${iconUnlock} 1x, ${iconUnlock2x} 2x`}
          alt="Icon unlock"
        />
      </PwmIconContainer>
    );

    if (!isUnregisteredMode) {
      resetAccountHTML = (
        <Box
          textAlign="center"
        >
          <Link
            to="/reset-account"
            id="a-forgotMasterPassword-action"
          >
            <Text>
              <FormattedMessage
                id="masterPassword.account.forgotMasterPassword"
                defaultMessage="Forgot Master Password?"
              />
            </Text>
          </Link>
        </Box>
      );
    }
  }

  if (lockReason && lockReason === 'autolock') {
    lockReasonMessage = (
      <Box
        textAlign="left"
        mb="s"
      >
        <Text variant="dark">
          <FormattedMessage
            id="masterPassword.unlockScreen.lockReason"
            defaultMessage="Password Manager was automatically locked as configured in your settings"
          />
        </Text>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100%" flexGrow={1}>
      {headerHTML}
      {lockReasonMessage}
      <form
        id="form-section"
        onSubmit={onFormSubmit}
      >
        {/* Prevent Firefox from saving the master password */}
        <FakeInputField type="password" defaultValue="abc" />
        <FakeInputField type="password" defaultValue="def" />

        <Box
          id="password"
          mb="m"
        >
          <PasswordInput
            ref={passwordRef}
            placeholder={intl.formatMessage({ id: inputPlaceholderId })}
            value={password}
            onChange={onPasswordChange}
            error={errorPassword
              ? (
                <span dangerouslySetInnerHTML={{ __html: errorPassword.message }} />
              )
              : null}
            autoComplete="new-password"
            feedback={passwordScore ? passwordScore.feedback : undefined}
            score={passwordScore ? passwordScore.score : undefined}
            id="a-master-password"
          />
        </Box>

        {
          usageAs === 'create' && (
            <Box>
              <Box
                id="confirmPassword"
                mb="m"
              >
                <PasswordInput
                  placeholder={intl.formatMessage({ id: 'masterPassword.verify.placeholder' })}
                  value={passwordConfirm}
                  onChange={onPasswordConfirmChange}
                  error={errorConfirm ? errorConfirm.message : null}
                  autoComplete="new-password"
                  id="a-master-password-confirm"
                />
              </Box>
              <Box
                id="consentMasterPassword"
                mb="m"
              >
                <Tooltip
                  align="left"
                  bgOverride="scarlet"
                  colorOverride="white"
                  visible={!!errorCheckbox}
                >
                  {errorCheckbox && errorCheckbox.message ? errorCheckbox.message : null}
                </Tooltip>
                <Box>
                  <Checkbox
                    id="a-master-password-consent"
                    onChange={onCheckboxChange}
                    checked={checkboxConfirm}
                    label={(
                      <Text variant="dark">
                        <FormattedMessage
                          id="masterPassword.consent.checkbox"
                          defaultMessage="I understand that the Master Password cannot be recovered"
                        />
                      </Text>
                    )}
                  />
                </Box>
              </Box>
            </Box>
          )
        }

        <Box
          mb="xxl"
        >
          <Button
            fullWidth
            disabled={!!errorPassword || !!errorConfirm || !!errorCheckbox || loading}
            loading={loading}
            variant="login"
            id="a-unlock-action"
          >
            <FormattedMessage id={actionLabelId} />
          </Button>
        </Box>
        {resetAccountHTML}
      </form>
      <Box
        textAlign="center"
        mt="a"
        mb="xxl"
      >
        <Box>
          <Text variant="dark" size="small">
            <FormattedMessage
              id="masterPassword.userInfo.loggedIn"
              values={{ userEmail }}
            />
          </Text>
        </Box>
        <Box>
          <TextButton
            variant="link"
            focusable
            onClick={onLogout}
            id="a-logout-action"
          >
            <Text size="small">
              <FormattedMessage
                id="verifyEmail.logout"
                defaultMessage="Log out"
              />
            </Text>
          </TextButton>
        </Box>
      </Box>
    </Box>
  );
};

export default injectIntl(MasterPassword);
