import React from 'react';
import { node } from 'prop-types';
import Grid from 'pwm-components/objects/Grid';
import Column from 'pwm-components/objects/Column';
import Box from 'pwm-components/components/Box';

const TwoColumnView = ({ leftSection, rightSection }) => (
  <Grid
    justifyContent="spaceBetween"
    alignItems="flexStart"
  >
    <Column size="auto">
      {leftSection}
    </Column>
    <Column size="auto">
      <Box display="flex" justifyContent="center">
        {rightSection}
      </Box>
    </Column>
  </Grid>
);

TwoColumnView.propTypes = {
  leftSection: node.isRequired,
  rightSection: node.isRequired,
};

export default TwoColumnView;
