/* eslint-disable no-continue */
//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2016, Symantec Corporation, All rights reserved.
//
// Change History :
// Wed Feb 20 2019 rahuraman Adding to Git.
//
import constants from './VTConstants';
import telemetryWrapper from './VTTelemetryWrapper';
import ItemManager from './VTItemManager';
import nodeSync from './VTNodeSync';
import loginSearchManager from './VTLoginSearchManager';
import TagManager from './VTTagManager';
import vaultManager from './VTVaultManager';

const {
  logger, utils: {
    isNil, isntNil, isEmptyObject, isString, isFunction, isArray, isSuccessCode
  }
} = SymBfw;

const { TAG } = constants;

/**
 * @typedef {import('./VTLogin').default} Login
 */

class LoginManager extends ItemManager {
  constructor() {
    super('LoginManager');
    this.items = {};
    this.tagType = TAG.LOGIN;
  }

  async addItem(item, shouldSyncToCloud = true) {
    const returnValue = await super.addItem(item, shouldSyncToCloud);
    if (shouldSyncToCloud) {
      telemetryWrapper.sendLoginAdd(telemetryWrapper.LOGIN_TYPE_LOCAL);
    }
    return returnValue;
  }

  async addItems(loginItems, shouldSyncToCloud = true) {
    const returnValue = await super.addItems(loginItems, shouldSyncToCloud);
    if (shouldSyncToCloud) {
      telemetryWrapper.sendLoginAdd(telemetryWrapper.LOGIN_TYPE_LOCAL);
    }
    return returnValue;
  }

  async addUpdateItems(loginItems, shouldSyncToCloud = true) {
    if (!isArray(loginItems)) {
      throw new Error('Item must be an array of logins');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const nodeList = [];
    const self = this;
    const GUIDList = [];
    loginItems.forEach((item) => {
      const guid = item.getPath();
      const currentItem = self.items[guid];
      item.setLastUpdate(new Date().getTime() / 1000);
      if (isntNil(currentItem)) {
        self.updateItem(item, false);
      } else {
        self.addItem(item, false);
      }
      GUIDList.push(guid);
      nodeList.push(item.clone().serializeNode());
    });

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
      telemetryWrapper.sendLoginModify(telemetryWrapper.LOGIN_TYPE_LOCAL);
    }
  }

  searchKey(searchKey, callback) {
    if (isFunction(callback) === false) {
      throw new Error('Need a valid callback function');
    }

    if (Object.keys(this.items).length === 0) {
      logger.info('Empty vault');
      setTimeout(() => {
        callback([], null);
      }, 1);
      return;
    }

    if (isNil(searchKey)) {
      setTimeout(() => {
        callback(null, new Error('Search key cannot be null'));
      }, 1);
      return;
    }

    const searchKeyHostName = SymBfw.utils.baseURL(searchKey);
    const searchResults = loginSearchManager.rawSearch(searchKey);
    const orderedResults = [];
    let minIndex = Number.MAX_SAFE_INTEGER;
    const self = this;
    if (isNil(searchResults) || searchResults.size === 0) {
      setTimeout(() => {
        callback([], null);
      }, 1);
      return;
    }
    searchResults.forEach((_value, loginGUID) => {
      const item = self.getItem(loginGUID);
      const index = searchKeyHostName.indexOf(SymBfw.utils.baseURL(item.getLoginURL()));
      // -1 is not minimum. It is considered as the lowest priority in
      // search results since it is not found.
      if (index <= minIndex && index > -1) {
        orderedResults.unshift(item);
        minIndex = Math.min(minIndex, index);
        return;
      }
      orderedResults.push(item);
    });
    setTimeout(() => {
      // send telemetry
      telemetryWrapper.sendIDSVaultLoginURLCount(searchKeyHostName, orderedResults.length);
      callback(orderedResults, null);
    }, 1);
  }

  /**
   * @async
   * @function search
   * @desc Searches the collection given the input key from UI.
   * @param {string} searchKey The key you want to search on.
   * @param {boolean} isFavourite if the isFavourite=true then will search only favorite.
   * @returns {Promise<{ [key: string]: string[] }>} Promise object represent the items matches the key.
   */
  async search(searchKey, isFavourite) {
    /**
     * @type {{ [key: string]: string[] }}
     */
    const searchResult = {};
    if (isEmptyObject(this.items)) {
      logger.info('Empty vault');
      return searchResult;
    }

    if (!isString(searchKey)) {
      throw new Error('Search key cannot be null');
    }

    const searchOrder = ['siteName', 'loginURL', 'username'];
    for (const item of searchOrder) {
      searchResult[item] = [];
    }

    for (const key of Object.keys(this.items)) {
      const login = this.items[key];
      if (isFavourite === true) {
        const isFavouriteItem = login.getFavorite();
        if (isNil(isFavouriteItem) || isFavouriteItem === false) {
          continue;
        }
      }
      // match case insensitively
      searchKey = searchKey.toLowerCase();
      let siteName = login.getSiteName();
      siteName = isString(siteName) ? siteName : '';
      let loginURL = login.getLoginURL();
      loginURL = isString(loginURL) ? loginURL : '';
      let userName = login.getUsername();
      userName = isString(userName) ? userName : '';

      if (siteName.toLowerCase().indexOf(searchKey) > -1) {
        searchResult[searchOrder[0]].push(key);
      } else if (loginURL.toLowerCase().indexOf(searchKey) > -1) {
        searchResult[searchOrder[1]].push(key);
      } else if (userName.toLowerCase().indexOf(searchKey) > -1) {
        searchResult[searchOrder[2]].push(key);
      }
    }
    return searchResult;
  }

  async updateItem(item, shouldSyncToCloud = true) {
    if (isNil(item)) {
      throw new Error('Item cannot be null');
    }

    if (isNil(shouldSyncToCloud)) {
      shouldSyncToCloud = false;
    }

    const guid = item.getPath();

    const currentItem = this.items[guid];
    if (isNil(currentItem)) {
      throw new Error('New item is being added. Please use the addItem interface');
    }

    loginSearchManager.removeItemFromMap(currentItem);

    this.items[guid] = item;

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(item.clone().serializeNode(), null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
      telemetryWrapper.sendLoginModify(telemetryWrapper.LOGIN_TYPE_LOCAL);
    }
  }

  postSyncSetup() {
    for (const key in this.items) {
      if (Object.prototype.hasOwnProperty.call(this.items, key)) {
        const item = this.items[key];
        if (item.encrypted) {
          try {
            item.decrypt();
            loginSearchManager.addItemToMap(item);
          } catch (e) {
            logger.error(e);
            delete this.items[key];
            continue;
          }
        }
      }
    }
  }


  async deleteItem(guid, shouldSyncToCloud = true) {
    if (isNil(guid)) {
      return;
    }

    const item = this.items[guid];
    if (isNil(item)) {
      logger.info('No item found to delete');
      return;
    }

    // delete item from search map
    loginSearchManager.removeItemFromMap(item);

    const node = item.serializeNode(true);
    delete this.items[guid];

    if (isNil(shouldSyncToCloud)) {
      return;
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(node, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
    }

    await this.removeTagItems([guid], shouldSyncToCloud);
  }

  async deleteAllItems(shouldSyncToCloud = true) {
    const nodeList = [];
    const itemGuids = [];
    for (const key in this.items) {
      if (Object.prototype.hasOwnProperty.call(this.items, key)) {
        const node = this.items[key].serializeNode(true);
        itemGuids.push(key);
        delete this.items[key];
        nodeList.push(node);
      }
    }
    loginSearchManager.clearMaps();
    if (nodeList.length === 0) {
      // nothing to delete.
      return;
    }

    if (isNil(shouldSyncToCloud)) {
      return;
    }

    if (shouldSyncToCloud) {
      const response = await nodeSync.uploadNodes(nodeList, null);
      const { status } = response;
      if (!isSuccessCode(status)) {
        this.handleErrorResponse(response);
      }
      telemetryWrapper.sendLoginDelete(telemetryWrapper.LOGIN_TYPE_LOCAL, nodeList.length);
    }

    await this.removeTagItems(itemGuids, shouldSyncToCloud);
  }
}

export default LoginManager;
