import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button from 'pwm-components/components/Button';
import Text from 'pwm-components/components/Text';
import Box from 'pwm-components/components/Box';
import Autofill from '../../../img/autosave_and_autofill.gif';
import { StepComponentProps } from './StepProps';
import mixpanel from '../../../tracking/mixpanel';

const StepSetAsDefault: React.FC<StepComponentProps> = ({
  goToNextStep,
  stepName,
}) => {
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    mixpanel.track('PwmActivationShown', { stepName });
  }, [stepName]);

  const onClick = useCallback((): void => {
    mixpanel.track('PwmActivationClick', { stepName, buttonLabel: 'Set as Default' });
    goToNextStep();
  }, [stepName, goToNextStep]);

  const onClickGif = useCallback((): void => {
    const current = Date.now();
    if (current - timestamp > 23000) {
      setTimestamp(current); // reset
    }
  }, [timestamp]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      width="485px"
      flex="0 1 485px"
      height="90%"
      m="xxxl"
      ml="64px"
    >
      <Text size="h1" variant="light">
        <FormattedMessage id="onboarding.rightPanel.second.title" defaultMessage="First, enable Avira as your default Password Manager" />
      </Text>
      <Text size="normal" variant="light" mt="m">
        <FormattedMessage id="onboarding.rightPanel.second.subtitle" defaultMessage="To save and fill your passwords automatically, you need to enable Avira as your default Password Manager for Chrome " />
      </Text>
      <Box onClick={onClickGif} mt="m">
        <img
          src={`${Autofill}?t=${timestamp}`}
          alt=""
          style={{ height: '350px', width: 'auto' }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        pt="m"
      >
        <Button variant="login" size="mini" onClick={onClick}>
          <FormattedMessage id="onboarding.rightPanel.second.default" defaultMessage="Set as default" />
        </Button>
      </Box>
    </Box>
  );
};

export default StepSetAsDefault;
