import React from 'react';
import ProtectedRoute from './ProtectedRoute';
import { isUserAuthenticated } from '../../user/selectors';

const AuthenticatedRoute = props => (
  <ProtectedRoute
    {...props}
    requirement={isUserAuthenticated}
    redirectPath="/login"
  />
);

export default AuthenticatedRoute;
