import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ABTestDecider from 'pwm-components/objects/ABTestDecider';

import config from '../../config';
import mixpanel from '../../tracking/mixpanel';

import MessengerContext from '../../componentLib/MessengerContext';
import Loading from '../../app/components/Loading';


const ABTestInitializer: React.FC = (props) => {
  const { children } = props;

  const [loading, setLoading] = useState(true);
  const diceRoll = useSelector((state: any) => state.abTest.diceRoll);

  const messenger = useContext(MessengerContext);

  useEffect(() => {
    async function init(): Promise<void> {
      if (!messenger?.isConnected()) {
        setLoading(false);
        return;
      }

      await new Promise((resolve) => {
        messenger.send('dashboard:extension:getAbTest', null, (_err, abTests) => {
          mixpanel.register(abTests);
          resolve(undefined);
        });

        setTimeout(resolve, 1000);
      });

      setLoading(false);
    }

    init();
  }, [messenger]);

  if (loading) {
    return <Loading />;
  }

  return (
    <ABTestDecider
      diceRoll={diceRoll}
      experiments={config.experiments}
      onExperimentsCalculated={(AvailableABTests: { [key: string]: string }) => {
        mixpanel.register(AvailableABTests);
      }}
    >
      {children}
    </ABTestDecider>
  );
};

export default ABTestInitializer;
