/* eslint-disable object-curly-newline */
import React, { memo } from 'react';
import theming from 'styled-theming';
import styled, { css } from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import DualButton from 'pwm-components/components/DualButton';
import TextButton from 'pwm-components/components/TextButton';

import Card from './CardWrapper';

import { getBrowser, getMajorVersion } from '../lib/UserAgent';

const isBlurSupported = (): boolean => !(getBrowser() === 'Internet Explorer' && getMajorVersion() < 13);

type DataListCellProps = {
  shrink?: boolean;
  truncate?: boolean;
  spacer?: boolean;
  separator?: boolean;
  hidden?: boolean;
  fixed?: boolean;
  blur?: boolean;
  loading?: boolean;
  collapse?: boolean;
  hideOnShrink?: boolean;
};

type DualButtonProps = {
  visible?: boolean;
}

// "shrink", "spacer" and "fixed" are helpers to avoid the need of knowing exactly how to
// configure flex-shrink, flex-grow and flex-basis. The absolute default for
// flex-shrink and flex-grow are 0. Sometimes though for a "shrink" we'd want to overwrite
// the value of flex-shrink. For that we default them case by case as defaultProps would make them
// all the same if nothing was specified.
// That was achieved before with the use of "style" prop, which heavily impacts performance.
const StyledDataListCell = styled(({ loading, ...props }) => <Box {... props} />)<DataListCellProps>`
  flex-shrink: ${({ flexShrink = 0 }) => flexShrink};
  flex-grow: ${({ flexGrow = 0 }) => flexGrow};
  flex-basis: ${({ flexBasis = 'auto' }) => flexBasis};

  ${({ shrink, flexShrink = 1 }) => shrink && css`
    flex-shrink: ${flexShrink};
    flex-grow: 0; // doesn't make sense to allow "shrink" to grow
  `}

  ${({ truncate }) => truncate && css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  `}

  ${({ spacer, flexBasis = 0, flexGrow = 3, flexShrink = 0 }) => spacer && css`
    flex-basis: ${flexBasis};
    flex-grow: ${flexGrow};
    flex-shrink: ${flexShrink};
    margin-right: 0;
  `}

  ${({ spacer, fixed, flexBasis = 0, flexGrow = 1, flexShrink = 0 }) => spacer && fixed && css`
    flex-basis: ${flexBasis};
    flex-grow: ${flexGrow};
    flex-shrink: ${flexShrink};
  `}

  ${({ spacer, separator, flexBasis = 0, flexGrow = 1, flexShrink = 0 }) => spacer && separator && css`
    flex-basis: ${flexBasis};
    flex-grow: ${flexGrow};
    flex-shrink: ${flexShrink};
    margin-right: ${({ theme }) => theme.space.m};
    border-left: 2px solid ${({ theme: { mode, colors } }) => (mode === 'light' ? colors.lightgrey : colors.outerspace)};
    height: 30px;

    @media screen and (max-width: 690px) {
      margin-right: ${({ theme }) => theme.space.xs};
    }
  `}

  ${({ loading, theme }) => loading && css`
    position: absolute;
    top: ${theme.space.l};
    right: ${theme.space.m};
  `}

  ${({ hideOnShrink }) => hideOnShrink && css`
      @media screen and (max-width: 960px) {
        display: none;
      }
  `}
`;

StyledDataListCell.defaultProps = {
  mr: 'm',
};

StyledDataListCell.displayName = 'DataListCell';

export const DataListCell = memo<React.ComponentProps<typeof StyledDataListCell>>(
  props => <StyledDataListCell {...props} />,
);

type DataListCellOnHoverProps = {
  visible?: boolean;
};

const StyledDataListCellOnHover = styled(StyledDataListCell)<DataListCellOnHoverProps>`
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transition: opacity 200ms;
`;

StyledDataListCellOnHover.displayName = 'DataListCellOnHover';
StyledDataListCellOnHover.defaultProps = {
  visible: false,
};

export const DataListCellOnHover = memo<React.ComponentProps<typeof StyledDataListCellOnHover>>(
  props => <StyledDataListCellOnHover {...props} />,
);

export const DataListCellBlur = styled(StyledDataListCell)``;

export const DataListActions = styled(StyledDataListCellOnHover)`
  margin-right: 32px;

  @media screen and (max-width: 1292px) {
    margin-right: ${({ theme }) => theme.space.m};
  }

  @media screen and (max-width: 690px) {
    margin-right: ${({ theme }) => theme.space.xs};
  }
`;

DataListCellBlur.displayName = 'DataListCellBlur';

export const TextButtonOnHover = styled(TextButton)`
  opacity: 0;
  transition: opacity 200ms;
`;

export const StyledDualButton = styled(DualButton)<DualButtonProps>`
  margin-right: 1px;
  height: 30px;
  width: 30px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transition: opacity 200ms;
`;

TextButtonOnHover.displayName = 'TextButtonOnHover';

type DataListCardProps = {
  placeholder?: boolean;
  disabled?: boolean;
}

const cardColor = theming('mode', {
  light: css<DataListCardProps>`
    ${({ disabled, theme: { colors } }) => disabled && css`
      background-color: ${colors.gainsboro};
    `}
  `,
  dark: css<DataListCardProps>`
    ${({ disabled, theme: { colors } }) => disabled && css`
      background-color: ${colors.outerspacedarker};
    `}
  `,
});

export const DataListCard = styled(Card).attrs<DataListCardProps>(({
  placeholder,
}) => ({ placeholder: undefined, usePlaceholder: placeholder }))<DataListCardProps & { usePlaceholder?: boolean }>`
  ${({ usePlaceholder, theme: { colors } }) => usePlaceholder && css`
    border: 1px dashed ${colors.silver};
    box-shadow: none;
    cursor: default;
  `}

  ${cardColor}

  &:hover ${TextButtonOnHover}, &:hover ${StyledDataListCellOnHover}, &:hover ${StyledDualButton} {
    opacity: 1;
  }
`;

DataListCard.displayName = 'DataListCard';

export const UpsellContainer = styled('div')``;

UpsellContainer.displayName = 'UpsellContainer';

type UpsellWrapperProps = {
  spotlight?: boolean;
}

export const UpsellWrapper = styled('div')<UpsellWrapperProps>`
  position: relative;
  min-height: ${({ spotlight }) => (spotlight ? '0px' : '200px')};

  & ${DataListCellBlur} {
    -webkit-filter: blur(7px);
    filter: blur(7px);

    ${!isBlurSupported() && css`
      visibility: hidden;
    `}
  }

  & ${UpsellContainer} {
    background: ${({ theme }) => (theme.mode === 'light' ? 'rgba(244, 246, 247, 0.75)' : 'rgba(44, 52, 60, 0.77)')};
    bottom: 0;
    left: 0;
    top: 0;
    position: absolute;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: center;

    & > * {
      margin-top: 124px;
    }
  }
`;

UpsellWrapper.displayName = 'UpsellWrapper';
