//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// Change History :
// Thursday Feb 14 2019 kalpana_m Adding to Git.
//

import constants from './VTConstants';
import Item from './VTItem';

const dsv2Messages = SymO2.proto.com.symantec.oxygen.datastore.v2.messages;
const { Value } = dsv2Messages;
const { DataTypeID } = Value;
const { logger } = SymBfw;
const { isNil } = SymBfw.utils;
const { ITEM_INTERNAL_CONTEXT } = constants;

const addressKeys = [
  constants.DEFAULT_NODE_ID,
  constants.DEFAULT_LASTUPDATE,
  constants.DEFAULT_FAVORITE,
  constants.ADDRESS.ADDR_CITY,
  constants.ADDRESS.ADDR_POSTAL,
  constants.ADDRESS.ADDR_STATE,
  constants.ADDRESS.ADDR_STREET,
  constants.ADDRESS.ADDR_STREET2,
  constants.ADDRESS.ADDR_STREET3,
  constants.ADDRESS.APTSTREETHOUSENUM,
  constants.ADDRESS.ADDR_CITY_ALPHABET,
  constants.ADDRESS.ADDR_STREET_ALPHABET,
  constants.ADDRESS.ADDR_STREET2_ALPHABET,
  constants.ADDRESS.ADDRESSNAME,
  constants.ADDRESS.SECURE,
  constants.ADDRESS.COUNTRY_REGION,
];

const propertyToSchemaKeyMap = {
  path: constants.DEFAULT_NODE_ID,
  lastUpdate: constants.DEFAULT_LASTUPDATE,
  favorite: constants.DEFAULT_FAVORITE,
  city: constants.ADDRESS.ADDR_CITY,
  postal: constants.ADDRESS.ADDR_POSTAL,
  state: constants.ADDRESS.ADDR_STATE,
  street: constants.ADDRESS.ADDR_STREET,
  street2: constants.ADDRESS.ADDR_STREET2,
  street3: constants.ADDRESS.ADDR_STREET3,
  apt: constants.ADDRESS.APTSTREETHOUSENUM,
  cityAlphabet: constants.ADDRESS.ADDR_CITY_ALPHABET,
  streetAlphabet: constants.ADDRESS.ADDR_STREET_ALPHABET,
  street2Alphabet: constants.ADDRESS.ADDR_STREET2_ALPHABET,
  addressName: constants.ADDRESS.ADDRESSNAME,
  secure: constants.ADDRESS.SECURE,
  region: constants.ADDRESS.COUNTRY_REGION,
};

/**
         * @constructor Address
         * @desc Constructor for creating a new Address item.
         */
class Address extends Item {
  constructor() {
    super();

    // state of the object (encrypted/decrypted)
    this.encrypted = true;
    this.propertyToSchemaKeyMap = propertyToSchemaKeyMap;
    this.keys = addressKeys;
  }

  schemaForKey(key) {
    switch (key) {
      case constants.DEFAULT_NODE_ID:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setPath,
          getter: this.getPath
        };

      case constants.DEFAULT_LASTUPDATE:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_UINT64,
          deserializedType: DataTypeID.TID_UINT64,
          setter: this.setLastUpdate,
          getter: this.getLastUpdate
        };
      case constants.DEFAULT_FAVORITE:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setFavorite,
          getter: this.getFavorite
        };
      case constants.ADDRESS.ADDR_CITY:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setCity,
          getter: this.getCity
        };
      case constants.ADDRESS.ADDR_POSTAL:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setPostal,
          getter: this.getPostal
        };
      case constants.ADDRESS.ADDR_STATE:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setState,
          getter: this.getState
        };
      case constants.ADDRESS.ADDR_STREET:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setStreet,
          getter: this.getStreet
        };
      case constants.ADDRESS.ADDR_STREET2:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setStreet2,
          getter: this.getStreet2
        };
      case constants.ADDRESS.ADDR_STREET3:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setStreet3,
          getter: this.getStreet3
        };
      case constants.ADDRESS.ADDR_CITY_ALPHABET:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setCityAlphabet,
          getter: this.getCityAlphabet
        };
      case constants.ADDRESS.ADDR_STREET_ALPHABET:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setStreetAlphabet,
          getter: this.getStreetAlphabet
        };
      case constants.ADDRESS.ADDR_STREET2_ALPHABET:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setStreet2Alphabet,
          getter: this.getStreet2Alphabet
        };
      case constants.ADDRESS.APTSTREETHOUSENUM:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BINARY,
          setter: this.setApt,
          getter: this.getApt
        };
      case constants.ADDRESS.ADDRESSNAME:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setAddressName,
          getter: this.getAddressName
        };

      case constants.ADDRESS.SECURE:
        return {
          encrypted: true,
          obfuscated: false,
          serializedType: DataTypeID.TID_BINARY,
          deserializedType: DataTypeID.TID_BOOL,
          setter: this.setSecure,
          getter: this.getSecure
        };
      case constants.ADDRESS.COUNTRY_REGION:
        return {
          encrypted: false,
          obfuscated: false,
          serializedType: DataTypeID.TID_STRING,
          deserializedType: DataTypeID.TID_STRING,
          setter: this.setRegion,
          getter: this.getRegion
        };
      default:
        logger.error(`Invalid data value: ${key}`);
        break;
    }

    return null;
  }

  /**
  * @function getNodePath
  * @desc Implementation of interface function that
  * returns the full path to the current Address
  * @returns Returns a string that contains the full path
  * to the item in the datastore
  */
  getNodePath() {
    // format /11/IDSC/3/<guid>
    return `${constants.DEFAULT_ADDRESS_NODE}/${this.getPath()}`;
  }

  /**
  * @function getCity
  * @desc gets the city
  * @param {String=} context Internal context or not. If you are calling this getter
  *  for getting the raw value (if obfuscated the binary) then ignore this value.
  * @return {string}  city
  */
  getCity(context) {
    return super.getDeobfuscatedStringProperty(this.city, context);
  }

  /**
  * @function setCity
  * @desc setter to set the city
  * @param  {string} value the
  * @param {String=} context Internal context or not. If you are calling this setter
  * for storing a new item then you can ignore this param.
  */
  setCity(value, context) {
    super.setClearOrObfuscatedStringProperty('city', value, context);
  }

  /**
  * @function getPostal
  * @desc gets the postal
  * @param {String=} context Internal context or not. If you are calling this getter
  * for getting the raw value (if obfuscated the binary) then ignore this value.
  * @return {string}  postal
  */
  getPostal(context) {
    return super.getDeobfuscatedStringProperty(this.postal, context);
  }

  /**
  * @function setPostal
  * @desc sets the postal
  * @param  {string} value the postal
  * @param {String=} context Internal context or not. If you are calling this getter
  * for getting the raw value (if obfuscated the binary) then ignore this value.
  */
  setPostal(value, context) {
    super.setClearOrObfuscatedStringProperty('postal', value, context);
  }

  /**
  * @function getState
  * @desc gets the state
  * @param {String=} context Internal context or not. If you are calling this getter
  *   for getting the raw value (if obfuscated the binary) then ignore this value.
  * @return {string}  state
  */
  getState(context) {
    return super.getDeobfuscatedStringProperty(this.state, context);
  }

  /**
  * @function setState
  * @desc sets the state
  * @param  {string} value the state
  * @param {String=} context Internal context or not. If you are calling this getter
  *  for getting the raw value (if obfuscated the binary) then ignore this value.
  */
  setState(value, context) {
    super.setClearOrObfuscatedStringProperty('state', value, context);
  }

  /**
   * @function getStreet
   * @desc gets the street
   * @param {String=} context Internal context or not. If you are calling this getter
   * for getting the raw value (if obfuscated the binary) then ignore this value.
   * @return {string}  street
   */
  getStreet(context) {
    return super.getDeobfuscatedStringProperty(this.street, context);
  }

  /**
    * @function setStreet
    * @desc sets the street
    * @param  {string} value the street
    * @param {String=} context Internal context or not. If you are calling this getter
    * for getting the raw value (if obfuscated the binary) then ignore this value.
    */
  setStreet(value, context) {
    super.setClearOrObfuscatedStringProperty('street', value, context);
  }

  /**
    * @function getStreet2
    * @desc gets the setStreet2
    * @param {String=} context Internal context or not. If you are calling this getter
    * for getting the raw value (if obfuscated the binary) then ignore this value.
    * @return {string}  street2
    */
  getStreet2(context) {
    return super.getDeobfuscatedStringProperty(this.street2, context);
  }

  /**
    * @function setStreet2
    * @desc sets the street2
    * @param  {string} value the street2
    * @param {String=} context Internal context or not. If you are calling this getter
    * for getting the raw value (if obfuscated the binary) then ignore this value.
    */
  setStreet2(value, context) {
    super.setClearOrObfuscatedStringProperty('street2', value, context);
  }

  /**
    * @function getStreet3
    * @desc gets the street3
    * @param {String=} context Internal context or not. If you are calling this getter
    * for getting the raw value (if obfuscated the binary) then ignore this value.
    * @return {string}  street3
    */
  getStreet3(context) {
    return super.getDeobfuscatedStringProperty(this.street3, context);
  }

  /**
    * @function setStreet3
    * @desc sets the street3
    * @param  {string} value the street3
    * @param {String=} context Internal context or not. If you are calling this getter
    * for getting the raw value (if obfuscated the binary) then ignore this value.
    */
  setStreet3(value, context) {
    super.setClearOrObfuscatedStringProperty('street3', value, context);
  }

  /**
     * @function getApt
     * @desc gets the apt
     * @param {String=} context Internal context or not. If you are calling this getter
     * for getting the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  apt
     */
  getApt(context) {
    return super.getDeobfuscatedStringProperty(this.apt, context);
  }

  /**
     * @function setApt
     * @desc sets the apt
     * @param  {string} value the apt
     * @param {String=} context Internal context or not. If you are calling this getter
     * for getting the raw value (if obfuscated the binary) then ignore this value.
     */
  setApt(value, context) {
    super.setClearOrObfuscatedStringProperty('apt', value, context);
  }

  /**
     * @function getCityAlphabet
     * @desc gets the cityAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter
     * for getting the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  cityAlphabet
     */
  getCityAlphabet(context) {
    return super.getDeobfuscatedStringProperty(this.cityAlphabet, context);
  }

  /**
     * @function setCityAlphabet
     * @desc setter to set the cityAlphabet
     * @param  {string} value
     * @param {String=} context Internal context or not. If you are calling this setter
     *  for storing a new item then you can ignore this param.
     */
  setCityAlphabet(value, context) {
    super.setClearOrObfuscatedStringProperty('cityAlphabet', value, context);
  }

  /**
     * @function getStreetAlphabet
     * @desc gets the streetAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter
     * for getting the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  streetAlphabet
     */
  getStreetAlphabet(context) {
    return super.getDeobfuscatedStringProperty(this.streetAlphabet, context);
  }

  /**
     * @function setStreetAlphabet
     * @desc sets the streetAlphabet
     * @param  {string} value the streetAlphabet
     * @param {String=} context Internal context or not. If you are calling this getter
     * for getting the raw value (if obfuscated the binary) then ignore this value.
     */
  setStreetAlphabet(value, context) {
    super.setClearOrObfuscatedStringProperty('streetAlphabet', value, context);
  }

  /**
     * @function getStreet2Alphabet
     * @desc gets the setStreet2Alphabet
     * @param {String=} context Internal context or not. If you are calling this getter
     * for getting the raw value (if obfuscated the binary) then ignore this value.
     * @return {string}  street2Alphabet
     */
  getStreet2Alphabet(context) {
    return super.getDeobfuscatedStringProperty(this.street2Alphabet, context);
  }

  /**
     * @function setStreet2Alphabet
     * @desc sets the street2Alphabet
     * @param  {string} value the street2Alphabet
     * @param {String=} context Internal context or not. If you are calling this getter
     *  for getting the raw value (if obfuscated the binary) then ignore this value.
     */
  setStreet2Alphabet(value, context) {
    super.setClearOrObfuscatedStringProperty('street2Alphabet', value, context);
  }

  /**
     * @function getAddressName
     * @desc gets the addressName
     * @return {string}  addressName
     */
  getAddressName() {
    return this.addressName;
  }

  /**
     * @function setAddressName
     * @desc sets the addressName
     * @param  {string} value the addressName
     */
  setAddressName(value) {
    this.addressName = value;
  }

  /**
     * @function getRegion
     * @desc gets the region
     * @return {string=}  region
     */
  getRegion(context) {
    if (context === ITEM_INTERNAL_CONTEXT) {
      return this.region;
    }

    if (isNil(this.region)) {
      return '';
    }
    return this.region.toLowerCase();
  }

  /**
     * @function setRegion
     * @desc sets the region
     * @param  {string} value the region
     */
  setRegion(value) {
    this.region = value;
  }
}

export default Address;
