/* eslint-disable max-params */
/* eslint-disable no-restricted-globals */
import {
  isErrorStatus,
  isInProgressStatus,
  isMigratedStatus,
  shouldMigrate,
  getMigrationError as getMigrationErrorHelper,
  isNonMigratable as isNonMigratableHelper,
} from '@avira-pwm/sync/helpers/migration';
import { MigrationStatus } from '@avira-pwm/services/LicenseService';

import { createSelector, Selector } from 'reselect';

import type { RootState } from '../app/store';
import { isConnectedToExtension, isUnregisteredMode } from '../dashboard/selectors';
import { isExtensionCompatibleWithVaultSDK } from './helpers';
import config from '../config';

export const isCompatibleExtensionOrDisconnected = createSelector(
  isConnectedToExtension,
  (state: RootState): boolean => {
    const installedVersion = state.dashboard.extensionInstalledVersion;

    if (installedVersion == null) {
      return true;
    }

    return isExtensionCompatibleWithVaultSDK(installedVersion);
  },
  (connected, compatible) => !connected || compatible,
);

const nlokDataSelector = (state: RootState): RootState['nlokData'] => state.nlokData;

export const getVaultKeys = createSelector(
  nlokDataSelector,
  ({ encryptionKey, challengeKey }) => (
    { encryptionKey, challengeKey }
  ),
);

export const getShouldBypassVault = createSelector(
  nlokDataSelector,
  ({ bypassVault }) => bypassVault,
);

export const vaultIsUnlocked = createSelector(
  getVaultKeys,
  ({ encryptionKey, challengeKey }) => (
    !!(encryptionKey && challengeKey)
  ),
);

export const getMigrationStatus: Selector<RootState, MigrationStatus | null> = (
  state => (state.userData.migration_status as MigrationStatus) ?? null
);

export const getMigrationAttempts: Selector<RootState, number> = (
  state => state.nlokData.migrationAttempts
);

export const migrationNotStarted = createSelector(
  getMigrationStatus,
  migrationStatus => migrationStatus == null,
);

export const migrationInProgress = createSelector(
  getMigrationStatus,
  migrationStatus => isInProgressStatus(migrationStatus?.status ?? null),
);

export const migrationFailed = createSelector(
  getMigrationStatus,
  migrationStatus => isErrorStatus(migrationStatus?.status ?? null),
);

export const migrationSucceeded = createSelector(
  getMigrationStatus,
  migrationStatus => isMigratedStatus(migrationStatus?.status ?? null),
);

export const isNonMigratable = createSelector(
  getMigrationStatus,
  migrationStatus => isNonMigratableHelper(migrationStatus?.status ?? null),
);

export const isVaultEnabled: Selector<RootState, boolean> = (state: RootState) => (
  state.userData.vault_sdk_enabled === true
);

export const userShouldMigrate: Selector<RootState, boolean> = (state: RootState) => (
  state.userData.should_migrate === true || !!config.bypassVaultSDKRollout
);

export const shouldConnectToVault = createSelector(
  isUnregisteredMode,
  isVaultEnabled,
  migrationSucceeded,
  getShouldBypassVault,
  (
    isUnregistered,
    isVaultEnabledVal,
    isMigrationSucceeded,
    shouldBypassVault,
  ) => (
    !isUnregistered
    && isVaultEnabledVal
    && isMigrationSucceeded
    && !shouldBypassVault
  ),
);

export const shouldConnectToNDSAdapter = createSelector(
  shouldConnectToVault,
  isConnectedToExtension,
  (shouldConnectToVaultVal, isConnectedToExtensionVal) => (
    shouldConnectToVaultVal && !isConnectedToExtensionVal
  ),
);

export const shouldMigrateVault = createSelector(
  getMigrationStatus,
  isVaultEnabled,
  userShouldMigrate,
  isCompatibleExtensionOrDisconnected,
  getShouldBypassVault,
  (
    migrationStatus,
    isVaultEnabledVal,
    userShouldMigrateVal,
    isCompatibleExtensionOrDisconnectedVal,
    shouldBypassVault,
  ) => (
    shouldMigrate(migrationStatus)
    && isVaultEnabledVal
    && userShouldMigrateVal
    && isCompatibleExtensionOrDisconnectedVal
    && !shouldBypassVault
  ),
);

export const getMigrationError = createSelector(
  getMigrationStatus,
  migrationStatus => getMigrationErrorHelper(migrationStatus),
);
