import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Text from 'pwm-components/components/Text';
import ToggleSwitch from 'pwm-components/components/ToggleSwitch';

import Slider from '../../componentLib/Slider';
import PleaseRegisterMsg from '../../componentLib/PleaseRegisterMsg';
import Card from '../../componentLib/CardWrapper';

const AutoLockLabel = styled(Text).attrs(() => ({ size: 'small' }))`
  white-space: nowrap;
`;

const autoLockOptions = {
  0: {
    label: (
      <AutoLockLabel>
        <FormattedMessage id="dashboard.settings.lock.tenMinutes" />
      </AutoLockLabel>
    ),
    value: 0.16,
  },
  1: {
    label: (
      <AutoLockLabel>
        <FormattedMessage id="dashboard.settings.lock.thirtyMinutes" />
      </AutoLockLabel>
    ),
    value: 0.5,
  },
  2: {
    label: (
      <AutoLockLabel>
        <FormattedMessage id="dashboard.settings.lock.oneHour" />
      </AutoLockLabel>
    ),
    value: 1,
  },
  3: {
    label: (
      <AutoLockLabel>
        <FormattedMessage id="dashboard.settings.lock.threeHours" />
      </AutoLockLabel>
    ),
    value: 3,
  },
  4: {
    label: (
      <AutoLockLabel>
        <FormattedMessage id="dashboard.settings.lock.twentyFourHours" />
      </AutoLockLabel>
    ),
    value: 24,
  },
};

const getSliderValue = (duration) => {
  let sliderValue = 1;
  Object.keys(autoLockOptions).forEach((opt) => {
    if (autoLockOptions[opt].value === duration) {
      sliderValue = parseInt(opt, 0);
    }
  });
  return sliderValue;
};

class Lock extends React.Component {
  static propTypes = {
    autolockDuration: PropTypes.number,
    onAutoLockChange: PropTypes.func.isRequired,
    isUnregisteredMode: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    autolockDuration: null,
  }

  constructor(props) {
    super(props);
    this.onAutoLockChange = this.onAutoLockChange.bind(this);
    this.onAutoLockToggle = this.onAutoLockToggle.bind(this);
  }

  onAutoLockChange(value) {
    const { onAutoLockChange } = this.props;
    onAutoLockChange(value);
  }

  onAutoLockToggle(e) {
    const { onAutoLockChange } = this.props;
    onAutoLockChange(e.target.checked ? 0.5 : null);
  }

  render() {
    const { autolockDuration, isUnregisteredMode } = this.props;
    const sliderValue = getSliderValue(autolockDuration);
    return (
      <Card
        title={(
          <Text size="small">
            <FormattedMessage id="dashboard.settings.lock" />
          </Text>
        )}
        mb="l"
      >
        <Paragraph mb="l">
          <FormattedMessage id="dashboard.settings.lock.description" />
        </Paragraph>
        <Box
          display="flex"
        >
          <Box mb="l" display="flex" alignItems="center">
            <Text mr="xxl">
              <FormattedMessage id="dashboard.settings.lock.autoLock" />
            </Text>
            <Box
              mr="s"
              display="flex"
              alignItems="center"
            >
              <ToggleSwitch
                id="autolock-setting"
                checked={!!autolockDuration}
                disabled={isUnregisteredMode}
                onChange={this.onAutoLockToggle}
              />
            </Box>
            <Box
              mr="xxl"
            >
              {
                isUnregisteredMode
                  ? (
                    <PleaseRegisterMsg />
                  ) : (
                    <Text size="normal">
                      <strong>
                        <FormattedMessage id={!autolockDuration ? 'dashboard.settings.lock.off' : 'dashboard.settings.lock.on'} />
                      </strong>
                    </Text>
                  )
              }
            </Box>
          </Box>
          <Box
            mx="xxl"
            mb="l"
            style={{
              flexGrow: '1',
              visibility: autolockDuration ? '' : 'hidden',
            }}
          >
            <Slider
              marks={autoLockOptions}
              onChange={value => this.onAutoLockChange(autoLockOptions[value].value)}
              value={sliderValue}
            />
          </Box>
        </Box>
      </Card>
    );
  }
}

export default Lock;
