//
//     Symantec copyright header start
//
// Copyright © 2019, Symantec Corporation, All rights reserved.
//
// THIS SOFTWARE CONTAINS CONFIDENTIAL INFORMATION AND TRADE SECRETS OF SYMANTEC
// CORPORATION.  USE, DISCLOSURE OR REPRODUCTION IS PROHIBITED WITHOUT THE PRIOR
// EXPRESS WRITTEN PERMISSION OF SYMANTEC CORPORATION.
//
// The Licensed Software and Documentation are deemed to be commercial computer
// software as defined in FAR 12.212 and subject to restricted rights as defined
// in FAR Section 52.227-19 "Commercial Computer Software - Restricted Rights"
// and DFARS 227.7202, “Rights in Commercial Computer Software or Commercial
// Computer Software Documentation”, as applicable, and any successor regulations.
// Any use, modification, reproduction release, performance, display or disclosure
// of the Licensed Software and Documentation by the U.S. Government shall be
// solely in accordance with the terms of this Agreement.
//
// Symantec copyright header stop
//
// BrowserFramework
// watermark CB70-6840-3597-44-15-4
// PROPRIETARY/CONFIDENTIAL.  Use of this product is subject to license terms.
// Copyright © 2016, Symantec Corporation, All rights reserved.
//

const { SymBfw } = global;
const { isNil } = SymBfw.utils;

/**
 * @class VaultState 
 * @classdesc Class representation of vault state.
 */
class VaultState {
  /**
    * Create a instance of VaultState
    * @param {number} state - state of the vault.
    * @param {string} additionalInfo - Any additional information related to the vault state
    */
  constructor(state, additionalInfo) {
    if (isNil(state)) {
      throw new Error('State cannot be null');
    }
  
    this.state = state;
    this.additionalInfo = additionalInfo;
  }
  
  /**
    * @function getState
    * @returns Returns the state.
    */
  getState() {
    return this.state;
  }
  
  /**
    * @function getAdditionalInfo
    * @returns Returns the additionalInfo.
    */
  getAdditionalInfo() {
    return this.additionalInfo;
  }
}

export default VaultState;
