import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import PasswordGenerator from 'pwm-components/components/PasswordGenerator';
import { setPassGenPrefs } from '../../preferences';
import { validatePasswordStrength } from '../../lib/AuthenticationValidator';
import { trackPasswordGenerated } from '../AccountActions';

type PreferencesType = {
  preferences: {
    PassGenLength: number;
    PassGenNumbers: boolean;
    PassGenSymbols: boolean;
  };
};

const mapStateToProps: any = ({ preferences }: PreferencesType) => ({ preferences });
const mapDispatchToProps: any = (dispatch: Dispatch) => bindActionCreators({
  trackGeneratedPw: trackPasswordGenerated,
  setPreferences: setPassGenPrefs,
}, dispatch);

export type Props = PreferencesType
& ReturnType<typeof mapStateToProps>
& ReturnType<typeof mapDispatchToProps>
& {
  visible: boolean;
  onUsePassword: (value: string) => void;
  onCloseClick: () => void;
  onOutsideClick: () => void;
};

const GeneratePasswordContainer: React.FC<Props> = ({
  preferences,
  trackGeneratedPw,
  setPreferences,
  onUsePassword,
  onCloseClick,
  onOutsideClick,
}) => {
  const [strength, setStrength] = useState<ReturnType<typeof validatePasswordStrength>>();

  useEffect(() => {
    window.addEventListener('click', onOutsideClick);

    return () => window.removeEventListener('click', onOutsideClick);
  });

  function onPasswordPreferencesChange(length: number, numbers: boolean, symbols: boolean): void {
    setPreferences({ length, numbers, symbols });
  }

  function onPasswordChange(password: string): void {
    setStrength(validatePasswordStrength(password));
  }

  function onUsePasswordClicked(value: string): void {
    const { PassGenLength, PassGenNumbers, PassGenSymbols } = preferences;
    trackGeneratedPw({
      type: ((PassGenLength === 12 && PassGenNumbers && PassGenSymbols) ? 'default' : 'custom'),
      containsNumbers: PassGenNumbers,
      containsSpecialChars: PassGenSymbols,
    });
    onUsePassword(value);
  }

  let strengthProp = {
    score: 0,
    timeToCrack: 0,
  };

  if (strength) {
    const { feedback, ...others } = strength;
    ({ ...strengthProp } = others);
  }

  return (
    <PasswordGenerator
      visible
      preferences={preferences}
      strength={strengthProp}
      strings={{
        title: 'dashboard.account.details.generatedPassword.title',
        passwordLength: 'dashboard.account.details.generatePassword.length',
        useNumbers: 'dashboard.account.details.generatePassword.useNumbers',
        useSpecialChars: 'dashboard.account.details.generatePassword.useSpecialCharacters',
        usePassword: 'dashboard.account.details.generatePassword.usePassword',
      }}
      onUsePassword={onUsePasswordClicked}
      onCloseClick={onCloseClick}
      onPasswordChange={onPasswordChange}
      onPasswordPreferencesChange={onPasswordPreferencesChange}
      left="calc(100% + 16px)"
      bottom="100%"
      width="360px"
    />
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneratePasswordContainer);
