import React from 'react';
import { number } from 'prop-types';

import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';

import achievement from '../../img/achievement.png';
import achievement2x from '../../img/achievement@2x.png';

const ProgressIndicator = ({ points, maxPoints }) => (
  <Box display="flex" alignItems="center" pr="32px">
    <strong>
      <Text size="h2" mr="xxs" color="slategrey">
        {' '}
        {points}
        {' '}
      </Text>
    </strong>
      /
    <Text size="h2" ml="xxs" mr="s">
      {' '}
      {maxPoints}
      {' '}
    </Text>
    <img
      src={achievement}
      srcSet={`${achievement} 1x, ${achievement2x} 2x`}
      alt="Achievement mark"
    />
  </Box>
);

ProgressIndicator.propTypes = {
  points: number.isRequired,
  maxPoints: number.isRequired,
};

export default ProgressIndicator;
