import {
  GET_DEVICES,
  LOG_OUT_DEVICE,
  GetDevices,
  LogOutDevice,
  Device,
} from './ConnectedDevicesActionTypes';

type State = Device[];

const defaultState = (): State => [];

type Actions = GetDevices | LogOutDevice;

export default (
  state: State = defaultState(),
  action: Actions,
): State => {
  switch (action.type) {
    case GET_DEVICES:
      return action.value;
    case LOG_OUT_DEVICE:
      return state.filter(device => device.id !== action.deviceId);
    default:
      return state;
  }
};
