import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import { generateID } from '../../lib/AccountHelper';
import {
  triggerAccountCreation, autoLoginAccount, trackCredentialsUsed, onAccountCopy,
} from '../AccountActions';
import AddEditAccountPage from './AddEditAccountPage';
import { getSuggestedUsernames } from '../../componentLib/selectors';

class AddAccount extends React.Component {
  static propTypes = {
    accounts: PropTypes.shape({}).isRequired,
    onLoginClick: PropTypes.func.isRequired,
    onCopyClick: PropTypes.func.isRequired,
    onRevealPasswordClick: PropTypes.func.isRequired,
    onAccountSaveClick: PropTypes.func.isRequired,
    location: PropTypes.object,
    history: PropTypes.shape({
      replace: PropTypes.func.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    location: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.loading && nextProps.accounts[this.state.id] != null) {
      this.props.history.replace(`/mydata/passwords/${this.state.id}/edit`);
    }
  }

  onSubmit(id, accountChangedProps, config) {
    return new Promise((resolve, reject) => {
      this.setState({ id: id || generateID(), loading: true }, async () => {
        try {
          resolve(await this.props.onAccountSaveClick(this.state.id, accountChangedProps, config));
        } catch (error) {
          this.setState({ loading: false });
          reject(error);
        }
      });
    });
  }

  render() {
    return (
      <AddEditAccountPage
        {...this.props}
        addMode
        onAccountSaveClick={this.onSubmit}
        onLoginClick={this.props.onLoginClick}
        onCopyClick={this.props.onCopyClick}
        onRevealPasswordClick={this.props.onRevealPasswordClick}
        prefilledValues={this.props.location.state}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const {
    oe,
    files,
    accounts,
    dashboard: { extensionConnected: extensionInstalled },
  } = state;
  return {
    oe,
    files,
    accounts,
    extensionInstalled,
    defaultUsername: getSuggestedUsernames(state)[0],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onAccountSaveClick: triggerAccountCreation,
  onLoginClick: autoLoginAccount,
  onCopyClick: onAccountCopy,
  onRevealPasswordClick: trackCredentialsUsed,
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddAccount));
