import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

const howtoHttps = [1, 2].map(
  number => `dashboard.securityStatus.unsafeWebsite.http.howTo.${number}`,
);

const TooltipUnsafeWebsite: React.FC = () => (
  <Box>
    <Paragraph>
      <strong>
        <FormattedMessage id="dashboard.securityStatus.tooltip.header.notSecure" />
      </strong>
    </Paragraph>

    <Paragraph
      mt="xs"
    >
      <FormattedMessage id="dashboard.securityStatus.unsafeWebsite.http" />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedMessage id="dashboard.securityStatus.unsafeWebsite.http.howTo" />
    </Paragraph>
    <ol
      type="1"
      style={{
        listStylePosition: 'outside',
        margin: '0 20px',
        listStyleType: 'decimal',
      }}
    >
      {
        howtoHttps.map(item => (
          <Paragraph
            mt="xxs"
          >
            <li>
              <FormattedHTMLMessage
                id={item}
              />
            </li>
          </Paragraph>
        ))
      }
    </ol>
  </Box>
);

export default TooltipUnsafeWebsite;
