import { Entity as AccountEntity } from '@avira-pwm/redux/accounts';
import { Type as AccountType } from '@avira-pwm/sync/ModelSpecifics/Account';
import { Type as AccountHistoryType } from '@avira-pwm/sync/ModelSpecifics/AccountHistory';
import { Flattened } from '@avira-pwm/sync/ModelSpecifics/ModelInfo';

export const ACCOUNTS_CLEAR = 'ACCOUNTS_CLEAR';

export interface AccountsClear {
  type: typeof ACCOUNTS_CLEAR;
}

export const ACCOUNTS_DATA = 'ACCOUNTS_DATA';

export interface AccountsData {
  type: typeof ACCOUNTS_DATA;
  accounts: AccountType[];
}

export const ACCOUNTS_PREFERENCES_SCROLLPOSITION = 'ACCOUNTS_PREFERENCES_SCROLLPOSITION';

export interface AccountsPreferencesScrollPosition {
  type: typeof ACCOUNTS_PREFERENCES_SCROLLPOSITION;
  value: number;
}

export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';

export interface UpdateAccountDetails {
  type: typeof UPDATE_ACCOUNT_DETAILS;
  value: {

    account: AccountEntity;
    accountHistory: Flattened<AccountHistoryType>[];
  };
}
