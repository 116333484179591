/* eslint-disable import/first */

// The  app.css will be created before the build - we ignore
// to prevent eslint from failing
import './PWM';

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'es6-promise/auto';

import './polyfills/TextEncoder';

require('./polyfills/fetch');

import 'core-js/stable';
import 'core-js/web/url';
import 'regenerator-runtime/runtime';
import './css/app.css';
import './app';
import './nlok/setup';

import config from './config';

import VaultSDK from '@nlok/vault-sdk/source/VTIndexWebpack';

if (!window.crypto) {
  // @ts-ignore
  window.crypto = window.msCrypto;
}

if (config.localBuild) {
  window.PWM.VaultSDK = VaultSDK;
}
