import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import it from './it.json';
import jaJP from './ja-JP.json';
import nlNL from './nl-NL.json';
import ptBR from './pt-BR.json';
import ru from './ru.json';
import trTR from './tr-TR.json';
import zhCN from './zh-CN.json';
import zhTW from './zh-TW.json';

const DEFAULT_LOCALE = 'en';

export const supported = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  'ja-JP': '日本語',
  'nl-NL': 'Nederlands',
  'pt-BR': 'Português',
  ru: 'Pусский',
  'tr-TR': 'Türkçe',
  'zh-CN': '简体中文',
  'zh-TW': '繁體中文',
};

export const messages = {
  de,
  en,
  es,
  fr,
  it,
  'ja-JP': jaJP,
  'nl-NL': nlNL,
  'pt-BR': ptBR,
  ru,
  'tr-TR': trTR,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};

export const facebookLocales = {
  en: 'en_US',
  de: 'de_DE',
  es: 'es_ES',
  fr: 'fr_FR',
  it: 'it_IT',
  'ja-JP': 'ja_JP',
  'nl-NL': 'nl_NL',
  'pt-BR': 'pt_BR',
  ru: 'ru_RU',
  'tr-TR': 'tr_TR',
  'zh-CN': 'zh_CN',
  'zh-TW': 'zh_TW',
};

export const oeLocales = {
  en: 'en',
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  'ja-JP': 'ja',
  'nl-NL': 'nl',
  'pt-BR': 'pt-br',
  ru: 'ru',
  'tr-TR': 'tr',
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-tw',
};

export const languageCodes = {
  en: 'en',
  de: 'de',
  es: 'es',
  fr: 'fr',
  it: 'it',
  'ja-JP': 'ja',
  'nl-NL': 'nl',
  'pt-BR': 'pt-br',
  ru: 'ru',
  'tr-TR': 'tr',
  'zh-CN': 'zh-cn',
  'zh-TW': 'zh-tw',
};

export const getLocale = (locale) => {
  const short = locale.slice(0, 2).toLowerCase();
  const long = `${short}-${locale.slice(3, 5).toUpperCase()}`;

  if (locale.length === 2 && messages[short] != null) {
    return short;
  } if (messages[long] != null) {
    return long;
  } if (messages[short] != null) {
    return short;
  }

  const localeList = Object.keys(messages);

  for (let i = 0; i < localeList.length; i += 1) {
    if (localeList[i].indexOf(short) === 0) {
      return localeList[i];
    }
  }

  return DEFAULT_LOCALE;
};

export const getLocaleData = (navigatorLocale) => {
  const locale = getLocale(navigatorLocale);
  return {
    locale,
    messages: messages[locale],
  };
};
