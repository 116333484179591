import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import { trackCreditCardUsed, trackCreditCardEdit, updateLastUsedAt } from '../../WalletActions';
import CreditCardListItem, { Props } from './CreditCardListItem';

const mapStateToProps = (_: any): {} => ({});

interface DispatchProps {
  onDeleteClick: (id: string) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): any => bindActionCreators({
  trackCreditCardUsed,
  updateLastUsedAt,
  onEditClick: trackCreditCardEdit,
}, dispatch);


export default connect<
ReturnType<typeof mapStateToProps>,
ReturnType<typeof mapDispatchToProps>,
Exclude<Props, keyof DispatchProps>,
{}
>(
  null,
  mapDispatchToProps,
)(CreditCardListItem);
