import React, { useState, useCallback } from 'react';
import { Document, Page as PageComponent } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Loading from 'pwm-components/components/Loading';
import Paragraph from 'pwm-components/components/Paragraph';
import ErrorIcon from 'pwm-components/icons/Error';
import ViewerProps from './ViewerProps';

const PdfContainer = styled('div')`
  padding: ${({ theme: { space } }) => space.xxl} 0;
`;

const Page = styled(PageComponent)`
  &: not(: last-child) {
    margin-bottom: ${({ theme: { space } }) => space.m};
  }
`;

export type Props = ViewerProps & {
};

const PdfViewer: React.FC<Props> = ({ data }) => {
  const [numPages, setNumPages] = useState(null);
  const [width, setWidth] = useState<number | undefined>();

  const onDocumentLoadSuccess = useCallback(({ numPages: n }: any): void => {
    setNumPages(n);
  }, []);

  const onPageLoadSuccess = useCallback(({ width: pdfWidth }: any): void => {
    const maxWidth = window.innerWidth * 0.90;
    if (pdfWidth > window.innerWidth * 0.90) {
      setWidth(maxWidth);
    }
  }, []);

  return (
    <PdfContainer>
      <Document
        file={{ data: data || new Uint8Array() }}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<Loading />}
        error={(
          <Box display="flex" alignItems="center" flexDirection="column">
            <ErrorIcon variant="alert" />
            <Paragraph mt="l" variant="light" textAlign="center">Download Failed</Paragraph>
          </Box>
        )}
      >
        {Array.from(
          new Array(numPages),
          (_el, index) => (
            <Page
              renderMode="svg"
              width={width}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
              onLoadSuccess={onPageLoadSuccess}
              loading={<Loading />}
              error={(
                <ErrorIcon variant="alert" />
              )}
            />
          ),
        )}
      </Document>
    </PdfContainer>
  );
};

export default PdfViewer;
