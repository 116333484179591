import pick from 'lodash/pick';
import { State as FilesState } from '@avira-pwm/redux/files';
import config from '../../config';

export function getFileData(file: File): Promise<Uint8Array> {
  return new Promise<Uint8Array>((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      const result = (reader.result as ArrayBuffer) || new Uint8Array();
      resolve(new Uint8Array(result));
    };
  });
}

export function getTotalFilePreviewSize(obj: Record<string, Uint8Array>): number {
  return Object.values(obj).reduce(
    (total, val) => total + (val || new Uint8Array())?.byteLength, 0,
  );
}

export function filterFiles(allFiles: FilesState, eID = ''): FilesState {
  const allFilesId = Object.keys(allFiles);

  if (eID && eID.length > 0 && allFilesId.length > 0) {
    return pick(allFiles, allFilesId.filter((id) => {
      const { entityID } = allFiles[id];
      return (entityID || '').toString().includes(eID);
    }));
  }

  return {};
}

export function getDataSizeString(bytes: number, precision = 0): string {
  let byteNum;
  let unit;
  const byteKB = bytes / 1e+3;
  const byteMB = bytes / 1e+6;
  const byteGB = bytes / 1e+9;
  if (byteGB >= 1) {
    byteNum = byteGB;
    unit = 'GB';
  } else if (byteMB >= 1) {
    byteNum = byteMB;
    unit = 'MB';
  } else {
    byteNum = byteKB;
    unit = 'KB';
  }

  return `${byteNum.toFixed(precision)}${unit}`;
}

export function isAttached(files: FilesState): boolean {
  return config.showFiles && Object.keys(files || {}).length > 0;
}
