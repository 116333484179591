import { Type } from '@avira-pwm/sync/ModelSpecifics/CreditCard';
import { ModelData } from '@avira-pwm/sync/SyncUtils';
import { SortOption } from './WalletHelpers';

export const CREDIT_CARD_DATA = 'CREDIT_CARD_DATA';
export const CLEAR_WALLET = 'CLEAR_WALLET';
export const UPDATE_CREDIT_CARD_DETAILS = 'UPDATE_CREDIT_CARD_DETAILS';
export const WALLET_DETAILS_SCROLLPOSITION = 'WALLET_DETAILS_SCROLLPOSITION';
export const UPDATE_WALLET_SORT_BY = 'UPDATE_WALLET_SORT_BY';

interface CreditCardDataAction {
  type: typeof CREDIT_CARD_DATA;
  value: Array<ModelData<Type>>;
}

interface ClearWalletAction {
  type: typeof CLEAR_WALLET;
}

interface WalletScrollPositionAction {
  type: typeof WALLET_DETAILS_SCROLLPOSITION;
  value: number;
}

interface WalletSortPreferenceAction {
  type: typeof UPDATE_WALLET_SORT_BY;
  value: SortOption;
}

export interface UpdateCreditCardDetailsAction {
  type: typeof UPDATE_CREDIT_CARD_DETAILS;
  value: Type;
}

export type WalletActionTypes =
  CreditCardDataAction
  | WalletScrollPositionAction
  | UpdateCreditCardDetailsAction
  | WalletSortPreferenceAction
  | ClearWalletAction
