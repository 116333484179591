import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import WalletPage from './WalletPage';
import {
  setWalletSortBy,
  syncDeleteCreditCard,
  setScrollPosition,
  updateCreditCard,
  trackCreditCardAdd,
} from '../../WalletActions';
import { isDataUsageDisallowed } from '../../../selectors';
import { syncDeleteFile } from '../../../files/FileActions';
import { creditCardsTagHeaderSelector, walletFileListSelector } from '../../../lib/MyDataSelectors';


const mapStateToProps = (state: any): any => ({
  creditCards: walletFileListSelector(state),
  disallowDataUsage: isDataUsageDisallowed(state),
  isUnregisteredMode: state.dashboard.isUnregisterdMode,
  preferences: state.preferences,
  tags: creditCardsTagHeaderSelector(state),
  walletPreferences: state.walletPreferences,
});

const mapDispatchToProps = (dispatch: Dispatch): any => bindActionCreators({
  setScrollPosition,
  onAddClick: trackCreditCardAdd,
  onSortByChange: setWalletSortBy,
  onDeleteClick: syncDeleteCreditCard,
  onDeleteFile: syncDeleteFile,
  onFavoriteClick: updateCreditCard,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WalletPage);
