import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';

import cloud from '../../../img/icon-cloud.png';
import cloud2x from '../../../img/icon-cloud@2x.png';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

import backup from '../../../img/icon-backup.png';
import backup2x from '../../../img/icon-backup@2x.png';

import mobile from '../../../img/icon-mobile.png';
import mobile2x from '../../../img/icon-mobile@2x.png';

import autofill from '../../../img/icon-autofill.png';
import autofill2x from '../../../img/icon-autofill@2x.png';

import secure from '../../../img/icon-secure.png';
import secure2x from '../../../img/icon-secure@2x.png';

const HowDoesPwmWorkRegistered = () => (
  <>
    <li>
      <Media
        image={(
          <img
            src={cloud}
            srcSet={`${cloud} 1x, ${cloud2x} 2x`}
            alt="cloud"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.step1"
              defaultMessage="Your passwords are stored in a highly secured online vault. All your sensitive data is encrypted with your Master Password"
            />
          </Text>
        )}
      />
      <Box
        ml="54px"
      >
        <Media
          image={(
            <img
              src={hint}
              srcSet={`${hint} 1x, ${hint2x} 2x`}
              alt="hint"
            />
          )}
          body={(
            <Text>
              <FormattedMessage
                id="dashboard.getStarted.howDoesPwmWork.step1Substep"
                defaultMessage="Even we from Avira cannot access your data as we do not know your Master Password"
              />
            </Text>
          )}
        />
      </Box>
    </li>

    <li>
      <Media
        image={(
          <img
            src={mobile}
            srcSet={`${mobile} 1x, ${mobile2x} 2x`}
            alt="mobile"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.step2"
              defaultMessage="Your passwords are synchronized across all your desktop and mobile devices"
            />
          </Text>
        )}
      />
    </li>

    <li>
      <Media
        image={(
          <img
            src={backup}
            srcSet={`${backup} 1x, ${backup2x} 2x`}
            alt="backup"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.step3"
              defaultMessage="Your passwords are regularly backed up to prevent data loss"
            />
          </Text>
        )}
      />
    </li>

    <li>
      <Media
        image={(
          <img
            src={autofill}
            srcSet={`${autofill} 1x, ${autofill2x} 2x`}
            alt="autofill"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.step4"
              defaultMessage="You can autofill your passwords both on all your desktop and mobile devices"
            />
          </Text>
        )}
      />
    </li>

    <li>
      <Media
        image={(
          <img
            src={secure}
            srcSet={`${secure} 1x, ${secure2x} 2x`}
            alt="secure"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.howDoesPwmWork.step5"
              defaultMessage="The security status checks how secure your accounts and passwords are and warns you in case of findings (PRO feature)"
            />
          </Text>
        )}
      />
    </li>
  </>
);

export default HowDoesPwmWorkRegistered;
