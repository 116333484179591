import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ms from 'ms';
import { FormattedMessage } from 'react-intl';
import ServiceContext from './ServiceContext';
import { setGeoCheck } from '../../preferences/PreferencesActions';
import { RootState } from '../store';
import debug from '../../debug';
import AuthPageWrapper from '../../oe/components/AuthPageWrapper';

import Loading from './Loading';
import SearchParamsContext from './SearchParamsContext';
import EmbargoError from './EmbargoError';

const log = debug.extend('GeoLocationWrapper');

type Props = {};

const BLOCKED_INTERVAL = ms('24h');
const UNBLOCKED_INTERVAL = ms('30d');

const GeoLocationWrapper = ({
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const dispatch = useDispatch();
  const { geoBlock, geoCheckTimestamp } = useSelector(
    (state: RootState) => state.preferences,
  );
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(geoBlock);
  const { oe } = useContext(ServiceContext);

  const {
    geoBlock: geoBlockParam,
    geoCheckTimestamp: geoCheckTimestampParam,
    setParam,
  } = useContext(SearchParamsContext);

  // eslint-disable-next-line complexity
  useEffect(() => {
    let newGeoBlock = geoBlock === false && geoBlockParam
      ? Boolean(geoBlockParam as string)
      : geoBlock;
    const parsedGeoCheckTimestamp = geoCheckTimestampParam
      ? Number.parseInt(geoCheckTimestampParam as string, 10)
      : 0;
    const newGeoCheckTimestamp = Math.max(
      geoCheckTimestamp,
      parsedGeoCheckTimestamp,
    );

    setParam({
      geoBlock: null,
      geoCheckTimestamp: null,
    });

    if (
      newGeoBlock !== geoBlock
      || newGeoCheckTimestamp !== geoCheckTimestamp
    ) {
      setError(newGeoBlock);
      dispatch(
        setGeoCheck({
          geoBlock: newGeoBlock,
          geoCheckTimestamp: newGeoCheckTimestamp,
        }),
      );

      return;
    }

    const now = Date.now();
    const lastCheckInterval = now - newGeoCheckTimestamp;

    async function init(): Promise<void> {
      try {
        log('pinging');
        await oe?.ping();
        log('all good');
        newGeoBlock = false;
      } catch (e) {
        if (e.httpCode === 451) {
          log('geo blocked');
          newGeoBlock = true;
        }
      } finally {
        dispatch(
          setGeoCheck({
            geoBlock: newGeoBlock,
            geoCheckTimestamp: Date.now(),
          }),
        );
        setError(newGeoBlock);
        setLoaded(true);
      }
    }

    log(`last checked ${ms(lastCheckInterval)} ago`);

    if (
      (newGeoBlock && lastCheckInterval > BLOCKED_INTERVAL)
      || (!newGeoBlock && lastCheckInterval > UNBLOCKED_INTERVAL)
    ) {
      init();
    } else {
      setLoaded(true);
      log('skipping check');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, geoBlock, geoCheckTimestamp]);

  if (!loaded) {
    return <Loading />;
  }

  if (error) {
    return (
      <AuthPageWrapper
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore AuthPageWrapper isn't typed
        showInfoSection={false}
        Page={() => (
          <EmbargoError
            message={(
              <FormattedMessage
                id="embargo.error.description.geolocation"
                defaultMessage="It is not possible to use Avira Password Manager in your country. Avira runs automatic checks to ensure we don't export products in breach of government regulations."
              />
            )}
          />
        )}
      />
    );
  }

  return <>{children}</>;
};

export default GeoLocationWrapper;
