import React, {
  useState, useEffect, useMemo, useRef,
} from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import { validNumber } from 'card-validator';
import PasswordInput, { Props as PasswordInputProps } from 'pwm-components/components/PasswordInput';
import {
  prettyPrintCard,
  CARDNUMBER_MAX,
  DEFAULT_GAPS_WITH_SPACING,
  removeChars,
} from '../../WalletHelpers';

type Props = WrappedComponentProps & Omit<PasswordInputProps, 'ref' | 'onChange'> & {
  onChange: (value: string) => void;
  validator: validNumber;
  value: string;
}

const VALID_INPUT_KEY_SET = new Set(['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']);
const VALID_SHORTCUTS_KEYS = new Set(['c', 'v', 'x']);
const DELETE_KEYCODE = 'Delete';

const CardnumberInput: React.FC<Props> = (props: Props) => {
  const {
    value,
    onChange,
    validator,
    ...rest
  } = props;

  const ref = useRef<HTMLInputElement>(null);
  const formattedCardNumber = useMemo(() => prettyPrintCard(value), [value]);
  // Update card gap positions with actual display value positions.
  const cardFormatGaps = useMemo(
    () => (validator.card
      ? new Set(validator.card.gaps.map((gapIndex, index) => gapIndex + index))
      : new Set(DEFAULT_GAPS_WITH_SPACING)
    ), [validator],
  );
  const inputLength = useMemo(() => CARDNUMBER_MAX + cardFormatGaps.size, [cardFormatGaps]);
  const [inputCaretIndex, setInputCaretIndex] = useState(
    formattedCardNumber ? formattedCardNumber.length : 0,
  );
  const [lastKeyEntered, setLastKeyEntered] = useState('');

  useEffect(() => {
    if (VALID_INPUT_KEY_SET.has(lastKeyEntered)) {
      if (cardFormatGaps.has(inputCaretIndex - 1)) {
        setInputCaretIndex(inputCaretIndex + 1);
      }
    }

    if (ref.current) {
      ref.current.selectionStart = inputCaretIndex;
      ref.current.selectionEnd = inputCaretIndex;
    }
    // Omitting anything but inputCaretIndex as it would place the cousor wrong when using backspace
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputCaretIndex]);

  useEffect(() => {
    if (lastKeyEntered === DELETE_KEYCODE && ref.current) {
      if (cardFormatGaps.has(inputCaretIndex)) {
        ref.current.selectionStart = inputCaretIndex + 1;
        ref.current.selectionEnd = inputCaretIndex + 1;
        return;
      }
      ref.current.selectionStart = inputCaretIndex;
      ref.current.selectionEnd = inputCaretIndex;
    }
    // Omitting anything but value as it would place the cousor wrong when using backspace
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  function onCardNumberChange(e: React.ChangeEvent<HTMLInputElement>): void {
    setInputCaretIndex(e.target.selectionStart || 0);
    const normalizedValue = removeChars(e.target.value);
    onChange(normalizedValue);
  }

  function onKeyDownHandler(e: React.KeyboardEvent): void {
    // allows for copy and paste and delete
    if (e.ctrlKey || e.metaKey) {
      if (VALID_SHORTCUTS_KEYS.has(e.key)) {
        return;
      }
    }
    // prevents default for all other key entries
    if (e.key.length === 1 && !e.key.match(/^\d$/)) {
      e.preventDefault();
      return;
    }
    setLastKeyEntered(e.key);
  }

  return (
    <PasswordInput
      value={formattedCardNumber}
      maxLength={inputLength}
      onChange={onCardNumberChange}
      onKeyDown={onKeyDownHandler}
      ref={ref}
      {...rest}
    />
  );
};

export default injectIntl(CardnumberInput);
