/* eslint-disable no-shadow, max-statements */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import Info from 'pwm-components/icons/Info';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import { getOS, getBrowser } from '../../lib/UserAgent';
import config from '../../config';
import nativeMsgCode from '../nativeMessagingErrorCode';
import {
  readFile,
  requestCredentials,
  checkNativeMessagingPermissions,
  requestNativeMessagingPermissions,
  deselectItem,
  deselectItems,
  restoreDefaultDeselected,
  selectItem,
  selectItems,
  setMapping,
  triggerImport,
  clearItems,
  parseItems,
  loadItems,
  trackImportReferrer,
  trackImportSource,
  trackImportSourceMapped,
  trackImportReviewd,
} from '../ImporterActions';

import ImporterTable from './ImporterTable';
import ImporterAccountMapping, { autoAccountMapping } from './ImporterAccountMapping';

import ImportCsvCard from './ImportCsvCard';
import ImportBrowserCard from './ImportBrowserCard';

import ActionButton from '../../componentLib/ActionButton';
import WizardSteps from '../../componentLib/WizardSteps';
import PageContentWrapper from '../../componentLib/PageContentWrapper';

import { calculateHeight, calculateVerticalPadding } from '../../lib/ElementHelper';

import alert from '../../img/alert.png';
import alert2x from '../../img/alert@2x.png';
import { validateStep2, STATUS_MAPPING, mapStatus } from '../../lib/importHelper';
import intlShape from '../../lib/intlShape';

const FIRST_STEP = 0;
const STEP_NUMBER = 4;
// eslint-disable-next-line no-undef
const currentBrowser = getBrowser()?.toLowerCase();
const currentOS = getOS().toLowerCase();

const { supportedBrowser } = config.nativeMessaging;

class ImporterContainer extends React.Component {
  constructor(props) {
    super(props);

    this.handleFiles = this.handleFiles.bind(this);
    this.handleNative = this.handleNative.bind(this);
    this.onChangeItem = this.onChangeItem.bind(this);
    this.triggerStepChange = this.triggerStepChange.bind(this);
    this.verifyItems = this.verifyItems.bind(this);
    this.calculateHeight = this.calculateHeight.bind(this);
    this.onTableMount = this.onTableMount.bind(this);
    this.onImportFromBrowserClick = this.onImportFromBrowserClick.bind(this);
    this.checkPermissions = this.checkPermissions.bind(this);
    this.setBrowserData = this.setBrowserData.bind(this);
    this.requestPermissions = this.requestPermissions.bind(this);
    this.exrtactPreselectedBrowsers = this.exrtactPreselectedBrowsers.bind(this);
    this.resetImportUrl = this.resetImportUrl.bind(this);
    this.getImportReviewTrackingData = this.getImportReviewTrackingData.bind(this);
    this.initSelectableItems = this.initSelectableItems.bind(this);
    this.onChangeAllItems = this.onChangeAllItems.bind(this);

    this.state = {
      step: FIRST_STEP,
      loading: false,
      loadingNative: false,
      emptyImportFile: false,
      tableMounted: false,
      errorCode: 0,
      defaultDeselected: [],
      selectable: {},
    };
  }

  componentDidMount() {
    this.handleNative();
  }

  componentWillUnmount() {
    const { clearItems } = this.props;
    clearItems();
  }

  onChangeItem(value, index) {
    const { selectItem, deselectItem } = this.props;
    if (value) {
      selectItem(index);
    } else {
      deselectItem(index);
    }
  }

  onChangeAllItems() {
    const { selectItems, deselectItems, importer: { items, deselected } } = this.props;
    const { selectable } = this.state;

    const select = Object.keys(selectable).length !== items.length - deselected.length;

    if (select) {
      selectItems(selectable);
    } else {
      deselectItems(Object.keys(selectable).map(k => Number(k)));
    }
  }

  onTableMount() {
    if (!this.containerRef) return;

    const minHeight = calculateHeight(this.containerRef.firstChild)
      + calculateVerticalPadding(this.containerRef);

    this.containerRef.style.minHeight = `${minHeight}px`;
    this.setState({ tableMounted: true });
  }

  async onImportFromBrowserClick(credentials, checkedBrowsers = {}) {
    const { history, loadItems, trackImportSource } = this.props;
    const { errorCode } = this.state;
    if (errorCode === nativeMsgCode.noNativeMessagingPermissions) {
      if (await this.requestPermissions()) {
        const preSelectedBrowsers = `&preselected=${JSON.stringify(checkedBrowsers)}`;
        history.push(`${history.location.pathname}${history.location.search}&step=3${preSelectedBrowsers}`);
        this.setState({ loadingNative: true });
      }
      return;
    }

    await loadItems(credentials);

    this.setState({ step: FIRST_STEP + 1 });
    this.triggerStepChange(FIRST_STEP + 1, FIRST_STEP + 1);
    this.triggerStepChange(FIRST_STEP + 2);
    this.setState({ step: FIRST_STEP + 2 });

    const trackingData = { totalItems: credentials.length };

    const selectedBrowser = [];
    supportedBrowser.forEach((browser) => {
      if (checkedBrowsers[browser]) {
        selectedBrowser.push(browser);
      }
    });
    trackingData.importSource = selectedBrowser;

    trackImportSource(trackingData);
  }

  async setBrowserData() {
    try {
      const { requestCredentials } = this.props;
      await requestCredentials();
      return null;
    } catch ({ errorCode }) {
      return errorCode;
    }
  }

  getImportReviewTrackingData(importer, accountIndex, noteIndex) {
    const { defaultDeselected } = this.state;
    const inconsistentPasswords = importer.errors.filter(
      item => (!accountIndex.indexOf(item) > -1 && item && !item.duplicateAccount),
    ).length;
    const inconsistentNotes = importer.errors.filter(
      item => (!noteIndex.indexOf(item) > -1 && item && !item.duplicateNote),
    ).length;
    const suggestedPasswords = accountIndex.filter(
      item => (defaultDeselected.indexOf(item) === -1),
    ).length;
    const suggestedNotes = noteIndex.filter(
      item => (defaultDeselected.indexOf(item) === -1),
    ).length;
    const deselectedAccounts = importer.deselected.filter(
      item => accountIndex.indexOf(item) > -1,
    ).length;
    const deselectedNotes = importer.deselected.filter(
      item => noteIndex.indexOf(item) > -1,
    ).length;
    const duplicatedPasswords = importer.errors.filter(
      item => (!accountIndex.indexOf(item) > -1 && item && item.duplicateAccount),
    ).length;
    const duplicatedNotes = importer.errors.filter(
      item => (!accountIndex.indexOf(item) > -1 && item && item.duplicateNote),
    ).length;

    const totalPasswords = accountIndex.length;
    const totalNotes = noteIndex.length;
    const selectedPasswords = accountIndex.length - deselectedAccounts;
    const selectedNotes = noteIndex.length - deselectedNotes;

    return {
      totalPasswords,
      inconsistentPasswords,
      duplicatedPasswords,
      suggestedPasswords,
      selectedPasswords,
      totalNotes,
      inconsistentNotes,
      duplicatedNotes,
      suggestedNotes,
      selectedNotes,
    };
  }

  initSelectableItems() {
    const { importer: { errors } } = this.props;
    const selectable = {};

    for (let i = 0; i < errors.length; ++i) {
      if (!STATUS_MAPPING[mapStatus(errors[i])].disabled) {
        selectable[i] = true;
      }
    }

    this.setState({ selectable });
  }

  calculateHeight(ref) {
    if (!ref) return 0;
    return calculateHeight(ref) - calculateVerticalPadding(ref);
  }

  async checkPermissions() {
    try {
      const { checkNativeMessagingPermissions } = this.props;
      await checkNativeMessagingPermissions();
      return true;
    } catch (permissionStatus) {
      this.setState({ errorCode: permissionStatus, loadingNative: false });
      return false;
    }
  }

  async requestPermissions() {
    try {
      const { requestNativeMessagingPermissions } = this.props;
      await requestNativeMessagingPermissions();
      return true;
    } catch (permissionGranted) {
      return false;
    }
  }

  async triggerStepChange(nextStep, overrideStep) {
    const {
      clearItems, deselectItem, selectItem, importer,
      trackImportSourceMapped, restoreDefaultDeselected,
    } = this.props;
    const { step: stateStep, defaultDeselected } = this.state;
    const step = overrideStep || stateStep;
    if (nextStep < FIRST_STEP || nextStep >= STEP_NUMBER) return;
    this.setState({ loading: true });

    let error = false;

    if (nextStep === FIRST_STEP) {
      await clearItems();
      this.handleNative();
    } else if (step === FIRST_STEP + 1 && nextStep === FIRST_STEP + 2) {
      const { mapping, rawData, deselected } = importer;

      if (Object.values(autoAccountMapping(rawData)).some(id => id !== null)) {
        deselectItem(0);
      } else {
        const headerRowIndex = deselected.indexOf(0);
        if (headerRowIndex >= 0) {
          selectItem(headerRowIndex);
        }
      }

      const mappingErrors = validateStep2(mapping);

      if (mappingErrors.length) {
        error = true;
        this.setState({
          mappingErrors,
        });
      } else {
        await this.verifyItems();
        this.initSelectableItems();
      }

      this.setState({ defaultDeselected: [...importer.deselected] });

      const defaultMapping = autoAccountMapping(rawData);
      const customMapping = importer.mapping;
      let defaultMappingChanged = true;

      if (isEqual(defaultMapping, customMapping)) defaultMappingChanged = false;

      const suggestedMapping = Object.keys(defaultMapping).map((key) => {
        if (defaultMapping[key] || defaultMapping[key] === 0) return key;
        return null;
      });

      const userMapping = Object.keys(customMapping).map((key) => {
        if (customMapping[key] || customMapping[key] === 0) return key;
        return null;
      });

      const trackingData = {
        defaultMappingChanged,
        suggestedMapping,
        userMapping,
      };

      trackImportSourceMapped(trackingData);
    } else if (step === FIRST_STEP + 2 && nextStep === FIRST_STEP + 3) {
      await this.triggerImport();
    } else if (step === FIRST_STEP + 2 && nextStep === FIRST_STEP + 1) {
      restoreDefaultDeselected(defaultDeselected);
      this.setState({ step: nextStep });
    }

    if (error) {
      this.setState({ loading: false });
    } else if (overrideStep) {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 200);
    } else {
      // Prevents mistakenly double clicking
      setTimeout(() => {
        this.setState({ loading: false, step: nextStep });
      }, (nextStep < step ? 0 : 200));
    }
  }

  async triggerImport() {
    const { importer, triggerImport, trackImportReviewd } = this.props;
    const accountIndex = [];
    const noteIndex = [];

    await triggerImport();

    importer.itemsType.forEach((type, index) => {
      if (type === 'account') accountIndex.push(index);
    });
    importer.itemsType.forEach((type, index) => {
      if (type === 'note') noteIndex.push(index);
    });

    trackImportReviewd(this.getImportReviewTrackingData(importer, accountIndex, noteIndex));
  }

  async handleFiles(files) {
    const { importer, readFile, trackImportSource } = this.props;

    const rawData = await readFile(files[0]) || [];

    if (rawData.length === 0) {
      this.handleNative();
      this.setState({ emptyImportFile: true });
    } else {
      this.setState({ step: FIRST_STEP + 1 });
    }

    const trackingData = {
      totalItems: importer.rawData.length,
      importSource: [
        'CSV',
      ],
    };

    trackImportSource(trackingData);
  }

  verifyItems() {
    const { parseItems } = this.props;
    parseItems();
  }

  exrtactPreselectedBrowsers() {
    const { location } = this.props;
    const query = queryString.parse(location.search);
    return JSON.parse(query.preselected);
  }

  resetImportUrl() {
    const { history, location } = this.props;
    const { context } = queryString.parse(location.search);
    history.replace({ search: `?context=${context}` });
  }

  // eslint-disable-next-line complexity
  async handleNative() {
    const {
      extensionConnected, history, importer, location, trackImportReferrer,
    } = this.props;

    if (!config.enableNativeImport) return;
    if (currentOS !== 'windows' && !config.nativeMessaging.showImportFromBrowser.includes(currentBrowser)) return;
    if (!extensionConnected) return;

    this.setState({ loadingNative: true });

    const permission = await this.checkPermissions();
    if (!permission) return;

    const browserDataErrCode = await this.setBrowserData();
    if (browserDataErrCode) {
      this.setState({ errorCode: browserDataErrCode, loadingNative: false });
    }

    this.setState({ loadingNative: false });

    let connectClientActive = true;
    if (importer.browserData.errorCode !== 0) connectClientActive = false;

    const importReferrer = new URLSearchParams(location.search).get('context');
    const trackingData = {
      context: importReferrer,
      extensionActive: extensionConnected,
      connectClientActive,
    };

    supportedBrowser.forEach((browser) => {
      const { browserData } = importer;
      trackingData[browser] = (browserData[browser] || { credentials: [] }).credentials.length;
    });

    trackImportReferrer(trackingData);

    if (history.location.search.indexOf('step=3') !== -1) {
      const { browserData } = importer;
      let credentials = [];
      let cutHeader = false;
      const preSelectedBrowsers = this.exrtactPreselectedBrowsers();

      if (browserData.errorCode !== nativeMsgCode.noError) {
        this.resetImportUrl();
        return;
      }

      Object.keys(browserData).forEach((key) => {
        const data = browserData[key];
        if (preSelectedBrowsers[data.browser]) {
          if (cutHeader) {
            data.credentials.shift();
          }
          credentials = credentials.concat(data.credentials);
          cutHeader = true;
        }
      });

      if (credentials === undefined || credentials.length === 0) {
        this.resetImportUrl();
        return;
      }

      this.onImportFromBrowserClick(credentials, preSelectedBrowsers);
      this.resetImportUrl();
    }
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      step, tableMounted, mappingErrors, loadingNative, loading, emptyImportFile, selectable,
    } = this.state;
    const {
      intl, importer, extensionConnected, language, location, setMapping,
    } = this.props;

    let content = '';
    let buttons = '';
    let importChrome = '';
    let errors = null;

    const header = (
      <div className="u-ml-a u-mr-a u-mb-xxxl" style={{ width: '60%' }}>
        <WizardSteps
          active={step}
          onStepClick={(clickedStep) => {
            if (clickedStep < step) {
              this.triggerStepChange(clickedStep);
            }
          }}
          steps={[
            intl.formatMessage({ id: 'dashboard.settings.import.stepOne' }),
            intl.formatMessage({ id: 'dashboard.settings.import.stepTwo' }),
            intl.formatMessage({ id: 'dashboard.settings.import.stepThree' }),
            intl.formatMessage({ id: 'dashboard.settings.import.stepFour' }),
          ]}
        />
      </div>
    );

    if (mappingErrors) {
      errors = (
        <div>
          {
            mappingErrors.map((mappingError, index) => (
              <div key={mappingError}>
                <div className="u-mb-s">
                  {
                    mappingError.map(type => (
                      <p key={type}>
                        •
                        {' '}
                        {intl.formatMessage({ id: ImporterAccountMapping[type].label })}
                      </p>
                    ))
                  }
                </div>
                {
                  mappingErrors.length > 1 && index < mappingError.length ? (
                    <div className="u-mb-s">
                      <FormattedMessage id="dashboard.settings.import.or" />
                    </div>
                  ) : null
                }
              </div>
            ))
          }
        </div>
      );
    }

    if (step === FIRST_STEP) {
      if (currentOS === 'windows'
        && config.nativeMessaging.showImportFromBrowser.includes(currentBrowser)
        && config.enableNativeImport) {
        importChrome = (
          <>
            <ImportBrowserCard
              browserData={importer.browserData}
              onActionClick={this.onImportFromBrowserClick}
              extensionInstalled={extensionConnected}
              lang={language}
              performRetry={this.handleNative}
              loading={loadingNative}
            />
            <span className="u-fx u-fx-ai-c">
              <div
                className="u-ml-xxl u-mr-xxl c-importer__strong c-importer__or"
              >
                <FormattedMessage id="dashboard.settings.import.or" />
              </div>
            </span>
          </>
        );
      }

      content = (
        <>
          <div className="u-fx">
            {importChrome}
            <ImportCsvCard
              handleFiles={this.handleFiles}
            />
          </div>
        </>
      );

      // Buttons have to be present in the first step so that
      // the correct height is calculated for the table
      buttons = (
        <div
          className="u-mb-xl"
          style={{
            visibility: 'hidden',
          }}
        >
          <ActionButton
            label="placeholder"
            color="white"
            block={false}
            onClick={() => { }}
          />
        </div>
      );
    } else if (step === FIRST_STEP + 3) {
      content = (
        <div className="u-txt-center">
          <div className="u-mb-l">
            <div className="u-txt-greatprimer u-mb-m">
              <FormattedMessage
                id="dashboard.settings.import.stepFour.totalCount"
                defaultMessage="Out of {total} enteries, you have imported:"
                values={{ total: importer.rawData.length }}
              />
            </div>
            <div className="u-d-ib">
              <ul className="u-txt-english">
                <li className="u-mb-xxs">
                  <FormattedMessage
                    id="dashboard.settings.import.stepFour.passwordCount"
                    defaultMessage="Passwords: {value}"
                    values={{ value: importer.finalStatus.accounts }}
                  />
                </li>
                <li className="u-mb-xxs">
                  <FormattedMessage
                    id="dashboard.settings.import.stepFour.notesCount"
                    defaultMessage="Notes: {value}"
                    values={{ value: importer.finalStatus.notes }}
                  />
                </li>
              </ul>
              <div className="u-mt-xxs">
                <FormattedMessage
                  id="dashboard.settings.import.stepFour.mergeCount"
                  defaultMessage="We merged {value} password(s)"
                  values={{ value: importer.finalStatus.merges }}
                />
              </div>
            </div>
          </div>
          <div>
            <Link
              className="c-button a-import-next"
              to={new URLSearchParams(location.search).get('context') === 'get-started' ? '/get-started/import-your-passwords' : '/mydata/passwords'}
            >
              <FormattedMessage id="dashboard.settings.import.stepFour.done" />
            </Link>
          </div>
        </div>
      );
    } else {
      const containerHeight = this.calculateHeight(this.containerRef);
      let selected = 0;
      let allSelected = false;
      let someSelected = false;
      if (step === FIRST_STEP + 2) {
        selected = importer.items.length - importer.deselected.length;
        allSelected = Object.keys(selectable).length === selected;
        someSelected = !allSelected && selected > 0;
      }

      content = (
        <ImporterTable
          items={importer.rawData}
          deselected={importer.deselected}
          itemStatus={importer.errors}
          itemsType={importer.itemsType}
          mapping={importer.mapping}
          allowMapping={step === FIRST_STEP + 1}
          selectBoxes={step === FIRST_STEP + 2}
          someSelected={someSelected}
          allSelected={allSelected}
          onChangeItem={this.onChangeItem}
          onChangeAllItems={this.onChangeAllItems}
          onChangeMapping={setMapping}
          maxHeight={containerHeight}
          onMount={this.onTableMount}
          onUnmount={() => { this.setState({ tableMounted: false }); }}
          title={
            intl.formatMessage({
              id: step === FIRST_STEP + 1
                ? 'dashboard.settings.import.mapImportMessage'
                : 'dashboard.settings.import.stepThree.verify',
            })
          }
          intl={intl}
        />
      );

      buttons = (
        <div className="u-txt-center">
          <ActionButton
            type="button"
            label={intl.formatMessage({ id: 'dashboard.settings.import.back' })}
            className="u-mr-m"
            color="white"
            block={false}
            loading={loading}
            onClick={() => this.triggerStepChange(step - 1)}
          />

          <ActionButton
            type="button"
            className="a-import-next"
            label={intl.formatMessage({
              id: step === FIRST_STEP + 1 ? 'dashboard.settings.import.reviewData' : 'dashboard.settings.import',
            })}
            block={false}
            loading={loading}
            onClick={() => this.triggerStepChange(step + 1)}
          />
        </div>
      );
    }

    return (
      <PageContentWrapper height="100%">
        {header}

        <div
          className={`${tableMounted ? 'u-fx-s-1' : 'u-fx-g-1'} u-mb-xl u-p-xs`}
          ref={(ref) => { this.containerRef = ref; }}
        >
          {content}
        </div>

        {buttons}

        <ConfirmationDialog
          show={emptyImportFile}
          loading={loading}
          onCloseClick={() => this.setState({ emptyImportFile: null })}
          confirmLabel={intl.formatMessage({
            id: 'dashboard.settings.import.errorMessage.confirm',
          })}
          onConfirm={() => this.setState({ emptyImportFile: null })}
        >
          <div className="u-txt-center">
            <p className="u-mb-m">
              <Info color="royalblue" style={{ height: 50, width: 50 }} />
            </p>
            <p className="u-mb-s">
              <FormattedMessage
                id="dashboard.settings.import.nothingToImport"
              />
            </p>
          </div>
        </ConfirmationDialog>
        <ConfirmationDialog
          show={mappingErrors}
          loading={loading}
          confirmLabel={intl.formatMessage({
            id: 'dashboard.settings.import.errorMessage.confirm',
          })}
          onConfirm={() => this.setState({ mappingErrors: null })}
          onCloseClick={() => this.setState({ mappingErrors: null })}
        >
          <div className="u-txt-center">
            <p className="u-mb-m">
              <img
                src={alert}
                srcSet={`${alert} 1x, ${alert2x} 2x`}
                alt="alert"
              />
            </p>
            <p className="u-mb-s">
              <FormattedMessage
                id="dashboard.settings.import.errorMessage"
              />
            </p>
            {errors}
            <p>
              <FormattedMessage
                id="dashboard.settings.import.stepTwo.adjustMapping"
              />
            </p>
          </div>
        </ConfirmationDialog>
      </PageContentWrapper>
    );
  }
}

ImporterContainer.propTypes = {
  readFile: PropTypes.func.isRequired,
  requestCredentials: PropTypes.func.isRequired,
  checkNativeMessagingPermissions: PropTypes.func.isRequired,
  requestNativeMessagingPermissions: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  importer: PropTypes.shape({
    browserData: PropTypes.shape(),
    items: PropTypes.array.isRequired,
    itemsType: PropTypes.array.isRequired,
    deselected: PropTypes.array.isRequired,
    errors: PropTypes.array.isRequired,
    mapping: PropTypes.shape({
      name: PropTypes.number,
      website: PropTypes.number,
      username: PropTypes.number,
      password: PropTypes.number,
    }).isRequired,
    rawData: PropTypes.array.isRequired,
    finalStatus: PropTypes.shape({
      accounts: PropTypes.number,
      notes: PropTypes.number,
      merges: PropTypes.number,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  selectItem: PropTypes.func.isRequired,
  selectItems: PropTypes.func.isRequired,
  deselectItem: PropTypes.func.isRequired,
  deselectItems: PropTypes.func.isRequired,
  restoreDefaultDeselected: PropTypes.func.isRequired,
  triggerImport: PropTypes.func.isRequired,
  loadItems: PropTypes.func.isRequired,
  clearItems: PropTypes.func.isRequired,
  parseItems: PropTypes.func.isRequired,
  setMapping: PropTypes.func.isRequired,
  extensionConnected: PropTypes.bool,
  language: PropTypes.string.isRequired,
  trackImportReferrer: PropTypes.func.isRequired,
  trackImportSource: PropTypes.func.isRequired,
  trackImportSourceMapped: PropTypes.func.isRequired,
  trackImportReviewd: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      search: PropTypes.string,
      pathname: PropTypes.string,
    }),
    replace: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  }),
};

ImporterContainer.defaultProps = {
  extensionConnected: false,
  history: {
    location: {
      search: '',
      pathname: '',
    },
  },
};

const mapStateToProps = ({ importer, dashboard, preferences }) => ({
  importer,
  extensionConnected: dashboard.extensionConnected,
  language: preferences.language,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  readFile,
  requestCredentials,
  checkNativeMessagingPermissions,
  requestNativeMessagingPermissions,
  deselectItem,
  deselectItems,
  restoreDefaultDeselected,
  selectItem,
  selectItems,
  setMapping,
  triggerImport,
  clearItems,
  parseItems,
  loadItems,
  trackImportReferrer,
  trackImportSource,
  trackImportSourceMapped,
  trackImportReviewd,
}, dispatch);

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ImporterContainer)));
