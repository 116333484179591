import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecurityStatusPage from './SecurityStatusPage';
import {
  dismissBreachCheckBanner, setShowcaseAccountId, setScrollPosition, trackSecurityStatusDisplay,
} from '../SecurityStatusActions';
import { setHibpBreachCheck } from '../../settings/SettingsActions';
import { getSecurityStatus, getUsernameBreachesLoadedPercent } from '../selectors';
import { deleteUnknownBreach } from '../DeletedUnknownBreachesActions';
import { showGetProFree, getHasAccessToPro } from '../../user/selectors';
import { syncDeleteAccount } from '../../accounts/AccountActions';


const mapStateToProps = (state) => {
  const { preferences, securityStatusPreferences, userData } = state;
  return ({
    securityStatus: getSecurityStatus(state),
    securityStatusPreferences,
    breachCheck: userData.breach_check,
    breachCheckBannerDismissed: preferences.breachCheckBannerDismissed,
    otherBreachesBannerDismissed: preferences.otherBreachesBannerDismissed,
    isPro: userData.isPro,
    usernamesLoadedPercent: getUsernameBreachesLoadedPercent(state),
    showGetProFree: showGetProFree(state),
    hasAccessToPro: getHasAccessToPro(state),
  });
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onBannerClose: dismissBreachCheckBanner,
  onBannerActivate: setHibpBreachCheck,
  onDeleteUnknownBreachClick: deleteUnknownBreach,
  onDeleteAccountClick: syncDeleteAccount,
  setShowcaseAccountId,
  setScrollPosition,
  trackSecurityStatusDisplay,
}, dispatch);

const SecurityStatusPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityStatusPage);

export default SecurityStatusPageContainer;
