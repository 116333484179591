import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import * as Sentry from '@sentry/browser';
import keyBy from 'lodash/keyBy';

import config from '../config';

import { SET_AVIRA_BREACHED_WEBSITES } from './AviraBreachesActionTypes';


type State = {
  preferences: any;
}

type ExtraArguments = {
  breachedWebsiteService: any;
}

type AviraBreachesThunkAction<R> = ThunkAction<R, State, ExtraArguments, AnyAction>

const arrayToHash = (dataArray: any, key = 'id'): any => keyBy(dataArray, o => o[key]);

// eslint-disable-next-line import/prefer-default-export
export const loadAviraBreaches = ():
AviraBreachesThunkAction<void> => async (dispatch, getState, { breachedWebsiteService }) => {
  const { preferences } = getState();
  try {
    const aviraBreaches = await breachedWebsiteService
      .getBreachedWebsites(preferences.language, config.aviraBreachedWebsitesApiKey);
    dispatch({
      type: SET_AVIRA_BREACHED_WEBSITES,
      value: arrayToHash(aviraBreaches.domains, 'domain'),
    });
  } catch (e) {
    Sentry.captureException(e);
  }
};
