import React from 'react';
import { string } from 'prop-types';
import ProtectedRoute from './ProtectedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';

const UnverifiedEmailRoute = ({ path, ...otherProps }) => (
  <AuthenticatedRoute
    path={path}
    render={() => (
      <UnauthorizedRoute
        path={path}
        render={() => (
          <ProtectedRoute
            path={path}
            requirement={({ oe }) => !oe.gdprConfirm && (!!oe.gdprConfirm || !!oe.gdprConsent)}
            redirectPath="/create-master-password"
            {...otherProps}
          />
        )}
      />
    )}
  />
);

UnverifiedEmailRoute.propTypes = {
  path: string.isRequired,
};

export default UnverifiedEmailRoute;
