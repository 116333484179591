import { connect } from 'react-redux';

import Authentication, { StateProps, DispatchProps, OwnProps } from './Authentication';

import { initAuth, logoutUser, authSetDidReload } from '../AuthenticationActions';
import { refreshOE } from '../../oe/OEActions';
import { trackAppOpen } from '../../app/AppActions';
import { getRelevantOeTokens } from '../../oe/selector';
import { getRelevantUserKey } from '../../user/selectors';
import { setError } from '../../dashboard/DashboardActions';

// TODO: this is not a good way to pass props or to define the return type on this
// and should be changed when we have solid typing definitions in our store
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const mapStateToProps = (state: any): StateProps => ({
  auth: state.auth,
  dashboard: state.dashboard,
  oe: getRelevantOeTokens(state),
  userKey: getRelevantUserKey(state),
  userData: state.userData,
  tracking: state.tracking,
});

const mapDispatchToProps: DispatchProps = {
  initAuth: initAuth as any,
  refreshOE: refreshOE as any,
  onOpen: trackAppOpen,
  logoutUser: logoutUser as any,
  setDidReload: authSetDidReload,
  onError: setError,
};

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Authentication);
