/* eslint-disable no-restricted-globals */
import * as Sentry from '@sentry/browser';
import { pwnedPassword } from 'hibp';
import responseCodes from '@avira-pwm/services/responseCodes';

import * as crypto from '@avira-pwm/crypto-tools';

import { Status } from '@avira-pwm/services/licenseMigration';
import {
  isInProgressStatus,
  waitForMigration,
  MigrationError,
  formatErrorForDatabase,
} from '@avira-pwm/sync/helpers/migration';
import { getServerTimeFromErrorMsg } from '@avira-pwm/helpers/ServerDate';
import config from '../config';

import {
  getErrorDescription, getError, getErrorCode, mapErrorCode,
} from '../lib/ErrorHelper';
import { TrackingActions, MixpanelEvents, MixpanelErrorDictionary } from '../tracking';
import { getMasterPasswordErrorCode, getVerifyMasterPasswordErrorCode } from '../lib/AuthenticationHelper';
import { validatePassword } from '../lib/AuthenticationValidator';
import {
  setError,
  setLoadingOverlay, clearLoadingOverlay, verifyExtensionVersion,
} from '../dashboard/DashboardActions';
import { markActive } from '../dashboard/AutoLockTrackerActions';
import { sendSync } from '../authentication/AuthenticationActions';
import {
  setUserData,
  storeAuthToken,
  storeKey,
  migrateUnregisteredModeMilestones,
  setLockReason,
  getUserData,
  lockUser,
  clearAuthToken,
} from './UserActions';
import debug from '../debug';
import mixpanel from '../tracking/mixpanel';
import {
  openVaultWithPassword,
  createVault,
  triggerMigrate,
  deleteVault,
  increaseMigrationAttempts,
  closeVault,
  setBypassVault,
} from '../nlok/NLOKActions';
import { ThunkAction } from '../app/thunk';
import {
  getMigrationStatus,
  shouldConnectToVault as shouldConnectToVaultSelector,
  shouldMigrateVault as shouldMigrateVaultSelector,
  getMigrationError,
  getMigrationAttempts,
  getShouldBypassVault,
} from '../nlok/selectors';
import { trackMigrationStatusChanged } from '../nlok/NLOKMigrationTrackingActions';
import { setServerTimeOffset } from '../preferences/PreferencesActions';
import { getServerTimeOffset, setServerTime } from '../preferences/helpers';


const log = debug.extend('MasterPasswordActions');

type Awaited<T> = T extends PromiseLike<infer U> ? U : T;

const { identify, trackEvent } = TrackingActions;

const {
  MP_PWM_REGISTER_SHOWN,
  MP_PWM_REGISTER_INITIATED,
  MP_PWM_REGISTER,
  MP_PWM_REGISTER_USER,
  MP_PWM_REGISTER_FAILED,
  MP_PWM_REGISTER_DROP_OUT,
  MP_UNLOCK_SHOWN,
  MP_UNLOCK_INITIATED,
  MP_UNLOCK,
  MP_UNLOCK_FAILED,
  MP_UNLOCK_DROP_OUT,
} = MixpanelEvents;

const mpActionTable = {
  shown: {
    create: MP_PWM_REGISTER_SHOWN,
    unlock: MP_UNLOCK_SHOWN,
    provide: MP_UNLOCK_SHOWN,
  },
  initiated: {
    create: MP_PWM_REGISTER_INITIATED,
    unlock: MP_UNLOCK_INITIATED,
    provide: MP_UNLOCK_INITIATED,
  },
  failed: {
    create: MP_PWM_REGISTER_FAILED,
    verify: MP_PWM_REGISTER_FAILED,
    unlock: MP_UNLOCK_FAILED,
    provide: MP_UNLOCK_FAILED,
  },
  dropOut: {
    create: MP_PWM_REGISTER_DROP_OUT,
    verify: MP_PWM_REGISTER_DROP_OUT,
    unlock: MP_UNLOCK_DROP_OUT,
    provide: MP_UNLOCK_DROP_OUT,
  },
};

const MAX_MIGRATION_ATTEMPTS = 2;

const getSecondsFromTimestamp = (
  (timeStamp: number): number => Math.floor((Date.now() - timeStamp) / 1000)
);

export const trackMasterPasswordShown = (
  usageAs: keyof typeof mpActionTable.shown,
): ThunkAction<void> => async (dispatch) => {
  dispatch(trackEvent(mpActionTable.shown[usageAs]));
};

export const trackMasterPasswordDropout = ({
  usageAs,
  mountTimestamp,
}: {
  usageAs: keyof typeof mpActionTable.dropOut;
  mountTimestamp: number;
}): ThunkAction<void> => (dispatch) => {
  dispatch(trackEvent(mpActionTable.dropOut[usageAs], {
    timeSpent: getSecondsFromTimestamp(mountTimestamp),
  }));
};

export const trackMasterPasswordInitiated = (
  usageAs: keyof typeof mpActionTable.initiated,
): ThunkAction<void> => (dispatch) => {
  dispatch(trackEvent(mpActionTable.initiated[usageAs]));
};

export const trackMasterPasswordFailed = (
  usageAs: keyof typeof mpActionTable.failed,
  error: any,
): ThunkAction<Promise<void>> => dispatch => new Promise((resolve) => {
  const errorCode = getErrorCode(error);
  // Often the Initiated event and the Failed event are triggered at the same time
  // and most of the time the second request will finist slightly earlier than the second
  // breaking the funnel order, hence the setTimeout
  setTimeout(() => {
    dispatch(trackEvent(mpActionTable.failed[usageAs], {
      cause: MixpanelErrorDictionary[errorCode as keyof typeof MixpanelErrorDictionary],
      cause_error: error?.status ?? error.name,
      cause_description: error?.code ?? error?.message,
    }));
    resolve();
  }, 50);
});

type CreateMasterPasswordArgs = {
  usageAs: keyof typeof mpActionTable.failed;
  password: string;
  passwordVerification: string;
  consent: boolean;
  mountTimestamp: number;
};

// Account creation action
export const createMasterPassword = ({
  usageAs,
  password,
  passwordVerification,
  consent,
  mountTimestamp,
  // eslint-disable-next-line max-statements, complexity
}: CreateMasterPasswordArgs): ThunkAction<Promise<void>> => async (
  dispatch,
  getState,
  { licenseService },
) => {
  const cmpLog = log.extend('createMasterPassword');

  cmpLog('validating master password');

  if (!validatePassword(password)) {
    const errorCode = getMasterPasswordErrorCode('create', password);
    await (dispatch(trackMasterPasswordFailed('create', errorCode)).catch(() => {}));
    throw getErrorDescription(errorCode);
  }

  let numberOfBreaches = null;
  try {
    numberOfBreaches = await pwnedPassword(password);
  } catch (e) { /** HIBP calls might fail; we can ignore it */ }

  if (numberOfBreaches) {
    const errorCode = getMasterPasswordErrorCode('create', password, true);
    await (dispatch(trackMasterPasswordFailed('create', errorCode)).catch(() => {}));
    throw getErrorDescription(errorCode);
  }

  if (!passwordVerification || password !== passwordVerification) {
    const errorCode = getVerifyMasterPasswordErrorCode(passwordVerification);
    await (dispatch(trackMasterPasswordFailed('create', errorCode)).catch(() => {}));
    throw getErrorDescription(errorCode);
  }

  if (!consent) {
    const errorCode = getMasterPasswordErrorCode('create', password, false, consent);
    await (dispatch(trackMasterPasswordFailed('create', errorCode)).catch(() => {}));
    throw getErrorDescription(errorCode);
  }

  cmpLog('master password validated');

  const state = getState();

  const { oe, dashboard: { isUnregisteredMode } } = state;

  try {
    await dispatch(verifyExtensionVersion());
    const userExists: boolean = await licenseService.isUserRegistered(oe.token!);

    if (!userExists) {
      cmpLog('user does not exist; attempting to delete previous vault');
      await dispatch(deleteVault());
    }

    cmpLog('creating license user');
    const { auth_token, user } = await licenseService.createUser(
      oe.token!,
      oe.email!,
      password,
      mixpanel.getInstallationDistinctId(),
    );
    cmpLog('license user created');

    cmpLog('creating vault');
    await dispatch(createVault(password));
    cmpLog('vault created');
    await dispatch(openVaultWithPassword(password));
    cmpLog('vault open');

    await licenseService.updateMigrationStatus(
      auth_token,
      oe.token!,
      Status.AllMigrated,
    );
    cmpLog('migration status updated');
    dispatch(trackMigrationStatusChanged({ status: Status.AllMigrated }));

    mixpanel.resetInstallationDistinctId();

    const userData = await licenseService.getUserData(auth_token, oe.token!);
    dispatch(setUserData(userData.user));

    cmpLog('setting user data');

    if (auth_token) {
      dispatch(storeAuthToken(auth_token));
    }

    const encKey = user.key;
    const verKey = user.verify_key;
    const encDistinctId = user.distinct_id;
    const encFileKey = user.key2;
    try {
      const plainKey = licenseService.getPlainKey(password, encKey, verKey);
      const key2 = crypto.decryptFileKey(password, encFileKey);
      dispatch(markActive()); // when the password is verified also mark it as active.
      dispatch(storeKey(plainKey, key2));
      cmpLog('unlocking user');
      const distinctId = licenseService.getPlainDistinctId(plainKey, encDistinctId);

      dispatch(trackEvent(MP_PWM_REGISTER, {
        timeSpent: getSecondsFromTimestamp(mountTimestamp),
      }));

      dispatch(identify(distinctId));
      dispatch(sendSync());
      dispatch(trackEvent(MP_PWM_REGISTER_USER, {
        timeSpent: getSecondsFromTimestamp(mountTimestamp),
      }));

      if (isUnregisteredMode) {
        dispatch(migrateUnregisteredModeMilestones());
      }
    } catch (e) {
      // Todo: there should be no way that something goes wrong with the decryption
      // And if it would, why are we ignoring it?
      await (dispatch(trackMasterPasswordFailed(usageAs, e)).catch(() => {}));
      await dispatch(closeVault());
    }
  } catch (e) {
    await (dispatch(trackMasterPasswordFailed(usageAs, e)).catch(() => {}));
    await dispatch(closeVault());
    throw getError(e);
  }
};


export const validateMasterPassword = (
  licenseService: any,
  password: string,
  encKey: string,
  verKey: string,
  authToken: string,
  // eslint-disable-next-line max-params
): string => {
  let key = null;
  try {
    key = licenseService.getPlainKey(password, encKey, verKey);
  } catch (e) {
    key = licenseService.recoverPlainKey(password, encKey, verKey);
    licenseService.updateKey(authToken, password, key);
  }

  return key;
};

export const verifyMasterPassword = (
  // eslint-disable-next-line max-statements
  ({ password }: { password: string }): ThunkAction<void> => async (
    dispatch,
    getState,
    { licenseService },
  ) => {
    if (!password) {
      const errorCode = getMasterPasswordErrorCode('provide', password);
      dispatch(trackMasterPasswordFailed('provide', errorCode));
      throw getErrorDescription(errorCode);
    }

    const { oe, user } = getState();

    let { authToken } = user;
    let encKey;
    let verKey;

    try {
      if (!authToken) {
        const result = await licenseService.getAuthData(oe.token, oe.email);
        authToken = result.auth_token;
      }
      const userData = await licenseService.getUserData(authToken, oe.token);

      encKey = userData.user.key;
      verKey = userData.user.verify_key;
    } catch (e) {
      dispatch(trackMasterPasswordFailed('provide', e));
      throw getError(e);
    }

    try {
      validateMasterPassword(licenseService, password, encKey, verKey, authToken!);
    } catch (e) {
      dispatch(trackMasterPasswordFailed('provide', e));
      throw getError(e);
    }
  }
);

type CheckMasterPassword = {
  password: string;
  mountTimestamp: number;
  attempts?: number;
};

const MAX_RETRIES = 2;

// Unlock action
export const checkMasterPassword = (
  // eslint-disable-next-line max-statements, complexity
  ({ password, mountTimestamp, attempts = 0 }: CheckMasterPassword): ThunkAction<void> => async (
    dispatch,
    getState,
    { licenseService },
  ) => {
    const cmpLog = log.extend('checkMasterPassword');

    if (!password) {
      const errorCode = getMasterPasswordErrorCode('provide', password);
      dispatch(trackMasterPasswordFailed('provide', errorCode));
      throw getErrorDescription(errorCode);
    }

    const { oe, user, dashboard: { isUnregisteredMode } } = getState();

    let { authToken } = user;

    let userData: Awaited<ReturnType<typeof licenseService.getUserData>>['user'];

    try {
      await dispatch(verifyExtensionVersion());

      if (!authToken) {
        cmpLog('fetching auth data');
        const result = await licenseService.getAuthData(oe.token, oe.email);
        userData = result.user;
        authToken = result.auth_token;
      } else {
        cmpLog('fetching user data');
        userData = (await licenseService.getUserData(authToken, oe.token)).user;
      }
    } catch (e) {
      try {
        if (mapErrorCode(e) === responseCodes.license.UNAUTHORIZED) {
          cmpLog('refreshing auth data');
          const result = await licenseService.getAuthData(oe.token, oe.email);
          userData = result.user;
          authToken = result.auth_token;
        } else {
          log(e);
          dispatch(trackMasterPasswordFailed('provide', e));
          throw getError(e);
        }
      } catch (e2) {
        log(e);
        dispatch(trackMasterPasswordFailed('provide', e));
        throw getError(e);
      }
    }

    const encKey = userData.key;
    const verKey = userData.verify_key;
    const encKey2 = userData.key2;
    const encDistinctId = userData.distinct_id;
    dispatch(setUserData(userData));

    try {
      cmpLog('validating master password');
      const key = validateMasterPassword(licenseService, password, encKey, verKey, authToken!);
      cmpLog('master password valid');

      let key2 = null;
      if (encKey2 && encKey2.length) {
        key2 = crypto.decryptFileKey(password, encKey2);
      }

      if (key) {
        let distinctId;
        let shouldMigrateVault = shouldMigrateVaultSelector(getState());
        let shouldConnectToVault = shouldConnectToVaultSelector(getState());
        const migrationError = getMigrationError(getState());
        const currentStatus = getMigrationStatus(getState());

        dispatch(setLoadingOverlay('dashboard.accounts.loading', null));

        if (isInProgressStatus(currentStatus?.status ?? null)) {
          cmpLog('wait for migration to finish in other client');
          try {
            await waitForMigration(
              { licenseService, logger: cmpLog },
              { oeToken: oe.token!, authToken: authToken! },
            );
          } catch (e) {
            // eslint-disable-next-line max-depth
            if (mapErrorCode(e) === responseCodes.license.UNAUTHORIZED
                && attempts < MAX_RETRIES) {
              dispatch(clearAuthToken());
              /**
                When backend receives "all_migrated" from any client it invalidates all auth_tokens
                for that user and this will result in a rejected call to the license server while
                trying to fetch a new migration status.
                It could be though that it was  for other reasons
               */
              return dispatch(
                checkMasterPassword({ password, mountTimestamp, attempts: attempts + 1 }),
              );
            }

            // eslint-disable-next-line max-depth
            if (!(e instanceof MigrationError && e.isNonRecoverable())) {
              dispatch(setError({
                messageId: 'dashboard.error.contactSupport',
                retry: true,
                error: e as Error,
                context: 'unlock',
                dismissable: true,
              }));
              dispatch(clearLoadingOverlay());

              throw e;
            }
          }
        }

        if (shouldMigrateVault) {
          // if for some reason migration aborted user will still have a vault
          // delete before attempting to migrate
          cmpLog('clearing for migration vault');
          await dispatch(deleteVault());
        }

        if (shouldMigrateVault || shouldConnectToVault) {
          cmpLog('opening vault');
          try {
            await dispatch(openVaultWithPassword(password, shouldMigrateVault));
            cmpLog('vault open');
          } catch (e) {
            Sentry.captureException(e, {
              tags: {
                action: 'open-vault',
              },
              extra: {
                shouldMigrateVault,
              },
            });

            // eslint-disable-next-line max-depth
            if (!shouldMigrateVault && (e as Error).message === 'Unable to handle challenge') {
              dispatch(setBypassVault(new Date().toISOString()));
              shouldMigrateVault = shouldMigrateVaultSelector(getState());
              shouldConnectToVault = shouldConnectToVaultSelector(getState());

              cmpLog("can't open vault; setting bypass");
            } else {
              throw e;
            }
          }
        }

        if (shouldMigrateVault) {
          cmpLog('triggering migration');
          try {
            await dispatch(triggerMigrate(
              oe.token!,
              authToken!,
              key,
              // TODO: check for null
              key2!,
              password,
            ));
          } catch (e) {
            dispatch(increaseMigrationAttempts());

            // eslint-disable-next-line max-depth
            if (!(e instanceof MigrationError && e.isNonRecoverable())) {
              const errorName = (e as MigrationError).error?.name ?? (e as Error).name;
              const errorMessage = (e as MigrationError).error?.message ?? (e as Error).message;
              // eslint-disable-next-line max-depth
              if (errorName === 'InvalidSignatureException') {
                const serverDate = getServerTimeFromErrorMsg(errorMessage);
                // eslint-disable-next-line max-depth
                if (serverDate) {
                  setServerTime(serverDate);
                  dispatch(setServerTimeOffset(getServerTimeOffset()));
                }
              }

              const migrationAttempts = getMigrationAttempts(getState());

              const migrationLimitReached = (migrationAttempts > MAX_MIGRATION_ATTEMPTS)
                || (migrationError != null);

              // eslint-disable-next-line max-depth
              if (migrationLimitReached) {
                await licenseService.updateMigrationStatus(
                  authToken!,
                  oe.token!,
                  Status.NonMigratable,
                  await formatErrorForDatabase((e as MigrationError)?.error ?? (e as Error)),
                );
              } else {
                dispatch(setError({
                  messageId: 'dashboard.error.contactSupport',
                  retry: true,
                  error: e as Error,
                  context: 'unlock',
                  dismissable: true,
                }));

                Sentry.withScope((scope) => {
                  scope.setExtra('context', 'migration');
                  Sentry.captureException(
                    e instanceof MigrationError && e.error
                      ? e.error
                      : e,
                  );
                });

                throw e;
              }
            }
          } finally {
            dispatch(clearLoadingOverlay());
          }

          cmpLog('finished migration');
        }

        dispatch(storeAuthToken(authToken));
        dispatch(markActive()); // when the user verifies master password mark as active.
        cmpLog('storing keys');
        dispatch(storeKey(key, key2));
        if (!encDistinctId) {
          distinctId = await licenseService.createDistinctId(authToken!, key);
        } else {
          distinctId = licenseService.getPlainDistinctId(key, encDistinctId);
        }

        dispatch(trackEvent(MP_UNLOCK, {
          timeSpent: getSecondsFromTimestamp(mountTimestamp),
          bypassVault: !!getShouldBypassVault(getState()),
        }));
        dispatch(setLockReason(null));
        dispatch(identify(distinctId));
        dispatch(sendSync());

        if (isUnregisteredMode) {
          dispatch(migrateUnregisteredModeMilestones());
        }

        await dispatch(getUserData());

        dispatch(clearLoadingOverlay());
      }
    } catch (e) {
      cmpLog('failed to unlock');
      cmpLog(e);
      await (dispatch(trackMasterPasswordFailed('provide', e)).catch(() => {}));
      dispatch(lockUser());
      dispatch(clearLoadingOverlay());
      await dispatch(closeVault());
      throw getError(e);
    }
  }
);

export const resetMasterPassword = (): ThunkAction<void> => async (
  _,
  getState,
  { licenseService },
) => {
  const { oe } = getState();
  try {
    await licenseService.requestAccountReset(oe.token, config.environment);
  } catch (error) {
    Sentry.captureException(error);
  }
};
