const ErrorDescriptions = {
  'error.unknown': {
    key: 'error.unknown',
    status: '000',
  },
  no_email: {
    key: 'error.login.noEmail',
    status: '000',
  },
  invalid_email: {
    key: 'error.login.invalidEmail',
    status: '000',
  },
  no_password: {
    key: 'error.login.noPassword',
    status: '000',
  },
  invalid_password: {
    key: 'error.register.passwordIsNotStrongEnough',
    status: '000',
  },
  invalid_credentials: {
    key: 'error.login.invalidCredentials',
    status: '400',
  },
  disabled_account: {
    key: 'error.login.disabledAccount',
    status: '400',
  },
  required_captcha: {
    key: 'error.login.requiredCaptcha',
    status: '400',
  },
  'Captcha required': {
    key: 'error.login.requiredCaptcha',
    status: '400',
  },
  unsupported_grant_type: {
    key: 'error.login.unsupportedGrantType',
    status: '400',
  },
  invalid_request: {
    key: 'error.login.invalidRequest',
    status: '400',
  },
  invalid_client: {
    key: 'error.login.invalidClient',
    status: '400',
  },
  registered_token: {
    key: 'error.login.registeredToken',
    status: '401',
  },
  invalid_otp: {
    key: 'error.login.invalidOtp',
    status: '401',
  },
  expired_otp: {
    key: 'error.login.expiredOtp',
    status: '401',
  },
  retries_exceeded: {
    key: 'error.login.retriesExceeded',
    status: '401',
  },
  invalid_custom_kit_token: {
    key: 'error.login.invalidCustomKitToken',
    status: '401',
  },
  'missing user info': {
    key: 'error.login.missingUserInfo',
    status: '000',
  },
  'Bad password': {
    key: 'masterPassword.error.provide.invalid',
    status: '000',
  },
  'Password is not strong enough': {
    key: 'error.register.passwordIsNotStrongEnough',
    status: '400',
  },
  'Invalid email address': {
    key: 'error.register.invalidEmailAdress',
    status: '400',
  },
  'This email already exists with this provider': {
    key: 'error.register.emailExists',
    status: '409',
  },
  'User already exists (state is pending)': {
    key: 'error.register.emailExists',
    status: '409',
  },


  // Needed to be repeated because errors come in different
  // formats from libs (OEService and oe-oauth)
  'code: 22906': {
    key: 'error.register.ustcViolation',
    status: '451',
  },
  'Rejected by trade compliance validation': {
    key: 'error.register.ustcViolation',
    status: '451',
  },


  internal_server_error: {
    key: 'error.serverError',
    status: '500',
  },

  'Gateway Timeout': {
    key: 'error.connectionTimeout',
    status: '504',
  },

  'already logged in': {
    key: 'error.login.alreadyLoggedIn',
    status: '000',
  },

  'Failed to fetch': {
    key: 'error.failedToFetch',
    status: '000',
  },

  'Network request failed': {
    key: 'error.networkRequestFailed',
    status: '000',
  },

  // masterPassword
  'masterPassword.error.empty': {
    key: 'masterPassword.error.empty',
    status: '000',
  },
  'masterPassword.error.invalid': {
    key: 'masterPassword.error.invalid',
    status: '000',
  },

  'masterPassword.error.provide.invalid': {
    key: 'masterPassword.error.provide.invalid',
    status: '000',
  },
  'masterPassword.error.provide.empty': {
    key: 'masterPassword.error.provide.empty',
    status: '000',
  },
  'masterPassword.error.create.invalid': {
    key: 'masterPassword.error.create.invalid',
    status: '000',
  },
  'masterPassword.error.create.empty': {
    key: 'masterPassword.error.create.empty',
    status: '000',
  },
  'masterPassword.error.verify.invalid': {
    key: 'masterPassword.error.verify.invalid',
    status: '000',
  },
  'masterPassword.error.verify.empty': {
    key: 'masterPassword.error.verify.empty',
    status: '000',
  },
  'masterPassword.error.unlock.invalid': {
    key: 'masterPassword.error.unlock.invalid',
    status: '000',
  },
  'masterPassword.error.unlock.empty': {
    key: 'masterPassword.error.unlock.empty',
    status: '000',
  },
  'masterPassword.error.unlock.wrong': {
    key: 'masterPassword.error.unlock.wrong',
    status: '000',
  },
  'masterPassword.error.verify.notIdentical': {
    key: 'masterPassword.error.verify.notIdentical',
    status: '000',
  },
  'masterPassword.error.verify.wrong': {
    key: 'masterPassword.error.verify.wrong',
    status: '000',
  },
  'masterPassword.error.breached': {
    key: 'masterPassword.error.breached',
    status: '000',
  },
  'masterPassword.error.noConsent': {
    key: 'masterPassword.error.noConsent',
    status: '000',
  },
  'error.outdated.extension.version': {
    key: 'error.outdated.extension.version',
    status: '000',
  },
};

export default ErrorDescriptions;
