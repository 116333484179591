import React from 'react';
import { useSelector } from 'react-redux';
import { RouteProps } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { isUserAuthenticated } from '../../user/selectors';

const transitionToRegisterPaths = {
  '/login': true,
  '/register': true,
};

export type Props = RouteProps;

const UnauthenticatedRoute = (props: Props): JSX.Element => {
  const userAuthenticated = useSelector(isUserAuthenticated);

  return (
    <ProtectedRoute
      {...props}
      requirement={state => (
        !userAuthenticated
          || (
            state.dashboard.isUnregisteredMode
            && transitionToRegisterPaths[props.path as keyof typeof transitionToRegisterPaths]
          )
      )}
      redirectPath="/mydata/passwords"
    />
  );
};

export default UnauthenticatedRoute;
