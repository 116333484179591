import { AviraBreach } from './AviraBreachesTypes';

// eslint-disable-next-line import/prefer-default-export
export const SET_AVIRA_BREACHED_WEBSITES = 'SET_AVIRA_BREACHED_WEBSITES';

interface SetAviraBreachedWebsites {
  type: typeof SET_AVIRA_BREACHED_WEBSITES;
  value: {
    [K: string]: {
      domain: string;
      breaches: AviraBreach[];
    };
  };
}

export type AviraBrechesActionTypes = SetAviraBreachedWebsites;
