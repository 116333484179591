import React from 'react';
import PropTypes from 'prop-types';
import styled from 'pwm-components/styled';

import BackIcon from 'pwm-components/icons/Back';

const BackIconWrapper = styled('div')`
  display: inline-block;
  cursor: pointer;
  margin-right: 24px;
  margin-left: -45px;
  margin-top: 10px;
  margin-bottom: 10px;

  > svg {
    display: block;
  }

  @media screen and (max-width: 1350px) {
    margin-left: 0;
  }
`;

const BackButton = ({ onClick }) => (
  <BackIconWrapper onClick={onClick}>
    <BackIcon variant="linkAlternate" />
  </BackIconWrapper>
);

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
