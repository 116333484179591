import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore } from 'react-redux';

import config from '../../config';
import { dismissExtBanner } from '../../preferences';
import { getExtensionBrowserName } from '../../lib/UserAgent';
import { showExtBanner } from '../../user/selectors';
import ExtensionSettings from './ExtensionSettings';
import ExtensionBanner from './ExtensionBanner';

const ExtensionCardWrapper = ({
  page,
}) => {
  const dispatch = useDispatch();
  const state = useStore().getState();
  const { dashboard: { extensionConnected, extensionInstalledVersion } } = state;

  const currentBrowser = getExtensionBrowserName();
  const isBrowserSupported = Object.keys(config.links).includes(currentBrowser);

  if (page === 'settings') {
    return (
      <ExtensionSettings
        extensionInstalled={extensionConnected}
        installedVersion={extensionInstalledVersion}
        browser={currentBrowser}
        storeLinks={config.links}
        isBrowserSupported={isBrowserSupported}
      />
    );
  }

  if (showExtBanner(state)) {
    return (
      <ExtensionBanner
        onCloseClick={() => { dispatch(dismissExtBanner()); }}
        browser={currentBrowser}
        storeLink={`${config.links[currentBrowser]}?subSource=Dashboard%20Banner`}
      />
    );
  }

  return null;
};

ExtensionCardWrapper.propTypes = {
  page: PropTypes.oneOf(['settings', 'accounts', 'notes']).isRequired,
};

export default ExtensionCardWrapper;
