import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from 'react-intl';

const UnlockProBanner = ({ page }) => (
  <div className={`c-unlock-pro c-unlock-pro--${page}`}>
    <h2 className="c-unlock-pro__header u-mb-l">
      <FormattedMessage
        id="dashboard.milestones.getProFree.header"
        defaultMessage="Unlock Pro for FREE"
      />
    </h2>
    <p>
      <FormattedMessage
        id="dashboard.milestones.getProFree.message"
        defaultMessage="Add 5 passwords and try Password Manager Pro for FREE until 31st December 2018"
      />
    </p>
  </div>
);

UnlockProBanner.propTypes = {
  page: string,
};

UnlockProBanner.defaultProps = {
  page: 'auth-pages',
};

export default UnlockProBanner;
