import React, {
  createContext, useReducer, useContext, useEffect, Dispatch, useCallback, useState,
} from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/browser';
import { FileStatusEnum, isStatusResolved } from './helpers/FileStatusHelper';
import FileDatabase from './FileDatabase';
import { getStorageSize } from './FileActions';

export type FileStatusState = Readonly<{ [key in string]?: FileStatusEnum }>;

export type FileThumbnailState = Readonly<{ [key in string]?: Uint8Array }>;

export type FileProgressState = Readonly<{ [key in string]?: number }>;

export type FileStorageState = Readonly<{ bytesUsed: number; bytesLimit: number }>;

type FileStates = {
  fileStatuses?: FileStatusState;
  fileDatabase?: FileDatabase;
  fileStorage?: FileStorageState;
  fileProgresses?: FileProgressState;
  fileThumbnails?: FileThumbnailState;
};

type FileManagerContextProps = {
  fileManager: FileStates;
  fileDispatch: Dispatch<{ type: string; value: any }>;
};

const FileManagerContext = createContext<FileManagerContextProps>({
  fileManager: {},
  fileDispatch: () => {},
});
const { Provider, Consumer } = FileManagerContext;

function updateState(
  state: FileStates, value: FileStates[keyof FileStates], key: keyof FileStates,
): FileStates {
  return {
    ...state,
    [key]: {
      ...state[key],
      ...value,
    },
  };
}

export const FileManagerProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();
  const [fileDatabase] = useState(() => new FileDatabase('fileDb'));

  useEffect(() => {
    // Open the database
    fileDatabase.open().catch((err) => {
      Sentry.withScope((scope) => {
        scope.setExtra('context', 'FileManagerContext');
        scope.setLevel('warning');
        Sentry.captureException(err);
      });
    });
  }, [fileDatabase]);

  const [fileManager, fileDispatch] = useReducer((state, { type, value }) => {
    switch (type) {
      case 'SET_FILE_STATUS':
        if (fileDatabase.isOpen()) {
          Object.entries<FileStatusEnum>(value).forEach(
            ([id, status]) => fileDatabase.setStatus(id, status).catch(() => {}),
          );
        }
        return updateState(state, value, 'fileStatuses');
      case 'SET_FILE_PROGRESS':
        return updateState(state, value, 'fileProgresses');
      case 'SET_FILE_THUMBNAIL':
        return updateState(state, value, 'fileThumbnails');
      case 'SET_FILE_STORAGE':
        return updateState(state, value, 'fileStorage');
      default:
        return state;
    }
  }, {
    fileStatuses: {},
    fileProgresses: {},
    fileThumbnails: {},
    fileStorage: { bytesUsed: 0, bytesLimit: 1 },
  });

  useEffect(() => {
    async function getCachedStatus(): Promise<void> {
      if (!fileDatabase.isOpen()) {
        return;
      }

      const allStatuses = await fileDatabase.getAllStatus();
      if (!allStatuses || allStatuses.length === 0) {
        return;
      }

      const { fileStatuses } = fileManager;
      const cachedStatuses = allStatuses.filter(
        f => f && f.status && !isStatusResolved(f.status) && !fileStatuses.hasOwnProperty(f.id),
      ).reduce(
        (prev, curr) => ({ ...prev, ...curr }),
        {},
      );

      dispatch({ type: 'SET_FILE_STATUS', value: cachedStatuses });
    }

    if (fileDatabase == null) {
      return;
    }

    getCachedStatus();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileDatabase]);

  useEffect(() => {
    async function getStorage(retry = false): Promise<void> {
      const storage = await dispatch(getStorageSize());

      if (storage == null) {
        if (retry) {
          setTimeout(() => getStorage(false), 2000);
        }
        return;
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      fileDispatch({ type: 'SET_FILE_STORAGE', value: storage });
    }

    getStorage(true);
  }, [dispatch, fileDispatch]);

  return (
    <Provider
      value={{ fileManager: { ...fileManager, fileDatabase }, fileDispatch }}
    >
      {children}
    </Provider>
  );
};

export type FileManagerProps = Omit<
FileManagerContextProps, 'fileDispatch'
> & {
  fileManagerActions: {
    setFileStatus: (id: string, status: FileStatusEnum) => void;
    setFileThumbnail: (id: string, data: Uint8Array) => void;
    setFileProgress: (id: string, progress: number) => void;
    setFileStorage: (bytesUsed: number, bytesLimit: number) => void;
  };
};

export const useFileManager = (): FileManagerProps => {
  const {
    fileDispatch, ...props
  } = useContext(FileManagerContext);

  const setFileStatus = useCallback((id: string, status: FileStatusEnum) => {
    fileDispatch({
      type: 'SET_FILE_STATUS',
      value: { [id]: status },
    });
  }, [fileDispatch]);

  const setFileThumbnail = useCallback((id: string, data: Uint8Array) => {
    fileDispatch({
      type: 'SET_FILE_THUMBNAIL',
      value: { [id]: data },
    });
  }, [fileDispatch]);

  const setFileProgress = useCallback((id: string, progress: number) => {
    fileDispatch({
      type: 'SET_FILE_PROGRESS',
      value: { [id]: progress },
    });
  }, [fileDispatch]);

  const setFileStorage = useCallback((bytesUsed, bytesLimit) => {
    fileDispatch({
      type: 'SET_FILE_STORAGE',
      value: { bytesUsed, bytesLimit },
    });
  }, [fileDispatch]);

  return {
    ...props,
    fileManagerActions: {
      setFileStatus,
      setFileThumbnail,
      setFileProgress,
      setFileStorage,
    },
  };
};

export function withFileManager<T extends FileManagerProps, P = Omit<T, 'fileManager'>>(
  Component: React.ComponentType<T>,
): React.FC<P> {
  const ComponentWithFileManager: React.FC<P> = (props: P) => {
    const fileManagerProps = useFileManager();

    return (
      <Consumer>
        {(): React.ReactNode => (
          <Component
            {...props as any}
            {...fileManagerProps}
          />
        )}
      </Consumer>
    );
  };

  ComponentWithFileManager.displayName = `withFileManager(${Component.displayName || Component.name})`;

  return ComponentWithFileManager;
}

export default FileManagerContext;
