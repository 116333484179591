import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage, FormattedDate, FormattedPlural, injectIntl,
} from 'react-intl';
import { withRouter } from 'react-router-dom';
import DeleteIcon from 'pwm-components/icons/Delete';
import RestoreIcon from 'pwm-components/icons/Restore';
import Paragraph from 'pwm-components/components/Paragraph';
import ConfirmationDialog from 'pwm-components/components/ConfirmationDialog';
import Box from 'pwm-components/components/Box';
import ModifiedAt from 'pwm-components/components/ModifiedAt';

import List from '../../componentLib/List';
import Card from '../../componentLib/Card';
import PageTitle from '../../componentLib/PageTitle';
import PageContentWrapper from '../../componentLib/PageContentWrapper';

import confirmDeleteIcon from '../../img/icon-delete-big.png';
import Loading from '../../app/components/Loading';
import intlShape from '../../lib/intlShape';

class ManageBackups extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      componentMounted: false,
      showRestoreDialog: false,
      showDeleteDialog: false,
      backupMetadata: {},
      restoringBackup: false,
      loadingMetadata: false,
    };
    this.setComponentMounted = this.setComponentMounted.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onRestoreButtonClick = this.onRestoreButtonClick.bind(this);
    this.onDeleteBackupClick = this.onDeleteBackupClick.bind(this);
    this.confirmRestore = this.confirmRestore.bind(this);
    this.confirmDelete = this.confirmDelete.bind(this);
  }

  componentDidMount() {
    this.setComponentMounted();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.backupData.metadata) {
      this.setState({ backupMetadata: nextProps.backupData.metadata });
    }
  }

  componentWillUnmount() {
    this.props.clearBackupData();
    this.props.clearBackupDatasets();
  }

  onRestoreButtonClick(e, rowData) {
    e.stopPropagation();
    this.setState({
      showRestoreDialog: true,
      activeBackupDataset: rowData,
      loadingMetadata: true,
    }, () => {
      this.props.setBackupData(rowData.key)
        .then(() => this.setState({ loadingMetadata: false }));
    });
  }

  onDeleteBackupClick(e, rowData) {
    e.stopPropagation();
    this.setState({ activeBackupDataset: rowData, showDeleteDialog: true });
  }

  onBackClick(e) {
    e.preventDefault();
    this.props.history.goBack();
  }

  setComponentMounted() {
    this.setState({ componentMounted: true });
  }

  confirmRestore() {
    this.setState({ restoringBackup: true }, () => {
      this.props.restoreBackup()
        .then(() => this.props.history.push('/mydata/passwords'));
    });
  }

  confirmDelete() {
    this.props.deleteBackupDataset(this.state.activeBackupDataset.key).then(() => {
      this.setState({ showDeleteDialog: false });
    });
  }

  // eslint-disable-next-line complexity
  render() {
    let backupDate = '';
    const columns = [{
      headerLabelId: 'dashboard.settings.backupAndSync.manageBackups.dateCreated',
      key: 'createdAt',
      renderer: cellData => (<ModifiedAt value={cellData} showTime />),
      flexGrow: 7,
      className: 'u-fx-jc-s u-pl-xxxl',
    }, {
      headerLabelId: 'dashboard.settings.backupAndSync.manageBackups.restore',
      key: 'restore',
      renderer: (cellData, rowData) => (
        <a
          className="u-outline-none u-cursor-p a-backup-restore"
          onClick={e => this.onRestoreButtonClick(e, rowData)}
          tabIndex="0"
          role="button"
        >
          <RestoreIcon variant="linkAlternate" />
        </a>
      ),
    }, {
      headerLabelId: 'dashboard.settings.backupAndSync.manageBackups.delete',
      key: 'delete',
      renderer: (cellData, rowData) => (
        <a
          className="u-outline-none u-cursor-p a-backup-delete"
          onClick={e => this.onDeleteBackupClick(e, rowData)}
          tabIndex="0"
          role="button"
        >
          <DeleteIcon variant="linkAlternate" />
        </a>
      ),
      className: 'u-pr-xxxl',
    }];

    if (this.state.activeBackupDataset) {
      backupDate = (<FormattedDate value={this.state.activeBackupDataset.createdAt} />);
    }

    const { reverted, restored, notModified } = this.state.backupMetadata;

    return (
      <PageContentWrapper height="100%">

        {/* HEADER */}
        <PageTitle
          onBackClick={this.onBackClick}
        >
          <FormattedMessage id="dashboard.settings.backupAndSync.manageBackups" />
        </PageTitle>

        {/* CARD */}
        <div
          className="u-fx-1 u-minheight-0"
          ref={(container) => { this.containerRef = container; }}
        >
          <Card
            noPadding
          >
            <div
              className="o-section o-section--xl u-pb-xxxl"
              ref={(input) => { this.descriptionRef = input; }}
            >
              <FormattedMessage id="dashboard.settings.backupAndSync.manageBackups.message" />
            </div>
            {
              this.state.componentMounted
                && this.props.backupDatasets.length > 0 ? (
                  <List
                    data={
                      this.props.backupDatasets
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                    }
                    columns={columns}
                    maxHeight={this.containerRef.clientHeight - this.descriptionRef.clientHeight}
                  />
                ) : (
                  <div className="u-d-b u-txt-center u-mb-xxxl u-color-silver">
                    <FormattedMessage
                      id="dashboard.settings.backupAndSync.noBackupsCreated"
                      defaultMessage="There were no backups created."
                    />
                  </div>
                )
            }
          </Card>
        </div>
        <ConfirmationDialog
          show={this.state.showRestoreDialog}
          confirmLabel={this.props.intl.formatMessage({ id: 'dashboard.settings.backupAndSync.manageBackups.restoreBackup' })}
          cancelLabel={this.props.intl.formatMessage({ id: 'dashboard.account.details.cancel' })}
          onConfirm={this.confirmRestore}
          onCancel={() => this.setState({ activeBackupDataset: null, showRestoreDialog: false })}
          loading={this.state.restoringBackup}
        >
          <Box mb="l">
            <Paragraph textAlign="center">
              <FormattedMessage
                id="dashboard.settings.backupAndSync.manageBackups.confirmRestore"
                values={{ date: backupDate }}
                defaultMessage="Are you sure you want to restore this backup? That will mean you will lose all the changes made to your data after {date}:"
              />
            </Paragraph>
            {
              this.state.loadingMetadata ? (
                <Box mt="l" mb="s">
                  <Loading />
                </Box>
              ) : (
                <Box mt="l" mb="s">
                  {!!reverted && (
                    <Paragraph textAlign="center" mb="s" variant="disabled">
                      <FormattedPlural
                        value={reverted}
                        one={this.props.intl.formatMessage({
                          id: 'dashboard.settings.backupAndSync.manageBackups.accountChangedReverted',
                        }, {
                          amount: reverted,
                        })}
                        other={this.props.intl.formatMessage({
                          id: 'dashboard.settings.backupAndSync.manageBackups.accountsChangedReverted',
                        }, {
                          amount: reverted,
                        })}
                      />
                    </Paragraph>
                  )}
                  {!!restored && (
                    <Paragraph textAlign="center" mb="s" variant="disabled">
                      <FormattedPlural
                        value={restored}
                        one={this.props.intl.formatMessage({
                          id: 'dashboard.settings.backupAndSync.manageBackups.accountDeletedRestored',
                        }, {
                          amount: restored,
                        })}
                        other={this.props.intl.formatMessage({
                          id: 'dashboard.settings.backupAndSync.manageBackups.accountsDeletedRestored',
                        }, {
                          amount: restored,
                        })}
                      />
                    </Paragraph>
                  )}
                  {!!notModified && (
                    <Paragraph textAlign="center" mb="s" variant="disabled">
                      <FormattedPlural
                        value={notModified}
                        one={this.props.intl.formatMessage({
                          id: 'dashboard.settings.backupAndSync.manageBackups.accountAddedNotModified',
                        }, {
                          amount: notModified,
                        })}
                        other={this.props.intl.formatMessage({
                          id: 'dashboard.settings.backupAndSync.manageBackups.accountsAddedNotModified',
                        }, {
                          amount: notModified,
                        })}
                      />
                    </Paragraph>
                  )}
                  {!reverted && !restored && !notModified && (
                    <Paragraph textAlign="center" mb="s" variant="disabled">
                      <FormattedMessage
                        id="dashboard.settings.backupAndSync.manageBackups.noChangeInAccounts"
                        defaultMessage="There were no changes to the accounts"
                      />
                    </Paragraph>
                  )}
                </Box>
              )
            }
          </Box>
        </ConfirmationDialog>
        <ConfirmationDialog
          confirmButtonType="alert"
          show={this.state.showDeleteDialog}
          confirmLabel={this.props.intl.formatMessage({ id: 'dashboard.settings.backupAndSync.manageBackups.delete' })}
          cancelLabel={this.props.intl.formatMessage({ id: 'dashboard.settings.backupAndSync.manageBackups.cancelDelete' })}
          onConfirm={this.confirmDelete}
          onCancel={() => this.setState({ activeBackupDataset: null, showDeleteDialog: false })}
        >
          <Paragraph mb="l" textAlign="center">
            <img src={confirmDeleteIcon} alt="Delete Password" />
          </Paragraph>
          <Paragraph textAlign="center">
            <FormattedMessage id="dashboard.settings.backupAndSync.manageBackups.confirmDelete" />
          </Paragraph>
        </ConfirmationDialog>
      </PageContentWrapper>
    );
  }
}

ManageBackups.propTypes = {
  backupData: PropTypes.shape().isRequired,
  backupDatasets: PropTypes.array.isRequired,
  intl: intlShape.isRequired,
  clearBackupDatasets: PropTypes.func.isRequired,
  deleteBackupDataset: PropTypes.func.isRequired,
  setBackupData: PropTypes.func.isRequired,
  clearBackupData: PropTypes.func.isRequired,
  restoreBackup: PropTypes.func.isRequired,
  history: PropTypes.object,
};

ManageBackups.defaultProps = {
  history: {
    push: () => { },
    goBack: () => { },
  },
};

export default withRouter(injectIntl(ManageBackups));
