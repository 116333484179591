import React from 'react';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import { FileStatusEnum, getIconFromStatus, getMessageFromStatus } from '../helpers/FileStatusHelper';

export type Props = {
  status?: FileStatusEnum;
};

const ErrorViewer: React.FC<Props> = ({ status = FileStatusEnum.Downloading }) => (
  <Box display="flex" alignItems="center" flexDirection="column">
    {getIconFromStatus({ status, defaultShowWrapper: true })}
    <Paragraph mt="l" variant="light" textAlign="center">
      {getMessageFromStatus(status)}
    </Paragraph>
  </Box>
);

export default ErrorViewer;
