import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withTooltip } from 'pwm-components/components/Tooltip';

const Icon = ({
  icon, label, size, active, horizontal,
  labelClassName, className, disabled, color,
}) => (
  <span
    className={
      `c-icon c-icon--${size} c-icon--${color} ${
        active ? 'c-icon--active' : ''
      } ${className} c-icon--${icon} a-icon-${icon} u-fx ${
        horizontal ? 'u-fx-d-r' : 'u-fx-d-c'
      } ${disabled ? 'c-icon--disabled' : ''} u-fx-ai-c`
    }
  >
    <svg className="c-icon__icon">
      <use xlinkHref={`#svg-icon-${icon}`} />
    </svg>
    {
      label
      && (
      <span className={`c-icon__label ${horizontal ? 'u-ml-xs' : ''} ${labelClassName}`}>
        <FormattedMessage
          id={label}
        />
      </span>
      )
    }
  </span>
);

Icon.propTypes = {
  active: PropTypes.bool,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string,
  size: PropTypes.oneOf([
    'normal',
    'small',
    'large',
  ]),
  horizontal: PropTypes.bool,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

Icon.defaultProps = {
  active: false,
  label: null,
  size: 'normal',
  horizontal: false,
  labelClassName: '',
  className: '',
  disabled: false,
  color: 'gray',
};

export const IconWithTooltip = withTooltip(Icon);

export default Icon;
