import React from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import iconClick from '../../../img/icon-click.png';
import iconClick2x from '../../../img/icon-click@2x.png';

import openWebDashboard from '../../../img/animations/openWebDashboard.gif';
import openWebDashboard2x from '../../../img/animations/openWebDashboard2x.gif';

import TwoColumnView from '../TwoColumnView';
import GifContainer from '../GifContainer';

export default () => (
  <TwoColumnView
    leftSection={(
      <>
        <Box mb="m">
          <Paragraph>
            <FormattedHTMLMessage
              id="dashboard.getStarted.openWebDashboard.message"

              defaultMessage="There are <strong style='color: black;'>two ways</strong> to open the web dashboard"
            />
          </Paragraph>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
          mb="m"
        >
          <Box mr="l">
            <img
              src={iconClick}
              srcSet={`${iconClick} 1x, ${iconClick2x} 2x`}
              alt="Click icon"
            />
          </Box>
          <Box>
            <Paragraph
              color="black"
            >
              <FormattedMessage
                id="dashboard.getStarted.openWebDashboard.fromExtension"
                defaultMessage="From Extension"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="dashboard.getStarted.openWebDashboard.fromExtension.instruction"

                defaultMessage='Open extension, click on the "User Menu" and then on "Open Web Dashboard" (see picture).'
              />
            </Paragraph>
          </Box>
        </Box>
        <Box
          display="flex"
          alignItems="flex-start"
        >
          <Box mr="l">
            <img
              src={iconClick}
              srcSet={`${iconClick} 1x, ${iconClick2x} 2x`}
              alt="Click icon"
            />
          </Box>
          <Box>
            <Paragraph
              color="black"
            >
              <FormattedMessage
                id="dashboard.getStarted.openWebDashboard.fromBrowser"
                defaultMessage="From any browser"
              />
            </Paragraph>
            <Paragraph>
              <FormattedMessage
                id="dashboard.getStarted.openWebDashboard.fromBrowser.instruction"
                defaultMessage='Enter "https://passwords.avira.com" and log in with your Avira Account and unlock with your Master Password.'
              />
            </Paragraph>
          </Box>
        </Box>
      </>
    )}

    rightSection={(
      <GifContainer
        gifSrc={openWebDashboard}
        gifSrc2x={openWebDashboard2x}
        gifAlt="Open web dashboard animation"
      />
    )}
  />
);
