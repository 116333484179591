import * as Sentry from '@sentry/browser';
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { State } from '@avira-pwm/redux/credit-cards';

import { generateID } from '../../../lib/AccountHelper';
import AddEditPage from './AddEditPage';
import { getIcon } from '../../WalletHelpers';
import { triggerCreditCardCreation } from '../../WalletActions';

// Minimum fields needed for add edit card to work.
type DefaultNewCard = {
  id: string;
  iconElement: JSX.Element;
}

const getDefaultCreditCard = (): DefaultNewCard => ({
  id: '',
  iconElement: getIcon('Placeholder'),
});

const AddCreditCard: React.FC<void> = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [id, setId] = useState('');
  const isAddedToState = useSelector((state: { creditCards: State }) => !!state.creditCards[id]);
  const files = useSelector((state: State) => state.files);

  useEffect(() => {
    if (isAddedToState) {
      history.replace(`/mydata/wallet/${id}/edit`);
    }
  }, [history, id, isAddedToState]);


  const onSubmit = useCallback(
    async (generatedID, creditCardProps) => {
      const cardID = generatedID || generateID();
      setId(cardID);
      try {
        await dispatch(triggerCreditCardCreation(cardID, creditCardProps));
      } catch (error) {
        Sentry.captureException(error);
      }
    }, [dispatch, setId],
  );

  return (
    // TODO: better typing for AddEditCreditCard
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    <AddEditPage
      addMode
      data={getDefaultCreditCard()}
      files={files}
      onSaveItemClick={onSubmit}
    />
  );
};

export default AddCreditCard;
