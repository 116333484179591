import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { func, string } from 'prop-types';
import debug from '../../debug';

const log = debug.extend('ProtectedRoute');

export type Props = RouteProps & {
  requirement: (state: any) => boolean;
  redirectPath: string;
}

const ProtectedRoute = ({
  requirement,
  redirectPath,
  ...otherProps
}: Props): JSX.Element => {
  const requirementMet = useSelector(requirement);

  if (requirementMet) {
    log('Route requirement met');
    return <Route {...otherProps} />;
  }

  log(`Route requirement not met - redirecting to "${redirectPath}"`);
  return (
    <Redirect
      to={{
        pathname: redirectPath,
      }}
    />
  );
};

ProtectedRoute.propTypes = {
  requirement: func.isRequired,
  redirectPath: string.isRequired,
};

export default ProtectedRoute;
