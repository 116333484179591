import React from 'react';
import { RouteProps } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthorizedRoute from './UnauthorizedRoute';

type Props = RouteProps;

const VerifiedEmailRoute = ({
  path,
  ...otherProps
}: Props): JSX.Element => (
  <AuthenticatedRoute
    path={path}
    render={() => (
      <UnauthorizedRoute
        path={path}
        render={() => (
          <ProtectedRoute
            path={path}
            requirement={({ oe }) => !!oe.gdprConfirm || (!oe.gdprConfirm && !oe.gdprConsent)}
            redirectPath="verify-email"
            {...otherProps}
          />
        )}
      />
    )}
  />
);

export default VerifiedEmailRoute;
