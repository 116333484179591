import React from 'react';
import { FormattedMessage } from 'react-intl';

import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';
import Media from 'pwm-components/objects/Media';
import Text from 'pwm-components/components/Text';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

const WhyUnique = () => (
  <>
    <Box mb="m">
      <Paragraph mb="xs">
        <FormattedMessage
          id="dashboard.getStarted.whyUnique.step"
          defaultMessage="When using the same password for different accounts, you make it easy for hackers to get access to all your accounts."
        />
      </Paragraph>
      <Paragraph mb="xs">
        <FormattedMessage
          id="dashboard.getStarted.whyUnique.step1"
          defaultMessage="Since 2013 more than 14 billion data sets have been stolen (including user names and passwords). By knowing a single user name/password combination, hackers can get access to your other accounts."
        />
      </Paragraph>
      <Media
        image={(
          <img
            src={hint}
            srcSet={`${hint} 1x, ${hint2x} 2x`}
            alt="hint"
          />
        )}
        body={(
          <Text>
            <FormattedMessage
              id="dashboard.getStarted.whyUnique.step2"
              defaultMessage="Use a unique password for each account."
            />
          </Text>
        )}
      />
    </Box>
  </>
);

export default WhyUnique;
