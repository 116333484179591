import React from 'react';
import PropTypes from 'prop-types';

import MasterPassword from './MasterPasswordContainer';
import UserShape from '../UserShape';

const USAGE_AS = 'create';

class CreateMasterPassword extends React.Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.onMasterPasswordInitiated = this.onMasterPasswordInitiated.bind(this);
  }

  UNSAFE_componentWillMount() {
    const { onComponentShown, setTrackingTime } = this.props;
    onComponentShown(USAGE_AS);
    setTrackingTime();
  }

  onSubmit(password) {
    const { onFormSubmit } = this.props;
    return onFormSubmit(password);
  }

  onMasterPasswordInitiated() {
    const { trackMasterPasswordInitiated } = this.props;
    return trackMasterPasswordInitiated(USAGE_AS);
  }

  render() {
    const {
      user, oe, onChangeClick, mountTimestamp, onDropOut, isUnregisteredMode,
    } = this.props;
    return (
      <MasterPassword
        usageAs={USAGE_AS}
        onSuccessRedirect="/mydata/passwords"
        user={user}
        oe={oe}
        onSubmit={this.onSubmit}
        onLogout={onChangeClick}
        onBackButtonClick={onChangeClick}
        mountTimestamp={mountTimestamp}
        onDropOut={onDropOut}
        passwordStrength
        onMasterPasswordInitiated={this.onMasterPasswordInitiated}
        isUnregisteredMode={isUnregisteredMode}
      />
    );
  }
}

CreateMasterPassword.propTypes = {
  mountTimestamp: PropTypes.number.isRequired,
  oe: PropTypes.shape({
    email: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }).isRequired,
  onChangeClick: PropTypes.func.isRequired,
  onComponentShown: PropTypes.func.isRequired,
  onDropOut: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  setTrackingTime: PropTypes.func.isRequired,
  trackMasterPasswordInitiated: PropTypes.func.isRequired,
  user: UserShape.isRequired,
  isUnregisteredMode: PropTypes.bool.isRequired,
};

export default CreateMasterPassword;
