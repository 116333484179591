import { Entity as NoteEntity } from '@avira-pwm/redux/notes';

import { UPDATE_NOTE_DETAILS } from './NoteActionTypes';

type State = {
  note: NoteEntity;
};

function getInitialState(): State {
  return {
    // TODO: `{}` should be removed and we should use null in this case
    note: {} as unknown as NoteEntity,
  };
}

type UpdateNoteDetails = {
  type: typeof UPDATE_NOTE_DETAILS;
  value: State;
}

export type Actions = UpdateNoteDetails;

export default function noteDetailsReducer(state = getInitialState(), action: Actions): State {
  switch (action.type) {
    case UPDATE_NOTE_DETAILS:
      return {
        ...state,
        ...action.value,
      };
    default:
      return state;
  }
}
