import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CreateMasterPassword from './CreateMasterPassword';
import {
  createMasterPassword,
  trackMasterPasswordShown,
  trackMasterPasswordDropout,
  trackMasterPasswordInitiated,
  trackMasterPasswordFailed,
} from '../MasterPasswordActions';

const mapStateToProps = ({ user, oe, dashboard: { isUnregisteredMode } }) => (
  { user, oe, isUnregisteredMode }
);

const mapDispatchToProps = dispatch => bindActionCreators({
  onFormSubmit: createMasterPassword,
  onComponentShown: trackMasterPasswordShown,
  onDropOut: trackMasterPasswordDropout,
  trackMasterPasswordInitiated,
  trackMasterPasswordFailed,
}, dispatch);

const CreateMasterPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateMasterPassword);

export default CreateMasterPasswordContainer;
