import { AsyncStorage, Json } from '@avira-pwm/storage';
import { SpotlightApi } from '../spotlight-api-types';

export default class SpotlightStorageWrapper<S extends SpotlightApi.Storage> extends AsyncStorage {
  public static isApiAvailable(api: SpotlightApi.Storage): boolean {
    return (
      typeof api.StorageGet !== 'undefined'
      && typeof api.StorageSet !== 'undefined'
      && typeof api.StorageRemove !== 'undefined'
      && typeof api.StorageClear !== 'undefined'
      && typeof api.StorageKeys !== 'undefined'
    );
  }

  private api: S;

  constructor(api: S) {
    super();
    this.api = api;
  }

  public get<T extends string, O extends { [K in T]?: Json }>(keys: T[]): Promise<O> {
    return new Promise((resolve, reject) => {
      this.api.StorageGet(JSON.stringify(keys), (err, data) => {
        if (err != null) {
          reject(err);
        } else {
          resolve(JSON.parse(data));
        }
      });
    });
  }

  public set(obj: { [key: string]: Json }): Promise<void> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.api.StorageSet(JSON.stringify(obj), (err, data) => {
        if (err != null) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  public remove(keys: string[]): Promise<void> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.api.StorageRemove(JSON.stringify(keys), (err, data) => {
        if (err != null) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  public clear(): Promise<void> {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.api.StorageClear((err, data) => {
        if (err != null) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  public keys(): Promise<string[]> {
    return new Promise((resolve, reject) => {
      this.api.StorageKeys((err, data) => {
        if (err != null) {
          reject(err);
        } else {
          resolve(JSON.parse(data));
        }
      });
    });
  }
}
