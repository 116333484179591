import React from 'react';
import { string } from 'prop-types';
import styled from 'pwm-components/styled';
import Paragraph from 'pwm-components/components/Paragraph';
import Box from 'pwm-components/components/Box';

import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import List from '../List';

import chromeImg from '../../../img/icon-disable-chrome-passwords.png';
import chromeImg2x from '../../../img/icon-disable-chrome-passwords@2x.png';

import firefoxImg from '../../../img/icon-disable-firefox-passwords.png';
import firefoxImg2x from '../../../img/icon-disable-firefox-passwords@2x.png';

import hint from '../../../img/icon-hint.png';
import hint2x from '../../../img/icon-hint@2x.png';

import CopyValue from '../../../componentLib/CopyValue';

import TwoColumnView from '../TwoColumnView';


const OrderedList = styled('ol')`
  margin-bottom: ${({ theme }) => theme.space.m};
  margin-left: 14px;
  padding-left: 4px;
  list-style: decimal;

  & > li {
    padding-left: ${({ theme }) => theme.space.xs};
    margin-bottom: ${({ theme }) => theme.space.s}
  }
`;

const StyledCopyValue = styled(CopyValue)`
  position: absolute;
  bottom: 0;
  display: inline-block;
  margin-left: -14px;
`;

const ChromeOperaStep2 = () => (
  <li>
    <Paragraph>
      <FormattedHTMLMessage
        id="dashboard.getStarted.howToDisable.chromeOpera.step2"
        defaultMessage="Switch off the two options:"
      />
    </Paragraph>
    <Box
      color="black"
      mt="xs"
    >
      <List>
        <li>
          <FormattedMessage
            id="dashboard.getStarted.howToDisable.chromeOpera.step2.subStep1"
            defaultMessage="Offer to save passwords"
          />
        </li>
        <li>
          <FormattedMessage
            id="dashboard.getStarted.howToDisable.chromeOpera.step2.subStep2"
            defaultMessage="Auto Sign-in"
          />
        </li>
      </List>
    </Box>
  </li>
);

const instructionsMap = {
  Opera: {
    instructions: () => (
      <>
        <OrderedList>
          <li>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.howToDisable.opera.step1"
                defaultMessage="<strong>Open the Opera Settings</strong>, choose <strong>Advanced</strong>, select <strong>Privacy & security</strong>, locate the section <strong>Passwords and forms</strong> and select <strong>Passwords</strong>"
              />
            </Paragraph>
            <Box display="flex" alignItems="center" mt="xs">
              <img
                src={hint}
                srcSet={`${hint} 1x, ${hint2x} 2x`}
                alt="hint"
              />
              <Box ml="m" position="relative">
                <FormattedHTMLMessage
                  id="dashboard.getStarted.howToDisable.opera.step1.hint"
                  defaultMessage="You can also copy and paste this text into the browser address field: <strong>opera://settings/passwords</strong>"
                />
                <StyledCopyValue
                  value="opera://settings/passwords"
                />
              </Box>
            </Box>
          </li>
          <ChromeOperaStep2 />
        </OrderedList>
      </>
    ),
    img: chromeImg,
    img2x: chromeImg2x,
  },

  'Google Chrome': {
    instructions: () => (
      <>
        <OrderedList>
          <li>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.howToDisable.chrome.step1"

                defaultMessage="<strong>Open the Chrome Settings</strong> locate the section <strong>Autofill</strong> and select <strong>Passwords</strong>"
              />
            </Paragraph>
            <Box display="flex" alignItems="center" mt="xs">
              <img
                src={hint}
                srcSet={`${hint} 1x, ${hint2x} 2x`}
                alt="hint"
              />
              <Box ml="m" position="relative">
                <FormattedHTMLMessage
                  id="dashboard.getStarted.howToDisable.chrome.step1.hint"
                  defaultMessage="You can also copy and paste this text into the browser address field: <strong>chrome://settings/passwords</strong>"
                />
                <StyledCopyValue
                  value="chrome://settings/passwords"
                />
              </Box>
            </Box>
          </li>
          <ChromeOperaStep2 />
        </OrderedList>
      </>
    ),
    img: chromeImg,
    img2x: chromeImg2x,
  },

  Firefox: {
    instructions: () => (
      <>
        <OrderedList>
          <li>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.howToDisable.firefox.step1"

                defaultMessage="<strong>Open the Firefox Options</strong> and navigate to the section Privacy & Security"
              />
            </Paragraph>
            <Box display="flex" alignItems="center" mt="xs">
              <img
                src={hint}
                srcSet={`${hint} 1x, ${hint2x} 2x`}
                alt="hint"
              />
              <Box ml="m" position="relative">
                <FormattedHTMLMessage
                  id="dashboard.getStarted.howToDisable.firefox.step1.hint"

                  defaultMessage="You can also copy and paste this text into the browser address field: <strong>about:preferences#privacy</strong>"
                />
                <StyledCopyValue
                  value="about:preferences#privacy"
                />
              </Box>
            </Box>
          </li>
          <li>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.howToDisable.firefox.step2"
                defaultMessage="Go to <strong>Logins and Passwords</strong>"
              />
            </Paragraph>
          </li>
          <li>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.howToDisable.firefox.step3"

                defaultMessage="Switch off the option <strong>Ask to save logins and passwords for websites</strong>"
              />
            </Paragraph>
          </li>
        </OrderedList>
      </>
    ),
    img: firefoxImg,
    img2x: firefoxImg2x,
  },

  'Microsoft Edge': {
    instructions: () => (
      <>
        <OrderedList>
          <li>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.howToDisable.edge.step1"

                defaultMessage="<strong>Open the Edge Settings</strong> and navigate to the section <strong>Passwords</strong>"
              />
            </Paragraph>
            <Box display="flex" alignItems="center" mt="xs">
              <img
                src={hint}
                srcSet={`${hint} 1x, ${hint2x} 2x`}
                alt="hint"
              />
              <Box ml="m" position="relative">
                <FormattedHTMLMessage
                  id="dashboard.getStarted.howToDisable.edge.step1.hint"
                  defaultMessage="You can also copy and paste this text into the browser address field: <strong>edge://settings/passwords</strong>"
                />
                <StyledCopyValue
                  value="edge://settings/passwords"
                />
              </Box>
            </Box>
          </li>
          <ChromeOperaStep2 />
        </OrderedList>
      </>
    ),
    img: chromeImg,
    img2x: chromeImg2x,
  },
};

const HowToDisableSavingPasswords = ({ browser }) => {
  const browserInstructions = instructionsMap[browser];

  if (!browserInstructions) {
    return null;
  }

  const { instructions: Instructions, img, img2x } = instructionsMap[browser];

  return (
    <TwoColumnView
      leftSection={<Instructions />}
      rightSection={(
        <img
          src={img}
          srcSet={`${img} 1x, ${img2x} 2x`}
          alt="Disable saving passwords illustration"
        />
      )}
    />
  );
};

HowToDisableSavingPasswords.propTypes = {
  browser: string.isRequired,
};

export default HowToDisableSavingPasswords;
