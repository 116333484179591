import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import TextButton from 'pwm-components/components/TextButton';
import Text from 'pwm-components/components/Text';
import NoteIcon from 'pwm-components/components/NoteIcon';
import ModifiedAt from 'pwm-components/components/ModifiedAt';

import DeleteIcon from 'pwm-components/icons/Delete';
import EditIcon from 'pwm-components/icons/Edit';
import FavoriteIcon from 'pwm-components/icons/Favorite';
import FavoriteOutlineIcon from 'pwm-components/icons/FavoriteOutline';

import DataListItem from '../../componentLib/DataListItem';
import {
  DataListCell,
  DataListCellOnHover,
  TextButtonOnHover,
  DataListActions,
} from '../../componentLib/DataListComponents';
import { sortOptions, defaultSortBy } from '../../lib/NoteHelper';
import { isAttached } from '../../files/helpers/FileDataHelper';

class NoteListItem extends React.Component {
  constructor(props) {
    super(props);

    this.onCardClick = this.onCardClick.bind(this);
    this.onEditClick = this.onEditClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onFavoriteClick = this.onFavoriteClick.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    return this.props.data !== nextProps.data
      || this.props.sortBy !== nextProps.sortBy;
  }

  onCardClick() {
    const {
      history, match, onInteraction, onEditClick,
    } = this.props;
    history.push(`${match.url}/${this.props.data.id}/edit`);
    onEditClick(this.props.data.id);
    onInteraction(this.props.index);
  }

  onFavoriteClick(e) {
    e.stopPropagation();
    this.props.updateIgnoreSort();
    this.props.updateIgnoreFilter(this.props.data.id);
    this.props.onFavoriteClick(this.props.data);
  }

  onEditClick(e) {
    e.stopPropagation();
    this.props.onEditClick(this.props.data.id);
    this.props.onInteraction(this.props.index);
  }

  onDeleteClick(e, id) {
    e.stopPropagation();
    this.props.onDeleteClick(id);
  }

  render() {
    const {
      data,
      sortBy,
      match,
      index,
    } = this.props;
    const { sortKey, isDate } = sortOptions[sortBy];
    const sortValue = typeof sortKey === 'function' ? sortKey(data) : data[sortKey];
    return (
      <DataListItem
        onClick={this.onCardClick}
      >
        <DataListCell>
          <NoteIcon color={data.color} attached={isAttached(data.files)} />
        </DataListCell>
        <DataListCell
          id={`a-title-${index}`}
          style={{
            flexBasis: '15em',
            flexShrink: 2,
          }}
          shrink
          truncate
        >
          <Text>
            <strong>{data.title}</strong>
          </Text>
        </DataListCell>

        <DataListCellOnHover spacer separator />

        <DataListCell
          style={{
            flexBasis: '25em',
            flexGrow: 3,
            flexShrink: 2,
          }}
          shrink
          truncate
        >
          <Text>
            {data.notes ? data.notes.trim().split('\n')[0] : ''}
          </Text>
        </DataListCell>

        {
          isDate && <DataListCellOnHover spacer separator />
        }

        <DataListCell
          style={{
            flexBasis: '8em',
            flexShrink: 2,
            textAlign: 'center',
          }}
          hideOnShrink
          shrink
          truncate
        >
          {
            isDate && <ModifiedAt value={sortValue} showDay />
          }
        </DataListCell>

        <DataListCellOnHover spacer separator />

        <DataListActions fixed>
          <Link
            id={`a-edit-data-item-button-${index}`}
            to={`${match.url}/${data.id}/edit`}
            onClick={this.onEditClick}
          >
            <EditIcon variant="link" />
          </Link>
        </DataListActions>

        <DataListCell spacer />

        <DataListActions fixed>
          <TextButtonOnHover
            onClick={e => this.onDeleteClick(e, data.id)}
            id={`a-delete-data-item-button-${index}`}
            tabIndex="-1"
          >
            <DeleteIcon variant="link" />
          </TextButtonOnHover>
        </DataListActions>

        <DataListCell spacer />

        <DataListCellOnHover
          style={(data.favorite ? { opacity: 1 } : {})}
        >
          <TextButton
            onClick={this.onFavoriteClick}
            tabIndex="-1"
          >
            {
              data.favorite
                ? <FavoriteIcon variant="active" /> : <FavoriteOutlineIcon variant="link" />
            }
          </TextButton>
        </DataListCellOnHover>
      </DataListItem>
    );
  }
}

NoteListItem.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    color: PropTypes.string,
    title: PropTypes.string,
    notes: PropTypes.string,
    favorite: PropTypes.bool,
    files: PropTypes.shape({}),
  }),
  index: PropTypes.number.isRequired,
  sortBy: PropTypes.string,
  onFavoriteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onInteraction: PropTypes.func,
  updateIgnoreFilter: PropTypes.func.isRequired,
  updateIgnoreSort: PropTypes.func.isRequired,
  history: PropTypes.object,
  match: PropTypes.object,
};

NoteListItem.defaultProps = {
  data: {
    color: '',
    title: '',
    notes: '',
    favorite: false,
  },
  sortBy: defaultSortBy,
  onInteraction: () => { },
  history: {
    push: () => { },
  },
  match: {
    url: '',
  },
};

export default withRouter(NoteListItem);
