// import { browserHistory } from 'react-router';
import * as Sentry from '@sentry/browser';

import { lockUser } from '../user/UserActions';
import { getRelevantUserId } from '../user/selectors';
import { UPDATE_LAST_ACTIVITY } from '../preferences/PreferencesActionTypes';
import * as SpotlightApi from '../lib/SpotlightAPI';
import config from '../config';

const getActiveTime = () => async (dispatch, getState, { dashboardMessenger }) => {
  let activeTime = 0;
  if (config.spotlight) {
    const userId = getRelevantUserId(getState());
    if (userId) {
      activeTime = await new Promise((resolve) => {
        SpotlightApi.getLastActivity(userId, (err, data) => {
          if (err) {
            resolve(0);
          } else {
            resolve(parseInt(data, 10));
          }
        });
      });
    }
  } else {
    activeTime = await new Promise((resolve) => {
      dashboardMessenger.send(
        'dashboard:extension:checkExtensionActivity',
        null,
        (err, response) => resolve((response && response.activeTime) || 0),
      );
    });
  }
  return activeTime;
};

const shouldAutolock = (autolockDuration, lastActivity, now) => (
  async (dispatch, getState, { dashboardMessenger }) => {
    let shouldLock = false;
    const dashboardMessengerConnected = dashboardMessenger.isConnected();
    const getActivityFromSpotlight = SpotlightApi.getLastActivity.isAvailable();

    if (dashboardMessengerConnected || getActivityFromSpotlight) {
      const activeTime = await dispatch(getActiveTime());

      if (lastActivity > activeTime || (now - activeTime) >= autolockDuration) {
        dispatch(lockUser('autolock', 'inactive with extension', null));
        shouldLock = true;
      }
    } else {
      dispatch(lockUser('autolock', 'inactive without extension', null));
      shouldLock = true;
    }

    return shouldLock;
  }
);

export const markActive = () => (dispatch, getState, { dashboardMessenger }) => {
  const now = Date.now();
  const userId = getRelevantUserId(getState());

  dispatch({ type: UPDATE_LAST_ACTIVITY, value: now });

  if (SpotlightApi.setLastActivity.isAvailable() && config.spotlight && userId) {
    SpotlightApi.setLastActivity(userId.toString(), now);
  }

  dashboardMessenger.send('dashboard:extension:webappActive');
};

export const checkAutoLock = () => async (dispatch, getState) => {
  try {
    const {
      preferences,
      userPreferences,
    } = getState();

    const autolock = userPreferences.autolock ? userPreferences.autolock.value : 0;

    if (autolock) {
      const now = Date.now();
      const lastActivity = parseFloat(preferences.lastActivity);
      const autolockDuration = (autolock * 60 * 60 * 1000);
      if ((now - lastActivity) >= autolockDuration) {
        return dispatch(shouldAutolock(autolockDuration, lastActivity, now));
      }
    }
    return false;
  } catch (e) {
    Sentry.captureException(e);
    return false;
  }
};
