export default {
  mainFormFields: 1,
  secondaryFormFields: 2,
  primaryActionFields: 3,
  secondaryActionFields: 4,
  optionalActionFields: 5,
  lastFocus: 6,
  defaultFocus: 0,
  noFocus: -1,
};
