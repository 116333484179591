import { SECURITY_STATUS_PREFERENCES_SCROLLPOSITION } from './SecurityStatusActionTypes';

export type State = Readonly<{
  scrollPosition: number;
}>;

function getInitialState(): State {
  return {
    scrollPosition: 0,
  };
}

interface SetScrollPosition {
  type: typeof SECURITY_STATUS_PREFERENCES_SCROLLPOSITION;
  value: number;
}

export type SecurityStatusPreferenceActions = SetScrollPosition;

export default function securityStatusPreferencesReducer(
  state: State = getInitialState(),
  action: SecurityStatusPreferenceActions,
): State {
  switch (action.type) {
    case SECURITY_STATUS_PREFERENCES_SCROLLPOSITION:
      return {
        ...state,
        scrollPosition: action.value,
      };
    default:
      return state;
  }
}
