import React, { useEffect } from 'react';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import List1 from '../../../img/list_item_1.png';
import List2 from '../../../img/list_item_2.png';
import List3 from '../../../img/list_item_3.png';
import { StepComponentProps } from './StepProps';
import mixpanel from '../../../tracking/mixpanel';

import Card from '../../../componentLib/CardWrapper';

const StepDone: React.FC<StepComponentProps> = ({
  stepName,
}) => {
  useEffect(() => {
    mixpanel.track('PwmActivationShown', { stepName });
  }, [stepName]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="485px"
      flex="0 1 485px"
      height="90%"
      m="xxxl"
      ml="64px"
    >
      <Text size="h1" variant="light">
        <FormattedMessage id="onboarding.rightPanel.fourth.title" defaultMessage="Great! You're all set" />
      </Text>
      <Text size="large" variant="light">
        <FormattedMessage id="onboarding.rightPanel.fourth.subtitle" defaultMessage="Now add your first password:" />
      </Text>
      <Box
        display="flex"
        mt="m"
      >
        <Box
          flex="0 0 auto"
          mr="m"
        >
          <img src={List1} alt="" width="34px" />
        </Box>
        <Box
          flex="1 1 auto"
        >
          <Box pt="6px">
            <Text size="normal">
              <FormattedHTMLMessage id="onboarding.rightPanel.fourth.step1" defaultMessage="<strong>Visit</strong> your favorite websites" />
            </Text>
            <Card variant="secondary" mt="m">
              <Text size="normal">
                <FormattedHTMLMessage id="onboarding.rightPanel.fourth.step1.hint" defaultMessage="<strong>Hint:</strong> If you're logged in, log out (just once)" />
              </Text>
            </Card>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        mt="m"
      >
        <Box
          flex="0 0 auto"
          mr="m"
        >
          <img src={List2} alt="" width="34px" />
        </Box>
        <Box
          flex="1 1 auto"
        >
          <Text size="normal">
            <FormattedHTMLMessage id="onboarding.rightPanel.fourth.step2" defaultMessage="Use your email address and password <strong>to log in to</strong> your favorite websites" />
          </Text>
        </Box>
      </Box>
      <Box
        display="flex"
        mt="m"
      >
        <Box
          flex="0 0 auto"
          mr="m"
        >
          <img src={List3} alt="" width="34px" />
        </Box>
        <Box
          flex="1 1 auto"
        >
          <Text size="normal">
            <FormattedHTMLMessage id="onboarding.rightPanel.fourth.step3" defaultMessage="Click <strong>Save</strong> when Avira offers you to save your credentials" />
          </Text>
        </Box>
      </Box>
      <Box
        mt="m"
        style={{ border: '1px solid #959496' }}
        p="m"
      >
        <Text size="normal">
          <FormattedMessage id="onboarding.rightPanel.fourth.finish" defaultMessage="Next time you want to log in, let the autofill enter your credentials for you" />
        </Text>
      </Box>
    </Box>
  );
};

export default StepDone;
