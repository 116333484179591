import React, { useEffect } from 'react';
import {
  bool, func, string, array, number, object,
} from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

import GetStartedDetailsCard from './GetStartedDetailsCard';
import achievement from '../../img/achievement.png';
import achievement2x from '../../img/achievement@2x.png';
import notAchieved from '../../img/notAchieved.png';
import notAchieved2x from '../../img/notAchieved@2x.png';
import cannotAchieve from '../../img/cannotAchieve.png';
import cannotAchieve2x from '../../img/cannotAchieve@2x.png';

import ProgressIndicator from './ProgressIndicator';

import { TrackingActions, MixpanelEvents } from '../../tracking';
import PageTitle from '../../componentLib/PageTitle';

const {
  MP_GETSTARTED_VIEW_TILE,
} = MixpanelEvents;

const { trackEvent } = TrackingActions;

const DetailsPageContainer = styled(Box)`
  min-width: 850px;
`;

const getCompletionStatusIcon = (completed, cantAchieve) => {
  let src1x = null;
  let src2x = null;

  if (cantAchieve) {
    src1x = cannotAchieve;
    src2x = cannotAchieve2x;
  } else {
    src1x = completed ? achievement : notAchieved;
    src2x = completed ? achievement2x : notAchieved2x;
  }

  return <img src={src1x} srcSet={`${src1x} 1x, ${src2x} 2x`} alt="Achievement status icon" />;
};

const GetStartedDetailsPage = ({
  maxPoints,
  points,
  tileId,
  steps,
  history: { push },
  onStepCompleted,
  language,
  isUnregisteredMode,
  browser,
  extensionConnected,
  email,
  trackGetStartedViewTile,
}) => {
  useEffect(() => {
    trackGetStartedViewTile(MP_GETSTARTED_VIEW_TILE, {
      tileName: tileId,
      pointsEarned: points,
      maxPoints,
    });
  }, [tileId, points, maxPoints, trackGetStartedViewTile]);

  return (
    <DetailsPageContainer
      py="xxxl"
    >
      {/* Header */}

      <PageTitle
        onBackClick={() => push('/get-started')}
        renderAfter={() => (
          <ProgressIndicator
            points={points}
            maxPoints={maxPoints}
          />
        )}
      >
        <FormattedMessage
          id={`dashboard.getStarted.${tileId}`}
          values={{
            browser,
          }}
        />
      </PageTitle>

      {/* map steps to components */}
      {steps
        .map((step, index) => {
          const onCompleted = () => !step.completed
            && onStepCompleted(step.stepId, tileId);
          return (
            <GetStartedDetailsCard
              openOnMount={Boolean(!index)}
              key={step.stepId}
              icon={getCompletionStatusIcon(step.completed, step.cannotAchieve)}
              title={<FormattedMessage id={`dashboard.getStarted.${step.stepId}`} />}
              onClick={step.useCustomCompletion || step.cannotAchieve ? null : onCompleted}
            >
              {step.component({
                onCompleted,
                id: step.stepId,
                language,
                isUnregisteredMode,
                extensionConnected,
                email,
                completed: step.completed,
                browser,
              })}
            </GetStartedDetailsCard>
          );
        })}
    </DetailsPageContainer>
  );
};

GetStartedDetailsPage.propTypes = {
  maxPoints: number.isRequired,
  points: number.isRequired,
  tileId: string.isRequired,
  isUnregisteredMode: bool.isRequired,
  language: string.isRequired,
  steps: array.isRequired,
  history: object.isRequired,
  onStepCompleted: func.isRequired,
  browser: string.isRequired,
  extensionConnected: bool.isRequired,
  email: string,
  trackGetStartedViewTile: func.isRequired,
};

GetStartedDetailsPage.defaultProps = {
  email: '',
};

const mapDispatchToProps = dispatch => bindActionCreators({
  trackGetStartedViewTile: trackEvent,
}, dispatch);

export default withRouter(
  connect(null, mapDispatchToProps)(GetStartedDetailsPage),
);
