import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isDataUsageDisallowed } from '../../selectors';
import {
  setActiveTab,
  trackSearchInteraction,
  trackSearchUsed,
  trackTagsFilterClick,
  trackListDisplay,
} from '../MyDataActions';
import DataListContainer from './DataListContainer';

const mapStateToProps = state => ({
  disallowDataUsage: isDataUsageDisallowed(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  afterSearch: trackSearchUsed,
  onMount: trackListDisplay,
  onTagChange: trackTagsFilterClick,
  onTabClick: setActiveTab,
  trackInteraction: trackSearchInteraction,
}, dispatch);

const MyDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DataListContainer);

export default MyDataContainer;
