import React, { createContext } from 'react';
import DashboardMessenger from '@avira-pwm/messenger/DashboardMessenger';

const Context = createContext<DashboardMessenger | undefined>(undefined);

export const Provider: React.FC<{ value: DashboardMessenger }> = ({ children, value }) => (
  <Context.Provider value={value}>
    {children}
  </Context.Provider>
);

export const { Consumer } = Context;
export default Context;

export function withMessenger<T extends { messenger: DashboardMessenger }, P = Omit<T, 'messenger'>>(
  Component: React.ComponentType<T>,
): React.FC<P> {
  const ComponentWithMessenger: React.FC<P> = (props: P) => (
    <Consumer>
      {(messenger): React.ReactNode => (
        // `any` here because HOC still have weird behaviour in TS
        // check: https://github.com/microsoft/TypeScript/issues/28938
        <Component {...props as any} messenger={messenger} />
      )}
    </Consumer>
  );

  ComponentWithMessenger.displayName = `withMessenger(${Component.displayName || Component.name})`;

  return ComponentWithMessenger;
}
