import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loading from 'pwm-components/components/Loading';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

import * as SpotlightAPI from '../../lib/SpotlightAPI';
import { checkUserExists } from '../../user';
import { setError } from '../../dashboard/DashboardActions';
import SpotlightOnboardingExistingUser from './SpotlightOnboardingExistingUser';
import SpotlightOnboardingNewUser from './SpotlightOnboardingNewUser';
import SpotlightPwmIcon from './SpotlightPwmIcon';
import { State as OEState } from '../../oe/OEReducer';
import {
  trackSpotlightWelcomeNext,
  trackSpotlightWelcomeBack,
} from '../../spotlight/SpotlightActions';

type StateProps = {
  oe: OEState;
}

enum UserStates {
  Unregistered,
  RegisteredWithNoPwmAccount,
  RegistererdWithPwmAccount
}

const SpotlightOnboardingContainer: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const oe = useSelector<StateProps, OEState>(state => state.oe);
  const [isConnected, setIsConnected] = useState(false);
  const [supportedBrowsersAvailable, setSupportedBrowsersAvailable] = useState(true);
  const [userState, setUserState] = useState<UserStates>(UserStates.Unregistered);
  const [loading, setLoading] = useState(true);

  // Check Whether the user exists or not
  useEffect(() => {
    const setUserStatus = async (): Promise<void> => {
      if (oe.id) {
        try {
          const userExists = await dispatch(checkUserExists(oe.token)) as any;

          if (userExists) {
            setUserState(UserStates.RegistererdWithPwmAccount);
          } else {
            setUserState(UserStates.RegisteredWithNoPwmAccount);
          }
        } catch (e) {
          dispatch(setError({ messageId: e.key, context: 'UserExistsRoute', error: e }));
        }
      }
      setLoading(false);
    };

    setUserStatus();
  }, [oe, dispatch]);

  // set onboarding status
  useEffect(() => {
    if (oe.id) {
      SpotlightAPI.getSpotlightUserOnboardingStatus(oe.id.toString(), (_err, data) => {
        if (data == null) return;

        setIsConnected(data.connected);
        setSupportedBrowsersAvailable(data.supportedBrowsersAvailable);
      });
    }
  }, [oe]);

  // Skips onboarding if user is connected
  useEffect(() => {
    if (!isConnected) {
      SpotlightAPI.onUserOnboarded((err) => {
        if (!err) {
          history.push('/login');
        }
      });
    }
    return () => SpotlightAPI.onUserOnboarded(() => { });
  }, [isConnected, history]);

  function onNextClick(): void {
    dispatch(trackSpotlightWelcomeNext());

    if (userState === UserStates.Unregistered) {
      history.push('/verify-avira-account');
    } else {
      if (isConnected || !supportedBrowsersAvailable) {
        history.push('/enter-master-password');
      } else {
        SpotlightAPI.showBrowserExtensions(oe.id);
      }

      if (oe.id) {
        SpotlightAPI.setSpotlightUserActivated(oe.id, true);
      }
    }
  }

  function onBackClick(): void {
    dispatch(trackSpotlightWelcomeBack());
    SpotlightAPI.closeHtml('Return from Welcome screen');
  }

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Box display="flex" height="100%" alignItems="center" justifyContent="center">
      <Paragraph as="div" textAlign="center" id="a-master-password-redirect">
        <Box position="relative" mb="xxl" mx="a" width="150px">
          <SpotlightPwmIcon type="key" />
        </Box>
        {
          userState === UserStates.RegistererdWithPwmAccount
            ? (
              <SpotlightOnboardingExistingUser
                onNextClick={onNextClick}
                onBackClick={onBackClick}
              />
            ) : (
              <SpotlightOnboardingNewUser
                onBackClick={onBackClick}
                onNextClick={onNextClick}
              />
            )
        }
      </Paragraph>
    </Box>
  );
};

export default SpotlightOnboardingContainer;
