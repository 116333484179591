import React from 'react';
import { bool, string } from 'prop-types';
import Box from 'pwm-components/components/Box';
import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';

import syncDevices from '../../../img/icon-sync-devices.png';
import syncDevices2x from '../../../img/icon-sync-devices@2x.png';

import TwoColumnView from '../TwoColumnView';

const SynchronizeYourData = ({ isUnregisteredMode, email }) => (
  <TwoColumnView
    leftSection={
      isUnregisteredMode
        ? (
          <Box>
            <Paragraph>
              <FormattedMessage
                id="dashboard.getStarted.synchronizeData.unregistered.message1"

                defaultMessage="Register your Avira Password manager to synchronize your data across all your devices. Registration is easy and free."
              />
            </Paragraph>
            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.synchronizeData.unregistered.moreDetails"

                defaultMessage="<a href='/why-register?dashboardContext=get-started' target='_blank'>More details</a>"
              />
            </Paragraph>
            <Box mt="xl">
              <a
                href="/register?dashboardContext=get-started"
                target="_blank"
                rel="noreferrer noopener"
              >
                <Button>
                  <FormattedMessage
                    id="dashboard.getStarted.synchronizeData.unregistered.registerForFree"
                    defaultMessage="Register for free"
                  />
                </Button>
              </a>
            </Box>
          </Box>
        )
        : (
          <Box>
            <Paragraph mb="l">
              <FormattedHTMLMessage
                id="dashboard.getStarted.synchronizeData.message1"

                defaultMessage="You are registered as <strong>{email}</strong> and synchronization is available for you."
                values={{
                  email,
                }}
              />
            </Paragraph>

            <Paragraph>
              <FormattedHTMLMessage
                id="dashboard.getStarted.synchronizeData.message2"

                defaultMessage="Now <strong>install</strong> Avira Password Manager on your mobile device and <strong>log in</strong> with your user <strong>{email}.</strong> Your data will be automatically <strong>synchronized.</strong>"
                values={{
                  email,
                }}
              />
            </Paragraph>
          </Box>
        )
    }

    rightSection={(
      <img
        src={syncDevices}
        srcSet={`${syncDevices} 1x, ${syncDevices2x} 2x`}
        alt="Synchronization between devices illustration"
      />
    )}
  />
);

SynchronizeYourData.propTypes = {
  isUnregisteredMode: bool.isRequired,
  email: string,
};

SynchronizeYourData.defaultProps = {
  email: '',
};

export default SynchronizeYourData;
