import { HIBP_FORBIDDEN_CHARS } from './constants';

const hibpForbiddenCharsRegex = new RegExp(
  `(${HIBP_FORBIDDEN_CHARS.map(c => JSON.stringify(c).slice(1, -1)).join('|')})`,
  'g',
);

// eslint-disable-next-line import/prefer-default-export
export function hasUsernameForbiddenHIBPChars(username: string): boolean {
  return !!username.match(hibpForbiddenCharsRegex);
}
