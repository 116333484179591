import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as SpotlightAPI from '../../lib/SpotlightAPI';
import { setSpotlightAuthData } from '../AuthenticationActions';
import { setSpotlightAutolock } from '../../preferences/PreferencesActions';
import { spotlightUserChanged } from '../../oe/OEActions';

const SpotlightSync: React.FC<{}> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (SpotlightAPI.registerEventListener.isAvailable()) {
      SpotlightAPI.registerEventListener('SetAuthData', (data) => {
        if (data != null) {
          dispatch(setSpotlightAuthData(JSON.parse(data)));
        }
      });

      SpotlightAPI.registerEventListener('SetAutolock', (data) => {
        if (data != null) {
          dispatch(setSpotlightAutolock(JSON.parse(data)));
        }
      });
    }

    if (SpotlightAPI.onLanguageChanged.isAvailable()) {
      SpotlightAPI.onLanguageChanged((err, data) => {
        if (!err) {
          history.push(`/change-language/${data}`);
        }
      });
    }

    if (SpotlightAPI.onColorSchemeChanged.isAvailable()) {
      SpotlightAPI.onColorSchemeChanged((err, data) => {
        if (!err) {
          history.push(`/change-theme/${data}`);
        }
      });
    }

    if (SpotlightAPI.onUserChanged.isAvailable()) {
      SpotlightAPI.onUserChanged((err) => {
        if (!err) {
          history.push('/');
          dispatch(spotlightUserChanged());
        }
      });
    }
  }, [dispatch, history]);

  return (
    <>
      {children}
    </>
  );
};

export default SpotlightSync;
