import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

const propTypes = {
  aucType: PropTypes.string.isRequired,
  aucResult: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof propTypes>;

const TooltipAucDetection: React.FC<Props> = ({ aucType, aucResult }) => (
  <Box>
    <Paragraph>
      <strong>
        <FormattedMessage
          id="dashboard.securityStatus.tooltip.header.auc"
          values={{ aucType }}
        />
      </strong>
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage id="dashboard.securityStatus.unsafeWebsite.auc.securityHint" />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage
        id={aucResult}
      />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedMessage id="dashboard.securityStatus.unsafeWebsite.auc.cta" />
    </Paragraph>
  </Box>
);

export default TooltipAucDetection;
