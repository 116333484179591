import { connect } from 'react-redux';

import ExtensionSync, { StateProps, DispatchProps, OwnProps } from './ExtensionSync';
import { withMessenger } from '../../componentLib/MessengerContext';
import { setExtAuthData } from '../AuthenticationActions';
import { setExtensionConnected, setExtensionDisconnected } from '../../dashboard/DashboardActions';
import { setLocalSalt } from '../../tracking/TrackingActions';
import { setExtensionAutolock } from '../../preferences/PreferencesActions';
import { RootState } from '../../app/store';

const mapStateToProps = ({
  auth,
  oe,
  tracking,
  user,
  nlokData,
}: RootState): StateProps => ({
  auth,
  oe,
  tracking,
  user,
  nlokData,
});

const mapDispatchToProps: DispatchProps = {
  onDataReceived: setExtAuthData as any,
  onConnect: setExtensionConnected as any,
  onDisconnect: setExtensionDisconnected,
  setLocalSalt,
  setExtensionAutolock,
};

export default withMessenger(
  connect<StateProps, DispatchProps, OwnProps, RootState>(mapStateToProps, mapDispatchToProps)(
    ExtensionSync,
  ),
);
