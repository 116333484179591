import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  setIgnoreWarning, trackActionButtonClick,
} from '../SecurityStatusActions';
import { autoLoginAccount, trackCredentialsUsed } from '../../accounts/AccountActions';
import SecurityStatusListItem from './SecurityStatusListItem';
import { getHasAccessToPro } from '../../user/selectors';

const mapStateToProps = state => ({
  extensionInstalled: state.dashboard.extensionConnected,
  scrolling: state.dashboard.scrolling,
  hasAccessToPro: getHasAccessToPro(state),
});

const mapDispatchToProps = dispatch => bindActionCreators({
  onAccountClick: autoLoginAccount,
  onIgnore: setIgnoreWarning,
  onActionButtonClick: trackActionButtonClick,
  onRevealPasswordClick: trackCredentialsUsed,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecurityStatusListItem);
