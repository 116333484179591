import React from 'react';
import Input from 'pwm-components/components/Input';
import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';

import Button from 'pwm-components/components/Button';
import Paragraph from 'pwm-components/components/Paragraph';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import TextButton from 'pwm-components/components/TextButton';
import StyledInputFieldWrapper from 'pwm-components/components/Input/StyledInputFieldWrapper';
import Auth from './Auth';
import countryList from '../../lib/countryList.json';

const HeaderSpacer = styled(Box)`
  height: 80px;

  @media screen and (max-width: 480px) {
    height: 40px;
  }
`;

const Label = styled.label`
  position: relative;

  &::after {
    content: '';
    width: 8px;
    height: 8px;
    position: absolute;
    border-right: solid 2px ${({ theme: { colors } }) => colors.darkcerulean};
    border-bottom: solid 2px ${({ theme: { colors } }) => colors.darkcerulean};
    transform: rotate(45deg);
    right: 18px;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;

const StyledSelect = styled(StyledInputFieldWrapper).attrs(() => ({ as: 'select' }))`
  padding: ${({ theme: { space } }) => (`${space.xs} ${space.xxs} ${space.xs} ${space.s}`)};
  appearance: none;
`;

class UserIdentity extends Auth {
  constructor(props) {
    super(props);

    this.state = {
      ...this.state,
      firstName: '',
      lastName: '',
      country: '',
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(e) {
    e.preventDefault();

    const { onFormSubmit } = this.props;
    this.submitAttempt(onFormSubmit);
  }

  render() {
    return (
      <form
        id="form-section"
        className="u-mb-xs"
        onSubmit={this.onSubmit}
      >
        <HeaderSpacer />

        <Box mb="s">
          <Input
            placeholder="First Name"
            value={this.state.firstName}
            onChange={e => this.setState({ firstName: e.target.value })}
          />
        </Box>

        <Box mb="s">
          <Input
            placeholder="Last Name"
            value={this.state.lastName}
            onChange={e => this.setState({ lastName: e.target.value })}
          />
        </Box>

        <Box mb="s">
          <Label>
            <StyledSelect
              onChange={(e) => { this.setState({ country: e.target.value }); }}
            >
              <option>Country</option>
              {
                countryList.map(c => (
                  <option value={c.toUpperCase()}>
                    {this.props.intl.formatMessage({ id: `country.${c}` })}
                  </option>
                ))
              }
            </StyledSelect>
          </Label>
        </Box>

        <Paragraph variant="dark" size="mini" textAlign="left" mb="l">
          <FormattedHTMLMessage
            id="auth.legalStatement"
          />
        </Paragraph>

        <Box mb="s">
          <Button
            disabled={!!this.state.error
              || !this.state.firstName
              || !this.state.lastName
              || !this.state.country}
            loading={this.state.loading}
            variant="login"
            fullWidth
          >
            {this.props.mainCtaLabel}
          </Button>
        </Box>
        <Box textAlign="center">
          <TextButton variant="link" onClick={() => this.props.onBackClick?.()}>
            <FormattedMessage
              id="auth.back"
              defaultMessage="Back"
            />
          </TextButton>
        </Box>
      </form>
    );
  }
}

export default injectIntl(UserIdentity);
