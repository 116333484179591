import { DeepReadonly } from 'utility-types';
import {
  SET_DICE_ROLL,
} from './ABTestTypes';

export type State = DeepReadonly<{
  diceRoll: number | null;
}>;

export function defaultState(): State {
  return {
    diceRoll: Math.random(),
  };
}

interface ActionValue {
  type: typeof SET_DICE_ROLL;
  diceRoll: number;
}

export default function abTestReducer(state = defaultState(), action: ActionValue): State {
  switch (action.type) {
    case SET_DICE_ROLL:
      return {
        ...state,
        diceRoll: action.diceRoll,
      };
    default:
      return state;
  }
}
