import React, { useEffect } from 'react';

import { Subdomain } from '@avira-pwm/sync/ModelSpecifics/Account';

import styled from 'pwm-components/styled';

import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';

import LoginIcon from 'pwm-components/icons/Login';

import { getSubdomain } from '@avira-pwm/helpers/url';

type Props = {
  showDropdownMenu: boolean;
  domain: string | undefined;
  associatedUrlsCollection: Array<Subdomain>;
  closeLinkedUrls: () => void;
  onLoginClick: (
    e: React.MouseEvent,
    linkedUrlEntry?: Subdomain,
    loginUrl?: string
  ) => void;
  onAssociatedUrlNavigateClicked: (tracking: object) => void;
};

type AssociatedUrlsContainerProps = {
  collapsed: boolean;
  maxHeight: number;
};

const AssociatedUrlsContainer = styled('div')<AssociatedUrlsContainerProps>`
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 100;
  overflow: hidden;
  border-radius: ${({ theme: { space } }) => space.xxs};
  box-shadow: 0 20px 20px 0 rgba(0,0,0,0.2);
  max-height: ${({
    collapsed,
    maxHeight,
  }) => (collapsed ? 0 : `${maxHeight}px`)};
  transition: max-height ${({
    theme: { transition },
    collapsed,
  }) => (collapsed ? 0 : transition.duration.long)};
`;

const AssociatedUrlsList = styled('ul')`
  overflow: auto;
  border-radius: ${({ theme: { space } }) => space.xxs};
  border: 1px solid ${({ theme: { colors } }) => colors.gray};
  background: ${({ theme: { colors } }) => colors.white};
`;

const AssociatedUrlsItemContainer = styled('li')`
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  outline: none;
  text-align: left;

  &:hover {
    background-color: ${({ theme: { colors } }) => colors.alto};
  }

  color: ${({ theme: { colors } }) => colors.martinique};

  &: not(: last-child) {
    border-bottom: solid 1px ${({ theme: { colors } }) => colors.alabaster};
  }
`;

const AssociatedUrlsItemNavigation = styled(Box)`
  flex: 1 1 auto;
  align-items: center;
  padding: ${({ theme: { space } }) => `${space.m} ${space.m} ${space.m} ${space.s}`};
  cursor: pointer;
  &:hover {
    color: ${({ theme: { colors } }) => colors.royalblue};
    ${LoginIcon} {
      color: ${({ theme: { colors } }) => colors.royalblue};
      fill: ${({ theme: { colors } }) => colors.royalblue};
    }
  }
`;

const maxHeight = 200;
const TRACKING_CONTEXT = 'list';

const AssociatedUrlsListView: React.FC<Props> = ({
  showDropdownMenu,
  domain,
  associatedUrlsCollection,
  closeLinkedUrls,
  onLoginClick,
  onAssociatedUrlNavigateClicked,
}) => {
  const onNavigate = (
    e: React.MouseEvent,
    linkedUrlEntry: Subdomain,
  ): void => {
    closeLinkedUrls();
    onAssociatedUrlNavigateClicked({
      domain,
      subdomain: linkedUrlEntry.subdomain,
      context: TRACKING_CONTEXT,
    });
    onLoginClick(
      e,
      linkedUrlEntry,
      linkedUrlEntry.loginUrl || linkedUrlEntry.subdomain,
    );
  };

  useEffect(() => {
    const hideSuggestionList = (): void => {
      closeLinkedUrls();
    };

    window.addEventListener('click', hideSuggestionList);
    return () => window.removeEventListener('click', hideSuggestionList);
  }, [closeLinkedUrls]);

  return (
    <AssociatedUrlsContainer
      collapsed={!showDropdownMenu}
      maxHeight={maxHeight}
    >
      <AssociatedUrlsList className="a-subdomains-list" style={{ maxHeight }}>
        {associatedUrlsCollection.map((associatedUrlsEntry, index) => (
          <AssociatedUrlsItemContainer className={`a-subdomain-component-${index}`} key={associatedUrlsEntry.subdomain}>
            <AssociatedUrlsItemNavigation
              display="flex"
              alignItems="center"
              minWidth="0px"
              onClick={(e) => {
                e.stopPropagation();
                onNavigate(e, associatedUrlsEntry);
              }}
            >
              <Box className="a-subdomain" minWidth="0px" flex="1 1 auto">
                <Text
                  style={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    display: 'block',
                  }}
                >
                  {getSubdomain(associatedUrlsEntry.subdomain)}
                </Text>
              </Box>
              <LoginIcon className="a-redirect-subdomain-action" variant="link" />
            </AssociatedUrlsItemNavigation>
          </AssociatedUrlsItemContainer>
        ))}
      </AssociatedUrlsList>
    </AssociatedUrlsContainer>
  );
};

export default AssociatedUrlsListView;
