import * as Sentry from '@sentry/browser';
import envConfig from '../config';
import debug from '../debug';

const log = debug.extend('Sentry');

const TAGS = {
  platform: envConfig.platform,
};

export default (): void => {
  const {
    localBuild,
    version,
    buildNumber,
    environment,
    sentryServer,
    hostConfig: {
      dashboardHost,
    },
  } = envConfig;

  if (localBuild) {
    return;
  }

  const sentryRelease = `pwm-dashboard-${environment}-${version}.${buildNumber}`;

  log('init sentry - version: %s; env: %s; release: %s %O', version, environment, sentryRelease, TAGS);

  Sentry.init({
    ignoreErrors: [
      'font-src',
      'script-src-elem',
      'img-src',
      'script-src-attr',
      'connect-src',
    ],
    dsn: sentryServer,
    release: sentryRelease,
    environment,
    dist: `${version}.${buildNumber}`,
    allowUrls: [new RegExp(`${dashboardHost}.*`)],
    beforeSend(event, hint) {
      const error = hint?.originalException;

      if (Array.isArray(error)) {
        return null;
      }

      return event;
    },
  });

  Sentry.configureScope(scope => scope.setTags(TAGS));
};
