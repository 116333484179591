import React from 'react';
import { FormattedMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import TextButton from 'pwm-components/components/TextButton';

type StepProps = {
  id: string;
  values: { website: string };
};

type GenerateSteps = (a: string, b: string) => StepProps[];

const generateSteps: GenerateSteps = (website: string, idPrefix: string) => (
  ['One', 'Two', 'Three', 'Four', 'Five']
    .map((step: string) => ({
      id: `${idPrefix}${step}`,
      values: { website },
    }))
);

type Props = {
  showDetails: boolean;
  extensionInstalled: boolean;
  website: string;
  onShowDetailsClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const TooltipChangePasswordSteps: React.FC<Props> = ({
  extensionInstalled,
  onShowDetailsClick,
  showDetails,
  website,
}) => {
  const stepsIdPrefix = `dashboard.securityStatus.changePassword.howToProceed${extensionInstalled ? '' : '.withoutExtension'}.step`;
  const steps = generateSteps(website, stepsIdPrefix);

  return (
    <Box
      textAlign="left"
      style={{ whiteSpace: 'normal' }}
    >
      <Paragraph mb="xs">
        <strong>
          <FormattedMessage id="dashboard.securityStatus.changePassword.header" />
        </strong>
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          id="dashboard.securityStatus.changePassword.message"
          values={{ website }}
        />
      </Paragraph>
      <Box textAlign="right">
        {
          showDetails
            ? null
            : (
              <TextButton
                size="small"
                onClick={onShowDetailsClick}
                variant='link'
              >
                <FormattedMessage
                  id="dashboard.securityStatus.changePassword.showDetails"
                  defaultMessage="Details"
                />
              </TextButton>
            )
        }
      </Box>
      {showDetails && (
        <Box mt="xs">
          <Paragraph>
            <strong>
              <FormattedMessage
                id="dashboard.securityStatus.changePassword.howToProceed"
                defaultMessage="How to proceed:"
              />
            </strong>
          </Paragraph>
          <ul style={{ listStyleType: 'decimal', marginLeft: '12px' }}>
            {
              steps.map((step: StepProps) => (
                <li key={step.id}>
                  <FormattedMessage
                    id={step.id}
                    values={step.values}
                  />
                </li>
              ))
            }
          </ul>
        </Box>
      )}
    </Box>
  );
};

export default TooltipChangePasswordSteps;
