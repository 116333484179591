import PropTypes from 'prop-types';

const {
  string,
  func,
  object,
  shape,
  any,
} = PropTypes;

const funcReq = func.isRequired;

export const intlConfigPropTypes = {
  locale: string,
  defaultLocale: string,
  timeZone: string,
  formats: object,
  messages: object,
  textComponent: any,

  defaultFormats: object,

  onError: func,
};

export const intlFormatPropTypes = {
  formatDate: funcReq,
  formatDateToParts: funcReq,
  formatDisplayName: funcReq,
  formatHTMLMessage: funcReq,
  formatList: funcReq,
  formatMessage: funcReq,
  formatNumber: funcReq,
  formatNumberToParts: funcReq,
  formatPlural: funcReq,
  formatRelativeTime: funcReq,
  formatTime: funcReq,
  formatTimeToParts: funcReq,
};

const intlShape = shape({
  ...intlConfigPropTypes,
  ...intlFormatPropTypes,
  formatters: shape({
    getDateTimeFormat: funcReq,
    getDisplayNames: funcReq,
    getListFormat: funcReq,
    getMessageFormat: funcReq,
    getNumberFormat: funcReq,
    getPluralRules: funcReq,
    getRelativeTimeFormat: funcReq,
  }),
});

export default intlShape;
