import * as Sentry from '@sentry/browser';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { VaultError } from '@avira-pwm/sync/adapters/NDSAdapter/helpers/NLOK';
import { FormattedMessage } from 'react-intl';
import { closeVault, tryOpenVault } from '../NLOKActions';
import Loading from '../../app/components/Loading';
import nlokHelper from '../helper';
import { logoutUser } from '../../authentication/AuthenticationActions';
import { lockUser } from '../../user/UserActions';
import { isNonMigratable as isNonMigratableSelector, shouldConnectToVault as shouldConnectToVaultSelector, shouldMigrateVault } from '../selectors';
import { isTransitioningFromUnregisteredMode as isTransitioningFromUnregisteredModeSelector } from '../../user/selectors';
import { isUnregisteredMode as isUnregisteredModeSelector } from '../../dashboard/selectors';
import debug from '../../debug';

const log = debug.extend('VaultWrapper');

const VaultWrapper: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const [loaded, setLoaded] = useState(false);
  const isUnregisteredMode = useSelector(isUnregisteredModeSelector);
  const shouldMigrate = useSelector(shouldMigrateVault);
  const isNonMigratable = useSelector(isNonMigratableSelector);
  const shouldConnectToVault = useSelector(shouldConnectToVaultSelector);

  const isTransitioningFromUnregisteredMode = useSelector(
    isTransitioningFromUnregisteredModeSelector,
  );

  useEffect(() => {
    const migrationPending = !isTransitioningFromUnregisteredMode
      && shouldMigrate
      && !isNonMigratable;

    const init = async (): Promise<void> => {
      log(
        'is unreg %s; is from unreg %s; should migrate %s; is non migratable %s',
        isUnregisteredMode,
        isTransitioningFromUnregisteredMode,
        shouldMigrate,
        isNonMigratable,
      );

      if (isUnregisteredMode) {
        setLoaded(true);
        return;
      }

      if (migrationPending) {
        dispatch(lockUser('vault', 'migration pending', null));
        return;
      }

      try {
        if (shouldConnectToVault) {
          await dispatch(tryOpenVault());
        }
      } catch (e) {
        if (e instanceof VaultError) {
          if (!nlokHelper.shouldLogout(e.state)) {
            dispatch(lockUser('vault', 'vault error', e.message));
          } else {
            dispatch(logoutUser('vault', 'vault error', e.message));
            Sentry.withScope((scope) => {
              scope.setExtra('context', 'vault');
              Sentry.captureException(e);
            });
          }
        } else {
          dispatch(lockUser('vault', 'unknown error', (e as Error).message));
          Sentry.withScope((scope) => {
            scope.setExtra('context', 'vault');
            Sentry.captureException(e);
          });
        }

        return;
      }

      setLoaded(true);
    };

    init();

    return () => {
      if (!isUnregisteredMode && !migrationPending && shouldConnectToVault) {
        (async () => {
          try {
            await dispatch(closeVault());
          } catch { /**/ }
        })();
      }
    };
  }, [
    dispatch,
    shouldMigrate,
    shouldConnectToVault,
    isNonMigratable,
    isTransitioningFromUnregisteredMode,
    isUnregisteredMode,
  ]);

  if (!loaded) {
    return (
      <Loading>
        <FormattedMessage
          id="dashboard.accounts.loading"
        />
      </Loading>
    );
  }

  return <>{children}</>;
};

export default VaultWrapper;
