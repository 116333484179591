import React from 'react';
import { Provider } from 'react-intl-redux';
import { Store } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { FormattedMessage } from 'react-intl';

import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import DashboardMessenger from '@avira-pwm/messenger/dashboard';

import GlobalStyle, { FontFaces, DEFAULT_CONFIG } from 'pwm-components/global';
import Box from 'pwm-components/components/Box';

import ABTestInitializer from '../../abTest/components/ABTestInitializer';
import SpotlightMasterPasswordRedirect from '../../user/components/SpotlightMasterPasswordRedirect';

import {
  AuthPageContainer,
} from '../../authentication';

import SpotlightRoute from './SpotlightRoute';
import ChangeLanguage from './ChangeLanguage';
import ChangeTheme from './ChangeTheme';

import ThemeProvider from './ThemeProvider';

import mixpanel from '../../tracking/mixpanel';

import {
  AccountResetConfirmation,
  AccountVerified,
  ForgotMasterPassword,
  LoginContainer,
  RegisterContainer,
  FTUContainer,
  WhyRegister,
  MoreInfoPage,
} from '../../oe';

import {
  SpotlightConnectContainer,
  GetNativePermission,
  GetSpotlightData,
} from '../../spotlight';

import {
  CreateMasterPasswordContainer,
  UnlockMasterPasswordContainer,
  VerifyEmailContainer,
} from '../../user';

import { Provider as MessengerProvider } from '../../componentLib/MessengerContext';

import { DashboardContainer } from '../../dashboard';
import ErrorWrapper from '../../dashboard/components/ErrorWrapper';
import SyncState from '../../syncState';

import Tracking from './Tracking';

import AuthenticatedRoute from './AuthenticatedRoute';
import LockedRoute from './LockedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';
import UnlockedRoute from './UnlockedRoute';
import UnverifiedEmailRoute from './UnverifiedEmailRoute';
import UserExistsRoute from './UserExistsRoute';
import VerifiedEmailRoute from './VerifiedEmailRoute';
import { withOERouteComponent } from './OERouteComponent';
import ProtectedRoute from './ProtectedRoute';
import Loading from './Loading';

import DashboardListener from '../../dashboard/components/DashboardListener';
import ExtensionSyncContainer from '../../authentication/components/ExtensionSyncContainer';
import AuthenticationContainer from '../../authentication/components/AuthenticationContainer';
import Onboarding from '../../onboarding';
import SpotlightSync from '../../authentication/components/SpotlightSync';
import config from '../../config';
import ConfigRenderer from '../../componentLib/ConfigRenderer';
import Breadcrumbs from '../../componentLib/Breadcrumbs';
import Icons from '../../img/icons.inline.svg';
import { AARRRSync } from '../../tracking';
import { UserPreferencesInitializer } from '../../preferences';
import { trackTimeout } from '../../tracking/TrackingActions';
import { SpotlightLoginContainer, SpotlightOnboardingContainer, SpotlightOnboardingAccountVerify } from '../../spotlightLogin';
import { Provider as SearchParamsProvider } from './SearchParamsContext';
import FeedbackDialog from '../../dashboard/components/FeedbackDialog';
import OCLWrapper from '../../nlok/components/OCLWrapper';
import LoadingOverlay from './LoadingOverlay';
import VaultConfigWrapper from '../../nlok/components/VaultConfigWrapper';
import GeoLocationWrapper from './GeoLocationWrapper';
import PreferencesInitializer from '../../preferences/components/PreferencesInitializer';

const SyncWrapper = ({ children, ...props }: any): JSX.Element => (
  <>
    <ConfigRenderer condition="spotlight">
      <SpotlightSync>
        {children}
      </SpotlightSync>
    </ConfigRenderer>
    <ConfigRenderer condition="spotlight" negate>
      <ExtensionSyncContainer {...props}>
        {children}
      </ExtensionSyncContainer>
    </ConfigRenderer>
  </>
);

type Props = {
  store: Store;
  messenger: DashboardMessenger;
}

const LoginRouteComponent = withOERouteComponent(LoginContainer);
const RegisterRouteComponent = withOERouteComponent(RegisterContainer);

const App = ({ store, messenger }: Props): JSX.Element => (
  <Provider store={store}>
    <ThemeProvider>
      <PreferencesInitializer>
        <VaultConfigWrapper>
          <SearchParamsProvider>
            <>
              <FontFaces config={DEFAULT_CONFIG} />
              <GlobalStyle disabledSelection={config.spotlight} />
              <LoadingOverlay />
              <ErrorWrapper>
                <MessengerProvider value={messenger}>
                  <GeoLocationWrapper>
                    <AARRRSync>
                      <Box id="a-pwm-dashboard" height="100%">
                        <Box display="none">
                          <Icons />
                        </Box>
                        <Tracking />
                        <SyncState>
                          <SyncWrapper
                            renderWhileLoading={() => (
                              <Loading
                                onRetry={() => window.location.reload()}
                                timeout={40}
                                onTimeout={() => {
                                  (store.dispatch as ThunkDispatch<any, any, any>)(trackTimeout('ExtensionSync'));
                                }}
                              >
                                <FormattedMessage
                                  id="dashboard.status.connectingToExtension"
                                  defaultMessage="Connecting to extension ..."
                                />
                              </Loading>
                            )}
                          >
                            <AuthenticationContainer
                              mixpanel={mixpanel}
                              renderWhileLoading={() => (
                                <Loading
                                  onRetry={() => window.location.reload()}
                                  timeout={40}
                                  onTimeout={() => {
                                    // Thought the name of the component changed I'm keeping
                                    // AuthInitializer for mixpanel consistency
                                    (store.dispatch as ThunkDispatch<any, any, any>)(trackTimeout('AuthInitializer'));
                                  }}
                                >
                                  <FormattedMessage
                                    id="dashboard.status.authenticatingUser"
                                    defaultMessage="Authenticating user..."
                                  />
                                </Loading>
                              )}
                            >
                              <OCLWrapper>
                                <DashboardListener>
                                  <ABTestInitializer>

                                    <ConfigRenderer condition="spotlight">
                                      <Box mx="m">
                                        <Box position="fixed" top="s" zIndex={40}>
                                          <Breadcrumbs />
                                        </Box>
                                      </Box>
                                    </ConfigRenderer>

                                    <Switch>
                                      <Route
                                        exact
                                        path="/"
                                        component={() => <Redirect to={{ pathname: '/mydata/passwords' }} />}
                                      />

                                      <Route path="/account-deleted" component={AccountResetConfirmation} />

                                      <Route path="/account-verified" component={AccountVerified} />

                                      <Route
                                        path="/change-language/:language"
                                        component={ChangeLanguage}
                                      />

                                      <Route
                                        path="/change-theme/:theme"
                                        component={ChangeTheme}
                                      />

                                      <UnauthenticatedRoute
                                        path="/onboarding(|/existing)"
                                        component={Onboarding}
                                      />

                                      <UnauthenticatedRoute
                                        path="/login"
                                        component={LoginRouteComponent}
                                      />

                                      <Route
                                        path="/spotlight-login"
                                        component={SpotlightLoginContainer}
                                      />

                                      <UnauthenticatedRoute
                                        path="/register"
                                        component={RegisterRouteComponent}
                                      />

                                      <UnauthenticatedRoute
                                        path="/ftu(|/existing)"
                                        component={FTUContainer}
                                      />

                                      <AuthenticatedRoute
                                        path="/reset-account"
                                        component={ForgotMasterPassword}
                                      />

                                      <VerifiedEmailRoute
                                        path="/create-master-password"
                                        render={({ match: { path } }) => (
                                          <UserExistsRoute
                                            path={`${path}`}
                                            render={() => (
                                              <AuthPageContainer>
                                                <CreateMasterPasswordContainer />
                                              </AuthPageContainer>
                                            )}
                                          />
                                        )}
                                      />

                                      <Route
                                        path="/spotlight-unregistered"
                                        render={() => (
                                          <AuthPageContainer>
                                            <CreateMasterPasswordContainer />
                                          </AuthPageContainer>
                                        )}
                                      />

                                      <VerifiedEmailRoute
                                        path="/enter-master-password"
                                        render={({ match: { path } }) => (
                                          <UserExistsRoute
                                            path={`${path}`}
                                            render={() => (
                                              <AuthPageContainer>
                                                <UnlockMasterPasswordContainer />
                                              </AuthPageContainer>
                                            )}
                                          />
                                        )}
                                      />

                                      <VerifiedEmailRoute
                                        path="/master-password"
                                        render={({ match: { path } }) => (
                                          <UserExistsRoute
                                            path={`${path}`}
                                            redirectOnly
                                          />
                                        )}
                                      />

                                      <LockedRoute
                                        path="/unlock"
                                        render={() => (
                                          <AuthPageContainer>
                                            <UnlockMasterPasswordContainer />
                                          </AuthPageContainer>
                                        )}
                                      />

                                      <UnverifiedEmailRoute
                                        path="/verify-email"
                                        render={() => (
                                          <AuthPageContainer>
                                            {
                                              config.spotlight
                                                ? (
                                                  <SpotlightMasterPasswordRedirect />
                                                ) : (
                                                  <VerifyEmailContainer />
                                                )
                                            }
                                          </AuthPageContainer>
                                        )}
                                      />

                                      <Route
                                        path="/page-not-found"
                                        component={() => <div className="u-mt-l u-ml-l">404!!!</div>}
                                      />

                                      <Route
                                        path="/why-register"
                                        component={WhyRegister}
                                      />

                                      <Route
                                        path="/more-info"
                                        component={MoreInfoPage}
                                      />

                                      <Route
                                        path="/connect-extension"
                                        render={() => (
                                          <SpotlightConnectContainer>
                                            <GetNativePermission />
                                          </SpotlightConnectContainer>
                                        )}
                                      />

                                      <Route
                                        path="/connect-spotlight"
                                        render={() => (
                                          <SpotlightConnectContainer>
                                            <GetSpotlightData />
                                          </SpotlightConnectContainer>
                                        )}
                                      />

                                      <ProtectedRoute
                                        path="/spotlight-onboarding"
                                        component={SpotlightOnboardingContainer}
                                        requirement={() => config.spotlight}
                                        redirectPath="login"
                                      />

                                      <ProtectedRoute
                                        path="/verify-avira-account"
                                        component={SpotlightOnboardingAccountVerify}
                                        requirement={() => config.spotlight}
                                        redirectPath="login"
                                      />


                                      <Route
                                        path="/(accounts|mydata|connected-devices|settings|security-status|get-started|feature|spotlight|mobile)"
                                      >
                                        <UserPreferencesInitializer>
                                          <Switch>
                                            <Route
                                              exact
                                              path="/spotlight"
                                              component={SpotlightRoute}
                                            />
                                            <UnlockedRoute
                                              path="/(accounts|mydata|connected-devices|settings|security-status|get-started|feature|mobile)"
                                              component={DashboardContainer}
                                            />
                                          </Switch>
                                        </UserPreferencesInitializer>
                                      </Route>

                                      <Route render={() => (<Redirect to={{ pathname: '/page-not-found' }} />)} />

                                    </Switch>
                                    <FeedbackDialog />
                                  </ABTestInitializer>
                                </DashboardListener>
                              </OCLWrapper>
                            </AuthenticationContainer>
                          </SyncWrapper>
                        </SyncState>
                      </Box>
                    </AARRRSync>
                  </GeoLocationWrapper>
                </MessengerProvider>
              </ErrorWrapper>
            </>
          </SearchParamsProvider>
        </VaultConfigWrapper>
      </PreferencesInitializer>
    </ThemeProvider>
  </Provider>
);

App.propTypes = {
  store: PropTypes.shape({
    dispatch: PropTypes.func.isRequired,
    getState: PropTypes.func.isRequired,
    subscribe: PropTypes.func.isRequired,
  }),

  messenger: PropTypes.any.isRequired,
};

App.defaultProps = {
  store: null,
};

export default App;
