import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  clearBackupDatasets, restoreBackup, deleteBackupDataset, setBackupData, clearBackupData,
} from '../ManageBackupsActions';
import ManageBackups from './ManageBackups';

const mapStateToProps = ({ accounts, backupDatasets, backupData }) => (
  { accounts, backupDatasets, backupData }
);

const mapDispatchToProps = dispatch => bindActionCreators({
  clearBackupDatasets,
  restoreBackup,
  deleteBackupDataset,
  setBackupData,
  clearBackupData,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageBackups);
