import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl';

import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';

const propTypes = {
  aucCategory: PropTypes.string.isRequired,
};

type Props = PropTypes.InferProps<typeof propTypes>;

const TooltipAucDetectionCta: React.FC<Props> = ({ aucCategory }) => (
  <Box>
    <Paragraph>
      <strong>
        <FormattedMessage id="dashboard.securityStatus.tooltip.header.aucCta" />
      </strong>
    </Paragraph>

    <Paragraph
      mt="xs"
    >
      <FormattedHTMLMessage
        id="dashboard.securityStatus.unsafeWebsite.auc.tooltip"
        values={{ aucCategory }}
      />
    </Paragraph>
    <Paragraph
      mt="xs"
    >
      <FormattedMessage id="dashboard.securityStatus.unsafeWebsite.auc.cta" />
    </Paragraph>
  </Box>
);

export default TooltipAucDetectionCta;
