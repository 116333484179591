import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import theme from 'styled-theming';

import Box from 'pwm-components/components/Box';
import styled, { css } from 'pwm-components/styled';

import * as SpotlightAPI from '../../lib/SpotlightAPI';
import { State as OEState } from '../../oe/OEReducer';

import { lockUser } from '../../user/UserActions';
import DropdownMenu from '../../componentLib/DropdownMenu';

import config from '../../config';

type State = {
  oe: OEState;
};

const spotlightMenutTheme = theme('mode', {
  light: css`
    background: ${({ theme: { semanticColors } }) => semanticColors.foreground};
    color: ${({ theme: { colors } }) => colors.martinique};

    &:hover {
      background: ${({ theme: { colors } }) => colors.gainsboro};
    }
  `,
  dark: css`
    background: ${({ theme: { colors } }) => colors.shark};
    color: ${({ theme: { colors } }) => colors.white};

    &:hover {
      background: ${({ theme: { colors } }) => colors.outerspacedarker};
    }
  `,
});

const SpotlightMenuButton = styled(Box)`
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  height: 24px;
  line-height: 24px;
  text-align: center;
  font-weight: 500;
  font-family: Kievit, sans-serif;
  padding: 0 ${({ theme: { space } }) => space.medium} 0 ${({ theme: { space } }) => space.small};
  border: none;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  outline: none;
  ${spotlightMenutTheme}
`;

const SpotlightMenu: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const oeId = useSelector((state: State) => state.oe.id);
  const dispatch = useDispatch();

  const onOutsideClick = useCallback((): void => {
    if (visible) {
      setVisible(false);
    }
  }, [visible]);

  const onDropdownMenuClick = useCallback((): void => {
    setVisible(true);
  }, []);

  const onMenuLockClick = useCallback((e: Event): void => {
    e.stopPropagation();
    dispatch(lockUser('header', 'lock', null));
  }, [dispatch]);

  const onMenuInstallExtensionClick = useCallback((event: Event): void => {
    event.stopPropagation();
    if (config.spotlightOnboarding) {
      SpotlightAPI.showBrowserExtensions(oeId!.toString());
    } else {
      SpotlightAPI.openURL(config.onboardingWebsite);
    }
  }, [oeId]);

  useEffect(() => {
    window.addEventListener('click', onOutsideClick);
    return () => window.removeEventListener('click', onOutsideClick);
  });

  const menuItemsList = [
    {
      labelId: 'dashboard.spotlight.menu.settings',
      menuItemAttrs: {
        to: '/settings',
      },
    },
    {
      labelId: 'dashboard.account.menu.installExtension',
      menuItemAttrs: {
        onClick: onMenuInstallExtensionClick,
      },
    },
    {
      labelId: 'dashboard.account.menu.lock',
      menuItemAttrs: {
        onClick: onMenuLockClick,
      },
    },
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
    >
      <SpotlightMenuButton
        onClick={onDropdownMenuClick}
      >
        •••
      </SpotlightMenuButton>
      <Box
        position="absolute"
        top="xl"
        left="xl"
      >
        <DropdownMenu
          showDropdownMenu={visible}
          menuItems={menuItemsList}
        />
      </Box>
    </Box>
  );
};

export default SpotlightMenu;
