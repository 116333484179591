const IMPORT_ITEM_STATUS_OK = 0;
const IMPORT_ITEM_STATUS_DUPLICATE = 1;
const IMPORT_ITEM_STATUS_DUPLICATE_NON_MATCHING = 2;
const IMPORT_ITEM_STATUS_DUPLICATE_IMPORT = 3;
const IMPORT_ITEM_STATUS_ERROR = 4;

// eslint-disable-next-line max-statements
export const validateStep2 = (mapping) => {
  const nameMapped = mapping.name != null;
  const usernameMapped = mapping.username != null;
  const websiteMapped = mapping.website != null;
  const passwordMapped = mapping.password != null;
  const notesMapped = mapping.notes != null;

  const triedToMapPasswords = (passwordMapped || usernameMapped);
  const passwordsSuccesfullyMapped = ((passwordMapped && nameMapped)
    || (passwordMapped && usernameMapped && websiteMapped));

  const mappingErrors = [];

  if (!triedToMapPasswords) {
    if (!notesMapped) {
      const mappingError = [];
      if (!passwordMapped) {
        mappingError.push('password');
      }
      if (!websiteMapped) {
        mappingError.push('website');
      }
      if (!usernameMapped) {
        mappingError.push('username');
      }
      if (mappingError.length) {
        mappingErrors.push(mappingError);
      }

      mappingErrors.push(['notes']);
    }
  } else if (!passwordsSuccesfullyMapped) {
    if (!usernameMapped && !websiteMapped && !nameMapped) {
      mappingErrors.push(['username', 'website', 'password']);
      mappingErrors.push(['name']);
    } else {
      const mappingError = [];
      if (!passwordMapped) {
        mappingError.push('password');
      }
      if (!websiteMapped) {
        mappingError.push('website');
      }
      if (!usernameMapped) {
        mappingError.push('username');
      }
      if (mappingError.length) {
        mappingErrors.push(mappingError);
      }
      if (!nameMapped) {
        mappingErrors.push(['name']);
      }
    }
  }

  return mappingErrors;
};


export const STATUS_MAPPING = {
  [IMPORT_ITEM_STATUS_OK]: {
    icon: 'smiley-happy',
    iconColor: 'green',
    tooltip: 'dashboard.settings.import.reviewData.consistent',
    disabled: false,
    status: 'consistent',
  },
  [IMPORT_ITEM_STATUS_DUPLICATE]: {
    icon: 'smiley-happy',
    iconColor: 'green',
    tooltip: 'dashboard.settings.import.reviewData.duplicateAllMatching',
    disabled: true,
    status: 'duplicateAllMatching',
  },
  [IMPORT_ITEM_STATUS_DUPLICATE_IMPORT]: {
    icon: 'smiley-happy',
    iconColor: 'green',
    tooltip: 'dashboard.settings.import.reviewData.duplicateImport',
    disabled: true,
    status: 'duplicateImport',
  },
  [IMPORT_ITEM_STATUS_DUPLICATE_NON_MATCHING]: {
    icon: 'smiley-neutral',
    iconColor: 'strengthorange',
    tooltip: 'dashboard.settings.import.reviewData.duplicateNonMatching',
    disabled: false,
    status: 'duplicateNonMatching',
  },
  [IMPORT_ITEM_STATUS_ERROR]: {
    icon: 'smiley-sad',
    iconColor: 'red',
    tooltip: 'dashboard.settings.import.reviewData.inconsistent',
    disabled: true,
    status: 'inconsistent',
  },
};

export const mapStatus = (error) => {
  if (error == null) {
    return IMPORT_ITEM_STATUS_OK;
  }

  const keys = Object.keys(error).filter(key => key !== 'duplicateAccount' && key !== 'duplicateNote' && key !== 'duplicateImport');

  if (keys.length) {
    return IMPORT_ITEM_STATUS_ERROR;
  } if (error.duplicateAccount === 'ERROR_GLOBAL' || error.duplicateNote === 'ERROR_GLOBAL') {
    return IMPORT_ITEM_STATUS_DUPLICATE;
  } if (error.duplicateAccount === 'ERROR_CUSTOM' || error.duplicateNote === 'ERROR_CUSTOM') {
    return IMPORT_ITEM_STATUS_DUPLICATE_NON_MATCHING;
  } if (error.duplicateImport) {
    return IMPORT_ITEM_STATUS_DUPLICATE_IMPORT;
  }

  return IMPORT_ITEM_STATUS_ERROR;
};
