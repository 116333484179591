function arrayToHash<
  T extends { id: string },
  A extends T[],
>(
  dataArray: A,
  key: keyof T,
  {
    filter = () => true,
    map = item => item,
  }: {
    filter?: (item: T) => boolean;
    map?: (item: T) => any;
  } = {},
): ReturnType<typeof map> {
  return dataArray.reduce((r, v) => {
    if (filter(v)) {
      r[v[key]] = map(v);
    }
    return r;
  }, {} as any);
}

export default arrayToHash;
