import React, { useEffect } from 'react';
import { string, func } from 'prop-types';
import Box from 'pwm-components/components/Box';

const playerWidth = 560;
const playerHeight = 315;

const createYoutubeVideoPlayer = (videoId, language, onPlay) => (
  new window.YT.Player(videoId, {
    height: playerHeight,
    width: playerWidth,
    videoId,
    events: {
      onStateChange: (event) => {
        if (event.data === 1) onPlay();
      },
    },
    playerVars: {
      rel: 0, // show related videos only from same channel (Avira)
      modestbranding: 1,
      hl: language, // set player's interface language to user's lanaguage
    },
  })
);

const YouTubeVideo = ({
  videoId,
  language,
  onPlay,
}) => {
  useEffect(() => {
    const scriptTags = Array.from(document.getElementsByTagName('script'));
    const scriptTagsSrcs = scriptTags.map(script => script.src);

    let YTAPIReadyCheckInterval = null;

    if (scriptTagsSrcs.includes('https://www.youtube.com/iframe_api')) {
      // If we have more than one YouTube video element immediately on mount of the page
      // the YouTube API might not be ready though one of the components has initiated the loading.
      // As such we need to handle this case separately as there is only one
      // global onYouTubeIframeAPIReady
      if (window.YT && window.YT.Player) {
        createYoutubeVideoPlayer(videoId, language, onPlay);
      } else {
        YTAPIReadyCheckInterval = setInterval(() => {
          if (window.YT && window.YT.Player) {
            createYoutubeVideoPlayer(videoId, language, onPlay);
            clearInterval(YTAPIReadyCheckInterval);
          }
        }, 100);
      }
    } else {
      const firstScriptTag = scriptTags[0];
      const tag = document.createElement('script');

      tag.src = 'https://www.youtube.com/iframe_api';
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = () => {
        createYoutubeVideoPlayer(videoId, language, onPlay);
      };
    }

    return () => {
      clearInterval(YTAPIReadyCheckInterval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box width={playerWidth} height={playerHeight} bgOverride="smokegrey">
      <div id={videoId} />
    </Box>
  );
};

YouTubeVideo.propTypes = {
  videoId: string.isRequired,
  language: string,
  onPlay: func.isRequired,
};

YouTubeVideo.defaultProps = {
  language: 'en',
};

export default YouTubeVideo;
