import { AWSSyncStore } from '@avira-pwm/services/LicenseService';
import { Credentials as S3FileCredentials } from '@avira-pwm/services/S3FileService';
import { Credentials } from 'aws-sdk';

export const DASHBOARD_SET_EXTENSION_CONNECTED = 'DASHBOARD_SET_EXTENSION_CONNECTED';
export const DASHBOARD_SET_EXTENSION_DISCONNECTED = 'DASHBOARD_SET_EXTENSION_DISCONNECTED';
export const DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED = 'DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED';
export const DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED = 'DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED';
export const DASHBOARD_SET_SYNC_DATA = 'DASHBOARD_SET_SYNC_DATA';
export const DASHBOARD_SET_S3_FILE_AUTH_DATA = 'DASHBOARD_SET_S3_FILE_AUTH_DATA';
export const DASHBOARD_ACTIVATE_UNREGISTERED_MODE = 'DASHBOARD_ACTIVATE_UNREGISTERED_MODE';
export const DASHBOARD_DEACTIVATE_UNREGISTERED_MODE = 'DASHBOARD_DEACTIVATE_UNREGISTERED_MODE';
export const DASHBOARD_DISMISS_CONGRATS_REGISTER = 'DASHBOARD_DISMISS_CONGRATS_REGISTER';
export const DASHBOARD_SET_ERROR = 'DASHBOARD_SET_ERROR';
export const DASHBOARD_CLEAR_ERROR = 'DASHBOARD_CLEAR_ERROR';
export const DASHBOARD_SET_LOADING_OVERLAY = 'DASHBOARD_SET_LOADING_OVERLAY';
export const DASHBOARD_CLEAR_LOADING_OVERLAY = 'DASHBOARD_CLEAR_LOADING_OVERLAY';
export const DASHBOARD_SET_SCROLLING = 'DASHBOARD_SET_SCROLLING';
export const DASHBOARD_SET_MIGRATING = 'DASHBOARD_SET_MIGRATING';

export type DashboardError = {
  message?: string;
  messageId?: string;
  context?: string;
  errorCode?: string;
  error?: Error;
  retry?: boolean;
  dismissable?: boolean;
}

interface DashboardSetExtensionConnected {
  type: typeof DASHBOARD_SET_EXTENSION_CONNECTED;
  value: {
    connected: true;
    compatible: boolean;
    installedVersion: string | null;
    currentVersion: string | null;
    currentHash: string | null;
    isOutdated: boolean | null;
    outdatedTimestamp: number | null;
  };
}

interface DashboardSetExtensionDisconnected {
  type: typeof DASHBOARD_SET_EXTENSION_DISCONNECTED;
}

interface DashboardSetExtensionAdapterConnected {
  type: typeof DASHBOARD_SET_EXTENSION_ADAPTER_CONNECTED;
  value: boolean;
}

interface DashboardSetCloudSyncAdapterConnected {
  type: typeof DASHBOARD_SET_CLOUD_SYNC_ADAPTER_CONNECTED;
  value: boolean;
}

interface DashboardSetSyncData {
  type: typeof DASHBOARD_SET_SYNC_DATA;
  value: {
    data: null | AWSSyncStore;
    credentials: null | Credentials;
  };
}

interface DashboardSetS3FileAuthData {
  type: typeof DASHBOARD_SET_S3_FILE_AUTH_DATA;
  value: {
    data: null | S3FileCredentials;
    credentials: null | Credentials;
  };
}

interface DashboardActivateUnregisteredUserData {
  type: typeof DASHBOARD_ACTIVATE_UNREGISTERED_MODE;
  value: {
    tempKey: string;
    tempUserId: string;
  };
}

interface DashboardDeactivateUnregisteredMode {
  type: typeof DASHBOARD_DEACTIVATE_UNREGISTERED_MODE;
}

interface DashboardDismissCongratsRegister {
  type: typeof DASHBOARD_DISMISS_CONGRATS_REGISTER;
}

interface DashboardSetError {
  type: typeof DASHBOARD_SET_ERROR;
  value: DashboardError;
}

interface DashboardClearError {
  type: typeof DASHBOARD_CLEAR_ERROR;
}

interface DashboardSetLoadingOverlay {
  type: typeof DASHBOARD_SET_LOADING_OVERLAY;
  value: {
    message: string;
    timeout: number | null;
  };
}

interface DashboardClearLoadingOverlay {
  type: typeof DASHBOARD_CLEAR_LOADING_OVERLAY;
}

interface DashboardSetScrolling {
  type: typeof DASHBOARD_SET_SCROLLING;
  scrolling: boolean;
}

interface DashboardSetMigrating {
  type: typeof DASHBOARD_SET_MIGRATING;
  value: boolean;
}

export type DashboardActionTypes =
  DashboardSetExtensionConnected
  | DashboardSetExtensionDisconnected
  | DashboardSetExtensionAdapterConnected
  | DashboardSetCloudSyncAdapterConnected
  | DashboardSetSyncData
  | DashboardSetS3FileAuthData
  | DashboardActivateUnregisteredUserData
  | DashboardDeactivateUnregisteredMode
  | DashboardDismissCongratsRegister
  | DashboardSetError
  | DashboardClearError
  | DashboardSetLoadingOverlay
  | DashboardClearLoadingOverlay
  | DashboardSetScrolling
  | DashboardSetMigrating;
