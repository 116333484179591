import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CloseCircleIcon from 'pwm-components/icons/CloseCircle';
import Text from 'pwm-components/components/Text';
import ModifiedAt from 'pwm-components/components/ModifiedAt';

import Card from '../../componentLib/Card';
import List from '../../componentLib/List';
import PageTitle from '../../componentLib/PageTitle';
import PageContentWrapper from '../../componentLib/PageContentWrapper';
import Loading from '../../app/components/Loading';

import androidPhoneIcon from '../../img/ic-android-phone.png';
import androidPhoneIcon2x from '../../img/ic-android-phone-2x.png';
import androidTabletIcon from '../../img/ic-android-tablet.png';
import androidTabletIcon2x from '../../img/ic-android-tablet-2x.png';
import iphoneIcon from '../../img/ic-iphone.png';
import iphoneIcon2x from '../../img/ic-iphone-2x.png';
import ipadIcon from '../../img/ic-ipad.png';
import ipadIcon2x from '../../img/ic-ipad-2x.png';
import macIcon from '../../img/ic-mac.png';
import macIcon2x from '../../img/ic-mac-2x.png';
import windowsIcon from '../../img/ic-windows.png';
import windowsIcon2x from '../../img/ic-windows-2x.png';
import phoneIcon from '../../img/ic-phone.png';
import phoneIcon2x from '../../img/ic-phone-2x.png';
import tabletIcon from '../../img/ic-tablet.png';
import tabletIcon2x from '../../img/ic-tablet-2x.png';
import desktopIcon from '../../img/ic-desktop.png';
import desktopIcon2x from '../../img/ic-desktop-2x.png';

const devicesMap = {
  iphone: {
    name: 'iPhone',
    icon: iphoneIcon,
    icon2x: iphoneIcon2x,
  },
  ipad: {
    name: 'iPad',
    icon: ipadIcon,
    icon2x: ipadIcon2x,
  },
  windows: {
    name: 'Windows',
    icon: windowsIcon,
    icon2x: windowsIcon2x,
  },
  mac: {
    name: 'Mac',
    icon: macIcon,
    icon2x: macIcon2x,
  },
  androidPhone: {
    name: 'Android phone',
    icon: androidPhoneIcon,
    icon2x: androidPhoneIcon2x,
  },
  androidtablet: {
    name: 'Android tablet',
    icon: androidTabletIcon,
    icon2x: androidTabletIcon2x,
  },
  desktop: {
    name: 'Desktop',
    icon: desktopIcon,
    icon2x: desktopIcon2x,
  },
  phone: {
    name: 'Phone',
    icon: phoneIcon,
    icon2x: phoneIcon2x,
  },
  tablet: {
    name: 'Tablet',
    icon: tabletIcon,
    icon2x: tabletIcon2x,
  },
};

const typeMap = {
  android: 'Android app',
  ios: 'iOS app',
  opera: 'Opera Browser',
  edge: 'Microsoft Edge',
  firefox: 'Firefox',
  chrome: 'Google Chrome',
};

class ConnectedDevices extends React.Component {
  static detectDevice({ device, device_type: deviceType, platform }) {
    const lcDevice = device.toLowerCase();
    const lcDeviceType = deviceType.toLowerCase();
    const lcPlatform = platform.split(' ')[0].toLowerCase();

    let detectedDevice = devicesMap[lcDeviceType];

    if (lcPlatform in devicesMap) {
      detectedDevice = devicesMap[lcPlatform];
    } else if (lcDevice in devicesMap) {
      detectedDevice = devicesMap[lcDevice];
    } else if (lcPlatform === 'android') {
      detectedDevice = lcDeviceType === 'phone' ? devicesMap.androidPhone : devicesMap.androidtablet;
    } else if (lcPlatform === 'ios') {
      detectedDevice = lcDeviceType === 'phone' ? devicesMap.iphone : devicesMap.ipad;
    }

    if (lcDeviceType === 'phone' || lcDeviceType === 'tablet') {
      detectedDevice.name = device;
    }

    return detectedDevice;
  }

  static detectType({ browser, platform }) {
    let detectedType = browser !== 'Other' ? browser : '';
    const lcBrowser = browser.split(' ')[0].toLowerCase();
    const lcPlatform = platform.split(' ')[0].toLowerCase();

    if (Object.keys(typeMap).includes(lcBrowser) && lcBrowser !== lcPlatform) {
      detectedType = typeMap[lcBrowser];
    } else if (browser === 'mobile' && (lcPlatform === 'android' || lcPlatform === 'ios')) {
      detectedType = typeMap[lcPlatform];
    }

    return detectedType;
  }

  constructor(props) {
    super(props);
    const { devices } = this.props;
    this.state = {
      devices,
      loading: true,
    };
    this.getDevices = this.getDevices.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.getDevices();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { devices } = this.props;

    if (devices !== nextProps.devices) {
      devices = nextProps.devices
        .map(({
          browser, last_seen_at, self, platform, device, device_type, id,
        }) => {
          const detectedDevice = ConnectedDevices.detectDevice({ device, device_type, platform });
          const detectedType = ConnectedDevices.detectType({ browser, platform });
          return {
            id,
            lastUsed: { self, lastSeen: last_seen_at },
            device: detectedDevice,
            type: detectedType,
            disconnect: self ? '' : (
              <a
                className="u-cursor-p u-outline-none a-disconnect"
                role="button"
                tabIndex="0"
                onClick={() => { this.props.logOutDevice(id, this.props.user.authToken); }}
              >
                <CloseCircleIcon variant="linkAlternate" />
              </a>
            ),
          };
        });

      this.setState({ devices });
    }
  }

  async getDevices() {
    const { isUnregisteredMode, getDevices, user } = this.props;
    if (!isUnregisteredMode) {
      try {
        await getDevices(user.authToken);
      } catch { /**/ }
    }
    this.setState({ loading: false });
  }

  render() {
    const { loading, devices } = this.state;
    const { isUnregisteredMode } = this.props;

    const columns = [{
      headerLabelId: 'dashboard.connectedDevices.device',
      key: 'device',
      className: 'u-pl-xxxl u-fx-jc-s',
      renderer:
        cellData => (
          <div className="o-device-box u-fx u-fx-ai-c">
            <img
              src={cellData.icon}
              srcSet={`${cellData.icon} 1x, ${cellData.icon2x} 2x`}
              alt="deviceIcon"
            />
            <Text ml="s">
              {cellData.name}
            </Text>
          </div>
        ),
    }, {
      headerLabelId: 'dashboard.connectedDevices.lastUsed',
      key: 'lastUsed',
      renderer: cellData => (
        <span className="a-last-used">
          {cellData.self
            ? (
              <a>
                <FormattedMessage id="dashboard.connectedDevices.activeNow" />
              </a>
            )
            : <ModifiedAt value={cellData.lastSeen} showTime />}
        </span>
      ),
    }, {
      headerLabelId: 'dashboard.connectedDevices.type',
      key: 'type',
      renderer: cellData => cellData,
    }, {
      headerLabelId: 'dashboard.connectedDevices.disconnect',
      key: 'disconnect',
      className: 'u-pr-xxxl',
      renderer: cellData => cellData,
    }];

    return (
      <PageContentWrapper height="100%">
        {!loading
          && (
            <PageTitle>
              <FormattedMessage id="sidebar.menu.devices" />
            </PageTitle>
          )}

        {
          isUnregisteredMode
            ? (
              <Card large>
                <FormattedMessage
                  id="dashboard.connectedDevices.registerForFreeMessage"
                  defaultMessage="With Avira Password Manager you can synchronize your data between all your devices. To use this feature, please register for free. After registration, you can install and login to Avira Password Manager on all your devices. In this section all your connected devices will be shown in a table."
                />
              </Card>
            ) : (
              <div
                className="u-fx-1 u-mb-xxxl u-minheight-0"
                ref={(container) => { this.container = container; }}
              >
                {
                  loading
                    ? (
                      <Loading onRetry={this.getDevices} />
                    ) : (
                      <Card noPadding>
                        <List
                          data={
                            devices.sort((a, b) => (
                              new Date(b.lastUsed.lastSeen) - new Date(a.lastUsed.lastSeen)
                            ))
                          }
                          columns={columns}
                          maxHeight={
                            this.container
                            && (this.container.clientHeight - this.container.offsetTop)
                          }
                        />
                      </Card>
                    )
                }
              </div>
            )
        }
      </PageContentWrapper>
    );
  }
}

ConnectedDevices.propTypes = {
  user: PropTypes.shape({
    authToken: PropTypes.string,
  }),
  devices: PropTypes.array.isRequired,
  getDevices: PropTypes.func.isRequired,
  logOutDevice: PropTypes.func.isRequired,
  isUnregisteredMode: PropTypes.bool.isRequired,
};

ConnectedDevices.defaultProps = {
  user: {
    authToken: null,
  },
};

export default ConnectedDevices;
