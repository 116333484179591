import { updateIntl } from 'react-intl-redux';
import { updateUserPreferences } from '@avira-pwm/redux/userPreferences';
import * as Sentry from '@sentry/browser';
import asyncJsonStorage from '../lib/asyncJsonStorage';
import * as SpotlightAPI from '../lib/SpotlightAPI';
import { getRelevantUserId } from '../user/selectors';

import {
  PREFS_SET_PASSGEN,
  PREFS_DISMISS_EXT_BANNER,
  PREFS_DISMISS_FILE_BANNER,
  PREFS_DISMISS_GET_MOBILE_BANNER,
  SET_FTU_AB_TEST_VERSION,
  UPDATE_LANGUAGE,
  SET_THEME,
  SET_GEO_CHECK,
  SET_SERVER_TIME_OFFSET,
} from './PreferencesActionTypes';

import { getLocaleData } from '../locales';
import mixpanel from '../tracking/mixpanel';
import config from '../config';

const getUserPreferencesKey = id => `USER_${id}_preferences`;

const loadPersistedUserPreferences = async (id) => {
  const key = getUserPreferencesKey(id);
  try {
    const userPrefs = await asyncJsonStorage.get([key]);
    return userPrefs[key];
  } catch (e) {
    Sentry.captureException(e);
  }

  return null;
};

export const setTheme = theme => ({
  type: SET_THEME,
  value: theme,
});

export const toggleTheme = () => (dispatch, getState) => {
  const currentTheme = getState().preferences.theme;
  dispatch(setTheme(currentTheme === 'dark' ? 'light' : 'dark'));
};

const persistUserPreferences = (id, prefs) => {
  const key = getUserPreferencesKey(id);
  try {
    asyncJsonStorage.set({ [key]: prefs });
  } catch (e) {
    Sentry.captureException(e);
  }
};

const getExtensionMergedPreferences = (prefsToSend, dashboardMessenger) => (
  new Promise((resolve) => {
    const noResponseTimeout = setTimeout(() => resolve(null), 100); // fallback

    dashboardMessenger.send(
      'dashboard:extension:mergeUserPreferences',
      prefsToSend,
      (err, mergedPrefs) => {
        clearTimeout(noResponseTimeout);
        resolve(mergedPrefs);
      },
    );
  })
);

const getSpotlightMergedPreferences = async (userId, userPreference) => {
  if (!SpotlightAPI.getAutolock.isAvailable()) {
    return null;
  }

  const spotlightAutolock = await new Promise((resolve) => {
    if (!SpotlightAPI.getAutolock.isAvailable()) {
      resolve(null);
      return;
    }

    SpotlightAPI.getAutolock(userId, (err, data) => {
      if (err) {
        resolve(null);
      }
      resolve(data);
    });
  });

  if (!userPreference.autolock
    || (spotlightAutolock && spotlightAutolock.timestamp > userPreference.autolock.timestamp)) {
    userPreference.autolock = spotlightAutolock;
    return userPreference;
  }

  return null;
};

const setUserPreferences = prefs => async (dispatch, getState, { dashboardMessenger }) => {
  const {
    dashboard: { isUnregisteredMode },
    userData: { id },
  } = getState();
  dispatch(updateUserPreferences(prefs));

  if (isUnregisteredMode) {
    dashboardMessenger.send(
      'dashboard:extension:setUserPreferences',
      getState().userPreferences,
    );
  } else {
    let prefsToPersist = getState().userPreferences;

    if (dashboardMessenger.isConnected()) {
      const mergedPreferences = await getExtensionMergedPreferences(prefs, dashboardMessenger);
      if (mergedPreferences) {
        dispatch(updateUserPreferences(mergedPreferences));
        prefsToPersist = mergedPreferences;
      }
    }
    persistUserPreferences(id, prefsToPersist);
  }
};

// eslint-disable-next-line max-statements, complexity
export const initializeUserPreferences = () => async (
  dispatch, getState, { dashboardMessenger },
) => {
  const {
    dashboard: { isUnregisteredMode },
    userData: { id },
    userPreferences,
    preferences,
    intl: { locale },
  } = getState();

  if (isUnregisteredMode) {
    dashboardMessenger.send(
      'dashboard:extension:getUserPreferences',
      null,
      (err, prefs) => {
        dispatch(updateUserPreferences(prefs));
        if (prefs.language.value !== locale) {
          dispatch(updateIntl(getLocaleData(prefs.language.value)));
        }
      },
    );
  } else {
    const persistedUserPrefs = await loadPersistedUserPreferences(id);

    let prefsToPersist = null;

    if (persistedUserPrefs) {
      await dispatch(updateUserPreferences(persistedUserPrefs));
    } else {
      // we want to initialize the language to last used language on the dashboard here
      // instead of defaulting it to the browser's current language
      if (preferences.language !== userPreferences.language.value) {
        dispatch(updateUserPreferences({
          language: {
            value: preferences.language,
            timestamp: null,
          },
        }));
      }

      prefsToPersist = getState().userPreferences;
    }

    if (dashboardMessenger.isConnected()) {
      const mergedPreferences = await getExtensionMergedPreferences(
        getState().userPreferences, dashboardMessenger,
      );
      if (mergedPreferences) {
        dispatch(updateUserPreferences(mergedPreferences));
        prefsToPersist = mergedPreferences;
      }
    } else if (config.spotlight) {
      const userId = getRelevantUserId(getState());

      if (userId) {
        const mergedPreferences = await getSpotlightMergedPreferences(
          userId, getState().userPreferences,
        );
        if (mergedPreferences) {
          dispatch(updateUserPreferences(mergedPreferences));
        }
      }
    }

    const userPrefLanguage = getState().userPreferences.language.value;

    if (locale !== userPrefLanguage) {
      dispatch(updateIntl(getLocaleData(userPrefLanguage)));
      dispatch({ type: UPDATE_LANGUAGE, value: userPrefLanguage });
    }

    if (prefsToPersist) persistUserPreferences(id, prefsToPersist);
  }
};

export const dismissExtBanner = () => ({ type: PREFS_DISMISS_EXT_BANNER });
export const dismissFileBanner = () => ({ type: PREFS_DISMISS_FILE_BANNER });
export const dismissGetMobileBanner = () => ({ type: PREFS_DISMISS_GET_MOBILE_BANNER });

export const setPassGenPrefs = ({ length, numbers, symbols }) => ({
  type: PREFS_SET_PASSGEN,
  value: {
    PassGenLength: length,
    PassGenNumbers: numbers,
    PassGenSymbols: symbols,
  },
});

export const changeAutoLockDuration = duration => (dispatch, getState) => {
  const userId = getRelevantUserId(getState());
  const autolock = {
    value: duration,
    timestamp: Date.now(),
  };
  dispatch(setUserPreferences({ autolock }));

  if (SpotlightAPI.setAutolock.isAvailable() && userId) {
    SpotlightAPI.setAutolock(userId.toString(), autolock);
  }
};

export const updateLanguageChoice = language => async (dispatch) => {
  await dispatch(updateIntl(getLocaleData(language)));
  await dispatch({ type: UPDATE_LANGUAGE, value: language });
  await dispatch(setUserPreferences({
    language: {
      value: language,
      timestamp: Date.now(),
    },
  }));
};

export const setFTUParameters = existing => (dispatch, getState) => {
  let { preferences: { ftuABTestVersion } } = getState();

  const currentAVersion = 'D';
  const currentBVersion = 'D';

  if (existing) {
    ftuABTestVersion = 'Z';
  } else {
    // to check for old versions and replace them with new versions.
    switch (ftuABTestVersion) {
      case 'A':
      case 'B':
      case 'C1':
      case 'C2':
        ftuABTestVersion = currentAVersion;
        break;
      case 'Z':
        ftuABTestVersion = null;
        break;
      default:
        ftuABTestVersion = currentAVersion;
        break;
    }
  }

  if (ftuABTestVersion == null) {
    ftuABTestVersion = Math.random() >= 0.5 ? currentBVersion : currentAVersion;
  }

  dispatch({ type: SET_FTU_AB_TEST_VERSION, value: ftuABTestVersion });
  mixpanel.setFTUParameters(ftuABTestVersion, existing);
  return { ftuABTestVersion };
};

export const setSpotlightAutolock = data => (dispatch, getState) => {
  const { userData: { id }, userPreferences } = getState();
  const { autolock } = userPreferences;

  if (id === data.user_id && data.autolock.timestamp > autolock.timestamp) {
    const mergedPrefs = {
      ...userPreferences,
      ...{ autolock: data.autolock },
    };
    dispatch(updateUserPreferences(mergedPrefs));
    persistUserPreferences(id, mergedPrefs);
  }
};

export const setExtensionAutolock = data => (dispatch, getState) => {
  const { userData: { id }, userPreferences } = getState();
  const { autolock } = userPreferences;

  if (data.timestamp > autolock.timestamp) {
    const mergedPrefs = {
      ...userPreferences,
      ...{ autolock: data },
    };
    dispatch(updateUserPreferences(mergedPrefs));
    persistUserPreferences(id, mergedPrefs);
  }
};

/**
 * @param {Pick<import('./PreferencesReducer').State, 'geoBlock' | 'geoCheckTimestamp'>} value
 */
export const setGeoCheck = value => ({ type: SET_GEO_CHECK, value });

/**
 * @param {import('./PreferencesReducer').State['serverTimeOffset']} value
 */
export const setServerTimeOffset = value => ({ type: SET_SERVER_TIME_OFFSET, value });
