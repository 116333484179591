import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { SidebarItem, SidebarImage, SidebarText } from 'pwm-components/components/Sidebar';

const SidebarMenuItem = ({
  icon,
  imgSrcActive,
  imgSrcActive2x,
  label,
  linkTo,
  active,
  imgOverlay,
  textColor,
}) => (
  <Link
    to={linkTo}
    className="u-d-b u-outline-none"
  >
    <SidebarItem active={active}>
      <SidebarImage>
        {icon || (
          <img
            src={imgSrcActive}
            srcSet={`${imgSrcActive} 1x, ${imgSrcActive2x} 2x`}
            alt={label}
          />
        )}
        {imgOverlay}
      </SidebarImage>

      <SidebarText color={textColor}>
        <FormattedMessage
          id={`sidebar.menu.${label}`}
        />
      </SidebarText>
    </SidebarItem>
  </Link>
);

SidebarMenuItem.propTypes = {
  imgSrcActive: PropTypes.string,
  imgSrcActive2x: PropTypes.string,
  label: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  imgOverlay: PropTypes.node,
  icon: PropTypes.element,
  textColor: PropTypes.string,
};

SidebarMenuItem.defaultProps = {
  imgOverlay: null,
  icon: null,
  imgSrcActive: null,
  imgSrcActive2x: null,
  textColor: null,
};

export default SidebarMenuItem;
