import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import {
  FormattedMessage, FormattedHTMLMessage, injectIntl,
} from 'react-intl';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import ActionButton from '../../componentLib/ActionButton';
import {
  prepareNewAccountSetup,
} from '../../user/UserActions';

import { trackResetAccountConfirmed, trackResetAccountError } from './ResetAccountActions';

import PWMLogo from '../../componentLib/PWMLogo';
import aviraIcon from '../../img/avira-app-icon.svg';
import checkMark from '../../img/checkmark_verified.png';
import checkMark2x from '../../img/checkmark_verified2x.png';
import errorMark from '../../img/icon-error.png';
import errorMark2x from '../../img/icon-error@2x.png';
import { isMobilePlatform } from '../../lib/UserAgent';
import { resetMasterPassword } from '../../user/MasterPasswordActions';
import intlShape from '../../lib/intlShape';

const response = {
  success: 'success',
  used: 'used',
  invalid: 'invalid',
  expired: 'expired',
};

class AccountResetConfirmation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mobilePlatform: isMobilePlatform(),
    };

    this.resendEmail = this.resendEmail.bind(this);
    this.startNew = this.startNew.bind(this);
  }

  componentDidMount() {
    const { status } = queryString.parse(this.props.location.search);
    const serverResponse = status || response.invalid;
    if (serverResponse === response.success) {
      this.props.trackResetAccountConfirmed();
    } else {
      this.props.trackResetAccountError(serverResponse);
    }
  }

  resendEmail() {
    this.props.onResend(this.props.email);
    this.props.history.push('mydata/passwords');
  }

  startNew() {
    this.props.onStartNew();
    this.props.history.push('master-password');
  }

  // eslint-disable-next-line max-statements, complexity
  render() {
    const { location, email, intl } = this.props;
    const query = queryString.parse(location.search);
    const resetEmail = decodeURIComponent(query.email || '');
    const serverResponse = query.status || response.invalid;
    const sameEmail = (email === resetEmail);

    const { mobilePlatform } = this.state;

    let icon = null;
    let header = null;
    let instruction = null;

    if (serverResponse === response.success) {
      icon = (
        <img
          src={checkMark}
          srcSet={`${checkMark} 1x, ${checkMark2x} 2x`}
          alt="Yee-Hoo"
          className="u-mb-xxl"
        />
      );

      header = (
        <h1 className="u-txt-paragon u-color-lightslategrey u-mb-s">
          <FormattedHTMLMessage
            id="forgot.masterPassword.accountReset.header"
            values={{ email: resetEmail }}
          />
        </h1>
      );

      instruction = (
        <div className="u-txt-greatprimer u-color-lightslategrey u-pl-xs u-pr-xs">
          <FormattedMessage
            id="forgot.masterPassword.accountReset.instruction"
          />
        </div>
      );
    } else {
      icon = (
        <img
          src={errorMark}
          srcSet={`${errorMark} 1x, ${errorMark2x} 2x`}
          alt="Yee-Hoo"
          className="u-mb-xxl"
        />
      );

      header = (
        <h1 className="u-txt-paragon u-color-lightslategrey u-mb-s">
          <FormattedHTMLMessage
            id="forgot.masterPassword.accountReset.header.error"
            values={{ email: resetEmail }}
          />
        </h1>
      );

      if (serverResponse === response.used) {
        instruction = (
          <div className="u-txt-greatprimer u-color-lightslategrey u-pl-xs u-pr-xs">
            <FormattedMessage
              id="forgot.masterPassword.accountReset.instruction.alreadyReset"
            />
          </div>
        );
      } else if (serverResponse === response.expired) {
        instruction = (
          <div className="u-txt-greatprimer u-color-lightslategrey u-pl-xs u-pr-xs">
            <FormattedMessage
              id={sameEmail
                ? 'forgot.masterPassword.accountReset.instruction.linkExpired'
                : 'forgot.masterPassword.accountReset.instruction.linkExpired.differentUser'}
            />
          </div>
        );
      } else if (serverResponse === response.invalid) {
        instruction = (
          <div className="u-txt-greatprimer u-color-lightslategrey u-pl-xs u-pr-xs">
            <FormattedMessage
              id={sameEmail
                ? 'forgot.masterPassword.accountReset.instruction.invalidLink'
                : 'forgot.masterPassword.accountReset.instruction.invalidLink.differentUser'}
            />
          </div>
        );
      }
    }

    let cta = null;
    if (!mobilePlatform) {
      if (serverResponse === response.success) {
        cta = (
          <ActionButton
            onClick={this.startNew}
            label={intl.formatMessage({
              id: 'forgot.masterPassword.button.startNew',
            })}
            block={false}
          />
        );
      } else if (
        serverResponse !== response.success
        && serverResponse !== response.used
        && sameEmail
      ) {
        cta = (
          <ActionButton
            onClick={this.resendEmail}
            label={intl.formatMessage({
              id: 'forgot.masterPassword.button.resend',
            })}
            block={false}
          />
        );
      } else {
        cta = (
          <ActionButton
            onClick={() => this.props.history.push('mydata/passwords')}
            label={intl.formatMessage({
              id: 'forgot.masterPassword.button.goBackToDashboard',
            })}
            block={false}
          />
        );
      }
    }

    return (
      <div className="u-fx u-minheight-full">
        <div className="u-fx u-fx-d-c u-minheight-full u-width-full">
          <div className="c-header">
            <PWMLogo />
          </div>

          <div
            className="u-fx u-fx-d-c u-fx-ai-c u-fx-jc-c u-fx-g-1 u-bg-gainsboro u-txt-center"
          >
            <div className="u-mb-xxl c-dashboard u-pl-xxxl u-pr-xxxl">
              {icon}
              {header}
              {instruction}
            </div>
            {cta}
          </div>

          <div className="o-section u-pb-l u-pt-xl">
            <p className="u-txt-center">
              <img height="25" width="25" src={aviraIcon} alt="Avira" />
            </p>
            <p className="u-txt-center u-txt-longprimer u-color-silver">
              <FormattedMessage
                id="product.poweredByAvira"
                defaultMessage="powered by Avira"
              />
            </p>
          </div>
        </div>
      </div>
    );
  }
}

AccountResetConfirmation.propTypes = {
  email: PropTypes.string.isRequired,
  intl: intlShape.isRequired,
  onResend: PropTypes.func.isRequired,
  onStartNew: PropTypes.func.isRequired,
  trackResetAccountConfirmed: PropTypes.func,
  trackResetAccountError: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
};

AccountResetConfirmation.defaultProps = {
  trackResetAccountConfirmed: () => { },
  trackResetAccountError: () => { },
  history: {
    push: () => { },
  },
  location: {
    search: '',
  },
};

const mapDispatchToProps = dispatch => bindActionCreators({
  onResend: resetMasterPassword,
  onStartNew: prepareNewAccountSetup,
  trackResetAccountConfirmed,
  trackResetAccountError,
}, dispatch);

const mapStateToProps = ({ oe }) => ({ email: oe.email });

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(AccountResetConfirmation)));
