import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedHTMLMessage } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import styled from 'pwm-components/styled';
import Box from 'pwm-components/components/Box';
import Paragraph from 'pwm-components/components/Paragraph';
import Checkbox from 'pwm-components/components/Checkbox';
import PWMLogo from '../../componentLib/PWMLogo';
import { resetMasterPassword } from '../../user/MasterPasswordActions';
import backgroundImage from '../../img/avira-bg.png';

import ForgotMasterPasswordStep from './ForgotMasterPasswordStep';
import { trackResetAccount } from './ResetAccountActions';

import {
  STEP_MOBILE,
  STEP_MOBILE_BIOMETRIC,
  STEP_MOBILE_CHANGE,
  STEP_DASHBOARD_CHANGE,
  STEP_EMAIL_SENT,
  CONGRATULATION,
} from '../../lib/ResetAccountHelper';

const BackgroundImage = styled('div')`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: ${({ theme: { mode } }) => (mode === 'light' ? 1 : 0.1)};
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
`;

const mobileSteps = [
  'resetAccount.newMasterPassword.mobileReset.step1',
  'resetAccount.newMasterPassword.mobileReset.step2',
  'resetAccount.newMasterPassword.mobileReset.step3',
];

class ForgotMasterPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: STEP_MOBILE,
      checkboxConfirm: true,
      checked: false,
    };

    this.onClickReset = this.onClickReset.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.getForgotMasterPasswordStep = this.getForgotMasterPasswordStep.bind(this);
  }

  componentDidMount() {
    this.props.trackResetAccount(STEP_MOBILE);
  }

  onClickReset() {
    this.props.onReset(this.props.email);
    this.goToStep(STEP_EMAIL_SENT);
  }

  onCheckboxChange() {
    const { checked, checkboxConfirm } = this.state;
    this.setState({
      checkboxConfirm: !checkboxConfirm,
      checked: !checked,
    });
  }

  getForgotMasterPasswordStep(step) {
    const steps = {
      STEP_MOBILE: (
        <ForgotMasterPasswordStep
          onConfirm={() => this.goToStep(STEP_MOBILE_BIOMETRIC)}
          onCancel={() => this.goToStep(STEP_DASHBOARD_CHANGE, step)}
        >
          <Paragraph size="h2">
            <FormattedHTMLMessage id="resetAccount.newMasterPassword.mobileInstalled" />
          </Paragraph>
        </ForgotMasterPasswordStep>
      ),
      STEP_MOBILE_BIOMETRIC: (
        <ForgotMasterPasswordStep
          onConfirm={() => this.goToStep(STEP_MOBILE_CHANGE)}
          onCancel={() => this.goToStep(STEP_DASHBOARD_CHANGE, step)}
        >
          <Paragraph size="h2">
            <FormattedHTMLMessage id="resetAccount.newMasterPassword.biometric" />
          </Paragraph>
        </ForgotMasterPasswordStep>
      ),
      STEP_MOBILE_CHANGE: (
        <ForgotMasterPasswordStep
          onConfirm={() => this.goToStep(CONGRATULATION)}
          confirmText="resetAccount.button.iDidIt"
          onCancel={() => this.goToStep(STEP_DASHBOARD_CHANGE, step)}
          cancelText="resetAccount.button.itDoesNotWork"
          cancelButtonColor="silver"
        >
          <Paragraph size="h2">
            <FormattedHTMLMessage id="resetAccount.newMasterPassword.mobileReset" />
          </Paragraph>
          <Paragraph>
            <ol
              type="1"
              style={{
                listStylePosition: 'outside',
                margin: '20px',
                listStyleType: 'decimal',
              }}
            >
              {
                mobileSteps.map(item => (
                  <li key={item}>
                    <FormattedHTMLMessage id={item} />
                  </li>
                ))
              }
            </ol>
          </Paragraph>
        </ForgotMasterPasswordStep>
      ),
      STEP_DASHBOARD_CHANGE: (
        <ForgotMasterPasswordStep
          onConfirm={this.onClickReset}
          confirmText="resetAccount.button.startReset"
          onCancel={() => this.goToStep(STEP_MOBILE, step)}
          cancelText="resetAccount.button.back"
          confirmDisabled={this.state.checkboxConfirm}
          cancelButtonColor="silver"
        >
          <Paragraph size="h2" mb="l">
            <FormattedHTMLMessage id="resetAccount.newMasterPassword.dashboardReset" />
          </Paragraph>
          <Paragraph mb="l">
            <Paragraph mb="s">
              <FormattedHTMLMessage
                id="resetAccount.newMasterPassword.dashboardReset.step1"
              />
            </Paragraph>
            <Paragraph>
              <FormattedHTMLMessage
                id="resetAccount.newMasterPassword.dashboardReset.step2"
              />
            </Paragraph>
          </Paragraph>

          <Paragraph mb="l">
            <Checkbox
              id="a-dashboard-reset-confirm-checkbox"
              label={(
                <FormattedHTMLMessage id="resetAccount.newMasterPassword.dashboardReset.confirm" />
              )}
              onChange={this.onCheckboxChange}
              checked={this.state.checked}
            />
          </Paragraph>
        </ForgotMasterPasswordStep>
      ),
      CONGRATULATION: (
        <ForgotMasterPasswordStep
          title="resetAccount.newMasterPassword.title.congratulation"
          onConfirm={() => this.props.history.push('master-password')}
          confirmText="resetAccount.button.openDashboard"
        >
          <Paragraph size="h2" mb="l">
            <FormattedHTMLMessage id="resetAccount.resetSuccessful" />
          </Paragraph>
        </ForgotMasterPasswordStep>
      ),
      STEP_EMAIL_SENT: (
        <ForgotMasterPasswordStep
          title="resetAccount.newMasterPassword.title.checkInbox"
        >
          <Paragraph size="h2" mb="l">
            <FormattedHTMLMessage
              id="resetAccount.checkInbox"
              values={{ email: this.props.email }}
            />
          </Paragraph>
          <Paragraph>
            <FormattedHTMLMessage id="resetAccount.checkInbox.step" />
          </Paragraph>
        </ForgotMasterPasswordStep>
      ),
    };

    return steps[step];
  }

  goToStep(step, prevStep = '') {
    this.setState({ step });
    this.props.trackResetAccount(step, prevStep);
  }

  render() {
    const { step } = this.state;
    return (
      <Box bg="background" display="flex" flexDirection="column" height="100%" width="100%">
        <BackgroundImage />
        <Box p="s" bg="foreground" zIndex="1">
          <PWMLogo />
        </Box>
        <Box display="flex" zIndex="1">
          <Box width="625px" mx="a" my="xxxl">
            {this.getForgotMasterPasswordStep(step)}
          </Box>
        </Box>
      </Box>
    );
  }
}

ForgotMasterPassword.propTypes = {
  email: PropTypes.string.isRequired,
  trackResetAccount: PropTypes.func,
  onReset: PropTypes.func.isRequired,
  history: PropTypes.func,
};

ForgotMasterPassword.defaultProps = {
  trackResetAccount: () => { },
  history: {
    push: () => { },
  },
};

const mapDispatchToProps = dispatch => bindActionCreators({
  trackResetAccount,
  onReset: resetMasterPassword,
}, dispatch);

const mapStateToProps = ({ oe }) => ({ email: oe.email });

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotMasterPassword));
