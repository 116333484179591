import styled from 'pwm-components/styled';

export default styled('ul')`
  margin-left: ${({ theme }) => theme.space.m};
  list-style: disc;
  color: white;

  & > li {
    margin-left: ${({ theme }) => theme.space.m};
    padding-left: ${({ theme }) => theme.space.xs};
    margin-bottom: ${({ theme }) => theme.space.xxs};
  }
`;
