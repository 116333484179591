import React, { useContext, useEffect } from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import Box from 'pwm-components/components/Box';
import Text from 'pwm-components/components/Text';
import MessengerContext from '../../../componentLib/MessengerContext';
import ImagePlaceholder from '../../../img/image_placeholder.png';
import { StepComponentProps } from './StepProps';
import mixpanel from '../../../tracking/mixpanel';

type ResponseType = {
  error: string;
}

const StepAllowDefault: React.FC<StepComponentProps> = ({
  goToNextStep,
  goToPreviousStep,
  stepName,
}) => {
  const dashboardMessenger = useContext(MessengerContext);

  useEffect(() => {
    if (dashboardMessenger?.isConnected()) {
      dashboardMessenger.send(
        'dashboard:extension:enablePasswordManager',
        null,
        (_err, { error }) => {
          if (!error) {
            mixpanel.track('PwmActivationClick', { stepName, buttonLabel: 'Allow' });
            goToNextStep();
          } else {
            mixpanel.track('PwmActivationClick', { stepName, buttonLabel: 'Deny' });
            goToPreviousStep();
          }
        },
      );
    }
  }, [dashboardMessenger, goToNextStep, goToPreviousStep, stepName]);

  useEffect(() => {
    mixpanel.track('PwmActivationShown', { stepName: 'AllowDefault' });
  }, []);

  return (

    <Box
      style={{ border: '2px dashed #555C64' }}
      display="flex"
      flexDirection="column"
      justifyContent="flex-end"
      alignItems="center"
      height="97%"
      pt="xxxl"
      pb="l"
      m="s"
    >
      <Box width="40%" textAlign="center">
        <img src={ImagePlaceholder} alt="" width="100%" />
        <Box pt='l'>
          <Text>
            <FormattedHTMLMessage id="onboarding.rightPanel.third.text" defaultMessage="<strong>Allow</strong> the additional permissions so Avira can start protecting your passwords" />
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default StepAllowDefault;
