/* globals SymBfw */
import VaultState from '@nlok/vault-sdk/source/VTVaultState';
import { DeepReadonly } from 'utility-types';
import {
  NLOKClearKeys,
  NLOKClearMigrationAttempts,
  NLOKIncreaseMigrationAttempts,
  NLOKSetBypassVault,
  NLOKSetKeys,
  NLOKSetMachineGuid,
  NLOKSetState,
  NLOK_CLEAR_KEYS,
  NLOK_CLEAR_MIGRATION_ATTEMPTS,
  NLOK_INCREASE_MIGRATION_ATTEMPTS,
  NLOK_SET_BYPASS_VAULT,
  NLOK_SET_KEYS,
  NLOK_SET_MACHINE_GUID,
  NLOK_SET_STATE,
} from './NLOKActionTypes';

export type State = DeepReadonly<{
  machineGuid: string | null;
  encryptionKey: string | null;
  challengeKey: string | null;
  vaultState: VaultState | null;
  bypassVault: string | null;
  migrationAttempts: number;
}>;

export function defaultState(): State {
  return {
    machineGuid: SymBfw.utils.createNewGuid(),
    encryptionKey: null,
    challengeKey: null,
    bypassVault: null,
    migrationAttempts: 0,
    vaultState: null,
  };
}

export type Actions = (
  NLOKSetMachineGuid
  | NLOKSetKeys
  | NLOKClearKeys
  | NLOKIncreaseMigrationAttempts
  | NLOKClearMigrationAttempts
  | NLOKSetBypassVault
  | NLOKSetState
);

// eslint-disable-next-line complexity
export default function nlokDataReducer(state = defaultState(), action: Actions): State {
  switch (action.type) {
    case NLOK_SET_MACHINE_GUID:
      return {
        ...state,
        machineGuid: action.value,
      };
    case NLOK_SET_KEYS:
      return {
        ...state,
        ...action.value,
      };
    case NLOK_CLEAR_KEYS:
      return {
        ...state,
        encryptionKey: null,
        challengeKey: null,
        bypassVault: null,
      };
    case NLOK_SET_STATE:
      return {
        ...state,
        vaultState: action.value,
      };
    case NLOK_SET_BYPASS_VAULT:
      return {
        ...state,
        bypassVault: action.value,
      };
    case NLOK_INCREASE_MIGRATION_ATTEMPTS:
      return {
        ...state,
        migrationAttempts: state.migrationAttempts + 1,
      };
    case NLOK_CLEAR_MIGRATION_ATTEMPTS:
      return {
        ...state,
        migrationAttempts: 0,
      };
    default:
      return state;
  }
}
